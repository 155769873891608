/**
* @file upload-PO-dialog.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/

import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ChangeDetectorRef, HostListener } from "@angular/core";
import { SelectItem } from "primeng/primeng";
import { FileItem } from "../../../modals/files/file-item";
import { Ng2PicaService } from "ng2-pica";
import * as _ from "lodash";
import { UploadImageService } from "../../../services/shared/upload-images.service";
import { CampaignActionDispatcher } from "../../../campaigns/action-dispatcher/action-dispatcher";
import * as utils from '../../../helpers/utils';
import { FirebaseService } from "../../../services/shared/firebase.service";
import { ImageTypeEnum } from "../../constants/image-type-enum";
import { Picture } from "../../../modals/hoardings/picture";
import { PurchaseOrder } from "../../../modals/campaigns/purchaseOrder";
import { MultiMediaType } from "../../../modals/hoardings/multiMediaType";
import { ImageService } from "../../../services/shared/image.service";
import { MultiMediaTypeEnum } from "../../constants/multi-media-type-enum";
import { ImageFolderConfig } from "../../../modals/image/image-folder-config";
import { PurchaseOrderWrapper } from "../../../modals/campaigns/purchaseOrderWrapper";
import * as fileUtils from '../../../helpers/file.util';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';


@Component({
    selector: 'sib-upload-PO-dialog',
    templateUrl: './upload-PO-dialog.component.html',
    styleUrls: ['./upload-PO-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UploadPODialogComponent implements OnInit {

    @Input() displayUploadPODialog = false;
    // @Input() campaignId = null;
    _campaign = null;
    // @Input() campaign = null;
    @Input() set campaign(campaign) {
        this._campaign = campaign;
        this.getCampaignPO();
    }

    get campaign() {
        return this._campaign;
    }

    _plan = null;
    @Input() set plan(plan) {
        this._plan = plan;
        this.getPlanPO();
    }

    get plan() {
        return this._plan;
    }

    @Input() imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();

    @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
    @Output() eEmitPurchseOrder: EventEmitter<any> = new EventEmitter();

    files: FileItem[] = [];  // for image upload
    purchaseOrders: PurchaseOrder[] = [];
    purchaseOrdersWrapper: PurchaseOrderWrapper[] = [];
    campaignPurchaseOrders: PurchaseOrder[] = [];
    planPurchaseOrders: PurchaseOrder[] = [];
    newName: string;
    savePressed = false;
    firebaseUrlSaved = false; // to know when the firebase urls are set
    isLoading = false;
    disableSave = false;

    constructor(
        private ng2PicaService: Ng2PicaService,
        private firebaseService: FirebaseService,
        private campaignActionDispatcher: CampaignActionDispatcher,
        private changeDetectorRef: ChangeDetectorRef,
        private imageService: ImageService,
        private notificationService: NotificatoinsService
    ) { }

    ngOnInit() {
        this.firebaseService.fileItems$.subscribe((fileItems) => {
            if (fileItems && fileItems.length > 0) {
                this.setUrlToPO(fileItems);
                // this.firebaseService.resetFileItems();
                this.detectChanges();
            }
        });
    }

    showUploadPODialog() {
        this.savePressed = false;
        this.firebaseService.resetFileItems();
    }

    hideUploadPODialog() {
        // if (this.savePressed) {
        // this.displayUploadPODialog = false;
        // }
        // this.files = [];
        // this.purchaseOrders = [];
        // this.purchaseOrdersWrapper = [];
        // this.displayReset.emit(false);
        if (!this.savePressed) {
            this.removeAllFiles();
            this.resetData();
        } else {
            this.displayUploadPODialog = false;
            this.resetData();
        }
    }

    resetData() {
        // this.displayUploadPODialog = false;
        this.files = [];
        this.purchaseOrders = [];
        this.purchaseOrdersWrapper = [];
        // this.savePressed = false;
        this.firebaseUrlSaved = false;
        this.displayReset.emit(false);
    }

    onFileSelection(event) {
        const files = this.imageService.getFirebasePoObjects(event.files);
        const supportedFiles = files.filter((fileItem) => {
            const fileExtension = fileUtils.getFileExtension(fileItem.file.name);
            return fileExtension === '.pdf' || fileExtension === '.doc' ||
                fileExtension === '.docx' || fileExtension === '.xlsx' || fileExtension === '.xls' ||
                fileExtension === '.csv' || fileExtension === '.txt' || fileItem.file.type.includes('image');
        })
        if (supportedFiles.length === event.files.length) {
            files.forEach((object) => {
                this.files.push(object);
            });
            this.imageService.getDatabasePoObjects(event.files, MultiMediaTypeEnum['FILE']);
            this.imageService.databasePoObjects$.subscribe((databasePoObjects) => {
                if (databasePoObjects && databasePoObjects.length > 0) {
                    databasePoObjects.forEach((object) => {
                        const purchaseOrderWrapper: PurchaseOrderWrapper = new PurchaseOrderWrapper();
                        purchaseOrderWrapper.purchaseOrder = object;
                        this.purchaseOrdersWrapper.push(purchaseOrderWrapper);
                        this.detectChanges();
                    });
                    this.imageService.databasePoObjects$.next([]);
                }
            });
        } else {
            this.notificationService.info("Uploaded File(s) format does not support.", "Not supportable file(s) format");
        }

    }

    changeName(event, index) {
        if (event.target.value && event.target.value !== "") {
            // const nameSplitArray = this.purchaseOrdersWrapper[index].purchaseOrder.name.split('.');
            // this.purchaseOrdersWrapper[index].newName = this.purchaseOrdersWrapper[index].newName + '.' + nameSplitArray[nameSplitArray.length - 1];
            // const file = new File([this.files[index].file], this.purchaseOrdersWrapper[index].newName, { type: this.files[index].file.type });
            // this.files[index].file = file;

            // const nameSplitArray = this.purchaseOrdersWrapper[index].purchaseOrder.name.split('.');
            // this.purchaseOrdersWrapper[index].newName = this.purchaseOrdersWrapper[index].newName + '.' + nameSplitArray[nameSplitArray.length - 1];
            let nameChanged = false;
            const eventExt = fileUtils.getFileExtension(event.target.value);
            if (eventExt) {
                if (this.purchaseOrdersWrapper[index].newName && this.purchaseOrdersWrapper[index].newName !== "") {
                    if (eventExt === fileUtils.getFileExtension(this.purchaseOrdersWrapper[index].purchaseOrder.name)) {
                        // do nothing
                    } else {
                        this.purchaseOrdersWrapper[index].newName = this.purchaseOrdersWrapper[index].newName + fileUtils.getFileExtension(this.purchaseOrdersWrapper[index].purchaseOrder.name);
                        nameChanged = true;
                    }
                }
            } else {
                if (this.purchaseOrdersWrapper[index].newName && this.purchaseOrdersWrapper[index].newName !== "") {
                    if (this.purchaseOrdersWrapper[index].newName + fileUtils.getFileExtension(this.purchaseOrdersWrapper[index].purchaseOrder.name) === this.purchaseOrdersWrapper[index].purchaseOrder.name) {
                        // do nothing
                        this.purchaseOrdersWrapper[index].newName = this.purchaseOrdersWrapper[index].newName + fileUtils.getFileExtension(this.purchaseOrdersWrapper[index].purchaseOrder.name);
                    } else {
                        this.purchaseOrdersWrapper[index].newName = this.purchaseOrdersWrapper[index].newName + fileUtils.getFileExtension(this.purchaseOrdersWrapper[index].purchaseOrder.name);
                        nameChanged = true;
                    }
                }
            }
            if (nameChanged) {
                const file = new File([this.files[index].file], this.purchaseOrdersWrapper[index].newName, { type: this.files[index].file.type });
                this.files[index].file = file;
                if (this.files[index].isQueued) {
                    // this.imageService.setFirebaseImageFolder(this.files, 'campaigns', this.imageFolderConfig.campaignId, this.imageFolderConfig.moduleSubType);
                    // this.firebaseService.uploadImageToFirebase(this.files[index]);
                    // this.files[index].isQueued = true;
                    this.files[index].progress = 0;
                    this.removeFile(index, false);
                    this.files[index].isQueued = false;
                }
            }

            // if (this.files[index].isQueued) {
            //     // this.imageService.setFirebaseImageFolder(this.files, 'campaigns', this.imageFolderConfig.campaignId, this.imageFolderConfig.moduleSubType);
            //     // this.firebaseService.uploadImageToFirebase(this.files[index]);
            //     // this.files[index].isQueued = true;
            //     this.files[index].progress = 0;
            //     this.removeFile(index, false);
            //     this.files[index].isQueued = false;
            // }
        }
    }

    // uploadPO(index) {
    //     if (!this.files[index].isQueued) {
    //         this.imageService.setFirebaseImageFolder(this.files, 'campaign', this.imageFolderConfig.campaignId, this.imageFolderConfig.moduleSubType);
    //         this.firebaseService.uploadImageToFirebase(this.files[index]);
    //         this.files[index].isQueued = true;
    //     }
    // }

    uploadAllPO() {
        this.uploadFiles();
    }

    setUrlToPO(fileItems) {
        if (fileItems && fileItems.length === this.purchaseOrders.length) {
            this.purchaseOrdersWrapper.forEach((po, index) => {
                fileItems.forEach((fileItem) => {
                    if (po.newName && po.newName !== "") {
                        if (po.newName === fileItem.file.name) {
                            this.purchaseOrdersWrapper[index].purchaseOrder.url = fileItem.url;
                        }
                    } else {
                        if (po.purchaseOrder.name === fileItem.file.name) {
                            this.purchaseOrdersWrapper[index].purchaseOrder.url = fileItem.url;
                        }
                    }
                });
            });
            this.firebaseUrlSaved = true;
            this.setPurchaseOrders();
        }
    }

    removeAllFiles() {
        const fileIndicesToBeRemoved: number[] = []; // indices in files array where the file item is to be removed
        // this.files.forEach((file, index) => {
        //     fileIndicesToBeRemoved.push(index);
        // });
        // fileIndicesToBeRemoved.forEach((index) => {
        //     this.removeFile(index);
        // });
        this.files.forEach((file) => {
            this.removeFile(0);
        });
        this.setPurchaseOrders();
    }


    removeFile(index, removeFromFiles = true) {
        if (this.files[index].isQueued) {
            this.firebaseService.deleteFile(this.files[index].file.name, this.files[index].imagesFolder);
            // this.firebaseService.resetFileItems();
            this.firebaseService.removeItemFromFileItem(this.files[index].file.name, this.files[index].imagesFolder);
        }
        if (removeFromFiles) {
            this.files.splice(index, 1);
            this.purchaseOrdersWrapper.splice(index, 1);
        }
    }

    saveItems() {
        this.isLoading = true;
        this.savePressed = true;
        this.uploadFiles(); // to upload the files which are not uploaded
        // this.getPurchaseOrderObjectFromWrapper();
        // this.purchaseOrders.forEach((po) => {
        //     po.folder = this.files[0].imagesFolder;
        // });
        // this.eEmitPurchseOrder.emit(this.purchaseOrders);
        this.setPurchaseOrders();
        // this.hideUploadPODialog();
    }

    setPurchaseOrders() {
        if (this.firebaseUrlSaved) {
            this.getPurchaseOrderObjectFromWrapper();
            this.purchaseOrders.forEach((po) => {
                po.folder = this.files[0].imagesFolder;
            });
            if (this.savePressed) {
                this.eEmitPurchseOrder.emit(this.purchaseOrders);
                this.hideUploadPODialog();
            }
            this.isLoading = false;
            this.disableSave = false;
            // this.detectChanges();
            // if (!this.savePressed) {
            //     this.removeAllFiles();
            //     this.resetData();
            // } else {
            //     this.resetData();
            // }
        }
    }

    uploadFiles() {
        this.renamePONames();
        let id;
        for (let i = 0; i < this.files.length; i++) {
            if (!this.files[i].isQueued) {
                this.disableSave = true;
                if (this.imageFolderConfig.moduleName === 'campaigns') {
                    id = this.imageFolderConfig.campaignId;
                } else if (this.imageFolderConfig.moduleName === 'plans') {
                    id = this.imageFolderConfig.planId;
                }
                this.imageService.setFirebaseImageFolder(this.files, this.imageFolderConfig.moduleName, id, this.imageFolderConfig.moduleSubType);
                this.firebaseService.uploadImageToFirebase(this.files[i]);
                this.files[i].isQueued = true;
                // this.detectChanges();
            }
        }
    }

    getPurchaseOrderObjectFromWrapper() {
        this.purchaseOrders = [];
        this.purchaseOrdersWrapper.forEach((pow) => {
            if (pow.newName && pow.newName !== "") {
                pow.purchaseOrder.name = pow.newName;
            }
            this.purchaseOrders.push(pow.purchaseOrder);
        });
    }

    renamePONames() {
        this.getPurchaseOrderObjectFromWrapper();
        let purchaseOrders: any[] = [];
        if (this.imageFolderConfig.moduleName === 'campaigns') {
            purchaseOrders = this.campaignPurchaseOrders;
        } else if (this.imageFolderConfig.moduleName === 'plans') {
            purchaseOrders = this.planPurchaseOrders;
        }
        let finalPOList: any[] = [...purchaseOrders, ...this.purchaseOrders];
        finalPOList = fileUtils.renameDuplicateNames(finalPOList, 'name');
        finalPOList = finalPOList.slice(purchaseOrders.length, finalPOList.length);
        finalPOList.forEach((fpo, index) => {
            // const ext=fileUtils.getFileExtension(fpo.name);
            this.purchaseOrdersWrapper.forEach((po, ind) => {

                if (index === ind) {
                    if (po.newName === "" || po.newName === undefined) {

                        po.newName = fpo.name;
                    }
                    else {
                        if (!fileUtils.getFileExtension(po.newName)) {
                            po.newName = po.newName + "." + fpo.extension;
                            fpo.name = po.newName;
                        }

                    }
                }
            });
        });
        this.updateNamesFromFiles(finalPOList);
        // this.detectChanges();
    }

    updateNamesFromFiles(finalPOList) {
        finalPOList.forEach((po, index) => {
            const file = new File([this.files[index].file], finalPOList[index].name, { type: this.files[index].file.type });
            this.files[index].file = file;
        });
        // this.detectChanges();
    }

    viewPO(PO) {
        this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
            window.open(response);
        });
    }

    onCheckboxSelectionChange(event, index) {
        if (!event) {
            this.purchaseOrdersWrapper[index].purchaseOrder.poNumber = null;
            this.purchaseOrdersWrapper[index].purchaseOrder.poDate = null;
        }
    }

    getCampaignPO() {
        this.campaignPurchaseOrders = (this.campaign && this.campaign.purchaseOrders) ? this.campaign.purchaseOrders : [];
    }

    getPlanPO() {
        this.planPurchaseOrders = (this.plan && this.plan.purchaseOrders) ? this.plan.purchaseOrders : [];
    }

    detectChanges() {
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    // @HostListener('window:keyup', ['$event'])
    // handleKeyDown(event: KeyboardEvent) {
    //     if (event.key === 'Escape') {
    //         if (this.disableSave) {
    //             // do nothing
    //         } else {
    //             event.stopImmediatePropagation();
    //             this.hideUploadPODialog();
    //         }
    //     }
    // }
}
