<div class="invoice-summary-grid">
    <div class="ui-g"
        [ngClass]="{'annexure-grid-format': getInvoiceFormat() && items.length > 1 && checkForFirstItem() && !showChanges}">
        <form [formGroup]="invoiceSummaryGroup">
            <sib-grid [showFilter]="false" name="invoiceSummaryGrid" #invoiceSummaryGrid [columns]="columns"
                [buttons]="buttonConfig" [config]="gridConfig" [totalRecords]="totalRecords" (eSort)="onSort($event)"
                formControlName="invoiceSummaryGridControl" ngDefaultControl>

                <sib-grid-column field="srNo">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="showChanges || rowData.amount && !checkInvoiceFormat() || showChanges">
                            {{rowData.serialNumber}}
                        </span>
                        <span *ngIf="!showChanges && rowData.amount && checkInvoiceFormat() ">
                            {{rowIndex + 1}}
                        </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="inventory">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.type === 'Rent'">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                <div>
                                    {{rowData.customId? rowData.customId+' - ':'' }}
                                    {{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                rowData.location+ (rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                </div>
                                <div *ngIf="rowData?.note">
                                    <i>{{rowData?.note}}</i>
                                </div>
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: (rowData?.customId ? rowData?.customId + ' - ' : '') + (rowData?.city ? rowData?.city + '-' : '') + (rowData?.location ? rowData?.location + (rowData?.size ? ' - ' : '') : '') + (rowData?.size ? rowData?.size : ''), previousValue: (getPreviousInvoiceRentItem(rowData)?.customId ? getPreviousInvoiceRentItem(rowData)?.customId + ' - ' : '') + (getPreviousInvoiceRentItem(rowData)?.city ? getPreviousInvoiceRentItem(rowData)?.city + '-' : '') + (getPreviousInvoiceRentItem(rowData)?.location ? getPreviousInvoiceRentItem(rowData)?.location + '-' : '') + (getPreviousInvoiceRentItem(rowData)?.size ? getPreviousInvoiceRentItem(rowData)?.size : ''), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData), noteValue: rowData?.note, previousNoteValue: getPreviousInvoiceRentItem(rowData)?.note  }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges">
                                <div>
                                    {{rowData.customId? rowData.customId+' - ':'' }}{{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                        rowData.location+  (rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                </div>
                                <div *ngIf="rowData?.note">
                                    <i>{{rowData?.note}}</i>
                                </div>
                            </span>
                        </span>
                        <span *ngIf="rowData?.type === 'Printing' || rowData?.type === 'UnitPrinting'">
                            <span *ngIf="invoice?.grouped">
                                <span *ngIf="showChanges && rowData?.statusType !== 'both'"
                                    class="{{rowData?.statusType}}">
                                    <div>
                                        Printing - {{checkForTotalSquareFeetOrUnit(rowData)}}
                                        {{checkChargeType(rowData)}} @
                                        {{rowData?.printRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <!-- <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div> -->
                                </span>
                                <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: Printing + ' - ' + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + rowData?.printRate + ' Rs./' + checkChargeType(rowData), previousValue: getPreviousInvoicePrintingItem(rowData)?.type + ' - ' + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + getPreviousInvoicePrintingItem(rowData)?.printRate + ' Rs./' +  checkChargeType(rowData), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoicePrintingItem(rowData) }">
                                    </ng-container>
                                </span>
                                <span *ngIf="!showChanges">
                                    <div>
                                        Printing - {{checkForTotalSquareFeetOrUnit(rowData)}}
                                        {{checkChargeType(rowData)}} @
                                        {{rowData?.printRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <!-- <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div> -->
                                </span>
                            </span>
                            <span *ngIf="!invoice?.grouped">
                                <span *ngIf="showChanges && rowData?.statusType !== 'both'"
                                    class="{{rowData?.statusType}}">
                                    <div>
                                        {{rowData.customId? rowData.customId+' - ':'' }}{{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                        rowData.location+ (rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                    </div>
                                    <div>
                                        Printing - {{(rowData?.creative && rowData?.creative !== 'Default')
                                        ?
                                        rowData?.creative + ' -
                                        ' : ''}}
                                        {{checkForTotalSquareFeetOrUnit(rowData)}} {{checkChargeType(rowData)}} @
                                        {{rowData?.printRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div>
                                </span>
                                <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="printMountInvoiceTemplate; context: {value1: ((rowData?.customId) ? rowData?.customId + ' - ' : '') + ((rowData?.city) ? rowData?.city + ' - ' : '') + ((rowData?.location) ? rowData?.location + (rowData?.size ? ' - ' : '') : '') + ((rowData?.size) ? rowData?.size : ''), value2: ('Printing' + ' - ') + ((rowData?.creative && rowData?.creative !== 'Default') ? rowData?.creative + ' - ' : '') + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + rowData?.printRate + ' Rs./' + checkChargeType(rowData), previousValue1: ((getPreviousInvoicePrintingItem(rowData)?.customId) ? getPreviousInvoicePrintingItem(rowData)?.customId + ' - ' : '') + ((getPreviousInvoicePrintingItem(rowData)?.city) ? getPreviousInvoicePrintingItem(rowData)?.city + ' - ' : '') + ((getPreviousInvoicePrintingItem(rowData)?.location) ? getPreviousInvoicePrintingItem(rowData)?.location + ' - ' : '') + ((getPreviousInvoicePrintingItem(rowData)?.size) ? getPreviousInvoicePrintingItem(rowData)?.size : ''), previousValue2: (getPreviousInvoicePrintingItem(rowData)?.type + ' - ') + ((getPreviousInvoicePrintingItem(rowData)?.creative && getPreviousInvoicePrintingItem(rowData)?.creative !== 'Default') ? getPreviousInvoicePrintingItem(rowData)?.creative + ' - ' : '') + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + getPreviousInvoicePrintingItem(rowData)?.printRate + ' Rs./' + checkChargeType(rowData), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoicePrintingItem(rowData), noteValue: rowData?.note, previousNoteValue: getPreviousInvoicePrintingItem(rowData)?.note }">
                                    </ng-container>
                                </span>
                                <span *ngIf="!showChanges">
                                    <div>
                                        {{rowData.customId? rowData.customId+' - ':'' }}{{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                        rowData.location+ (rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                    </div>
                                    <div>
                                        Printing- {{(rowData?.creative && rowData?.creative !== 'Default')
                                        ?
                                        rowData?.creative + ' -
                                        ' : ''}}
                                        {{checkForTotalSquareFeetOrUnit(rowData)}} {{checkChargeType(rowData)}} @
                                        {{rowData?.printRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div>
                                </span>
                            </span>
                        </span>
                        <span *ngIf="rowData?.type === 'Mounting' || rowData?.type === 'UnitMounting'">
                            <span *ngIf="invoice?.grouped">
                                <span *ngIf="showChanges && rowData?.statusType !== 'both'"
                                    class="{{rowData?.statusType}}">
                                    <div>
                                        Mounting - {{checkForTotalSquareFeetOrUnit(rowData)}}
                                        {{checkChargeType(rowData)}} @
                                        {{rowData?.mountRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <!-- <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div> -->
                                </span>
                                <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: Mounting + ' - ' + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + rowData?.mountRate + ' Rs./' + checkChargeType(rowData), previousValue: getPreviousInvoiceMountingItem(rowData)?.type + ' - ' + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + getPreviousInvoiceMountingItem(rowData)?.mountRate + ' Rs./' + checkChargeType(rowData), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceMountingItem(rowData) }">
                                    </ng-container>
                                </span>
                                <span *ngIf="!showChanges">
                                    <div>
                                        Mounting - {{checkForTotalSquareFeetOrUnit(rowData)}}
                                        {{checkChargeType(rowData)}} @
                                        {{rowData?.mountRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <!-- <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div> -->
                                </span>
                            </span>
                            <span *ngIf="!invoice?.grouped">
                                <span *ngIf="showChanges && rowData?.statusType !== 'both'"
                                    class="{{rowData?.statusType}}">
                                    <div>
                                        {{rowData.customId? rowData.customId+' - ':'' }}
                                        {{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                        rowData.location+(rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                    </div>
                                    <div>
                                        Mounting - {{(rowData?.creative && rowData?.creative !== 'Default')
                                        ?
                                        rowData?.creative + ' -
                                        ' : ''}}
                                        {{checkForTotalSquareFeetOrUnit(rowData)}} {{checkChargeType(rowData)}} @
                                        {{rowData?.mountRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div>
                                </span>
                                <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="printMountInvoiceTemplate; context: {value1: ((rowData?.customId) ? rowData?.customId + ' - ' : '') + ((rowData?.city) ? rowData?.city + ' - ' : '') + ((rowData?.location) ? rowData?.location + (rowData?.size ? ' - ' : '') : '') + ((rowData?.size) ? rowData?.size : ''), value2: ('Mounting' + ' - ') + ((rowData?.creative && rowData?.creative !== 'Default') ? rowData?.creative + ' - ' : '') + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + rowData?.mountRate + ' Rs./' + checkChargeType(rowData), previousValue1: ((getPreviousInvoiceMountingItem(rowData)?.customId) ? getPreviousInvoiceMountingItem(rowData)?.customId + ' - ' : '') + ((getPreviousInvoiceMountingItem(rowData)?.city) ? getPreviousInvoiceMountingItem(rowData)?.city + ' - ' : '') + ((getPreviousInvoiceMountingItem(rowData)?.location) ? getPreviousInvoiceMountingItem(rowData)?.location + ' - ' : '') + ((getPreviousInvoiceMountingItem(rowData)?.size) ? getPreviousInvoiceMountingItem(rowData)?.size : ''), previousValue2: (getPreviousInvoiceMountingItem(rowData)?.type + ' - ') + ((getPreviousInvoiceMountingItem(rowData)?.creative && getPreviousInvoiceMountingItem(rowData)?.creative !== 'Default') ? getPreviousInvoiceMountingItem(rowData)?.creative + ' - ' : '') + checkForTotalSquareFeetOrUnit(rowData) + checkChargeType(rowData) +' @ ' + getPreviousInvoiceMountingItem(rowData)?.mountRate + ' Rs./'+ checkChargeType(rowData), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceMountingItem(rowData), noteValue: rowData?.note, previousNoteValue: getPreviousInvoiceMountingItem(rowData)?.note }">
                                    </ng-container>
                                </span>
                                <span *ngIf="!showChanges">
                                    <div>
                                        {{rowData.customId? rowData.customId+' - ':'' }}{{rowData.city? rowData.city+', ':'' }} {{rowData.location?
                                        rowData.location+(rowData?.size ? ' - ' : ''):'' }} {{rowData?.size}}
                                    </div>
                                    <div>
                                        Mounting - {{(rowData?.creative && rowData?.creative !== 'Default')
                                        ?
                                        rowData?.creative + ' -
                                        ' : ''}}
                                        {{checkForTotalSquareFeetOrUnit(rowData)}} {{checkChargeType(rowData)}} @
                                        {{rowData?.mountRate}}
                                        Rs./{{checkChargeType(rowData)}}
                                    </div>
                                    <div *ngIf="rowData?.note">
                                        <i>{{rowData?.note}}</i>
                                    </div>
                                </span>
                            </span>
                        </span>
                        <span
                            *ngIf="rowData?.type === 'RO' || rowData?.type === 'RO_RENT' || rowData?.type === 'RO_PRINT_MOUNT'">
                            <span *ngIf="!showChanges">
                                {{rowData.id}}
                            </span>
                            <span *ngIf="showChanges">
                                <span *ngIf="rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                    {{rowData.id}}
                                </span>
                                <span *ngIf="rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: rowData?.id, previousValue: rowData?.id, rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRoRentPrintMountItem(rowData) }">
                                    </ng-container>

                                </span>
                            </span>
                        </span>

                        <span
                            *ngIf="(rowData?.type === 'ANNEXURE' || rowData?.type === 'PRINTING_CHARGES' || rowData?.type === 'MOUNTING_CHARGES'); else showChangesAnnexureFormat">
                            <span *ngIf="!showChanges">
                                {{rowData.id}}

                            </span>
                            <span *ngIf="showChanges">
                                <span *ngIf="rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                    {{rowData.id}}
                                </span>
                                <span *ngIf="rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: rowData?.id, previousValue: rowData?.id, rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousAnnexureRentPrintMountItem(rowData) }">
                                    </ng-container>
                                </span>
                            </span>
                        </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="itemStartDate">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.itemStartDate">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                {{rowData?.itemStartDate | date:'ddMMMyy'}}
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedDate(rowData?.itemStartDate), previousValue: getFormattedDate(getPreviousInvoiceRentItem(rowData)?.itemStartDate), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData) }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges ">
                                {{rowData?.itemStartDate | date:'ddMMMyy'}}
                            </span>
                        </span>
                        <span *ngIf="!rowData?.itemStartDate">-</span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="itemEndDate">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.itemEndDate">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                {{rowData?.itemEndDate | date:'ddMMMyy'}}
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedDate(rowData?.itemEndDate), previousValue: getFormattedDate(getPreviousInvoiceRentItem(rowData)?.itemEndDate), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData) }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges ">
                                {{rowData?.itemEndDate | date:'ddMMMyy'}}
                            </span>
                        </span>
                        <span *ngIf="!rowData?.itemEndDate">-</span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="days">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.days">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                {{rowData?.days}}
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: rowData?.days, previousValue: getPreviousInvoiceRentItem(rowData)?.days, rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData) }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges">
                                {{rowData?.days}}
                            </span>
                        </span>
                        <span *ngIf="!rowData?.days">-</span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="rate">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.type === 'Rent'">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                <span *ngIf="rowData.rate">
                                    {{rowData?.rate | CurrencyConverter}}
                                </span>
                                <span *ngIf="!rowData.rate">
                                    -
                                </span>

                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedCurrency(rowData?.rate), previousValue: getFormattedCurrency(getPreviousInvoiceRentItem(rowData)?.rate), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData) }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges">
                                <span *ngIf="rowData.rate">
                                    {{rowData?.rate | CurrencyConverter}}
                                </span>
                                <span *ngIf="!rowData.rate">
                                    -
                                </span>
                            </span>
                        </span>
                        <span *ngIf="rowData?.type !== 'Rent'">
                            -
                        </span>
                    </ng-template>
                </sib-grid-column>



                <sib-grid-column field="amount">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span
                            *ngIf="invoice?.invoiceFormat !== 'PACKAGE_RATE_INCLUSIVE' && invoice?.invoiceFormat !== 'PACKAGE_RATE_EXCLUSIVE' && invoice?.invoiceFormat !== 'AS_PER_RO_INCLUSIVE' && invoice?.invoiceFormat !== 'AS_PER_RO_EXCLUSIVE' && invoice?.invoiceFormat !== 'WITH_ATTACHED_ANNEXURE'">
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                <span *ngIf="rowData.amount">
                                    {{getRoundedOffValue(rowData?.amount) | CurrencyConverter}}
                                </span>
                                <span *ngIf="!rowData.amount">
                                    -
                                </span>
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedCurrency(getParseValueToDecimalTwoPlaces(rowData?.amount)), previousValue: (rowData?.type === 'Rent') ? getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousInvoiceRentItem(rowData)?.amount)) : (rowData?.type === 'Printing') ? getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousInvoicePrintingItem(rowData)?.amount)) : getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousInvoiceMountingItem(rowData)?.amount)), rowData: rowData, rowIndex: rowIndex, previousRowData: getPreviousInvoiceRentItem(rowData) }">
                                </ng-container>
                            </span>
                            <span *ngIf="!showChanges">
                                <span *ngIf="rowData.amount">
                                    {{getParseValueToDecimalTwoPlaces(rowData?.amount) | CurrencyConverter}}
                                </span>
                                <span *ngIf="!rowData.amount">
                                    -
                                </span>
                            </span>
                        </span>
                        <span *ngIf="!showChanges">
                            <span
                                *ngIf="invoice && invoice?.invoiceFormat && (invoice?.invoiceFormat === 'PACKAGE_RATE_INCLUSIVE' || invoice?.invoiceFormat === 'PACKAGE_RATE_EXCLUSIVE')">
                                {{getAmountBasedOnInvoiceFormat(rowData, rowIndex) |
                                CurrencyConverter}}
                            </span>
                            <span
                                *ngIf="invoice && invoice?.invoiceFormat && (invoice?.invoiceFormat === 'AS_PER_RO_INCLUSIVE' || invoice?.invoiceFormat === 'AS_PER_RO_EXCLUSIVE'  || invoice?.invoiceFormat === 'WITH_ATTACHED_ANNEXURE')">
                                {{getParseValueToDecimalTwoPlaces(rowData?.amount) |
                                CurrencyConverter}}
                            </span>

                        </span>
                        <span *ngIf="showChanges">
                            <span
                                *ngIf="invoice && invoice?.invoiceFormat && (invoice?.invoiceFormat === 'PACKAGE_RATE_INCLUSIVE' || invoice?.invoiceFormat === 'PACKAGE_RATE_EXCLUSIVE')">
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedCurrency(getParseValueToDecimalTwoPlaces(getAmountBasedOnInvoiceFormat(rowData, rowIndex))), previousValue: getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousAmountBasedOnInvoiceFormat(rowData, rowIndex))) }">
                                </ng-container>
                            </span>
                            <span
                                *ngIf="invoice && invoice?.invoiceFormat && (invoice?.invoiceFormat === 'AS_PER_RO_INCLUSIVE' || invoice?.invoiceFormat === 'AS_PER_RO_EXCLUSIVE') ">
                                <span *ngIf="rowData?.statusType === 'both'">

                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedCurrency(getParseValueToDecimalTwoPlaces(rowData?.amount)), previousValue: getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousInvoiceRoRentPrintMountItem(rowData)?.amount)) }">
                                    </ng-container>
                                </span>
                                <span *ngIf="rowData?.statusType !== 'both'">
                                    <span *ngIf="rowData.amount" class="{{rowData?.statusType}}">
                                        {{getParseValueToDecimalTwoPlaces(rowData?.amount) | CurrencyConverter}} </span>
                                    <span *ngIf="!rowData.amount">
                                        -
                                    </span>
                                </span>
                            </span>
                            <span
                                *ngIf="invoice && invoice?.invoiceFormat && invoice?.invoiceFormat === 'WITH_ATTACHED_ANNEXURE' ">
                                <span *ngIf="rowData?.statusType === 'both'">
                                    <ng-container
                                        *ngTemplateOutlet="rentInvoiceTemplate; context: {value: getFormattedCurrency(getParseValueToDecimalTwoPlaces(rowData?.amount)), previousValue: getFormattedCurrency(getParseValueToDecimalTwoPlaces(getPreviousAnnexureRentPrintMountItem(rowData)?.amount)) }">
                                    </ng-container>
                                </span>
                                <span *ngIf="rowData?.statusType !== 'both'">
                                    <span *ngIf="rowData.amount" class="{{rowData?.statusType}}">
                                        {{getParseValueToDecimalTwoPlaces(rowData?.amount) | CurrencyConverter}} </span>
                                    <span *ngIf="!rowData.amount">
                                        -
                                    </span>
                                </span>

                            </span>

                        </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="hsnCode">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.hsnCode">
                            <span *ngIf="!showChanges">
                                <span *ngIf="rowData?.hsnCode">
                                    {{rowData?.hsnCode}}
                                </span>
                            </span>
                            <span *ngIf="showChanges && rowData?.statusType !== 'both'" class="{{rowData?.statusType}}">
                                <span *ngIf="rowData?.hsnCode">
                                    {{rowData?.hsnCode}}

                                </span>

                            </span>
                            <span *ngIf="showChanges && rowData?.statusType === 'both'">
                                <!-- {{rowData?.hsnCode}} -->
                                <ng-container
                                    *ngTemplateOutlet="rentInvoiceTemplate; context: {value: rowData?.hsnCode, previousValue: rowData?.hsnCode }">
                                </ng-container>
                            </span>
                        </span>
                    </ng-template>
                </sib-grid-column>

            </sib-grid>
        </form>
    </div>


    <ng-template #rentInvoiceTemplate let-rowData='rowData' let-rowIndex='rowIndex' let-noteValue='noteValue'
        let-previousNoteValue='previousNoteValue' let-previousRowData='previousRowData' let-value='value'
        let-previousValue='previousValue'>
        <div>
            {{value}}
        </div>
        <div>
            {{noteValue}}
        </div>
        <div *ngIf="value !== previousValue" class="change-data">
            {{previousValue}}
        </div>
        <div *ngIf="noteValue !== previousNoteValue" class="change-data">
            {{previousNoteValue}}
        </div>
    </ng-template>

    <ng-template #printMountInvoiceTemplate let-rowData='rowData' let-rowIndex='rowIndex' let-noteValue='noteValue'
        let-previousNoteValue='previousNoteValue' let-previousRowData='previousRowData' let-value1='value1'
        let-value2='value2' let-previousValue1='previousValue1' let-previousValue2='previousValue2'>
        <div>
            {{value1}}
        </div>
        <div>
            {{value2}}
        </div>
        <div>
            {{noteValue}}
        </div>
        <div *ngIf="value1 !== previousValue1 || value2 !== previousValue2" class="change-data">
            {{previousValue1}}
        </div>
        <div *ngIf="value1 !== previousValue1 || value2 !== previousValue2" class="change-data">
            {{previousValue2}}
        </div>
        <div *ngIf="noteValue !== previousNoteValue" class="change-data">
            {{previousNoteValue}}
        </div>
    </ng-template>
</div>