import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, CanActivate, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs";
import { NotificatoinsService } from './services/notifications/notifications.service';
import { AuthGuardService } from './login-service/auth-guard.service';
import { AuthService } from './login-service/auth.service';

@Injectable()
export class CanActivateMenuGuard implements CanActivate {

    constructor(
        private notificationServcie: NotificatoinsService,

    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        let subscriptionActive = JSON.parse(localStorage.getItem('subscriptionActive'));
        if (subscriptionActive) {
            return true;
        } else {
            this.notificationServcie.info(Boolean(subscriptionActive) === false ? 'Subscribe your Plan first.' : 'Your Subscription Plan has been expired. Subscribe it.', "Information");
        }
    }
}
