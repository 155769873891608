<div class="card-title  ui-g-12">
  <span class="c-title princing-header">{{summaryTitle}}</span>
  <span class="c-title princing-header cLSPSummary "> LSP Difference</span>
  <span class="c-title cInline">
    <span *ngIf="summary?.lspDiffAmt >= 0 "
      class="lsp-diif pos-diff">{{getIntegerRoundedOffValue(getAbsoluteValue(summary?.lspDiffAmt))|CurrencyConverter}}
      [ {{getIntegerRoundedOffValue(getAbsoluteValue(summary?.lspDiffPctg))}}
      %] </span>
    <span *ngIf="(summary?.lspDiffAmt < 0 )"
      class="lsp-diif neg-diff">{{getIntegerRoundedOffValue(getAbsoluteValue(summary?.lspDiffAmt))|CurrencyConverter}}
      [ {{getIntegerRoundedOffValue(getAbsoluteValue(summary?.lspDiffPctg))}}
      %] </span>

  </span>


  <hr>
</div>
<div class="card-detail ui-g-12">

  <div class="ui-g padding-0">
    <div class="ui-g-4">
      <div class="keyfont">Total Rent:</div>
    </div>
    <div class="ui-g-2 value-digit">
      <div>{{getDecimalNum(summary?.totalRent, 2)|CurrencyConverter}}</div>
    </div>
    <div class="ui-g-1"></div>

  </div>
  <div class="ui-g padding-0">
    <div class="ui-g-4">
      <div class="keyfont">Printing Price:</div>
    </div>
    <div class="ui-g-2 value-digit">
      <div>{{getDecimalNum((summary?.printingAmt +
                                    summary?.additionalPrintingPrice), 2)
                                        | CurrencyConverter}}
      </div>
    </div>

  </div>
  <div class="ui-g padding-0">
    <div class="ui-g-4">
      <div class="keyfont">Mounting Price:</div>
    </div>
    <div class="ui-g-2 value-digit">
      <div>{{getDecimalNum((summary?.mountingAmt +
                                    summary?.additionalMountingPrice), 2)|
                                        CurrencyConverter}}
      </div>
    </div>

  </div>
  <div class="ui-g padding-0 horizontalRuler-dotted">
    <div class="ui-g-4">
      <div class="keyfont">GST({{gstParameter}}%)</div>
    </div>
    <div class="ui-g-2 value-digit">
      <div>{{summary?.totalGST | CurrencyConverter}}</div>
    </div>
  </div>
  <div class="ui-g padding-0 sumary-grand-total">
    <div class="ui-g-4">
      <div>Grand Total:</div>
    </div>
    <div class="ui-g-2 value-digit">
      <div>{{getIntegerRoundedOffValue(summary?.grandTotal) | CurrencyConverter}}
      </div>
    </div>
  </div>
</div>