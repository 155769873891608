export enum ActivityLogModuleEnum {
  AREA = "Area",
  CITY = "City",
  CONTRACT_TYPE = "Contract Type",
  DISTRICT = "District",
  GROUP = "Group",
  CONTRACT = "Contract",
  CUSTOMER = "Customer",
  INVENTORY = "Inventory",
  POINT_OF_CONTACT = "Point Of Contact",
  PLAN = "Plan",
  PLAN_ITEM = "Plan Item",
  CAMPAIGN = "Campaign",
  CAMPAIGN_ITEM = "Campaign Item",
  INVOICE = "Invoice",
  MIGRATED_INVOICE = "Migrated Invoice",
  PURCHASE_ORDER = "Purchase Order",
  SUGGESTION = "suggestion",
  CAMPAIGN_ITEM_CONFLICT = "Campaign Item Conflict",
  CI_MOUNTING = "Campaign Item Mounting",
  SUPPLIER = "Supplier",
}
