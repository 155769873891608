<div class="reports-container">
  <div class="flex-action-container margin-top-14">
    <div class="action-button-container" *ngIf="!showBreakups">
      <ul class="cinline-block">
        <li class="padding-right-10 margin-top--7 float-left">
          <h3 class="margin-0 header-text">{{title}}</h3>
        </li>
        <li class="margin-top-4 padding-left-5 float-right">
          <label class="font-14 font-bold label-vCenter padding-right-5 toggle-switch-label">Export XLSX</label>
          <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn top--2" (click)="exportXlsLevel1()"
            title="Export Xlsx" icon="fa fa-file-excel-o">
          </button>
        </li>
        <li #startDate class="margin-top-5 calendar-container float-right">
          <p-calendar [styleClass]="'dates-button-hm'" #calender [appendTo]="startDate" dateFormat="Myy"
            [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050" placeholder=" Ex-Jan18"
            [(ngModel)]="calendarEndDate" (onClose)="onDateSelection($event)" [utc]="true" [showIcon]="true"
            readonlyInput="true" (onMonthChange)="onEndDateMonthOrYearChange($event)"
            (onYearChange)="onEndDateMonthOrYearChange($event)">
          </p-calendar>
        </li>
        <li class="dates-text float-right">
          <div>End Date</div>
        </li>
        <li #endDate class="margin-top-5 calendar-container float-right">
          <p-calendar [styleClass]="'dates-button-hm'" #calender [appendTo]="endDate" placeholder=" Ex-01Jan18"
            dateFormat="Myy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050" [utc]="true"
            readonlyInput="true" [showIcon]="true" [(ngModel)]="calendarStartDate"
            (onMonthChange)="onStartDateMonthOrYearChange($event)"
            (onYearChange)="onStartDateMonthOrYearChange($event)">
          </p-calendar>
        </li>
        <li class="dates-text float-right">
          <div>Start Date</div>
        </li>

      </ul>
    </div>
    <div *ngIf="showBreakups">
      <ul class="cinline-block padding-6">
        <li class="padding-right-10 margin-top--7 float-left">
          <button pTooltip="Back" class="mtab-secondary c-edit-btn border-rad-2 top--46 height-24 go-back "
            icon="fa fa-arrow-left" (click)="goback()" pButton>
          </button>
        </li>
        <li class="padding-right-10 margin-top--7 float-left">
          <h3 class="margin-0 ">{{expenseBreakupTitle}}</h3>
        </li>
        <li class="padding-right-10 margin-top--7 float-right">
          <span class="font-bold">
            Total Amount:
          </span>
          ₹ {{totalAmount}}
        </li>
        <li class="padding-right-10 margin-top--7 float-right position-relative top--3">
          <label class="font-14 font-bold label-vCenter padding-right-5 toggle-switch-label">Export XLSX</label>
          <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn top--2" (click)="exportXlsLevel2()"
            title="Export Xlsx" icon="fa fa-file-excel-o">
          </button>
        </li>
        <li class="status-li float-right multi-select-filter">
          <ul class="horizontal-list-hm">
            <li style="margin-left: 10px;height:16px; float:right; position: relative;
            top: -10px; ">
              <p-multiSelect [options]="expenses" defaultLabel="" [style]="{'width':'132px'}" maxSelectedLabels="0"
                [(ngModel)]="selectedExpenses" [dropdownIcon]="'fa fa-filter'" name="status-field" appendTo="body"
                [displaySelectedLabel]="false" [styleClass]="'show-multi-expenses'" (onChange)="emitSelectedExpenses()">
              </p-multiSelect>
            </li>
            <li *ngFor="let expense of selectedExpenses; let i = index" class="saved-template-container">
              <span class="status-background">
                {{getExpenses(expense)}}
              </span>
              <i class="fa fa-times" (click)="removeExpense(i)"></i>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="expenses-grid-scroller" *ngIf="!showBreakups">
    <sib-grid #sibGrid [columns]="gridColumnConfig" [config]="gridConfig" [totalRecords]="totalInventoryRecords"
      [selectedColumn]="hmSelectedColumns" (eRowExpand)="onRowExpand($event)"
      (eEmitColumnSelection)="updatingUserGridConfig($event)" ngDefaultControl>

      <sib-grid-column field="customId">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <a class="link-1  after" target="_blank" (click)="showExpenseBreakup(rowData)">{{rowData.customId}}</a>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span>
            {{rowIndex+1}}
          </span>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
            appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="mediaType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown #mediatypedd [options]="mediaTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
            appendTo="body" placeholder="Media" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>
    </sib-grid>
  </div>
  <div class="expenses-grid-scroller" *ngIf="showBreakups">
    <sib-expenses-breakup [rowData]="rowData" [startDate]="startDate" [endDate]="endDate"
      [selectedExpenses]="selectedExpenses" (eEmitTotalAmount)="totalAmount = $event"
      [exportLevel2Xlsx]="exportLevel2Xlsx" (eEmitXlsxDownloaded)="exportLevel2Xlsx = false"></sib-expenses-breakup>
  </div>
</div>