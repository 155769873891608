/**
* @file assign-to-plan-dialog.component.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import * as utils from '../../../helpers/utils';
import { Customer } from "../../../modals/KYC/customer";
import { Plan } from "../../../modals/plans/plan";
import { CalculationService } from '../../../services/shared/calculationService';
import { ChangesService } from '../../../services/shared/changes.service';
import { CompanyService } from "../../../services/shared/company.service";
import { PlanService } from "../../../services/shared/plan.service";
import { PlanNameService } from "../../../services/shared/planName.service";
import { ActivityLogModuleEnum } from '../../constants/activity-log-module-enum';


@Component({
    selector: 'sib-assign-to-plan-dialog',
    templateUrl: './assign-to-plan-dialog.component.html',
    styleUrls: ['./assign-to-plan-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [PlanNameService]
})

export class AssignToPlanDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() displayPlanDialog = false;
    @Input() planEdit = false;
    @Input() copyConvertedPlan = false;
    _editablePlan: any;
    @Input() set editablePlan(editablePlan) {
        this._editablePlan = editablePlan;
        this._editablePlan = JSON.parse(JSON.stringify(this._editablePlan));
    }
    get editablePlan() {
        return this._editablePlan;
    }
    @Output() eEmitChangeDisplay: EventEmitter<any> = new EventEmitter();
    @Output() eEmitSavePlan: EventEmitter<Plan> = new EventEmitter();
    @Output() eEmitCreatePlan: EventEmitter<Plan> = new EventEmitter();
    @ViewChild('newPlanForm') newPlanForm;
    @ViewChild('noOfDays') noOfDays: ElementRef;

    plans: any[] = [];
    employees = [];
    selectedEmployee: any = null;
    selectedPlan: any;
    newPlan: Plan = new Plan();
    companies: any[] = [];
    selectedBillingName: any;
    filteredBillingName: any[] = [];
    filteredPlans: any[] = [];
    filteredEmployees: any[] = [];
    isloading = false;
    currentDate: Date = new Date();
    existingPlanFocus = false;
    planFocus = false;
    subscription: Subscription[] = [];
    originalStartDateValue: Date;
    originalEndDateValue: Date;

    /**
     * @description to set the editable fields array of the dialog for changes map resetting
     * @memberof AssignToPlanDialogComponent
     */
    editableFields = [];

    constructor(
        private planNameService: PlanNameService,
        private companyService: CompanyService,
        private planService: PlanService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private calculationService: CalculationService,
        private changesService: ChangesService,
    ) { }

    ngOnInit() {
        if (!this.planEdit) {
            this.getAllPlans();
        }
        this.showPlanDialog();
        this.checkForAdmin();
        this.editableFields = ["employee.fullName", "customer.company", "displayName", "campaignStartDate", "campaignEndDate", "note"];
    }
    getAllPlans() {
        this.isloading = true;
        this.subscription.push(this.planNameService.getAllPlans()
            .subscribe((response) => {
                this.plans = response['data'];
                this.isloading = false;
            }));
    }

    getCompanies() {
        this.subscription.push(this.companyService.get()
            .subscribe((response) => {
                this.companies = response['_embedded']['customers'];
                if (this.planEdit) {
                    this.setSelectedBillingName();
                }
            }));
    }

    setSelectedBillingName() {
        this.companies.forEach((company) => {
            if (company.billingName === this.newPlan.customer.billingName) {
                this.selectedBillingName = company;
            }
        });
    }

    checkForAdmin() {
        if (this.checkPermission('readAll')) {
            this.getEmployees();
        } else {
            this.getEmployees(); // employee was not been assigned to  the plan while adding site to an existing plan
            this.getCompanies();
        }
    }

    getEmployees() {
        this.subscription.push(this.planService.getUsersByRole("SALES_REPRESENTATIVE").subscribe((response: any) => {
            this.employees = response;
            this.setSelectedEmployee();
        }));


    }

    setSelectedEmployee() {
        this.employees.forEach((emp) => {
            if (this.newPlan.employee.emailId === emp.emailId) {
                this.selectedEmployee = emp;
            }
        });
        this.setCompanyForEmployee();
    }

    ngAfterViewInit() { }

    filterBillingName(event) {
        this.filteredBillingName = [];
        this.filteredBillingName = utils.filterData(this.companies, 'billingName', event);      // removed company name selection
    }

    showPlanDialog() {
        if (this.planEdit) {
            this.newPlan = JSON.parse(JSON.stringify(this.editablePlan));
            this.newPlan.displayName = this.editablePlan.displayName;
            this.newPlan.note = this.editablePlan.note;
            this.newPlan.campaignStartDate = new Date(this.editablePlan.campaignStartDate);
            this.newPlan.campaignEndDate = new Date(this.editablePlan.campaignEndDate);
            this.originalStartDateValue = new Date(_.cloneDeep(this.newPlan.campaignStartDate));
            this.originalEndDateValue = new Date(_.cloneDeep(this.newPlan.campaignEndDate));
            if (this.editablePlan.customer === null) {
                this.editablePlan.customer = new Customer();
            }
            if (this.newPlan.customer === null) {
                this.newPlan.customer = new Customer();
            }
        }
    }

    hidePlanDialog() {
        this.changesService.resetMap(this.getChangedType(), this.editableFields);
        this.displayPlanDialog = false;
        this.newPlanForm.reset();
        this.eEmitChangeDisplay.emit(false);
    }

    getSelectedPlan() {
        this.subscription.push(this.planService.getPlanById(this.selectedPlan.id).subscribe((response: any) => {
            this.isloading = true;
            this.newPlan = response['data'];
            this.setPlanDetails();
        }));
    }

    filterPlan(event) {
        this.filteredPlans = [];
        this.filteredPlans = utils.filterData(this.plans, 'displayName', event);
    }

    filterEmployees(event) {
        this.filteredEmployees = [];
        this.filteredEmployees = utils.filterData(this.employees, 'fullName', event);
    }
    setPlanDetails() {
        this.companies.forEach((company) => {
            if (company.id === this.newPlan.customer.id) {
                this.selectedBillingName = company;
            }
        });
        this.newPlan.campaignStartDate = new Date(this.newPlan.campaignStartDate);
        this.newPlan.campaignEndDate = new Date(this.newPlan.campaignEndDate);

        this.employees.forEach((emp) => {
            if (this.newPlan.employee.emailId === emp.emailId) {
                this.selectedEmployee = emp;
            }
        });
        this.setCompanyForEmployee();
    }

    setCompanyForEmployee() {

        this.companies = [];
        this.selectedBillingName = null;
        this.newPlan.employee = this.selectedEmployee;
        if (!this.selectedEmployee || !this.selectedEmployee.id) {
            this.subscription.push(this.companyService.get()
                .subscribe((response) => {
                    this.companies = response;
                    this.setSelectedBillingName();
                }));
        } else {
            this.subscription.push(this.companyService.getCompaniesWithSrEmail(this.selectedEmployee.emailId).subscribe((response: any) => {
                this.companies = response;
                this.setSelectedBillingName();
            }));
        }
        this.isloading = false;

    }

    clearSelectedEmployee() {
        this.selectedEmployee = null;
        this.setCompanyForEmployee();
    }




    savePlan() {
        if (this.isloading !== undefined && !this.isloading) {
            this.isloading = true;
        }
        this.newPlan.campaignStartDate = new Date(this.newPlan.campaignStartDate);
        this.newPlan.campaignEndDate = new Date(this.newPlan.campaignEndDate);
        this.newPlan.customer = this.selectedBillingName; // if company is modified
        this.eEmitSavePlan.emit(this.newPlan);
    }

    createPlan() {
        if (this.selectedPlan) {
            this.selectedPlan = this.newPlan;
        }

        if (this.validateDates()) {

            if (this.planEdit) {

                if (this.selectedEmployee) {
                    if (!this.selectedEmployee.id) {
                        this.newPlan.employee = this.editablePlan.employee;
                    } else {
                        this.newPlan.employee = this.selectedEmployee;
                    }
                }
            }
            if (this.selectedPlan === undefined || this.selectedPlan === null) {
                if (this.isloading !== undefined && !this.isloading) {
                    this.isloading = true;
                }
                if (this.selectedBillingName && this.selectedBillingName.id) {
                    this.newPlan.customer.id = this.selectedBillingName.id;
                } else {
                    this.newPlan.customer = new Customer();
                }
                if (!this.planEdit || this.copyConvertedPlan) {
                    this.eEmitCreatePlan.emit(this.newPlan);
                } else {
                    this.eEmitSavePlan.emit(this.newPlan);
                }
                } else {
                this.savePlan();
            }
        }
    }

    validateDates() {
        this.validateWithCurrentDate();
        this.validateStartDate();

        if (this.newPlan.campaignStartDate && this.newPlan.campaignEndDate) {
            this.noOfDays.nativeElement.value = this.calculationService.calculateDays(this.newPlan.campaignStartDate, this.newPlan.campaignEndDate);
            return true;
        } else {
            return false;
        }
    }

    validateStartDate() {
        if (this.newPlan.campaignEndDate !== null) {
            if (this.newPlan.campaignEndDate < this.newPlan.campaignStartDate) {
                this.newPlan.campaignEndDate = null;
            }
        }
    }

    validateWithCurrentDate() {
        if (this.newPlan.campaignStartDate) {
            if (new Date(this.calculationService.setToBeginning(this.newPlan.campaignStartDate)).valueOf() < new Date(this.calculationService.setToBeginning(new Date())).valueOf()) {
                this.newPlan.campaignStartDate = (this.planEdit) ? new Date(this.newPlan.campaignStartDate) : (this.selectedPlan ? this.selectedPlan.campaignStartDate : (this.newPlan.campaignStartDate ? this.newPlan.campaignStartDate : null));

            }
        } else {
            this.newPlan.campaignStartDate = (this.originalStartDateValue) ? new Date(this.newPlan.campaignStartDate) : null;
        }
        if (this.newPlan.campaignEndDate) {
            if (new Date(this.calculationService.setToBeginning(this.newPlan.campaignEndDate)).valueOf() < new Date(this.calculationService.setToBeginning(new Date())).valueOf()) {
                this.newPlan.campaignEndDate = (this.planEdit) ? new Date(this.newPlan.campaignEndDate) : (this.selectedPlan ? this.selectedPlan.campaignEndDate : null);
            }
        } else {
            this.newPlan.campaignEndDate = (this.originalEndDateValue) ? new Date(this.newPlan.campaignEndDate) : null;
        }
    }



    /**
     * sets plan dates on basis no of days.
     *
     * @memberof AssignToPlanDialogComponent
     */
    setDates(days) {
        if (!this.newPlan.campaignStartDate && days) {
            this.newPlan.campaignStartDate = this.currentDate;
        }
        if (days) {
            this.newPlan.campaignEndDate = this.calculationService.extendDate(this.newPlan.campaignStartDate, days - 1);
        }
        //  else {
        //     this.newPlan.campaignEndDate = new Date(this.newPlan.campaignStartDate);
        // }
    }

    checkPermission(permission) {
        return utils.checkPermission(permission);
    }

    getChangedType() {
        return ActivityLogModuleEnum.PLAN;
    }

    checkStartDateValue() {
        if (!this.newPlan.campaignStartDate) {
            setTimeout(() => {
                this.newPlan.campaignEndDate = null;
            });

        }
    }

    /**
     * clears the selected plan and its emnployee and billing name
     *
     * @memberof AssignToPlanDialogComponent
     */
    clearSelectedPlan() {
        this.selectedPlan = null;
        this.selectedEmployee = null;
        this.selectedBillingName = null;
        this.newPlan = new Plan();
        this.newPlan.displayName = '';

    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }


}
