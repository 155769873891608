export enum MediaTypeEnum {
  HOARDING = "Hoarding",
  KIOSK = "Kiosk",
  PILLAR = "Pillar",
  // WALL_WRAP = 'wall-wrap',
  GANTRY = "Gantry",
  BQS = "BQS",
  // BUS = 'bus',
  SIGNBOARD = "Sign Board",
  "PICKUP STAND" = "Pickup Stand",
  UNIPOLE = "Unipole",
  UTILITY = "Utility",
  LED = "LED",
  // GANTRY = 'Gantry',
}
