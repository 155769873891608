import { Component, OnInit } from "@angular/core";
import { InventoryCount } from "../../../modals/dashboard/inventory-count";
import { DashboardUiService } from "../../service/dashboard-ui.service";
import { PieChartConfig } from "../../../modals/charts/pieChart.config";
import { ChartArea } from "../../../modals/charts/chart-area/chart-area";
import { ChartLegend } from "../../../modals/charts/chart-legend/chart-legend";
import { Router } from "@angular/router";
import { GridPaginationEvent } from "../../../components/sib-forms/grid/grid.config";

@Component({
  selector: "sib-inventory-count-card",
  templateUrl: "./inventory-count-card.component.html",
  styleUrls: ["./inventory-count-card.component.scss"],
})
export class InventoryCountCardComponent implements OnInit {
  inventoryCount: InventoryCount;
  showCount: boolean = false;
  pieChartData: any[] = [];
  pieConfig: PieChartConfig;
  pieElementId: string;
  pieChartArea: ChartArea = new ChartArea();
  pieChartLegend: ChartLegend = new ChartLegend();
  pieChartColors: string[];
  searchEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    sortField: "updatedDate",
    sortOrder: 1,
    filters: {},
    globalFilter: "",
  };
  totalInventorySqft: number;

  constructor(
    private dashboardUiService: DashboardUiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dashboardUiService.getInventoryCount().subscribe(
      (response) => {
        // console.log("response is", response);
        this.inventoryCount = response.data;
        this.setPieChart();
      },
      (error) => {}
    );

    // Start ----> get Inventory total Sqft.
    Object.assign(this.searchEvent.filters, {
      status: {
        value: ["INACTIVE", "EXPIRED", "MIGRATED", "INVALID"],
        matchMode: "notIn",
      },
      tradedAsset: { value: false, matchMode: "binary" },
      hasChild: { value: false, matchMode: "is" },
      exposed: { value: false, matchMode: "is" },
    });
    this.dashboardUiService.getTotalInventorySqft(this.searchEvent).subscribe(
      (response) => {
        // console.log("response is", response);
        this.totalInventorySqft =
          response.data && response.data["count"] ? response.data["count"] : 0;
      },
      (error) => {}
    );
    // End
    // console.log(this.showCount);
  }

  public toggle() {
    this.showCount = this.showCount ? false : true;
  }

  /**
   * to set the header section
   *
   * @memberof SuperbillViewComponent
   */
  setPieChart() {
    this.pieChartData = [
      ["Type", "Count"],
      ["Hoarding", this.inventoryCount.hoarding],
      ["Group", this.inventoryCount.group],
      ["Kiosks", this.inventoryCount.kiosk],
    ];
    this.setPieChartArea();
    this.setPieChartLegend();
    this.setPieChartColors();
    this.pieConfig = new PieChartConfig(
      null,
      0,
      false,
      100,
      200,
      this.pieChartArea,
      this.pieChartLegend,
      this.pieChartColors,
      "#F7F7F7"
    );
    this.pieElementId = "myPieChart";
  }

  setPieChartArea() {
    this.pieChartArea.height = 100;
    this.pieChartArea.width = 200;
    this.pieChartArea.left = 0;
    this.pieChartArea.top = 0;
  }

  setPieChartLegend() {
    this.pieChartLegend.position = "right";
    this.pieChartLegend.alinment = "center";
    this.pieChartLegend.textStyle.bold = false;
    this.pieChartLegend.textStyle.italic = true;
    this.pieChartLegend.textStyle.fontSize = "11";
  }

  setPieChartColors() {
    this.pieChartColors = ["#FF6371", "#36A2EB", "#FFCE45"];
  }

  redirectToInventory() {
    this.router.navigate(["/hoardings"]);
  }
}
