<div class="card">
  <form [formGroup]="totalItemsGroup">
    <sib-grid name="totalCampaignItemgroup" #sibGrid [columns]="columns" [totalRecords]="totalRecords"
      [source]="'campaignTracker'" [showFilter]="source=='PLAN' ? true: false" [config]="gridConfig" formControlName="gridControl"
      (eFilteredValue)="onFilter($event)" (eSort)="onSort($event)" ngDefaultControl>
      <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span>
            {{rowIndex+1}}
          </span>
        </ng-template>
      </sib-grid-column>
      <sib-grid-column field="lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="inventory.lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="image" >
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <a *ngIf="rowData?.inventory?.images?.length > 0" class="link-1  after" (click)="viewImages(rowData?.inventory)">View</a>
          <div *ngIf="rowData?.inventory?.images?.length === 0">-</div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="sitesAvailableFrom">
        <ng-template
          #bodyTemplate
          let-rowData="rowData"
          let-rowIndex="rowIndex"
          let-col="col"
          let-column="column"
        >
          <span
            *ngIf="
              rowData?.pendingReason &&
                rowData?.inventory?.statusQueues &&
                rowData?.inventory?.statusQueues.length;
              else defaultTemplate
            "
          >
            <span *ngIf="getMaxCampaignEndDate(rowData?.inventory?.statusQueues)">
              {{
                getMaxCampaignEndDate(rowData?.inventory?.statusQueues)
                  | date: "ddMMMyy"
              }}
              <span *ngIf="getMaxBlockedEndDate(rowData?.inventory?.statusQueues)">
                /
              </span>
            </span>
            <span *ngIf="getMaxBlockedEndDate(rowData?.inventory?.statusQueues)">
              Blocked (till
              {{
                getMaxBlockedEndDate(rowData?.inventory?.statusQueues)
                  | date: "ddMMMyy"
              }})
            </span>
          </span>
          <ng-template #defaultTemplate> - </ng-template>
        </ng-template>
      </sib-grid-column>
    </sib-grid>
  </form>
</div>
