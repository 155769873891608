import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  CustomerUiService,
  LocalUserRoleEnum,
} from "../../customers/services/customer-ui.service";
import { AuthService } from "../../login-service/auth.service";
import { UserService } from "../../services/shared/user.service";
import { DashboardCards } from "../../shared/constants/dashboard-card.enum";
import { AppUrls } from "../../services/urls";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { PackageModulesEnum } from "../../shared/constants/package-modules-enum";

@Component({
  selector: "sib-top-section",
  templateUrl: "./top-section.component.html",
  styleUrls: ["./top-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TopSectionComponent implements OnInit {
  userRole: string;
  customizeGraphs: boolean = false;
  userProfile: any;
  emailId: string;
  dashboardCardObject = {};
  user: any = {};
  graphCustomization: boolean = false;
  capacityChartVisibility: boolean = false;
  industrialChartVisibility: boolean = false;
  employeeChartVisibility: boolean = false;
  showDashboardModule: boolean = false;
  chartVisibility: boolean = false;

  isDashboardVisible: boolean = false;
  x_tenant:string='';

  constructor(
    private customerUiService: CustomerUiService,
    private auth: AuthService,
    private userService: UserService,
    private notificationService: NotificatoinsService
  ) {}

  ngOnInit() {
    if (
      localStorage.getItem("profileSetOnLogin") &&
      JSON.parse(localStorage.getItem("profileSetOnLogin"))
    ) {
      // if there is any api call to be made on ngoninit then add it in this if clause
      // and this if condition is in 3 components 1) apptopbar 2)main.component 3) top-section.component
      // This if clause is added to avoid API calls before getting token from auth0
      this.isDashboardVisible = true;
    }
    let subscribedPackage;
    this.customerUiService.$userRole.subscribe((role) => {
      this.userRole = role;
    });

    this.userService.setDashboardData.subscribe((user) => {
      this.setDashboardCards(user);
    });

    this.auth.emitProifle.subscribe((profile) => {
      this.isDashboardVisible = true;
      this.userProfile = profile;
      this.emailId = profile.email;
      this.getUserData();
      // this.userRole = this.customerUiService.getUserRole();
    });

    this.userProfile = this.auth.getUserInfo();
    if (this.userProfile) {
      this.emailId = this.userProfile.emailId
        ? this.userProfile.emailId
        : this.userProfile.email;
      if (
        this.userProfile["app_metadata"]["authorization"][
          "permissions"
        ].indexOf("readAll") > -1
      ) {
        this.userRole = LocalUserRoleEnum["ADMIN"];
      }

      this.getUserData();
    }

    subscribedPackage = JSON.parse(localStorage.getItem("subscribedPackage"));
    if (
      subscribedPackage &&
      subscribedPackage.supportedModules.includes(
        PackageModulesEnum["DASHBOARD"]
      )
    ) {
      this.showDashboardModule = true;
    }

    if(localStorage.getItem('id')){
      this.x_tenant = localStorage.getItem('id');
    }
  }

  /**
   * @description gets user data to perform graph customization
   * @author Raveena Nathani
   * @date 2020-05-27
   * @memberof TopSectionComponent
   */
  getUserData() {
    // this.userService
    //   .get(null, "/search/byEmailId?emailId=" + this.emailId)
    //   .subscribe((user) => {
    let user = JSON.parse(localStorage.getItem("user-by-email"));
    this.setDashboardCards(user);
    // });
  }

  customizeGraph() {
    this.graphCustomization = true;
    this.capacityChartVisibility = this.user.dashBoardCardToIshidden[
      "CAPACITY_CHART"
    ];
    this.industrialChartVisibility = this.user.dashBoardCardToIshidden[
      "INDUSTRY_CHART"
    ];
    this.employeeChartVisibility = this.user.dashBoardCardToIshidden[
      "EMP_SALES_CHART"
    ];
    this.chartVisibility = this.user.dashBoardCardToIshidden[
      "PLAN_VS_CAMPAIGN_CHART"
    ];
  }

  saveChartConfiguration() {
    this.graphCustomization = false;
    this.notificationService.success(
      "Charts have been configured.",
      "Charts Customization"
    );
    Object.keys(this.user.dashBoardCardToIshidden).forEach((key) => {
      switch (key) {
        case "CAPACITY_CHART":
          this.capacityChartVisibility !==
          this.user.dashBoardCardToIshidden["CAPACITY_CHART"]
            ? this.updateDashboardConfig(key, this.capacityChartVisibility)
            : null;
          this.user.dashBoardCardToIshidden[
            "CAPACITY_CHART"
          ] = this.capacityChartVisibility;
          break;
        case "INDUSTRY_CHART":
          this.industrialChartVisibility !==
          this.user.dashBoardCardToIshidden["INDUSTRY_CHART"]
            ? this.updateDashboardConfig(key, this.industrialChartVisibility)
            : null;
          this.user.dashBoardCardToIshidden[
            "INDUSTRY_CHART"
          ] = this.industrialChartVisibility;
          break;
        case "EMP_SALES_CHART":
          this.employeeChartVisibility !==
          this.user.dashBoardCardToIshidden["EMP_SALES_CHART"]
            ? this.updateDashboardConfig(key, this.employeeChartVisibility)
            : null;
          this.user.dashBoardCardToIshidden[
            "EMP_SALES_CHART"
          ] = this.employeeChartVisibility;
          break;
        case "PLAN_VS_CAMPAIGN_CHART":
          this.chartVisibility !==
          this.user.dashBoardCardToIshidden["PLAN_VS_CAMPAIGN_CHART"]
            ? this.updateDashboardConfig(key, this.chartVisibility)
            : null;
          this.user.dashBoardCardToIshidden[
            "PLAN_VS_CAMPAIGN_CHART"
          ] = this.chartVisibility;
          break;
      }
    });
  }

  updateDashboardConfig(chart, visibility) {
    let requestBody = {
      emailId: this.emailId,
      hidden: visibility,
      dashboardCard: chart,
    };

    this.userService
      .create(requestBody, null, AppUrls.UPDATE_DASHBOARD_CONFIG)
      .subscribe();
  }

  chartVisibilityEvent(event) {
    this.chartVisibility = event;
  }

  setDashboardCards(user) {
    if (user) {
      if (Object.entries(user["dashBoardCardToIshidden"]).length === 0) {
        Object.keys(DashboardCards).forEach((key) => {
          this.dashboardCardObject[key] = true;
        });
        user["dashBoardCardToIshidden"] = this.dashboardCardObject;
        this.userService.create(user, null, AppUrls.UPDATE).subscribe();
      } else {
        Object.keys(DashboardCards).forEach((key) => {
          if (user["dashBoardCardToIshidden"][key] === undefined) {
            user["dashBoardCardToIshidden"][key] = true;
            this.userService.create(user, null, AppUrls.UPDATE).subscribe();
          }
        });
      }
      this.user = user;
    } else {
      this.user = {};
    }
  }
}
