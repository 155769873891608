import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class HoardingConfigurationService {
    minPricePercentage: Subject<number> = new Subject();

    constructor(
        private systemService: SystemService
    ) { }

    getHoardingConfiguration() {
        this.systemService.get({ module: "INVENTORY", key: 'MIN_PRICE_PERCENTAGE' }, '/byModule/key')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                this.getMinPricePercentage(configurations)
            })
    }

    getMinPricePercentage(configurations) {
        // for (let i = 0; i < configurations.length; i++) {
        //     if (configurations[i]['key'] === 'MIN_PRICE_PERCENTAGE') {
        this.minPricePercentage.next(configurations['value']);
        //     }
        // };
    }
}