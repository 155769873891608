import { Component, Input, Injectable, ViewEncapsulation } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { MenuItem } from "primeng/primeng";
import { MainComponent } from "../../main.component";
import { TitleService } from "../../../services/title.service";
import { TasksService } from "../../../services/shared/tasks.service";
import * as $ from "jquery";

@Component({
  selector: "sib-main-submenu",
  templateUrl: "./main-submenu.component.html",
  styleUrls: ["./main-submenu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("children", [
      state(
        "hiddenAnimated",
        style({
          height: "0px",
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*",
        })
      ),
      state(
        "visible",
        style({
          height: "*",
        })
      ),
      state(
        "hidden",
        style({
          height: "0px",
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
    ]),
  ],
})
@Injectable()
export class MainSubmenuComponent {
  totalCount: string;
  outOfViewPort: boolean = false;

  _item: MenuItem;
  @Input() set item(item) {
        this._item = item;
    if (item && item.length) {
      this.tasksService.get(null, "/totalCount").subscribe((response) => {
        if (response["data"] !== 0) {
          this.totalCount = response["data"].toString();
          setTimeout(
            () => {
              for (let i = 0; i < item.length; i++) {
                if (item[i].label === "Tasks") {
                  item[i].badge = this.totalCount;
                  break;
                }
              }
            },
            3000,
            this
          );
        }
      });
    }
  }

  get columns() {
    return this._item;
  }

  // @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(
    public app: MainComponent,
    public titleService: TitleService,
    public tasksService: TasksService
  ) {}

  itemClick(event: Event, item: MenuItem, index: number) {
    this.checkForVisibilityInViewport(index);
    // console.log("inside item click", item, index);
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
      // console.log("The menuitem is", item, index)
      // this.titleService.setTitle(item.label)
    }

    // if(item.items){
    //   for(let i=0; i<item.items.length; i++){
    //     this.titleService.setTitle(item.items[i].label);
    //   }
    // }

    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    //prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
      // this.titleService.setTitle(item.items[0].label)
    }

    //hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim())
        this.app.resetMenu = true;
      else this.app.resetMenu = false;

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    this.outOfViewPort = false;
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isHorizontal() || this.app.isSlim())
    ) {
      this.activeIndex = index;
    }
    this.checkForVisibilityInViewport(index);
  }

  isActive(index: number): boolean {
        return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  checkForVisibilityInViewport(index) {
    let submenu = document
      .querySelectorAll(".subMenu")
      [index].querySelector("#submenu")
      ? document.querySelectorAll(".subMenu")[index].querySelector("#submenu")
          .lastElementChild
      : null;
    if (submenu) {
      var bounding = submenu.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        //do nothing
      } else {
        this.outOfViewPort = true;
      }
    }
  }

  onMouseLeave() {
    this.outOfViewPort = false;
  }
}
