import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule, MultiSelectModule, ProgressSpinnerModule, TabViewModule, ButtonModule, StepsModule, TieredMenuModule, DialogModule, DropdownModule, CalendarModule, InputSwitchModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModules } from '../../modules/directive.modules';
import { SibChartModule } from '../../sib-chart/sib-chart.module';
import { SibGridModule } from '../../components/sib-forms/grid/grid.module';
import { SibBackModule } from "../../shared/components/sib-back/sib-back.module";
import { CommonDataTableModule } from "../../shared/components/dataTable/dataTable.module";
import { EmployeeTableChartComponent } from './employee-table-chart.component';

@NgModule({
    imports: [
        CommonModule,
        AutoCompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        TabViewModule,
        ButtonModule,
        StepsModule,
        TieredMenuModule,
        DialogModule,
        DropdownModule,
        DirectiveModules,
        SibChartModule,
        SibGridModule,
        SibBackModule,
        CommonDataTableModule,
        CalendarModule,
        InputSwitchModule
    ],
    declarations: [
        EmployeeTableChartComponent
    ],
    exports: [
        EmployeeTableChartComponent
    ],
    providers: [

    ]
})
export class EmployeeTableChartModule { }
