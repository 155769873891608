import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Alignment, EditableGridFieldTypes, GridColumn, GridConfig } from '../../../components/sib-forms/grid/grid.config';
import { ContractHoardings } from '../../../modals/contracts/contract-hoarding';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { HoardingService } from '../../../services/shared/hoarding.service';
import * as utils from '../../../helpers/utils';
import { GridNameEnum } from '../../constants/grid-name.enum';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HoardingLightEnum } from '../../constants/hoarding-light-enum';
import { DateUtil } from '../../../helpers/date.util';
import { Dropdown, SelectItem } from 'primeng/primeng';
import { HoardingCategoryEnum } from '../../constants/hoarding-category-enum';
import { HoardingStatusEnum } from '../../constants/hoarding-status-enum';

@Component({
  selector: 'sib-chart-drilldown-hoardings',
  templateUrl: './chart-drilldown-hoardings.component.html',
  styleUrls: ['./chart-drilldown-hoardings.component.scss']
})
export class ChartDrilldownHoardingsComponent implements OnInit {
  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<ContractHoardings>;
  @ViewChild('ratingdd') ratingDd: Dropdown;
  @ViewChild('categorydd') categoryDd: Dropdown;
  @ViewChild('lighttypedd') lightTypeDd: Dropdown;
  @ViewChild('tradedassetdd') tradedAssetDd: Dropdown;

  @Input() gridConfig: GridConfig<ContractHoardings> = new GridConfig<ContractHoardings>();
  @Input() totalRecords: number;

  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();

  tradedAssetDropdown: SelectItem[] = [];
  ratingDropdown: SelectItem[] = [];
  lightTypeDropdown: any[] = [];
  statusDropdown: any[] = [];

  hoardingGridColumnConfig: Array<GridColumn>;

  hmSelectedColumns: any[] = [];
  subscription: Subscription[] = [];
  email: string;
  authorized: boolean = false; // to check if the logged in user is authorized for all - currently admin and accountant

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private cd: ChangeDetectorRef,
    private notificationServcie: NotificatoinsService,
  ) { }

  ngOnInit() {
    this.hoardingGridColumnConfig = this.getHoardingGridColumnConfig();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const roles = userInfo[0].app_metadata.authorization.roles;

    this.email = utils.getLoggedInUserEmail();

    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
    this.userGridConfigService.create([GridNameEnum['CHART_HOARDINGS']]).subscribe((chartHoardingGridConfig) => {
      this.hoardingGridColumnConfig = utils.getUserConfiguredColumns(chartHoardingGridConfig[GridNameEnum['CHART_HOARDINGS']], this.hoardingGridColumnConfig);
      // this.hmColService.defaultColumns.next(this.hoardingGridColumnConfig)
      this.gridViewChildRef.ngOnInit();
    });
    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
    });

    this.statusDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingStatusEnum).forEach((status) => {
      this.statusDropdown.push({ label: HoardingStatusEnum[status], value: status });
    });

    this.ratingDropdown = [
      { label: 'All', value: 'ALL' },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
    ];

    this.tradedAssetDropdown = [
      { label: 'All', value: 'ALL' },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];

    // this.resetDropDownFilters();

    this.subscription.push(this.hmColService.selectedColumns.subscribe((hmSelectedColumns) => {
      this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
      this.detectChanges();
      setTimeout(() => {
        this.gridViewChildRef.setColumns();
      }, 0)
    }));

  }

  getHoardingGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: 'move',
        name: '',
        header: '',
        required: true,
        editable: false,
        hidden: false,
        width: '2vw',
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.CENTER,
        styleClass: 'padding-0'
      },


      {
        field: 'customId',
        name: 'customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'contract.id',
        name: 'contract',
        header: 'CID',
        width: '6vw',
        required: true,
        editable: false,
        permanent: false,
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.contract) {
            return data.contract.id;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.latitude',
        name: 'geoCoordinate.latitude',
        header: 'Latitude',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        sortable: true,
        default: false,
        width: '7vw',
        styleClass: 'word-break-all',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.latitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.longitude',
        name: 'geoCoordinate.longitude',
        header: 'Longitude',
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        width: '7vw',
        styleClass: 'word-break-all',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.longitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'district',
        name: 'district',
        header: 'District',
        required: true,
        editable: false,
        width: '7vw',
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'city',
        name: 'city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'areaName',
        name: 'area',
        header: 'Area',
        required: true,
        width: '9vw',
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        // default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        /* displayFn: (data) => {
          if (data.area) {
            return data.area.name;
          } else {
            return '-';
          }
        } */
      },
      {
        field: 'location',
        name: 'location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '20vw',
        default: true,
        sortable: true,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'width',
        name: 'width',
        header: 'W',
        width: '50px',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.NUMBER,
        displayFn: (data) => {
          if (data.width) {
            return data.width;
          } else {
            return '-';
          }
        },

      },
      {
        field: 'height',
        name: 'height',
        header: 'H',
        width: '50px',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.height) {
            return data.height;
          } else {
            return '-';
          }
        },
      },
      {
        field: 'size',
        name: 'size',
        header: 'Size',
        width: '6vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        // default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return '-';
          }
        },
        // filter: {
        //   matchMode: 'contains',
        //   placeHolder: 'Search',
        //   custom: false
        // },
      },
      {
        field: 'squareFeet',
        name: 'squareFeet',
        header: 'Total SqFt',
        width: '5.5vw',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.squareFeet) {
            return this.currencyPipe.transform(Math.ceil(data.squareFeet));
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'lightType',
        name: 'lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '4.5vw',
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'is',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'image',
        name: '',
        header: 'Photos',
        required: true,
        editable: false,
        permanent: true,
        width: '4.5vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.LEFT
      },
      {
        field: 'grossPrice',
        name: 'grossPrice',
        header: 'Gross Price',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'minimumPrice',
        name: 'minimumPrice',
        header: 'LSP',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: '5.2vw', //width:'7vw';
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'campaignStartDate',
        name: 'campaignStartDate',
        header: 'Campaign Start Date',
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: false,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'campaignEndDate',
        name: 'campaignEndDate',
        header: 'Campaign End Date',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'statusQueues',
        name: 'statusQueues',
        header: 'Status',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: '7.5vw',
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: 'statusQueues.displayName',
        name: 'displayName',
        header: 'Display Name',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '9vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'statusQueues.employee',
        name: 'employeeName',
        header: 'Employee',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: '9vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'rating',
        name: 'rating',
        header: 'Rating',
        required: true,
        editable: false,
        default: false,
        width: '5vw',
        permanent: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'is',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.rating) {
            return data.rating;
          } else {
            return '-';
          }
        },
      },
      // {
      //   field: 'tradedAsset',
      //   name: 'tradedAsset',
      //   header: 'Traded Asset',
      //   required: true,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   width: '5.5vw',
      //   sortable: true,
      //   permanent: false,
      //   type: EditableGridFieldTypes.TEXT,
      //   filter: {
      //     matchMode: 'binary',
      //     placeHolder: 'Search',
      //     custom: true
      //   },
      //   displayFn: (data) => {
      //     if (data.tradedAsset) {
      //       return 'True';
      //     } else {
      //       return 'False';
      //     }
      //   },
      // },
      // {
      //   field: 'vendorName',
      //   name: 'vendorName',
      //   header: 'Vendor Name',
      //   required: true,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   sortable: true,
      //   permanent: false,
      //   width: '9vw',
      //   type: EditableGridFieldTypes.TEXT,
      //   filter: {
      //     matchMode: 'contains',
      //     placeHolder: 'Search',
      //     custom: false
      //   },
      //   displayFn: (data) => {
      //     if (data.vendorName) {
      //       return data.vendorName;
      //     } else {
      //       return '-';
      //     }
      //   }
      // },
      {
        field: 'note',
        name: 'note',
        header: 'Note',
        required: true,
        editable: false,
        permanent: false,
        width: '8vw',
        styleClass: 'word-break-wrap',
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
    ];

    return columns;
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }
  resetAndReloadTable() {
    this.gridViewChildRef.resetAndReloadTable();
  }

  refresh() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.loadData();
      this.resetDropDownFilters();
    }
  }

  resetDropDownFilters() {

    this.ratingDd ? this.ratingDd.selectedOption = null : '';
    this.lightTypeDd ? this.lightTypeDd.selectedOption = null : '';
    this.tradedAssetDd ? this.tradedAssetDd.selectedOption = null : '';
  }

  viewImages(rowData) {
    this.eEmitViewImage.emit(rowData);
  }

  viewStatusDetail(rowData) {
    this.eEmitViewDetail.emit(rowData);
  }

  getValue(status) {
    return HoardingStatusEnum[status];
  }

  updatingUserGridConfig(event) {
    let chartHoardingGridConfig: Map<string, string> = new Map();
    chartHoardingGridConfig = utils.getGridConfigMap(event, this.hoardingGridColumnConfig);
    this.userGridConfigService.patch(utils.buildObject(chartHoardingGridConfig), null, GridNameEnum['CHART_HOARDINGS'])
      .pipe(take(1))
      .subscribe((result) => {
      });
  }

  getModifiedColumns() {
    return this.gridViewChildRef.getModifiedColumns();
  }

  rowSelect(rowData) {
    this.eRowSelection.emit(rowData);
  }

  pushData(data, value) {
    data.push(value);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  pushMultipleValues(data, values) {
    data.push(...values);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.hmColService.defaultColumns.next([]);
    this.hmColService.statuses.next(undefined);
    // this.hmColService.globalFilter.next(undefined);
  }

}
