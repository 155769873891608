export enum CampaignItemStatusEnum {
    PENDING_APPROVE = "Pending Approve",
    PENDING_PRINTING = "Pending Printing",
    PENDING_FLEX = "Pending Flex",
    PENDING_MOUNTING = "Pending Mounting",
    MOUNTED = "Mounted",
    PENDING_UNMOUNTING = "Pending Unmounting",
    EXPIRED = "Expired",
    PENDING_MONITORING = "Pending Monitoring",
    MONITORING_DONE = "Monitoring Done",
    REJECTED = "Rejected",
    UNMOUNTED = "Unmounted",
    RUNNING = "Running",
    // UNUTILIZED_CLIENT = "Unutilized", // will be uncommented when printer module is integrated
    UNUTILIZED_VENDOR = "Unutilized",
    // PENDING_RATE = "Rate Pending Approve"
}
