
import { map, concat, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { empty as observableEmpty } from 'rxjs';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GridConfig, GridColumn, EditableGridFieldTypes, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { CampaignItem } from '../../modals/campaigns/campaignItem';
import { FormGroup, FormControl } from '@angular/forms';
import { PublicPageService } from '../../services/shared/publicpage.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DateUtil } from '../../helpers/date.util';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { of as observableOf, Observable, Subscription, Subject } from 'rxjs';
import * as _ from "lodash";
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { PaginationData } from '../../modals/paginationdata';
import { PlanItem } from '../../modals/plans/planItem';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { PlanService } from '../../services/shared/plan.service';

@Component({
  selector: 'sib-totalsites',
  templateUrl: './totalsites.component.html',
  styleUrls: ['./totalsites.component.scss'],
  encapsulation: ViewEncapsulation.None
  // providers: [PublicPageService]
})
export class TotalsitesComponent implements OnInit {
  @ViewChild('sibGrid')
  gridViewChildRef: GridComponent<CampaignItem | PlanItem>;

  filteredItems: (CampaignItem | PlanItem )[]  = [];
  lightTypeDropdown: any[] = [];
  subscription: Subscription;
  filteredItemsByType:(CampaignItem | PlanItem )[] = [];

  @Input() source:string = "CAMPAIGN";

  _items: (CampaignItem | PlanItem )[] = [];
  @Input() set items(items:(CampaignItem | PlanItem)[]) {
    this._items = items;
    this.filteredItems = _.cloneDeep(this._items) as (CampaignItem | PlanItem)[];;

    this.gridViewChildRef.refresh();
  }

  get items() {
    return this._items;
  }

  gridConfig: GridConfig<CampaignItem | PlanItem> = new GridConfig<CampaignItem | PlanItem>();

  totalRecords: number;
  totalItemsGroup: FormGroup;
  id: string;
  x_tenant: string;
  @Output() eEmitTotalItems: EventEmitter<number> = new EventEmitter();
  @Output() eEmitFilteredItems: EventEmitter<CampaignItem[] | PlanItem[]> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };
  columns:Array<GridColumn>;

  Campaigncolumns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },

    {
      field: 'customId',
      name: 'customId',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '4vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'location',
      name: 'locatoin',
      header: 'Location',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '14vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '4vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'lightType',
      name: 'lightType',
      header: 'Light',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '3vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.lightType) {
          return HoardingLightEnum[data.lightType]
        } else {
          return '-';
        }
      }

    },
    {
      field: 'itemStartDate',
      name: 'itemStartDate',
      header: 'Start Date',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemStartDate) {
          return DateUtil.dategridFormatter(data.itemStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'itemEndDate',
      name: 'itemEndDate',
      header: 'End Date',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemEndDate) {
          return DateUtil.dategridFormatter(data.itemEndDate);
        } else {
          return '-';
        }
      },
    },
    // {
    //   field: 'image',
    //   name: 'image',
    //   header: 'Photos',
    //   required: true,
    //   editable: false,
    //   hidden: false,
    //   permanent: false,
    //   width: '5vw',
    //   sortable: true,
    //   type: EditableGridFieldTypes.CUSTOM,
    // },
  ]
  PlanColumns:Array<GridColumn>=[
    {
      field:'srNo',
      name:'srNo',
      header:'Sr ',
      required:true,
      width:'2vw',
      editable:false,
      hidden:false,
      default:true,
      permanent:true,
      sortable:false,
      styleClass:'sr-number',
      type:EditableGridFieldTypes.CUSTOM
    },
    {
      field: "inventory.customId",
      name: "customId",
      header: "HID",
      required: true,
      width: "8vw",
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.customId) {
          return data.inventory.customId;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.contract.id",
      name: "cid",
      header: "CID",
      required: true,
      editable: false,
      width: "6vw",
      hidden: false,
      default: false,
      permanent: false,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.contract) {
          return data.inventory.contract.id;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.geoCoordinate.latitude",
      name: "latitude",
      header: "Latitude",
      required: true,
      editable: false,
      hidden: false,
      // default: false,
      width: "7vw",
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //   matchMode: 'contains',
      //   placeHolder: 'Search',
      //   custom: false
      // },
      displayFn: (data) => {
        if (data.inventory.geoCoordinate) {
          return data.inventory.geoCoordinate.latitude;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.geoCoordinate.longitude",
      name: "longitude",
      header: "Longitude",
      required: true,
      editable: false,
      hidden: false,
      width: "7vw",
      // default: false,
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //   matchMode: 'contains',
      //   placeHolder: 'Search',
      //   custom: false
      // },
      displayFn: (data) => {
        if (data.inventory.geoCoordinate) {
          return data.inventory.geoCoordinate.longitude;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.district",
      name: "district",
      header: "District",
      required: true,
      editable: false,
      hidden: false,
      sortable: true,
      width: "7vw",
      permanent: false,
      // default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.district) {
          return data.inventory.district;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.city",
      name: "city",
      header: "City",
      required: true,
      editable: false,
      hidden: false,
      width: "7vw",
      sortable: true,
      permanent: true,
      default: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.city) {
          return data.inventory.city;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.areaName",
      name: "area",
      header: "Area",
      required: true,
      width: "7vw",
      editable: false,
      hidden: false,
      sortable: true,
      permanent: false,
      // default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.areaName) {
          return data.inventory.areaName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.location",
      name: "location",
      header: "Location",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      styleClass: "word-break-wrap",
      permanent: true,
      width: "20vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.location) {
          return data.inventory.location;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.size",
      name: "size",
      header: "Size",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "5vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.size) {
          return data.inventory.size;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.width",
      name: "width",
      header: "W",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "50px",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //   matchMode: 'contains',
      //   placeHolder: 'Search',
      //   custom: false
      // },
      displayFn: (data) => {
        if (data.inventory.width) {
          return new CurrencyHelperPipe().transform(data.inventory.width);
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.height",
      name: "height",
      header: "H",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "50px",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //   matchMode: 'contains',
      //   placeHolder: 'Search',
      //   custom: false
      // },
      displayFn: (data) => {
        if (data.inventory.height) {
          return new CurrencyHelperPipe().transform(data.inventory.height);
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.squareFeet",
      name: "squareFeet",
      header: "SQFT",
      required: true,
      editable: false,
      hidden: false,
      permanent: false,
      // default: false,
      width: "5vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "equals",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.squareFeet) {
          return new CurrencyHelperPipe().transform(data.inventory.squareFeet);
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.lightType",
      name: "lightType",
      header: "Light",
      required: true,
      editable: false,
      width: "6vw",
      hidden: false,
      default: true,
      sortable: true,
      permanent: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: true,
      },
      displayFn: (data) => {
        if (data.inventory.lightType) {
          return HoardingLightEnum[data.inventory.lightType];
        } else {
          return "-";
        }
      },
    },
    {
      field: "sitesAvailableFrom",
      name: "sitesAvailableFrom",
      header: "Available From",
      required: true,
      hidden:false,
      width: "7vw",
      editable: false,
      default: true,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "inventory.grossPrice",
      name: "grossPrice",
      header: "Gross Price",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "7vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "equals",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.grossPrice) {
          return new CurrencyHelperPipe().transform(
            utils.parseValueToDecimalTwoPlaces(data.inventory.grossPrice)
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "rate",
      name: "rate",
      header: "Monthly Rate",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      width: "8vw",
      sortable: true,
      type: EditableGridFieldTypes.NUMBER,
      filter: {
        matchMode: "equals",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.rate) {
          return new CurrencyHelperPipe().transform(
            utils.parseValueToDecimalTwoPlaces(data.rate)
          );
        } else {
          return 0;
        }
      },
    },
    {
      field: "cost",
      name: "cost",
      header: "Cost",
      required: true,
      editable: false,
      hidden: false,
      width: "7vw",
      permanent: false,
      default: true,
      sortable: true,
      type: EditableGridFieldTypes.NUMBER,
      filter: {
        matchMode: "equals",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.cost) {
          return new CurrencyHelperPipe().transform(
            utils.parseValueToDecimalTwoPlaces(data.cost)
          );
        } else {
          return 0;
        }
      },
    },
    {
      field: "inventory.trafficFrom",
      name: "trafficFrom",
      header: "Traffic From",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "5vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.trafficFrom) {
          return data.inventory.trafficFrom;
        } else {
          return "-";
        }
      },
    },
    {
      field: "inventory.trafficTo",
      name: "trafficTo",
      header: "Traffic To",
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: false,
      width: "5vw",
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.inventory.trafficTo) {
          return data.inventory.trafficTo;
        } else {
          return "-";
        }
      },
    },
    {
      field: "image",
      name: "image",
      header: "Photos",
      required: true,
      editable: false,
      hidden: false,
      // default: false,
      width: "4vw",
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },
  ]

  getMaxCampaignEndDate(statusQueue) {
    if (statusQueue && statusQueue.length) {
      const queues = statusQueue.filter((item) => item.module === "Campaign");
      if (queues && queues.length) {
        let date: Date = new Date(queues[0].campaignEndDate);
        queues.forEach((queue) => {
          if (
            new Date(queue.campaignEndDate).valueOf() > new Date(date).valueOf()
          ) {
            date = new Date(queue.campaignEndDate);
          }
        });
        date.setDate(date.getDate() + 1);
        return new Date(date);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getMaxBlockedEndDate(statusQueue) {
    if (statusQueue && statusQueue.length) {
      const queues = statusQueue.filter((item) => item.module === "Plan");
      if (queues && queues.length) {
        let date: Date = new Date(queues[0].blockedEndDate);
        queues.forEach((queue) => {
          if (
            new Date(queue.blockedEndDate).valueOf() > new Date(date).valueOf()
          ) {
            date = new Date(queue.blockedEndDate);
          }
        });
        return new Date(date);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  constructor(
    private _route: ActivatedRoute,
    private campaignService: CampaignService,
    private planService:PlanService
  ) {
   
  }

  ngOnInit() {
    if(this.source=='CAMPAIGN'){
    this.subscription = this.campaignService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        this.filteredItemsByType = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.items.forEach((item:any) => {
            if (item.customId.toLowerCase().includes(searchTerm) || item.district.toLowerCase().includes(searchTerm) || item.city.toLowerCase().includes(searchTerm) || item.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.items) as (CampaignItem | PlanItem)[];;
        }

        this.filteredItemsByType = _.cloneDeep(this.filteredItems) as (CampaignItem | PlanItem)[];;
        this.eEmitTotalItems.emit(this.filteredItems.length);
        this.gridViewChildRef.refresh();

      })
    }

    else if(this.source=='PLAN'){
      this.subscription = this.planService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        this.filteredItemsByType = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.items.forEach((item:any) => {
            if (item.inventory.customId.toLowerCase().includes(searchTerm) || item.inventory.district.toLowerCase().includes(searchTerm) || item.inventory.city.toLowerCase().includes(searchTerm) || item.inventory.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.items) as (CampaignItem | PlanItem)[];;
        }

        this.filteredItemsByType = _.cloneDeep(this.filteredItems) as (CampaignItem | PlanItem)[];;
        this.eEmitTotalItems.emit(this.filteredItems.length);
        this.gridViewChildRef.refresh();

      })
    }
    if(this.source=="CAMPAIGN"){
      this.columns = this.Campaigncolumns;
    }
    else if(this.source=='PLAN'){
      this.columns = this.PlanColumns;
    }
    this.totalItemsGroup = new FormGroup({
      gridControl: new FormControl()
    });
    this.setLightDropdown();
    this.setGridConfigObject();

  }

  setGridConfigObject() {
    if(this.source=='CAMPAIGN'){
    this.gridConfig.getDefaultModel = () => new CampaignItem();
    this.gridConfig.model = CampaignItem;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = true;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      this.totalRecords = this.filteredItems.length;
      return observableOf(this.filteredItems);
    }
  }
  else if(this.source=='PLAN'){
    this.gridConfig.getDefaultModel = () => new PlanItem();
    this.gridConfig.model = PlanItem;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = true;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      this.totalRecords = this.filteredItems.length;
      return observableOf(this.filteredItems);
    }
  }
  }

  onFilter(event) {
    this.filteredItemsByType = event;
    this.eEmitFilteredItems.emit(event);
  }

  getCustomIds() {
    this.items = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignItems);
    const customIds: string[] = [];
    if (this.filteredItemsByType.length) {
      this.filteredItemsByType.forEach((item : CampaignItem | PlanItem) => {
        if(item instanceof CampaignItem){
          customIds.push(item.customId);
        }
      });
    } else {
      this.items.forEach((item : CampaignItem | PlanItem) => {
        if(item instanceof CampaignItem){
          customIds.push(item.customId);
        }
      });
    }
    return customIds;
  }


  /**
   * to get the ids of items
   *
   * @returns
   * @memberof TotalsitesComponent
   */
  getIds() {
    this.items = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignItems);
    const ids: string[] = [];
    if (this.filteredItemsByType.length && this.filteredItemsByType.length !== this.items.length) {
      this.filteredItemsByType.forEach((item:any) => {
        if(this.source=='PLAN'){
          ids.push(item.inventory.id);
        }
        else{
        ids.push(item.id);
        }
      });
    } else {
      this.items.forEach((item:any) => {
        if(this.source=='PLAN'){
          ids.push(item.inventory.id);
        }
        else{
          ids.push(item.id);
        }
      });
    }
    return ids;
  }

  setLightDropdown() {
    this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
  }

  createMetaSortData() {
    if(this.source !== 'PLAN'){
      return [{ field: 'district', order: 1 }, { field: 'city', order: 1 }, { field: 'area.name', order: 1 },{field:'customId',order:1}];
    }
    else{
      return [{field:'inventory.district',order:1},{field:'inventory.city',order:1},{field:'inventory.area.name',order:1},{field:'inventory.customId',order:1}]
    }
  }

  onSort(event) {
    if (this.filteredItemsByType && this.filteredItemsByType.length) {
      this.items = _.cloneDeep(this.filteredItemsByType);
    } else {
      this.items = this.gridViewChildRef.getDataTableInstance()['_value'];
    }
    // this.eEmitFilteredCampaignItems.emit(this.campaignItems);
    this.searchEvent.multiSortMetas = Object.assign(this.searchEvent.multiSortMetas, event['multisortmeta']);
  }

  getCampaignSites() {
    this.items = this.gridViewChildRef.getDataTableInstance()['_value'];
    if (this.filteredItemsByType.length && this.filteredItemsByType.length !== this.items.length) {
      return this.filteredItemsByType;
    } else {
      return this.items;
    }
  }


  /**
 * emites images to public page component
 *
 * @param {*} rowData
 * @memberof MountedsitesComponent
 */
  viewImages(rowData) {
    this.eEmitImages.emit(rowData);
  }
}
