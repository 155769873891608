<div class="expenses-breakup-container margin-top-18">

  <sib-grid #sibGrid [columns]="gridColumnConfig" [config]="gridConfig" [totalRecords]="totalInventoryRecords"
    ngDefaultControl>

    <sib-grid-column field="srNo">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <span>
          {{rowIndex+1}}
        </span>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="customId">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        {{rowData.customId}}
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="lightType">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
          appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column>
    <sib-grid-column field="expenseType">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #expenseTypedd [options]="expensesTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
          appendTo="body" placeholder="Expenses"
          (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column>
    <sib-grid-column field="mediaType">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #mediatypedd [options]="mediaTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
          appendTo="body" placeholder="Media" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column>


  </sib-grid>
</div>