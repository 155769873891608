import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { BillingUIService } from '../../../billings/services/billingUI.service';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { GridColumn, GridConfig, SelectionMode, GridPaginationEvent, EditableGridFieldTypes, Alignment } from '../../../components/sib-forms/grid/grid.config';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, EMPTY as empty, Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { CreditNote } from '../../../modals/billings/credit-note';
import { DateUtil } from '../../../helpers/date.util';
import { InvoiceStatus } from '../../constants/invoice-status-enum';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import * as _ from "lodash";
import * as utils from '../../../helpers/utils';
import { GridNameEnum } from '../../constants/grid-name.enum';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { SelectItem, OverlayPanel } from 'primeng/primeng';
import { StatusVisualizationNode } from '../../../modals/billings/status-visualization-node';
import { map } from 'rxjs/operators';
import { AppUrls } from '../../../services/urls';
import { PaginationData } from '../../../modals/paginationdata';
import { InvoiceCharEnum } from '../../../modals/billings/invoice-char-enum';
import { SIBConstants } from '../../constants/SIBConstant';
import { CreditNotesService } from '../../../services/shared/credit-note.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { InvoiceService } from '../../../services/shared/invoice.service';
import { CreditNotesTaskCardsService } from '../../../services/shared/credit-notes-task-cards.service';
import { FirebaseService } from '../../../services/shared/firebase.service';
import { CommonService } from '../../../services/shared/common.service';
import { CustomerUiService } from '../../../customers/services/customer-ui.service';
import * as $ from 'jquery';
import { PrintConfig } from '../../../modals/billings/print-config';
import { ErrorUtil } from '../../../helpers/error.utils';
import { NotificationsMessages } from '../../../services/shared/notifications-messages';

@Component({
  selector: 'sib-credit-notes-tasks',
  templateUrl: './credit-notes-tasks.component.html',
  styleUrls: ['./credit-notes-tasks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreditNotesTasksComponent implements OnInit {

  _showEmployee: boolean;
  @Input() set showEmployee(showEmployee) {
    this._showEmployee = showEmployee;
    this.setColumns();
  }

  get showEmployee() {
    return this._showEmployee;
  }

  _campaignId: string = null;
  @Input() set campaignId(campaignId) {
    this._campaignId = campaignId;
  }

  get campaignId() {
    return this._campaignId;
  }

  _customFilter: any;
  @Input() set customFilter(customFilter) {
    if (customFilter !== undefined && customFilter !== null) {
      this._customFilter = customFilter;
      this.customGridFilter = {};
      this.setPaginationFilter();
    }
  }

  get customFilter() {
    return this._customFilter;
  }

  @ViewChild('creditNoteTaskGrid')
  creditNoteTaskGridViewChildRef: GridComponent<CreditNote>;

  @ViewChild('printMenu')
  printOverlay: OverlayPanel;

  @ViewChild('op')
  overlay: OverlayPanel;

  @Output() eEmitTotalPendingCreditNoteTaskRecords: EventEmitter<any> = new EventEmitter();
  @Output() eEmitActionResult: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  creditNoteTaskGroup: FormGroup;
  statusVisualizationNodes: StatusVisualizationNode[] = [];
  rowData: any;
  rowIndex: number;
  currentDate = new Date();

  // to set the values of visualization chars and titles
  pendingAt: any[] = [];
  charAt: any[] = [];
  charTitleAt: any[] = [];

  // to check in which flow the invoice is present
  generateFlowStatus = [];
  editFlowStatus = [];
  cancelFlowStatus = [];

  // to check with whom the invoice is pending
  srPendingStatus = [];
  adminPendingStatus = [];
  accountantPendingStatus = [];
  flowCompleteWithApprovalStatus = [];
  flowCompleteWithRejectionStatus = [];
  invoicePrintedCheckboxVisibleStatus: any[] = [];
  invoiceTallyEnteredCheckboxVisibleStatus: any[] = [];
  creditNotePendingStatus = [];

  selectedInvoiceFormat: any;
  preview = false; // for printing
  userRole: string;

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    multiSortMetas: []
  };
  creditNotes: CreditNote[] = [];
  rowsPerPage: number;
  totalRecords = 100;
  customGridFilterApplied = false; // for dropdown filters
  customGridFilter: any;
  paginationFilter: any;

  cnStatusDropdown: any[] = [];
  printDropdown: SelectItem[] = [];
  tallyEntryDropdown: SelectItem[] = [];
  invoiceStatusDropdown: any[] = [];

  invoicePrinted: Map<string, boolean> = new Map();
  invoiceTallyEntered: Map<string, boolean> = new Map();

  safePdfUrl: SafeResourceUrl;
  safePdfName: string;
  isDuplicate = false;
  isWithoutDetail = false; // for with/without detail switch
  isWithoutLetterHead = false; // for with/without letterhead switch

  buttonConfig: GridActionButtonConfig;
  gridConfig: GridConfig<CreditNote> = new GridConfig<CreditNote>();

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    // {
    //   field: 'invoiceId',
    //   name: 'invoiceId',
    //   header: 'Invoice',
    //   required: true,
    //   width: '8vw',
    //   editable: false,
    //   default: true,
    //   hidden: false,
    //   permanent: true,
    //   sortable: true,
    //   styleClass: 'word-break-wrap ',
    //   type: EditableGridFieldTypes.CUSTOM,
    //   filter: {
    //     matchMode: 'contains',
    //     placeHolder: 'Search',
    //     custom: false,
    //   },
    // },
    {
      field: 'invoiceId',
      name: 'invoiceId',
      header: 'Credit Note',
      required: true,
      width: '8vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'invoiceReferenceId',
      name: 'invoiceReferenceId',
      header: 'Invoice',
      required: true,
      width: '8vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    // {
    //   field: 'campaign.customer.company',
    //   name: 'campaign.customer.company',
    //   header: 'Company',
    //   required: true,
    //   width: '7vw',
    //   editable: false,
    //   default: false,
    //   hidden: false,
    //   permanent: false,
    //   sortable: true,
    //   styleClass: 'word-break-wrap ',
    //   type: EditableGridFieldTypes.TEXT,
    //   filter: {
    //     matchMode: 'contains',
    //     placeHolder: 'Search',
    //     custom: false,
    //   },
    //   displayFn: (data) => {
    //     if (data.campaign.customer.company) {
    //       return data.campaign.customer.company;
    //     } else {
    //       return '-';
    //     }
    //   },
    // },
    {
      field: 'campaign.customer.billingName',
      name: 'campaign.customer.billingName',
      header: 'Billing Name',
      required: true,
      width: '7vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.customer.billingName) {
          return data.campaign.customer.billingName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaign.displayName',
      name: 'campaign.displayName',
      header: 'Display Name',
      required: true,
      width: '8vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.displayName) {
          return data.campaign.displayName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaign.employee.fullName',
      name: 'employee.fullName',
      header: 'Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      // hidden: !this.showEmployee,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.employee) {
          return data.campaign.employee.fullName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billStartDate',
      name: 'billStartDate',
      header: 'Bill Start Date',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billStartDate) {
          return DateUtil.dategridFormatter(data.billStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billEndDate',
      name: 'billEndDate',
      header: 'Bill End Date',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billEndDate) {
          return DateUtil.dategridFormatter(data.billEndDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billGeneratedDate',
      name: 'billGeneratedDate',
      header: 'Bill Generated Date',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billGeneratedDate) {
          return DateUtil.dategridFormatter(data.billGeneratedDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'status',
      name: 'status',
      header: 'Status',
      required: true,
      width: '12vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'min-width-10',
      type: EditableGridFieldTypes.CUSTOM,
      // align: Alignment.LEFT,
      filter: {
        matchMode: 'is',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.status) {
          return InvoiceStatus[data.status];
        } else {
          return '-';
        }
      },
    },
    {
      field: 'rentAmount',
      name: 'rentAmount',
      header: 'Rent Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.rentAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.rentAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'printAmount',
      name: 'printAmount',
      header: 'Print Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.printAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.printAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'mountAmount',
      name: 'mountAmount',
      header: 'Mount Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.mountAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.mountAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'totalAmount',
      name: 'totalAmount',
      header: 'Bill Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.totalAmount !== null) {
          return this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.totalAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'lspDiff',
      name: 'lspDiff',
      header: 'LSP Difference',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.lspDiff !== null) {
          return this.currencyPipe.transform(data.lspDiff);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaignDiff',
      name: 'campaignDiff',
      header: 'Campaign Difference',
      required: true,
      width: '7vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.campaignDiff !== null) {
          return this.currencyPipe.transform(data.campaignDiff);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'printed',
      name: 'printed',
      header: 'Printed',
      required: true,
      width: '5vw',
      editable: false,
      default: true,
      // hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.printed !== null && data.printed !== undefined) {
          return data.printed;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'markAsPrinted',
      name: 'markAsPrinted',
      header: 'Mark Print',
      required: true,
      width: '5vw',
      editable: false,
      default: true,
      // hidden: false,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
    },
    {
      field: 'printInvoice',
      name: 'printInvoice',
      header: 'Print',
      required: true,
      width: '4vw',
      editable: false,
      default: true,
      // hidden: false,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
    },
    {
      field: 'tallyEntered',
      name: 'tallyEntered',
      header: 'Tally Entry',
      required: true,
      width: '5vw',
      editable: false,
      default: true,
      // hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.tallyEntered !== null && data.tallyEntered !== undefined) {
          return data.tallyEntered;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'markAsTallyEntered',
      name: 'markAsTallyEntered',
      header: 'Mark Tally',
      required: true,
      width: '5vw',
      editable: false,
      default: true,
      // hidden: false,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
    },
    {
      field: 'approveReject',
      name: 'approveReject',
      header: 'Action',
      permanent: true,
      sortable: false,
      default: true,
      hidden: false,
      required: true,
      width: '6vw',
      editable: false,
      type: EditableGridFieldTypes.CUSTOM,
      // align: Alignment.CENTER,
    },
    {
      field: 'purchaseOrders',
      name: 'purchaseOrders',
      header: 'PO',
      required: true,
      width: '4vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM
    },
  ];

  constructor(
    private notificationServcie: NotificatoinsService,
    private billingUIService: BillingUIService,
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private creditNotesTaskService: CreditNotesTaskCardsService,
    private firebaseService: FirebaseService,
    private sanitizer: DomSanitizer,
    private creditNoteService: CreditNotesService,
    private commonService: CommonService,
    private customerUiService: CustomerUiService,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.userRole = this.customerUiService.getUserRole();
    this.setUserGridConfig();
    this.setFlows();
    this.setFilterDropdowns();
    this.setFormGroupObjects();
    this.setGridConfigObject();
  }

  setUserGridConfig() {
    this.userGridConfigService.create([GridNameEnum['INVOICE']])
      .subscribe((creditNoteTaskListGridConfig) => {
        this.columns = utils.getUserConfiguredColumns(creditNoteTaskListGridConfig[GridNameEnum['INVOICE']], this.columns);
        this.creditNoteTaskGridViewChildRef.setAllColumns();
      });
  }

  setFormGroupObjects() {
    this.creditNoteTaskGroup = new FormGroup({
      creditNoteTaskSiteGridControl: new FormControl()
    });
  }

  setInvoicePrinted(inv) {
    // this.creditNotes.forEach((inv) => {
    (InvoiceCharEnum[inv.printed] === InvoiceCharEnum.F || InvoiceCharEnum[inv.printed] === InvoiceCharEnum.N) ? this.invoicePrinted.set(inv.id, false) : this.invoicePrinted.set(inv.id, true);
    // });
  }

  setInvoiceTallyEntered(inv) {
    // this.creditNotes.forEach((inv) => {
    (InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.F || InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.N) ? this.invoiceTallyEntered.set(inv.id, false) : this.invoiceTallyEntered.set(inv.id, true);
    // });
  }

  setFilterDropdowns() {
    this.setPrintDropdown();
    this.setTallyEntryDropdown();
  }

  setPrintDropdown() {
    this.printDropdown = [
      { label: "All", value: "ALL" },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];
  }

  onPrintedFilterChange(value, field, matchMode) {
    this.setFilterValues(value, field, matchMode);
  }

  setTallyEntryDropdown() {
    this.tallyEntryDropdown = [
      { label: "All", value: "ALL" },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];
  }

  onTallyEnteredFilterChange(value, field, matchMode) {
    this.setFilterValues(value, field, matchMode);
  }

  setFilterValues(value, field, matchMode) {
    this.customGridFilterApplied = true;
    switch (value) {
      case 'ALL': {
        value = ["ALL"];
        matchMode = 'in';
        break;
      }
      case true: {
        value = ['T'];
        matchMode = 'in';
        break;
      }
      case false: {
        value = ['N', 'F'];
        matchMode = 'in';
        break;
      }
    }
    this.setCustomGridFilter(value, field, matchMode);
  }

  setCustomGridFilter(value, field, matchMode) {
    switch (field) {
      case "printed": {
        this.customGridFilter = { "printed": { value: value, matchMode: matchMode } };
        break;
      }
      case "tallyEntered": {
        this.customGridFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
        break;
      }
      case "markAsPrinted": {
        this.customGridFilter = { "printed": { value: value, matchMode: matchMode } };
        break;
      }
      case "markAsTallyEntered": {
        this.customGridFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
      }
    }
    this.creditNoteTaskGridViewChildRef.refresh();
  }

  setFlows() {
    this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
    this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
    this.cancelFlowStatus = ['ADMIN_CANCEL_PENDING', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'ADMIN_FULL_CN_PENDING', 'PARTIAL_CN_APPLIED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
    this.srPendingStatus = ['EDIT_PENDING'];
    this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ADMIN_CANCEL_PENDING', 'ADMIN_FULL_CN_PENDING'];
    this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];
    this.flowCompleteWithApprovalStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_EDIT_APPROVE', 'BILL_GENERATED', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
    this.flowCompleteWithRejectionStatus = ['ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'FULL_CN_REJECTED'];
    this.invoicePrintedCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'PARTIAL_CN_APPROVED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED'];
    this.invoiceTallyEnteredCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'PARTIAL_CN_APPROVED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED'];
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new CreditNote();
    this.gridConfig.model = CreditNote;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showColorCode = true;


    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
      // Object.assign(paginationEvent.filters, { "status": { value: 'ADMIN_FULL_CN_PENDING', matchMode: 'contains' } });

      this.searchEvent = {
        // filters: paginationEvent.filters,
        // globalFilter: paginationEvent.globalFilter,
        // multiSortMetas: paginationEvent.multiSortMetas,
        sortField: 'billGeneratedDate',
        sortOrder: 1
      };

      this.setPaginationEvent(paginationEvent, this.searchEvent);
      return this.creditNotesTaskService.create(paginationEvent, paginationEvent, AppUrls.CREDIT_NOTES_TASKS).pipe(
        map((response) => {

          this.creditNotes = response['data']['content'];
          this.rowsPerPage = response["data"].size;
          this.invoicePrinted = new Map();
          this.invoiceTallyEntered = new Map();
          this.setCreditNotes();
          // this.setInvoicePrinted();
          // this.setInvoiceTallyEntered();
          // this.setPendingAt();
          // this.setCharAt();
          this.setStatusVisualizationNode();
          this.totalRecords = response['data'].totalElements;
          this.eEmitTotalPendingCreditNoteTaskRecords.emit(this.totalRecords);
          return response['data']['content'];
        }));
    };
  }

  setCreditNotes() {
    this.pendingAt = [];
    this.charAt = [];
    this.charTitleAt = [];
    this.creditNotes.forEach((inv, index) => {
      this.setInvoicePrinted(inv);
      this.setInvoiceTallyEntered(inv);
      this.setPendingAt(inv, index);
      this.setCharAt(inv, index);
    });
  }

  setPaginationEvent(paginationEvent, paginationData) {

    if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
      Object.assign(paginationEvent, paginationData);
    }

    if (paginationEvent.filters === undefined) {
      paginationEvent.filters = {};
    }
    delete paginationEvent.filters.status;
    delete paginationEvent.filters.tallyEntered;
    delete paginationEvent.filters.printed;
    if (this.customFilter !== undefined) {
      Object.assign(paginationEvent.filters, this.paginationFilter);
    } else {

    }
  }

  setPendingAt(invoice, index) {
    // this.pendingAt = [];
    // this.creditNotes.forEach((invoice, index) => {
    this.setBackground(invoice.status, index);
    // });
  }

  setCharAt(invoice, index) {
    // this.charAt = [];
    // this.charTitleAt = [];
    // this.creditNotes.forEach((invoice, index) => {
    this.setChar(invoice.status, index);
    // });
  }

  setBackground(status, index) {
    if (this.srPendingStatus.includes(status)) {
      this.pendingAt[index] = "SR";
    }
    if (this.adminPendingStatus.includes(status)) {
      this.pendingAt[index] = "ADM";
    }
    if (this.accountantPendingStatus.includes(status)) {
      this.pendingAt[index] = "ACC";
    }
    if (this.flowCompleteWithApprovalStatus.includes(status)) {
      this.pendingAt[index] = "APP";
    }
    if (this.flowCompleteWithRejectionStatus.includes(status)) {
      this.pendingAt[index] = "REJ";
    }
  }

  setChar(status, index) {
    if (this.generateFlowStatus.includes(status)) {
      this.charAt[index] = "G";
      this.charTitleAt[index] = SIBConstants.STATUS_GEN_ICON_TOOLTIP;
    }
    if (this.editFlowStatus.includes(status)) {
      this.charAt[index] = "E";
      this.charTitleAt[index] = SIBConstants.STATUS_MOD_ICON_TOOLTIP;
    }
    if (this.cancelFlowStatus.includes(status)) {
      this.charAt[index] = "C";
      this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
    }
  }

  getClassForCharacter(index) {
    switch (this.charAt[index]) {
      case 'G': {
        return "generate";
      }
      case 'E': {
        return "edit";
      }
      case 'C': {
        return "cancel";
      }
    }
  }

  setStatusVisualizationNode() {
    this.statusVisualizationNodes = [];
    this.statusVisualizationNodes = [
      { header: "SR", title: "Sales Representative" },
      // { header: "ACC", title: "Accountant" },
      { header: "ADM", title: "Admin" },
      { header: "CMP", title: " " }, // for APP or REJ
    ];
  }


  setColumns() {
    this.columns.filter((col) => {
      switch (col.field) {
        case 'campaign.employee.fullName': {
          col.permanent = !this.showEmployee;
          col.hidden = !this.showEmployee;
          break;
        }
        case 'printed':
        case 'markAsPrinted':
        case 'printInvoice': {
          col.hidden = (this.customFilter && this.customFilter.printed === "F") ? false : true;
          col.default = true;
          col.permanent = (col.hidden) ? true : false;
          break;
        }
        case 'tallyEntered':
        case 'markAsTallyEntered': {
          col.hidden = (this.customFilter && this.customFilter.tallyEntered === "F") ? false : true;
          col.default = true;
          col.permanent = (col.hidden) ? true : false;
          break;
        }
        case 'approveReject': {
          col.hidden = (this.customFilter && (this.customFilter.tallyEntered === "F" || this.customFilter.printed === "F")) ? true : false;
          col.default = true;
          col.permanent = (col.hidden) ? true : false;
          break;
        }
        case 'invoiceReferenceId': {
          col.hidden = (this.customFilter && (this.customFilter.tallyEntered === "F" || this.customFilter.printed === "F")) ? false : true;
          col.default = true;
          col.permanent = (col.hidden) ? true : false;
          break;
        }
        case 'campaign.displayName': {
          col.hidden = utils.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
      }
      return col;
    });
    if (this.creditNoteTaskGridViewChildRef) {
      this.creditNoteTaskGridViewChildRef.setAllColumns();
    }
  }

  updatingUserGridConfig(columnArray) {
    let creditNoteTaskListGridConfig: Map<string, string> = new Map();
    creditNoteTaskListGridConfig = utils.getGridConfigMap(columnArray, this.columns);
    this.userGridConfigService.patch(utils.buildObject(creditNoteTaskListGridConfig), null, GridNameEnum['INVOICE'])
      .subscribe((result) => {
      });
  }

  getEncodedURI(id) {
    return encodeURIComponent(id);
  }

  parseValueToDecimal(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getAbsoluteValue(value) {
    return Number(Math.abs(Number(value)));
  }

  getDecimalNum(target, decimal) {
    return Number(Math.fround(target).toFixed(decimal));
  }

  approveFullCn(rowData) {
    this.isLoading = true;
    // console.log(rowData)
    this.billingUIService.approveCNRequest(rowData).subscribe(
      (response) => {
        if (response) {
          this.notificationServcie.success("CN Request Approved Successfully", "CN Request Approval");
          this.eEmitActionResult.emit(true);
          this.refreshGrid();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        this.refreshGrid();
        this.isLoading = false;
      }
    );
  }

  rejectFullCn(rowData) {
    this.isLoading = true;
    // console.log(rowData);
    this.billingUIService.rejectCNRequest(rowData).subscribe(
      (response) => {
        if (response) {
          this.notificationServcie.success("CN Request Rejected Successfully", "CN Request Rejection");
          this.eEmitActionResult.emit(true);
          this.refreshGrid();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        this.refreshGrid();
        this.isLoading = false;
      }
    );
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.creditNoteTaskGridViewChildRef.refresh();
    }
  }

  setPaginationFilter() {
    this.setColumns();
    this.paginationFilter = {};
    if (this.customFilter.filters.length > 0) {
      this.paginationFilter = { 'status': { value: this._customFilter.filters, matchMode: 'in' } };
    } else if (this.customFilter.printed === "F") {
      this.paginationFilter = { "printed": { value: [this._customFilter.printed], matchMode: 'in' }, 'status': { value: ['FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'], matchMode: 'in' } };
    } else if (this.customFilter.tallyEntered === "F") {
      this.paginationFilter = { 'tallyEntered': { value: [this._customFilter.tallyEntered], matchMode: 'in' }, 'status': { value: ['FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'], matchMode: 'in' } };
    }
    if (this.customGridFilterApplied) {
      Object.assign(this.paginationFilter, this.customGridFilter);
    }
    this.creditNoteTaskGridViewChildRef.refresh();
  }

  checkPrintedStatus(status) {
    if (this.invoicePrintedCheckboxVisibleStatus.includes(status)) {
      return true;
    } else {
      return false;
    }
  }

  checkTallyEnteredStatus(status) {
    if (this.invoiceTallyEnteredCheckboxVisibleStatus.includes(status)) {
      return true;
    } else {
      return false;
    }
  }

  onMarkAsPrintedChange(row, event) {
    this.isLoading = true;
    this.creditNoteService.markCNAsPrinted((event) ? 'T' : 'F', row.id).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          event ? this.notificationServcie.success("CN Marked As Printed", "CN Marked As Printed Successfully") : this.notificationServcie.success("CN Unmarked As Printed", "CN Unmarked As Printed Successfully");
          this.eEmitActionResult.emit(true);
          this.creditNoteTaskGridViewChildRef.refresh();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  onMarkAsTallyEnteredChange(row, event) {
    this.isLoading = true;
    this.creditNoteService.markCNAsTallyEntered((event) ? 'T' : 'F', row.id, row.id).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          event ? this.notificationServcie.success("CN Marked As Tally Entered", "CN Marked As Tally Entered Successfully") : this.notificationServcie.success("CN Unmarked As Tally Entered", "CN Unmarked As Tally Entered Successfully");
          this.eEmitActionResult.emit(true);
          this.creditNoteTaskGridViewChildRef.refresh();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  togglePrintMenu(event, rowData, rowIndex) {
    this.rowData = rowData;
    this.selectedInvoiceFormat = rowData.invoiceFormat;
    this.rowIndex = rowIndex;
    this.printOverlay.toggle(event);
  }

  setSelectedInvoiceFormat(event) {
    this.selectedInvoiceFormat = event;
    this.rowData.invoiceFormat = event;
  }

  onPrintTypeChange(event) {
    this.rowData.printConfiguration.original = event;
  }

  onPrintLetterHeadChange(event) {
    this.rowData.printConfiguration.withLetterHead = event;
  }

  savePrintingInstructions(printConfig: PrintConfig) {
    this.rowData.printConfiguration = printConfig;
    this.printOverlay.hide();
    this.commonService.saveBillingPrintConfig(this.rowData.printConfiguration, this.selectedInvoiceFormat, this.rowData.id, SIBConstants.CREDIT_NOTE_PARAM)
      .subscribe(
        (response) => {
          if (response) {
            this.notificationServcie.success('Configuration Saved Successfully', 'Configuration Saved');
          }
        },
        (error) => {
          this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        }
      );
  }

  previewPrintItems(printConfig: PrintConfig) {
    this.preview = true;
    this.printCn(printConfig);
  }

  printItems(printConfig: PrintConfig) {
    this.preview = false;
    this.printCn(printConfig);
  }

  printCn(printConfig: PrintConfig) {
    this.checkForAddBlock();
    this.creditNoteService.printCn(utils.createPrintWrapperObject(printConfig, this.rowData.invoiceFormat, this.preview, null), this.rowData.id)
      .subscribe(
        (response) => {
          if (response) {
            // console.log("response", response);
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
          }
        },
        // (error) => {
        //   this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        //   this.isLoading = false;
        // }
        (error) => {
          const errorObject = ErrorUtil.getErrorObject(error);
          if (errorObject.code === 417 || errorObject.code === 412) {
            this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
          } else {
            this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
          }
          this.isLoading = false;
        }
      );
  }

  checkForAddBlock() {
    if ($("#bottomAd").height() > 0) {
      // console.log("ad block inctive");
    } else {
      // console.log("ad block active");
      this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
    }
  }

  viewPO(PO) {
    this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
      window.open(response);
    });
  }

  showOverlay($event, rowData, rowIndex) {
    this.overlay.show($event);
    this.rowData = rowData;
    this.rowIndex = rowIndex;
  }

  printingItem(isDuplicate, isWithoutLetterHead) {
    this.printOverlay.hide();
    if (isWithoutLetterHead) {
      this.printCNWithoutLetterHead(isDuplicate);
    } else {
      this.printCNWithletterHead(isDuplicate);
    }
  }

  printCNWithoutLetterHead(isDuplicate) {
    this.isLoading = true;
    this.creditNoteService.printCNWithoutLetterHead(this.rowData.id, isDuplicate).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
          this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;
          window.open(url, "_blank").focus(); // need to disable adblock for this
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  printCNWithletterHead(isDuplicate) {
    this.isLoading = true;
    this.creditNoteService.printCNWithLetterHead(this.rowData.id, isDuplicate).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
          this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;
          window.open(url, "_blank").focus(); // need to disable adblock for this
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() { }

}
