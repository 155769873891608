import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoInterfaceComponent } from './photo-interface/photo-interface.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, DropdownModule, CalendarModule, SharedModule, DataScrollerModule, CheckboxModule, InputTextModule, TabViewModule, TieredMenuModule, MultiSelectModule, ProgressSpinnerModule, TooltipModule, OverlayPanelModule, ConfirmationService, ConfirmDialogModule } from 'primeng/primeng';
import { MountedImageComponent } from './photo-interface/mounted-image/mounted-image.component';
import { MonitoringImageComponent } from './photo-interface/monitoring-image/monitoring-image.component';
import { UnmountImageComponent } from './photo-interface/unmount-image/unmount-image.component';
import { PhotoDialogModule } from '../photo-dialog/photo-dialog.module';
import { PhotoInterfaceGlobalSrevice } from './photo-interface/services/photo-interface-global.service';
import { InventoryImageComponent } from './photo-interface/inventory-image/inventory-image.component';
import { HoardingService } from '../../../contracts/services/hoardings.service';
import { HoardingGroupService } from '../../../services/shared/hoardingGroup.service';
import { KioskService } from '../../../services/shared/kiosk.service';
import { AllImagesComponent } from './photo-interface/allI-images/all-images.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    SharedModule,
    DataScrollerModule,
    CheckboxModule,
    InputTextModule,
    TabViewModule,
    PhotoDialogModule,
    TieredMenuModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TooltipModule,
    ConfirmDialogModule
  ],
  declarations: [
    PhotoInterfaceComponent,
    MountedImageComponent,
    MonitoringImageComponent,
    UnmountImageComponent,
    InventoryImageComponent,
    AllImagesComponent
  ],

  exports: [
    PhotoInterfaceComponent
  ],

  providers: [
    PhotoInterfaceGlobalSrevice,
    HoardingService,
    HoardingGroupService,
    KioskService
  ]
})
export class PhotoInterfaceModule { }
