import { CurrencyHelperPipe } from '../shared/helpers/currency.pipe/currencyHelperPipe';
import { DateUtil } from './date.util';
import { GridColumn } from '../components/sib-forms/grid/grid.config';
import { ChargeTypeEnum } from '../shared/constants/charge-type-enum';


/**
 * @description util to reorder the rows of an array
 * @author Pulkit Bansal
 * @date 2019-10-06
 * @export
 * @class RowsReorderUtil
 */
export class RowsReorderUtil {

    /**
     * @description to map the type of array with the columns in the array (in order)
     * @static
     * @type {Array<any>}
     * @memberof RowsReorderUtil
     */
    static typeToColArray: Array<any> = [
        {
            type: "Invoice",
            columns: []
        }
    ];


    /**
     * @description breaking down the inventory based on the line no in which the item needs to be set
     * @static
     * @type {Array<any>}
     * @memberof RowsReorderUtil
     */
    static colArrayForInvoiceInventory: Array<any> = [
        {
            row: "first",
            columns: [
                {
                    name: "customId",
                    formatter: null
                },
                {
                    name: "city",
                    formatter: null
                },
                {
                    name: "location",
                    formatter: null
                },
                {
                    name: "size",
                    formatter: null
                },
            ]
        },
        {
            row: "second",
            columns: [
                {
                    name: "type",
                    formatter: null
                },
                {
                    name: "creative",
                    formatter: null
                },
                {
                    name: "squareFeet",
                    formatter: 'number'
                },
                {
                    name: 'units',
                    formatter: "number"
                },
                {
                    name: "rate",
                    formatter: "currency"
                },
                {
                    name: "printRate",
                    formatter: "number"
                },
                {
                    name: "mountRate",
                    formatter: "number"
                },

            ]
        },
        {
            row: "third",
            columns: [
                {
                    name: "note",
                    formatter: null
                }
            ]
        }
    ];


    /**
     * @description to generate the values based on cols and rows
     * @author Pulkit Bansal
     * @date 2019-10-15
     * @static
     * @param {Array<GridColumn>} columns
     * @param {Array<any>} rows
     * @param {string} type
     * @returns {Array<any>}
     * @memberof RowsReorderUtil
     */
    static generateValuesForRearrangement(columns: Array<GridColumn>, rows: Array<any>, type: string): Array<any> {
        let values: Array<any> = [];
        const colsToDisplay = this.getColsToDisplay(columns, type);
        switch (type) {
            case "Invoice": {
                values = this.generateValuesForInvoice(colsToDisplay, rows);
                break;
            }
        }
        return values;
    }

    /**
     * @description to get the columns to be displayed
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @static
     * @param {Array<GridColumn>} cols
     * @param {string} type
     * @param {boolean} [includeSrNo=false]
     * @returns {Array<GridColumn>}
     * @memberof RowsReorderUtil
     */
    static getColsToDisplay(cols: Array<GridColumn>, type: string, includeSrNo = false): Array<GridColumn> {
        const columns = this.changeColHeaderForDisplay(cols, type);
        return (includeSrNo) ? columns.filter(column => column.hidden !== true) : columns.filter(column => column.hidden !== true && column.field !== 'srNo');
    }

    /**
     * @description to change the header of a column
     * currently used in case of invoice --> amount column --> values - cost and package rate
     * @author Pulkit Bansal
     * @date 2019-10-31
     * @static
     * @param {Array<GridColumn>} columns
     * @param {string} type
     * @returns {Array<GridColumn>}
     * @memberof RowsReorderUtil
     */
    static changeColHeaderForDisplay(columns: Array<GridColumn>, type: string): Array<GridColumn> {
        switch (type) {
            case "Invoice": {
                columns.filter((column) => {
                    switch (column.field) {
                        case "amount": {
                            column.header = "Cost";
                            break;
                        }
                        default: {
                            // do nothing
                        }
                    }
                });
                break;
            }
            default: {
                // do nothing
            }
        }
        return columns;
    }




    /**
     * @description to get the values for invoices
     * @author Pulkit Bansal
     * @date 2019-10-15
     * @static
     * @param {Array<GridColumn>} cols
     * @param {Array<any>} rows
     * @returns {Array<any>}
     * @memberof RowsReorderUtil
     */
    static generateValuesForInvoice(cols: Array<GridColumn>, rows: Array<any>): Array<any> {
        const values: Array<any> = [];
        rows.forEach((row) => {
            const value: any = [];
            value['id'] = row['id']; // for comparision

            let selectedChargeType: Map<string, string> = new Map();
            if (!selectedChargeType.has(row.mediaType) && row["type"] === "Printing") {
                row.printingPrice.perUnit ? selectedChargeType.set(row.mediaType, ChargeTypeEnum['PER_UNIT']) : selectedChargeType.set(row.mediaType, ChargeTypeEnum['PER_SQFT']);
            } else if (!selectedChargeType.has(row.mediaType) && row["type"] === "Mounting") {
                row.mountingPrice.pricePerUnit ? selectedChargeType.set(row.mediaType, ChargeTypeEnum['PER_UNIT']) : selectedChargeType.set(row.mediaType, ChargeTypeEnum['PER_SQFT']);
            }
            cols.forEach((col) => {
                value[col.field] = this.setCustomValueForColumn(row, col.field, "Invoice", selectedChargeType);
            });
            value["type"] = row.type;
            // value.push(row.type);
            values.push(value);
        });
        return values;
    }


    /**
   * @description to set the value of a field for a row
   * @author Pulkit Bansal
   * @date 2019-10-15
   * @static
   * @param {*} row
   * @param {string} field
   * @param {string} type
   * @returns {*}
   * @memberof RowsReorderUtil
   */
    static setCustomValueForColumn(row: any, field: string, type: string, selectedChargeType?): any {
        let value: any = "";
        switch (type) {
            case "Invoice": {
                // to check if the item exists in the type to col array
                const index = this.getIndex(type);
                if (index !== -1) {
                    // to check if row is valid i.e !undefined and !null
                    if (row) {
                        switch (field) {
                            case "inventory": {
                                value = this.setInventoryForDifferentRowTypes(row, field, selectedChargeType);
                                break;
                            }
                            case 'hsnCode': {
                                if (row[field]) {
                                    value = row[field];
                                } else {
                                    value = "";
                                }
                                break;
                            }
                            case "itemStartDate": {
                                if (row[field]) {
                                    value = this.getFormattedValue(row[field], "date");
                                } else {
                                    value = "-";
                                }
                                break;
                            }
                            case "itemEndDate": {
                                if (row[field]) {
                                    value = this.getFormattedValue(row[field], "date");
                                } else {
                                    value = "-";
                                }
                                break;
                            }
                            case "days": {
                                if (row[field]) {
                                    value = this.getFormattedValue(row[field], "number");
                                } else {
                                    value = "-";
                                }
                                break;
                            }
                            case "rate": {
                                if (row[field]) {
                                    value = row['type'] === "Rent" ? this.getFormattedValue(row[field], "currency") : "-";
                                } else {
                                    value = "-";
                                }
                                break;
                            }
                            case "amount": {
                                if (row[field]) {
                                    value = this.getFormattedValue(row[field], "currency");
                                } else {
                                    value = "-";
                                }
                                break;
                            }
                        }
                    } else { // if row is undefined or null
                        value = "-";
                    }
                } else { // this case should not arise
                    value = "-";
                }
                break;
            }
        }
        return value;
    }

    /**
     * @description to set the inventory field for different types
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @static
     * @param {any} row
     * @param {string} field
     * @returns {string}
     * @memberof RowsReorderUtil
     */
    static setInventoryForDifferentRowTypes(row: any, field: string, selectedChargeType?): string {
        let value: any = "";
        switch (row["type"]) {
            case "Rent": {
                // getting the items based on rows to consider for this type of inventory i.e second row is not needed in this case
                const rowToColumnsArray = this.colArrayForInvoiceInventory.filter(item => item["row"] !== "second");
                // to check if any rowsToColumnsArray is not empty
                if (rowToColumnsArray && rowToColumnsArray.length) {
                    rowToColumnsArray.forEach((rowToColumn) => {
                        // to check when to insert <br> in the value as value is of multiple lines
                        if (value && value !== "" && !this.lastValueIsBreak(value)) {
                            value = value + "<br>";
                        }
                        const columns = rowToColumn["columns"];
                        // to check if columns array contains the field value as name or not
                        if (columns.findIndex(item => item['name'] === field) === -1) {
                            columns.forEach((column) => {
                                // whether value is present in the row or not
                                if (row[column['name']]) {
                                    value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                } else { // if value is not present
                                    // do nothing
                                }
                            });
                        } else { // value not found in the columns array --> should not occur ---> hence return the value in the row
                            value = row[field];
                        }
                    });
                } else { // this case should not occur
                    value = "";
                }
                break;
            }
            case "Printing": {
                const rowToColumnsArray = this.colArrayForInvoiceInventory;
                if (rowToColumnsArray && rowToColumnsArray.length) {
                    rowToColumnsArray.forEach((rowToColumn) => {
                        if (value && value !== "" && !this.lastValueIsBreak(value)) {
                            value = value + "<br>";
                        }
                        const columns = rowToColumn["columns"];
                        if (columns.findIndex(item => item['name'] === field) === -1) {
                            columns.forEach((column) => {
                                if (row[column['name']]) {
                                    switch (column['name']) {
                                        case "creative": {
                                            if (row[column['name']] === "Default") {
                                                // do nothing
                                            } else {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                            }
                                            break;
                                        }
                                        case "squareFeet": {
                                            if (selectedChargeType.get(row.mediaType) === ChargeTypeEnum['PER_SQFT']) {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                                value = value + " " + this.checkChargeType(row, selectedChargeType);
                                            }

                                            break;
                                        }

                                        case "units": {
                                            if (selectedChargeType.get(row.mediaType) === ChargeTypeEnum['PER_UNIT']) {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                                value = value + " " + this.checkChargeType(row, selectedChargeType);
                                            }
                                            break;
                                        }
                                        case "rate": {
                                            // do nothing
                                            break;
                                        }
                                        case "printRate": {
                                            value = value + " @ ";
                                            value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                            value = value + " Rs./" + this.checkChargeType(row, selectedChargeType);
                                            break;
                                        }
                                        case "mountRate": {
                                            // do nothing
                                            break;
                                        }
                                        default: {
                                            value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                        }
                                    }
                                } else {
                                    // do nothing
                                }
                            });
                        } else {
                            value = row[field];
                        }
                    });
                } else {
                    value = "";
                }
                break;
            }
            case "Mounting": {
                const rowToColumnsArray = this.colArrayForInvoiceInventory;
                if (rowToColumnsArray && rowToColumnsArray.length) {
                    rowToColumnsArray.forEach((rowToColumn) => {
                        if (value && value !== "" && !this.lastValueIsBreak(value)) {
                            value = value + "<br>";
                        }
                        const columns = rowToColumn["columns"];
                        if (columns.findIndex(item => item['name'] === field) === -1) {
                            columns.forEach((column) => {
                                if (row[column['name']]) {
                                    switch (column['name']) {
                                        case "creative": {
                                            if (row[column['name']] === "Default") {
                                                // do nothing
                                            } else {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                            }
                                            break;
                                        }
                                        case "squareFeet": {
                                            if (selectedChargeType.get(row.mediaType) === ChargeTypeEnum['PER_SQFT']) {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                                value = value + " " + this.checkChargeType(row, selectedChargeType);
                                            }
                                            break;
                                        }
                                        case "units": {
                                            if (selectedChargeType.get(row.mediaType) === ChargeTypeEnum['PER_UNIT']) {
                                                value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                                value = value + " " + this.checkChargeType(row, selectedChargeType);
                                            }
                                            break;
                                        }
                                        case "rate": {
                                            // do nothing
                                            break;
                                        }
                                        case "printRate": {
                                            // do nothing
                                            break;
                                        }
                                        case "mountRate": {
                                            value = value + " @ ";
                                            value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                            value = value + " Rs./" + this.checkChargeType(row, selectedChargeType);
                                            break;
                                        }
                                        default: {
                                            value = (this.isValueEmpty(value) || this.lastValueIsBreak(value) || this.lastValueIsPrintMountRate(value)) ? value + this.getFormattedValue(row[column['name']], column['formatter']) : value + " - " + this.getFormattedValue(row[column['name']], column['formatter']);
                                        }
                                    }
                                } else {
                                    // do nothing
                                }
                            });
                        } else {
                            value = row[field];
                        }
                    });
                } else {
                    value = "";
                }
                break;
            }
            case "RO": {
                value = "As Per Ro";
                break;
            }
            case "RO_RENT": {
                value = "As Per Ro - Rent";
                break;
            }
            case "RO_PRINT_MOUNT": {
                value = "As Per Ro - Printing/Mounting";
                break;
            }
            case "ANNEXURE": {
                value = "As Per Attached Annexure";
                break;
            }
            case "PRINTING_CHARGES": {
                value = "Printing Charges";
                break;
            }
            case "MOUNTING_CHARGES": {
                value = "Mounting Charges";
                break;
            }
        }
        return value;
    }

    /**
     * @description validation
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @static
     * @param {string} value
     * @returns {boolean}
     * @memberof RowsReorderUtil
     */
    static isValueEmpty(value: string): boolean {
        return (value === "") ? true : false;
    }



    /**
     * @description to check if the last part of the value string contains <br>
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @static
     * @param {string} value
     * @returns {boolean}
     * @memberof RowsReorderUtil
     */
    static lastValueIsBreak(value: string): boolean {
        if (value && value.length) {
            if (value.substring(value.length - 4) === "<br>") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * @description to check if the last value contains print or mount rate
     * need this so that '-' is not appended in the value string
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @static
     * @param {string} value
     * @returns {boolean}
     * @memberof RowsReorderUtil
     */
    static lastValueIsPrintMountRate(value: string): boolean {
        return value.substring(value.length - 2, value.length - 1) === "@";
    }

    /**
     * @description to check if type exists in the typeToColArray
     * returns index of item
     * @static
     * @param {string} type
     * @returns {number}
     * @memberof RowsReorderUtil
     */
    static getIndex(type: string): number {
        return this.typeToColArray.findIndex(item => item.type === type);
    }


    /**
     * @description to get the formatted value of a filed in an array
     * use case --> date and currency values
     * @static
     * @param {string} value
     * @param {string} formatter
     * @returns {string}
     * @memberof RowsReorderUtil
     */
    static getFormattedValue(value: string, formatter: string): string {
        switch (formatter) {
            case 'currency': {
                return "₹" + new CurrencyHelperPipe().transform(value);
            }
            case 'date': {
                return DateUtil.dategridFormatter(value);
            }
            case 'number': {
                return new CurrencyHelperPipe().transform(value);
            }
            default: {
                return value;
            }
        }
    }


    /**
     * @description checks for charge type for each inventory
     * @author Raveena Nathani
     * @date 2020-03-02
     * @static
     * @param {*} rowData
     * @param {Map<string, string>} selectedChargeType
     * @returns
     * @memberof RowsReorderUtil
     */
    static checkChargeType(rowData: any, selectedChargeType: Map<string, string>) {
        return selectedChargeType.get(rowData.mediaType) === ChargeTypeEnum['PER_SQFT'] ? 'SQ.FT.' : 'Unit'
    }

}

