import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataScroller } from 'primeng/primeng';
import * as utils from '../../../helpers/utils';
import { AuthService } from '../../../login-service/auth.service';
import { PaginationData } from '../../../modals/paginationdata';
import { WebNotificationService } from '../../../services/shared/web-notifications.service';
import { AppUrls } from '../../../services/urls';
import { WebNotificationsBodyEnum } from '../../constants/web-notification-body-enum';

@Component({
  selector: 'sib-web-notifications',
  templateUrl: './web-notifications.component.html',
  styleUrls: ['./web-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebNotificationsComponent implements OnInit, AfterViewInit {
  userProfile: any;
  notifications: any[] = [];
  notificationData: any;
  onInlineScroll: any;
  scrollHeight: string;
  notificationLink: string;
  isLoading = false;
  notificationHeaderKey: any;
  notificationHeaderValue: any;
  emailId: any;
  notificationDisplayName: string;
  subElementDescription: string;

  dscrollerPaginationEvent: PaginationData = {
    "page": 0,
    "size": 10,
    "sortField": "updatedDate",
    "filters": {},
    "sortOrder": -1,
    "globalFilter": ""
  };

  @ViewChild('dscroller') dscroller: DataScroller;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScrollHeight();
  }

  @HostListener('scroll', ['$event'])
  onscroll($event) {
    this.setScrollHeight();
  }

  constructor(
    private notificationService: WebNotificationService,
    private auth: AuthService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.isLoading = true;
    // this.userProfile = this.auth.getUserProfile();
    this.emailId = utils.getLoggedInUserEmail();
    // this.getNotifications();
  }

  ngAfterViewInit() {
    this.setScrollHeight();
    this.onInlineScroll = this.inlineScrollerListener.bind(this);
    this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
    this.inlineScrollerListener();
    // this.displayLoaderButton(false);
  }

  inlineScrollerListener() {
    const scrollTop = this.dscroller.contentViewChild.nativeElement.scrollTop;
    const scrollHeight = this.dscroller.contentViewChild.nativeElement.scrollHeight;
    const viewportHeight = this.dscroller.contentViewChild.nativeElement.clientHeight;

    if ((scrollTop >= ((scrollHeight) - (viewportHeight)))) {
      this.getNotifications();
    } else {
      // do nothing
    }
  }

  setScrollHeight() {
    this.scrollHeight = (window.innerHeight - 170) + 'px';
  }

  getNotifications() {
    this.isLoading = true;

    Object.assign(this.dscrollerPaginationEvent.filters, { 'emailId': { value: this.emailId, matchMode: 'is' } });

    this.notificationService.create(this.dscrollerPaginationEvent, this.dscrollerPaginationEvent, AppUrls.SEARCH).subscribe((response) => {
      if (response['data']) {
        if (this.dscrollerPaginationEvent.page === 0) {
          this.notifications = response['data']['content'];
        } else {
          this.notifications = [...this.notifications, ...response['data']['content']];
        }
        this.dscrollerPaginationEvent.page++;
        this.setNotificationData();
        this.isLoading = false;

        this.cd.detectChanges();
        if (this.dscrollerPaginationEvent.page === 1) { // this is because change detection is creating an issue detecting
          this.getNotifications();
        }


      }
    });
  }

  setNotificationData() {
    this.notificationData = [];
    this.notifications.forEach((notification) => {
      const messages = utils.sort(Object.entries(notification.message));
      messages.forEach((message) => {
        message[0] = this.getBodyElementTitle(message[0]);
      });

      const messageData = {
        read: notification.read,
        messages: messages
      };
      this.notificationData.push(messageData);
    });
  }


  getBodyElementTitle(element) {
    const title = element.substr(3);
    return WebNotificationsBodyEnum[title];
  }

  getNotificationStatus(customId) {
    this.notifications.forEach((notification) => {
      if (notification.message["03_customId"] === customId) {
        return notification.read;
      }
    });
  }

  markAsRead(customId) {
    this.notificationData.forEach((item) => {
      if (item.messages[2][1] === customId) {
        item.read = true;
      }
    });
    this.notificationService.unreadCountSubject.next("decrement");
    this.notificationService.create({ "customId": customId, "emailId": this.emailId }, null, AppUrls.MARK_READ).subscribe((reponse) => {
      if (reponse) {

      }
    }
    );
  }



}
