export enum SibUserRoleEnum {
  /**
  * Role is Sales Rep
  */
  SALES_REPRESENTATIVE = "sales-representative",
  /**
   * Role is Support Team
   */
  SUPPORT_TEAM = "supprt-team",
  /**
   * Role is Admin
   */
  ADMIN = "admin",
  /**
   * Role is Branch Manager
   */
  // BRANCH_MANAGER = "branch-manager",
  /**
   * Role is Photographer
   */
  // PHOTOGRAPHER = "photographer",
  /**
   * Role is Mounter
   */
  MOUNTER = "mounter",
  /**
   * Role is Printer
   */
  PRINTER = "printer",
  /**
   * Role is Warehouse
   */
  WAREHOUSE = "warehouse",
  /**
   *
   */
  ACCOUNTANT = "accountant",

  SUPER_ADMIN = "super-admin",

  BILL_HANDLER = "bill-handler",

  VIEW_DISPLAY_NAME = "view-display-name",

  VIEW_EXPIRED_CAMPAIGNS = "view-expired-campaigns"
}
