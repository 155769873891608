/**
 * @file File containes utility functions
 * @author ShowItMax Team
 * @copyright Meditab Software 2017
 */

// import { IApiResponse } from 'app/services/base-service/rest-interfaces';
import { Observable } from "rxjs";
import { ListBox } from "../modals/listbox";
import { TreeNode } from "../contracts/contract-hoarding/contract-hoarding.component";
import { SelectItem } from "primeng/components/common/selectitem";
import { FileItem } from "../modals/files/file-item";
import { ConflictItem } from "../modals/conflict/conflictItem";
import { ConflictGroup } from "../modals/conflict/conflictGroup";
import { MediaSubCategoryEnum } from "../shared/constants/media-sub-category-enum";
import { CurrencyHelperPipe } from "../shared/helpers/currency.pipe/currencyHelperPipe";
import { DateUtil } from "./date.util";
import { PrintWrapper } from "../modals/billings/print-wrapper";
import { PrintConfig } from "../modals/billings/print-config";
import { InvoiceFormatEnum } from "../shared/constants/invoice-format-enum";
import { Inventory } from "../modals/hoardings/inventory";

import { ConflictGroups } from "../modals/conflict/conflictGroups";
import { ConflictUnits } from "../modals/conflict/conflictUnits";

export type BooleanResolvable =
  | boolean
  | Promise<boolean>
  | Observable<boolean>;

/**
 * returns a function which resolves a Boolean Resolvable to a promise
 *
 * @export
 * @param {any} shouldPromiseFn
 * @returns
 */
export function buildResolveShouldPromise(
  shouldPromiseFn: (...args: any[]) => BooleanResolvable
) {
  return (...args) => {
    if (shouldPromiseFn) {
      return normalizeToPromise(shouldPromiseFn(...args));
    }
    return Promise.resolve(true);
  };
}

/**
 * handles a shouldPromise
 *
 * @export
 * @param {any} shouldPromiseFn
 * @param {any} [truthyCallback=null]
 * @param {any} [falsyCallback=null]
 * @returns
 */
export function handleShouldPromise(
  shouldPromiseFn: (...args: any[]) => BooleanResolvable,
  truthyCallback = null,
  falsyCallback = null
) {
  return (...args) => {
    buildResolveShouldPromise(shouldPromiseFn)(...args)
      .then((shouldValue) => {
        if (shouldValue && truthyCallback) {
          truthyCallback(...args);
        } else {
          if (falsyCallback && falsyCallback) {
            falsyCallback(...args);
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
  };
}

/**
 * Normalizes a value to return a promise where asynVAlue can be either a scalar value or a promise or an observable
 *
 * @export
 * @param {(any | Promise<any> | Observable<any>)} asyncValue
 * @returns {Promise<any>}
 */
export function normalizeToPromise(
  asyncValue: any | Promise<any> | Observable<any>
): Promise<any> {
  // check if value is observable
  if (asyncValue instanceof Observable) {
    return asyncValue.toPromise();
  }

  if (asyncValue instanceof Promise) {
    return asyncValue;
  }
  return Promise.resolve(asyncValue);
}

/**
 * Return a config value from config object using string key
 *
 * @private
 * @param {string} key
 * @returns
 *
 * @memberOf AppConfig
 */
export function getByString(object: any, key: string) {
  key = key.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  key = key.replace(/^\./, ""); // strip a leading dot
  var a = key.split(".");
  var o = object;
  try {
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
  return o;
}
/**
 * List Mapper function to modify the response according {label, value} object
 *
 * @export
 * @param {any[]} data
 * @param {string} label
 * @param {*} value
 * @param {boolean} isValueObject
 * @returns {*}
 */
export function ListMapper(
  data: any[],
  label: string,
  isValueObject: boolean,
  value?: string,
  isFirstBlank?: boolean
): any {
  const list = [];

  if (isFirstBlank) {
    const blankListMapper = new ListBox();
    blankListMapper.setLabel("");
    if (isValueObject) {
      blankListMapper.setValue({});
    } else {
      blankListMapper.setValue("");
    }

    list.push(blankListMapper);
  }

  for (let i = 0; i < data.length; i++) {
    const listMapper = new ListBox();
    if (label.length > 0) {
      listMapper.setLabel(data[i][label]);
    } else {
      listMapper.setLabel(data[i]);
    }
    if (isValueObject) {
      listMapper.setValue(data[i]);
    } else {
      listMapper.setValue(data[i][value]);
    }
    list.push(listMapper);
  }
  return list;
}

/**
 *  This function will return the value finding it in listmapper
 *
 * @export
 * @param {any[]} data
 * @param {*} value
 * @param {string} searchBy
 */
export function findValueInListMapper(
  data: any[],
  value: any,
  searchBy: string
): any {
  for (let i = 0; i < data.length; i++) {
    if (data[i]["value"][searchBy] === value[searchBy]) {
      return data[i]["value"];
    }
  }
}

/**
 * Used to filter Data.
 *
 * @export
 * @param {any[]} data
 * @param {string} filterBy
 * @param {*} event
 * @returns
 */
export function filterData(data: any[], filterBy: string, event: any) {
  const filteredData = [];
  data.forEach((object) => {
    if (
      object &&
      object[filterBy] &&
      object[filterBy].toLowerCase().indexOf(event.query.toLowerCase()) > -1
    ) {
      filteredData.push(object);
    }
  });

  return filteredData;
}

export function filterDataMulti(data: any[], filterBy: string[], event: any) {
  const filteredData = [];
  const combineData = [];

  data.forEach((object) => {
    /* if ((object[filterBy[0]] + ' ' + object[filterBy[1]]).toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
            filteredData.push(object);
        } */

    if (
      (object[filterBy[0]] + " " + object[filterBy[1]])
        .toLowerCase()
        .indexOf(event.query.toLowerCase()) > -1
    ) {
      filteredData.push(object);
    }
  });

  return filteredData;
}

export function dateFormatter(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

/**
 * set data for rendering purpose
 *
 * @export
 * @param {any[]} data
 * @returns
 */
export function setData(data: any[]) {
  return data.filter((datum) => {
    return datum;
  });
}

/**
 *  set data for rendering based on not condition
 *
 * @export
 * @param {any[]} data
 * @param {string} dataField
 * @param {*} condition
 * @returns
 */
export function setDataOnCondition(
  data: any[],
  dataField: string,
  condition: any
) {
  let fieldArray = [];
  const s = "";
  fieldArray = dataField.split(",");
  return data.filter((datum) => {
    for (let i = 0; i < fieldArray.length; i++) {
      datum = datum[fieldArray[i]];
    }
    if (datum !== condition) {
      return datum;
    }
  });
}

/**
 *  creating tree one node at a time
 *
 * @export
 * @param {*} data
 * @param {string} dataField
 * @returns
 */
export function createTree(data: any, dataField: string) {
  // console.log("data is", data);
  let treeData: any;
  let fieldArray = [];
  fieldArray = dataField.split(",");
  treeData = new TreeNode();
  treeData.data = data;
  if (data[fieldArray[0]] && data[fieldArray[0]].length > 0) {
    treeData.children = [];
    data[fieldArray[0]].forEach((field, index) => {
      treeData.children[index] = new TreeNode();
      treeData.children[index].data = field;
    });
  }
  // console.log("treedata is", treeData);
  return treeData;
}

/**
 *  convert to select item type for dropdown
 *
 * @export
 * @param {*} data
 * @returns
 */
export function convertToSelectItem(data: any) {
  const item: SelectItem[] = [];
  data.forEach((datum) => {
    item.push({ label: datum, value: datum });
  });
  return item;
}

/**
 * setting the image folder in firebase
 *
 * @export
 * @param {FileItem[]} files
 * @param {string} imageFolder
 * @returns
 */
export function setImageFolder(files: FileItem[], imageFolder: string) {
  files.forEach((file) => {
    file.imagesFolder = imageFolder;
  });
  return files;
}

/**
 * setting the response to be displayed on the page
 *
 * @param {any} data
 * @param {any} values
 * @returns
 * @memberof HoardingFunctionalService
 */
export function setResponse(data, values) {
  let response: any[] = data;
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < values.length; j++) {
      if (values[j].hoardings) {
        for (let k = 0; k < values[j].hoardings.length; k++) {
          response = this.setDataOnCondition(
            response,
            "customId",
            values[j].hoardings[k].customId
          );
        }
      } else {
        response = this.setDataOnCondition(
          response,
          "customId",
          values[j].customId
        );
      }
    }
  }
  return response;
}

/**
 * Builds the map<String,String> from the Object
 * @param  {} obj
 */
export function buildMap(obj) {
  const map = new Map();
  Object.keys(obj).forEach((key) => {
    map.set(key, obj[key]);
  });
  return map;
}

/**
 *
 *
 * @export
 * @param {any} data -- Complete data to be filtered
 * @param {any} values -- values to be filtered
 * @param {any} comparator -- on what basis
 */
export function filterArray(data, values, comparator) {
  let response: any[] = [];
  let push: boolean = false;
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < values.length; j++) {
      if (data[i][comparator] === values[j][comparator]) {
        push = false;
        break;
      } else {
        push = true;
      }
    }
    if (push === true) {
      response.push(data[i]);
    }
  }
  return response;
}

/**
 *  This function will return the value finding it in listmapper
 *
 * @export
 * @param {any[]} data
 * @param {*} value
 * @param {string} searchBy
 */
export function findInListMapper(
  data: any[],
  value: any,
  searchBy: string
): any {
  for (let i = 0; i < data.length; i++) {
    if (data[i]["value"][searchBy] === value) {
      return data[i]["value"];
    }
  }
}

/**
 * to find the conflicts in plan while blocking
 * and converting the plan
 * @export
 * @param {any[]} data
 */
export function findConflictsInPlan(groups: any[], units: any[]) {
  let conflictItem: ConflictItem = new ConflictItem();
  units.forEach((unit) => {
    let conflictInventory: ConflictUnits = new ConflictUnits();
    let found = false;
    groups.forEach((group) => {
      group.childInventories.forEach((child) => {
        if (unit.customId === child.customId) {
          conflictInventory.inventoryUnits = unit;
          conflictInventory.inventoryGroups.push(group);
          found = true;
        }
      });
    });
    if (found) {
      conflictItem.conflictUnits.push(conflictInventory);
    }
  });
  if (groups.length > 1) {
    groups.forEach((group, index) => {
      let conflictGroup: ConflictGroups = new ConflictGroups();
      let foundGrps: any[] = []; // if more than one group is in conflict with the given group
      let result = false;
      groups.forEach((grp, ind) => {
        if (group.customId !== grp.customId) {
          let grpAdded = false;
          group.childInventories.forEach((child) => {
            grp.childInventories.forEach((hrdg) => {
              if (!grpAdded) {
                if (
                  child.customId &&
                  hrdg.customId &&
                  child.customId === hrdg.customId
                ) {
                  result = true;
                  foundGrps.push(grp);
                  grpAdded = true; // to prevent the same group from getting again if more than one hoardings are common
                }
              }
            });
          });
        }
      });
      if (result) {
        conflictGroup.inventoryGroup = group;
        foundGrps.forEach((foundGrp) => {
          conflictGroup.inventoryGroups.push(foundGrp);
        });
        conflictItem.conflictGroups.push(conflictGroup);
      }
    });
  }
  // console.log("conflict item", conflictItem);
  return conflictItem;
}

export function getKey(enumName, value) {
  let keys = Object.keys(enumName);
  let foundKey: any;
  keys.forEach((key) => {
    if (enumName[key] === value) {
      foundKey = key;
    }
  });
  return foundKey;
}

export function getGridConfigMap(columns, allColumns) {
  var gridConfigColumnMap: Map<string, string> = new Map();
  var filteredColumns = allColumns.filter((column) => {
    if (
      columns.indexOf(column["header"]) > -1 ||
      (column["permanent"] === true && column["header"].length > 0)
    ) {
      return column;
    }
  });

  filteredColumns.forEach((column) => {
    gridConfigColumnMap.set(column["header"], column["field"]);
  });

  return gridConfigColumnMap;
}

/**
 * gets the user configured columns
 *
 * @export
 * @param {Map<string, string>} columnMap
 * @param {*} allColumns
 * @returns
 */
export function getUserConfiguredColumns(
  columnMap: Map<string, string>,
  allColumns
) {
  allColumns.forEach((column) => {
    if (column.hidden !== true) {
      column.hidden = false;
    }
    if (columnMap[column["header"]]) {
      column["default"] = true;
    } else {
      column["default"] = false;
    }
  });

  return allColumns;
}

export function getXlsColumnConfig(columnMap: Map<string, string>, allColumns) {
  var selectedColumns = [];
  allColumns.forEach((column) => {
    if (columnMap[column["header"]]) {
      selectedColumns.push(column["header"]);
    }
  });

  return selectedColumns;
}

/**
 * @description builds Object from Map
 * @author Divya Sachan
 * @date 2019-10-10
 * @export
 * @param {*} map
 * @returns
 */
export function buildObject(map) {
  var obj = Object.create(null);

  map.forEach((value, key, map) => {
    obj[key] = value;
  });

  return obj;
}

export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function getAbsoluteValue(value) {
  return Math.abs(value);
}

export function getMaxStartDate(rows) {
  let minDate = new Date(rows[0].itemStartDate);
  rows.forEach((row, index) => {
    if (
      this.calculationService.setToBeginning(
        new Date(row.itemStartDate).valueOf()
      ) >= this.calculationService.setToBeginning(new Date(minDate).valueOf())
    ) {
      minDate = new Date(row.itemStartDate);
    }
  });
  return new Date(minDate);
}

export function getMinEndDate(rows) {
  let maxDate = new Date(this.rows[0].itemEndDate);
  this.rows.forEach((row, index) => {
    if (
      this.calculationService.setToBeginning(
        new Date(row.itemEndDate).valueOf()
      ) <= this.calculationService.setToBeginning(new Date(maxDate).valueOf())
    ) {
      maxDate = new Date(row.itemEndDate);
    }
  });
  return new Date(maxDate);
}

export function getDecimalNum(value) {
  return Number(Math.fround(value).toFixed(2));
}

/**
 * @description get value fixed to 2 decimal places
 * @author Divya Sachan
 * @date 2019-10-10
 * @export
 * @param {*} value
 * @returns
 */
export function getDecimalNumFixedToTwoPlaces(value) {
  return Number(value).toFixed(2);
}

export function getIntegerValue(value) {
  // if (String(value).includes('.')) {
  //      value = String(value).split('.')[0];
  // }
  return Number(String(value).split(".")[0]);
}

export function getIntegerRoundedOffValue(value) {
  return Number(Math.round(Number(value)));
}

export function getIntegerFloorValue(value) {
  return Number(Math.floor(Number(value)));
}

export function getIntegerCeilValue(value) {
  return Number(Math.ceil(Number(value)));
}

export function setThousandSeperators(value) {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * @description parse value to two decimal places
 * @export
 * @param {*} value
 * @returns
 */
export function parseValueToDecimalTwoPlaces(value) {
  if (value === "-") {
    return value;
  } else {
    if (String(value).includes(".")) {
      const parsedInputArray = String(value).split(".");
      let fractionalPart = parsedInputArray[1];
      if (fractionalPart.length > 2) {
        fractionalPart = String(fractionalPart).substr(0, 2);
      }
      parsedInputArray[1] = fractionalPart;
      value = parsedInputArray[0] + "." + parsedInputArray[1];
    }
    return Number(value);
  }
}

export function removeFractionalPart(value) {
  if (String(value).includes(".")) {
    const parsedInputArray = String(value).split(".");
    value = parsedInputArray[0];
  }
  return Number(value);
}

export function multiplyBy100(value) {
  if (String(value).includes(".")) {
    const parsedInputArray = String(value).split(".");
    let fractionalPart = parsedInputArray[1];
    fractionalPart = String(fractionalPart).substr(0, 2);
    parsedInputArray[1] = fractionalPart;
    if (fractionalPart.length === 2) {
      value = parsedInputArray[0] + parsedInputArray[1];
    } else if (fractionalPart.length === 1) {
      value = parsedInputArray[0] + parsedInputArray[1] + "0";
    }
  } else {
    value = value * 100;
  }
  return Number(value);
}

export function multiplyDecimals(val1, val2) {
  val1 = multiplyBy100(val1);
  val2 = multiplyBy100(val2);
  val1 = removeFractionalPart(val1);
  val2 = removeFractionalPart(val2);
  let val = val1 * val2;
  val = parseValueToDecimalTwoPlaces(val);
  return Number(val / 10000);
}

export function divideDecimals(val1, val2) {
  val1 = multiplyBy100(val1);
  val2 = multiplyBy100(val2);
  val1 = removeFractionalPart(val1);
  val2 = removeFractionalPart(val2);
  let val = val1 / val2;
  val = parseValueToDecimalTwoPlaces(val);
  return Number(val);
}

export function testForDecimals(value) {
  const decimalNumbers: RegExp = /^(\d*\.)?\d+$/; // for decimal number fields
  return decimalNumbers.test(value);
}

export function getDate(date) {
  return new Date(date);
}

export function getTime(date) {
  return new Date(date).getTime();
}

/**
 * @description create dropdown from the enumType
 * @export
 * @param {*} enumType
 * @param {boolean} [allRequired=true]
 * @returns
 */
export function createDropdown(enumType, allRequired = true) {
  const dropdown: any[] = [];
  if (allRequired) {
    dropdown.push({ label: "All", value: "ALL" });
  }
  Object.keys(enumType).forEach((key) => {
    // dropdown.push({ label: enumType[key], value: key });
    if (enumType[key] instanceof Object) {
      dropdown.push({
        label: enumType[key][Object.keys(enumType[key])[0]],
        value: Object.keys(enumType[key]),
      });
    } else {
      dropdown.push({ label: enumType[key], value: key });
    }
  });
  return dropdown;
}

export function createMultiSelect(data, field) {
  const multiSelectOptions: any[] = [];
  data.forEach((item) => {
    multiSelectOptions.push({ label: item[field], value: item });
  });

  return multiSelectOptions;
}

/**
 * @description create true false dropdown
 * @export
 * @param {boolean} [allRequired=true]
 * @returns
 */
export function createTrueFalseDropdown(allRequired = true) {
  const dropdown: any[] = [];
  if (allRequired) {
    dropdown.push({ label: "All", value: "ALL" });
  }
  dropdown.push({ label: "True", value: true });
  dropdown.push({ label: "False", value: false });
  return dropdown;
}

/**
 * @description reset Dropdown
 * @export
 * @param {*} dropdown
 */
export function resetDropDown(dropdown) {
  dropdown ? (dropdown.selectedOption = null) : "";
}

/**
 * create grouped array from sorted array
 *
 * @export
 * @param {any[]} sortedArray
 * @returns
 */
export function group(sortedArray: any[]) {
  // const initialSize = sortedArray.length;
  let hoardingGroups = sortedArray.filter(
    (item) =>
      MediaSubCategoryEnum[item.mediaType] ===
      MediaSubCategoryEnum.HOARDING_GROUP
  );
  // const groupToHoardingIdsMap = new Map<any, string[]>();
  // const hoardingsToRemove: any[] = [];
  // const indexToItemMap = new Map<Number, Set<any>>();

  sortedArray = sortedArray.filter((item) => {
    if (
      MediaSubCategoryEnum[item.mediaType] !==
      MediaSubCategoryEnum.HOARDING_GROUP
    ) {
      return item;
    } else {
      let found = false;
      item.hoardings.forEach((hoarding) => {
        if (
          sortedArray.findIndex((itm) => itm.customId === hoarding.customId) !==
          -1
        ) {
          found = true;
        }
      });
      if (!found) {
        return item;
      }
    }
  });

  hoardingGroups = hoardingGroups.filter(matchValue);

  function matchValue(value) {
    const id = value.customId;
    const index = sortedArray.findIndex((item) => item.customId === id);
    return index === -1 ? value : false;
  }

  // =====================================================================================================

  while (hoardingGroups.length !== 0) {
    const grp = hoardingGroups[0];
    let indices: number[] = [];

    // find indices of hoardings of the group
    grp.hoardings.forEach((hoarding) => {
      const index = sortedArray.findIndex(
        (item) => item.customId === hoarding.customId
      );
      if (index !== -1) {
        indices.push(index);
      }
    });

    // sort the indices
    indices = indices.sort();

    // index to insert the sub group
    const indexToInsert = indices[0];

    // form sub group from the hoardings at the indices and the group
    const subGroup: any[] = [];
    indices.forEach((ind) => {
      subGroup.push(sortedArray[ind]);
    });
    subGroup.push(grp);

    sortedArray = sortedArray.filter(
      (item) =>
        subGroup.findIndex((itm) => itm.customId === item.customId) === -1
    );

    sortedArray.splice(indexToInsert, 0, ...subGroup);

    hoardingGroups.splice(0, 1);
  }

  // =====================================================================================================

  // // create a map of group and hoardings in group which are contained in the array
  // hoardingGroups.forEach((grp) => {
  //     const hoardingIds: string[] = [];

  //     // to store the hoarding ids in sorted order of already applied sorting
  // let indices: number[] = [];

  // grp.hoardings.forEach((hoarding) => {
  //     const index = sortedArray.findIndex(item => item.customId === hoarding.customId);
  //     if (index !== -1) {
  //         indices.push(index);
  //     }
  // });
  // indices = indices.sort();
  // indices.forEach((ind) => {
  //     hoardingIds.push(sortedArray[ind].customId);
  // });
  //     groupToHoardingIdsMap.set(grp, hoardingIds);

  //     // remove group from sorted array
  //     sortedArray.splice(sortedArray.findIndex(item => item.customId === grp.customId), 1);
  // });

  // console.log("sorted array later and group to hoarding ids map", sortedArray, groupToHoardingIdsMap);

  // // create a map for index and hoardings/group and items to remove list
  // groupToHoardingIdsMap.forEach((value, key) => {
  //     let found = false;
  //     let ind = -1;
  //     const subGroup = new Set<any>();
  //     sortedArray.forEach((item, index) => {
  //         if (value.includes(item.customId)) {
  //             ind = ind === -1 ? index : ind;
  //             found = true;
  //             hoardingsToRemove.push(sortedArray[index]);
  //             subGroup.add(sortedArray[index]);
  //         }
  //     });
  //     if (found) {
  //         // hoardingsToRemove.push(key);
  //         subGroup.add(key);
  //         if (indexToItemMap.has(ind)) {
  //             const alreadyPresentSubGroup: Set<any> = indexToItemMap.get(ind);
  //             alreadyPresentSubGroup.add(subGroup);
  //             indexToItemMap.set(ind, alreadyPresentSubGroup);
  //         } else {
  //             indexToItemMap.set(ind, subGroup);
  //         }
  //     }
  // });

  // console.log("index to item map", indexToItemMap);

  // // remove hoardingstoremove from sorted array
  // sortedArray = sortedArray.filter(matchValue);

  // console.log("sorted array after removal", sortedArray);

  // function matchValue(value) {
  //     const id = value.customId;
  //     const index = hoardingsToRemove.findIndex(item => item.customId === id);
  //     return (index === -1) ? value : false;
  // }

  // // create sorted array of initial size with null elements
  // const difference = initialSize - sortedArray.length;
  // for (let i = 0; i < difference; i++) {
  //     sortedArray.push(null);
  // }

  // // add all values to sorted array at the right index
  // let previousValuesAdded = 0;
  // indexToItemMap.forEach((value, key) => {
  //     const valuesToAdd: any[] = [];
  //     value.forEach((val) => {
  //         valuesToAdd.push(val);
  //     });
  //     if (Number(key) <= Number(key) + previousValuesAdded) {
  //         key = Number(key) + Math.abs(Number(key) - previousValuesAdded);
  //     }
  //     if (previousValuesAdded > 0) {
  //         sortedArray.splice(Number(key) + previousValuesAdded, 0, ...valuesToAdd);
  //     } else {
  //         sortedArray.splice(Number(key), 0, ...valuesToAdd);
  //     }
  //     previousValuesAdded = valuesToAdd.length;
  // });

  // console.log("sorted array before filter null", sortedArray);

  // sortedArray = sortedArray.filter(item => item !== null);

  // console.log("sorted array after filter null", sortedArray);

  return sortedArray;
}

export function formatValueBasedOnType(value, formatterType) {
  if (value) {
    const type = typeof value;
    switch (type) {
      case "string": {
        //  if (new Date(value).toLocaleString() !== "Invalid Date") {
        // && value.split('+').length === 2
        if (value.split("T").length === 2 && value.split(":").length === 3) {
          value = DateUtil.dategridFormatter(value);
        } else if (!isNaN(Number(value))) {
          if (formatterType === "currency") {
            value = new CurrencyHelperPipe().transform(
              parseValueToDecimalTwoPlaces(value)
            );
          } else {
            // do nothing
          }
        } else if (formatterType === "date") {
          value = DateUtil.dategridFormatter(value);
        } else {
          // do nothing
        }
        break;
      }
      case "number": {
        value = new CurrencyHelperPipe().transform(
          parseValueToDecimalTwoPlaces(value)
        );
      }
    }
  } else {
    return "-";
  }
  return value;
}

/**
 * to create the object of required type with index
 *
 * @export
 * @param {*} array
 * @param {*} type
 */
export function createObject(array, type) {
  const obj: { value: any; index: number }[] = [];
  array.filter((item, index) => {
    if (
      MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum[type]
    ) {
      obj.push({ value: item, index: index });
    }
  });
  return obj;
}

/**
 *to find the correct group index
 *
 * @export
 */
export function findGroupPosition(group, hoardings) {
  const hoardingIds: { value: string; index: string }[] = [];
  group.value.hoardings.forEach((item) => {
    hoardings.forEach((hoarding) => {
      if (item.customId === hoarding.value.customId) {
        hoardingIds.push({ value: item.customId, index: hoarding.index });
      }
    });
  });
}

export function checkPermission(permission) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo && userInfo[0]) {
    const roles = userInfo[0].app_metadata.authorization.roles;
    const permissions = userInfo[0].app_metadata.authorization.permissions;
    return (permissions.indexOf(permission) !== -1 || permissions.indexOf("sim:admin") !== -1) ? true : false;
  } else {
    return false;
  }
}

export function addPermission(permission) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo && userInfo[0]) {
    const permissions = userInfo[0].app_metadata.authorization.permissions;
    permissions.indexOf(permission) === -1
      ? userInfo[0].app_metadata.authorization.permissions.push(permission)
      : "";
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  } else {
  }
}

export function removePermission(permission) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo && userInfo[0]) {
    const permissions = userInfo[0].app_metadata.authorization.permissions;
    permissions.indexOf(permission) !== -1
      ? (userInfo[0].app_metadata.authorization.permissions = userInfo[0].app_metadata.authorization.permissions.filter(
          (p) => p !== permission
        ))
      : "";
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  } else {
  }
}

export function getXlsConfigMap(columns, allColumns) {
  var gridConfigColumnMap: Map<string, string> = new Map();
  var filteredColumns = allColumns.filter((column) => {
    if (columns.indexOf(column["header"]) > -1 && column["header"].length > 0) {
      return column;
    }
  });

  filteredColumns.forEach((column) => {
    gridConfigColumnMap.set(column["header"], column["field"]);
  });

  return gridConfigColumnMap;
}

export function groupByProperty(objectArray, property: string) {
  return objectArray.reduce((acc, obj) => {
    var key = obj;
    var path = property.split(".");
    while (path.length) {
      if (typeof key !== "object") return undefined;
      key = key[path.shift()];
    }
    if (Date.parse(key) !== NaN) {
      key = new Date(key).toDateString();
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function removeByKeyInObject(object, key) {
  var filteredObject = [];
  for (var k in object) {
    if (k === key) {
      delete object[key];
    }
  }
  return object;
}

/**
 * get logged-in user email
 *
 * @export
 * @returns
 */
export function getLoggedInUserEmail() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")); // get userinfo from localstorage
  return userInfo && userInfo.length ? userInfo[0].email : null;
}

/**
 * Get logged in user fullname
 *
 * @export
 * @returns
 */
export function getLoggedInUserFullName() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")); // get userinfo from localstorage
  return (
    userInfo[0]["user_metadata"].firstname +
    " " +
    userInfo[0]["user_metadata"].lastname
  );
}

/**
 * Get No image Object
 *
 * @export
 * @param {*} imageType
 * @returns
 */
export function getNoImageObject(imageType) {
  const noImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";
  return [{ url: noImageUrl, imageType: imageType }];
}

export function getCommaSeparatedFields(globalFilterFields) {
  var fields;
  if (globalFilterFields && globalFilterFields.length > 0) {
    for (let i = 0; i < globalFilterFields.length; i++) {
      var fieldName = globalFilterFields[i];
      if (i === 0) {
        fields = fieldName;
      } else {
        fields = fields + ", " + fieldName;
      }
    }
  } else {
    fields = "No Fields";
  }
  return fields;
}

/**
 * @description to sort an array on a particular field
 * @author Pulkit Bansal
 * @date 2019-10-03
 * @export
 * @param {any[]} array
 * @param {string} field
 * @returns
 */
export function sortArray(array: any[], field: string) {
  array = array.sort((a, b) =>
    a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
  );
  return array;
}

/**
 * @description creates dropdown from array Object *
 * @author Divya Sachan
 * @export
 * @param {*} arrayObject
 * @param {*} labelField
 * @param {*} allRequired
 * @returns
 */
export function createDropdownFromArray(
  arrayObject,
  labelField,
  allRequired = true
) {
  // second parameter 'labelField' is used for label part to be viewed in the dropdown
  const dropdown: any[] = [];
  if (allRequired) {
    // if ALL option is required in the dropdown
    dropdown.push({ label: "All", value: "ALL" });
  }
  // use for
  // make labelfield optional
  Object.keys(arrayObject).forEach((key) => {
    // creating dropdown specifying label and value
    dropdown.push({
      label: arrayObject[key][labelField],
      value: arrayObject[key],
    });
  });
  return dropdown;
}

export function createDropdownFromObjectArray(
  arrayObject,
  labelField,
  allRequired = true
) {
  const dropdown: any[] = [];
  if (allRequired) {
    // if ALL option is required in the dropdown
    dropdown.push({ label: "All", value: "ALL" });
  }

  Object.keys(arrayObject).forEach((objectKey) => {
    dropdown.push({
      label: arrayObject[objectKey][labelField],
      value: Object.values(arrayObject[objectKey])[0],
    });
  });
  return dropdown;
}

/**
 * creates dropdown from string array
 *
 * @export
 * @param {*} array
 * @param {boolean} [allRequired=true]
 * @returns
 */
export function createDropdownFromStringArray(array, allRequired = true) {
  const dropdown: any[] = [];
  if (allRequired) {
    dropdown.push({ label: "All", value: "All" });
  }
  array.forEach((item) => {
    dropdown.push({ label: item, value: item });
  });
  return dropdown;
}

/**
 * @description to create a print wrapper object for printing purpose
 * @author Pulkit Bansal
 * @date 2019-10-21
 * @export
 * @param {PrintConfig} printConfig
 * @param {InvoiceFormatEnum} printFormat
 * @param {string[]} itemIds
 * @returns {PrintWrapper}
 */
export function createPrintWrapperObject(
  printConfig: PrintConfig,
  printFormat: InvoiceFormatEnum,
  preview: boolean,
  itemIds: string[]
): PrintWrapper {
  const printWrapper: PrintWrapper = new PrintWrapper();
  printWrapper.printConfig = printConfig;
  printWrapper.printFormat = printFormat;
  printWrapper.preview = preview;
  printWrapper.itemIds = itemIds ? itemIds.filter((item) => item !== null) : [];
  return printWrapper;
}

/**
 * @description get App Title
 * @author Divya Sachan
 * @date 2019-11-29
 * @export
 * @returns
 */
export function getAppTitle() {
  const appTitle = localStorage.getItem("appTitle"); // get app title from localstorage
  return appTitle;
}

/**
 * @description sorting array
 * @author Raveena Nathani
 * @date 2020-05-14
 * @param {*} headersArray
 * @returns
 * @memberof AppComponent
 */
export function sort(array) {
  return array.sort(this.compare);
}

/**
 * @description comparision between elements for sorting
 * @author Raveena Nathani
 * @date 2020-05-14
 * @param {*} element1
 * @param {*} element2
 * @returns
 * @memberof AppComponent
 */
export function compare(element1, element2) {
  let comparison = 0;
  if (element1 > element2) {
    comparison = 1;
  } else if (element1 < element2) {
    comparison = -1;
  }
  return comparison;
}
