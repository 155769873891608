import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { DOCUMENT, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as $ from "jquery";
// import { HotkeyModule, HotkeysService, Hotkey, HotkeyOptions } from 'angular2-hotkeys';
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { fromEvent, merge, Observable, of } from "rxjs";
import { mapTo } from "rxjs/operators";
import { ActionDispatcher } from "./app state/action-dispatcher/action-dispatcher";
import {
  CustomerUiService,
  LocalUserRoleEnum,
} from "./customers/services/customer-ui.service";
import { Car } from "./demo/domain/car";
import { CarService } from "./demo/service/carservice";
import { EventService } from "./demo/service/eventservice";
import { ErrorUtil } from "./helpers/error.utils";
import * as utils from "./helpers/utils";
import { AuthService } from "./login-service/auth.service";
import { User } from "./modals/user/user";
import { NotificatoinsService } from "./services/notifications/notifications.service";
import { AuthApiService } from "./services/shared/auth-api.service";
import { AuthUserAPIService } from "./services/shared/auth-user.service";
import { ChatBaseService } from "./services/shared/chat.base.services";
import { ContractTypeService } from "./services/shared/contract-type.service";
import { EncrDecrService } from "./services/shared/encr-decr.service";
import { GroupService } from "./services/shared/group.service";
import { IndustryService } from "./services/shared/industry.service";
import { MessagingService } from "./services/shared/messaging.service";
import { NotificationsMessages } from "./services/shared/notifications-messages";
import { SystemService } from "./services/shared/system.service";
import { UserService } from "./services/shared/user.service";
import { VendorService } from "./services/shared/vendor.service";
import { WebNotificationService } from "./services/shared/web-notifications.service";
import { AppUrls } from "./services/urls";
import { SibUserRoleEnum } from "./shared/constants/sib-user-role-enum";
import { WebNotificationsBodyEnum } from "./shared/constants/web-notification-body-enum";
import { PackagesService } from "./subscription-plans/services/packages.service";

// import * as firebase from 'firebase';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // @ViewChild('appFavicon') appFavicon:ElementRef;
  // test commit
  industryList: any[] = [];
  groupList: any[] = [];
  contractTypeList: any[] = [];
  areaList: any[] = [];

  rightPanelActive: any;

  cities: SelectItem[];

  cars: Car[];

  chartData: any;

  events: any[];

  selectedCity: any;

  /**
   * Boolean to toggle display of dialog for entering user Details
   *
   * @type {boolean}
   * @memberof DashboardDemo
   */
  displayUserDetailDialog: boolean = false;

  /**
   * creating user Object to store user Information
   *
   * @type {User}
   * @memberof DashboardDemo
   */
  user: User = new User();

  deadlineDate: any;
  nowDate: any;
  dateDiff: any;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isLoading: boolean = false;
  online$: Observable<boolean>;
  isConnected: boolean;
  showRefreshScreenDialog: boolean = false;
  refresh: boolean = false;
  message: any;
  // userProfile: any;
  tenantId: string;
  emailId: any;
  subscriptionActive: boolean;
  authenticated: boolean = false;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public renderer: Renderer2,
    private industryService: IndustryService,
    private groupService: GroupService,
    private contractTypeService: ContractTypeService,
    private actionDispatcher: ActionDispatcher,
    private titleService: Title,
    private router: Router,
    private aRoute: ActivatedRoute,
    // private _hotkeysService: HotkeysService,
    private carService: CarService,
    private eventService: EventService,
    private systemService: SystemService,
    // Injecting auth Service --> Handles login
    private auth: AuthService,
    //Injecting authApi service that handles all the api functionality at auth end
    private authApiService: AuthApiService,
    //user service to handle all the api call related to user.
    private userService: UserService,
    private notificationServcie: NotificatoinsService,
    private authUserApiService: AuthUserAPIService,
    private confirmationService: ConfirmationService,
    private chatBaseService: ChatBaseService,
    private vendorService: VendorService,
    private messagingService: MessagingService,
    private notificationService: NotificatoinsService,
    private webNotificationService: WebNotificationService,
    private customerUiService: CustomerUiService,
    private packageService: PackagesService,
    private encrDecrService: EncrDecrService
  ) {
    // this.actionDispatcher.getIndustries();
    // this.actionDispatcher.getGroups();
    // this.actionDispatcher.getContractTypes();
    // this.actionDispatcher.getAreaListFirst();
    // this.actionDispatcher.getSystemConfiguations();

    // this._hotkeysService.add(new Hotkey('ctrl+alt+w', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Dashboards'));
    // this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/customers']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Customers'));

    // this._hotkeysService.add(new Hotkey('ctrl+alt+h', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/hoardings']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Hoarding Master'));
    // this._hotkeysService.add(new Hotkey('ctrl+alt+o', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/contracts']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Contracts'));
    // this._hotkeysService.add(new Hotkey('ctrl+alt+p', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/plans']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Plans'));
    // this._hotkeysService.add(new Hotkey('ctrl+alt+m', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/campaigns']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Campaigns'));
    // this._hotkeysService.add(new Hotkey('ctrl+alt+s', (event: KeyboardEvent): boolean => {
    //   this.router.navigate(['/masters/settings']);
    //   return false; // Prevent bubbling
    // }, undefined, 'Go to Settings'));

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, "online").pipe(mapTo(true)),
      fromEvent(window, "offline").pipe(mapTo(false))
    );
  }

  ngOnInit() {
    this.emailId = utils.getLoggedInUserEmail();
    this.tenantId = localStorage.getItem("id");

    this.authenticated = localStorage.getItem("access_token") ? true : false;
    this.subscriptionActive = JSON.parse(
      localStorage.getItem("subscriptionActive")
    );

    this.online$.subscribe((isConnected) => {
      if (isConnected && this.refresh) {
        //when internet goes offline first
        this.showRefreshScreenDialog = true;
      } else {
        this.refresh = true;
      }
    });

    // this.actionDispatcher.industries.subscribe((industryList) => {
    //   this.industryList = industryList;
    // });

    // this.actionDispatcher.groups.subscribe((groupList) => {
    //   this.groupList = groupList;
    // });

    // this.actionDispatcher.contractTypes.subscribe((contractTypeList) => {
    //   this.contractTypeList = contractTypeList;
    // });

    // this.actionDispatcher.areas.subscribe((areaList) => {
    //   this.areaList = areaList;
    // });

    this.checkForUserInfo();

    this.packageService.$packageSubscribed.subscribe((value) => {
      if (value) {
        this.subscriptionActive = true;
      }
    });

    this.packageService.$subscriptionActive.subscribe((value) => {
      if (value) {
        this.subscriptionActive = value;
      }
    });

    /**
     * When the user logs in then Get API access token
     * Programmer: Sanchit Mirg
     *
     * @memberof DashboardDemo
     */

    //first when user gets the profile information after login from auth End.
    this.auth.emitProifle.subscribe(
      (profile) => {
        //after getting the profile get api access token api is called to get the api access token
        if (profile) {
          // this.authApiService.getUserManagmentApiAccessToken().subscribe((response) => {
          //   localStorage.setItem('usermanagement_access_token', response['access_token'])
          // })

          // this.authApiService.getApiAccessToken().subscribe(
          //   (response) => {
          //     // storing the api_access_tokin in the local storage
          //     localStorage.setItem(
          //       "api_access_token",
          //       response["access_token"]
          //     );

          //after getting the api access token we are getting the userinfo based on email Id
          this.authApiService.getUserInfoByEmail(profile).subscribe(
            (userInfo) => {
              if (userInfo) {
                //setting the user info in the local storage
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                if (
                  userInfo &&
                  userInfo[0]["app_metadata"]["authorization"][
                    "permissions"
                  ].indexOf("readAll") > -1
                ) {
                  this.customerUiService.$userRole.next(
                    LocalUserRoleEnum["ADMIN"]
                  );
                } else {
                  this.customerUiService.$userRole.next(null);
                }

                // setting the auth service authSet subject to true so that we can enable chat
                this.auth.authSet.next(true);
                this.addCrossSiteEventListener();

                //validating the user if user is loggin for the first time
                //or if there is no information for that user in the backend
                this.validateUserFirstLogin();
              }
            },
            (error) => {
              // console.log("Error in UBE API", error);
            }
          );
          //   },
          //   (error) => {
          //     console.log("Error in fetching AAT", error);
          //   }
          // );
        }
      },
      (error) => {
        // console.log("Error in fetching UP", error);
      }
    );

    //calling the validate user - login independently if user refreshes the screen
    this.countDown();
    setInterval(() => {
      this.countDown();
    }, 1000);
    this.validateUserFirstLogin();

    this.addCrossSiteEventListener();

    const previousToken = localStorage.getItem("deviceToken");
    this.messagingService.requestPermission(previousToken);
    this.message = this.messagingService.currentMessage;
    this.messagingService.receiveMessage();
    const self = this;
    navigator.serviceWorker
      ? navigator.serviceWorker.addEventListener(
          "message",
          function (event) {
            if (event.data.firebaseMessagingData) {
              const data = event.data.firebaseMessagingData.data;
              const notificationTitle = data["01_title"];
              const notificationLink = data["02_link"];
              const notificationData = utils.sort(Object.entries(data));

              let notificationBody = "";

              for (let i = 3; i < notificationData.length; i++) {
                const title = notificationData[i][0].substr(3);
                notificationBody +=
                  WebNotificationsBodyEnum[title] +
                  ": " +
                  notificationData[i][1] +
                  (i !== notificationData.length - 1 ? "<br>" : "");
              }
              const src = "../assets/notification-sound/pristine.mp3";

              const audio = new Audio(src);
              audio.play();
              self.notificationService.info(
                notificationBody,
                notificationTitle,
                true,
                true,
                notificationLink
              );
              self.webNotificationService
                .create(
                  { customId: data["03_customId"], emailId: self.emailId },
                  null,
                  AppUrls.MARK_READ
                )
                .subscribe();
              self.webNotificationService.unreadCountSubject.next("increment");
            } else if (event.data === "Notification Closed") {
              self.webNotificationService.unreadCountSubject.next("decrement");
              self.webNotificationService
                .create(
                  { customId: event.data[2], emailId: self.emailId },
                  null,
                  AppUrls.MARK_READ
                )
                .subscribe();
            } else if (event.data[0] === "Notification Clicked") {
              window.open(event.data[1], "_blank");
              self.webNotificationService
                .create(
                  { customId: event.data[2], emailId: self.emailId },
                  null,
                  AppUrls.MARK_READ
                )
                .subscribe();
              self.webNotificationService.unreadCountSubject.next("decrement");
            } else {
              self.webNotificationService.unreadCountSubject.next("increment");
              const src = "../assets/notification-sound/pristine.mp3";
              const audio = new Audio(src);
              audio.play();
            }

            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
          },
          false
        )
      : "";
  }

  addCrossSiteEventListener() {
    // check for browser support
    if (window.addEventListener) {
      let chatLocal = this;

      // message handler
      window.addEventListener(
        "message",
        function (e) {
          // check message origin
          if (
            typeof e.data != "undefined" &&
            e.data.event_id != undefined &&
            e.data.event_id == "sib-chat-frame"
          ) {
            $("#chat-box").slideToggle(function () {
              //Chat button toggle
              $("#chat-button").slideToggle(100);
            });
          } else if (
            typeof e.data != "undefined" &&
            e.data.event_id != undefined &&
            e.data.event_id == "sib-chat-count"
          ) {
            chatLocal.chatBaseService.unreadMsgCnt.next(e.data.chat_count);
          }
        },
        false
      );
    }
  }

  checkForUserInfo() {
    if (localStorage.getItem("userInfo")) {
      this.auth.authSet.next(true);
    }
  }

  onRightPanelClick() {}

  countDown() {
    this.deadlineDate = new Date("Nov 30, 2018 00:00:00").getTime();
    var currentDate = new Date().getTime();

    var seconds_left = (this.deadlineDate - currentDate) / 1000;

    this.days = Math.floor(seconds_left / 86400);
    seconds_left = seconds_left % 86400;

    this.hours = Math.floor(seconds_left / 3600);
    seconds_left = seconds_left % 3600;

    this.minutes = Math.floor(seconds_left / 60);
    this.seconds = Math.floor(seconds_left % 60);
  }

  /**
   * validating if the user is logging in for the first.
   * Programmer: Sanchit Mirg.
   *
   * @memberof DashboardDemo
   */
  validateUserFirstLogin() {
    this.isLoading = true;
    //getting the userDetails from local storage
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    this.userService.user.next(userDetails);
    //calling the userService to check if the user by that email id already exist or not
    if (userDetails && userDetails[0]) {
      this.userService
        .get(null, "/search/byEmailId?emailId=" + userDetails[0].email)
        .subscribe(
          (user) => {
            //checking if the user is logging in for the first or user does not exist in the backend.
            if (user && user.userRegistered === false) {
              //setting default userInfo
              /* this.user.emailId = userDetails[0].email
            this.user.authId = userDetails[0].user_id */
              this.user = user;
              this.user.roles = [
                ...this.user.roles,
                ...this.mapUserRole(
                  userDetails[0].app_metadata["authorization"]["roles"]
                ),
              ];

              //toggling the user Detail Dialog.
              this.displayUserDetailDialog = true;
              this.isLoading = false;
            } else if (user && user.userRegistered === true) {
              this.isLoading = false;
            }
            localStorage.setItem("user-by-email", JSON.stringify(user));
            this.userService.setDashboardData.next(user);
          },
          (error) => {
            // this.confirm();
            // console.log("Error in byEmailId API", error);
            this.isLoading = false;
          }
        );
    }
  }

  // confirm() {
  //   this.confirmationService.confirm({
  //     message: "Please contact ShowItMax support.",
  //     accept: () => {
  //       this.validateUserFirstLogin();
  //     },
  //     reject: () => {
  //       this.validateUserFirstLogin();
  //     },
  //   });
  // }

  mapUserRole(authRoles) {
    var userRole = [];

    for (let item in SibUserRoleEnum) {
      if (authRoles.indexOf(SibUserRoleEnum[item]) !== -1) {
        userRole.push(item);
      }
    }
    return userRole;
  }

  /**
   * Calling this function to save User
   * Programmer: Sanchit Mirg
   *
   * @param {any} user
   * @memberof DashboardDemo
   */
  saveUser(user) {
    user.user.userRegistered = true;
    user.user.authPassword = this.encrDecrService.set(user.password);
    // console.log("password", user.user.password)
    // console.log("encrpted", user.user.authPassword)
    // calling the service to create user.
    this.userService.create(user.user, null, "/update").subscribe(
      (user) => {
        this.notificationServcie.success(
          "User saved Successfully",
          "User Save"
        );
        this.displayUserDetailDialog = false;
      },
      (error) => {
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          this.notificationServcie.info(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TRY_AGAIN,
            NotificationsMessages.INFORMATION
          );
        } else {
          this.notificationServcie.error(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
        }
      }
    );
  }

  closeDialog() {
    this.displayUserDetailDialog = false;
    this.validateUserFirstLogin();
  }

  goToSubscriptionPlansPage() {
    this.router.navigate(["/subscription-plans"]);
  }

  checkForCurrentPageUrl() {
    return window.location.href.indexOf("/subscription-plans") > 0
      ? true
      : false;
  }

  refreshPage() {
    let url = window.location.href;
    window.open(url, "_self");
  }
}
