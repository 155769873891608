import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Notifications, AlertType } from '../modals/notifications';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { WebNotificationService } from '../services/shared/web-notifications.service';

@Component({
  selector: 'sib-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: Notifications[] = [];
  life: number = 5000;
  constructor(
    private notificationsService: NotificatoinsService,
    private webNotificationService: WebNotificationService
  ) { }

  ngOnInit() {
    this.notificationsService.getAlert().subscribe((notification: Notifications) => {
      if (!notification) {
        this.notifications = [];
        return;
      }
      this.notifications.push(notification);

      if (notification.severity === AlertType['0']) {
        setTimeout(() => {
          this.notificationsService.clear();
        }, 3000)
      } else if (notification.severity === AlertType['1']) {
        setTimeout(() => {
          this.notificationsService.clear();
        }, 15000)

      } else {
        setTimeout(() => {
          this.notificationsService.clear();
        }, 10000)
      }

    });
  }

  ngOnDestroy() {
    this.notificationsService.clear();
  }

  onClose(event) {
    let index = this.notifications.indexOf(event.message);
    this.notifications.splice(index, 1);
    if (event.message.notify) {
      this.webNotificationService.unreadCountSubject.next("decrement")
    }
    // this.notifications = [];
  }

  onNotificationClicked(event) {
    let index = this.notifications.indexOf(event.message);
    this.notifications.splice(index, 1);
    if (event.message.notify) {
      this.webNotificationService.unreadCountSubject.next("decrement");
      window.open(event.message.link)
    }


  }
}
