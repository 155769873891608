import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../helpers/date.util';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { MountImageStatus } from '../../shared/constants/mount-image-status-enum';

export const UnmountingReportColumns: Array<GridColumn> = [
  {
    field: 'srNo',
    name: 'srNo',
    header: 'Sr ',
    required: true,
    width: '2.7vw',  // changed from 3vw to 2.7vw --> Raveena
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: false,
    styleClass: 'sr-number',
    type: EditableGridFieldTypes.CUSTOM
  },
  {
    field: 'customId',
    name: 'HID',
    header: 'HID',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    width: '8vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    }
  },
  {
    field: 'district',
    name: 'district',
    header: 'District',
    required: true,
    editable: false,
    hidden: false,
    permanent: false,
    width: '7vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
  },
  {
    field: 'city',
    name: 'city',
    header: 'City',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    width: '7vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    }
  },
  {
    field: 'areaName',
    name: 'area',
    header: 'Area',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    width: '7vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    /* displayFn: (data) => {
      if (data.area) {
        return data.area.name;
      } else {
        return '-';
      }
    } */
  },
  {
    field: 'location',
    name: 'location',
    header: 'Location',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    width: '20vw',
    default: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
  },
  {
    field: 'size',
    name: 'size',
    header: 'Size',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    displayFn: (data) => {
      if (data.size) {
        return data.size;
      } else {
        return '-';
      }
    }
  },
  {
    field: 'employeeName',
    name: 'employee',
    header: 'Employee',
    required: true,
    editable: false,
    sortable: true,
    permanent: false,
    default: true,
    width: '7vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    displayFn: (data) => {
      if (data.employeeName) {
        return data.employeeName;
      } else {
        return '-';
      }
    }
  },
  {
    field: 'displayName',
    name: 'displayName',
    header: 'Display Name',
    required: true,
    editable: false,
    sortable: false,
    permanent: true,
    hidden: false,
    width: '8vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    displayFn: (data) => {
      if (data) {
        return data.displayName;
      } else {
        return '-';
      }
    }
  },
  {
    field: 'unmountingItems.mounter.fullName',
    name: 'mounter',
    header: 'Mounter',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    width: '7vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'in',
      placeHolder: 'Search',
      custom: true,
    },
    displayFn: (data) => {
      if (data && data.unmountingItems && data.unmountingItems.mounter && data.unmountingItems.mounter.id) {
        return data.unmountingItems.mounter.firstName + ' ' + data.unmountingItems.mounter.lastName;
      } else {
        return '-';
      }
    }
  },
  {
    field: 'itemStartDate',
    name: 'itemStartDate',
    header: 'Item St. Dt.',
    required: true,
    editable: false,
    sortable: true,
    width: '7vw',   //mentioned width for campaign start date ---> Raveena
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data) {
        return DateUtil.dategridFormatter(data.itemStartDate);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'itemEndDate',
    name: 'itemEndDate',
    header: 'Item End Dt.',
    required: true,
    editable: false,
    sortable: true,
    width: '7vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data) {
        return DateUtil.dategridFormatter(data.itemEndDate);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'unmountingItems.creativeName',
    name: 'creativeName',
    header: 'Creative Name',
    required: true,
    editable: false,
    sortable: true,
    width: '9vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    displayFn: (data) => {
      if (data.unmountingItems && data.unmountingItems.creativeName !== 'Default') {
        return data.unmountingItems.creativeName
      } else {
        return '-';
      }
    },
  },
  {
    field: 'unmountingItems.date',
    name: 'mountingDate',
    header: 'Unmt. Date',
    required: true,
    editable: false,
    sortable: true,
    width: '7vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data.unmountingItems) {
        return DateUtil.dategridFormatter(data.unmountingItems.date)
      } else {
        return '-';
      }
    },
  },
  {
    field: 'unmountingItems.imageStatus',
    name: 'imageStatus',
    header: 'Status',
    required: true,
    editable: false,
    permanent: true,
    sortable: true,
    hidden: false,
    width: '8vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data && data.unmountingItems && data.unmountingItems.imageStatus) {
        return MountImageStatus[data.unmountingItems.imageStatus];
      } else {
        return '-';
      }
    },
    filter: {
      matchMode: 'in',
      placeHolder: 'Search',
      custom: true
    },
  },
];

export const UnmountingReportMap = (() => {
  return mapUtils.createMap(UnmountingReportColumns);
})();
