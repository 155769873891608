import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { Dropdown, OverlayPanel, SelectItem } from "primeng/primeng";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AdvancedSearch } from "../../../modals/hoardings/advanced-search";
import { GridActionButtonConfig } from "../../../components/sib-forms/grid/grid-action-button.config";
import { GridComponent } from "../../../components/sib-forms/grid/grid.component";
import {
  Alignment,
  EditableGridFieldTypes,
  GridColumn,
  GridConfig,
  GridPaginationEvent,
} from "../../../components/sib-forms/grid/grid.config";
import { HoardingService } from "../../../contracts/services/hoardings.service";
import { DateUtil } from "../../../helpers/date.util";
import * as utils from "../../../helpers/utils";
import { ContractHoardings } from "../../../modals/contracts/contract-hoarding";
import { AuthorityTax } from "../../../modals/hoardings/authority-tax";
import { Inventory } from "../../../modals/hoardings/inventory";
import { ImageFolderConfig } from "../../../modals/image/image-folder-config";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { HoardingMasterColService } from "../../../services/shared/hoarding-master-column.service";
import { InventoryService } from "../../../services/shared/inventory.service";
import { UploadDocumentService } from "../../../services/shared/upload-document.service";
import { UserGridConfigService } from "../../../services/shared/user-grid-config.service";
import { GridNameEnum } from "../../constants/grid-name.enum";
import { HoardingCategoryEnum } from "../../constants/hoarding-category-enum";
import { HoardingLightEnum } from "../../constants/hoarding-light-enum";
import { HoardingStatusEnum } from "../../constants/hoarding-status-enum";
import { InventoryTypeEnum } from "../../constants/inventory-type.enum";
import { CurrencyHelperPipe } from "../../helpers/currency.pipe/currencyHelperPipe";
import { PumpCategoryEnumLocal } from "../common-inventory/common-inventory.component";
import { SupplierVendorService } from "../../../contracts/services/supplier-vendor.service";
import { AppUrls } from "../../../services/urls";
import { MapMarker } from "../../../modals/map/MapMarker";

@Component({
  selector: "sib-list-hoardings",
  templateUrl: "./list-hoardings.component.html",
  styleUrls: ["./list-hoardings.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListHoardingsComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Inventory>;
  @ViewChild("doc") overlay: OverlayPanel;
  @ViewChild("ratingdd") ratingDd: Dropdown;
  @ViewChild("categorydd") categoryDd: Dropdown;
  @ViewChild("lighttypedd") lightTypeDd: Dropdown;
  @ViewChild("inventorytypedd") inventoryTypedd: Dropdown;
  @ViewChild("tradedassetdd") tradedAssetDd: Dropdown;
  @ViewChild("pumpCategorydd") pumpCategorydd: Dropdown;

  @Input() gridConfig: GridConfig<Inventory> = new GridConfig<Inventory>();
  @Input() buttonConfig: GridActionButtonConfig;
  @Input() columns: any[] = [];
  @Input() showFilter: boolean = false;
  @Input() sitesAvailableFrom = false;
  @Input() totalRecords;
  @Input() status: SelectItem[] = [];
  @Input() gridTitle: string = "";
  _src: string = null;
  @Input() set source(source) {
    this._src = source;
    this.getGridColumnConfig();
  }
  get source() {
    return this._src;
  }

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
    if (this._isExpired) {
      this.ngOnInit();
    }
  }
  get isExpired() {
    return this._isExpired;
  }

  _showGroups: boolean;
  @Input() set showGroups(showGroups) {
    this._showGroups = showGroups;
    this.toggleSwitchValue = showGroups;
  }
  get showGroups() {
    return this._showGroups;
  }

  _advanceSearch: AdvancedSearch;
  @Input() set advanceSearch(advanceSearch) {
    this._advanceSearch = advanceSearch;
    this.gridColumnConfig = this.getGridColumnConfig();
  }
  get advanceSearch() {
    return this._advanceSearch;
  }

  @Input() selected: any;
  @Input() tradedHoardings: boolean;
  @Input() isFileDownloading: boolean;
  @Input() isNotLazyNumberFilter: boolean = false;
  @Input() hideCommonInventoryDialog: boolean;

  @Output() eEmitSelectedHoardings: EventEmitter<
    ContractHoardings[]
  > = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckHoarding: EventEmitter<{
    rowData;
    rowIndex;
  }> = new EventEmitter();
  @Output() eEmitEditHoarding: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewHoarding: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eFilter: EventEmitter<any> = new EventEmitter();
  @Output() eSitesAvailableFrom: EventEmitter<any> = new EventEmitter();
  @Output() eEmitShowGroups: EventEmitter<any> = new EventEmitter();
  @Output() eEmitExportXlsx: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewHistory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImageUrl: EventEmitter<any> = new EventEmitter();

  tradedAssetDropdown: SelectItem[] = [];
  ratingDropdown: SelectItem[] = [];
  categoryDropdown: any[] = [];
  lightTypeDropdown: any[] = [];
  statusDropdown: any[] = [];
  pumpCategoryDropdown: any[] = [];

  gridColumnConfig: Array<GridColumn>;
  hoardingGroup: FormGroup;

  HoardingStatusEnum: HoardingStatusEnum;

  @Input() selectedHoardings: ContractHoardings[] = [];

  rowData: any;
  rowIndex: number;

  hmSelectedColumns: any[] = [];
  subscription: Subscription[] = [];

  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant
  displayAuthorityTaxDialog: boolean = false;
  authorityTax: AuthorityTax = new AuthorityTax();
  selectedHoardingId: string = "";
  editable: boolean = false;
  dFooter: string = "";
  changes: boolean = false;
  customId: string = "";

  displayUploadDocDialog: boolean = false;
  imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();
  clonedRowData: any;
  paginationEvent: GridPaginationEvent;
  hoardings: any;
  hoarding: any;
  inventories: Inventory[]; // for row expansion
  inventoryTypes: any[] = [];
  selectedColumns: any[] = [];
  toggleSwitchValue: boolean = false;
  showXlsExport: boolean = false;
  disableExportXlsx: boolean = false;

  supplierOptions: SelectItem[] = [];
  searchEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    sortField: "updatedDate",
    sortOrder: 1,
    filters: {},
    globalFilter: "",
  };
  showThumbImageLoader: boolean = false;
  inventoryCustomId: string = null;
  imageUrl: string = null;

  isMapVisible: boolean = false;
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  mapDialogHeader: string = "Inventory Location";

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private cd: ChangeDetectorRef,
    private uploadDocumentService: UploadDocumentService,
    private notificationServcie: NotificatoinsService,
    private hoardingService: HoardingService,
    private inventoryService: InventoryService, // private notificationServcie: NotificatoinsService
    private supplierVendorService: SupplierVendorService
  ) {
    // this.cd.detach();
  }

  ngOnInit() {
    // this.showUploadDocDialog();
    // this.hideUploadDocDialog();
    // this.hoardingService.getHoardingsByContractId(this.id).subscribe((response) => {
    //   if(response) {
    //     console.log(response)
    //     this.hoardings = response;
    //     console.log(this.hoardings);
    //     // console.log(this.hoardings[this.rowIndex].documents);
    //     // this.hoardings.forEach((hoarding) => {
    //     //   this.hoarding = hoarding;
    //     // })
    //   }
    // });
    this.gridColumnConfig = this.getGridColumnConfig();
    this.setFormGroupObjects();
    this.tradedAssetDropdown = [
      { label: "All", value: "ALL" },
      { label: "True", value: true },
      { label: "False", value: false },
    ];

    if (!this.hideCommonInventoryDialog) {
      this.getUserGridConfig();
    }

    this.categoryDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingCategoryEnum).forEach((key) => {
      this.categoryDropdown.push({
        label: HoardingCategoryEnum[key],
        value: key,
      });
    });

    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({
        label: HoardingLightEnum[light],
        value: light,
      });
    });

    this.statusDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingStatusEnum).forEach((status) => {
      this.statusDropdown.push({
        label: HoardingStatusEnum[status],
        value: status,
      });
    });

    this.inventoryTypes.push({ label: "All", value: "ALL" });
    Object.keys(InventoryTypeEnum).forEach((type) => {
      this.inventoryTypes.push({ label: InventoryTypeEnum[type], value: type });
    });

    this.pumpCategoryDropdown.push({ label: "All", value: "ALL" });
    Object.keys(PumpCategoryEnumLocal).forEach((key) => {
      this.pumpCategoryDropdown.push({
        label: PumpCategoryEnumLocal[key],
        value: key,
      });
    });

    this.ratingDropdown = [
      { label: "All", value: "ALL" },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];

    this.subscription.push(
      this.hmColService.hoardingSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );

    this.subscription.push(
      this.hmColService.siteFromDate.subscribe((siteFromDate) => {
        /*  if (!siteFromDate) {
         this.gridViewChildRef.inputSitesAvailableFrom(siteFromDate)
       } else { */
        this.gridViewChildRef.selectSitesAvailableFrom(siteFromDate);
        // }
      })
    );

    this.subscription.push(
      this.hmColService.statuses.subscribe((status) => {
        if (status) {
          this.gridViewChildRef.filterByStatuses(status);
        }
      })
    );

    this.subscription.push(
      this.hmColService.globalFilter.subscribe((globalFilterData) => {
        // if(globalFilterData) {
        this.gridViewChildRef.globalFilterHoardingMaster(globalFilterData);
        // }
      })
    );

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const roles = userInfo[0].app_metadata.authorization.roles;

    this.email = userInfo[0].email;

    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
    this.setColumns();
    this.setSupplierDropdown();
    this.showXlsExport =
      utils.checkPermission("enable:excel") &&
      (this.source === "Contract" || this.source === "Hoarding Master")
        ? true
        : false;

    this.inventoryService.$thumbImageLoader.subscribe((response) => {
      response === false ? (this.showThumbImageLoader = false) : "";
    });
  }

  setFormGroupObjects() {
    this.hoardingGroup = new FormGroup({
      gridControl: new FormControl(),
    });
  }

  /**
   * @description get saved column config
   * @author Divya Sachan
   * @date 2020-02-03
   * @memberof ListHoardingsComponent
   */
  getUserGridConfig() {
    this.subscription.push(
      this.userGridConfigService
        .create([this.setGridNameEnum()])
        // .pipe(take(1))
        .subscribe((hoardingGridConfig) => {
          this.gridColumnConfig = utils.getUserConfiguredColumns(
            hoardingGridConfig[this.setGridNameEnum()],
            this.gridColumnConfig
          );
          this.gridViewChildRef.setAllColumns();
          // this.cd.detectChanges();
          // this.hmColService.hoardingDefaultColumns.next(this.gridColumnConfig);
        })
    );
  }

  getGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: "move",
        name: "",
        header: "",
        required: true,
        editable: false,
        hidden: false,
        width: "3vw",
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
        styleClass: "padding-0",
      },

      {
        field: "customId",
        name: "customId",
        header: "HID",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: "8vw",
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "contract.id",
        name: "contract",
        header: "CID",
        width: "6vw",
        required: true,
        editable: false,
        permanent: false,
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.contract) {
            return data.contract.id;
          } else {
            return "N/A";
          }
        },
      },

      {
        field: "district",
        name: "district",
        header: "District",
        required: true,
        editable: false,
        width: "7vw",
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "city",
        name: "city",
        header: "City",
        required: true,
        editable: false,
        hidden: false,
        width: "7vw",
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "areaName",
        name: "area",
        header: "Area",
        required: true,
        width: "9vw",
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        // default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        /* displayFn: (data) => {
          if (data.area) {
            return data.area.name;
          } else {
            return '-';
          }
        } */
      },
      {
        field: "location",
        name: "location",
        header: "Location",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: "20vw",
        default: true,
        sortable: true,
        styleClass: "text-right",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      // {
      //   field: 'map',
      //   name: 'map',
      //   header: 'Map',
      //   width: '4vw',
      //   required: true,
      //   permanent: false,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   type: EditableGridFieldTypes.CUSTOM,

      // },
      {
        field: "width",
        name: "width",
        header: "W",
        width: "50px",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.NUMBER,
        displayFn: (data) => {
          if (data.width) {
            return data.width;
          } else {
            return "-";
          }
        },
      },
      {
        field: "height",
        name: "height",
        header: "H",
        width: "50px",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.height) {
            return data.height;
          } else {
            return "-";
          }
        },
      },
      {
        field: "size",
        name: "size",
        header: "Size",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        // default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "quantity",
        name: "quantity",
        header: "Qty",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        // default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.quantity) {
            return data.quantity;
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "inventoryType",
        name: "inventoryType",
        header: "Type",
        width: "8vw", // changed qty width from  4vw to 7vw -->Raveena
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.inventoryType) {
            return InventoryTypeEnum[data.inventoryType];
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
      },
      {
        field: "squareFeet",
        name: "squareFeet",
        header: "Total SqFt",
        width: "5.5vw",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        styleClass: "text-right",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.squareFeet) {
            return this.currencyPipe.transform(Math.ceil(data.squareFeet));
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "lightType",
        name: "lightType",
        header: "Light",
        required: true,
        editable: false,
        width: "4.5vw",
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return "-";
          }
        },
      },
      {
        field: "geoCoordinate.latitude",
        name: "geoCoordinate.latitude",
        header: "Latitude",
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        sortable: true,
        default: false,
        width: "7vw",
        styleClass: "word-break-wrap",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.latitude;
          } else {
            return "N/A";
          }
        },
      },
      {
        field: "geoCoordinate.longitude",
        name: "geoCoordinate.longitude",
        header: "Longitude",
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        width: "7vw",
        styleClass: "word-break-wrap",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.longitude;
          } else {
            return "N/A";
          }
        },
      },
      {
        field: "image",
        name: "",
        header: "Photos",
        required: true,
        editable: false,
        permanent: true,
        // width: "4.5vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.LEFT,
      },
      {
        field: "grossPrice",
        name: "grossPrice",
        header: "Gross Price",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return "-";
          }
        },
      },
      {
        field:"pricePerSqFt",
        name:'pricePerSqFt',
        header:'Price/Sq.Ft',
        required:true,
        editable:false,
        hidden:false,
        default:false,
        permanent:false,
        sortable:true,
        type: EditableGridFieldTypes.TEXT,
        filter:{
          matchMode:'relational',
          placeHolder:'Search',
          custom:false
        },
        displayFn: (data) => {
          if(data.grossPrice && data.squareFeet){
            return this.currencyPipe.transform((data.grossPrice/data.squareFeet).toFixed(2));
          }
          else{
            return "-"
          }
        }
      },
      {
        field: "minimumPrice",
        name: "minimumPrice",
        header: "LSP",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: "5.2vw", //width:'7vw';
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: this.isNotLazyNumberFilter ? "equals" : "relational",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return "-";
          }
        },
      },
      {
        field: "startDate",
        name: "startDate",
        header: "Start Date",
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: true,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.startDate) {
            return DateUtil.dategridFormatter(data.startDate);
          }
        },
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: true
        // },
      },
      {
        field: "endDate",
        name: "endDate",
        header: "End Date",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: true,
        width: "7vw",
        displayFn: (data) => {
          if (data.endDate) {
            return DateUtil.dategridFormatter(data.endDate);
          }
        },
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: true
        // },
      },
      {
        field: "campaignStartDate",
        name: "campaignStartDate",
        header: "Campaign Start Date",
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: false,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "campaignEndDate",
        name: "campaignEndDate",
        header: "Campaign End Date",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate);
          } else {
            return "-";
          }
        },
      },
      // {
      //   field: 'status',
      //   name: 'status',
      //   header: 'Status',
      //   required: true,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   // displayFn: (data) => {
      //   //   if (data.isActive) {
      //   //     return 'Active';
      //   //   } else {
      //   //     return 'Inactive';
      //   //   }
      //   // }
      // },
      {
        field: "statusQueues",
        name: "statusQueues",
        header: "Status",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: "7.5vw",
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "statusQueues.displayName",
        name: "displayName",
        header: "Display Name",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: "9vw",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "statusQueues.employee",
        name: "employeeName",
        header: "Employee",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: "9vw",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "campaignHistory",
        name: "campaignHistory",
        header: "Campaign History",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: "7.5vw",
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "rating",
        name: "rating",
        header: "Rating",
        required: true,
        editable: false,
        default: false,
        width: "5vw",
        permanent: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.rating) {
            return data.rating;
          } else {
            return "-";
          }
        },
      },

      {
        field: "tradedAsset",
        name: "tradedAsset",
        header: "Traded Asset",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        width: "5.5vw",
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.tradedAsset) {
            return "True";
          } else {
            return "False";
          }
        },
      },
      {
        field: "supplierVendor.name",
        name: "supplierVendor.name",
        header: "Vendor Name",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: "9vw",
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "in",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.supplierVendor && data.supplierVendor.name) {
            return (
              data.supplierVendor.name +
              (data.supplierVendor.code
                ? "(" + data.supplierVendor.code + ")"
                : "")
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "pumpName",
        name: "pumpName",
        header: "Pump Name",
        required: true,
        width: "8vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.pumpName) {
            return data.pumpName;
          } else {
            return "-";
          }
        },
      },

      {
        field: "pumpCode",
        name: "pumpCode",
        header: "CC Code",
        required: true,
        width: "7vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.pumpCode) {
            return data.pumpCode;
          } else {
            return "-";
          }
        },
      },

      {
        field: "pumpCategory",
        name: "pumpCategory",
        header: "Pump Category",
        required: true,
        width: "9vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.pumpCategory) {
            return PumpCategoryEnumLocal[data.pumpCategory];
          } else {
            return "-";
          }
        },
      },
      {
        field: "groupMCode",
        name: "groupMCode",
        header: "Group M Code",
        required: true,
        editable: false,
        permanent: false,
        sortable: true,
        width: "7vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.groupMCode) {
            return data.groupMCode;
          } else {
            return "-";
          }
        },
      },
      {
        field: "groupMOldRate",
        name: "groupMOldRate",
        header: "Group M Old Rate",
        required: true,
        editable: false,
        permanent: false,
        width: "7vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.groupMOldRate) {
            return this.currencyPipe.transform(data.groupMOldRate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "trafficFrom",
        name: "trafficFrom",
        header: "Traffic From",
        required: true,
        editable: false,
        permanent: false,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        sortable: true,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.trafficFrom) {
            return data.trafficFrom;
          } else {
            return "-";
          }
        },
      },
      {
        field: "trafficTo",
        name: "trafficTo",
        header: "Traffic To",
        required: true,
        editable: false,
        permanent: false,
        sortable: true,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.trafficTo) {
            return data.trafficTo;
          } else {
            return "-";
          }
        },
      },
      {
        field: "note",
        name: "note",
        header: "Note",
        required: true,
        editable: false,
        permanent: false,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.note) {
            return data.note;
          } else {
            return "-";
          }
        },
      },
      {
        field: "remarks",
        name: "remarks",
        header: "Remarks",
        required: true,
        editable: false,
        permanent: false,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => { 
          if (data.remarks) {
            return data.remarks;
          } else {
            return "-";
          }
        },
      },
      {
        field: "documents",
        name: "documents",
        header: "Doc",
        required: true,
        width: "4vw",
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "upload",
        name: "",
        header: "",
        required: true,
        width: "4vw",
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "id",
        name: "",
        header: "Edit",
        required: true,
        permanent: true,
        editable: false,
        width: "3vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "authorityTax",
        name: "",
        header: "",
        required: true,
        permanent: true,
        editable: false,
        width: "3vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
    ];

    const filteredColumns = this.filterColumns(columns);

    return filteredColumns;
  }

  filterColumns(columns: any[]) {
    columns.forEach((column) => {
      if (this.columns.includes(column.field)) {
        column.hidden = false;
      } else {
        column.hidden = true;
      }
    });
    if (this.source !== "Contract") {
      columns = columns.filter(
        (col) => col.field !== "startDate" && col.field !== "endDate"
      );
      if (this.source === "Hoarding Master") {
        columns = columns.filter((col) => col.field !== "tradedAsset");
      }
    } else {
      columns = columns.filter((col) => col.field !== "contract.id");
    }
    if (!utils.checkPermission("read:hoarding-pump-details")) {
      columns = columns.filter(
        (item) =>
          item.field !== "pumpCode" &&
          item.field !== "pumpName" &&
          item.field !== "pumpCategory"
      );
    }
    if (!this.advanceSearch || !Object.keys(this.advanceSearch).length) {
      columns = columns.filter((col) => col.field !== "campaignHistory");
    }
    return columns;
  }

  ngAfterViewInit() {
    this.subscription.push(
      this.hmColService.hoardingDefaultColumns.subscribe(
        (hoardingDefaultColumns) => {
          hoardingDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });

          if (this.selectedColumns.length > 0) {
            this.hmColService.hoardingSelectedColumns.next(
              this.selectedColumns
            );
          }
        }
      )
    );
  }

  onSelectionChange(event) {
    this.selectedHoardings = [];
    this.selectedHoardings = event.slice();
    this.eEmitSelectedHoardings.emit(this.selectedHoardings);
  }

  addAuthorityTax(rowData) {
    this.authorityTax = new AuthorityTax();
    this.customId = rowData.customId;
    this.selectedHoardingId = rowData.id;
    this.displayAuthorityTaxDialog = true;

    if (rowData.authorityTax.licenseNo !== null) {
      this.authorityTax.licenseNo = rowData.authorityTax.licenseNo;
      this.authorityTax.startDate = new Date(rowData.authorityTax.startDate);
      this.authorityTax.endDate = new Date(rowData.authorityTax.endDate);
      this.authorityTax.taxAmount = rowData.authorityTax.taxAmount;
      this.editable = true;
      this.changes = false;
    }

    this.cd.detectChanges();
    if (this.editable) {
      this.dFooter = "UPDATE";
    } else {
      this.dFooter = "SAVE";
    }
  }

  saveAuthorityTax() {
    this.inventoryService
      .create(
        this.authorityTax,
        null,
        "/updateAuthorityTax/" + this.selectedHoardingId
      )
      .subscribe((response) => {
        if (!this.editable) {
          this.notificationServcie.success(
            "Tax Saved successfully",
            "Tax Saved"
          );
        } else {
          this.notificationServcie.success(
            "Tax Updated successfully",
            "Tax Updated"
          );
        }
        this.hideDialog();
        this.refreshGrid();
      });
  }

  resetData() {
    this.authorityTax = new AuthorityTax();
  }

  handleChange() {
    this.changes = true;
  }

  hideDialog() {
    this.displayAuthorityTaxDialog = false;
    this.editable = false;
    this.cd.detectChanges();
  }
  checkHoarding(rowData, rowIndex) {
    this.eEmitCheckHoarding.emit({ rowData, rowIndex });
  }

  editHoarding(rowData) {
    this.eEmitEditHoarding.emit(rowData);
  }

  viewImages(rowData) {
    this.eEmitViewImage.emit(rowData);
  }

  viewHoarding(rowData) {
    this.eEmitViewHoarding.emit(rowData);
  }

  viewDetail(rowData) {
    this.eEmitViewDetail.emit(rowData);
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }
  resetAndReloadTable() {
    this.gridViewChildRef.resetAndReloadTable();
    this.resetDropDownFilters();
  }

  refresh() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.loadData();
    }
  }

  deselectAllRows() {
    this.gridViewChildRef.deselectAllRows();
  }

  /**
   * @description on hoarding selection
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} rowData
   * @memberof ListHoardingsComponent
   */
  rowSelect(rowData) {
    if (this.selected && this.selected.length) {
      if (rowData && rowData.length) {
        rowData.forEach((row) => {
          var findIndex = this.selected.findIndex(
            (selectedRow) => selectedRow.id === row.id
          );
          findIndex === -1 ? this.selected.push(row) : this.selected;
        });
      } else {
      }
    } else {
      this.selected = rowData;
    }
    // if (rowData && rowData.length) {
    //   if (this.selected && this.selected.length) {
    //     rowData.forEach(row => {
    //       var findIndex = this.selected.findIndex(selectedRow => selectedRow.id === row.id);
    //       (findIndex === -1) ? this.selected.push(row) : this.selected;
    //     });
    //   } else {
    //     this.selected = rowData;
    //   }
    // } else {
    //   this.selected = rowData;
    // }
    this.eRowSelection.emit(this.selected);
  }

  /**
   * @description deselect single row
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} event
   * @memberof ListHoardingsComponent
   */
  deselectRow(event) {
    if (this.selected && this.selected.length) {
      if (event) {
        this.selected = this.selected.filter(
          (selectedRow) => selectedRow.id !== event.id
        );
        this.gridViewChildRef.previousSeleceted = this.gridViewChildRef.previousSeleceted.filter(
          (previousSelectedRow) => previousSelectedRow.id !== event.id
        );
      }
    }
    this.eRowSelection.emit(this.selected);
  }

  /**
   * @description deselect all rows
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} event
   * @memberof ListHoardingsComponent
   */
  deselectAllRow(event) {
    if (event && event.length) {
      if (this.selected && this.selected.length) {
        event.forEach((row) => {
          this.selected = this.selected.filter(
            (selectedRow) => selectedRow.id !== row.id
          );
        });
      } else {
      }
    } else {
      this.selected = [];
    }
  }

  checkSelectedItems(items: any, dataKey: any) {
    this.gridViewChildRef.addToSelected(items, dataKey);
  }

  /**
   * Filtering the array from another based on the comparator.
   *
   * @param {any} totalRecords
   * @param {any} values
   * @param {any} comparator
   * @memberof ListHoardingsComponent
   */
  filterData(totalRecords, values, comparator) {
    this.gridViewChildRef.filterData(totalRecords, values, comparator);
  }

  pushData(data, value) {
    data.push(value);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  pushMultipleValues(data, values) {
    data.push(...values);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  /**
   * for navigation on display name
   *
   * @param {any} rowData
   * @memberof ListHoardingsComponent
   */
  navigate(rowData) {
    if (rowData.statusQueues[0].module === "Campaign") {
      window.open("/campaigns/view/" + rowData.statusQueues[0].id);
    } else {
      window.open("/plans/view/" + rowData.statusQueues[0].id);
    }
  }

  /**
   *to get status value
   *
   * @param {any} status
   * @returns
   * @memberof ListHoardingsComponent
   */
  getValue(status) {
    return HoardingStatusEnum[status];
  }

  resetDataTable() {
    this.resetDropDownFilters();
    this.gridViewChildRef.resetDataTable();
  }

  resetDropDownFilters() {
    this.ratingDd ? (this.ratingDd.selectedOption = null) : "";
    this.lightTypeDd ? (this.lightTypeDd.selectedOption = null) : "";
    this.inventoryTypedd ? (this.inventoryTypedd.selectedOption = null) : "";
    this.categoryDd ? (this.categoryDd.selectedOption = null) : "";
    this.tradedAssetDd ? (this.tradedAssetDd.selectedOption = null) : "";
    this.pumpCategorydd ? (this.pumpCategorydd.selectedOption = null) : "";
  }

  updatingUserGridConfig(event) {
    let hoardingGridConfig: Map<string, string> = new Map();
    hoardingGridConfig = utils.getGridConfigMap(event, this.gridColumnConfig);
    this.userGridConfigService
      .patch(
        utils.buildObject(hoardingGridConfig),
        null,
        this.setGridNameEnum()
      )
      .pipe(take(1))
      .subscribe((result) => {});
  }

  getModifiedColumns() {
    return this.gridViewChildRef.getModifiedColumns();
  }

  addToSelectedHoardings(hoardings) {
    this.selectedHoardings = [...this.selectedHoardings, ...hoardings];
    this.gridViewChildRef.addToExistingSelected(hoardings);
    this.cd.detectChanges();
  }

  showOverlay($event, rowData, rowIndex) {
    this.clonedRowData = _.cloneDeep(rowData);
    this.rowData = rowData;
    this.rowIndex = rowIndex;
    this.overlay.show($event);
  }

  uploadDoc(rowData) {
    this.clonedRowData = _.cloneDeep(rowData);
    this.displayUploadDocDialog = true;
    this.setImageFolderConfig();
  }

  setImageFolderConfig() {
    this.imageFolderConfig.itemId = this.clonedRowData.id;
    this.imageFolderConfig.moduleName = "hoardings";
  }

  showUploadDocDialog() {
    this.displayUploadDocDialog = true;
  }

  hideUploadDocDialog() {
    this.displayUploadDocDialog = false;
  }

  setDocument(event) {
    this.clonedRowData.documents = [...this.clonedRowData.documents, ...event];
    this.uploadDocumentService
      .uploadInventoryDocument(
        this.clonedRowData.documents,
        this.imageFolderConfig.itemId
      )
      .subscribe((response) => {
        this.notificationServcie.success(
          "Document Uploaded Successfully",
          "Document Upload"
        );
        this.displayUploadDocDialog = false;
        this.gridViewChildRef.refresh();
      });
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.hmColService.hoardingDefaultColumns.next([]);
    this.hmColService.statuses.next(undefined);
    // this.hmColService.globalFilter.next(undefined);
  }

  setColumns() {
    this.gridColumnConfig.filter((col) => {
      switch (col.field) {
        case "id": {
          col.hidden =
            this.isExpired || this.source === "addHoardingsFromCampaign";
          break;
        }
        case "inventoryType":
        case "quantity": {
          col.permanent = false;
          col.default = false;
          break;
        }
        case "statusQueues.displayName": {
          col.hidden = utils.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }

  /**
   * @description on row expansion
   * @author Raveena Nathani
   * @date 2020-01-07
   * @param {*} $event
   * @memberof ListHoardingsComponent
   */
  onRowExpand($event) {
    this.inventories = $event.data.childInventories;
  }

  /**
   * @description if a column is present in show columns
   * @author Raveena Nathani
   * @date 2020-01-07
   * @param {*} value
   * @returns
   * @memberof ListHoardingsComponent
   */
  isColumnPresent(value) {
    let result = false;
    this.hmSelectedColumns.forEach((col) => {
      if (col === value) {
        result = true;
      }
    });
    if (value === "Start Date" && this.columns.includes("startDate")) {
      result = true;
    }
    if (value === "End Date" && this.columns.includes("endDate")) {
      result = true;
    }
    if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
      if (
        value === "Campaign History" &&
        this.columns.includes("campaignHistory")
      ) {
        result = true;
      }
    }
    return result;
  }

  /**
   * @description get category value
   * @author Raveena Nathani
   * @date 2020-01-07
   * @param {*} value
   * @returns
   * @memberof ListHoardingsComponent
   */
  getCategory(value) {
    if (value !== null) {
      return HoardingCategoryEnum[value];
    } else {
      return "-";
    }
  }

  /**
   * @description get light type
   * @author Raveena Nathani
   * @date 2020-01-07
   * @param {*} value
   * @returns
   * @memberof ListHoardingsComponent
   */
  getLight(value) {
    return HoardingLightEnum[value];
  }

  /**
   * @description get inventory type
   * @author Divya Sachan
   * @date 2020-01-20
   * @param {*} value
   * @returns
   * @memberof ListHoardingsComponent
   */
  getInventoryType(value) {
    return InventoryTypeEnum[value];
  }

  /**
   * @description emit toggle value
   * @author Divya Sachan
   * @date 2020-01-10
   * @param {*} event
   * @memberof ListHoardingsComponent
   */
  onToggle(event) {
    // this.showChildren = event.checked;
    this.eEmitShowGroups.emit(event);
  }

  /**
   * @description get traded asset
   * @author Divya Sachan
   * @date 2020-01-21
   * @param {*} value
   * @returns
   * @memberof ListHoardingsComponent
   */
  getTradedAsset(value) {
    return this.inventoryService.getTradedAsset(value);
  }

  /**
   * @description export Hoarding XLS
   * @author Divya Sachan
   * @date 2020-01-30
   * @memberof ListHoardingsComponent
   */
  exportXlsx(event) {
    this.eEmitExportXlsx.emit(event);
  }

  /**
   * @description set grid name enum according to the source
   * @author Divya Sachan
   * @date 2020-01-16
   * @returns
   * @memberof ListHoardingsComponent
   */
  setGridNameEnum() {
    if (this.source === "Contract") {
      return GridNameEnum["CONTRACT_HOARDING"];
    } else {
      return GridNameEnum["HOARDING"];
    }
  }

  getPumpCategory(category) {
    return PumpCategoryEnumLocal[category];
  }

  detectChanges() {
    if (!this.cd["destroyed"]) {
      this.cd.detectChanges();
    }
  }

  showMap(inventory) {
    this.mapDialogHeader = inventory.customId
      ? "Inventory Location (" + inventory.customId + ")"
      : "Inventory Location";
    this.mapMarker = new MapMarker().setMapMarkerFromInventory(inventory);
    this.overlays.push(this.mapMarker);
    this.overlays = _.cloneDeep(this.overlays);
    if (this.overlays.length === 0) {
      return;
    } else {
      this.options = {
        center: {
          lat:
            typeof this.overlays[0].latitude === "string"
              ? parseFloat(this.overlays[0].latitude)
              : this.overlays[0].latitude,
          lng:
            typeof this.overlays[0].longitude === "string"
              ? parseFloat(this.overlays[0].longitude)
              : this.overlays[0].longitude,
        },
        zoom: 12,
      };
    }
    this.isMapVisible = true;
  }

  hideMap() {
    this.mapDialogHeader = "Inventory Location";
    this.isMapVisible = false;
    this.mapMarker = new MapMarker();
    this.overlays = [];
    this.options = {};
  }

  viewHistory(rowData) {
    this.eEmitViewHistory.emit(rowData);
  }

  setSupplierDropdown() {
    if (localStorage.getItem("supplierMultiselect")) {
      this.supplierOptions = JSON.parse(
        localStorage.getItem("supplierMultiselect")
      );
    } else {
      this.supplierVendorService
        .create(this.searchEvent, this.searchEvent, AppUrls.SEARCH)
        .subscribe((response) => {
          if (response["data"]["content"]) {
            this.supplierOptions = utils.createDropdownFromStringArray(
              response["data"]["content"].map((item) => item.name),
              false
            );
          }
        }),
        (error) => {
          this.notificationServcie.error(
            error.error.message
              ? error.error.message
              : "Unable to fetch supplier list.",
            "Please Refresh."
          );
        };
    }
  }

  viewImageOnHover(e, rowData) {
    let imageUrl =
      rowData.images && rowData.images.length
        ? rowData.images[0].thumbUrl
          ? rowData.images[0].thumbUrl
          : rowData.images[0].url
        : "";
    if (imageUrl) {
      this.showThumbImageLoader = true;
      this.imageUrl = imageUrl;
      this.inventoryCustomId = rowData.customId;
      this.eEmitImageUrl.emit([e, imageUrl]);
    } else {
      this.showThumbImageLoader = false;
      this.imageUrl = null;
      this.inventoryCustomId = null;
      this.eEmitImageUrl.emit([e, null]);
    }
  }

  hideImageOnLeave(e, rowData) {
    this.imageUrl = null;
    this.inventoryCustomId = null;
    this.eEmitImageUrl.emit([e, null]);
  }
}
