<div class="invoice-preview">
    <div *ngIf="isLoading" class="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="ui-g sort-arrange-list" *ngIf="showSortRearrange">
        <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-15 margin-bottom-3"
            (click)="onDefaultSort()" icon="fa fa-sort">
        </button>
        <label class="font-12 font-weight-500 label-vCenter padding-right-5 margin-right--1">Default Sort</label>


        <ng-container
            *ngIf="summaryGrid && summaryGrid.items && summaryGrid.items.length > 1 && invoice?.invoiceFormat !== 'WITH_ATTACHED_ANNEXURE'">
            <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-15 margin-bottom-3"
                (click)="showRowsRearrangeDialog()" icon="fa fa-arrows-alt" [disabled]="!invoice.invoiceFormat">
            </button>
            <label class="font-12 font-weight-500 label-vCenter padding-right-5 margin-right--1">Rearrange Rows</label>
        </ng-container>


        <!-- <div *ngIf="invoice?.status === 'ADMIN_EDIT_PENDING' || invoice?.status === 'ADMIN_GEN_PENDING' || invoice?.status === 'ADMIN_FULL_CN_PENDING'"
            class="padding-right-15">
            <label class="font-12 font-weight-500 label-vCenter padding-right-5 margin-right--1">Invoice
                Format</label>
            <p-dropdown [options]="invoiceFormats" [autoWidth]="false" [style]="{'width':'248px'}" name="invoiceFormat"
                [styleClass]="'invoice-format-dropdown'" placeholder="Select an Invoice Format" filter="true"
                (onChange)="onInvoiceFormatChange()" [(ngModel)]="selectedInvoiceFormat" [autoDisplayFirst]="false">
            </p-dropdown>
        </div> -->
        <div *ngIf="(checkPermission('readAll') || checkPermission('update:tally-invoice')) && ( selectedInvoiceFormat !== undefined && invoice?.invoiceFormat !== 'FULL_DETAIL') && (invoice?.status === 'ADMIN_EDIT_PENDING' || invoice?.status === 'ADMIN_GEN_PENDING' || invoice?.status === 'ADMIN_CANCEL_PENDING'  || invoice?.status === 'ADMIN_FULL_CN_PENDING')"
            class="padding-right-15">
            <label class="font-12 font-weight-500 label-vCenter padding-right-5 margin-right--1">Show Full
                Detail</label>
            <p-inputSwitch [styleClass]="'invoice-format-toggle'" name='fullDetailInvoiceFormat'
                [(ngModel)]="isInvoiceFormatFullDetail">
            </p-inputSwitch>
        </div>

    </div>
    <div class="card ui-g margin-0">
        <div class="ui-g-8 padding-bottom-0">
            <div class="ui-g">
                <div class="ui-g-6 padding-10">
                    <!-- vendor name -->
                    <div class="ui-g-12 billing-name-heading padding-0">
                        <ng-container
                            *ngTemplateOutlet="addressContentTemplate; context: {value: invoice?.supplier?.name, previousValue: tempInvoice?.supplier?.name  }">
                        </ng-container>
                    </div>
                    <!-- vendor address -->
                    <div class="ui-g-12 padding-0">
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invAdd?.address1, previousValue: getAddressComparisonObject()?.tempInvAdd?.address1  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invAdd2, previousValue: getAddressComparisonObject()?.tempInvAdd2  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'GSTIN / UIN: ' + (getAddressComparisonObject()?.invGstNo ? getAddressComparisonObject()?.invGstNo : 'N/A'), previousValue: 'GSTIN / UIN: ' + (getAddressComparisonObject()?.tempInvGstNo ? getAddressComparisonObject()?.tempInvGstNo : 'N/A')  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invStateWithCode, previousValue: getAddressComparisonObject()?.tempInvStateWithCode  }">
                            </ng-container>
                        </div>
                    </div>
                    <div class="ui-g-12 padding-left-0 padding-right-0 padding-bottom-0 padding-top-10">
                        Buyer,
                    </div>
                    <!-- customer name -->
                    <div class="ui-g-12 padding-0 billing-name-heading">
                        <ng-container
                            *ngTemplateOutlet="addressContentTemplate; context: {value: invoice?.campaign?.customer?.billingName, previousValue: tempInvoice?.campaign?.customer?.billingName  }">
                        </ng-container>
                    </div>
                    <!-- customer address -->
                    <div class="ui-g-12 padding-0">
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invAdd?.address1, previousValue: getCustomerAddComparisionObj()?.tempInvAdd?.address1 }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invAdd2, previousValue: getCustomerAddComparisionObj()?.tempInvAdd2  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'GSTIN / UIN: ' + getCustomerAddComparisionObj()?.invGstNo, previousValue: 'GSTIN / UIN: ' + getCustomerAddComparisionObj()?.tempInvGstNo  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'Place Of Supply: ' + invoice?.placeOfSupply, previousValue: 'Place Of Supply: ' + tempInvoice?.placeOfSupply  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invStateWithCode, previousValue: getCustomerAddComparisionObj()?.tempInvStateWithCode  }">
                            </ng-container>
                        </div>
                    </div>
                    <!-- sac code -->
                    <div class="ui-g-12 padding-left-0 padding-right-0 padding-bottom-0 padding-top-10 valuefont">
                        <span>
                            SAC:
                        </span>
                        <span>
                            {{sacCode}}
                        </span>
                    </div>
                </div>
                <div class="ui-g-6 padding-10">

                    <div class="ui-g padding-bottom-5">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">Invoice No</div>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.invoiceId">
                            {{invoice?.invoiceId}}

                            <span *ngIf="(invoice?.status === 'INVOICE_CANCEL' && invoice?.invoiceReferenceId)"
                                style="color: gray">({{invoice?.invoiceReferenceId}})</span>

                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="!invoice?.invoiceId">
                            N/A
                        </div>
                    </div>

                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Date', value: (invoice?.billGeneratedDate) ? getFormattedDate(invoice?.billGeneratedDate) : getFormattedDate(currentDate), previousValue: (tempInvoice?.billGeneratedDate) ? getFormattedDate(tempInvoice?.billGeneratedDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-5">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">PO No</div>
                        </div>
                        <div class="ui-g-8 padding-0 word-break" *ngIf="invoice?.purchaseOrders?.length !== 0">
                            <span *ngIf="invoice?.purchaseOrders[0]?.poNumber">
                                {{invoice?.purchaseOrders[0]?.poNumber}}
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                            <span *ngIf="!invoice?.purchaseOrders[0]?.poNumber"> -
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.purchaseOrders?.length === 0">
                            N/A
                        </div>


                        <div class="ui-g-4 padding-0" *ngIf="showChanges">
                        </div>
                        <div class="ui-g-8 padding-0 word-break"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length !== 0 && compareValues(invoice?.purchaseOrders, tempInvoice?.purchaseOrders)">
                            <span *ngIf="tempInvoice?.purchaseOrders[0]?.poNumber" class="change-data">
                                {{tempInvoice?.purchaseOrders[0]?.poNumber}}
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                            <span *ngIf="!tempInvoice?.purchaseOrders[0].poNumber"> -
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                        </div>
                        <div class="ui-g-8 padding-0 change-data"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length === 0 && compareValues(invoice?.purchaseOrders?.length, tempInvoice?.purchaseOrders?.length)">
                            N/A
                        </div>


                    </div>

                    <div class="ui-g padding-bottom-10">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">PO Date</div>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.purchaseOrders?.length !== 0">
                            <span *ngIf="invoice?.purchaseOrders[0]?.poDate"> {{invoice?.purchaseOrders[0]?.poDate |
                                date:'ddMMMyy'}}
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                            <span *ngIf="!invoice?.purchaseOrders[0]?.poDate"> -
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                        </div>

                        <div *ngIf="invoice?.purchaseOrders.length === 0" class="ui-g-8 padding-0">
                            N/A
                        </div>


                        <div class="ui-g-4 padding-0" *ngIf="showChanges">
                        </div>
                        <div class="ui-g-8 padding-0"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length !== 0  && compareValues(invoice?.purchaseOrders, tempInvoice?.purchaseOrders)">
                            <span *ngIf="tempInvoice?.purchaseOrders[0]?.poDate" class="change-data"> {{tempInvoice?.purchaseOrders[0]?.poDate |
                                    date:'ddMMMyy'}}
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                            <span *ngIf="!tempInvoice?.purchaseOrders[0]?.poDate"> -
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                        </div>

                        <div *ngIf="showChanges && tempInvoice?.purchaseOrders.length === 0  && compareValues(invoice?.purchaseOrders?.length, tempInvoice?.purchaseOrders?.length)"
                            class="ui-g-8 padding-0 change-data">
                            N/A
                        </div>


                    </div>


                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Display Name', value: invoice?.campaign?.displayName, previousValue: tempInvoice?.campaign?.displayName  }">
                        </ng-container>
                    </div>

                    <!-- <div class="ui-g padding-bottom-10">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">Invoice No</div>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.invoiceId">
                            {{invoice?.invoiceId}}

                            <span *ngIf="(invoice?.status === 'INVOICE_CANCEL' && invoice?.invoiceReferenceId)"
                                style="color: gray">({{invoice?.invoiceReferenceId}})</span>

                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="!invoice?.invoiceId">
                            N/A
                        </div>
                    </div> -->

                    <!-- <div class="ui-g padding-bottom-10">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">PO No</div>
                        </div>
                        <div class="ui-g-8 padding-0 word-break" *ngIf="invoice?.purchaseOrders?.length !== 0">
                            <span *ngIf="invoice?.purchaseOrders[0]?.poNumber">
                                {{invoice?.purchaseOrders[0]?.poNumber}}
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                            <span *ngIf="!invoice?.purchaseOrders[0]?.poNumber"> -
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.purchaseOrders?.length === 0">
                            N/A
                        </div>


                        <div class="ui-g-4 padding-0" *ngIf="showChanges">
                        </div>
                        <div class="ui-g-8 padding-0 word-break"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length !== 0 && compareValues(invoice?.purchaseOrders, tempInvoice?.purchaseOrders)">
                            <span *ngIf="tempInvoice?.purchaseOrders[0]?.poNumber" class="change-data">
                                {{tempInvoice?.purchaseOrders[0]?.poNumber}}
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                            <span *ngIf="!tempInvoice?.purchaseOrders[0].poNumber"> -
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                        </div>
                        <div class="ui-g-8 padding-0 change-data"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length === 0 && compareValues(invoice?.purchaseOrders?.length, tempInvoice?.purchaseOrders?.length)">
                            N/A
                        </div>


                    </div> -->


                    <!-- <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Date', value: (invoice?.billGeneratedDate) ? getFormattedDate(invoice?.billGeneratedDate) : 'N/A', previousValue: (tempInvoice?.billGeneratedDate) ? getFormattedDate(tempInvoice?.billGeneratedDate) : 'N/A'  }">
                        </ng-container>
                    </div> -->

                    <!-- <div class="ui-g padding-bottom-10">
                        <div class="ui-g-4 padding-0">
                            <div class="valuefont">PO Date</div>
                        </div>
                        <div class="ui-g-8 padding-0" *ngIf="invoice?.purchaseOrders?.length !== 0">
                            <span *ngIf="invoice?.purchaseOrders[0]?.poDate"> {{invoice?.purchaseOrders[0]?.poDate |
                                date:'ddMMMyy'}}
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                            <span *ngIf="!invoice?.purchaseOrders[0]?.poDate"> -
                                <a class="po-detail-button" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(invoice); poDetails.toggle($event)"
                                    *ngIf="invoice?.purchaseOrders?.length> 1"> +{{invoice?.purchaseOrders?.length -1
                                    }} more</a>
                            </span>
                        </div>

                        <div *ngIf="invoice?.purchaseOrders.length === 0" class="ui-g-8 padding-0">
                            N/A
                        </div>


                        <div class="ui-g-4 padding-0" *ngIf="showChanges">
                        </div>
                        <div class="ui-g-8 padding-0"
                            *ngIf="showChanges && tempInvoice?.purchaseOrders?.length !== 0  && compareValues(invoice?.purchaseOrders, tempInvoice?.purchaseOrders)">
                            <span *ngIf="tempInvoice?.purchaseOrders[0]?.poDate" class="change-data"> {{tempInvoice?.purchaseOrders[0]?.poDate |
                                    date:'ddMMMyy'}}
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                            <span *ngIf="!tempInvoice?.purchaseOrders[0]?.poDate"> -
                                <a class="po-detail-button change-data" (mouseleave)="poDetails.toggle($event)"
                                    (mouseenter)="openPoDetails(tempInvoice); poDetails.toggle($event)"
                                    *ngIf="tempInvoice?.purchaseOrders?.length> 1"> +{{tempInvoice?.purchaseOrders?.length -1
                                        }} more</a>
                            </span>
                        </div>

                        <div *ngIf="showChanges && tempInvoice?.purchaseOrders.length === 0  && compareValues(invoice?.purchaseOrders?.length, tempInvoice?.purchaseOrders?.length)"
                            class="ui-g-8 padding-0 change-data">
                            N/A
                        </div>


                    </div> -->


                    <div class="ui-g padding-bottom-5" *ngIf="invoice?.billStartDate  ">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'From', value: (invoice?.billStartDate) ? getFormattedDate(invoice?.billStartDate) : 'N/A', previousValue: (tempInvoice?.billStartDate) ? getFormattedDate(tempInvoice?.billStartDate) : 'N/A'  }">
                        </ng-container>

                    </div>

                    <div class="ui-g padding-bottom-10" *ngIf="invoice?.billEndDate ">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'To', value: (invoice?.billEndDate) ? getFormattedDate(invoice?.billEndDate) : 'N/A', previousValue: (tempInvoice?.billEndDate) ? getFormattedDate(tempInvoice?.billEndDate) : 'N/A'  }">
                        </ng-container>
                    </div>
                    <!-- <div class="ui-g padding-bottom-10 " *ngIf="invoice?.internalNotes">
                        <ng-container class="noteColor"
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Internal Note', value:invoice?.internalNotes, previousValue: tempInvoice.internalNotes,class:'noteColor'  }">
                        </ng-container>

                    </div> -->
                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Due', value: invoiceOverdueDate ? getFormattedDate(invoiceOverdueDate) : 'N/A', previousValue: tempInvoiceOverdueDate ? getFormattedDate(tempInvoiceOverdueDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Business By', value: invoice?.campaign?.employee?.fullName, previousValue: tempInvoice?.campaign?.employee?.fullName  }">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui-g-4 padding-bottom-0">
            <sib-invoice-summary *ngIf="invoice" [invoice]="invoice" [tempInvoice]="tempInvoice"
                [invoiceFormat]="invoiceFormat" [showChanges]="showChanges"
                (eEmitCampAndLspDiff)="setCampAndLspDiff($event)"></sib-invoice-summary>
        </div>
    </div>
    <div class="ui-g margin-top--4">
        <sib-invoice-summary-grid #summaryGrid [grouped]="invoice?.grouped" [invoice]="invoice"
            [showChildren]="showChildren"
            [invoiceFormat]="isInvoiceFormatFullDetail ? fullDetailInvoiceFormat : invoiceFormat"
            [tempInvoice]="tempInvoice" [showChanges]="showChanges" [revert]="revert"
            (eEmitRowsToRearrange)=setRowsToRearrange($event)>
        </sib-invoice-summary-grid>
    </div>
</div>


<p-overlayPanel [dismissable]="true" [styleClass]="'po-detail'" #poDetails>
    <h3>PO Details</h3>
    <table>
        <tr>
            <th>PO Name</th>
            <th>PO Number</th>
            <th>PO Date</th>
        </tr>
        <tr *ngFor="let poDetail of poDetailsList">
            <td style="word-break:break-all">{{poDetail.name}}</td>
            <td *ngIf="poDetail.poNumber" style="word-break:break-all">{{poDetail.poNumber}}</td>
            <td *ngIf="!poDetail.poNumber" style="padding-left:44px"> - </td>
            <td *ngIf="poDetail.poDate">{{poDetail.poDate | date:'ddMMMyy'}}</td>
            <td *ngIf="!poDetail.poDate" style="padding-left:39px"> - </td>
        </tr>

    </table>
</p-overlayPanel>

<ng-container *ngIf="rowsToRearrange && rowsToRearrange.length && columnsToRearrange && columnsToRearrange.length">
    <!-- <p-dialog header="Rearrange Rows" [(visible)]="displayRowsRearrangeDialog" modal="modal" showEffect="fade"
        [styleClass]="'fullscreen-dialog'" (onHide)="hideRowsRearrangeDialog()" blockScroll="true"> -->
    <sib-rows-rearrange *ngIf="displayRowsRearrangeDialog" [displayRowsRearrangeDialog]="displayRowsRearrangeDialog"
        [rows]="rowsToRearrange" [header]="'Invoice Rows'" [columns]="columnsToRearrange"
        (eEmitSaveRowsConfig)="updateRowsOrder($event)" (eEmitCancelRowsConfig)="hideRowsRearrangeDialog()">
    </sib-rows-rearrange>
    <!-- </p-dialog> -->
</ng-container>


<ng-template #contentTemplate let-key='key' let-value='value' let-class='class' let-previousValue='previousValue'>
    <div class="ui-g-4 padding-0">
        <div class="valuefont">{{key}}</div>
    </div>
    <div class="ui-g-8 padding-0">
        <div *ngIf="key === 'Display Name'" class="ui-g-12 padding-0"> <a target="_blank"
                [routerLink]="['/billings/view/',_invoice.campaign.id]">{{value}}</a></div>
        <div *ngIf="key !== 'Display Name'" class="ui-g-12 padding-0 {{class}}"> {{value}}</div>
        <div class="ui-g-12 padding-0 change-data" *ngIf="showChanges && value !== previousValue"> {{previousValue}}
        </div>
    </div>
</ng-template>

<ng-template #addressContentTemplate let-value='value' let-class='class' let-previousValue='previousValue'>
    <div class="ui-g-12 padding-0">
        {{value}}
    </div>

    <div class="ui-g-12 padding-0 change-data" *ngIf="showChanges && value !== previousValue">
        {{previousValue}}
    </div>
</ng-template>