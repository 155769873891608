/**
 * Programmer:  Sanchit Mirg
 * Date:        09-25-2017
 * Description: This service is called to check whether user is authenticated or not.
 */
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "../../environments/environment";
import { SibMasterService } from "../services/sib-master.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  userProfile: any;
  webConfig: {
    clientID: any;
    domain: any;
    responseType: any;
    audience: any;
    redirectUri: any;
    scope: any;
  };

  authConfig: {
    grant_type: string;
    client_id: string;
    client_secret: string;
    audience: string;
  };

  usermngmntConfig: {
    client_id: string;
    client_secret: string;
    audience: string;
    grant_type: string;
  };

  constructor(
    private router: Router,
    private auth: AuthService,
    private sibMasterService: SibMasterService
  ) {}
  /**
   * can activate method is called whenever user tried to navigate to component
   * that is mentioned in app.routes under canactivate
   *
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof AuthGuardService
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Programmer: Sanchit Mirg, Date: 09-25-2017
    // next is json object which contains fragment from which we get the authtoken,
    // if it exist in the fragment then calling the handleauthentication method to set
    // the userProfile and token in local storage
    let masterTokenConfig = this.auth.getMasterTokenData()
      ? this.auth.getMasterTokenData().aud
      : "";
    let envConfig = environment.masterConfigCredentials.config.audience;
    if (next.fragment) {
      this.auth.handleAuthentication();
    } else if (this.auth.isAuthenticated() && masterTokenConfig === envConfig) {
      const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
      const tokenExpired = new Date().getTime() >= expiresAt;
      if (tokenExpired) {
        this.getAuthConfig();
        return false;
      }
      return true;
    } else {
      this.sibMasterService
        .getMasterAccessToken()
        .subscribe((masterAccessToken) => {
          localStorage.setItem(
            "master_access_token",
            masterAccessToken["access_token"]
          );
          const expiresAt = JSON.stringify(
            masterAccessToken["expires_in"] * 1000 + new Date().getTime()
          );
          localStorage.setItem("master_expiry_time", expiresAt);
          this.getAuthConfig();
        });
      // this.auth.login();
      return false;
    }
  }

  domain(url) {
    return url.substring(url.indexOf("//") + 2, url.indexOf(".com") + 4);
  }

  createAuthConfig(webconfig) {
    this.webConfig = {
      clientID: webconfig["clientID"],
      domain: webconfig["domain"],
      responseType: webconfig["responseType"],
      audience: webconfig["audience"],
      redirectUri: webconfig["redirectUri"],
      scope: webconfig["scope"],
    };

    return this.webConfig;
  }

  createAuthApiConfig(sibConfig) {
    this.authConfig = {
      grant_type: sibConfig["grantType"],
      client_id: sibConfig["clientID"],
      client_secret: sibConfig["clientSecret"],
      audience: sibConfig["audience"],
    };

    return this.authConfig;
  }

  createUserMngtConfig(usermngmntConfig) {
    this.usermngmntConfig = {
      client_id: usermngmntConfig["clientID"],
      client_secret: usermngmntConfig["clientSecret"],
      audience: usermngmntConfig["audience"],
      grant_type: usermngmntConfig["grantType"],
    };
    return this.usermngmntConfig;
  }

  getAuthConfig() {
    this.sibMasterService
      .getAuthConfig(this.domain(document.location.href))
      .subscribe((authConfig) => {
        localStorage.setItem(
          "auth-web-config",
          JSON.stringify(this.createAuthConfig(authConfig["webConfig"]))
        );
        localStorage.setItem(
          "mobile-config-client-id",
          authConfig["mobileConfig"]["clientID"]
        );
        localStorage.setItem(
          "web-config-client-id",
          authConfig["webConfig"]["clientID"]
        );
        localStorage.setItem(
          "authApiUrl",
          authConfig["webConfig"]["authApiUrl"]
        );
        localStorage.setItem(
          "apiAccessTokenUrl",
          authConfig["webConfig"]["apiAccessTokenUrl"]
        );
        localStorage.setItem(
          "auth-api-config",
          JSON.stringify(this.createAuthApiConfig(authConfig["sibConfig"]))
        );
        localStorage.setItem("id", authConfig["code"]);
        localStorage.setItem(
          "usermanagement-config",
          JSON.stringify(
            this.createUserMngtConfig(authConfig["authUserManagementConfig"])
          )
        );
        localStorage.setItem(
          "auth_permission_url",
          authConfig["authUserManagementConfig"]["authPermissionUrl"]
        );
        this.sibMasterService.setAuthWebConfig(
          this.createAuthConfig(authConfig["webConfig"])
        );
        this.auth.setConfig(this.createAuthConfig(authConfig["webConfig"]));
        this.auth.login();
      });
  }
}
