<section class="hoarding-map-view">
  <section class="topbar-container">
    <ul class="topbar-list">
      <li>
        <sib-back></sib-back>
      </li>
    </ul>
    <!-- *ngIf="dscrollerPaginationEvent?.filters?.geocoordinate?.latitude || dscrollerPaginationEvent?.filters?.geocoordinate?.longitude" -->
    <ul class="topbar-reverse-list">
      <li>
        <button
          pButton
          class="mtab-save border-rad-2 reset-map-button"
          (click)="resetMap()"
          label="Reset Map"
          [disabled]="!mapZoomed"
        ></button>
      </li>
      <li class="padding-left-10 padding-right-10">
        <button
          pButton
          class="mtab-secondary c-edit-btn border-rad-2"
          (click)="toggleSidebar()"
          title="Assign To Plan"
          icon="fa fa-exchange"
        ></button>
      </li>
      <li class="padding-left-10">
        <p-calendar
          [styleClass]="'site-from-button-hm'"
          #calender
          dateFormat="ddMy"
          appendTo="body"
          placeholder=" Ex-01Jan18"
          (onInput)="inputSitesAvailableFrom($event)"
          (onSelect)="inputSitesAvailableFrom($event)"
          [utc]="true"
          [showIcon]="true"
        ></p-calendar>
      </li>
      <li class="padding-left-10 font-12">
        <span class="sites-from-text">Sites From</span>
      </li>
      <li class="padding-left-20 global-search-container">
        <input
          #globalSearch
          type="text"
          pInputText
          size="50"
          placeholder="Global Filter"
          pTooltip="{{ getCommaSeparatedFields() }}"
          (keyup)="globalFiltering(globalSearch.value)"
          class="global-search-textbox"
          tooltipPosition="left"
        />
        <span class="search-wrapper">
          <i class="fa fa-search"></i>
        </span>
      </li>
      <li class="padding-left-20" style="width: 109px">
        <p-slider
          [(ngModel)]="monthlyRateValues"
          name="monthlyRateValues"
          [style]="{ width: '8em' }"
          [range]="true"
          [min]="0"
          [max]="500000"
          (onSlideEnd)="handleMonthlyRateRange($event, $event.values)"
        >
        </p-slider>
      </li>
      <li class="padding-left-10">
        <span class="sites-from-text font-12"
          >{{ setMonthlyRateLabel() }}: ({{
            monthlyRateFrom + " - " + monthlyRateTo
          }})</span
        >
      </li>
      <li class="padding-left-10">
        <p-dropdown
          [options]="inventoryStatusDropdown"
          name="inventoryStatusDropdown"
          [(ngModel)]="selectedInventoryStatus"
          (onChange)="onInventoryStatusDropdownChange($event)"
          [style]="{ 'max-width': '120px', 'min-width': '94px' }"
          appendTo="body"
        ></p-dropdown>
      </li>
      <li class="padding-left-10">
        <span class="sites-from-text font-12">Status:</span>
      </li>
      <li class="padding-left-10">
        <p-dropdown
          [options]="inventoryTypeDropdown"
          name="inventoryTypeDropdown"
          [(ngModel)]="selectedInventoryType"
          (onChange)="onInventoryDropdownChange($event)"
          [style]="{ 'max-width': '120px', 'min-width': '94px' }"
          appendTo="body"
        ></p-dropdown>
      </li>
      <li>
        <span class="sites-from-text font-12">Type:</span>
      </li>
      <li
        *ngIf="!viewSidebar"
        class="padding-left-10 padding-right-10 selected-text"
      >
        <span class="sites-from-text font-12"
          >Selected:
          <span style="color: blue">
            {{ selectedInventories.length }}
          </span>
        </span>
      </li>

      <!-- <li class="padding-left-10">
        <span class="sites-from-text">Total: {{filteredInventories.length}}</span>
      </li> -->
    </ul>
  </section>
  <section
    class="content-container"
    [ngClass]="{ 'show-sidebar-pane': viewSidebar }"
  >
    <section *ngIf="!mapLoaded" class="loading">
      <p-progressSpinner></p-progressSpinner>
    </section>
    <section class="main-pane">
      <section class="inventory-list">
        <section *ngIf="isLoading && mapLoaded" class="loading1">
          <p-progressSpinner></p-progressSpinner>
        </section>
        <section class="no-message" *ngIf="showMessage">
          <span> No records found. </span>
        </section>

        <p-dataScroller
          #dscroller
          [value]="filteredInventories"
          [rows]="50"
          [inline]="true"
          [scrollHeight]="scrollHeight"
          (onLazyLoad)="lazyLoadHandler($event)"
          [lazy]="true"
          [totalRecords]="filteredInventories?.length"
        >
          <ng-template let-inventoryItem let-i="index" pTemplate="item">
            <section
              class="inventory-container"
              (mouseover)="cardHover(inventoryItem)"
              (mouseleave)="cardOut(inventoryItem)"
              *ngIf="filteredInventories?.length > 0"
            >
              <section class="image-container">
                <!-- style="height: 85px; width: 75px" -->
                <section *ngIf="inventoryItem.images?.length > 0">
                  <img
                    src="{{ inventoryItem.images[0].url }}"
                    class="inventory-image inventory-animate"
                  />
                </section>
                <!-- style="height: 85px; width: 75px" -->
                <section
                  *ngIf="
                    !inventoryItem ||
                    !inventoryItem.images ||
                    inventoryItem.images.length === 0
                  "
                >
                  <img
                    src="{{ noImageUrl }}"
                    class="inventory-image inventory-animate"
                  />
                </section>
              </section>
              <section class="inventory-detail-container">
                <section>
                  {{ inventoryItem.customId }} - {{ inventoryItem.city }},
                  {{ inventoryItem.location }}
                  <span *ngIf="inventoryItem.size">
                    -
                    {{ inventoryItem.size }}
                  </span>
                </section>
                <section class="inventory-details">
                  <section>
                    Light Type: {{ getLightType(inventoryItem.lightType) }}
                  </section>
                  <section>
                    {{ setMonthlyRateLabel() }}: {{ inventoryItem.grossPrice }}
                  </section>
                </section>
              </section>
              <section class="inventory-checkbox-container">
                <p-checkbox
                  name="inventoryCheckbox"
                  value="val[i]"
                  (onChange)="
                    onCheckboxSelectionChange($event, inventoryItem, i)
                  "
                  binary="true"
                  [(ngModel)]="checked[i]"
                ></p-checkbox>
              </section>
            </section>
          </ng-template>
        </p-dataScroller>
      </section>
      <section class="map-view">
        <sib-map
          #mapComponent
          *ngIf="showMap"
          [overlays]="overlays"
          [options]="options"
          [allowAddMarker]="false"
          [showSearch]="true"
          (eEmitSeachboxChanges)="searchBoxChanges($event)"
          [selectedInventoryIds]="selectedInventoryIds"
          [removeMarker]="removeMarker"
          (eEmitSelectedMarkerIds)="setSelectedInventoriesFromMap($event)"
          [mapFromHoarding]="mapFromHoarding"
          [resetSearchBox]="resetSearchBox"
          [planCreationMap]="planCreationMap"
          (eEmitCoordinatesForLatLng)="filterInventoriesOnCoordinates($event)"
        >
        </sib-map>
      </section>
    </section>
    <section class="sidebar-pane">
      <section>
        <span class="sidebar-selected-text"
          >Selected: {{ selectedInventories.length }}</span
        >
      </section>
      <section class="padding-10">
        <button
          (click)="assignToPlan()"
          *permission="'create:plans'"
          class="font-16 mtab-primary"
          pButton
          label="ASSIGN TO PLAN"
          style="float: right"
          [disabled]="!selectedInventories.length"
        ></button>
      </section>
      <section class="sidebar-datatable">
        <sib-common-data-table
          [values]="selectedInventories"
          [columns]="dataTableColumns"
          (eEmitRemoveInventory)="removeInventory($event)"
          [loading]="loading"
        ></sib-common-data-table>
      </section>
      <section>
        <button
          (click)="clearSidebar()"
          *permission="'create:plans'"
          class="font-16 mtab-danger"
          pButton
          label="CLEAR"
          style="float: right"
          [disabled]="selectedInventories.length === 0"
        ></button>
      </section>
    </section>
  </section>
  <p-dialog
    header="Assign To Plan"
    [(visible)]="displayPlanDialog"
    [modal]="true"
    focusOnShow="true"
    [width]="370"
    [autoAlign]="false"
    showEffect="fade"
    [styleClass]="'assignToPlanContainer'"
    (onHide)="hidePlanDialog()"
    (onShow)="showPlanDialog()"
    blockScroll="true"
  >
    <sib-assign-to-plan-dialog
      *ngIf="displayPlanDialog"
      #assignToPlan
      [displayPlanDialog]="displayPlanDialog"
      (eEmitSavePlan)="savePlan($event)"
      (eEmitChangeDisplay)="hidePlanDialog()"
      (eEmitCreatePlan)="createPlan($event)"
    >
    </sib-assign-to-plan-dialog>
  </p-dialog>
</section>
