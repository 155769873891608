import { Injectable } from "@angular/core";
import { HoardingGroup } from "../../modals/hoardings/hoardings-group";
import { TreeNode } from "../../contracts/contract-hoarding/contract-hoarding.component";
import * as utils from '../../helpers/utils';
import { HoardingQueryParams } from "../../modals/queryparams/hoarding-queryparams";
import { FindHoardingInGroupService } from "../../services/shared/findHoardingInGroup.service";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";
import { Plan } from "../../modals/plans/plan";
import { PlanItem } from "../../modals/plans/planItem";
import { GotPlan } from "../../modals/plans/gotPlan";
import * as _ from "lodash";
import { GridPaginationEvent } from "../../components/sib-forms/grid/grid.config";
import { MediaSubCategoryEnum } from '../../shared/constants/media-sub-category-enum';


Injectable()
export class HoardingFunctionalService {

    hoardingFilter: any[] = [];
    editedHoardingFilter: any[] = [];
    editedHoardingGroupFilter: any[] = [];
    hoardingGroupFilter: any[] = [];
    hoardingKeys: any[] = [];
    hoardingGroupKeys: any[] = [];
    isPreviousHoardingFilter = false;
    isPreviousHoardingGroupFilter = false;
    hoardingPaginationEvent: GridPaginationEvent;
    hoardingGroupPaginationEvent: GridPaginationEvent;


    // /**
    //  * check if the hoarding belongs to any groups
    //  * 
    //  * @param {any} selectedFiles 
    //  * @param {any} sidebarValues 
    //  * @returns 
    //  * @memberof HoardingFunctionalService
    //  */
    // checkHoardingInSelectedGroups(selectedFiles, sidebarValues) {
    //     let result = false;
    //     for (let i = 0; i < selectedFiles.children.length; i++) {
    //         for (let j = 0; j < sidebarValues.length; j++) {
    //             if (sidebarValues[j].hoardings) {
    //                 for (let k = 0; k < sidebarValues[j].hoardings.length; k++) {
    //                     if (selectedFiles.children[i].data.customId === sidebarValues[j].hoardings[k].customId) {
    //                         result = true;
    //                     }
    //                 }
    //             } else {
    //                 if (selectedFiles.children[i].data.customId === sidebarValues[j].customId) {
    //                     result = true;
    //                 }
    //             }
    //         }
    //     }
    //     return result;
    // }

    /**
    * checking hoardings for selected group
    * 
    * @param {any} selectedRow 
    * @param {any} sidebarValues 
    * @returns 
    * @memberof HoardingFunctionalService
    */
    checkHoardingInGroupsSelected(selected, sidebarValues, type) {
        let result = false;
        for (let i = 0; i < selected[type].length; i++) {
            for (let j = 0; j < sidebarValues.length; j++) {
                if (sidebarValues[j].hoardings) {
                    for (let k = 0; k < sidebarValues[j].hoardings.length; k++) {
                        if (type === 'children') {
                            if (selected[type][i].data.customId === sidebarValues[j].hoardings[k].customId) {
                                result = true;
                            }
                        } else {
                            if (selected[type][i].customId === sidebarValues[j].hoardings[k].customId) {
                                result = true;
                            }
                        }
                    }
                } else {
                    if (type === 'children') {
                        if (selected[type][i].data.customId === sidebarValues[j].customId) {
                            result = true;
                        }
                    } else {
                        if (selected[type][i].customId === sidebarValues[j].customId) {
                            result = true;
                        }
                    }
                }
            }
        }
        // console.log("result", result);
        return result;
    }

    checkHoardingInCampaignGroups(selectedGroup, campaignItems) {
        let result = false;
        for (let i = 0; i < selectedGroup.hoardings.length; i++) {
            for (let j = 0; j < campaignItems.length; j++) {
                if (campaignItems[j].hoardings !== undefined) {
                    for (let k = 0; k < campaignItems[j].hoardings.length; k++) {
                        if (selectedGroup.hoardings[i].customId === campaignItems[j].hoardings[k].customId) {
                            result = true;
                        }
                    }
                }
            }
        }
        return result;
    }

    checkHoardingInCampaignHoardings(selectedGroup, campaignItems) {
        let result = false;
        for (let i = 0; i < selectedGroup.hoardings.length; i++) {
            for (let j = 0; j < campaignItems.length; j++) {
                // for (let k = 0; k < campaignItems[j].hoardings.length; k++) {
                if (selectedGroup.hoardings[i].customId === campaignItems[j].customId) {
                    result = true;
                }
                // }
            }
        }
        return result;
    }

    // checkGroupInCampaign(selectedGroup, campaignGroups) {
    //     let result = false;
    //     campaignGroups.forEach((group) => {
    //         if (group.customId === selectedGroup.customId) {
    //             result = true;
    //         }
    //     });
    //     return result;
    // }




    // /**
    //  * checking hoardings for selected group
    //  * 
    //  * @param {any} selectedRow 
    //  * @param {any} sidebarValues 
    //  * @returns 
    //  * @memberof HoardingFunctionalService
    //  */
    // checkHoardingForSelectedGroups(selectedRow, sidebarValues) {
    //     let result = false;
    //     for (let i = 0; i < selectedRow.hoardings.length; i++) {
    //         for (let j = 0; j < sidebarValues.length; j++) {
    //             if (sidebarValues[j].hoardings) {
    //                 for (let k = 0; k < sidebarValues[j].hoardings.length; k++) {
    //                     if (selectedRow.hoardings[i].customId === sidebarValues[j].hoardings[k].customId) {
    //                         result = true;
    //                     }
    //                 }
    //             } else {
    //                 if (selectedRow.hoardings[i].customId === sidebarValues[j].customId) {
    //                     result = true;
    //                 }
    //             }
    //         }
    //     }
    //     return result;
    // }

    /**
     * setting the response to be displayed on the page
     * 
     * @param {any} data 
     * @param {any} values 
     * @returns 
     * @memberof HoardingFunctionalService
     */
    setResponse(data, values) {
        let response: any[] = data;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < values.length; j++) {
                if (values[j].hoardings) { // to remove the hoardings that are part of a group
                    // for (let k = 0; k < values[j].hoardings.length; k++) {
                    //     response = utils.setDataOnCondition(response, 'customId', values[j].hoardings[k].customId);
                    // }
                } else if (values[j].inventory) {
                    response = utils.setDataOnCondition(response, 'customId', values[j].inventory.customId);
                } else {
                    if (values[j].customId) {
                        response = utils.setDataOnCondition(response, 'customId', values[j].customId);
                    }
                }
            }
        }
        return response;
    }


    /**
     * setting response for group
     * 
     * @memberof HoardingFunctionalService
     */
    setResponseForGroup(data, values) {
        let response: any[] = data;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < values.length; j++) {
                if (values[j].customId === data[i].customId) {
                    response = utils.setDataOnCondition(response, 'customId', values[j].customId);
                }
            }
        }
        return response;
    }

    // /**
    //  *to filter the response and remove the kiosks already added in campaign
    //  *
    //  * @memberof HoardingFunctionalService
    //  */
    // setResponseForKiosk() {

    // }


    /**
     *  separtate hoardigs and hoarding groups 
     *  and setting them in plan 
     * @param {Plan} newPlan 
     * @param {any[]} values 
     * @returns 
     * @memberof HoardingFunctionalService
     */
    //Raveena | 16-05-2019 Removed this function - Optimization change
    // separateHoardingsAndGroups(newPlan: Plan, values: any[]) {
    //     newPlan.totalItems = values.length;
    //     values.forEach((value) => {
    //         if (value.hoardings) {
    //             const planItem = new PlanItem();
    //             planItem.hoardingGroup = value;
    //             newPlan.hoardingGroupItems.push(planItem);
    //         } else if (value.mediaType === 'KIOSK') {
    //             const planItem = new PlanItem();
    //             planItem.kiosk = value;
    //             newPlan.kioskItems.push(planItem);
    //         } else if (value.mediaType === 'HOARDING') {
    //             const planItem = new PlanItem();
    //             planItem.hoarding = value;
    //             newPlan.hoardingItems.push(planItem);
    //         } else {
    //             //for new Media Type
    //         }
    //     });
    //     return newPlan;
    // }


    /**
     * setting the recieved data of plan properly
     * 
     * @param {*} plan 
     * @param {GotPlan} gotPlan 
     * @returns 
     * @memberof HoardingFunctionalService
     */
    setPlan(plan: any, gotPlan: GotPlan) {
        gotPlan.hoardingItems = plan.hoardingItems;
        gotPlan.hoardingGroupItems = plan.hoardingGroupItems;
        //Sanchit 30-11-2018 Kiosk Changes
        gotPlan.kioskItems = plan.kioskItems;
        return gotPlan;
    }


    /**
     * setting the sidebar values based on the plan received
     * 
     * @param {any} plan
     * @param {any} values
     * @returns
     * @memberof HoardingFunctionalService
     */
    setSidebarValues(plan, values) {
        plan.hoardingItems.forEach((item) => {
            values.push(item.hoarding);
        });
        plan.hoardingGroupItems.forEach((item) => {
            values.push(item.hoardingGroup);
        });

        plan.kioskItems.forEach((item) => {
            values.push(item.kiosk);
        });
        return values;
    }


    /**
     * setting group data while navigating from plans to hoardnigs screen
     * 
     * @param {any} hoardingGroup 
     * @param {any} values 
     * @returns 
     * @memberof HoardingFunctionalService
     */
    setGroupData(hoardingGroup, values) {
        let dummyValue = false;
        values.forEach((value) => {
            if (value.customId === hoardingGroup.customId) {
                dummyValue = true;
                return;
            }
        });
        return dummyValue;
    }



    // this.groupService.get()  // to get all the groups and set them as tree (moved from hoardings component)
    //   .subscribe((response) => {
    //     this.totalGroups = response.page.totalElements;
    //     this.hoardingGroupsTree = response['_embedded']['hoardingGroups'];
    //     console.log("hoarding groups are", this.hoardingGroupsTree);
    //     this.hoardingGroupsTree.forEach((hoardingGroup) => {
    //       let dummyValue: boolean;
    //       dummyValue = this.hoardingFunctionalService.setGroupData(hoardingGroup, this.sidebarValues);
    //       if (!dummyValue) {
    //         this.groupTreeData.push(utils.createTree(hoardingGroup, 'hoardings'));
    //       }
    //     });
    //   });


    setHoardingFilter(filter: any) {
        this.hoardingFilter = filter;
        if (!this.isPreviousHoardingFilter && !this.isPreviousHoardingGroupFilter) {
            this.editedHoardingFilter = _.cloneDeep(this.hoardingFilter);
        }
        this.hoardingKeys = Object.keys(this.hoardingFilter);
        this.setHoardingKeys();
    }

    getHoardingFilter() {
        return this.hoardingFilter;
    }

    setHoardingKeys() {
        if (this.hoardingKeys.length === 0 && this.hoardingGroupKeys.length === 0) {
            this.isPreviousHoardingFilter = false;
        } else {
            this.isPreviousHoardingFilter = true;
        }
        this.compareHoardingAndGroupKeys();
    }

    getHoardingKeys() {
        return this.hoardingKeys;
    }

    setHoardingGroupFilter(filter: any) {
        this.hoardingGroupFilter = filter;
        if (!this.isPreviousHoardingFilter && !this.isPreviousHoardingGroupFilter) {
            this.editedHoardingGroupFilter = _.cloneDeep(this.hoardingGroupFilter);
        }
        this.hoardingGroupKeys = Object.keys(this.hoardingGroupFilter);
        this.setHoardingGroupKeys();
    }

    getHoardingGroupFilter() {
        return this.hoardingGroupFilter;
    }

    setHoardingGroupKeys() {
        if (this.hoardingGroupKeys.length === 0 && this.hoardingKeys.length === 0) {
            this.isPreviousHoardingGroupFilter = false;
        } else {
            this.isPreviousHoardingGroupFilter = true;
        }
        this.compareGroupAndHoardingKeys();
    }

    getHoardingGroupKeys() {
        return this.hoardingGroupKeys;
    }

    compareHoardingAndGroupKeys() {
        let result = false;
        if (this.hoardingKeys.length >= this.hoardingGroupKeys.length) {
            this.hoardingGroupFilter = this.hoardingFilter;
            this.hoardingGroupKeys = this.hoardingKeys;
        } else {
            this.hoardingKeys.forEach((hKey) => {
                if (this.hoardingGroupKeys.length === 0 && this.isPreviousHoardingGroupFilter) {
                    delete this.hoardingFilter[hKey];
                    // console.log("hoarding filter 1", this.hoardingFilter);
                } else {
                    this.hoardingGroupKeys.forEach((hgKey) => {
                        if (hgKey === hKey) {
                            result = true;
                        }
                        if (!result) {
                            // delete this.hoardingGroupFilter[hgKey];
                            delete this.editedHoardingGroupFilter[hgKey];
                            // console.log("hoarding filter 2", this.hoardingFilter, this.editedHoardingGroupFilter);
                        }
                        result = false;
                    });
                }
                // result = false;
            });
        }
    }

    compareGroupAndHoardingKeys() {
        let result = false;
        if (this.hoardingGroupKeys.length >= this.hoardingKeys.length) {
            this.hoardingFilter = this.hoardingGroupFilter;
            this.hoardingKeys = this.hoardingGroupKeys;
        } else {
            this.hoardingGroupKeys.forEach((hgKey) => {
                if (this.hoardingKeys.length === 0 && this.isPreviousHoardingFilter) {
                    delete this.hoardingGroupFilter[hgKey];
                    // console.log("hoarding group filter 1", this.hoardingGroupFilter);
                } else {
                    this.hoardingKeys.forEach((hKey) => {
                        if (hKey === hgKey) {
                            result = true;
                        }
                        if (!result) {
                            delete this.hoardingFilter[hKey];
                            // console.log("hoarding group filter 2", this.hoardingGroupFilter);
                        }
                        result = false;
                    });
                }
                // result = false;
            });
        }
    }

    getHoardingAndGroupKeyDifference() {
        if (this.hoardingKeys.length >= this.hoardingGroupKeys.length) {
            return true;
        } else {
            return false;
        }
    }

    getGroupAndHoardingKeyDifference() {
        if (this.hoardingGroupKeys.length >= this.hoardingKeys.length) {
            return true;
        } else {
            return false;
        }
    }

    setHoardingFilterValue(value) {
        this.hoardingPaginationEvent = value;
    }

    getHoardingFilterValue() {
        return this.hoardingPaginationEvent;
    }

    setHoardingGroupFilterValue(value) {
        this.hoardingGroupPaginationEvent = value;
    }

    getHoardingGroupFilterValue() {
        return this.hoardingGroupPaginationEvent;
    }

    //Raveena | 16-05-2019 function for getting plan items - Optimization change
    getPlanItemBo(items) {
        var planItemBo: { hoardingGroupItems: any[], hoardingItems: any[], kioskItems: any[] } = { hoardingGroupItems: [], hoardingItems: [], kioskItems: [] };
        for (let i = 0; i < items.length; i++) {
            switch (items[i].mediaSubCategory) {
                case 'HOARDING_GROUP': {
                    //Raveena: Changes for plan items wrapper
                    const planItem = new PlanItem();
                    planItem.hoardingGroup = items[i];
                    planItem.mediaSubCategory = items[i].mediaSubCategory
                    planItemBo.hoardingGroupItems.push(planItem);
                    // this.plan.hoardingGroupItems.push(this.buildItemToUpdate(planItemLocal, item, 'hoardingGroupItems'));
                    break;
                }
                case 'HOARDING': {
                    const planItem = new PlanItem();
                    planItem.hoarding = items[i];
                    planItem.mediaSubCategory = items[i].mediaSubCategory
                    planItemBo.hoardingItems.push(planItem);
                    // this.plan.hoardingItems.push(this.buildItemToUpdate(planItemLocal, item, 'hoardingItems'));
                    break;
                }

                case 'KIOSK': {
                    const planItem = new PlanItem();
                    planItem.kiosk = items[i];
                    planItem.mediaSubCategory = items[i].mediaSubCategory
                    planItemBo.kioskItems.push(planItem);
                    // this.plan.kioskItems.push(this.buildItemToUpdate(planItemLocal, item, 'kioskItems'));
                    break;
                }
            }
        }

        return planItemBo;
    }

    getPlanItemsWrapper(items) {
        var planItemBo = [];
        for (let i = 0; i < items.length; i++) {
            const planItem = new PlanItem();
            planItem.inventory = items[i];
            planItemBo.push(planItem);
        }
        return planItemBo;
    }
}
