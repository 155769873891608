
import { map, concat } from 'rxjs/operators';
/**
* @file hoarding-groups.component.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from "@angular/core";
import { HoardingGroupService } from "../services/shared/hoardingGroup.service";
import { GridComponent } from "../components/sib-forms/grid/grid.component";
import { HoardingGroup } from "../modals/hoardings/hoardings-group";
import { GridConfig, EditableGridFieldTypes, Alignment, SelectionMode, GridPaginationEvent } from "../components/sib-forms/grid/grid.config";
import { HoardingFunctionalService } from "../hoardings/services/hoarding-functional.service";
import { FindHoardingInGroupService } from "../services/shared/findHoardingInGroup.service";
import { ContractHoardings } from "../modals/contracts/contract-hoarding";
import { SelectItem, Dropdown } from "primeng/primeng";
import { Router, ActivatedRoute } from "@angular/router";
import { PhotoDialogComponent } from "../shared/components/photo-dialog/photo-dialog.component";
import { ActionDispatcher } from "../app state/action-dispatcher/action-dispatcher";
import * as utils from '../helpers/utils';
import * as _ from "lodash";
import { HoardingStatusEnum } from "../shared/constants/hoarding-status-enum";
import { HoardingCategoryEnum } from "../shared/constants/hoarding-category-enum";
import { HoardingLightEnum } from "../shared/constants/hoarding-light-enum";
import { DateUtil } from "../helpers/date.util";
import { CurrencyHelperPipe } from "../shared/helpers/currency.pipe/currencyHelperPipe";
import { UserGridConfigService } from "../services/shared/user-grid-config.service";
import { GridNameEnum } from "../shared/constants/grid-name.enum";
import { HoardingMasterColService } from "../services/shared/hoarding-master-column.service";
import { ImageDataDisplay } from "../modals/image/image-data";
import { Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { Contracts } from "../modals/contracts/contracts";
import { PaginationData } from '../modals/paginationdata';
import { ChangeDetectionStrategy } from '@angular/core';
import { PlanTemplate } from '../modals/plans/planTemplate';
import { PlanTemplateService } from '../services/shared/plan-template.service';
import { PlanTemplateGroupsService } from '../services/shared/plan-template-groups.service';
import { MediaSubCategoryEnum } from '../shared/constants/media-sub-category-enum';


@Component({
  selector: 'sib-hoarding-groups',
  templateUrl: './hoarding-groups.component.html',
  styleUrls: ['./hoarding-groups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HoardingGroupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoardingGroupsComponent implements OnInit, OnDestroy {

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<HoardingGroup>;

  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  @ViewChild('categorydd') categoryDd: Dropdown;
  @ViewChild('lighttypedd') lightTypeDd: Dropdown;

  totalRecords: number;
  totalGroups: number;
  hoardingGroup: HoardingGroup = new HoardingGroup(); // for receiving the edited hoarding group
  hoardingGroupsTree: HoardingGroup[] = [];
  selectedGroupRows: any[] = [];
  imageData: any;
  groupHoardings: ContractHoardings[]; // for row expansion
  rowData: any;
  editHide = false;

  displayClubHoarding = false;
  isGroupSaved = false;
  // isloading = false; // for loader

  categoryDropdown: any[] = [];
  lightTypeDropdown: any[] = [];

  filterColumns: any[] = []; // for selected filter columns

  globalFilterValue: any;

  hoardingGroupPageSize = 50;

  searchEvent: GridPaginationEvent;

  @Input()
  set hideEdit(hideEdit) {
    this.editHide = true;
    this.hoardingGroupColumns.forEach((col) => {
      if (col.field === 'id') {
        col.hidden = true;
      }
    });
    if (this.groupGridConfig.dataLoadFunction !== undefined) {
      this.groupGridViewChildRef.refresh();
    }
  }

  hoardingGroupColumns: any[] = [];
  _columns: any[] = [];
  @Input() set columns(columns) {
    this._columns = columns;
    this.hoardingGroupColumns = this.getHoardingGroupColumnConfig();
  }

  get columns() {
    return this._columns;
  }

  _src: string = null;
  @Input() set source(source) {
    this._src = source;
    this.getHoardingGroupColumnConfig();
  }
  get source() {
    return this._src;
  }

  @Input() sidebarValues: any[] = [];
  @Input() status: SelectItem[] = [];
  @Input() loadingGroupUpdate = false;
  @Input() campaignItems: any[] = [];
  @Input() planItems: any[] = [];
  // _planItems: any[] = [];
  // @Input() set planItems(planItems) {
  //   this._planItems = planItems;
  //   if (this.groupGridConfig.dataLoadFunction !== undefined) {
  //     this.groupGridViewChildRef.refresh();
  //   }
  // }

  // get planItems() {
  //   return this._planItems;
  // }
  @Input() contractId: string;
  @Input() isOpenedFromHoardingMaster: boolean = true;
  @Input() contract: Contracts;
  @Input() importFromContractId: string;
  // @Input() searchPlanTemplate: boolean;

  _siteTemplate: PlanTemplate;
  @Input() set siteTemplate(siteTemplate) {
    this._siteTemplate = siteTemplate;
    if (this.groupGridConfig.dataLoadFunction !== undefined) {
      this.groupGridViewChildRef.refresh();
    }
  }

  get siteTemplate() {
    return this._siteTemplate;
  }

  @Input() templateRemoved: boolean;
  // _templateRemoved: boolean;
  // @Input() set templateRemoved(templateRemoved) {
  //   this._templateRemoved = templateRemoved;
  //   if (this.groupGridConfig.dataLoadFunction !== undefined) {
  //     this.groupGridViewChildRef.refresh();
  //   }
  // }


  @Output() eEmitRowClick: EventEmitter<any> = new EventEmitter();
  @Output() eEmitTotalGroups: EventEmitter<any> = new EventEmitter();
  @Output() eEmitHoardingGroupPageSize: EventEmitter<any> = new EventEmitter();
  @Output() eEmitTotalRecords: EventEmitter<any> = new EventEmitter();
  @Output() eEmitEditedHoardingGroup: EventEmitter<any> = new EventEmitter();
  @Output() eEmitSelectedHoardingGroupRows: EventEmitter<any> = new EventEmitter();
  @Output() eEmitHoardingGroupsTree: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitHoardingGroupSearchEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(GridComponent)
  groupGridViewChildRef: GridComponent<HoardingGroup>;

  groupGridConfig: GridConfig<HoardingGroup> = new GridConfig<HoardingGroup>();
  subscription: Subscription[] = [];
  hmSelectedColumns: any[] = [];
  sitesFromDate: Date;
  selectedStatus: any[] = [];
  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant

  constructor(
    private groupService: HoardingGroupService,
    private hoardingFunctionalService: HoardingFunctionalService,
    private findHoardingInGroupService: FindHoardingInGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    public appActionDispatcher: ActionDispatcher,
    private router: Router,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private sanitizer: DomSanitizer,
    private planTemplateService: PlanTemplateService,
    private planTemplateGroupService: PlanTemplateGroupsService
  ) { }

  ngOnInit() {

    let gridNameEnum = this.setGridNameEnum();
    this.subscription.push(this.userGridConfigService.create([gridNameEnum])
      .subscribe((hoardingGroupsGridConfig) => {
        this.hoardingGroupColumns = utils.getUserConfiguredColumns(hoardingGroupsGridConfig[gridNameEnum], this.hoardingGroupColumns);
        this.gridViewChildRef.ngOnInit();
      }));


    // if (this.templateRemoved && this._siteTemplate) {
    //   if (this.groupGridConfig.dataLoadFunction !== undefined) {
    //     this.groupGridViewChildRef.refresh();
    //   }
    // }
    this.setGroupConfigObject();

    if (this.categoryDropdown.length === 0) {
      this.categoryDropdown.push({ label: "All", value: "ALL" });
      Object.keys(HoardingCategoryEnum).forEach((key) => {
        this.categoryDropdown.push({ label: HoardingCategoryEnum[key], value: key });
      });
    }

    if (this.lightTypeDropdown.length === 0) {
      this.lightTypeDropdown.push({ label: "All", value: "ALL" });
      Object.keys(HoardingLightEnum).forEach((light) => {
        this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
      });
    }

    this.subscription.push(this.hmColService.selectedColumns.subscribe((hmSelectedColumns) => {
      // if(hmSelectedColumns.length> 0) {
      this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
      this.detectChanges();
      setTimeout(() => {
        this.gridViewChildRef.setColumns();
      }, 100)
      // } 
    }));

    this.subscription.push(this.hmColService.siteFromDate.subscribe((siteFromDate) => {
      this.sitesFromDate = siteFromDate;
      this.gridViewChildRef.selectSitesAvailableFrom(siteFromDate);
    }));

    this.subscription.push(this.hmColService.statuses.subscribe((status) => {
      if (status) {
        this.selectedStatus = status;
        this.gridViewChildRef.filterByStatuses(status);
      }

    }));

    this.subscription.push(this.hmColService.globalFilter.subscribe((globalFilterData) => {
      // this.setGlobalFilterValue(globalFilterData);
      this.gridViewChildRef.globalFilterHoardingMaster(globalFilterData);
    }))

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const roles = userInfo[0].app_metadata.authorization.roles;

    this.email = userInfo[0].email;

    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    })

  }

  setGroupConfig() {
    this.groupGridConfig.getDefaultModel = () => new HoardingGroup();
    this.groupGridConfig.model = HoardingGroup;
    // this.groupGridConfig.selectionMode = SelectionMode.Multi;
    this.groupGridConfig.selectionMode = SelectionMode.Multi;
    this.groupGridConfig.editable = true;
    this.groupGridConfig.expandableRows = true;
    this.groupGridConfig.checkboxSelection = !this.editHide;
    this.groupGridConfig.resizableColumns = false;
    this.groupGridConfig.rowExpandMode = 'single';
    this.groupGridConfig.shouldAddRowOnDelete = false;
    this.groupGridConfig.showColorCode = true;
    this.groupGridConfig.lazy = true;
    this.groupGridConfig.enablePagination = true;
    this.groupGridConfig.scrollable = true;
    this.groupGridConfig.showLoader = false;
  }

  setGroupConfigObject() {
    this.setGroupConfig();
    this.groupGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen


      if (!this._siteTemplate) {
        if (this.planItems.length !== 0 && utils.isObjectEmpty(paginationEvent.filters)) {
          Object.assign(paginationEvent.filters, { 'status': { value: ['INACTIVE', 'EXPIRED', 'MIGRATED', 'INVALID'], matchMode: 'notIn' } });
        }
        if (this.campaignItems.length !== 0) {
          if (utils.isObjectEmpty(paginationEvent.filters) || paginationEvent.filters['status'].value.length === 0) {
            Object.assign(paginationEvent.filters, { 'status': { value: ['INACTIVE', 'EXPIRED', 'MIGRATED', 'INVALID'], matchMode: 'notIn' } });
          }
        }

        if (this.importFromContractId) {
          const filter = { contractId: { value: this.importFromContractId, matchMode: "contains" }, status: { value: ["MIGRATED"], matchMode: 'notIn' } }
          Object.assign(paginationEvent.filters, filter);
        }

        if (this.contractId) {
          const filter = { contractId: { value: this.contractId, matchMode: "contains" } }
          Object.assign(paginationEvent.filters, filter);
          if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
            Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
          }
        }

        //Add not contract id condition so that this filter is not applied in contract-hoarding screen
        if (!this.contractId && (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0))) {
          Object.assign(paginationEvent.filters, { 'status': { value: ['MIGRATED', 'EXPIRED', 'INVALID', 'INACTIVE'], matchMode: 'notIn' } });
        }

        if (this.planItems.length > 0) {
          let planItemsIds = [];
          this.planItems.forEach((item) => {
            if (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum['HOARDING_GROUP']) {
              planItemsIds.push(item.id);
            }
          })
          Object.assign(paginationEvent.filters, { 'id': { value: planItemsIds, matchMode: 'notIn' } });
        }

        if (this.campaignItems.length > 0) {
          let campaignItemIds = [];
          this.campaignItems.forEach((item) => {
            if (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum['HOARDING_GROUP']) {
              campaignItemIds.push(item.id);
            }
          })
          Object.assign(paginationEvent.filters, { 'id': { value: campaignItemIds, matchMode: 'notIn' } });
        }
        Object.assign(paginationEvent.filters, this.setSitesAvailableFromFilter());
        if (!this.contractId) {
          Object.assign(paginationEvent.filters, this.setStatusFilter());
        }
        Object.assign(paginationEvent, this.setGlobalFilterInPagination(paginationEvent));
        Object.assign(paginationEvent, this.setSortField(paginationEvent));

        this.hoardingGroupPageSize = paginationEvent.size;
        this.eEmitHoardingGroupPageSize.emit(this.hoardingGroupPageSize);

        this.searchEvent = paginationEvent;
        this.eEmitHoardingGroupSearchEvent.emit(this.searchEvent);
        return this.groupService.create(paginationEvent, paginationEvent).pipe(
          concat(),
          map((response) => {
            if (this.campaignItems.length !== 0) {
              response['content'] = this.hoardingFunctionalService.setResponseForGroup(response['content'], this.campaignItems);
            }
            if (this.planItems.length !== 0) {
              response['content'] = this.hoardingFunctionalService.setResponseForGroup(response['content'], this.planItems);
            }
            response['content'] = this.hoardingFunctionalService.setResponseForGroup(response['content'], this.sidebarValues);
            this.totalRecords = response.totalElements;
            this.eEmitTotalRecords.emit(this.totalRecords);
            this.totalGroups = response['content'].length;
            this.eEmitTotalGroups.emit(this.totalGroups);
            this.hoardingGroupsTree = response['content'];
            this.eEmitHoardingGroupsTree.emit(this.hoardingGroupsTree);
            return response['content'];
          }));
      } else {
        this.setPlanTemplateGroupconfig();
      }

    };
  }

  setPlanTemplateGroupconfig() {
    this.setGroupConfig();
    this.groupGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen

      if (this._siteTemplate) {
        const paginationData: PaginationData = {
          sortField: 'grossPrice',
          sortOrder: 1
        }

        if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
          paginationEvent = Object.assign(paginationEvent, paginationData)
        }
        if (this.planItems.length !== 0 && utils.isObjectEmpty(paginationEvent.filters)) {
          Object.assign(paginationEvent.filters, { 'status': { value: ['INACTIVE', 'EXPIRED', 'MIGRATED', 'INVALID'], matchMode: 'notIn' } });
        }
        if (this.campaignItems.length !== 0) {
          if (utils.isObjectEmpty(paginationEvent.filters) || paginationEvent.filters['status'].value.length === 0) {
            Object.assign(paginationEvent.filters, { 'status': { value: ['INACTIVE', 'EXPIRED', 'MIGRATED', 'INVALID'], matchMode: 'notIn' } });
          }
        }

        if (this.importFromContractId) {
          const filter = { contractId: { value: this.importFromContractId, matchMode: "contains" }, status: { value: ["MIGRATED"], matchMode: 'notIn' } }
          Object.assign(paginationEvent.filters, filter);
        }

        if (this.contractId) {
          const filter = { contractId: { value: this.contractId, matchMode: "contains" } }
          Object.assign(paginationEvent.filters, filter);
          if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
            Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
          }
        }

        //Add not contract id condition so that this filter is not applied in contract-hoarding screen
        if (!this.contractId && (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0))) {
          Object.assign(paginationEvent.filters, { 'status': { value: ['MIGRATED', 'EXPIRED', 'INVALID', 'INACTIVE'], matchMode: 'notIn' } });
        }

        if (this.planItems.length > 0) {
          let planItemsIds = [];
          this.planItems.forEach((item) => {
            if (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum['HOARDING_GROUP']) {
              planItemsIds.push(item.id);
            }
          })
          Object.assign(paginationEvent.filters, { 'id': { value: planItemsIds, matchMode: 'notIn' } });
        }
        Object.assign(paginationEvent.filters, this.setSitesAvailableFromFilter());
        if (!this.contractId) {
          Object.assign(paginationEvent.filters, this.setStatusFilter());
        }
        Object.assign(paginationEvent, this.setGlobalFilterInPagination(paginationEvent));
        Object.assign(paginationEvent, this.setSortField(paginationEvent));


        this.hoardingGroupPageSize = paginationEvent.size;
        this.eEmitHoardingGroupPageSize.emit(this.hoardingGroupPageSize);

        let requestData = { searchEvent: paginationEvent, id: this.siteTemplate.id, name: this.siteTemplate.name, states: this.siteTemplate.states, districts: this.siteTemplate.districts, cities: this.siteTemplate.cities, medias: this.siteTemplate.medias, lightTypes: this.siteTemplate.lightTypes, availableFromDays: this.siteTemplate.availableFromDays, fromOneCity: this.siteTemplate.fromOneCity, minimumPriceFrom: this.siteTemplate.minimumPriceFrom, minimumPriceTo: this.siteTemplate.minimumPriceTo, squareFeetFrom: this.siteTemplate.squareFeetFrom, squareFeetTo: this.siteTemplate.squareFeetTo, blocked: this.siteTemplate.blocked }
        return this.planTemplateGroupService.create(requestData, paginationEvent, '/planTemplateSearch').pipe(
          concat(),
          map((response) => {
            if (this.campaignItems.length !== 0) {
              response['data']['content'] = this.hoardingFunctionalService.setResponseForGroup(response['data']['content'], this.campaignItems);
            }
            if (this.planItems.length !== 0) {
              response['data']['content'] = this.hoardingFunctionalService.setResponseForGroup(response['data']['content'], this.planItems);
            }
            response['data']['content'] = this.hoardingFunctionalService.setResponseForGroup(response['data']['content'], this.sidebarValues);

            this.totalRecords = response['data'].totalElements;
            this.eEmitTotalRecords.emit(this.totalRecords);
            this.totalGroups = response['data']['content'].length;
            this.eEmitTotalGroups.emit(this.totalGroups);
            this.hoardingGroupsTree = response['data']['content'];
            this.eEmitHoardingGroupsTree.emit(this.hoardingGroupsTree);
            return response['data']['content'];

          }));
      } else {
        this.setGroupConfigObject();
      }

    };
  }

  getHoardingGroupColumnConfig() {
    const columns = [
      {
        field: 'customId',
        name: 'customId',
        header: 'GID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'contract.id',
        name: 'contract',
        header: 'CID',
        width: '6vw',
        required: true,
        editable: false,
        permanent: false,
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.contract) {
            return data.contract.id;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.latitude',
        name: 'geoCoordinate.latitude',
        header: 'Latitude',
        required: true,
        editable: false,
        permanent: false,
        width: '6vw',
        hidden: false,
        sortable: true,
        default: false,
        styleClass: 'word-break-wrap',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.latitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.longitude',
        name: 'geoCoordinate.longitude',
        header: 'Longitude',
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        permanent: false,
        width: '7vw',
        styleClass: 'word-break-wrap',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.longitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'district',
        name: 'district',
        header: 'District',
        required: true,
        editable: false,
        width: '7vw',
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'city',
        name: 'city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'areaName',
        name: 'area',
        header: 'Area',
        required: true,
        width: '9vw',
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        /* displayFn: (data) => {
          if (data.area) {
            return data.area.name;
          } else {
            return '-';
          }
        } */
      },
      {
        field: 'location',
        name: 'location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '20vw',
        default: true,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'width',
        name: 'width',
        header: 'W',
        width: '50px',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.width) {
            return data.width;
          } else {
            return '-';
          }
        },

      },
      {
        field: 'height',
        name: 'height',
        header: 'H',
        width: '50px',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.height) {
            return data.height;
          } else {
            return '-';
          }
        },

      },
      {
        field: 'size',
        name: 'size',
        header: 'Size',
        width: '6vw',
        required: true,
        // default: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'squareFeet',
        name: 'squareFeet',
        header: 'Total SqFt',
        width: '5.5vw',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.squareFeet) {
            return Math.ceil(data.squareFeet);
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'category',
        name: 'category',
        header: 'Category',
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        width: '8vw',
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.category) {
            return HoardingCategoryEnum[data.category];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'lightType',
        name: 'lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '4.5vw',
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'image',
        name: '',
        header: 'Photos',
        required: true,
        editable: false,
        permanent: true,
        width: '4vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.LEFT
      },
      {
        field: 'grossPrice',
        name: 'grossPrice',
        header: 'Gross Price',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },

        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return '-';
          }
        },

      },
      {
        field: 'minimumPrice',
        name: 'minimumPrice',
        header: 'LSP',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        width: '5.5vw', //width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },

        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return '-';
          }
        },

      },
      {
        field: 'startDate',
        name: 'startDate',
        header: 'Start Date',
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: true,
        width: '7vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: true
        // },
        displayFn: (data) => {
          if (data.startDate) {
            return DateUtil.dategridFormatter(data.startDate);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'endDate',
        name: 'endDate',
        header: 'End Date',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: true,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: true
        // },
        displayFn: (data) => {
          if (data.endDate) {
            return DateUtil.dategridFormatter(data.endDate);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'campaignStartDate',
        name: 'campaignStartDate',
        header: 'Campaign Start Date',
        required: true,
        editable: false,
        default: false,
        hidden: false,
        width: '7vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate);
          } else {
            return '-';
          }
        }
      },
      {
        field: 'campaignEndDate',
        name: 'campaignEndDate',
        header: 'Campaign End Date',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate);
          } else {
            return '-';
          }
        }
      },
      // {
      //   field: 'status',
      //   name: 'status',
      //   header: 'Status',
      //   required: true,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   filter: {
      //     matchMode: 'contains',
      //     placeHolder: 'Search',
      //     custom: false
      //   },
      //   // displayFn: (data) => {
      //   //   if (data.isActive) {
      //   //     return 'Active';
      //   //   } else {
      //   //     return 'Inactive';
      //   //   }
      //   // }
      // },
      {
        field: 'statusQueues',
        name: 'statusQueues',
        header: 'Status',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: '7.5vw',
        type: EditableGridFieldTypes.CUSTOM,
        // filter: {
        //   matchMode: 'contains',
        //   placeHolder: 'Search',
        //   custom: false
        // },
      },
      {
        field: 'statusQueues.displayName',
        name: 'displayName',
        header: 'Display Name',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '9vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'statusQueues.employee',
        name: 'employeeName',
        header: 'Employee',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: '8.5vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'id',
        name: '',
        header: '',
        required: true,
        permanent: true,
        editable: false,
        // width: '2vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
        // filter: {
        //   matchMode: 'contains',
        //   placeHolder: 'Search',
        //   custom: true
        // },
      },
    ];
    const filteredColumns = this.filterGroupColumns(columns);

    return filteredColumns;
  }

  filterGroupColumns(columns: any[]) {
    let updatedColumns: any[] = [];
    columns.forEach((column) => {
      if (this.columns.includes(column.field)) {
        column.hidden = false;
      } else {
        column.hidden = true;
      }
    });
    if (this.source !== 'Contract') {
      updatedColumns = columns.filter(col => col.field !== 'startDate' && col.field !== 'endDate');
      columns = updatedColumns;
    } else {
      columns = columns.filter(col => col.field !== 'contract.id')
    }
    return columns;
  }



  /**
   * on row click
   * 
   * @param {any} $event 
   * @memberof HoardingGroupsComponent
   */
  onRowClick($event) {
    this.eEmitRowClick.emit($event);
  }


  /**
   * on row select
   * 
   * @param {any} $event 
   * @memberof HoardingGroupsComponent
   */
  onRowSelect($event) {
    this.selectedGroupRows = $event;
    this.eEmitSelectedHoardingGroupRows.emit(this.selectedGroupRows);
    this.selectedGroupRows = [];
  }


  /**
   * on row expand
   * 
   * @param {any} $event 
   * @memberof HoardingGroupsComponent
   */
  onRowExpand($event) {
    this.groupHoardings = $event.data.hoardings;
  }


  /**
   * refresh grid
   * 
   * @memberof HoardingGroupsComponent
   */
  refreshGrid() {
    if (this.groupGridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

  refresh() {
    if (this.groupGridConfig.dataLoadFunction) {
      this.gridViewChildRef.loadData();
    }
  }

  resetDataTable() {
    this.resetDropDownFilters();
    this.gridViewChildRef.resetDataTable();
  }

  resetAndReloadTable() {
    this.gridViewChildRef.resetAndReloadTable();
    this.resetDropDownFilters();
  }
  resetDropDownFilters() {

    this.lightTypeDd ? this.lightTypeDd.selectedOption = null : '';
    this.categoryDd ? this.categoryDd.selectedOption = null : '';
  }

  viewImages(rowData) {
    this.eEmitViewImage.emit(rowData);
  }

  getImageData(data) {
    const imageData = [];
    for (let i = 0; i < data.images.length; i++) {
      imageData.push(new ImageDataDisplay().getImageData(data, data.images[i]))
    }
    return imageData;
  }

  /**
  * Filtering the array from another based on the comparator. 
  * 
  * @param {any} totalRecords 
  * @param {any} values 
  * @param {any} comparator 
  * @memberof HoardingGroupsComponent
  */
  filterData(totalRecords, values, comparator) {
    this.gridViewChildRef.filterData(totalRecords, values, comparator);
  }

  /**
   * before calling filter
   * getting the values from hoarding master
   * @param {any} values 
   * @param {any} comparator 
   * @memberof HoardingGroupsComponent
   */
  beforeFilter(values, comparator) {
    this.filterData(this.hoardingGroupsTree, values, comparator);
  }


  /**
   * for editing in group
   * 
   * @param {any} rowData 
   * @memberof HoardingGroupsComponent
   */
  editGroup(rowData) {
    this.rowData = rowData;
    this.isGroupSaved = true;
    this.displayClubHoarding = true;
  }


  /**
   *to hide the club hoarding dilaog 
   * 
   * @memberof HoardingGroupsComponent
   */
  hideHoardingGroupEditDialog() {
    this.displayClubHoarding = false;
    this.isGroupSaved = false;
    this.gridViewChildRef.refresh();
  }

  /**
   * to view the status queue detail
   * 
   * @param {any} rowData 
   * @memberof HoardingGroupsComponent
   */
  viewDetail(rowData) {
    this.eEmitViewDetail.emit(rowData);
  }


  /**
   * after hoarding group is updated
   * 
   * @memberof HoardingGroupsComponent
   */
  updateHoardingGroup(event) {
    this.hoardingGroup = event;
    this.eEmitEditedHoardingGroup.emit(this.hoardingGroup);
  }


  pushData(data, value) {
    data.push(value);
    this.gridViewChildRef.pushData(data);
    this.hoardingGroupsTree = utils.setData(this.hoardingGroupsTree);
  }

  pushMultipleValues(data, values) {
    data.push(...values);
    this.gridViewChildRef.pushData(data);
    this.hoardingGroupsTree = utils.setData(this.hoardingGroupsTree);
  }


  /**
   *get status value 
   * 
   * @param {any} status 
   * @returns 
   * @memberof HoardingGroupsComponent
   */
  getValue(status) {
    return HoardingStatusEnum[status];
  }


  /**
   *get category value 
   * 
   * @param {any} value 
   * @returns 
   * @memberof HoardingGroupsComponent
   */
  getCategory(value) {
    if (value !== null) {
      return HoardingCategoryEnum[value];
    } else {
      return '-';
    }
  }


  /**
   *get light value 
   * 
   * @param {any} value 
   * @returns 
   * @memberof HoardingGroupsComponent
   */
  getLight(value) {
    return HoardingLightEnum[value];
  }


  /**
   *columns selected in the show filter 
   * 
   * @param {any} event 
   * @memberof HoardingGroupsComponent
   */
  selectedFilterColumns(event) {
    this.filterColumns = event;
  }


  /**
   *if a column is selected in the show filter 
   * 
   * @param {any} value 
   * @returns 
   * @memberof HoardingGroupsComponent
   */
  isColumnPresent(value) {
    let result = false;
    this.hmSelectedColumns.forEach((col) => {
      if (col === value) {
        result = true;
      }
    });
    if ((value === 'Start Date' && this.columns.includes('startDate'))) {
      result = true;
    }
    if ((value === 'End Date' && this.columns.includes('endDate'))) {
      result = true;
    }
    return result;
  }

  getColumnWidth(value) {
    this.hoardingGroupColumns.forEach((col) => {
      if (col.header === value) {
        return this.sanitizer.bypassSecurityTrustStyle(col.width);
      }
    });
  }

  navigate(rowData) {
    if (rowData.statusQueues[0].module === 'Campaign') {
      window.open('/campaigns/view/' + rowData.statusQueues[0].id);
    } else {
      window.open('/plans/view/' + rowData.statusQueues[0].id);
    }
  }

  updatingUserGridConfig(columnArray) {
    let hoardingGroupsGridConfig: Map<string, string> = new Map();
    let gridNameEnum = this.setGridNameEnum();
    hoardingGroupsGridConfig = utils.getGridConfigMap(columnArray, this.hoardingGroupColumns);
    this.subscription.push(this.userGridConfigService.patch(utils.buildObject(hoardingGroupsGridConfig), null, gridNameEnum)
      .subscribe((result) => {
      }));
  }

  addToSelectedGroups(groups) {
    this.selectedGroupRows = [...this.selectedGroupRows, ...groups];
    this.gridViewChildRef.addToExistingSelected(this.selectedGroupRows);
    this.detectChanges();
  }

  resetHoardingGroupDialogDisplay(event) {
    this.hideHoardingGroupEditDialog()
  }

  checkIfContractIdChanged(val) {
    if (this.contractId != val) {
      return true;
    } else {
      return false;
    }
  }

  setSortField(paginationEvent) {
    if (this.checkPaginationEventForSortField(paginationEvent)) {
      return paginationEvent

    } else {
      paginationEvent.sortField = 'grossPrice';
      return paginationEvent;

    }
  }

  setSitesAvailableFromFilter() {
    return (this.sitesFromDate ? { 'campaignEndDate': { value: this.sitesFromDate, matchMode: 'availableFrom' } } : {})
  }

  checkPaginationEventForSortField(paginationEvent) {
    var filterKeys = Object.keys(paginationEvent.filters);
    if (filterKeys.length === 1) {
      if (paginationEvent.sortField && paginationEvent.sortField !== 'updatedDate' && paginationEvent.sortField !== 'grossPrice') {
        return true;
      } else {
        if (paginationEvent.globalFilter && paginationEvent.globalFilter.length) {
          return false;
        } else {
          if (!paginationEvent.sortField) {
            paginationEvent.sortField = 'updateDate'
          }
          return true;
        }
      }

    } else if (filterKeys.length > 1) {
      if (paginationEvent.sortField && paginationEvent.sortField !== 'updatedDate' && paginationEvent.sortField !== 'grossPrice') {
        return true;
      } else {
        return false;
      }
    }
  }

  setGlobalFilterValue(globalSearchValue) {
    this.globalFilterValue = globalSearchValue;
  }

  setStatusFilter() {
    return (this.selectedStatus.length > 0 ? { 'status': { value: this.selectedStatus, matchMode: 'in' } } : { 'status': { value: ['INACTIVE', 'EXPIRED', 'MIGRATED', 'INVALID'], matchMode: 'notIn' } })
  }

  setGlobalFilterInPagination(paginationEvent) {
    paginationEvent.globalFilter = this.globalFilterValue ? this.globalFilterValue : '';
    return paginationEvent;
  }

  /**
   * @description set grid name enum according to the source
   * @author Divya Sachan
   * @date 2020-01-16
   * @returns
   * @memberof HoardingGroupsComponent
   */
  setGridNameEnum() {
    if (this.source === 'Contract') {
      return GridNameEnum['CONTRACT_HOARDING_GROUPS'];
    } else {
      return GridNameEnum['HOARDING_GROUPS']
    }
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
    this.hmColService.defaultColumns.next([])
    this.hmColService.statuses.next(undefined);
  }

}
