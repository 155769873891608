<div class="capacity-chart-container">
  <div [ngClass]="{'transparent-background': areaChartLoader}">
    <div *ngIf="areaChartLoader" class="loader">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="ui-g header-text" *ngIf="showTitle">
      <h2 class="margin-0 header-text__description">{{title}}</h2>
    </div>


    <div class="float-right input-switch" *ngIf="graphCustomization">
      <p-inputSwitch name='capacityChartInputSwitch' [(ngModel)]="capacityChartVisibility"
        (onChange)="onCapacityChartVisibilityChange($event)">
      </p-inputSwitch>
    </div>
    <div class="multiselect-container">
      <span class="city-label"> Select City(s)</span>
      <div style="position: relative; top: 15px; ">
        <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
          (onPanelHide)="handleSelectedCities(selectedCities)" (onPanelShow)="filterCities()"
          selectedItemsLabel="{0} cities selected" defaultLabel="Choose" [disabled]="graphCustomization">
        </p-multiSelect>
      </div>
    </div>

    <sib-chart class="area-chart" #sibAreaChart *ngIf="areaChartData.length > 0 " [data]="areaChartData"
      [areaChartConfig]="areaChartConfig" [elementId]="areaElementId" [chartType]="'areaChart'"
      (eEmitAreaChartLoaded)="areaChartLoader = false"></sib-chart>

    <!-- (eEmitAreaChartLoaded)="areaChartLoader = false" -->

    <div *ngIf="viewCapacityChartDrilldown && showAction">
      <sib-capacity-chart-drilldown #capacityChartDrilldown [viewCapacityChartDrilldown]="viewCapacityChartDrilldown"
        [clickedData]="chartClickedData" [isCapacityChartClicked]="isCapacityChartClicked"
        (eEmitIsCapacityChartClicked)="getIsCapacityChartClicked($event)">
      </sib-capacity-chart-drilldown>
    </div>
  </div>
</div>