import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'sib-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.css']
})
export class UnauthorizedPageComponent implements OnInit {

  title: string;
  asDialog: boolean = false;
  closeForm$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

  }
  closeDialog() {
    this.closeForm$.next(true);
  }

}
