import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { GridColumn, GridConfig, SelectionMode, GridPaginationEvent, EditableGridFieldTypes } from '../../components/sib-forms/grid/grid.config';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { GridActionButtonConfig } from '../../components/sib-forms/grid/grid-action-button.config';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, EMPTY as empty, Subscription } from 'rxjs';
import { DateUtil } from '../../helpers/date.util';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { InvoiceService } from '../../services/shared/invoice.service';
import { Invoice } from '../../modals/billings/invoice';
import { SuperbillHoardingsComponent } from '../superbill-hoardings/superbill-hoardings.component';
import { ConfirmationService, SelectItem, MenuItem, OverlayPanel } from 'primeng/primeng';
import { CalculationService } from '../../services/shared/calculationService';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';
import { BillingActionDispatcher } from '../action-dispatcher/action-dispatcher';
import { Note } from '../../modals/billings/note';
import { BillingNote } from '../../modals/billings/billingNote';
import * as utils from '../../helpers/utils';
import * as _ from "lodash";
import { BillingGrid } from '../../modals/billings/billing-grid';
import { CampaignService } from '../../services/shared/campaign.service';
import { AdditionalItemWrapper } from '../../modals/billings/AdditionalItemWrapper';
import { BillingGridService } from '../services/billing-grid.service';
import { Campaign } from '../../modals/campaigns/campaign';
import { FirebaseService } from '../../services/shared/firebase.service';
import { BillingConfigurationService } from '../../services/shared/billing-configuration.service';
import { DateDeleteMessageDialogComponent } from '../../shared/components/date-delete-message-dialog/date-delete-message-dialog.component';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Correspondence } from '../../modals/correspondence/conrrespondence';
import { InvoiceCharEnum } from '../../modals/billings/invoice-char-enum';
import * as $ from 'jquery';
import { BillingUIService } from '../services/billingUI.service';
import { MenuConstants } from '../../shared/constants/menu-constants';
import { InvoiceQueryParams } from '../../modals/queryparams/invoice-queryparams';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';
import { MigratedInvoiceService } from '../services/migrated-invoice.service';
import { MigratedInvoice } from '../../modals/billings/migrated-invoice';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import { InvoiceWrapper } from '../../modals/billings/invoice-wrapper';
import { CnRequestWrapper } from '../../modals/billings/cnRequestWrapper';
import { ChangesService } from '../../services/shared/changes.service';
import { AppUrls } from '../../services/urls';
import { InvoicesPrintConfigurationService } from '../../services/shared/invoices-print-configuration.service';
import { SystemService } from '../../services/shared/system.service';
import { InvoicePreviewComponent } from '../invoice-preview/invoice-preview.component';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { PrintConfig } from '../../modals/billings/print-config';
import { CommonService } from '../../services/shared/common.service';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from '../../services/shared/notifications-messages';
import { AnnexurePrintConfig } from '../../modals/billings/annexure-print-config';



@Component({
    selector: 'sib-bill-view',
    templateUrl: './bill-view.component.html',
    styleUrls: ['./bill-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BillViewComponent implements OnInit, OnDestroy {

    @ViewChild('grids')
    gridsViewChildRef: SuperbillHoardingsComponent;

    @ViewChild("invoicePreview")
    invoicePreviewChildRef: InvoicePreviewComponent;

    @ViewChild('printMenu') printMenu: OverlayPanel;

    currentDate = new Date();
    locale = "en-us";
    currentMonth = this.currentDate.toLocaleString(this.locale, { month: "long" });
    currentYear = this.currentDate.getFullYear();

    invoice: any;
    aInvoice: Invoice = new Invoice();
    mInvoice: MigratedInvoice = new MigratedInvoice();
    lastInvoice: Invoice = new Invoice();
    invoiceId = null;
    isLoading = false;
    period: Number;
    billingGrid: BillingGrid = new BillingGrid();

    displayConfirmationDialog = false;
    displayPartialApplyCNDiaglog = false;
    displayPrintDialog = false;
    reason: string;
    remark: string;
    header: string;
    billingNote: BillingNote = new BillingNote();
    amount: String = '';
    totalAmount: number;
    sumPartialCN: number;
    amountWithGST: Number = 0;
    differPartialCNTotamt: number;
    decimalNumbers: RegExp = /^(\d*\.)?\d+$/;
    twoPlaceDecimalNumbers: RegExp = /^(?:\d*\.\d{1,2}|\d+)$/; // for decimal number fields upto 2 places
    disableButton: boolean = false;
    displayApproveRejectDialog = false;
    appRejReason: string;
    appRejHeader: string;
    showRejectionDropdown = false;
    showApprovalDropdown = false;
    dialogDropdownOptionsRejection: SelectItem[] = [
        { label: "CN", value: "CN" },
        { label: "Other", value: "Other" },
    ];
    dialogDropdownOptionsApproval: SelectItem[] = [
        { label: "Approve with CN", value: "CN" },
        { label: "Other", value: "Other" },
    ];
    selectedReason: any;

    // admin = false;
    // accountant = false;
    // salesRepresentative = false;
    // billHandler = false;
    editable = false;
    checkbox = false;
    isBilled = true;

    selectedCompany: any;
    companies: any[] = [];

    selectedRentRows: any[] = [];
    selectedPrintingRows: any[] = [];
    selectedMountingRows: any[] = [];

    editedRentItems: any[] = [];
    editedPrintingItems: any[] = [];
    editedMountingItems: any[] = [];

    displayCompareBillsDialog = false;

    displayBillingNotes = false;  // for billing notes component

    subscription: Subscription[] = [];

    campaign: Campaign = new Campaign();

    moreMenu: MenuItem[];

    editDisableStatus: any[] = []; // list of status for which edit in more menu will be disabled

    cancelDisableStatus: any[] = []; // list of status for which cancel in more menu will be disabled

    discardEnableStatus: any[] = []; // list of status for which discard in more menu will be enabled

    discardStatusPartialCN: any[] = [];

    disablePendingCancelStatus: any[] = [];

    clickedPo: string;

    approveCancelRequest = false;

    billMarginParameter: number;
    amountOverdueDaysParameter: number;
    invoiceFreezeDaysParameter: number;

    editDisable = false;

    // printMenu: MenuItem[] = [];
    versionMenu: MenuItem[] = [];
    versionInvoice: Invoice = new Invoice();
    previousVersionInvoice: Invoice = new Invoice();
    versions: string[] = [];
    clickedVersion: string = null;
    showRevert = false;
    revert = false;

    // remove after adding print config in back end and integrating all apis
    printConfig: PrintConfig = new PrintConfig();


    safePdfUrl: SafeResourceUrl;
    safePdfName: string;

    displayPDFDialog = false;

    subView: string = 'INVOICE';
    correspondence: Correspondence = new Correspondence(); // for email
    displayPreviewSendDialog = false;

    // =============== tally and print ================================================

    // isTallyEntered = false;
    // isCustomerReceived = false;
    // isPrinted = false;

    isTallyEntered: InvoiceCharEnum;
    isCustomerReceived: InvoiceCharEnum;
    isPrinted: InvoiceCharEnum;

    isTallyEnteredBoolean: boolean = false;
    isPrintedBoolean: boolean = false;
    isCustomerReceivedBoolean: boolean = false;

    displayTallyDialog = false;
    defaultTallyId: string;

    isDuplicate: boolean = false;
    isWithoutDetail: boolean = false; // for with/without detail switch
    isWithoutLetterHead: boolean = false; // for with/without letterhead switch
    hidePrintContainer: boolean = false;

    CNApplyRequest = false;
    partialCNApplyRequest = false;

    index: number;
    invoiceQueryParams: InvoiceQueryParams = new InvoiceQueryParams();
    tempInvoice: Invoice = new Invoice();
    showChanges = false;
    displayRevertVersionDialog = false;
    grouped = false;
    migratedInvoice: boolean;
    migratedEdit: boolean = false;
    migratedCancel: boolean = false;
    // openMigratedInvoice: boolean = false;
    userRole: string = '';

    displayUpdateInvoiceDialog = false;
    displayDocumentComponent = false;
    viewDocumentsFromBilling = false;

    cnRequestWrapper: CnRequestWrapper = new CnRequestWrapper();
    invoiceCancellationReasons: SelectItem[] = [];
    cnReasons: SelectItem[] = [];
    invoiceFormats: SelectItem[] = [];
    selectedInvoiceFormat: any;
    preview = false; // for printing
    approvedStatus: string[] = []; // list of status for which quick update will be visible
    isInvFormatFullDetail: boolean = true;


    annexureMenu: boolean = false;
    annexurePrintConfig: AnnexurePrintConfig = new AnnexurePrintConfig();


    @HostListener('window:resize', ['$event'])
    onResize(event) {

    }


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private invoiceService: InvoiceService,
        private migratedService: MigratedInvoiceService,
        private confirmationService: ConfirmationService,
        private calculationService: CalculationService,
        private billingActionDispatcher: BillingActionDispatcher,
        private billingGridService: BillingGridService,
        private changeDetectorRef: ChangeDetectorRef,
        private campaignService: CampaignService,
        private firebaseService: FirebaseService,
        private billingConfigurationService: BillingConfigurationService,
        private notificationServcie: NotificatoinsService,
        private sanitizer: DomSanitizer,
        private billingUIService: BillingUIService,
        private pageTitle: Title,
        private cd: ChangeDetectorRef,
        private customerUiService: CustomerUiService,
        private changesService: ChangesService,
        private invoicesPrintCongigurationService: InvoicesPrintConfigurationService,
        private systemService: SystemService,
        private commonService: CommonService
    ) { }


    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.GENERATE_INVOICE) : this.pageTitle.setTitle(SIBConstants.GENERATE_INVOICE + ' - ' + appTitle);
        this.isLoading = true;
        this.userRole = this.customerUiService.getUserRole();
        this.currentDate = new Date();
        this.CNApplyRequest = false;
        this.partialCNApplyRequest = false;
        this.manageCompareSidebar();
        this.getBillingConfiguration();
        this.approvedStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_EDIT_APPROVE', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED']
        this.editDisableStatus = ["ADMIN_GEN_PENDING", "ACCOUNTANT_GEN_PENDING", "ADMIN_EDIT_PENDING", "ACCOUNTANT_EDIT_PENDING", "ADMIN_CANCEL_PENDING", "ACCOUNTANT_CANCEL_PENDING", "INVOICE_CANCEL", "INVOICE_CANCEL_WITH_RETENTION"];
        this.cancelDisableStatus = ["ADMIN_GEN_PENDING", "ACCOUNTANT_GEN_PENDING", "EDIT_PENDING", "ADMIN_EDIT_PENDING", "ACCOUNTANT_EDIT_PENDING", "ADMIN_CANCEL_PENDING", "ACCOUNTANT_CANCEL_PENDING", "INVOICE_CANCEL", "INVOICE_CANCEL_WITH_RETENTION"];
        this.discardEnableStatus = ['EDIT_PENDING'];
        this.disablePendingCancelStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ADMIN_CANCEL_PENDING', 'ADMIN_FULL_CN_PENDING', 'INVOICE_CANCEL'];
        // remove full cn approved status from disable pending cancel status  -->, 'FULL_CN_APPROVED' because more menu was not visible to do quick update menu button
        this.discardStatusPartialCN = ["ADMIN_GEN_PENDING", "ADMIN_GEN_REJECT", "EDIT_PENDING", "ADMIN_EDIT_PENDING", "ADMIN_EDIT_REJECT", "ADMIN_CANCEL_PENDING", "ADMIN_CANCEL_REJECT", "INVOICE_CANCEL_WITH_RETENTION", "ADMIN_FULL_CN_PENDING"];
        this.getRouteId();
        this.checkInvoice();
        this.setReasonsDropdown();
        this.setInvoiceFormatDropdown();

        this.subscription.push(this.invoiceService.$hsnCodeTemplateFetching.subscribe((event) => {
            this.isLoading = event;
        }))

    }


    setInvoiceFormatDropdown() {
        this.invoiceFormats = utils.createDropdown(InvoiceFormatEnum, false);
        this.setSelectedInvoiceFormat(null);
    }

    setSelectedInvoiceFormat(event) {
        if (!event) {
            if (this.invoice && this.invoice.invoiceFormat) {
                this.selectedInvoiceFormat = this.invoice.invoiceFormat;
                this.onInvoiceFormatChange();
            }
        } else {
            this.selectedInvoiceFormat = event;
            this.invoice.invoiceFormat = event;
            this.invoice = JSON.parse(JSON.stringify(this.invoice));
        }
    }

    onInvoiceFormatChange() {
        this.setSelectedInvoiceFormat(this.selectedInvoiceFormat);
    }

    getRouteId() {
        this.subscription.push(this.route.params.subscribe((params: { id: string, migrated: boolean }) => {
            this.invoiceId = params.id;
            // console.log("IDDD....", this.invoiceId, params.migrated);
            if (params.id.includes('MIG')) {
                this.invoice = this.mInvoice;
                this.migratedInvoice = true;
            } else {
                this.invoice = this.aInvoice;
                this.migratedInvoice = false;
            }


        }));
    }

    checkInvoice() {
        if (this.migratedInvoice) {
            this.getMigratedInvoice();
        } else {
            this.getInvoice();
        }

    }


    getMigratedInvoice() {
        this.subscription.push(this.migratedService.getMigratedInvoice(this.invoiceId).subscribe((invoice: any) => {
            if (invoice) {
                this.invoice = invoice;
                this.invoice = JSON.parse(JSON.stringify(this.invoice));
                this.changesService.setInitialMigratedInvoice(_.cloneDeep(this.invoice));
                this.setTallyAndPrintData();
                // this.setCompareDate();
                this.setMoreMenu();
                this.setInvoice();
                this.isLoading = false;
                if (this.invoice.printConfiguration) {
                    this.setPrintConfigurations(this.invoice.printConfiguration);
                }
            }
        }));
    }

    getInvoice() {
        this.subscription.push(this.invoiceService.getInvoice(this.invoiceId).subscribe((invoice: any) => {
            if (invoice) {
                this.invoice = invoice;
                this.invoice = JSON.parse(JSON.stringify(this.invoice));
                this.changesService.setInitialInvoice(_.cloneDeep(this.invoice));
                this.grouped = invoice.grouped;
                this.getInvoiceVersions();
                // InvoiceStatus.ADMIN_PENDING[this.invoice.status] === InvoiceStatus.ADMIN_PENDING.ADMIN_GEN_PENDING || 
                // if (InvoiceStatus.ADMIN_PENDING[this.invoice.status] === InvoiceStatus.ADMIN_PENDING.ADMIN_EDIT_PENDING) {
                if (this.invoice.status === 'ADMIN_EDIT_PENDING') {
                    this.getTempInvoice();
                }
                this.setTallyAndPrintData();
                // this.setCompareDate();
                this.toggleEditCancelOnFreezeDate();
                this.setMoreMenu();
                this.setInvoice();
                this.isLoading = false;
                if (this.invoice.printConfiguration) {
                    this.setPrintConfigurations(this.invoice.printConfiguration);
                }
            }
        }));
    }


    setInvoice() {
        this.invoice.billEndDate = this.invoice.billEndDate ? new Date(this.invoice.billEndDate) : null;
        this.invoice.billStartDate = this.invoice.billStartDate ? new Date(this.invoice.billStartDate) : null;
        this.period = this.calculationService.calculateDays(this.invoice.billStartDate, this.invoice.billEndDate);
        this.subscription.push(this.campaignService.getCampaign(this.invoice.campaign.id).subscribe((campaign: any) => {
            if (campaign !== null && campaign !== undefined) {
                this.campaign = campaign;
                this.campaign.campaignStartDate = new Date(this.campaign.campaignStartDate);
                this.campaign.campaignEndDate = new Date(this.campaign.campaignEndDate);
                if (campaign.customer.group !== null) {
                    this.subscription.push(this.billingGridService.getCustomerGroups(campaign.customer.group.id).subscribe((group) => {
                        group.forEach((customer) => {
                            this.companies.push({ label: customer.company, value: customer.company });
                            if (customer.company === campaign.customer.company) {
                                this.selectedCompany = customer.company;
                            }
                        });
                    }));
                } else {
                    this.companies.push({ label: campaign.customer.company, value: campaign.customer.company });
                    this.selectedCompany = campaign.customer.company;
                }
                // if ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ACCOUNTANT_EDIT_APPROVED || InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_REJECTED || InvoiceStatus[this.invoice.status] === InvoiceStatus.ACCOUNTANT_REJECTED) && this.salesRepresentative) {
                //     this.editable = true;
                //     this.checkbox = true;
                //     this.detectChanges();
                //     this.setGridForUnbilled();
                // } else {
                //     this.setGrids();
                // }
            }
        },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        ));
    }
    getInvoiceVersions() {
        this.isLoading = true;
        this.billingUIService.getInvoiceVersions(this.invoice.id).subscribe(
            (response) => {
                if (response) {
                    this.versions = response['data'];
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    getInvoiceWithVersion(version) {
        this.isLoading = true;
        this.billingUIService.getInvoiceWithVersion(this.invoice.id, version).subscribe(
            (response) => {
                if (response) {
                    this.versionInvoice = response['data']['invoice'];
                    this.versionInvoice = JSON.parse(JSON.stringify(this.versionInvoice));
                    this.isLoading = false;
                    this.displayRevertVersionDialog = true;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    getPreviousInvoiceWithVersion(version) {
        this.isLoading = true;
        this.billingUIService.getInvoiceWithVersion(this.invoice.id, version).subscribe(
            (response) => {
                if (response) {
                    this.previousVersionInvoice = response['data']['invoice'];
                    this.previousVersionInvoice = JSON.parse(JSON.stringify(this.previousVersionInvoice));
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    showVersion(version, showRevert) {
        this.clickedVersion = version;
        this.showRevert = showRevert;
        this.showChanges = false;
        this.getInvoiceWithVersion(version);
        const index = this.versions.findIndex(v => v === version);
        if (index !== this.versions.length - 1) {
            this.getPreviousInvoiceWithVersion(this.versions[index + 1]);
        } else {
            this.previousVersionInvoice = this.invoice;
            this.previousVersionInvoice = JSON.parse(JSON.stringify(this.previousVersionInvoice));
        }
    }

    revertInvoiceVersion() {
        this.displayRevertVersionDialog = false;
        this.billingUIService.revertToPreviousVersion(this.invoice.id, this.clickedVersion).subscribe(
            (response) => {
                if (response) {
                    this.invoice = response['data'];
                    this.invoice = JSON.parse(JSON.stringify(this.invoice));
                    this.revert = true;
                    this.isPrinted = this.invoice.printed;
                    this.notificationServcie.success('Invoice Reverted Successfully', 'Interested Version Revert');
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }



    hideRevertVersionDialog() {
        this.displayRevertVersionDialog = false;
        this.showChanges = false;
    }

    setPrintConfigurations(configuration) {

        switch (configuration) {
            case "000": {
                this.isDuplicate = true;
                this.isWithoutDetail = true;
                this.isWithoutLetterHead = true;
                break;
            }
            case "001": {
                this.isDuplicate = true;
                this.isWithoutDetail = true;
                this.isWithoutLetterHead = false;
                break;
            }
            case "010": {
                this.isDuplicate = true;
                this.isWithoutDetail = false;
                this.isWithoutLetterHead = true;
                break;
            }
            case "011": {
                this.isDuplicate = true;
                this.isWithoutDetail = false;
                this.isWithoutLetterHead = false;
                break;
            }
            case "100": {
                this.isDuplicate = false;
                this.isWithoutDetail = true;
                this.isWithoutLetterHead = true;
                break;
            }
            case "101": {
                this.isDuplicate = false;
                this.isWithoutDetail = true;
                this.isWithoutLetterHead = false;
                break;
            }
            case "110": {
                this.isDuplicate = false;
                this.isWithoutDetail = false;
                this.isWithoutLetterHead = true;
                break;
            }
            case "111": {
                this.isDuplicate = false;
                this.isWithoutDetail = false;
                this.isWithoutLetterHead = false;
                break;
            }
        }

    }
    getTempInvoice() {
        const invoiceQueryParams = new InvoiceQueryParams();
        invoiceQueryParams.id = this.invoice.id;
        invoiceQueryParams.projection = 'tempInvoiceProjection';
        this.billingGridService.getTempInvoice(this.invoice.id, 'tempInvoiceProjection').subscribe(
            (response) => {
                if (response) {
                    this.tempInvoice = response;
                    this.tempInvoice = JSON.parse(JSON.stringify(this.tempInvoice));
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
            }
        );
    }

    manageCompareSidebar() {
        if (!this.displayCompareBillsDialog) {
            $('#split-bar').hide();
            $('#main').hide();
            $('#sidebar').css({ "width": "100%" });
        } else {
            const min = 700;
            const max = 3600;
            const mainmin = 200;
            $('#split-bar').show();
            $('#main').show().css({ "margin-left": "0px" });
            $('#sidebar').css({ "width": "70%" });
            $('#split-bar').mousedown(function (e) {
                e.preventDefault();
                // tslint:disable-next-line:no-shadowed-variable
                $(document).mousemove(function (e) {
                    e.preventDefault();
                    const x = e.pageX - $('#sidebar').offset().left;
                    if (x > min && x < max && e.pageX < ($(window).width() - mainmin)) {
                        $('#sidebar').css("width", x);
                        $('#main').css("margin-left", x);
                    }
                });
                // tslint:disable-next-line:no-shadowed-variable
                $(document).mouseup(function (e) {
                    $(document).unbind('mousemove');
                });
            });
        }
    }

    setTallyAndPrintData() {
        this.isTallyEntered = this.invoice.tallyEntered;
        this.isCustomerReceived = this.invoice.customerReceived;
        this.isPrinted = this.invoice.printed;
        this.setInvoicePrintAndTallyBooleanValues();
    }

    setInvoicePrintAndTallyBooleanValues() {
        if (InvoiceCharEnum[this.isPrinted] !== InvoiceCharEnum.N) {
            this.isPrintedBoolean = (InvoiceCharEnum[this.isPrinted] !== InvoiceCharEnum.F) ? true : false;
        }
        if (InvoiceCharEnum[this.isTallyEntered] !== InvoiceCharEnum.N) {
            this.isTallyEnteredBoolean = (InvoiceCharEnum[this.isTallyEntered] !== InvoiceCharEnum.F) ? true : false;
        }
    }

    displayPreviewSendDialogReset(event) {
        this.displayPreviewSendDialog = false;
        this.correspondence = new Correspondence();
    }

    sendMail(correspondence) {
        this.invoiceService.sendMail(correspondence).subscribe((response) => {
            if (response) {
                this.notificationServcie.success('Mail Sent Successfully', 'Mail Sent');
            }
        });
    }


    getBillingConfiguration() {
        this.billingConfigurationService.getBillingConfiguration();

        this.subscription.push(this.billingConfigurationService.billMargin.subscribe((billMargin) => {
            this.billMarginParameter = billMargin;
        }));

        this.subscription.push(this.billingConfigurationService.invoiceFreezeDays.subscribe((invoiceFreezeDays) => {
            if (invoiceFreezeDays) {
                this.invoiceFreezeDaysParameter = invoiceFreezeDays;
                this.toggleEditCancelOnFreezeDate();
            }
            // this.setCompareDate();
        }));

        this.subscription.push(this.billingConfigurationService.amountOverDueDays.subscribe((amountOverDueDays) => {
            this.amountOverdueDaysParameter = amountOverDueDays;
        }));
    }

    toggleEditCancelOnFreezeDate() {
        if (this.invoice.id && this.invoiceFreezeDaysParameter) {
            const presentDate = new Date().getTime();
            const invoiceDate = DateUtil.convertDateTimeToDate(new Date(this.invoice.billGeneratedDate)).getTime();
            // const prevMonth = new Date().getMonth() - 1 < 0 ? 11 : new Date().getMonth() - 1;
            // const prevMonthStartDate = DateUtil.convertDateTimeToDate(new Date(new Date().getFullYear(), prevMonth, 1)).getTime();
            // const prevMonthEndDate = DateUtil.convertDateTimeToDate(new Date(new Date().getFullYear(), prevMonth, DateUtil.getDaysInMonth(prevMonth, new Date().getFullYear()))).getTime();
            const prevMonthDates = DateUtil.getLastNMonthStartDateEndDate(new Date().getMonth(), 1);
            const prevMonthStartDate = DateUtil.convertDateTimeToDate(new Date(prevMonthDates[0])).getTime();
            const prevMonthEndDate = DateUtil.convertDateTimeToDate(new Date(prevMonthDates[1])).getTime();
            const freezeDate = new Date(new Date().getFullYear(), new Date().getMonth(), this.invoiceFreezeDaysParameter).getTime();
            if (invoiceDate >= prevMonthStartDate && invoiceDate <= prevMonthEndDate && freezeDate < presentDate) {
                this.editDisable = true;
            } else if (invoiceDate < prevMonthStartDate) {
                this.editDisable = true;
            } else if (invoiceDate > new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()) {
                this.editDisable = false;
            }

            this.setMoreMenu();
        }



    }

    setCompareDate() {
        let compareDate: Date = new Date();
        if (new Date(this.invoice.billGeneratedDate).getDate() <= this.invoiceFreezeDaysParameter) {
            // check in same month
            compareDate = this.createCompareDate(true);
        } else {
            // check in next month
            compareDate = this.createCompareDate(false);
        }
        if (this.calculationService.setToBeginning(this.currentDate) < this.calculationService.setToBeginning(compareDate)) {
            this.editDisable = false;
        } else {
            this.editDisable = true;
        }
        this.setMoreMenu();
    }

    createCompareDate(sameMonth) {
        const date = new Date(this.invoice.billGeneratedDate);
        // date.setDate(new Date(this.currentDate).getDate());
        date.setDate(this.invoiceFreezeDaysParameter);
        if (sameMonth) {
            date.setMonth(new Date(this.invoice.billGeneratedDate).getMonth());
        } else {
            date.setMonth(new Date(this.invoice.billGeneratedDate).getMonth() + 1);
        }
        return new Date(date);
    }

    setMoreMenu() {
        this.moreMenu = [
            {
                label: ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT || InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT) && this.userRole === 'Sales Rep') ? MenuConstants.EDIT_CONTINUE_L : MenuConstants.EDIT_L,
                icon: MenuConstants.EDIT_I,
                command: (event) => {
                    this.editBill();
                },
                // || this.editDisable
                disabled: (this.migratedInvoice && this.userRole === 'Sales Rep') ? true : this.compareStatus(this.invoice.status, this.editDisableStatus),
                visible: !this.migratedInvoice ? ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT || (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT && this.invoice.invoiceId.includes('TEMP'))) ? true : !this.editDisable) : true,
            },
            // {
            //     label: MenuConstants.UPDATE_INVOICE_L,
            //     icon: MenuConstants.UPDATE_INVOICE_I,
            //     command: (event) => {
            //         this.updateInvoice();
            //     },
            //     disabled: (this.migratedInvoice && this.userRole === 'Sales Rep') ? true : this.compareStatus(this.invoice.status, this.editDisableStatus),
            //     visible: !this.migratedInvoice ? ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT || (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT && this.invoice.invoiceId.includes('TEMP'))) ? true : (!this.editDisable && !this.compareStatus(this.invoice.status, this.discardEnableStatus))) : true,
            // },
            {
                label: MenuConstants.QUICK_UPDATE_INVOICE_L,
                icon: MenuConstants.UPDATE_INVOICE_I,
                command: (event) => {
                    this.updateInvoice();
                },
                disabled: (this.migratedInvoice && this.userRole === 'Sales Rep') ? true : this.compareStatus(this.invoice.status, this.editDisableStatus),
                // visible: !this.migratedInvoice ? ((this.invoiceFreezeDaysParameter && (new Date().valueOf() > this.invoiceFreezeDaysParameter) && (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_APPROVE || InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_APPROVE)) ? true : (this.invoiceFreezeDaysParameter && (new Date().valueOf() < this.invoiceFreezeDaysParameter) && (InvoiceStatus[this.invoice.status] === InvoiceStatus.FULL_CN_APPROVED || InvoiceStatus[this.invoice.status] === InvoiceStatus.PARTIAL_CN_APPROVED)) ? true : false) : true,
                visible: !this.migratedInvoice ? (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_APPROVE || InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_APPROVE || InvoiceStatus[this.invoice.status] === InvoiceStatus.FULL_CN_APPROVED || InvoiceStatus[this.invoice.status] === InvoiceStatus.PARTIAL_CN_APPROVED) ? true : false : true,
            },
            {
                label: this.migratedInvoice ? MenuConstants.DELETE_M : (((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT || InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT) && this.userRole === 'Sales Rep') ? MenuConstants.CANCEL_RESTORE_L : ((this.userRole === 'Sales Rep') ? 'Contact admin for Cancel' : MenuConstants.CANCEL_L)),
                icon: MenuConstants.CANCEL_I,
                command: (event) => {
                    this.cancelBill();
                },
                disabled: (this.migratedInvoice && this.userRole === 'Sales Rep') ? true : ((this.userRole === 'Sales Rep' && (InvoiceStatus[this.invoice.status] != InvoiceStatus.ADMIN_GEN_REJECT && InvoiceStatus[this.invoice.status] != InvoiceStatus.ADMIN_EDIT_REJECT)) ? true : this.compareStatus(this.invoice.status, this.cancelDisableStatus)),
                visible: this.checkPermission('request:cancel-invoices') && (!this.migratedInvoice ? ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT || (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT && this.invoice.invoiceId.includes('TEMP'))) ? true : (!this.editDisable && !this.compareStatus(this.invoice.status, this.discardEnableStatus))) : true),
            },
            {
                label: MenuConstants.DISCARD_L,
                icon: MenuConstants.DISCARD_I,
                command: (event) => {
                    this.discardEdit();
                },
                disabled: !this.compareStatus(this.invoice.status, this.discardEnableStatus),
                visible: this.compareStatus(this.invoice.status, this.discardEnableStatus),
            },
            {
                label: MenuConstants.APPLY_CN_L,
                icon: MenuConstants.APPLY_CN_I,
                command: (event) => {
                    this.applyCN();
                },
                disabled: (InvoiceStatus[this.invoice.status] === InvoiceStatus.INVOICE_CANCEL || InvoiceStatus[this.invoice.status] === InvoiceStatus.INVOICE_CANCEL_WITH_RETENTION || InvoiceStatus[this.invoice.status] === InvoiceStatus.PARTIAL_CN_APPROVED || InvoiceStatus[this.invoice.status] === InvoiceStatus.FULL_CN_APPROVED || !this.checkPermission('readAll')),
                visible: !this.migratedInvoice ? ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT || (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT && this.invoice.invoiceId.includes('TEMP'))) ? false : (this.editDisable && !this.compareStatus(this.invoice.status, this.discardEnableStatus))) : false,
            },
            {
                label: MenuConstants.APPLY_PARTIALLY_CN_L,
                icon: MenuConstants.APPLY_PARTIALLY_CN_I,
                command: (event) => {
                    this.applyPartialCN();
                },
                disabled: InvoiceStatus[this.invoice.status] === InvoiceStatus.FULL_CN_APPROVED || !this.checkPermission('readAll'),
                visible: !this.migratedInvoice ? ((InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_GEN_REJECT || (InvoiceStatus[this.invoice.status] === InvoiceStatus.ADMIN_EDIT_REJECT && this.invoice.invoiceId.includes('TEMP'))) ? false : (this.editDisable && !this.compareStatus(this.invoice.status, this.discardEnableStatus))) : false,
            }
        ];
    }

    compareStatus(status, list) {
        for (let i = 0; i < list.length; i++) {
            if (status === list[i]) {
                return true;
            }
        }
        return false;
    }


    /**
     * setting selected rows for each grid
     *
     * @memberof BillViewComponent
     */
    setSelected() {
        this.invoice.rentItems.forEach((item) => {
            this.gridsViewChildRef.rentItems.forEach((itm) => {
                if (item.customId === itm.customId) {
                    this.gridsViewChildRef.rentGridViewChildRef.selected.push(itm);
                    this.gridsViewChildRef.selectedRentRows.push(itm);
                    this.selectedRentRows.push(itm);
                }
            });
        });
        this.invoice.printingItems.forEach((item) => {
            this.gridsViewChildRef.printingItemsWrapper.forEach((itm) => {
                if (item.customId === itm.customId) {
                    this.gridsViewChildRef.printingGridViewChildRef.selected.push(itm);
                    this.gridsViewChildRef.selectedPrintingRows.push(itm);
                    this.selectedPrintingRows.push(itm);
                }
            });
        });
        this.invoice.mountingItems.forEach((item) => {
            this.gridsViewChildRef.mountingItemsWrapper.forEach((itm) => {
                if (item.customId === itm.customId) {
                    this.gridsViewChildRef.mountingGridViewChildRef.selected.push(itm);
                    this.gridsViewChildRef.selectedMountingRows.push(itm);
                    this.selectedMountingRows.push(itm);
                }
            });
        });
    }


    setGrids() {
        // this.gridsViewChildRef.rentItems = this.invoice.rentItems;
        // this.gridsViewChildRef.rentItems.forEach((item) => {
        //     item.itemStartDate = new Date(item.itemStartDate);
        //     item.itemEndDate = new Date(item.itemEndDate);
        // });
        // this.gridsViewChildRef.mountingItemsWrapper = this.setMountingItemsWrapper();
        // this.gridsViewChildRef.printingItemsWrapper = this.setPrintingItemsWrapper();
        // this.gridsViewChildRef.refreshAllGrids();
    }

    setGridForUnbilled() {
        // this.billingGrid.billed = false;
        // this.billingGrid.itemStartDate = new Date(this.invoice.billStartDate);
        // this.billingGrid.itemEndDate = new Date(this.invoice.billEndDate);
        // this.billingGrid.campaignId = this.invoice.campaign.id;
        // // this.gridsViewChildRef.setBillingGrid(this.billingGrid);
        // this.gridsViewChildRef.setMinAndMaxDateForBillingGrid(this.billingGrid, this.campaign);
        // setTimeout(() => {
        //     this.setSelected();
        // }, 1000);

    }

    uploadBill() { }

    viewChanges() {
        this.showChanges = !this.showChanges;
    }

    updateInvoice() {
        this.displayUpdateInvoiceDialog = true;
    }

    onUpdateInvoiceDialogHide() {
        this.displayUpdateInvoiceDialog = false;
    }

    onInvoiceUpdate(invoiceWrapper: InvoiceWrapper) {
        this.isLoading = true;
        this.billingUIService.updateInvoice(invoiceWrapper, this.migratedInvoice).subscribe(
            (response) => {
                if (response) {
                    this.notificationServcie.success("Invoice Updated Successfully", "Invoice Update");
                    // this.getInvoice();
                    this.checkInvoice();
                    this.setActivityLog();
                    this.updateCampaignPO(invoiceWrapper);
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    editBill() {
        if (this.editDisable && !this.migratedInvoice && InvoiceStatus[this.invoice.status] !== InvoiceStatus.ADMIN_GEN_REJECT && (InvoiceStatus[this.invoice.status] !== InvoiceStatus.ADMIN_EDIT_REJECT && !this.invoice.invoiceId.includes('TEMP'))) {
            this.notificationServcie.info("This invoice can only be cancelled as it is past its freezing date", "Editing Prohibited");
        } else {

            if (this.migratedInvoice) {
                this.migratedService.create(this.invoice, null, '/edit/request').subscribe(
                    (response) => {
                        this.router.navigate(['billings/generateBill/' + this.invoice.campaign.id, { campaign: true, invoiceId: this.invoice.id, migrated: true }]);
                    }
                );


            } else {
                this.billingActionDispatcher.editRequest(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isEditRequestSuccess.subscribe((isEditRequestSuccess) => {
                    if (isEditRequestSuccess) {
                        this.billingActionDispatcher.resetIsEditRequestSuccess();
                        this.isLoading = false;
                        this.router.navigate(['billings/generateBill/' + this.invoice.campaign.id, { campaign: true, invoiceId: this.invoice.id }]);
                    }
                }));
            }

        }

    }

    cancelBill() {
        this.displayConfirmationDialog = true;
        this.header = !this.migratedInvoice ? "Cancel Request" : "Delete Invoice";
        this.reason = !this.migratedInvoice ? "Reason for cancellation" : "Reason for Deletion";
        this.setReasonsDropdown();
    }

    applyCN() {
        this.CNApplyRequest = true;
        this.displayConfirmationDialog = true;
        this.header = "CN Request";
        this.reason = "Comment for Internal System";
        this.remark = "Comment to be printed on CN";
        this.setReasonsDropdown();
        this.setInternalNote();
    }

    // method for apply the partially cn
    applyPartialCN() {

        this.partialCNApplyRequest = true;
        this.displayPartialApplyCNDiaglog = true;
        this.header = "Partial CN Request";
        this.reason = "Comment for Internal System";
        this.remark = "Comment to be printed on CN";
        // console.log(this.childInvoiceSummary.totalWithTax);
        this.amount = '';
        this.billingNote.message = "";
        // this.totalAmount = this.invoice.totalAmount;
        this.totalAmount = this.invoice.rentAmount + this.invoice.mountAmount + this.invoice.printAmount;
        this.setReasonsDropdown();
        // console.log(this.invoice);
        this.billingUIService.getSumPartialCN(this.invoice.invoiceId).subscribe(
            (response) => {
                if (response) {
                    this.sumPartialCN = response.data;
                    this.differPartialCNTotamt = utils.parseValueToDecimalTwoPlaces(this.totalAmount - this.sumPartialCN);

                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
            }

        );
    }


    discardEdit() {
        this.isLoading = true;
        if (this.migratedInvoice) {
            this.migratedService.create(this.invoice, null, '/edit/discard').subscribe((response) => {
                this.isLoading = false;
                this.discardInvoice();
            }
            );
        }
        else if (!this.migratedInvoice) {
            this.billingActionDispatcher.editDiscardRequest(this.invoice);
            this.subscription.push(this.billingActionDispatcher.isEditDiscardRequestSuccess.subscribe((isEditDiscardRequestSuccess) => {
                if (isEditDiscardRequestSuccess) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetIsEditDiscardRequestSuccess();
                    this.discardInvoice();
                }
            }));
        }


        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.isLoading = false;
                this.billingActionDispatcher.resetError();
            }
        }));
    }

    discardInvoice() {
        let nextState: ActivatedRouteSnapshot;
        if (nextState && nextState.url) {
            this.router.navigate([nextState.url]);
        } else {
            this.router.navigate(['billings/view/' + this.campaign.id]);
        }
    }

    cancelRequest() {
        this.displayConfirmationDialog = false;
        this.billingNote = new BillingNote();
        this.ngOnInit();
        /* if (!this.migratedInvoice) {

        } else {
            // this.cd.detectChanges();
            this.router.navigate(['billings']);
            // this.openMigratedInvoice = true;
        } */

    }
    onPartialCNDialog() {

    }

    cancelApplyCNRequest() {
        this.displayPartialApplyCNDiaglog = false;
        // this.billingNote = new BillingNote();
        // this.amount = '';
        // this.invoice.remark = "";
        this.amountWithGST = 0;
        // this.ngOnInit();
    }

    sendApplyCNRequest() {
        this.isLoading = true;
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.billingNote.date = new Date();
        this.billingNote.emailId = userInfo[0].email;
        // this.billingNote.name = userInfo[0].user_metadata.firstname + ' ' + userInfo[0].user_metadata.lastname;
        this.billingNote.name = userInfo[0].nickname;
        this.invoice.billingNotes.push(this.billingNote);
        this.setCnRequestWrapper();
        this.billingUIService.createApplyPartialCN(this.cnRequestWrapper).subscribe(
            (response) => {
                if (response) {
                    this.notificationServcie.success("Partial CN applied Successfully", "Applied CN");
                    this.getInvoice();
                    this.cancelApplyCNRequest();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    setCnRequestWrapper() {
        this.cnRequestWrapper.id = this.invoice.id;
        this.cnRequestWrapper.amount = this.amount;
        this.cnRequestWrapper.billingNotes = this.invoice.billingNotes;
        this.cnRequestWrapper.remark = this.invoice.remark;
    }

    //check the amount enter by user
    validamount(event) {
        const amountinput = this.decimalNumbers.test(event.target.value);
        if (amountinput) {
            const twoplaceamount = this.twoPlaceDecimalNumbers.test(event.target.value);
            if (twoplaceamount) {
                this.amountWithGST = utils.parseValueToDecimalTwoPlaces(Number(event.target.value) * 1.18);
                if (this.sumPartialCN == 0 && +event.target.value < +this.totalAmount) {

                    this.amount = String(utils.parseValueToDecimalTwoPlaces(this.amount));
                    this.disableButton = false;
                }
                else if (+event.target.value <= this.differPartialCNTotamt) {
                    this.amount = String(utils.parseValueToDecimalTwoPlaces(this.amount));
                    this.disableButton = false;
                }
                else {
                    this.disableButton = true;
                }
            }
            else {
                this.disableButton = false;
            }
        }
        else {
            this.disableButton = true;
            this.amountWithGST = 0;
            if (String(event.target.value).includes('.')) {
                const parsedInputArray = String(event.target.value).split('.');
                if (parsedInputArray.length > 2) {
                    let fractionalPart = parsedInputArray[1];
                    if (fractionalPart.length > 2) {
                        fractionalPart = String(fractionalPart).substr(0, 2);
                    }
                    event.target.value = Number(parsedInputArray[0] + '.' + parsedInputArray[1]);
                    this.validamount(event);
                }
            }
        }
    }

    sendRequest() {
        if (!this.migratedInvoice) {
            this.isLoading = true;
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.billingNote.date = new Date();
            this.billingNote.emailId = userInfo[0].email;
            // this.billingNote.name = userInfo[0].user_metadata.firstname + ' ' + userInfo[0].user_metadata.lastname;
            this.billingNote.name = userInfo[0].nickname;
            this.invoice.billingNotes.push(this.billingNote);
            if (this.header === "Edit Request") {
                // this.billingActionDispatcher.editRequest(this.invoice);
            } else {
                this.invoice.cnRequest = this.CNApplyRequest;
                if (!this.invoice.cnRequest) {
                    this.billingActionDispatcher.cancelRequest(this.invoice);
                } else {
                    this.sendCNRequest();
                }
            }

            this.subscription.push(this.billingActionDispatcher.isCancelRequestSuccess.subscribe((isCancelRequestSuccess) => {
                if (isCancelRequestSuccess) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetIsCancelRequestSuccess();
                    this.ngOnInit();
                }
            }));

            this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
                if (isError) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetError();
                }
            }));
        } else {
            // this.migratedService.cancelMigratedInvoice(this.invoiceId).subscribe();

            this.migratedService.get({ id: this.invoiceId }, '/remove')
                .subscribe((response) => {
                    // console.log("response from cancel Migrated", response)
                    this.router.navigate(['billings']);
                    this.notificationServcie.success('Invoice Deleted Successfully', 'Invoice Delete');
                });
        }
        this.cancelRequest();
        // this.ngOnInit();
    }

    sendCNRequest() {
        this.billingUIService.sendCNRequest(this.invoice).subscribe(
            (response) => {
                if (response) {
                    this.notificationServcie.success("CN Request Raised Successfully", "CN Request");
                    this.getInvoice();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    submitBill() {
        if (this.migratedInvoice) {
            this.submitMigratedBill();
            this.migratedEdit = true;
        } else {
            this.submitInvoice()
        }


    }

    submitMigratedBill() {
        this.isLoading = true;
        if (this.selectedRentRows.length !== 0) {
            this.invoice.billStartDate = new Date(this.minDate(this.selectedRentRows));
            this.invoice.billEndDate = new Date(this.maxDate(this.selectedRentRows));
        }
        this.invoice.rentItems = this.selectedRentRows;
        this.invoice.printingItems = this.selectedPrintingRows;
        this.invoice.mountingItems = this.selectedMountingRows;
        this.detectChanges();
        this.billingActionDispatcher.submitMigratedBill(this.invoice);
        this.subscription.push(this.billingActionDispatcher.isMigratedBillSubmitted.subscribe((isMigratedBillSubmitted) => {
            if (isMigratedBillSubmitted) {
                this.billingActionDispatcher.resetIsMigratedBillSubmitted();
                this.isLoading = false;
                this.ngOnInit();
            }
        }));
        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.billingActionDispatcher.resetError();
                this.isLoading = false;
            }
        }));
    }

    submitInvoice() {
        this.isLoading = true;
        if (this.selectedRentRows.length !== 0) {
            this.invoice.billStartDate = new Date(this.minDate(this.selectedRentRows));
            this.invoice.billEndDate = new Date(this.maxDate(this.selectedRentRows));
        }
        this.invoice.rentItems = this.selectedRentRows;
        this.invoice.printingItems = this.selectedPrintingRows;
        this.invoice.mountingItems = this.selectedMountingRows;
        this.detectChanges();
        this.billingActionDispatcher.submitBill(this.invoice);
        this.subscription.push(this.billingActionDispatcher.isBillSubmitted.subscribe((isBillSubmitted) => {
            if (isBillSubmitted) {
                this.billingActionDispatcher.resetIsBillSubmitted();
                this.isLoading = false;
                this.ngOnInit();
            }
        }));
        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.billingActionDispatcher.resetError();
                this.isLoading = false;
            }
        }));
    }

    getStatus(status) {
        // if (InvoiceStatus[status]) {
        //     return InvoiceStatus[status];
        // }
        // if (InvoiceStatus.ADMIN_PENDING[status]) {
        //     return InvoiceStatus.ADMIN_PENDING[status];
        // }
        // // if (InvoiceStatus.ACCOUNTANT_PENDING[status]) {
        // //     return InvoiceStatus.ACCOUNTANT_PENDING[status];
        // // }
        // if (InvoiceStatus.APPROVED[status]) {
        //     return InvoiceStatus.APPROVED[status];
        // }
        // if (InvoiceStatus.ADMIN_REJECTED[status]) {
        //     return InvoiceStatus.ADMIN_REJECTED[status];
        // }
        // // if (InvoiceStatus.ACCOUNTANT_REJECTED[status]) {
        // //     return InvoiceStatus.ACCOUNTANT_REJECTED[status];
        // // }

        return InvoiceStatus[status];


    }

    approveRequest() {
        this.isInvFormatFullDetail = false;
        // set the itemids
        this.invoice.itemIds = this.getInvoiceItemIds();
        // if (this.accountant) {
        if (this.billingUIService.checkPermission('approve:account-invoices')) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_GEN_PENDING) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_PENDING.ACCOUNTANT_GEN_PENDING) {
            if (this.invoice.status === 'ACCOUNTANT_GEN_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.approveGenerateRequestAccountant(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isGenerateRequestApprovedAccountant.subscribe((isGenerateRequestApprovedAccountant) => {
                    if (isGenerateRequestApprovedAccountant) {
                        this.billingActionDispatcher.resetIsGenerateRequestApprovedAccountant();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_EDIT_PENDING) {
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_PENDING.ACCOUNTANT_EDIT_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_EDIT_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.approveEditRequestAccountant(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isEditRequestApprovedAccountant.subscribe((isEditRequestApprovedAccountant) => {
                    if (isEditRequestApprovedAccountant) {
                        this.billingActionDispatcher.resetIsEditRequestApprovedAccountant();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_CANCEL_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_CANCEL_PENDING') {
                this.isLoading = false;
                // this.displayApproveRejectDialog = true;
                this.displayApproveRejectDialog = this.invoice.cnRequest ? false : true;
                this.appRejHeader = "Invoice Cancel Request";
                this.appRejReason = "Type of Bill Cancellation Approval";
                // this.showApprovalDropdown = true;
                this.showApprovalDropdown = false;
                this.approveCancelRequest = true;

                // =========================================
                if (!this.displayApproveRejectDialog) {
                    this.performAppRejRequest();
                }
            }
        }

        // if (this.admin) {
        if (this.billingUIService.checkPermission('approve:invoices')) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_GEN_PENDING) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_PENDING.ADMIN_GEN_PENDING) {
            if (this.invoice.status === 'ADMIN_GEN_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.approveGenerateRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isGenerateRequestApprovedAdmin.subscribe((isGenerateRequestApprovedAdmin) => {
                    if (isGenerateRequestApprovedAdmin) {
                        this.billingActionDispatcher.resetIsGenerateRequestApprovedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_EDIT_PENDING) {
            } else if (this.invoice.status === 'ADMIN_EDIT_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.approveEditRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isEditRequestApprovedAdmin.subscribe((isEditRequestApprovedAdmin) => {
                    if (isEditRequestApprovedAdmin) {
                        this.billingActionDispatcher.resetIsEditRequestApprovedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL || this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
            } else if (this.invoice.status === 'ADMIN_CANCEL_PENDING') {
                this.isLoading = true;
                // changes as per the new flow - no accountant

                // =============== end ========================
                this.billingActionDispatcher.approveCancelRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isCancelRequestApprovedAdmin.subscribe((isCancelRequestApprovedAdmin) => {
                    if (isCancelRequestApprovedAdmin) {
                        this.billingActionDispatcher.resetIsCancelRequestApprovedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
            } else if (this.invoice.status === 'ADMIN_FULL_CN_PENDING') {
                this.isLoading = true;
                this.billingUIService.approveCNRequest(this.invoice).subscribe(
                    (response) => {
                        if (response) {
                            this.notificationServcie.success("CN Request Approved Successfully", "CN Request Approval");
                            this.getInvoice();
                            this.isLoading = false;
                        }
                    },
                    (error) => {
                        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                        this.isLoading = false;
                    }
                );
            }
        }

        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.billingActionDispatcher.resetError();
                this.checkInvoice();
                this.isLoading = false;
            }
        }));
    }

    rejectRequest() {
        this.isInvFormatFullDetail = false;
        // set the itemids
        this.invoice.itemIds = this.getInvoiceItemIds();
        this.displayApproveRejectDialog = true;
        // if (this.accountant) {
        if (this.billingUIService.checkPermission('reject:account-invoices')) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_GEN_PENDING) {
            if (this.invoice.status === 'ACCOUNTANT_GEN_PENDING') {
                this.appRejHeader = "Invoice Generation Request";
                this.appRejReason = "Reason for Rejecting Invoice";
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_EDIT_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_EDIT_PENDING') {
                this.appRejHeader = "Invoice Editing Request";
                this.appRejReason = "Reason for Rejecting Edited Invoice";
                this.showRejectionDropdown = true;
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_CANCEL_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_CANCEL_PENDING') {
                this.appRejHeader = "Invoice Cancel Request";
                this.appRejReason = "Reason for Rejecting Invoice Cancellation";
                // this.showRejectionDropdown = true;
                this.showRejectionDropdown = false;
            }
        }

        // if (this.admin) {
        if (this.billingUIService.checkPermission('reject:invoices')) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_GEN_PENDING) {
            if (this.invoice.status === 'ADMIN_GEN_PENDING') {
                this.appRejHeader = "Invoice Generation Request";
                this.appRejReason = "Reason for Rejecting Invoice";
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_EDIT_PENDING) {
            } else if (this.invoice.status === 'ADMIN_EDIT_PENDING') {
                this.appRejHeader = "Invoice Editing Request";
                this.appRejReason = "Reason for Rejecting Edited Invoice";
                // this.showRejectionDropdown = true;
            } else if (this.invoice.status === 'ADMIN_CANCEL_PENDING') {
                this.appRejHeader = "Invoice Cancel Request";
                this.appRejReason = "Reason for Rejecting Invoice Cancellation";
                // this.showRejectionDropdown = true;
            } else if (this.invoice.status === 'ADMIN_FULL_CN_PENDING') {
                this.appRejHeader = "Invoice CN Request";
                this.appRejReason = "Reason for Rejecting Invoice CN";
            }
        }
    }

    cancelAppRejRequest() {
        this.displayApproveRejectDialog = false;
        this.billingNote = new BillingNote();
        this.selectedReason = null;
        this.showRejectionDropdown = false;
        this.showApprovalDropdown = false;
    }

    performAppRejRequest() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.billingNote.date = new Date();
        this.billingNote.emailId = userInfo[0].email;
        // this.billingNote.name = userInfo[0].user_metadata.firstname + ' ' + userInfo[0].user_metadata.lastname;
        this.billingNote.name = userInfo[0].nickname;
        // if (this.selectedReason === "CN") {
        //     if (this.showRejectionDropdown) {
        //         this.billingNote.systemMessage = "Tax Already Paid";
        //         this.billingNote.message = "Tax Already Paid";
        //     }
        //     if (this.showApprovalDropdown) {
        //         this.billingNote.systemMessage = "CN is raised";
        //         this.billingNote.message = "CN is raised";
        //     }
        // }
        // if (this.cancelAppRejRequest) {
        // if (this.showRejectionDropdown) {
        //     this.billingNote.systemMessage = "Tax Already Paid";
        //     this.billingNote.message = "Tax Already Paid";
        // }
        // if (this.showApprovalDropdown) {
        if (this.invoice.cnRequest) {
            this.billingNote.systemMessage = "CN is raised";
            this.billingNote.message = "CN is raised";
        }
        // }
        // }
        this.invoice.billingNotes.push(this.billingNote);
        this.cancelAppRejRequest();
        // only for accountant approval - will need to change if flow changes
        if (this.approveCancelRequest) {
            this.isLoading = true;
            this.billingActionDispatcher.approveCancelRequestAccountant(this.invoice);
            this.subscription.push(this.billingActionDispatcher.isCancelRequestApprovedAccountant.subscribe((isCancelRequestApprovedAccountant) => {
                if (isCancelRequestApprovedAccountant) {
                    this.billingActionDispatcher.resetIsCancelRequestApprovedAccountant();
                    this.approveCancelRequest = false;
                    this.isLoading = false;
                    this.ngOnInit();
                }
            }));
        }
        // if (this.accountant && !this.approveCancelRequest) {
        if (this.billingUIService.checkPermission('reject:account-invoices') && !this.approveCancelRequest) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_GEN_PENDING) {
            if (this.invoice.status === 'ACCOUNTANT_GEN_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectGenerateRequestAccountant(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isGenerateRequestRejectedAccountant.subscribe((isGenerateRequestRejectedAccountant) => {
                    if (isGenerateRequestRejectedAccountant) {
                        this.billingActionDispatcher.resetIsGenerateRequestRejectedAccountant();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_EDIT_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_EDIT_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectEditRequestAccountant(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isEditRequestRejectedAccountant.subscribe((isEditRequestRejectedAccountant) => {
                    if (isEditRequestRejectedAccountant) {
                        this.billingActionDispatcher.resetIsEditRequestRejectedAccountant();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_CANCEL_PENDING) {
            } else if (this.invoice.status === 'ACCOUNTANT_CANCEL_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectCancelRequestAccountant(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isCancelRequestRejectedAccountant.subscribe((isCancelRequestRejectedAccountant) => {
                    if (isCancelRequestRejectedAccountant) {
                        this.billingActionDispatcher.resetIsCancelRequestRejectedAccountant();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
            }
        }

        // if (this.admin) {
        if (this.billingUIService.checkPermission('reject:invoices')) {
            // if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_GEN_PENDING) {
            if (this.invoice.status === 'ADMIN_GEN_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectGenerateRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isGenerateRequestRejectedAdmin.subscribe((isGenerateRequestRejectedAdmin) => {
                    if (isGenerateRequestRejectedAdmin) {
                        this.billingActionDispatcher.resetIsGenerateRequestRejectedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ADMIN_EDIT_PENDING) {
            } else if (this.invoice.status === 'ADMIN_EDIT_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectEditRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isEditRequestRejectedAdmin.subscribe((isEditRequestRejectedAdmin) => {
                    if (isEditRequestRejectedAdmin) {
                        this.billingActionDispatcher.resetIsEditRequestRejectedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
                // } else if (this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL || this.getStatus(this.invoice.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
            } else if (this.invoice.status === 'ADMIN_CANCEL_PENDING') {
                this.isLoading = true;
                this.billingActionDispatcher.rejectCancelRequestAdmin(this.invoice);
                this.subscription.push(this.billingActionDispatcher.isCancelRequestRejectedAdmin.subscribe((isCancelRequestRejectedAdmin) => {
                    if (isCancelRequestRejectedAdmin) {
                        this.billingActionDispatcher.resetIsCancelRequestRejectedAdmin();
                        this.isLoading = false;
                        this.ngOnInit();
                    }
                }));
            } else if (this.invoice.status === 'ADMIN_FULL_CN_PENDING') {
                this.isLoading = true;
                this.billingUIService.rejectCNRequest(this.invoice).subscribe(
                    (response) => {
                        if (response) {
                            this.notificationServcie.success("CN Request Rejected Successfully", "CN Request Rejection");
                            this.getInvoice();
                            this.isLoading = false;
                        }
                    },
                    (error) => {
                        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                        this.isLoading = false;
                    }
                );
            }
        }

        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.isLoading = false;
                this.billingActionDispatcher.resetError();
            }
        }));
    }

    compareBills() {
        // this.isLoading = true;
        // this.billingActionDispatcher.getTemporaryInvoice(this.invoice.id);
        // this.subscription.push(this.billingActionDispatcher.tempInvoice.subscribe((tempInvoice) => {
        //     if (tempInvoice !== null && tempInvoice !== undefined) {
        //         this.isLoading = false;
        //         this.lastInvoice = _.cloneDeep(tempInvoice);
        //         this.displayCompareBillsDialog = true;
        //         this.billingActionDispatcher.resetTempInvoice();
        //     }
        // }));
        // this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
        //     if (isError) {
        //         this.isLoading = false;
        //         this.billingActionDispatcher.resetError();
        //     }
        // }));
        // this.displayCompareBillsDialog = !this.displayCompareBillsDialog;
        this.displayCompareBillsDialog = true;
        this.manageCompareSidebar();
    }


    viewDocuments() {
        this.displayDocumentComponent = true;
        this.viewDocumentsFromBilling = true;
    }

    hideDocumentDialog() {
        this.displayDocumentComponent = false;
    }

    closeCompareDialog() {
        this.displayCompareBillsDialog = false;
        this.clickedPo = null; // to reset the iframe
        this.index = -1; // to reset the class of selected po
        this.manageCompareSidebar();
    }

    onPOClick(po, index) {
        this.index = index;
        this.clickedPo = po.url;
        // this.firebaseService.downloadImage(po.name, po.folder).then((response) => {
        //     this.clickedPo = response;
        //     console.log("clikced po", this.clickedPo);
        // });
        return this.clickedPo;
    }

    checkPO(po, index) {
        let result = false;
        if (this.index === index) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    hideCompareBillsDialog() {
        this.displayCompareBillsDialog = false;
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            // this.changeDetectorRef.detectChanges();
        }
    }

    onEndDateChange() {
        if (new Date(this.invoice.billEndDate) < new Date(this.invoice.billStartDate)) {
            this.invoice.billEndDate = this.invoice.billStartDate;
            this.detectChanges();
        }
    }

    onStartDateChange() {
        if (new Date(this.invoice.billStartDate) > new Date(this.invoice.billEndDate)) {
            this.invoice.billEndDate = null;
            this.detectChanges();
        }
    }

    onRentRowSelection(event) {
        this.selectedRentRows = event;
        // console.log("selected rent rows", this.selectedRentRows);
    }

    onPrintingRowSelection(event) {
        this.selectedPrintingRows = event;
    }

    onMountingRowSelection(event) {
        this.selectedMountingRows = event;
    }

    setEditedRentItems(event) {
        this.editedRentItems = event;
        this.selectedRentRows.forEach((row) => {
            this.editedRentItems.forEach((item) => {
                if (row.customId === item.customId) {
                    row = item;
                }
            });
        });
    }

    setEditedPrintingItems(event) {
        this.editedPrintingItems = event;
        this.selectedPrintingRows.forEach((row) => {
            this.editedPrintingItems.forEach((item) => {
                if (row.customId === item.customId) {
                    row = item;
                }
            });
        });
    }

    setEditedMountingItems(event) {
        this.editedMountingItems = event;
        this.selectedMountingRows.forEach((row) => {
            this.editedMountingItems.forEach((item) => {
                if (row.customId === item.customId) {
                    row = item;
                }
            });
        });
    }

    minDate(rows) {
        // console.log("item end date", rows);
        let minDate: Date = rows[0].itemStartDate;
        for (let i = 0; i < rows.length; i++) {
            if (minDate > rows[i].itemStartDate) {
                minDate = new Date(rows[i].itemStartDate);
            }
        }
        return new Date(minDate);
    }

    maxDate(rows) {
        let maxDate: Date = rows[0].itemEndDate;
        for (let i = 0; i < rows.length; i++) {
            if (maxDate < rows[i].itemEndDate) {
                maxDate = new Date(rows[i].itemEndDate);
            }
        }
        return new Date(maxDate);
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

    /**
    * setting the printing items wrapper for grid display
    *
    * @memberof BillViewComponent
    */
    setPrintingItemsWrapper() {
        const printingItemsWrapper: AdditionalItemWrapper[] = [];
        this.invoice.printingItems.forEach((item) => {
            const printingItemWrapperPrototype: AdditionalItemWrapper = new AdditionalItemWrapper();
            printingItemWrapperPrototype.amount = item.amount;
            printingItemWrapperPrototype.city = item.city;
            printingItemWrapperPrototype.cost = item.cost;
            printingItemWrapperPrototype.customId = item.customId;
            printingItemWrapperPrototype.id = item.id;
            printingItemWrapperPrototype.location = item.location;
            printingItemWrapperPrototype.printingPrice = item.printingPrice;
            printingItemWrapperPrototype.mountingPrice = item.mountingPrice;
            printingItemWrapperPrototype.rate = item.rate;
            printingItemWrapperPrototype.size = item.size;
            printingItemWrapperPrototype.squareFeet = item.squareFeet;
            printingItemWrapperPrototype.serviceDate = item.serviceDate;
            printingItemWrapperPrototype.actualAmount = item.actualAmount;
            printingItemWrapperPrototype.creative = item.creative;
            if (item.printingPrice.flNlPrice !== 0) {
                printingItemWrapperPrototype.printingCost = item.printingPrice.flNlPrice;
            } else if (item.printingPrice.blPrice !== 0) {
                printingItemWrapperPrototype.printingCost = item.printingPrice.blPrice;
            }
            printingItemsWrapper.push(printingItemWrapperPrototype);
        });
        return printingItemsWrapper;
    }


    /**
     *setting the mounting items wrapper for grid display
     *
     * @memberof BillViewComponent
     */
    setMountingItemsWrapper() {
        const mountingItemsWrapper: AdditionalItemWrapper[] = [];
        this.invoice.mountingItems.forEach((item) => {
            const mountingItemWrapperPrototype: AdditionalItemWrapper = new AdditionalItemWrapper();
            mountingItemWrapperPrototype.amount = item.amount;
            mountingItemWrapperPrototype.city = item.city;
            mountingItemWrapperPrototype.cost = item.cost;
            mountingItemWrapperPrototype.customId = item.customId;
            mountingItemWrapperPrototype.id = item.id;
            mountingItemWrapperPrototype.location = item.location;
            mountingItemWrapperPrototype.printingPrice = item.printingPrice;
            mountingItemWrapperPrototype.mountingPrice = item.mountingPrice;
            mountingItemWrapperPrototype.rate = item.rate;
            mountingItemWrapperPrototype.size = item.size;
            mountingItemWrapperPrototype.squareFeet = item.squareFeet;
            mountingItemWrapperPrototype.serviceDate = item.serviceDate;
            mountingItemWrapperPrototype.actualAmount = item.actualAmount;
            mountingItemWrapperPrototype.creative = item.creative;
            if (item.mountingPrice.pricePerSqFt !== 0) {
                mountingItemWrapperPrototype.mountingCost = item.mountingPrice.pricePerSqFt;
            } else if (item.mountingPrice.pricePerUnit !== 0) {
                mountingItemWrapperPrototype.mountingCost = item.mountingPrice.pricePerUnit;
            }
            mountingItemsWrapper.push(mountingItemWrapperPrototype);
        });
        return mountingItemsWrapper;
    }

    viewNotes(invoice) {
        this.displayBillingNotes = true;
    }

    hideBillingNotesComponent() {
        this.displayBillingNotes = false;
    }

    printBillsWithDetailsWithLetterhead(isDuplicate, ids) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithDetailsWithLetterhead(this.invoice.id, isDuplicate, ids).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            // const anchor = document.createElement("a");
            // anchor.download = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            // anchor.href = url;
            // anchor.click();
            // this.safePdfName = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            // this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            // this.displayPDFDialog = true;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithDetailsWithoutLetterhead(isDuplicate, ids) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithDetailsWithoutLetterhead(this.invoice.id, isDuplicate, ids).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            // const anchor = document.createElement("a");
            // anchor.download = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            // anchor.href = url;
            // anchor.click();
            this.safePdfName = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            // this.displayPDFDialog = true;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithoutDetailsWithLetterhead(isDuplicate, ids) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithoutDetailsWithLetterhead(this.invoice.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            // const anchor = document.createElement("a");
            // anchor.download = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            // anchor.href = url;
            // anchor.click();
            this.safePdfName = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            // this.displayPDFDialog = true;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithoutDetailsWithoutLetterhead(isDuplicate, ids) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithoutDetailsWithoutLetterhead(this.invoice.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            // const anchor = document.createElement("a");
            // anchor.download = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            // anchor.href = url;
            // anchor.click();
            this.safePdfName = (this.invoice.id ? this.invoice.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            // this.displayPDFDialog = true;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    onTallyEnteredChange(event) {
        this.isTallyEnteredBoolean = event;
        this.invoice.tallyEntered = utils.getKey(InvoiceCharEnum, (event) ? InvoiceCharEnum.T : InvoiceCharEnum.F);
        this.isTallyEntered = event ? InvoiceCharEnum['T'] : InvoiceCharEnum['F'];
        if (event) {
            // changes for removing tally invoice id for vendor - chitra
            this.defaultTallyId = this.invoice.id;
            // this.showTallyDialog();
        }
        this.invoiceService.markInvoiceAsTallyEntered((event) ? 'T' : 'F', this.invoice.id, this.defaultTallyId).subscribe((response) => {
            if (!event) {
                this.notificationServcie.success("Invoice Unmarked As Tally Entered", "Invoice Unmarked As Tally Entered Successfully");
            }
            // changes for removing tally invoice id for vendor - chitra
            else {
                this.notificationServcie.success("Invoice Marked As Tally Entered", "Invoice Marked As Tally Entered Successfully");
            }
        });
    }

    setTallyDefaultValue() {
        if (this.invoice.tallyInvoiceId) {
            this.defaultTallyId = this.invoice.tallyInvoiceId;
        } else {
            this.defaultTallyId = this.invoice.id;
        }
    }

    saveTallyInvoiceId() {
        this.hideTallyDialog();
        this.invoiceService.markInvoiceAsTallyEntered('T', this.invoice.id, this.defaultTallyId).subscribe((response) => {
            this.notificationServcie.success("Invoice Marked As Tally Entered", "Invoice Marked As Tally Entered Successfully");
        });
    }

    cancelTallyInvoiceId() {
        this.hideTallyDialog();
    }

    showTallyDialog() {
        this.setTallyDefaultValue();
        this.displayTallyDialog = true;
    }

    hideTallyDialog() {
        this.displayTallyDialog = false;
        this.ngOnInit();
    }

    onTallyInvoiceValueChange(event) { }

    onInvoicePrintedChange(event) {
        this.isPrintedBoolean = event;
        this.isPrinted = event ? InvoiceCharEnum['T'] : InvoiceCharEnum['F'];
        this.invoice.printed = utils.getKey(InvoiceCharEnum, (event) ? InvoiceCharEnum.T : InvoiceCharEnum.F);
        this.invoiceService.markInvoiceAsPrinted((event) ? 'T' : 'F', this.invoice.id).subscribe((response) => {
            this.isPrintedBoolean ? this.notificationServcie.success("Invoice Marked As Printed", "Invoice Marked As Printed Successfully") : this.notificationServcie.success("Invoice Unmarked As Printed", "Invoice Unmarked As Printed Successfully");
        });
    }

    onInvoiceCustoemrReceivedChange(event) {
        this.isCustomerReceivedBoolean = event;
        this.isCustomerReceived = event ? InvoiceCharEnum['T'] : InvoiceCharEnum['F'];
        this.invoiceService.markInvoiceAsCustomerReceived((event) ? 'T' : 'F', this.invoice.id).subscribe((response) => {
            // console.log('response is', response);
        });
    }

    checkIsPrinted() {
        if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.N) {
            return "N";
        } else if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.F) {
            return "F";
        } else if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.T) {
            return "T";
        }
    }

    checkIsTallyEntered() {
        if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.N) {
            return "N";
        } else if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.F) {
            return "F";
        } else if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.T) {
            return "T";
        }
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    printingMenu() {
        this.displayPrintDialog = true;
    }

    onPrintTypeChange(event) {
        this.invoice.printConfiguration.original = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    onPrintLetterHeadChange(event) {
        this.invoice.printConfiguration.withLetterHead = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    onChildrenVisibilityChange(event) {
        this.invoice.printConfiguration.withChildren = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    savePrintingOptions(printConfig: any) {

        !this.annexureMenu ? this.invoice.printConfiguration = printConfig : this.invoice.annexurePrintConfiguration = printConfig;
        this.annexureMenu ? this.invoice.annexurePrintConfiguration.preview = false : null;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.printMenu.hide();
        !this.annexureMenu ? this.savePrintingInstructions() : this.saveAnnexurePrintingInstructions();

    }

    savePrintingInstructions() {
        // this.invoice.printConfiguration = printConfig;
        this.isLoading = true;
        this.commonService.saveBillingPrintConfig(this.invoice.printConfiguration, this.selectedInvoiceFormat, this.invoice.id, this.migratedInvoice ? SIBConstants.MIGRATED_INVOICE_PARAM : SIBConstants.INVOICE_PARAM)
            .subscribe(
                (response) => {
                    if (response) {
                        this.isLoading = false;
                        this.notificationServcie.success('Configuration Saved Successfully', 'Configuration Saved');
                    }
                },
                (error) => {
                    this.isLoading = false;
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                }
            );
    }

    previewPrintItems(printConfig: any) {
        !this.annexureMenu ? this.preview = true : null;
        !this.annexureMenu ? this.printInvoice(printConfig) : this.printAnnexure(printConfig);
    }

    printItems(printConfig: any) {
        !this.annexureMenu ? this.preview = false : null;
        !this.annexureMenu ? this.printInvoice(printConfig) : this.printAnnexure(printConfig);
    }

    printInvoice(printConfig: PrintConfig) {
        this.isLoading = true;
        this.checkForAddBlock();
        if (this.userRole === 'Sales Rep') {
            printConfig.withLetterHead = true;
        }
        this.invoiceService.printInvoice(utils.createPrintWrapperObject(printConfig, this.selectedInvoiceFormat, this.preview, this.getInvoiceItemIds()), this.invoice.id)
            .subscribe(
                (response) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                        this.isLoading = false;
                    }
                },
                // (error) => {
                //     this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Print Error");
                // }
                (error) => {
                    this.isLoading = false;
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                }
            );
    }

    checkForAddBlock() {
        if ($("#bottomAd").height() > 0) {
            // console.log("ad block inctive", $("#bottomAd").height());
        } else {
            // console.log("ad block active", $("#bottomAd").height());
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }
    }

    printingItem(isDuplicate, isWithoutDetail, isWithoutLetterHead) {

        if ($("#bottomAd").height() > 0) {
            // console.log("ad block inctive");
        } else {
            // console.log("ad block active");
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }

        // if (this.userRole !== 'Admin' || this.userRole === undefined) {
        //     isWithoutLetterHead = false;
        // }
        const ids: string[] = this.getInvoiceItemIds();
        if (this.userRole === 'Sales Rep') {
            isWithoutLetterHead = false;
        }
        if (!isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithDetailsWithLetterhead(isDuplicate, ids);
        } else if (!isWithoutDetail && isWithoutLetterHead) {
            this.printBillsWithDetailsWithoutLetterhead(isDuplicate, ids);
        } else if (isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithoutDetailsWithLetterhead(isDuplicate, ids);
        } else {
            this.printBillsWithoutDetailsWithoutLetterhead(isDuplicate, ids);
        }

    }

    getInvoiceItemIds() {
        if (this.invoicePreviewChildRef && this.invoicePreviewChildRef.summaryGrid) {
            return this.invoicePreviewChildRef.summaryGrid.getInvoiceGridItemIds();
        }
    }

    togglePrintMenu(event) {
        this.annexureMenu = false;
        this.printConfig = JSON.parse(JSON.stringify(this.invoice.printConfiguration));
        this.printMenu.toggle(event);
    }

    toggleAnnexurePrintMenu(event) {
        this.annexureMenu = true;
        // this.annexurePrintConfig = new AnnexurePrintConfig();
        this.annexurePrintConfig = JSON.parse(JSON.stringify(this.invoice.annexurePrintConfiguration));
        this.printMenu.toggle(event);
    }

    toggleView(event) {
        this.grouped = event;
        this.invoice.grouped = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.invoiceService.markInvoiceAsGrouped(this.invoice.id, this.grouped).subscribe(
            (response) => {
                if (response) {
                    this.invoice = JSON.parse(JSON.stringify(this.invoice));
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
            }
        );
    }

    getClass(status) {
        if (InvoiceStatus[status]) {
            if (InvoiceStatus[status].includes("Pending") || InvoiceStatus[status].includes("progress")) {
                return "color-pending";
            } else if (InvoiceStatus[status].includes("Rejected")) {
                return "color-rejected";
            } else {
                return "color-approved";
            }
        }
    }

    setActivityLog() {
        this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
            (response) => {
                // console.log("response", response);
                this.changesService.resetData();
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
            }
        );
    }

    /**
     * Sets dropdown for invoice cancellation reasons and cn reasons
     *
     * @memberof BillViewComponent
     */
    setReasonsDropdown() {
        this.systemService.get({ module: 'BILLING' }, AppUrls.SEARCH + AppUrls.BY_MODULE).subscribe((response) => {
            response.forEach((item) => {
                if (item.key === 'INVOICE_CANCEL_REASON') {
                    this.invoiceCancellationReasons = utils.createDropdownFromStringArray(item.value, false);
                } else if (item.key === 'CN_REASON') {
                    this.cnReasons = utils.createDropdownFromStringArray(item.value, false);
                }
            })
        })
    }

    onRowsReorder(reorderedRows) {
        this.invoice.rowsOrder = reorderedRows;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    /**
     * @description edit campaign po on quick invoice update
     * @author Divya Sachan
     * @date 2019-11-07
     * @param {*} event
     * @memberof BillViewComponent
     */
    updateCampaignPO(event) {
        if (event && event.purchaseOrders && event.purchaseOrders.length === 1 && event.isPoEdited) {
            this.campaignService.patch(event.purchaseOrders[0], null, 'editPO/' + this.invoice.campaign.id).subscribe((response) => {
                this.notificationServcie.success('PO Edited Successfully', 'PO Editing');
            }, (error) => {
                this.notificationServcie.error('PO Editing Unsuccessfull', 'PO Editing');
            });
        }
    }

    /**
     * @description set internal note if any
     * @author Divya Sachan
     * @date 2019-11-19
     * @memberof BillViewComponent
     */
    setInternalNote() {
        let internalNotes: any[] = [];
        internalNotes = this.invoice.billingNotes.filter(note => !note.systemMessage && (note.message !== note.systemMessage));
        this.billingNote.message = internalNotes[internalNotes.length - 1].message;
    }



    /**
     * @description on annexure with or w/o other works option change
     * @author Raveena Nathani
     * @date 2020-08-04
     * @memberof BillViewComponent
     */
    onAnnexureOtherWorksOptionsChange(event) {
        this.invoice.annexurePrintConfiguration.withOtherWorks = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }


    /**
     * @description on annexure with or w/o units option change
     * @author Raveena Nathani
     * @date 2020-08-04
     * @memberof BillViewComponent
     */
    onAnnexureUnitsVisibilityChange(event) {
        this.invoice.annexurePrintConfiguration.withUnits = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }


    /**
     * @description save Annexure Printing Instructions
     * @author Raveena Nathani
     * @date 2020-08-04
     * @memberof BillViewComponent
     */
    saveAnnexurePrintingInstructions() {
        this.isLoading = true;
        this.invoiceService.saveAnnexureInstructions(this.invoice.annexurePrintConfiguration, this.invoice.id)
            .subscribe(
                (response) => {
                    if (response) {
                        this.isLoading = false;
                        this.notificationServcie.success(NotificationsMessages.ANNEXURE_CONFIGURATION_SAVED_SUCCESSFULLY, NotificationsMessages.CONFIGURATION_SAVED);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                }
            );
    }


    /**
     * @description print Annexure
     * @author Raveena Nathani
     * @date 2020-08-04
     * @memberof BillViewComponent
     */
    printAnnexure(printConfig: AnnexurePrintConfig) {
        this.isLoading = true;
        this.checkForAddBlock();
        this.invoiceService.printAnnexure(printConfig, this.invoice.id)
            .subscribe(
                (response) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                        this.isLoading = false;
                    }
                },
                (error) => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    this.isLoading = false;
                }
            );
    }

}
