import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GridConfig } from "../../../components/sib-forms/grid/grid.config";
import { Inventory } from "../../../modals/hoardings/inventory";
import { LoaderSubjects } from "../../../modals/loader-subjects/loader-subjects";
import { PPTLoader } from "../../../modals/loader-subjects/pptloader";
import { MediaTypeEnum } from "../../constants/media-type-enum";
import { ListHoardingsComponent } from "../list-hoardings/list-hoardings.component";

@Component({
  selector: "sib-common-hoardings",
  templateUrl: "./common-hoardings.component.html",
  styleUrls: ["./common-hoardings.component.css"],
})
export class CommonHoardingsComponent implements OnInit {
  @ViewChild("sibParentGrid") gridParentViewRef: ListHoardingsComponent;
  @ViewChild("sibChildrenGrid") gridChildViewRef: ListHoardingsComponent;

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
  }
  get isExpired() {
    return this._isExpired;
  }

  _gridTitle: string;
  @Input() set gridTitle(gridTitle) {
    this._gridTitle = gridTitle;
  }
  get gridTitle() {
    return this._gridTitle;
  }

  _searchEvent: any;
  @Input() set searchEvent(searchEvent) {
    this._searchEvent = searchEvent;
    this.createLoaderObject("XLS", this.xlsLoader);
  }
  get searchEvent() {
    return this._searchEvent;
  }

    @Input() hideCommonInventoryDialog: boolean = false;

  @Input() totalRecords: number;
  @Input() gridConfig: GridConfig<Inventory> = new GridConfig();
  @Input() columns: any[];
  @Input() showFilter: boolean;
  @Input() source: string;
  @Input() showGroups: boolean;
  @Input() sidebarValues: any[];
  @Input() tradedHoardings: boolean;
  @Input() advanceSearch: any = {};
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitEditHoarding: EventEmitter<any> = new EventEmitter();
  @Output() eEmitShowGroups: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckHoarding: EventEmitter<{
    rowData;
    rowIndex;
  }> = new EventEmitter();
  @Output() eEmitViewHistory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImageUrl: EventEmitter<any> = new EventEmitter();

  selectedChildHoardings: any[] = [];
  selectedParentHoardings: any[] = [];
  isFileDownloading: boolean = false;
  xlsLoader: PPTLoader = new PPTLoader();

  constructor(private loaderSubjects: LoaderSubjects) {}

  ngOnInit() {}

  /**
   * @description view images of hoarding
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  viewImages(event) {
    this.eEmitViewImage.emit(event);
  }

  /**
   * @description view statusqueue of hoarding
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  viewDetail(event) {
    this.eEmitViewDetail.emit(event);
  }

  /**
   * @description edit hoarding
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  editInventory(event) {
    this.eEmitEditHoarding.emit(event);
  }

  /**
   * @description show hoarding groups
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  showHoardingGroups(event) {
    this.eEmitShowGroups.emit(event);
    // this.eRowSelection.emit(this.selectedHoardings);
  }

  /**
   * @description refresh grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonHoardingsComponent
   */
  refreshGrid() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.refreshGrid();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.refreshGrid();
    }
  }

  /**
   * @description update user grid column config
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} selectedColumns
   * @memberof CommonHoardingsComponent
   */
  updatingUserGridConfig(selectedColumns) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.updatingUserGridConfig(selectedColumns);
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.updatingUserGridConfig(selectedColumns);
    }
  }

  /**
   * @description set columns
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonHoardingsComponent
   */
  setColumns() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.setColumns();
          }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.setColumns();
          }
  }

  /**
   * @description on child hoarding selection
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  onChildRowSelection(event) {
    this.selectedChildHoardings = event;
    this.eRowSelection.emit([
      ...this.selectedParentHoardings,
      ...this.selectedChildHoardings,
    ]);
  }

  /**
   * @description on parent hoarding selection
   * @author Divya Sachan
   * @date 2020-01-29
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  onParentRowSelection(event) {
    this.selectedParentHoardings = event;
    this.eRowSelection.emit([
      ...this.selectedParentHoardings,
      ...this.selectedChildHoardings,
    ]);
  }

  /**
   * @description push value to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} value
   * @memberof CommonHoardingsComponent
   */
  pushData(data, value) {
    if (this.gridChildViewRef && !value.hasChild) {
      this.gridChildViewRef.pushData(data, value);
    }
    if (this.gridParentViewRef && value.hasChild) {
      this.gridParentViewRef.pushData(data, value);
    }
  }

  /**
   * @description push values to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} values
   * @memberof CommonHoardingsComponent
   */
  pushMultipleValues(data, values) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.pushMultipleValues(data, this.getChildren(values));
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.pushMultipleValues(data, this.getParent(values));
    }
  }

  /**
   * @description filter data
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} totalRecords
   * @param {*} values
   * @param {*} comparator
   * @memberof CommonHoardingsComponent
   */
  filterData(totalRecords, values, comparator) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.filterData(
        totalRecords,
        this.getChildren(values),
        comparator
      );
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.filterData(
        totalRecords,
        this.getParent(values),
        comparator
      );
    }
  }

  /**
   * @description reset selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonHoardingsComponent
   */
  resetSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.selected = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.selected = [];
    }
  }

  /**
   * @description reset previously selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonHoardingsComponent
   */
  resetPreviousSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.previousSeleceted = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.previousSeleceted = [];
    }
  }

  refresh() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.refreshFormTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.refreshFormTable();
    }
  }

  /**
   * @description reset datatable
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonHoardingsComponent
   */
  resetDataTable() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.resetDataTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.resetDataTable();
    }
  }

  setSelectedRows() {
    if (this.gridChildViewRef) {
      if (this.gridChildViewRef.gridViewChildRef) {
        // this.gridChildViewRef.gridViewChildRef.selected = this.getChildren(this.selectedHoardings);
        this.gridChildViewRef.gridViewChildRef.selected = this.selectedChildHoardings;
      }
    }
    if (this.gridParentViewRef) {
      if (this.gridParentViewRef.gridViewChildRef) {
        // this.gridParentViewRef.gridViewChildRef.selected = this.getParent(this.selectedHoardings);
        this.gridParentViewRef.gridViewChildRef.selected = this.selectedParentHoardings;
      }
    }
  }

  /**
   * @description reset selected hoarding
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} rowData
   * @memberof CommonHoardingsComponent
   */
  resetSelectedRow(rowData) {
    if (rowData.hasChild) {
      this.selectedParentHoardings = this.selectedParentHoardings.filter(
        (item) => item.id !== rowData.id
      );
    }
    if (!rowData.hasChild) {
      this.selectedChildHoardings = this.selectedChildHoardings.filter(
        (item) => item.id !== rowData.id
      );
    }
    this.setSelectedRows();
  }

  /**
   * @description get parent's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonHoardingsComponent
   */
  getParent(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === true)
      : [];
  }

  /**
   * @description get children's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonHoardingsComponent
   */
  getChildren(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === false)
      : [];
  }

  /**
   * @description export xls for hoardings
   * @author Divya Sachan
   * @date 2020-01-30
   * @memberof CommonHoardingsComponent
   */
  exportXlsx(event) {
    if (event) {
      this.isFileDownloading = true;
      this.createLoaderObject("XLS", this.xlsLoader);
      if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
        this.loaderSubjects.advanceSearchHoardingXlsLoader.next(this.xlsLoader);
      } else {
        this.loaderSubjects.hoardingXlsLoader.next(this.xlsLoader);
      }
      this.isFileDownloading = false;
    }
  }

  /**
   * @description loader object for Xls
   * @author Divya Sachan
   * @date 2020-01-30
   * @param {*} fileType
   * @param {*} object
   * @memberof CommonHoardingsComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = "Downloading XLS...";
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
    object.source = this.source;
    object.mediaType = MediaTypeEnum.HOARDING;
  }

  // onRowSelection(event) {
  //   this.selectedHoardings = [...this.selectedHoardings, ...event];
  //   let hoardings = Array.from(new Set(this.selectedHoardings.map(item => item.id)));
  //   let selectedHoardings = [];
  //   hoardings.forEach(hoardingId => {
  //     selectedHoardings.push(this.selectedHoardings.find(item => item.id === hoardingId));
  //   });
  //   this.selectedHoardings = selectedHoardings;
  //   this.eRowSelection.emit(this.selectedHoardings);
  // }

  /**
   * @description
   * @author Divya Sachan
   * @date 2020-02-03
   * @memberof CommonHoardingsComponent
   */
  getUserGridConfig() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.getUserGridConfig();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.getUserGridConfig();
    }
  }

  /**
   * @description check hoarding when adding from campaign
   * @author Divya Sachan
   * @date 2020-02-10
   * @param {*} event
   * @memberof CommonHoardingsComponent
   */
  checkHoarding(event) {
    this.eEmitCheckHoarding.emit(event);
  }

  viewHistory(event) {
    this.eEmitViewHistory.emit(event);
  }

  viewImageOnHover(event) {
    this.eEmitImageUrl.emit(event);
  }
}
