import { Pipe, PipeTransform } from "@angular/core";
import { SupplierVendorType } from "../../../modals/supplier/supplier-vendor-type";
import { CampaignStatusEnum } from "../../constants/campaign-status-enum";
import { HoardingStatusEnum } from "../../constants/hoarding-status-enum";
@Pipe({
  name: "keyToValue",
  pure: false,
})
export class KeyToValuePipe implements PipeTransform {
  transform(value: any, enumType: string) {
    const enumVal = this.getEnum(enumType);
    const val = enumVal[value];
    return val;
  }

  getEnum(enumType) {
    switch (enumType) {
      case "HoardingStatusEnum": {
        return HoardingStatusEnum;
      }
      case "CampaignStatusEnum": {
        return CampaignStatusEnum;
      }
      case "SupplierVendorType": {
        return SupplierVendorType;
      }
    }
  }
}
