export class PptGenerationData {
  data: string;
  srno: any;
  imageHeight: number;
  imageWidth: number;
  header: string;
  size: string;
  lightType: string;
  rate: number;
  numberOfDays: number;
  customId: string;
  availabilityDate: string;
  operatedDate: string;
  imageType: string;
  mediaType: string;
  mode: string;
  temporaryId: number;
  hasChild: boolean;
  childInventoriesLength: number;
  location: string;
  city: string;
  quantity: number;
  monthlyRate?: number;
}
