<div class="ui-g "
  [ngClass]="{'no-notification' :notifications.length === 0, 'web-notifications__container': notifications.length > 0}">
  <div *ngIf="isLoading" class="loader">
    <div>
      <p-progressSpinner></p-progressSpinner>

    </div>
  </div>
  <div *ngIf="notifications.length === 0 && !isLoading" class="text-align__no-notification">
    No notification(s) found.
  </div>
  <p-dataScroller #dscroller [value]="notificationData" [rows]="10" [inline]="true" [scrollHeight]="scrollHeight"
    [totalRecords]="notifications?.length">
    <ng-template let-notification let-i="index" pTemplate="item">
      <div class="notification-container">
        <div [ngClass]="{'unread-background': !notification.read, 'read-notifications': notification.read}">
          <div class="title-weight title-font-color title-margin">
            {{notification.messages[0][1]}}

            <i *ngIf="!notification.read" class="fa fa-check-circle custom-icon true-color float-right cursor-pointer"
              title="Mark as read" (click)="markAsRead(notification.messages[2][1])"></i>
          </div>
          <div class="notification-body" *ngFor="let message of notification.messages">
            <ng-container *ngIf="message[0] !== 'Link' && message[0] != 'Title' && message[0] !== 'Custom Id'">
              <span class="body-description-font-size body-title-description-font-color">
                {{message[0]}}:
              </span>

              <span class="body-description-font-size body-description-font-color" style="word-break: break-all;"
                *ngIf="message[0] === 'Display Name'">
                <a href="{{notification.messages[1][1]}}" target="_blank">{{message[1]}}</a>
              </span>

              <span class="body-description-font-size body-description-font-color" style="word-break: break-all;"
                *ngIf="message[0] !== 'Display Name'">
                {{message[1]}}
              </span>
            </ng-container>

          </div>
        </div>
      </div>
    </ng-template>
  </p-dataScroller>
</div>