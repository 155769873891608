
import { map } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent, Alignment } from "../../components/sib-forms/grid/grid.config";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { UserGridConfigService } from "../../services/shared/user-grid-config.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { Invoice } from "../../modals/billings/invoice";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import { InvoiceStatus } from "../../shared/constants/invoice-status-enum";
import { OverlayPanel, SelectItem, Dropdown } from "primeng/primeng";
import { InvoiceService } from "../../services/shared/invoice.service";
import { FirebaseService } from "../../services/shared/firebase.service";
import { DateUtil } from "../../helpers/date.util";
import * as utils from '../../helpers/utils';
import { GridNameEnum } from "../../shared/constants/grid-name.enum";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { BillingUIService } from "../services/billingUI.service";
import { StatusVisualizationNode } from "../../modals/billings/status-visualization-node";
import { HostListener } from '@angular/core';
import { InvoiceChartComponent } from '../../billings/invoice-chart/invoice-chart.component';
import { ElementRef } from '@angular/core';
import { MonthEnum } from '../../shared/constants/month.enum';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { InvoiceCharEnum } from '../../modals/billings/invoice-char-enum';
import { Subscription } from 'rxjs';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AppUrls } from '../../services/urls';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import * as _ from "lodash";
import { PrintConfig } from '../../modals/billings/print-config';
import { CommonService } from '../../services/shared/common.service';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import * as $ from 'jquery';
import { NotificationsMessages } from '../../services/shared/notifications-messages';
import { ErrorUtil } from '../../helpers/error.utils';

@Component({
    selector: 'sib-invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InvoiceListComponent implements OnInit, AfterViewInit, OnDestroy {

    _showEmployee: boolean;
    @Input() set showEmployee(showEmployee) {
        this._showEmployee = showEmployee;
        this.setColumns();
    }

    get showEmployee() {
        return this._showEmployee;
    }

    _isInvoiceChartFilter: boolean;
    @Input() set isInvoiceChartFilter(invoiceChartFilter) {
        this._isInvoiceChartFilter = invoiceChartFilter;
        // this.billGridViewChildRef.refresh();
    }
    get isInvoiceChartFilter() {
        return this._isInvoiceChartFilter;
    }

    _invoiceChartEvent: any;
    @Input() set invoiceChartEvent(invoiceChartEvent) {
        this._invoiceChartEvent = invoiceChartEvent;
        // this.billGridViewChildRef.refresh();
    }
    get invoiceChartEvent() {
        return this._invoiceChartEvent;
    }

    @Input() isFinancialYearChanged: boolean = false;

    _financialYear: any;
    @Input() set financialYear(financialYear) {
        this._financialYear = financialYear;
        this.billGridViewChildRef.refresh();
    }

    get financialYear() {
        return this._financialYear;
    }

    @ViewChild('billSibGrid')
    billSibGrid;

    customFilterApplied = false; // for dropdown filters
    customFilter: any;

    rowData: any;
    rowIndex: number;
    rowsPerPage: number;

    invoiceTotalRecords = 0;

    billGroup: FormGroup;

    billGridConfig: GridConfig<Invoice> = new GridConfig<Invoice>();
    billButtonConfig: GridActionButtonConfig;

    invoiceStatusDropdown: any[] = [];

    invoices: Invoice[] = [];

    admin = false;
    accountant = false;
    salesRepresentative = false;

    // to set the values of visualization chars and titles
    pendingAt: any[] = [];
    charAt: any[] = [];
    charTitleAt: any[] = [];

    // to check in which flow the invoice is present
    generateFlowStatus = [];
    editFlowStatus = [];
    cancelFlowStatus = [];

    // to check with whom the invoice is pending
    srPendingStatus = [];
    adminPendingStatus = [];
    accountantPendingStatus = [];
    flowCompleteWithApprovalStatus = [];
    flowCompleteWithRejectionStatus = [];

    srPending = false;
    adminPending = false;
    accountantPending = false;

    activeIndex: number;
    rowInd: number;

    printDropdown: SelectItem[] = [];
    tallyEntryDropdown: SelectItem[] = [];
    statusVisualizationNodes: StatusVisualizationNode[] = [];
    // invoicePrinted: any[] = [];
    invoicePrinted: Map<string, boolean> = new Map();
    invoicePrintedCheckboxVisibleStatus: any[] = [];
    // invoiceTallyEntered: any[] = [];
    invoiceTallyEntered: Map<string, boolean> = new Map();
    invoiceTallyEnteredCheckboxVisibleStatus: any[] = [];

    invoiceChartHeight: number;
    invoiceChartWidth: number;
    invoiceFilterDates: Date[] = [];

    // for printing options
    isDuplicate = false;
    isWithoutDetail = false; // for with/without detail switch
    isWithoutLetterHead = false; // for with/without letterhead switch
    safePdfUrl: SafeResourceUrl;
    safePdfName: string;
    currentDate = new Date();

    subscription: Subscription[] = [];
    isLoading = false;
    showXlsExport = false;
    isFileDownloading = false;
    xlsLoader: PPTLoader = new PPTLoader();

    searchEvent: GridPaginationEvent;

    selectedInvoiceFormat: any;
    preview = false; // for printing
    userRole: string;

    @ViewChild(InvoiceChartComponent) invoiceChartChildRef: InvoiceChartComponent;

    @ViewChild('invoiceCardRef') invoiceCardRef: ElementRef;

    @ViewChild('printedDropDown') printedDropDown: Dropdown;

    @ViewChild('tallyEnteredDropDown') tallyEnteredDropDown: Dropdown;

    @ViewChild('statusDropDown') statusDropDown: Dropdown;

    billColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        //----------Start Sanchit Mirg 18-04-2018---------------------
        /* {
            field: 'id',
            name: 'id',
            header: 'Invoice',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        }, */
        {
            field: 'campaign.campaignId',
            name: 'campaign.campaignId',
            header: 'Campaign Id',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign && data.campaign.campaignId) {
                    return data.campaign.campaignId;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'invoiceId',
            name: 'invoiceId',
            header: 'Invoice',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        //---------End Sanchit Mirg--------------------------
        // {
        //     field: 'campaign.customer.company',
        //     name: 'campaign.customer.company',
        //     header: 'Company',
        //     required: true,
        //     width: '7vw',
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     permanent: false,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        //     displayFn: (data) => {
        //         if (data.campaign.customer.company) {
        //             return data.campaign.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'campaign.customer.billingName',
            name: 'campaign.customer.billingName',
            header: 'Billing Name',
            required: true,
            width: '7vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.customer.billingName) {
                    return data.campaign.customer.billingName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaign.displayName',
            name: 'campaign.displayName',
            header: 'Display Name',
            required: true,
            width: '8vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.displayName) {
                    return data.campaign.displayName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaign.employee.fullName',
            name: 'employee.fullName',
            header: 'Employee',
            required: true,
            width: '7vw',
            editable: false,
            default: false,
            // hidden: !this.showEmployee,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.employee) {
                    return data.campaign.employee.fullName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billGeneratedDate',
            name: 'billGeneratedDate',
            header: 'Bill Generated Date',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billGeneratedDate) {
                    return DateUtil.dategridFormatter(data.billGeneratedDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billStartDate',
            name: 'billStartDate',
            header: 'Bill Start Date',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billStartDate) {
                    return DateUtil.dategridFormatter(data.billStartDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billEndDate',
            name: 'billEndDate',
            header: 'Bill End Date',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billEndDate) {
                    return DateUtil.dategridFormatter(data.billEndDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'status',
            name: 'status',
            header: 'Status',
            required: true,
            width: '12vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'min-width-10',
            type: EditableGridFieldTypes.CUSTOM,
            // align: Alignment.LEFT,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.status) {
                    return InvoiceStatus[data.status];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'rentAmount',
            name: 'rentAmount',
            header: 'Rent Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.rentAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.rentAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'printAmount',
            name: 'printAmount',
            header: 'Print Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.printAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.printAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'mountAmount',
            name: 'mountAmount',
            header: 'Mount Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.mountAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.mountAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'totalAmount',
            name: 'totalAmount',
            header: 'Bill Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.totalAmount !== null) {
                    return this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.totalAmount));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'lspDiff',
            name: 'lspDiff',
            header: 'LSP Difference',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.lspDiff !== null) {
                    return this.currencyPipe.transform(data.lspDiff);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaignDiff',
            name: 'campaignDiff',
            header: 'Campaign Difference',
            required: true,
            width: '7vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.campaignDiff !== null) {
                    return this.currencyPipe.transform(data.campaignDiff);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'printed',
            name: 'printed',
            header: 'Printed',
            required: true,
            width: '5vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.printed !== null && data.printed !== undefined) {
                    return data.printed;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'markAsPrinted',
            name: 'markAsPrinted',
            header: 'Mark Print',
            required: true,
            width: '5vw',
            editable: false,
            default: false,
            // hidden: false,
            // permanent: false,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
        },
        {
            field: 'printInvoice',
            name: 'printInvoice',
            header: 'Print',
            required: true,
            width: '4vw',
            editable: false,
            default: false,
            // hidden: false,
            // permanent: false,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
        },
        {
            field: 'tallyEntered',
            name: 'tallyEntered',
            header: 'Tally Entry',
            required: true,
            width: '5vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.tallyEntered !== null && data.tallyEntered !== undefined) {
                    return data.tallyEntered;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'markAsTallyEntered',
            name: 'markAsTallyEntered',
            header: 'Mark Tally',
            required: true,
            width: '5vw',
            editable: false,
            default: false,
            // hidden: false,
            // permanent: false,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
        },
        // commented for changes in tally invoice id for vendor - chitra
        // {
        //     field: 'tallyInvoiceId',
        //     name: 'tallyInvoice',
        //     header: 'Tally Invoice',
        //     required: true,
        //     width: '6vw',
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     permanent: false,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        //     displayFn: (data) => {
        //         if (data.tallyInvoiceId !== null && data.tallyInvoiceId !== undefined) {
        //             return data.tallyInvoiceId;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'billGeneratedDate',
        //     name: 'billGeneratedDate',
        //     header: 'Bill Generated Date',
        //     required: true,
        //     width: '6vw',
        //     editable: false,
        //     default: true,
        //     hidden: false,
        //     permanent: true,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     displayFn: (data) => {
        //         if (data.billGeneratedDate) {
        //             return DateUtil.dategridFormatter(data.billGeneratedDate);
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'approveDate',
            name: 'approveDate',
            header: 'Approve Date',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.approveDate) {
                    return DateUtil.dategridFormatter(data.approveDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders',
            name: 'purchaseOrders',
            header: 'PO',
            required: true,
            width: '4vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM
        },
        // {
        //   field: 'details',
        //   name: 'details',
        //   header: 'Details',
        //   required: true,
        //   width: '4vw',
        //   editable: false,
        //   default: true,
        //   hidden: false,
        //   permanent: true,
        //   sortable: false,
        //   type: EditableGridFieldTypes.CUSTOM
        // },
    ];

    @ViewChild('billSibGrid')
    billGridViewChildRef: GridComponent<Invoice>;

    @ViewChild('op')
    overlay: OverlayPanel;

    @ViewChild('printMenu')
    printOverlay: OverlayPanel;


    constructor(
        private invoiceService: InvoiceService,
        private firebaseService: FirebaseService,
        private currencyPipe: CurrencyHelperPipe,
        private userGridConfigService: UserGridConfigService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public billingUIService: BillingUIService,
        private notificationServcie: NotificatoinsService,
        private sanitizer: DomSanitizer,
        private loaderSubjects: LoaderSubjects,
        private commonService: CommonService,
        private customerUiService: CustomerUiService,
    ) { }

    ngOnInit() {
        this.currentDate = new Date();
        this.userRole = this.customerUiService.getUserRole();
        // this.showXlsExport = this.billingUIService.getBillingTabIndex() === '2' ? true : false;
        // this.showXlsExport = this.billingUIService.getBillingTabIndex() === '2' ? true : false;
        this.showXlsExport = this.billingUIService.getBillingTabIndex() === '1' ? true : false;
        this.setUserGridConfig();
        this.setFilterDropdowns();
        this.setFlows();
        this.setFormGroupObjects();
        this.setGridConfigObject();
        this.setRoles();
        this.setColumns();
        // this.setInvoiceChartCard();
    }

    setUserGridConfig() {
        this.userGridConfigService.create([GridNameEnum['INVOICE']])
            .subscribe((invoiceListGridConfig) => {
                this.billColumns = utils.getUserConfiguredColumns(invoiceListGridConfig[GridNameEnum['INVOICE']], this.billColumns);
                this.billGridViewChildRef.setAllColumns();
            });
    }

    setFormGroupObjects() {
        this.billGroup = new FormGroup({
            billSiteGridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.billGridConfig.getDefaultModel = () => new Invoice();
        this.billGridConfig.model = Invoice;
        this.billGridConfig.selectionMode = SelectionMode.Multi;
        this.billGridConfig.editable = true;
        this.billGridConfig.expandableRows = false;
        this.billGridConfig.checkboxSelection = false;
        this.billGridConfig.resizableColumns = false;
        // this.billGridConfig.rowExpandMode = 'single';
        this.billGridConfig.shouldAddRowOnDelete = false;
        this.billGridConfig.showColorCode = true;
        this.billGridConfig.lazy = true;
        this.billGridConfig.enablePagination = true;
        this.billGridConfig.scrollable = true;
        this.billGridConfig.showColorCode = true;
        this.billGridConfig.showRefreshIcon = true;


        this.billGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            // const paginationData: PaginationData = {
            //     sortField: 'billGeneratedDate',
            //     sortOrder: 1
            // }
            this.setPaginationEvent(paginationEvent);
            // this.billingUIService.getBillingTabIndex() === '1' ? AppUrls.PENDING_TO_GEN : null
            return this.invoiceService.create(paginationEvent, paginationEvent, null).pipe(
                map((response) => {
                    this.searchEvent = paginationEvent;
                    // paginationEvent.sortOrder = 1;
                    // = {
                    //     filters: paginationEvent.filters,
                    //     globalFilter: paginationEvent.globalFilter,
                    //     multiSortMetas: paginationEvent.multiSortMetas
                    // };
                    this.invoices = response['data']['content'];
                    this.rowsPerPage = response["data"].size;
                    this.invoicePrinted = new Map();
                    this.invoiceTallyEntered = new Map();
                    // Start - Divya 03-10-2019==================
                    this.setInvoice();
                    // this.setInvoicePrinted();
                    // this.setInvoiceTallyEntered();
                    // this.setCharAt();
                    // this.setPendingAt();
                    // End - Divya 03-10-2019===================
                    this.setStatusVisualizationNode();
                    this.invoiceTotalRecords = response['data'].totalElements;
                    // this.isInvoiceChartFilter = false;
                    // this.invoices.forEach((i) => (i.printed === 'T') ? this.invoicePrinted[i.id] = true : '');
                    return response['data']['content'];
                }));
        };
    }


    setInvoice() {
        this.pendingAt = [];
        this.charAt = [];
        this.charTitleAt = [];
        this.invoices.forEach((inv, index) => {
            this.setInvoicePrinted(inv);
            this.setInvoiceTallyEntered(inv);
            this.setPendingAt(inv, index);
            this.setCharAt(inv, index);
        });
    }

    setInvoicePrinted(inv) {
        // this.invoices.forEach((inv) => {
        // (InvoiceCharEnum[inv.printed] === InvoiceCharEnum.F || InvoiceCharEnum[inv.printed] === InvoiceCharEnum.N) ? this.invoicePrinted.push(false) : this.invoicePrinted.push(true);
        (InvoiceCharEnum[inv.printed] === InvoiceCharEnum.F || InvoiceCharEnum[inv.printed] === InvoiceCharEnum.N) ? this.invoicePrinted.set(inv.id, false) : this.invoicePrinted.set(inv.id, true);
        // });
    }

    setInvoiceTallyEntered(inv) {
        // this.invoices.forEach((inv) => {
        // (InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.F || InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.N) ? this.invoiceTallyEntered.push(false) : this.invoiceTallyEntered.push(true);
        (InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.F || InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.N) ? this.invoiceTallyEntered.set(inv.id, false) : this.invoiceTallyEntered.set(inv.id, true);
        // });
    }

    setRoles() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const roles = userInfo[0].app_metadata.authorization.roles;
        const permissions = userInfo[0].app_metadata.authorization.permissions;
        this.admin = (permissions.indexOf('approve:invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
        this.accountant = (permissions.indexOf('approve:account-invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
        this.salesRepresentative = (permissions.indexOf('request:edit-invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
    }

    setFlows() {
        // this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
        // this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
        // this.cancelFlowStatus = ['ACCOUNTANT_INVOICE_CANCEL', 'ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
        // this.srPendingStatus = ['ADMIN_GEN_REJECT', 'ADMIN_GEN_APPROVE', 'ACCOUNTANT_GEN_REJECT', 'BILL_GENERATED', 'EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ADMIN_EDIT_APPROVE', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
        // this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_INVOICE_CANCEL', 'ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION'];
        // this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];

        // ============================================= addditional status for completion of flow ====================================================================

        this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
        this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
        this.cancelFlowStatus = ['ADMIN_CANCEL_PENDING', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'ADMIN_FULL_CN_PENDING', 'PARTIAL_CN_APPLIED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
        this.srPendingStatus = ['EDIT_PENDING'];
        this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ADMIN_CANCEL_PENDING', 'ADMIN_FULL_CN_PENDING'];
        this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];
        this.flowCompleteWithApprovalStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_EDIT_APPROVE', 'BILL_GENERATED', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
        this.flowCompleteWithRejectionStatus = ['ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'FULL_CN_REJECTED'];
        this.invoicePrintedCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
        this.invoiceTallyEnteredCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
    }

    setFilterDropdowns() {
        this.setPrintDropdown();
        this.setTallyEntryDropdown();
        this.setInvoiceStatusDropdown();
    }

    setPrintDropdown() {
        this.printDropdown = [
            { label: "All", value: "ALL" },
            { label: 'True', value: true },
            { label: 'False', value: false },
        ];
    }

    onPrintedFilterChange(value, field, matchMode) {
        this.setFilterValues(value, field, matchMode);
    }

    setTallyEntryDropdown() {
        this.tallyEntryDropdown = [
            { label: "All", value: "ALL" },
            { label: 'True', value: true },
            { label: 'False', value: false },
        ];
    }

    onTallyEnteredFilterChange(value, field, matchMode) {
        this.setFilterValues(value, field, matchMode);
    }

    setFilterValues(value, field, matchMode) {
        this.customFilterApplied = true;
        switch (value) {
            case 'ALL': {
                value = ["ALL"];
                matchMode = 'in';
                break;
            }
            case true: {
                value = ['T'];
                matchMode = 'in';
                break;
            }
            case false: {
                value = ['N', 'F'];
                matchMode = 'in';
                break;
            }
        }
        this.setCustomFilter(value, field, matchMode);
    }

    setCustomFilter(value, field, matchMode) {
        switch (field) {
            case "printed": {
                this.customFilter = { "printed": { value: value, matchMode: matchMode } };
                break;
            }
            case "tallyEntered": {
                this.customFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
                break;
            }
            case "markAsPrinted": {
                this.customFilter = { "printed": { value: value, matchMode: matchMode } };
                break;
            }
            case "markAsTallyEntered": {
                this.customFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
            }
        }
        this.billGridViewChildRef.refresh();
    }

    setPaginationEvent(paginationEvent) {
        if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
            paginationEvent.sortField = 'billGeneratedDate';
        }

        if (this.customFilterApplied) {
            Object.assign(paginationEvent.sortOrder, paginationEvent.sortOrder);
            Object.assign(paginationEvent.filters, this.customFilter);
        }
        if (this.isInvoiceChartFilter) {
            paginationEvent = Object.assign(paginationEvent, paginationEvent);
            this.setInvoiceChartFilter(paginationEvent)
        } else {
            let startDate = new Date(Date.UTC(Number(this.financialYear.split('-')[0]), 3, 1, 0, 0, 0, 0));
            let endDate = new Date(Date.UTC(Number(this.financialYear.split('-')[1]), 2, 31, 0, 0, 0, 0));
            this.invoiceFilterDates = [startDate, endDate];
            var chartFilter = { "billGeneratedDate": { value: this.invoiceFilterDates, matchMode: 'dateBetween' } }
            Object.assign(paginationEvent.filters, chartFilter);
        }

        if (this.isFinancialYearChanged) {
            this.setInvoiceChartFilter(paginationEvent);
        }
        this.customFilterApplied = false;
    }

    setInvoiceChartFilter(paginationEvent) {
        if (this.isFinancialYearChanged) {
            paginationEvent.sortOrder = 1;
            paginationEvent.sortField = 'billGeneratedDate';
            let startDate = new Date(Date.UTC(Number(this.financialYear.split('-')[0]), 3, 1, 0, 0, 0, 0));
            let endDate = new Date(Date.UTC(Number(this.financialYear.split('-')[1]), 2, 31, 0, 0, 0, 0));
            this.invoiceFilterDates = [startDate, endDate];
        } else {
            paginationEvent.sortOrder = -1;
            paginationEvent.sortField = 'billGeneratedDate';
            this.invoiceFilterDates = DateUtil.getMonthStartAndEndDate(Number(MonthEnum[this.invoiceChartEvent[0]]), this.financialYear);
        }
        var sortOrder = paginationEvent.sortOrder;
        var chartFilter = { "billGeneratedDate": { value: this.invoiceFilterDates, matchMode: 'dateBetween' } }
        Object.assign(paginationEvent.filters, chartFilter);
        Object.assign(paginationEvent.sortOrder, sortOrder);
    }

    setInvoiceStatusDropdown() {
        this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatus, true);
        // if (this.billingUIService.getBillingTabIndex() === '1') {
        //     this.invoiceStatusDropdown = this.invoiceStatusDropdown.filter(item => item.label === "All" || item.label === InvoiceStatus.ADMIN_EDIT_PENDING || item.label === InvoiceStatus.ADMIN_GEN_PENDING);
        // }
        // this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatusEnum, true);
    }

    ngAfterViewInit() {

    }

    setColumns() {
        this.billColumns.filter((col) => {
            switch (col.field) {
                case 'campaign.employee.fullName': {
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
                case 'markAsPrinted': {
                    col.hidden = this.billingUIService.checkPermission('update:invoice-print') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }
                case 'markAsTallyEntered': {
                    col.hidden = this.billingUIService.checkPermission('update:tally-invoice') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }
                case 'printInvoice': {
                    col.hidden = this.billingUIService.checkPermission('print:invoice') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }
                case 'campaign.displayName': {
                    col.hidden = this.billingUIService.checkPermission('view:display-name') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }
                case 'status': {
                    // col.filter = this.billingUIService.getBillingTabIndex() === '1' ? null : { matchMode: 'is', placeHolder: 'Search', custom: true, };
                    col.filter = this.billingUIService.getBillingTabIndex() === '1' ? { matchMode: 'is', placeHolder: 'Search', custom: true, } : null;
                    break;
                }
            }
            return col;
        });
        if (this.billGridViewChildRef) {
            this.billGridViewChildRef.setAllColumns();
        }
    }

    viewCampaign(rowData) {
        window.open('/campaigns/view/' + rowData.campaign.id);
    }

    viewInvoiceDetails(rowData, event) {
        if (event.ctrlKey) {
            // do nothing
        } else {
            const id = rowData.id;
            const encId = encodeURIComponent(id);
            this.router.navigate(['billings/bills/view/' + encId]);
        }
    }

    getEncodedURI(id) {
        return encodeURIComponent(id);
    }

    viewPO(PO) {
        this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
            window.open(response);
        });
    }

    onMarkAsPrintedChange(row, event) {
        this.invoiceService.markInvoiceAsPrinted((event) ? 'T' : 'F', row.id).subscribe((response) => {
            event ? this.notificationServcie.success("Invoice Marked As Printed", "Invoice Marked As Printed Successfully") : this.notificationServcie.success("Invoice Unmarked As Printed", "Invoice Unmarked As Printed Successfully");
            this.billGridViewChildRef.refresh();
        });
    }

    checkPrintedStatus(status) {
        if (this.invoicePrintedCheckboxVisibleStatus.includes(status)) {
            return true;
        } else {
            return false;
        }
    }

    checkTallyEnteredStatus(status) {
        if (this.invoiceTallyEnteredCheckboxVisibleStatus.includes(status)) {
            return true;
        } else {
            return false;
        }
    }

    onMarkAsTallyEnteredChange(row, event) {
        this.invoiceService.markInvoiceAsTallyEntered((event) ? 'T' : 'F', row.id, row.id).subscribe((response) => {
            event ? this.notificationServcie.success("Invoice Marked As Tally Entered", "Invoice Marked As Tally Entered Successfully") : this.notificationServcie.success("Invoice Unmarked As Tally Entered", "Invoice Unmarked As Tally Entered Successfully");
            this.billGridViewChildRef.refresh();
        });
    }


    showOverlay($event, rowData, rowIndex) {
        this.rowData = rowData;
        this.rowIndex = rowIndex;
        this.overlay.show($event);
    }

    updatingUserGridConfig(columnArray) {
        let invoiceListGridConfig: Map<string, string> = new Map();
        invoiceListGridConfig = utils.getGridConfigMap(columnArray, this.billColumns);
        this.userGridConfigService.patch(utils.buildObject(invoiceListGridConfig), null, GridNameEnum['INVOICE'])
            .subscribe((result) => {
            });
    }

    setChar(status, index) {
        if (this.generateFlowStatus.includes(status)) {
            this.charAt[index] = "G";
            this.charTitleAt[index] = SIBConstants.STATUS_GEN_ICON_TOOLTIP;
        }
        if (this.editFlowStatus.includes(status)) {
            this.charAt[index] = "E";
            this.charTitleAt[index] = SIBConstants.STATUS_MOD_ICON_TOOLTIP;
        }
        if (this.cancelFlowStatus.includes(status)) {
            this.charAt[index] = "C";
            this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
        }
    }

    setPendingAt(inv, index) {
        // this.pendingAt = [];
        // this.invoices.forEach((invoice, index) => {
        this.setBackground(inv.status, index);
        // });
    }

    setCharAt(inv, index) {
        // this.charAt = [];
        // this.charTitleAt = [];
        // this.invoices.forEach((invoice, index) => {
        this.setChar(inv.status, index);
        // });
    }

    setBackground(status, index) {
        if (this.srPendingStatus.includes(status)) {
            // this.pendingAt[index] = "sr";
            this.pendingAt[index] = "SR";
        }
        if (this.adminPendingStatus.includes(status)) {
            // this.pendingAt[index] = "admin";
            this.pendingAt[index] = "ADM";
        }
        if (this.accountantPendingStatus.includes(status)) {
            // this.pendingAt[index] = "accountant";
            this.pendingAt[index] = "ACC";
        }

        // ====================== changes for addition of new visualization node of flow completion =====================================================

        // if (this.flowCompleteWithApprovalStatus.includes(status) || this.flowCompleteWithRejectionStatus.includes(status)) {
        //     this.pendingAt[index] = "CMP";
        // }
        if (this.flowCompleteWithApprovalStatus.includes(status)) {
            this.pendingAt[index] = "APP";
        }
        if (this.flowCompleteWithRejectionStatus.includes(status)) {
            this.pendingAt[index] = "REJ";
        }
    }

    getClassForCharacter(index) {
        switch (this.charAt[index]) {
            case 'G': {
                return "generate";
                break;
            }
            case 'E': {
                return "edit";
                break;
            }
            case 'C': {
                return "cancel";
                break;
            }
        }
    }

    setStatusVisualizationNode() {
        this.statusVisualizationNodes = [];
        this.statusVisualizationNodes = [
            { header: "SR", title: "Sales Representative" },
            // { header: "ACC", title: "Accountant" },
            { header: "ADM", title: "Admin" },
            { header: "CMP", title: " " }, // for APP or REJ
        ];
    }

    setActiveIndex(status) {
        if (status.includes('APPROVE') || status.includes('REJECT') || InvoiceStatus[status] === InvoiceStatus.EDIT_PENDING) {
            this.activeIndex = 0;
        } else if (status.includes("ADMIN")) {
            this.activeIndex = 1;
        } else if (status.includes('ACCOUNTANT')) {
            this.activeIndex = 2;
        }
    }

    togglePrintMenu(event, rowData, rowIndex) {
        this.rowData = rowData;
        this.selectedInvoiceFormat = this.rowData.invoiceFormat;
        this.rowIndex = rowIndex;
        this.printOverlay.toggle(event);
    }

    setSelectedInvoiceFormat(event) {
        this.selectedInvoiceFormat = event;
        this.rowData.invoiceFormat = event;
    }

    onPrintTypeChange(event) {
        this.rowData.printConfiguration.original = event;
    }

    onPrintLetterHeadChange(event) {
        this.rowData.printConfiguration.withLetterHead = event;
    }

    savePrintingInstructions(printConfig: PrintConfig) {
        this.rowData.printConfiguration = printConfig;
        this.printOverlay.hide();
        this.commonService.saveBillingPrintConfig(this.rowData.printConfiguration, this.selectedInvoiceFormat, this.rowData.id, SIBConstants.INVOICE_PARAM)
            .subscribe(
                (response) => {
                    if (response) {
                        this.notificationServcie.success('Configuration Saved Successfully', 'Configuration Saved');
                    }
                },
                (error) => {
                    this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                }
            );
    }

    previewPrintItems(printConfig: PrintConfig) {
        this.preview = true;
        this.printInvoice(printConfig);
    }

    printItems(printConfig: PrintConfig) {
        this.preview = false;
        this.printInvoice(printConfig);
    }

    printInvoice(printConfig: PrintConfig) {
        this.checkForAddBlock();
        if (this.userRole === 'Sales Rep') {
            printConfig.withLetterHead = true;
        }
        this.invoiceService.printInvoice(utils.createPrintWrapperObject(printConfig, this.selectedInvoiceFormat, this.preview, this.rowData.rowsOrder), this.rowData.id)
            .subscribe(
                (response) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                    }
                },
                // (error) => {
                //     this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Print Error");
                // }
                (error) => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                }
            );
    }

    checkForAddBlock() {
        if ($("#bottomAd").height() > 0) {
            // console.log("ad block inctive");
        } else {
            // console.log("ad block active");
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }
    }

    printingItem(isDuplicate, isWithoutDetail, isWithoutLetterHead) {
        this.printOverlay.hide();
        if (!isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithDetailsWithLetterhead(isDuplicate);
        } else if (!isWithoutDetail && isWithoutLetterHead) {
            this.printBillsWithDetailsWithoutLetterhead(isDuplicate);
        } else if (isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithoutDetailsWithLetterhead(isDuplicate);
        } else {
            this.printBillsWithoutDetailsWithoutLetterhead(isDuplicate);
        }
    }


    printBillsWithDetailsWithLetterhead(isDuplicate) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithDetailsWithLetterhead(this.rowData.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithDetailsWithoutLetterhead(isDuplicate) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithDetailsWithoutLetterhead(this.rowData.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithoutDetailsWithLetterhead(isDuplicate) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithoutDetailsWithLetterhead(this.rowData.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    printBillsWithoutDetailsWithoutLetterhead(isDuplicate) {
        this.isLoading = true;
        this.subscription.push(this.invoiceService.printInvoiceWithoutDetailsWithoutLetterhead(this.rowData.id, isDuplicate).subscribe((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
        }));
    }

    exportXlsx() {
        this.isFileDownloading = true;
        this.createLoaderObject("XLS", this.xlsLoader)
        this.loaderSubjects.invoiceXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.fileType = fileType;
        object.searchEvent = this.searchEvent;
    }


    // filterInvoiceStatus(filterValue: any, field: string, matchMode: string) {
    //     // matchMode = (filterValue instanceof String) ? 'is' : 'in';
    //     // this.billSibGrid.filter(filterValue, field, matchMode);
    //     this.billingUIService.filterInvoiceStatus(this.billSibGrid, filterValue, field, matchMode);
    // }

    /* Getting decimal point in Number datatype
*  @param  {} source
*/
    getDecimalNum(target, decimal) {
        // return Number(target.toFixed(decimal));

        return Number(Math.fround(target).toFixed(decimal));
    }

    // getHeight(obj, val: number) {
    //     return (obj.nativeElement.clientHeight - val);
    // }

    // getWidth(obj, val: number) {
    //     return (obj.nativeElement.clientWidth - val);
    //     // return (window.innerWidth - val)
    // }

    // setInvoiceChartCard() {
    //     this.invoiceChartHeight = this.getHeight(this.invoiceCardRef, 50);
    //     this.invoiceChartWidth = this.getWidth(this.invoiceCardRef, 50)
    // }

    parseValueToDecimal(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    getAbsoluteValue(value) {
        return Number(Math.abs(Number(value)));
    }

    // refreshChart(event) {
    //     if (event) {
    //         this.invoiceChartChildRef.refreshChart(this.getHeight(this.invoiceCardRef, 0), this.getWidth(this.invoiceCardRef, 60))
    //     }
    // }

    // chart(event) {
    //     this.invoiceFilterDates = DateUtil.getMonthStartAndEndDate(Number(MonthEnum[event[0]]));
    //     this.isInvoiceChartFilter = true;
    //     this.billGridViewChildRef.refresh();
    // }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // this.calculateTopHoardingsChartSize();
        // this.invoiceChartChildRef.refreshChart(this.getHeight(this.invoiceCardRef, 0), this.getWidth(this.invoiceCardRef, 60));

    }

    resetStatus() {
        utils.resetDropDown(this.statusDropDown);
        utils.resetDropDown(this.printedDropDown);
        utils.resetDropDown(this.tallyEnteredDropDown);

    }

    uploadTally() {
        let searchEvent = this.searchEvent;
        // searchEvent.sortField = "billGeneratedDate";
        // searchEvent.sortOrder = -1;
        Object.assign(searchEvent.filters, { 'tallyEntered': { value: ["F"], matchMode: 'in' } });
        this.invoiceService.uploadTally(searchEvent).subscribe(() => {
            this.notificationServcie.success(NotificationsMessages.TALLY_UPLOADED_SUCCESSFULLY, NotificationsMessages.TALLY_UPLOADED)
        },
            (error) => {
                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
            });
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

}
