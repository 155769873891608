import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { GridColumn, EditableGridFieldTypes, GridPaginationEvent, GridConfig, SelectionMode } from '../../components/sib-forms/grid/grid.config';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { InventoryTypeEnum } from '../../shared/constants/inventory-type.enum';
import { InventoryExpenses } from '../../modals/hoardings/inventory-expenses';
import { map } from 'rxjs/operators';
import { InventoryExpenseService } from '../../services/shared/inventory-expenses.service';
import { AppUrls } from '../../services/urls';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { MediaTypeEnum } from '../../shared/constants/media-type-enum';
import * as $ from 'jquery';
import { ExpenseEnum } from '../../shared/constants/expense-enum';
import * as _ from "lodash";
import { ExpenseTypeEnum } from '../../shared/constants/expense-type.enum';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import * as utils from '../../helpers/utils';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';

@Component({
  selector: 'sib-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpenseReportComponent implements OnInit {

  gridColumnConfig: Array<GridColumn>;
  lightTypeDropdown: any[] = [];
  inventoryTypes: any[] = [];
  mediaTypeDropdown: any[] = [];
  gridConfig: GridConfig<any> = new GridConfig<any>();
  totalInventoryRecords: number;
  startDate: Date;
  endDate: Date;
  rowData: any;
  title: string = 'Expenses Report';
  expenseBreakupTitle: string = 'Expense Breakup'
  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<any>;
  selectedExpenses: any[] = [];
  expenses: any[] = [];
  totalAmount: number = 0;

  showBreakups: boolean = false;
  isFileDownloading: boolean = false;
  searchEvent: GridPaginationEvent;
  xlsLoader: PPTLoader = new PPTLoader();
  resetXlsLoader: PPTLoader = new PPTLoader();
  exportLevel2Xlsx: boolean = false;
  calendarStartDate: Date;
  calendarEndDate: Date;
  startDateMonth: number;
  startDateYear: number;
  endDateMonth: number;
  endDateYear: number;
  initialStartDate: boolean = true;
  initialEndDate: boolean = true;

  constructor(
    private inventoryExpensesService: InventoryExpenseService,
    private loaderSubjects: LoaderSubjects,
    private pageTitle: Title
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.EXPENSE_REPORT) : this.pageTitle.setTitle(SIBConstants.EXPENSE_REPORT + ' - ' + appTitle);
    this.gridColumnConfig = this.getGridColumnConfig();
    this.mediaTypeDropdown.push({ label: "All", value: "ALL" })
    Object.keys(MediaTypeEnum).forEach((type) => {
      this.mediaTypeDropdown.push({ label: MediaTypeEnum[type], value: type });
    });

    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
    });


    Object.keys(ExpenseTypeEnum).forEach((expense) => {
      this.expenses.push({ label: this.getExpenses(expense), value: expense });
    });
    this.setGridConfigObject();
    // this.getTotalExpenseAmount();
  }


  /**
   * @description gets the grid column config
   * @author Raveena Nathani
   * @date 2020-04-08
   * @returns
   * @memberof ExpenseReportComponent
   */
  getGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: 'srNo',
        name: 'srNo',
        header: 'Sr ',
        required: true,
        width: '35px',
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        sortable: false,
        styleClass: 'sr-number',
        type: EditableGridFieldTypes.CUSTOM
      },
      {
        field: 'customId',
        name: 'customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'contractId',
        name: 'contractId',
        header: 'CID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.contractId) {
            return data.contractId;
          } else {
            return '-';
          }
        },
      },
      {
        field: 'district',
        name: 'district',
        header: 'District',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'city',
        name: 'city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'areaName',
        name: 'area',
        header: 'Area',
        required: true,
        width: '9vw',
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'location',
        name: 'location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '16vw',
        default: true,
        sortable: true,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'size',
        name: 'size',
        header: 'Size',
        width: '4vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'mediaType',
        name: 'mediaType',
        header: 'Media',
        width: '5vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'is',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.mediaType) {
            return MediaTypeEnum[data.mediaType];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'lightType',
        name: 'lightType',
        header: 'Light',
        width: '4vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'is',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'totalAmount',
        name: 'totalAmount',
        header: 'Total Amount',
        width: '6vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.totalAmount) {
            return data.totalAmount;
          } else {
            return '-';
          }
        },
      },
    ]
    return columns;
  }


  /**
   * @description sets grid config object
   * @author Raveena Nathani
   * @date 2020-04-08
   * @memberof ExpenseReportComponent
   */
  setGridConfigObject() {
    this.setGridConfig();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      paginationEvent.sortField = 'customId'
      this.setStartAndEndDate(paginationEvent);
      return this.inventoryExpensesService.create(paginationEvent, paginationEvent, AppUrls.GET_REPORT).pipe(
        map((response) => {
          this.totalInventoryRecords = response['data'].totalElements;
          return response['data']['content'];
        }));
    }
  }


  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }



  /**
   * @description sets start date and end end date in filters of pagination event
   * @author Raveena Nathani
   * @date 2020-04-08
   * @memberof ExpenseReportComponent
   */
  setStartAndEndDate(paginationEvent) {
    this.endDate = new Date();
    this.startDate = new Date();
    if (this.initialStartDate) {
      this.startDate.setFullYear(this.startDate.getFullYear() - 1);
      this.startDate.setMonth(this.startDate.getMonth() + 1);
      this.startDate.setDate(1);
    } else {
      this.startDate.setFullYear(this.startDateYear);
      this.startDate.setMonth(this.startDateMonth - 1);
      this.startDate.setDate(1);
    }


    if (this.initialEndDate) {
      this.endDate.setDate(this.getLastDayOfMonth(this.endDate.getFullYear(), this.endDate.getMonth()));
    } else {
      this.endDate.setFullYear(this.endDateYear);
      this.endDate.setMonth(this.endDateMonth - 1);
      this.endDate.setDate(this.getLastDayOfMonth(this.endDateYear, this.endDateMonth - 1));

    }

    this.calendarStartDate = new Date(this.startDate);
    this.calendarEndDate = new Date(this.endDate);
    // return { 'mediaType': { value: this.mediaType, matchMode: "is" } };
    Object.assign(paginationEvent.filters, { 'startDate': { value: this.startDate, matchMode: 'gt' } });
    Object.assign(paginationEvent.filters, { 'endDate': { value: this.endDate, matchMode: 'lt' } })
    this.searchEvent = paginationEvent;
  }

  getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }
  /**
   * @description sets grid config
   * @author Raveena Nathani
   * @date 2020-04-08
   * @memberof ExpenseReportComponent
   */
  setGridConfig() {
    this.gridConfig.getDefaultModel = () => new InventoryExpenses();
    this.gridConfig.model = InventoryExpenses;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
  }

  onDateSelection(event) {

  }

  showExpenseBreakup(rowData) {
    this.rowData = rowData;
    this.showBreakups = true;
  }

  goback() {
    this.showBreakups = false;
  }


  removeExpense(i) {
    this.selectedExpenses.splice(i, 1);
    this.selectedExpenses = _.cloneDeep(this.selectedExpenses);
  }

  getExpenses(expense) {
    let expenseType = ExpenseTypeEnum[expense]
    expenseType = expense.substring(0, expenseType.indexOf("Expense") - 1);
    expenseType = expenseType.charAt(0).toUpperCase() + expenseType.slice(1).toLowerCase();
    return expenseType;
  }

  exportXlsLevel1() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader);
    this.loaderSubjects.expenseLevel1Loader.next(this.xlsLoader);
    this.isFileDownloading = false;
  }

  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
  }
  exportXlsLevel2() {
    this.exportLevel2Xlsx = true;
  }

  onStartDateMonthOrYearChange(event) {
    this.initialStartDate = false;
    this.startDateMonth = event.month;
    this.startDateYear = event.year;
    this.refreshGrid();
  }

  onEndDateMonthOrYearChange(event) {
    this.initialEndDate = false;
    this.endDateMonth = event.month;
    this.endDateYear = event.year;
    this.refreshGrid();
  }


  closeCalendar() {
    this.refreshGrid();
  }

}
