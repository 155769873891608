import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AdvancedSearch } from "../../../modals/hoardings/advanced-search";
import { GridConfig } from "../../../components/sib-forms/grid/grid.config";
import { Inventory } from "../../../modals/hoardings/inventory";
import { PlanTemplate } from "../../../modals/plans/planTemplate";
import { InventoryGridComponent } from "../inventory-grid/inventory-grid.component";

@Component({
  selector: "sib-common-inventory-grid",
  templateUrl: "./common-inventory-grid.component.html",
  styleUrls: ["./common-inventory-grid.component.css"],
})
export class CommonInventoryGridComponent implements OnInit {
  @ViewChild("sibParentGrid") gridParentViewRef: InventoryGridComponent;
  @ViewChild("sibChildrenGrid") gridChildViewRef: InventoryGridComponent;

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
  }
  get isExpired() {
    return this._isExpired;
  }

  _mediaType: string;
  @Input() set mediaType(mediaType) {
    this._mediaType = mediaType;
  }
  get mediaType() {
    return this._mediaType;
  }

  _gridTitle: string;
  @Input() set gridTitle(gridTitle) {
    this._gridTitle = gridTitle;
  }
  get gridTitle() {
    return this._gridTitle;
  }

  _tradedInventory: boolean;
  @Input() set tradedInventory(tradedInventory) {
    this._tradedInventory = tradedInventory;
  }
  get tradedInventory() {
    return this._tradedInventory;
  }

  @Input() totalRecords: number;
  @Input() gridConfig: GridConfig<Inventory> = new GridConfig();
  @Input() columns: any[];
  @Input() showFilter: boolean;
  @Input() source: string;
  @Input() showChildren: boolean;
  @Input() showGroups: boolean;
  @Input() sidebarValues: any[];
  @Input() contractId: string;
  @Input() items: any[];
  @Input() siteTemplate: PlanTemplate;
  @Input() planId: string;
  @Input() searchPlanTemplate: boolean;
  @Input() showColumns: boolean = true;
  @Input() hideCommonInventoryDialog: boolean = false;
  @Input() selectedStatus: any;

  @Input() exposed: boolean = false;
  @Input() advanceSearch: AdvancedSearch;

  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitEditInventory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitShowGroups: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eEmitInventory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitInventoryCount: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckInventory: EventEmitter<{
    rowData;
    rowIndex;
  }> = new EventEmitter();
  @Output() eEmitViewHistory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImageUrl: EventEmitter<any> = new EventEmitter();

  selectedChildInventory: any[] = [];
  selectedParentInventory: any[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  /**
   * @description view images of Inventory
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  viewImages(event) {
    this.eEmitViewImage.emit(event);
  }

  /**
   * @description view statusqueue of Inventory
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  viewDetail(event) {
    this.eEmitViewDetail.emit(event);
  }

  /**
   * @description edit Inventory
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  editInventory(event) {
    this.eEmitEditInventory.emit(event);
  }

  /**
   * @description show Inventory groups
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  showInventoryGroups(event) {
    this.eEmitShowGroups.emit(event);
  }

  /**
   * @description refresh grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonInventoryGridComponent
   */
  refreshGrid() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.refreshGrid();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.refreshGrid();
    }
  }

  refresh() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.refreshFormTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.refreshFormTable();
    }
  }

  /**
   * @description update user grid column config
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} selectedColumns
   * @memberof CommonInventoryGridComponent
   */
  updatingUserGridConfig(selectedColumns) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.updatingUserGridConfig(selectedColumns);
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.updatingUserGridConfig(selectedColumns);
    }
  }

  /**
   * @description set columns
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonInventoryGridComponent
   */
  setColumns() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.setColumns();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.setColumns();
    }
  }

  /**
   * @description on child Inventory selection
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  onChildRowSelection(event) {
    this.selectedChildInventory = event;
    this.eRowSelection.emit([
      ...this.selectedParentInventory,
      ...this.selectedChildInventory,
    ]);
  }

  /**
   * @description on parent Inventory selection
   * @author Divya Sachan
   * @date 2020-01-29
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  onParentRowSelection(event) {
    this.selectedParentInventory = event;
    this.eRowSelection.emit([
      ...this.selectedParentInventory,
      ...this.selectedChildInventory,
    ]);
  }

  // /**
  //  * @description on row selection
  //  * @author Divya Sachan
  //  * @date 2020-01-27
  //  * @param {*} event
  //  * @memberof CommonInventoryGridComponent
  //  */
  // onRowSelection(event) {
  //   this.selectedPillars = [...this.selectedPillars, ...event];
  //   let pillars = Array.from(new Set(this.selectedPillars.map(item => item.id)));
  //   let selectedPillars = [];
  //   pillars.forEach(pillarId => {
  //     selectedPillars.push(this.selectedPillars.find(item => item.id === pillarId));
  //   });
  //   this.selectedPillars = selectedPillars;
  //   this.eRowSelection.emit(this.selectedPillars);
  // }

  /**
   * @description push value to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} value
   * @memberof CommonInventoryGridComponent
   */
  pushData(data, value) {
    if (this.gridChildViewRef && !value.hasChild) {
      this.gridChildViewRef.pushData(data, value);
    }
    if (this.gridParentViewRef && value.hasChild) {
      this.gridParentViewRef.pushData(data, value);
    }
    this.detectChanges();
  }

  /**
   * @description push values to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} values
   * @memberof CommonInventoryGridComponent
   */
  pushMultipleValues(data, values) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.pushMultipleValues(data, this.getChildren(values));
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.pushMultipleValues(data, this.getParent(values));
    }
  }

  /**
   * @description filter data
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} totalRecords
   * @param {*} values
   * @param {*} comparator
   * @memberof CommonInventoryGridComponent
   */
  filterData(totalRecords, values, comparator) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.filterData(
        totalRecords,
        this.getChildren(values),
        comparator
      );
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.filterData(
        totalRecords,
        this.getParent(values),
        comparator
      );
    }
  }

  /**
   * @description reset selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonInventoryGridComponent
   */
  resetSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.selected = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.selected = [];
    }
  }

  /**
   * @description reset previously selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonInventoryGridComponent
   */
  resetPreviousSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.previousSeleceted = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.previousSeleceted = [];
    }
  }

  /**
   * @description reset datatable
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonInventoryGridComponent
   */
  resetDataTable() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.resetDataTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.resetDataTable();
    }
  }

  setSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.setSelectedRows();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.setSelectedRows();
    }
  }

  /**
   * @description reset selected row
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} rowData
   * @memberof CommonInventoryGridComponent
   */
  resetSelectedRow(rowData) {
    if (rowData.hasChild) {
      this.selectedParentInventory = this.selectedParentInventory.filter(
        (item) => item.id !== rowData.id
      );
    }
    if (!rowData.hasChild) {
      this.selectedChildInventory = this.selectedChildInventory.filter(
        (item) => item.id !== rowData.id
      );
    }
    this.setSelectedRows();
  }

  /**
   * @description get parent's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonInventoryGridComponent
   */
  getParent(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === true)
      : [];
  }

  /**
   * @description get children's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonInventoryGridComponent
   */
  getChildren(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === false)
      : [];
  }

  /**
   * @description emit inventory data
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  inventoryData(event) {
    this.eEmitInventory.emit(event);
  }

  /**
   * @description set inventory grid config
   * @author Divya Sachan
   * @date 2020-01-28
   * @memberof CommonInventoryGridComponent
   */
  setGridConfigObject() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.setGridConfigObject();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.setGridConfigObject();
    }
  }

  /**
   * @description get total inventories and number of inventories on current page
   * @author Divya Sachan
   * @date 2020-02-04
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  getInventoryCount(event) {
    this.eEmitInventoryCount.emit(event);
  }

  /**
   * @description check Inventory when adding from campaign
   * @author Divya Sachan
   * @date 2020-02-10
   * @param {*} event
   * @memberof CommonInventoryGridComponent
   */
  checkInventory(event) {
    this.eEmitCheckInventory.emit(event);
  }

  viewHistory(event) {
    this.eEmitViewHistory.emit(event);
  }

  viewImageOnHover(event) {
    this.eEmitImageUrl.emit(event);
  }

  detectChanges() {
    if (!this.cd["destroyed"]) {
      this.cd.detectChanges();
    }
  }
}
