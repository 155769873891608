<div class="uploadPODialog">
    <p-dialog header="Upload Document(s)" [(visible)]="displayUploadPODialog" [modal]="true" showEffect="fade"
        (onHide)="hideUploadPODialog()" [closable]="!disableSave" [closeOnEscape]="false" [width]="1100"
        (onShow)="showUploadPODialog()" class="dialogImageUpload">
        <div *ngIf="isLoading">
            <div class="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </div>
        <div>

            <p-fileUpload #fileRef mode="basic" [chooseLabel]="'ADD DOCUMENT'" [maxFileSize]="1000000" name="demo[]"
                accept=".pdf,.doc,.docx,.jpeg,.jpg,.xlsx,.xls,.csv,.txt" (onSelect)="onFileSelection($event)"
                pTooltip="{{files.length}} file(s) selected" title=" " [auto]="true"></p-fileUpload>
            <form #uploadPoForm="ngForm" autocomplete="off">
                <div class="padding-10 margin-top-10 ui-g-12 card bg-color outer-card-vertical-scroller">
                    <div class="unorderedList ui-g padding-5 ui-g-4"
                        *ngFor="let po of purchaseOrdersWrapper; let i=index">
                        <div class="ui-g-11 card inner-card-vertical-scroller">

                            <div class="ui-g padding-0">
                                <div class="ui-g-11 word-break-wrap padding-0 po-name">
                                    {{po.purchaseOrder.name}}
                                </div>
                                <div class="ui-g-1 padding-0">
                                    <i class="fa fa-trash-o info-red" title="Remove the file" aria-hidden="true"
                                        (click)="removeFile(i)"></i>
                                </div>
                            </div>

                            <div class="ui-g padding-top-10">
                                <div class="ui-g-9 setInput padding-left-0">
                                    <span class="md-inputfield">
                                        <input #name="ngModel" id="name" type="text" trim="blur"
                                            [(ngModel)]="po.newName" [disabled]="disableSave" autocomplete="off"
                                            pInputText name="newName{{i}}" title=" " />
                                        <label for="newName">New Name</label>
                                    </span>
                                </div>
                                <div class="ui-g-3 padding-0">
                                    <p-checkbox name="po{{i}}" binary="true" label="IS PO?" inputId="po{{i}}"
                                        (onChange)="onCheckboxSelectionChange($event, i)"
                                        [(ngModel)]="po.purchaseOrder.po"></p-checkbox>
                                </div>
                            </div>
                            <div class="ui-g padding-top-10">
                                <div class="ui-g-6 setInput padding-left-0">
                                    <span class="md-inputfield">
                                        <input #poNumber="ngModel" id="poNumber" type="text" trim="blur"
                                            [(ngModel)]="po.purchaseOrder.poNumber" [disabled]="!po.purchaseOrder.po"
                                            pInputText name="poNumber{{i}}" maxlength="50" title=" " required />
                                        <label for="poNumber">PO Number
                                            <span class="required">*</span>
                                        </label>
                                    </span>
                                </div>
                                <div class="ui-g-6">
                                    <span class="ui-float-label">
                                        <p-calendar [inputStyle]="{'width':'90%'}" #poDate="ngModel" id="poDate"
                                            name="poDate{{i}}" [disabled]="!po.purchaseOrder.po" showIcon="true"
                                            [utc]="true" [(ngModel)]="po.purchaseOrder.poDate" dateFormat="ddMy"
                                            trim="blur" monthNavigator="true" yearNavigator="true"
                                            [yearRange]="'2017:2050'" required appendTo="body"></p-calendar>
                                        <label for="endDate">PO Date
                                            <span class="required">*</span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div class="ui-g padding-top-10">
                                <div class="ui-g-12 padding-left-0">
                                    <p-progressBar [value]="files[i].progress"></p-progressBar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="ctext-center padding-10">
            <button class="mtab-primary margin-hori-5 " type="button" pButton label=" UPLOAD ALL "
                (click)="uploadAllPO()"
                [disabled]="!uploadPoForm.form.valid || purchaseOrdersWrapper.length === 0"></button>
            <button class="mtab-save" type="button" pButton label=" SAVE " (click)="saveItems()"
                [disabled]="!uploadPoForm.form.valid || purchaseOrdersWrapper.length === 0 || disableSave"></button>
        </div>

    </p-dialog>
</div>