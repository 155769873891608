
import { map } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, Component, ViewEncapsulation, OnInit, Input, ViewChild } from "@angular/core";
import { GridColumn, GridConfig, SelectionMode, GridPaginationEvent, EditableGridFieldTypes, Alignment } from "../../components/sib-forms/grid/grid.config";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { SalesJournal } from "../../modals/billings/sales-journal";
import { FormGroup, FormControl } from "@angular/forms";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import { CampaignStatusEnum } from "../../shared/constants/campaign-status-enum";
import { SuperBillsService } from "../services/superbill.service";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { Campaign } from "../../modals/campaigns/campaign";
import { DateUtil } from '../../helpers/date.util';
import { UserGridConfigService } from "../../services/shared/user-grid-config.service";
import { GridNameEnum } from "../../shared/constants/grid-name.enum";
import * as utils from '../../helpers/utils';
import { Router, ActivatedRoute } from "@angular/router";
import { SuperBillStatusEnum } from '../../shared/constants/superbill-status-enum';
import { AppUrls } from '../../services/urls';
import { ElementRef } from '@angular/core';
import { SuperbillChartComponent } from '../../billings/superbill-chart/superbill-chart.component';
import { HostListener } from '@angular/core';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { CampaignService } from '../../services/shared/campaign.service';
import { ConfirmationService, Dropdown } from 'primeng/primeng';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { BillingUIService } from '../services/billingUI.service';
import { of as observableOf } from 'rxjs';

@Component({
    selector: 'sib-superbill-campaigns',
    templateUrl: './superbill-campaigns.component.html',
    styleUrls: ['./superbill-campaigns.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuperbillCampaignsComponent implements OnInit, AfterViewInit, OnDestroy {

    _showEmployee: boolean;
    @Input() set showEmployee(showEmployee) {
        this._showEmployee = showEmployee;
        this.setColumns();
    }

    get showEmployee() {
        return this._showEmployee;
    }

    @Input() userEmailId: string;

    // @Input() isFinancialYearChanged: boolean = false;

    // _financialYear: any[];
    // @Input() set financialYear(financialYear) {
    //     this._financialYear = financialYear;
    //     this.preBillGridViewChildRef.refresh();
    // }

    // get financialYear() {
    //     return this._financialYear;
    // }

    isLoading = false;
    buttonConfig: GridActionButtonConfig;
    gridConfig: GridConfig<SalesJournal> = new GridConfig<SalesJournal>();
    totalRecords = 0;

    preBillGroup: FormGroup;

    campaignStatusDropdown: any[] = [];
    superBillStatusDropdown: any[] = [];

    rowData: any;
    rowIndex: number;

    campaigns: Campaign[] = [];

    admin = false;
    accountant = false;
    salesRepresentative = false;

    showXlsExport = false;
    isFileDownloading = false;
    xlsLoader: PPTLoader = new PPTLoader();
    searchEvent: GridPaginationEvent;

    @ViewChild('superbillCardRef') superbillCardRef: ElementRef;
    // SuperbillChartComponent
    @ViewChild(SuperbillChartComponent) superbillChartChildRef: SuperbillChartComponent;

    @ViewChild('statusDropDown') statusDropDown: Dropdown;

    @ViewChild('statusOpenClose') statusOpenClose: Dropdown;

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'campaignId',
            name: 'campaignId',
            header: 'Campaign Id',
            required: true,
            width: '6.5vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaignId) {
                    return data.campaignId;
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'customer.company',
        //     name: 'company',
        //     header: 'Company',
        //     required: true,
        //     width: '8vw',
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     permanent: false,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        //     displayFn: (data) => {
        //         if (data.customer) {
        //             return data.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'customer.billingName',
            name: 'customer.billingName',
            header: 'Billing Name',
            required: true,
            width: '8vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.customer) {
                    return data.customer.billingName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            width: '8vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'employee.fullName',
            name: 'employee.fullName',
            header: 'Employee',
            required: true,
            width: '6.5vw',
            editable: false,
            default: false,
            // hidden: !this.showEmployee,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.employee) {
                    return data.employee.fullName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaignStartDate',
            name: 'campaignStartDate',
            header: 'Start Date',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.campaignStartDate) {
                    return DateUtil.dategridFormatter(data.campaignStartDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaignEndDate',
            name: 'campaignEndDate',
            header: 'End Date',
            required: true,
            width: '5.7vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.campaignEndDate) {
                    return DateUtil.dategridFormatter(data.campaignEndDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'status',
            name: 'status',
            header: 'Campaign Status',
            required: true,
            width: '6.5vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.status) {
                    return CampaignStatusEnum[data.status];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'superBillStatus',
            name: 'superBillStatus',
            header: 'Status',
            required: true,
            width: '5.5vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.superBillStatus) {
                    return SuperBillStatusEnum[data.superBillStatus];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'summary.lspDiffAmt',
            name: 'summary.lspDiffAmt',
            header: 'LSP Diff',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.summary.lspDiffAmt) {
                    // return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.lspDiffAmt));
                    return (this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.summary.lspDiffAmt)) + '(' + this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.summary.lspDiffPctg)) + ')');
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billingAmount',
            name: 'billAmount',
            header: 'Billed Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.billingAmount !== null && data.summary.grandTotal !== null) {
                    const billAmount = Number(data.billingAmount).toFixed(2);
                    // const totalAmount = Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2);
                    // const totalAmount = Number(data.summary.grandTotal).toFixed(2);
                    const totalAmount = Number(data.eligibleBillingAmount * 1.18).toFixed(2);
                    if (Number(totalAmount) !== 0) {
                        return this.currencyPipe.transform(billAmount) + ' (' + this.currencyPipe.transform(Number(Number(billAmount) * 100 / Number(totalAmount)).toFixed(2)) + '%)';
                    } else {
                        return this.currencyPipe.transform(billAmount) + '(0%)';
                    }
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'superbillUnbilledAmount',
            name: 'superbillUnbilledAmount',
            header: 'Unbilled Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.eligibleBillingAmount || data.billingAmount) {
                    // superbill + campDiff = billed + unbilled
                    const eligibleBillingAmount = Number(data.eligibleBillingAmount * 1.18).toFixed(2);
                    const billedAmount = Number(data.billingAmount).toFixed(2);
                    let unbilledAmount = Number(Number(eligibleBillingAmount) - Number(billedAmount) + (Number(data.campaignDifference) * 1.18)).toFixed(2);

                    // unbilled amount should be 0 if user has created invoice for all of the items for all days.
                    let integerPartOfUnbilledAmount = 0;
                    Number(unbilledAmount) >= 0 ? integerPartOfUnbilledAmount = Number(unbilledAmount.split('.')[0]) : integerPartOfUnbilledAmount = Number(unbilledAmount.split('.')[0]) * (-1);
                    integerPartOfUnbilledAmount === 0 ? unbilledAmount = '0' : unbilledAmount = unbilledAmount;

                    return (Number(eligibleBillingAmount) > 0) ? this.currencyPipe.transform(unbilledAmount) + ' (' + this.currencyPipe.transform(Number(Number(unbilledAmount) * 100 / Number(eligibleBillingAmount)).toFixed(2)) + '%)' : this.currencyPipe.transform(unbilledAmount) + ' (0%)';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaignDifference',
            name: 'campaignDifference',
            header: 'Campaign Difference',
            required: true,
            width: '6.5vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            // displayFn: (data) => {
            //     if (data.unbilledAmount !== null && data.summary.grandTotal !== null) {
            //         const unbillAmount = Number(data.unbilledAmount).toFixed(2);
            //         const totalAmount = Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2);
            //         return this.currencyPipe.transform(unbillAmount) + ' (' + this.currencyPipe.transform(Number(Number(unbillAmount) * 100 / Number(totalAmount)).toFixed(2)) + '%)';
            //         const unbilledAmountWithTax = Number(Number(unbillAmount) * 1.18).toFixed(2);
            //         // const totalAmount = Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2);
            //         const totalAmount = Number(data.summary.grandTotal).toFixed(2);
            //         return this.currencyPipe.transform(unbilledAmountWithTax) + '(' + this.currencyPipe.transform(Number(Number(unbilledAmountWithTax) * 100 / Number(totalAmount)).toFixed(2)) + '%)';
            //     } else {
            //         return '-';
            //     }
            // },
            displayFn: (data) => {
                // if (data.unbilledAmount !== null && data.summary.grandTotal !== null) {
                //     const unbillAmount = Number(data.unbilledAmount).toFixed(2);
                //     const unbilledAmountWithTax = Number(Number(unbillAmount) * 1.18).toFixed(2);
                //     // const totalAmount = Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2);
                //     // const totalAmount = Number(data.summary.grandTotal).toFixed(2);
                //     // return this.currencyPipe.transform(unbilledAmountWithTax) + ' (' + this.currencyPipe.transform(Number(Number(unbilledAmountWithTax) * 100 / Number(totalAmount)).toFixed(2)) + '%)';
                //     // if (Number(totalAmount) > 0) {
                //     // return this.currencyPipe.transform(unbilledAmountWithTax) + ' (' + this.currencyPipe.transform(Number(Number(unbilledAmountWithTax) * 100 / Number(totalAmount)).toFixed(2)) + '%)';
                //     // } else {
                //     // return this.currencyPipe.transform(unbilledAmountWithTax) + '(0%)';
                //     // }
                //     return this.currencyPipe.transform(unbilledAmountWithTax);
                // } else {
                //     return '-';
                // }
                if (data && data.campaignDifference) {
                    return this.currencyPipe.transform((data.campaignDifference * 1.18).toFixed(2));
                } else {
                    return '0';
                }
            },
        },
        // {
        //     field: 'difference',
        //     name: 'difference',
        //     header: 'Difference',
        //     required: true,
        //     width: '6vw',
        //     editable: false,
        //     default: true,
        //     hidden: false,
        //     permanent: true,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.CUSTOM,
        //     filter: {
        //         matchMode: 'relational',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        // },
        {
            field: 'eligibleBillingAmountWithTAX',
            name: 'eligibleBillingAmount',
            header: 'Total Superbill Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.eligibleBillingAmount) {
                    // return this.currencyPipe.transform(Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2));
                    return this.currencyPipe.transform(Number(data.eligibleBillingAmount * 1.18).toFixed(2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'eligibleBillingAmount',
            name: 'eligibleBillingAmount',
            header: 'Total Superbill Amt w/o Tax',
            required: true,
            width: '8vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.eligibleBillingAmount) {
                    // return this.currencyPipe.transform(Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2));
                    return this.currencyPipe.transform(Number(data.eligibleBillingAmount).toFixed(2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'summary.grandTotal',
            name: 'totalAmount',
            header: 'Total Campaign Amount',
            required: true,
            width: '7vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.summary.grandTotal >= 0) {
                    // return this.currencyPipe.transform(Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2));
                    return this.currencyPipe.transform(Number(data.summary.grandTotal).toFixed(2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'summary.grandTotalWithoutTAX',
            name: 'totalAmount',
            header: 'Total Campaign Amt w/o Tax',
            required: true,
            width: '8vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.summary.grandTotal >= 0) {
                    // return this.currencyPipe.transform(Number(data.summary.grandTotal - data.summary.totalGST).toFixed(2));
                    return this.currencyPipe.transform(Number(data.summary.grandTotal / 1.18).toFixed(2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'mountRatio',
            name: 'mountRatio',
            header: 'Mount Count',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.totalMountItems) {
                    //commented to show the mounted items from the total sites excluding the additional creative
                    // return this.currencyPipe.transform(data.totalMountedItems) + '/' + this.currencyPipe.transform(data.totalMountItems) + ' (' + utils.parseValueToDecimalTwoPlaces(((data.totalMountedItems * 100) / data.totalMountItems)) + '%)';
                    return this.currencyPipe.transform(data.defaultMountedItems) + '/' + this.currencyPipe.transform(data.totalItems) + ' (' + utils.parseValueToDecimalTwoPlaces(((data.defaultMountedItems * 100) / data.totalItems)) + '%)';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billProgress',
            name: 'billProgress',
            header: 'Bill Progress',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: false,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
        },
        // {
        //     field: 'agingAmt',
        //     name: 'agingAmt',
        //     header: 'Aging',
        //     required: true,
        //     width: '6vw',
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     permanent: false,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'is',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        //     displayFn: (data) => {
        //         if (data.agingAmt) {
        //             return this.currencyPipe.transform(data.agingAmt);
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'note',
            name: 'note',
            header: 'Note',
            permanent: false,
            default: false,
            sortable: false,
            hidden: false,
            required: true,
            width: '7.4vw',
            editable: false,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.note) {
                    return data.note;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'delete',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '50px',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
    ];

    @ViewChild('sibGrid')
    preBillGridViewChildRef: GridComponent<SalesJournal>;

    constructor(
        private superBillsService: SuperBillsService,
        private currencyPipe: CurrencyHelperPipe,
        private userGridConfigService: UserGridConfigService,
        private router: Router,
        private notificationServcie: NotificatoinsService,
        private campaignService: CampaignService,
        private confirmationService: ConfirmationService,
        private loaderSubjects: LoaderSubjects,
        public billingUIService: BillingUIService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.userGridConfigService.create([GridNameEnum['SUPERBILL']])
            .subscribe((superbillListGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(superbillListGridConfig[GridNameEnum['SUPERBILL']], this.columns);
                this.preBillGridViewChildRef.ngOnInit();
            });

        this.showXlsExport = this.billingUIService.getBillingTabIndex() === '0' ? true : false;

        this.preBillGroup = new FormGroup({
            siteGridControl: new FormControl()
        });

        // this.campaignStatusDropdown.push({ label: "All", value: "ALL" });
        // Object.keys(CampaignStatusEnum).forEach((key) => {
        //     if (CampaignStatusEnum[key] !== CampaignStatusEnum.PENDING && CampaignStatusEnum[key] !== CampaignStatusEnum.REJECTED) {
        //         this.campaignStatusDropdown.push({ label: CampaignStatusEnum[key], value: key });
        //     }
        // });
        this.campaignStatusDropdown = utils.createDropdown(CampaignStatusEnum, true);

        this.superBillStatusDropdown = utils.createDropdown(SuperBillStatusEnum, true);

        this.setGridConfig();

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const roles = userInfo[0].app_metadata.authorization.roles;
        const permissions = userInfo[0].app_metadata.authorization.permissions;
        if (permissions.indexOf('approve:invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) {
            this.admin = true;
        } else {
            this.admin = false;
        }
        if (permissions.indexOf('approve:account-invoices') !== -1 && permissions.indexOf('reject:account-invoices') !== -1) {  // change to accountant
            this.accountant = true;
        } else {
            this.accountant = false;
        }
        if (permissions.indexOf('request:edit-invoices') !== -1) {  // change to accountant
            this.salesRepresentative = true;
        } else {
            this.salesRepresentative = false;
        }
    }

    setGridConfig() {
        this.gridConfig.getDefaultModel = () => new SalesJournal();
        this.gridConfig.model = SalesJournal;
        this.gridConfig.selectionMode = SelectionMode.Multi;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        // this.gridConfig.responsive = false;
        // this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = false;
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.scrollable = true;
        this.gridConfig.showRefreshIcon = true;

        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen

            this.setPaginationEvent(paginationEvent);
            if (!paginationEvent.filters.superBillStatus || (paginationEvent.filters.superBillStatus && paginationEvent.filters.superBillStatus.value.length === 0)) {
                Object.assign(paginationEvent.filters, { 'superBillStatus': { value: 'OPEN', matchMode: 'is' } });
            }
            return this.superBillsService.create(paginationEvent, paginationEvent, AppUrls.SEARCH).pipe(
                map((response) => {
                    this.searchEvent = paginationEvent;
                    this.campaigns = response['data']['content'];
                    this.totalRecords = response['data'].totalElements;
                    return response['data']['content'];
                }));
        };
    }

    setPaginationEvent(paginationEvent) {
        var filter;
        if (this.userEmailId) {
            filter = { 'employee.emailId': { value: this.userEmailId, matchMode: 'contains' } }
        }
        Object.assign(paginationEvent.filters, filter);
    }


    ngAfterViewInit() {
        // if (this.preBillGridViewChildRef !== undefined) {
        //     this.preBillGridViewChildRef.refresh();
        // }
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    setColumns() {
        this.columns.filter((col) => {
            switch (col.field) {
                case 'employee.fullName':{
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
                case 'displayName': {
                    col.hidden = this.checkPermission('view:display-name') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }

            }
            
            return col;
        });
    }

    viewSuperBillDetails(rowData, event) {
        if (event.ctrlKey) {
            // do nothing
        } else {
            this.router.navigate(['/billings/view/' + rowData.id]);
        }
    }

    parseValueToDecimal(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    getAbsoluteValue(value) {
        return Number(Math.abs(Number(value)));
    }

    /**
     *to show the notification msg when no mount items are present
     *
     * @memberof SuperbillCampaignsComponent
     */
    showMsg() {
        this.notificationServcie.info("There are no mounted items present to generate the invoice", "No Mounted Items");
    }

    /**
     *to calculate the value of the progress bar
     *
     * @param {*} rowData
     * @memberof SuperbillCampaignsComponent
     */
    getInvoiceProgressValue(rowData) {
        // const value = (rowData.summary.grandTotal - (rowData.unbilledAmount * 1.18));
        // const value = Number(((rowData.billingAmount * 100) / rowData.summary.grandTotal).toFixed(2));
        let value: Number;
        if (rowData.eligibleBillingAmount) {
            // value = Number(((Number(rowData.billingAmount) * 100) / (Number(rowData.eligibleBillingAmount) * 1.18)).toFixed(2));
            value = Number(utils.getDecimalNumFixedToTwoPlaces(Number(100 - Number(((Number(rowData.unbilledAmount) * 100) / Number(rowData.eligibleBillingAmount))))));
            // value = Number(this.parseValueToDecimal(Number(((Number(rowData.billingAmount) * 100) / (Number(rowData.eligibleBillingAmount) * 1.18)))));
        } else {
            value = 0;
        }
        return (value < 0) ? 0 : (value > 100) ? 100 : value;
    }

    deleteSuperbill(rowData) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Superbill?',
            accept: () => {
                this.isLoading = true;
                this.campaignService.delete(null, null, null, 'delete/' + rowData.id).subscribe(
                    (response) => {
                        if (response) {
                            this.notificationServcie.success("Superbill Deleted Successfully", "Superbill Deletion");
                            // this.preBillGridViewChildRef.refresh();
                            this.isLoading = false;
                            this.campaigns = this.campaigns.filter(campaign => campaign.id !== rowData.id); // updated the campaigns array
                            this.totalRecords = this.totalRecords - 1;
                            // Start ---> removed API call on superbill delete instead updated the campaigns array
                            this.gridConfig.lazy = false;
                            this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
                                return observableOf(this.campaigns);
                            }
                            this.preBillGridViewChildRef.refresh();
                            this.setGridConfig();
                            // End
                        }
                    },
                    (error) => {
                        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                // do nothing
            }
        });
    }

    updatingUserGridConfig(columnArray) {
        let superbillListGridConfig: Map<string, string> = new Map();
        superbillListGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(superbillListGridConfig), null, GridNameEnum['SUPERBILL'])
            .subscribe((result) => {
            });
    }
    getHeight(obj, val: number) {
        return (obj.nativeElement.clientHeight - val);
    }

    getWidth(obj, val: number) {
        return (obj.nativeElement.clientWidth - val);
        // return (window.innerWidth - val)
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // this.calculateTopHoardingsChartSize();
        this.superbillChartChildRef.refreshChart(this.getHeight(this.superbillCardRef, 0), this.getWidth(this.superbillCardRef, 60))

    }

    exportXlsx() {
        this.isFileDownloading = true;
        this.createLoaderObject("XLS", this.xlsLoader)
        this.loaderSubjects.superbillListXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.fileType = fileType;
        object.searchEvent = this.searchEvent;
    }

    resetStatus() {
        utils.resetDropDown(this.statusDropDown);
        utils.resetDropDown(this.statusOpenClose);
    }

    ngOnDestroy() { }
}
