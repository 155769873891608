
import { map } from 'rxjs/operators';
import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "../../../services/base-service/api.service";
import { Observable } from "rxjs";


@Injectable()

export class SettingService extends ApiService<any, any, any> {
    urlPath: string = 'settings';


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        // this.baseUrl = this.Injector.get(BASE_URL);
    }

    private extractData(res: Response) {
        let body = res.json();
        // console.log("Inside extract", body.data);
        return body || {};
    }

    saveConfig(setting) {
        if (navigator.onLine) {
            var body = JSON.stringify(setting);
            return this.Http.post(this.baseUrl + 'configs/save', body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(map((response) => {
                return response;
            }));
        }


    }

    saveConfigSuccess(response) {

        return response;

    }


}