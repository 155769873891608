import { Injectable } from '@angular/core';
import { ActionExtended } from '../../shared/actionextended';
import { PaginationData } from '../../modals/paginationdata';
import { Action } from '@ngrx/store';
import { Industry } from '../../modals/industry/industry';
import { Group } from '../../modals/group/group';
import { ContractType } from '../../modals/contract-type/contract-type';
import { Area } from '../../modals/Area/area';
import { District } from '../../modals/Area/district';
import { GroupWrapper } from '../../modals/group/group-wrapper';

@Injectable()

export class AppActions {

    static LOAD_MENUS = '[Menu] Load Menus';
    static LOAD_MENUS_SUCCESS = '[Menu] Load Menus Success';

    static GET_INDUSTRY = 'GET_INDUSTRY';
    static GET_INDUSTRY_SUCCESS = 'GET_INDUSTRY_SUCCESS';
    static CREATE_INDUSTRY = 'CREATE_INDUSTRY';
    static UPDATE_INDUSTRY = 'UPDATE_INDUSTRY';
    static CREATE_INDUSTRY_SUCCESS = 'CREATE_INDUSTRY_SUCCESS';
    static CREATE_INDUSTRY_FAILURE = 'CREATE_INDUSTRY_FAILURE';
    static UPDATE_INDUSTRY_SUCCESS = 'UPDATE_INDUSTRY_SUCCESS';
    static UPDATE_INDUSTRY_FAILURE = 'CREATE_INDUSTRY_FAILURE';

    static GET_GROUP = 'GET_GROUP';
    static GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
    static CREATE_GROUP = 'CREATE_GROUP';
    static CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
    static CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

    static GET_CONTRACT_TYPE = 'GET_CONTRACT_TYPE';
    static GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS';
    static CREATE_CONTRACT_TYPE = 'CREATE_CONTRACT_TYPE';
    static CREATE_CONTRACT_TYPE_SUCCESS = 'CREATE_CONTRACT_TYPE_SUCCESS';
    static CREATE_CONTRACT_TYPE_FAILURE = 'CREATE_CONTRACT_TYPE_FAILURE';
    static UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE';
    static UPDATE_CONTRACT_TYPE_SUCCESS = 'UPDATE_CONTRACT_TYPE_SUCCESS';
    static UPDATE_CONTRACT_TYPE_FAILURE = 'UPDATE_CONTRACT_TYPE_FAILURE';

    static GET_STATES = 'GET_STATES';
    static GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
    static GET_STATES_LIST = 'GET_STATES_LIST';
    static GET_STATES_LIST_SUCCESS = "GET_STATES_LIST_SUCCESS";
    static GET_DISTRICTS = 'GET_DISTRICTS';
    static GET_DISTRICTS_LIST = "GET_DISRICTS_LIST";
    static GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
    static CREATE_DISTRICT_SUCCESS = 'CREATE_DISTRICT_SUCCESS';
    static CREATE_DISTRICT_FAILURE = 'CREATE_DISTRICT_FAILURE';
    static GET_DISTRICT_LIST_SUCCESS = 'GET_DISTRICT_LIST_SUCCESS';
    static CREATE_DISTRICT = 'CREATE_DISTRICT';
    static UPDATE_DISTRICT = "UPDATE_DISTRICT";
    static RESET_DISTRICT_LIST = 'RESET_DISTRICT_LIST';
    static RESET_IS_DISTRICT_CREATED = 'RESET_IS_DISTRICT_CREATED';
    static RESET_IS_DISTRICT_UPDATED = "RESET_IS_DISTRICT_UPDATED";
    static RESET_CITIES_LIST = 'RESET_CITIES_LIST';
    static RESET_AREAS_LIST = 'RESET_AREAS_LIST';
    static GET_CITIES = 'GET_CITIES';
    static GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
    static GET_AREAS = 'GET_AREAS';
    static GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
    static ADD_AREA = 'ADD_AREA';
    static CREATE_AREA = 'CREATE_AREA';
    static CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
    static SAVE_AREA = 'SAVE_AREA';
    static SAVE_AREA_SUCCESS = 'SAVE_AREA_SUCCESS';
    static GET_AREA_LIST = 'GET_AREA_LIST';
    static GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS';
    static GET_AREA_LIST_FIRST = 'GET_AREA_LIST_FIRST';
    static GET_AREA_LIST_FIRST_SUCCESS = 'GET_AREA_LIST_FIRST_SUCCESS';
    static GET_AREA_BY_CRITERIA = 'GET_AREA_BY_CRITERIA';
    static GET_AREA_BY_CRITERIA_SUCCESS = 'GET_AREA_BY_CRITERIA_SUCCESS';
    static GET_AREA_BY_CRITERIA_FAILURE = 'GET_AREA_BY_CRITERIA_FAILURE';
    static CREATE_AREA_FAILURE = 'CREATE_AREA_FAILURE';
    static UPDATE_AREA = "UPDATE_AREA";
    static UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS";
    static UPDATE_AREA_FAILURE = "UPDATE_AREA_FAILURE"
    static RESET_ERROR = 'RESET_ERROR';
    static RESET_IS_GROUP_CREATED = 'RESET_IS_GROUP_CREATED';
    static RESET_IS_CONTRACT_TYPE_CREATED = 'RESET_IS_CONTRACT_TYPE_CREATED';
    static RESET_IS_CONTRACT_TYPE_UPDATED = 'RESET_IS_CONTRACT_TYPE_UPDATED';
    static RESET_IS_AREA_CREATED = 'RESET_IS_AREA_CREATED';
    static RESET_IS_AREA_UPDATED = 'RESET_IS_AREA_UPDATED';
    static GET_CONFIG = 'GET_CONFIG';
    static GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
    static SAVE_CONFIG = 'SAVE_CONFIG';
    static SAVE_CONFIG_SUCCESS = 'SAVE_CONFIG_SUCCESS';

    static UPLOAD_IMAGE = 'UPLOAD_IMAGE';
    static UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
    static UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
    static RESET_IS_IMAGE_UPLOADED = 'RESET_IS_IMAGE_UPLOADED';
    static RESET_IS_INDUSTRY_CREATED = 'RESET_IS_INDUSTRY_CREATED';
    static RESET_IS_INDUSTRY_UPDATED = 'RESET_IS_INDUSTRY_UPDATED';

    static UPDATE_MONITORING = 'UPDATE_MONITORING';
    static UPDATE_MONITORING_SUCCESS = 'UPDATE_MONITORING_SUCCESS';
    static UPDATE_MONITORING_FAILURE = 'UPDATE_MONITORING_FAILURE';
    static RESET_IS_MONITORING_UPDATED = 'RESET_IS_MONITORING_UPDATED';

    static REMOVE_MONITORING = 'REMOVE_MONITORING';
    static REMOVE_MONITORING_SUCCESS = 'REMOVE_MONITORING_SUCCESS';
    static REMOVE_MONITORING_FAILURE = 'REMOVE_MONITORING_FAILURE';
    static RESET_IS_MONITORING_REMOVED = 'RESET_IS_MONITORING_REMOVED';

    static SAVE_VENDOR_DETAILS = 'SAVE_VENDOR_DETAILS';
    static SAVE_VENDOR_DETAILS_SUCCESS = 'SAVE_VENDOR_DETAILS_SUCCESS';
    static GET_SYSTEM_MODULES = 'GET_SYSTEM_MODULES';
    static GET_SYSTEM_MODULES_SUCCESS = 'GET_SYSTEM_MODULES_SUCCESS';
    static GET_CONFIGURATION_BY_MODULE = 'GET_CONFIGURATION_BY_MODULE';
    static GET_CONFIGURATION_BY_MODULE_SUCCESS = 'GET_CONFIGURATION_BY_MODULE_SUCCESS';
    static SAVE_CONFIGURATIONS = 'SAVE_CONFIGURATIONS';
    static SAVE_CONFIGURATIONS_SUCCESS = 'SAVE_CONFIGURATIONS_SUCCESS';
    static SAVE_CONFIGURATIONS_FAILURE = 'SAVE_CONFIGURATIONS_FAILURE';
    static GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
    static GET_VENDOR_DETAILS_SUCCESS = "GET_VENDOR_DETAILS_SUCCESS";
    static SAVE_PASSBOOK_CONFIGURATION = "SAVE_PASSBOOK_CONFIGURATION";

    static GET_HOARDING_FILTERS = 'GET_HOARDING_FILTERS';
    static SET_HOARDING_FILTERS = 'SET_HOARDING_FILTERS';

    static UPDATE_GROUP = 'UPDATE_GROUP';
    static UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
    static UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
    static RESET_IS_GROUP_UPDATED = 'RESET_IS_GROUP_UPDATED';

    static GET_SYSTEM_CONFIGURATIONS = 'GET_SYSTEM_CONFIGURATIONS';
    static GET_SYSTEM_CONFIGURATIONS_SUCCESS = 'GET_SYSTEM_CONFIGURATIONS_SUCCESS';
    static UPDATE_DISTRICT_FAILURE = "UPDATE_DISTRICT_FAILURE";
    static UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS";

    static SET_ENABLE_PASSBOOK = 'SET_ENABLE_PASSBOOK';


    loadMenus(): any {
        return {
            type: AppActions.LOAD_MENUS
        };
    }

    loadMenusSuccess(menus): any {
        return {
            type: AppActions.LOAD_MENUS_SUCCESS,
            payload: menus
        };
    }

    getIndustry(): ActionExtended {
        return {
            type: AppActions.GET_INDUSTRY
        };
    }

    getIndustrySuccess(industry): ActionExtended {
        return {
            type: AppActions.GET_INDUSTRY_SUCCESS,
            payload: industry
        };
    }

    createIndustry(industry: Industry): ActionExtended {

        return {
            type: AppActions.CREATE_INDUSTRY,
            payload: industry
        };
    }

    updateIndustry(industry: Industry): ActionExtended {

        return {
            type: AppActions.UPDATE_INDUSTRY,
            payload: industry
        };
    }

    createIndustrySuccess(industry): ActionExtended {
        return {
            type: AppActions.CREATE_INDUSTRY_SUCCESS,
            payload: industry
        };
    }
    createIndustryFailure(industry): ActionExtended {
        return {
            type: AppActions.CREATE_INDUSTRY_FAILURE,
            payload:industry
        };
    }
    updateIndustrySuccess(industry): ActionExtended {
        return {
            type: AppActions.UPDATE_INDUSTRY_SUCCESS,
            payload:industry
        };
    }
    updateIndustryFailure(industry): ActionExtended {
        return {
            type: AppActions.UPDATE_INDUSTRY_FAILURE,
            payload:industry
        };
    }

    getGroup(): ActionExtended {
        return {
            type: AppActions.GET_GROUP
        };
    }

    getGroupSuccess(group): ActionExtended {
        return {
            type: AppActions.GET_GROUP_SUCCESS,
            payload: group
        };
    }

    createGroup(group: GroupWrapper): ActionExtended {
        return {
            type: AppActions.CREATE_GROUP,
            payload: group
        };
    }

    createGroupSuccess(group): ActionExtended {
        return {
            type: AppActions.CREATE_GROUP_SUCCESS,
            payload: group
        };
    }

    createGroupFailure(): ActionExtended {
        return {
            type: AppActions.CREATE_GROUP_FAILURE
        };
    }

    getContractType(): ActionExtended {
        return {
            type: AppActions.GET_CONTRACT_TYPE
        };
    }

    getContractTypeSuccess(contractType): ActionExtended {
        return {
            type: AppActions.GET_CONTRACT_TYPE_SUCCESS,
            payload: contractType
        };
    }

    createContractType(contractType: ContractType): ActionExtended {
        return {
            type: AppActions.CREATE_CONTRACT_TYPE,
            payload: contractType
        };
    }

    createContractTypeSuccess(contractType): ActionExtended {
        return {
            type: AppActions.CREATE_CONTRACT_TYPE_SUCCESS,
            payload: contractType
        };
    }

    createContractTypeFailure(): ActionExtended {
        return {
            type: AppActions.CREATE_CONTRACT_TYPE_FAILURE
        };
    }


    updateContractType(contractType: ContractType): ActionExtended {
        return {
            type: AppActions.UPDATE_CONTRACT_TYPE,
            payload: contractType
        };
    }

    updateContractTypeSuccess(contractType): ActionExtended {
        return {
            type: AppActions.UPDATE_CONTRACT_TYPE_SUCCESS,
            payload: contractType
        };
    }

    updateContractTypeFailure(): ActionExtended {
        return {
            type: AppActions.UPDATE_CONTRACT_TYPE_FAILURE
        };
    }

    getStates(areaQueryParams): ActionExtended {
        return {
            type: AppActions.GET_STATES,
            payload: areaQueryParams
        };
    }

    getStatesSuccess(states): ActionExtended {
        return {
            type: AppActions.GET_STATES_SUCCESS,
            payload: states
        };
    }

    getStatesList(districtQueryParams): ActionExtended {
        return {
            type: AppActions.GET_STATES_LIST,
            payload: districtQueryParams
        };
    }


    getStatesListSuccess(states): ActionExtended {
        return {
            type: AppActions.GET_STATES_LIST_SUCCESS,
            payload: states
        };
    }

    getDistrictsList(districtQueryParams): ActionExtended {
        return {
            type: AppActions.GET_DISTRICTS_LIST,
            payload: districtQueryParams
        };
    }

    getDistricts(areaQueryParams): ActionExtended {
        return {
            type: AppActions.GET_DISTRICTS,
            payload: areaQueryParams
        };
    }

    createDistrict(district: District): ActionExtended {

        return {
            type: AppActions.CREATE_DISTRICT,
            payload: district
        };
    }

    createDistrictSuccess(district): ActionExtended {
        return {
            type: AppActions.CREATE_DISTRICT_SUCCESS,
            payload: district
        };
    }

    createDistrictFailure(): ActionExtended {
        return {
            type: AppActions.CREATE_DISTRICT_FAILURE
        };
    }


    updateDistrict(district: District): ActionExtended {
        return {
            type: AppActions.UPDATE_DISTRICT,
            payload: district
        }
    }


    updateDistrictSuccess(district): ActionExtended {
        return {
            type: AppActions.UPDATE_DISTRICT_SUCCESS,
            payload: district
        };
    }

    updateDistrictFailure(): ActionExtended {
        return {
            type: AppActions.UPDATE_DISTRICT_FAILURE
        };
    }

    getDistrictsSuccess(districts): ActionExtended {
        return {
            type: AppActions.GET_DISTRICTS_SUCCESS,
            payload: districts
        };
    }

    resetDistrictsList(): ActionExtended {
        return {
            type: AppActions.RESET_DISTRICT_LIST
        };
    }

    resetIsDistrictCreated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_DISTRICT_CREATED
        };
    }

    resetIsDistrictUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_DISTRICT_UPDATED
        };
    }

    resetCitiesList(): ActionExtended {
        return {
            type: AppActions.RESET_CITIES_LIST
        };
    }

    resetAreasList(): ActionExtended {
        return {
            type: AppActions.RESET_AREAS_LIST
        };
    }

    getCities(areaQueryParams): ActionExtended {
        return {
            type: AppActions.GET_CITIES,
            payload: areaQueryParams
        };
    }

    getCitiesSuccess(cities): ActionExtended {
        return {
            type: AppActions.GET_CITIES_SUCCESS,
            payload: cities
        };
    }

    getAreas(areaQueryParams): ActionExtended {
        return {
            type: AppActions.GET_AREAS,
            payload: areaQueryParams
        };
    }

    getAreasSuccess(areas): ActionExtended {
        return {
            type: AppActions.GET_AREAS_SUCCESS,
            payload: areas
        };
    }

    createArea(area: Area): ActionExtended {

        return {
            type: AppActions.CREATE_AREA,
            payload: area
        };
    }

    updateArea(area: Area): ActionExtended {
        return {
            type: AppActions.UPDATE_AREA,
            payload: area
        };
    }

    updateAreaSuccess(area): ActionExtended {
        return {
            type: AppActions.UPDATE_AREA_SUCCESS,
            payload: area
        };
    }

    updateAreaFailure(): ActionExtended {
        return {
            type: AppActions.UPDATE_AREA_FAILURE
        };
    }

    createAreaSuccess(area): ActionExtended {
        return {
            type: AppActions.CREATE_AREA_SUCCESS,
            payload: area
        };
    }

    createAreaFailure(): ActionExtended {
        return {
            type: AppActions.CREATE_AREA_FAILURE
        };
    }


    addArea(area): ActionExtended {
        return {
            type: AppActions.ADD_AREA,
            payload: area
        };
    }

    saveArea(area): ActionExtended {
        return {
            type: AppActions.SAVE_AREA,
            payload: area
        };
    }

    saveAreaSuccess(area): ActionExtended {
        return {
            type: AppActions.SAVE_AREA_SUCCESS,
            payload: area
        };
    }

    getAreaList(areaQueryParams) {
        return {
            type: AppActions.GET_AREA_LIST,
            payload: areaQueryParams
        };
    }

    getAreaListSuccess(areaList) {
        return {
            type: AppActions.GET_AREA_LIST_SUCCESS,
            payload: areaList
        };
    }

    getAreaListFirst() {
        return {
            type: AppActions.GET_AREA_LIST_FIRST
        };
    }

    getAreaListFirstSuccess(areaList) {
        return {
            type: AppActions.GET_AREA_LIST_FIRST_SUCCESS,
            payload: areaList
        };
    }

    getAreaByCriteria(areaQueryParams) {
        return {
            type: AppActions.GET_AREA_BY_CRITERIA,
            payload: areaQueryParams
        };
    }

    getAreaByCriteriaSuccess(areas) {
        return {
            type: AppActions.GET_AREA_BY_CRITERIA_SUCCESS,
            payload: areas
        };
    }

    getAreaByCriteriaFailure() {
        return {
            type: AppActions.GET_AREA_BY_CRITERIA_FAILURE
        }
    }

    resetError(): ActionExtended {
        return {
            type: AppActions.RESET_ERROR
        };
    }

    resetIsGroupCreated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_GROUP_CREATED
        };
    }

    resetIsContractTypeCreated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_CONTRACT_TYPE_CREATED
        };
    }

    resetIsContractTypeUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_CONTRACT_TYPE_UPDATED
        };
    }

    resetIsAreaCreated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_AREA_CREATED
        };
    }

    resetIsAreaUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_AREA_UPDATED
        };
    }

    getConfiguration(): ActionExtended {
        return {
            type: AppActions.GET_CONFIG
        };
    }
    saveConfiguration(systemMasters): ActionExtended {
        return {
            type: AppActions.SAVE_CONFIG,
            payload: systemMasters
        };
    }

    saveConfigurationSuccess(systemMasters): ActionExtended {
        return {
            type: AppActions.SAVE_AREA_SUCCESS,
            payload: systemMasters
        };
    }

    getConfigurationSuccess(systemMasters): ActionExtended {
        return {
            type: AppActions.GET_CONFIG_SUCCESS,
            payload: systemMasters
        };
    }

    uploadImage(selectedRow): ActionExtended {
        return {
            type: AppActions.UPLOAD_IMAGE,
            payload: selectedRow
        };
    }

    uploadImageSuccess(selectedRow) {
        return {
            type: AppActions.UPLOAD_IMAGE_SUCCESS,
            payload: selectedRow
        };
    }

    uploadImageFailure() {
        return {
            type: AppActions.UPLOAD_IMAGE_FAILURE
        };
    }

    resetIsImageUploaded(): ActionExtended {
        return {
            type: AppActions.RESET_IS_IMAGE_UPLOADED
        };
    }

    resetIsIndustryCreated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_INDUSTRY_CREATED
        };
    }
    resetIsIndustryUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_INDUSTRY_UPDATED
        };
    }

    updateMonitoring(rowData): ActionExtended {
        return {
            type: AppActions.UPDATE_MONITORING,
            payload: rowData
        };
    }

    updateMonitoringSuccess(rowData) {
        return {
            type: AppActions.UPDATE_MONITORING_SUCCESS,
            payload: rowData
        };
    }

    updateMonitoringFailure() {
        return {
            type: AppActions.UPDATE_MONITORING_FAILURE
        };
    }

    resetIsMonitoringUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_MONITORING_UPDATED
        };
    }

    removeMonitoringItem(rowData): ActionExtended {
        return {
            type: AppActions.REMOVE_MONITORING,
            payload: rowData
        };
    }

    removeMonitoringItemSuccess(rowData) {
        return {
            type: AppActions.REMOVE_MONITORING_SUCCESS,
            payload: rowData
        };
    }

    removeMonitoringItemFailure() {
        return {
            type: AppActions.REMOVE_MONITORING_FAILURE
        };
    }

    resetIsMonitoringRemoved(): ActionExtended {
        return {
            type: AppActions.RESET_IS_MONITORING_REMOVED
        };
    }

    saveVendorDetails(vendorDetails): ActionExtended {
        return {
            type: AppActions.SAVE_VENDOR_DETAILS,
            payload: vendorDetails
        }
    }

    saveVendorSuccess(vendor): ActionExtended {
        return {
            type: AppActions.SAVE_VENDOR_DETAILS_SUCCESS,
            payload: vendor
        }
    }

    getSystemModules(): ActionExtended {
        return {
            type: AppActions.GET_SYSTEM_MODULES,

        }
    }

    getSystemModulesSuccess(modules): ActionExtended {
        return {
            type: AppActions.GET_SYSTEM_MODULES_SUCCESS,
            payload: modules
        }
    }

    getConfigurationByModule(moduleName): ActionExtended {
        return {
            type: AppActions.GET_CONFIGURATION_BY_MODULE,
            payload: moduleName
        }
    }

    getConfigurationByModuleSuccess(configurations): ActionExtended {
        return {
            type: AppActions.GET_CONFIGURATION_BY_MODULE_SUCCESS,
            payload: configurations
        }
    }

    saveParameters(parameters): ActionExtended {
        return {
            type: AppActions.SAVE_CONFIGURATIONS,
            payload: parameters
        }
    }

    savePassbookParameters(parameters): ActionExtended {
        return {
            type: AppActions.SAVE_PASSBOOK_CONFIGURATION,
            payload: parameters
        }
    }

    saveParametersSuccess(parameters): ActionExtended {
        return {
            type: AppActions.SAVE_CONFIGURATIONS_SUCCESS,
            payload: parameters
        }
    }

    saveParametersFailure(): ActionExtended {
        return {
            type: AppActions.SAVE_CONFIGURATIONS_FAILURE
        }
    }

    getVendorDetails(tenantId): ActionExtended {
        return {
            type: AppActions.GET_VENDOR_DETAILS,
            payload: tenantId
        }
    }

    getVendorDetailsSuccess(vendorDetails): ActionExtended {
        return {
            type: AppActions.GET_VENDOR_DETAILS_SUCCESS,
            payload: vendorDetails
        }
    }


    getHoardingFilters(): ActionExtended {
        return {
            type: AppActions.GET_HOARDING_FILTERS
        };
    }

    setHoardingFilters(filters): ActionExtended {
        return {
            type: AppActions.SET_HOARDING_FILTERS,
            payload: filters
        };
    }

    updateGroup(groupWrapper): ActionExtended {
        return {
            type: AppActions.UPDATE_GROUP,
            payload: groupWrapper
        };
    }

    updateGroupSuccess(groupWrapper): ActionExtended {
        return {
            type: AppActions.UPDATE_GROUP_SUCCESS,
            payload: groupWrapper
        };
    }

    updateGroupFailure(): ActionExtended {
        return {
            type: AppActions.UPDATE_GROUP_FAILURE
        };
    }

    resetIsGroupUpdated(): ActionExtended {
        return {
            type: AppActions.RESET_IS_GROUP_UPDATED
        };
    }

    getSystemConfiguations(): ActionExtended {
        return {
            type: AppActions.GET_SYSTEM_CONFIGURATIONS
        }
    }

    getSystemConfiguationsSuccess(configurations): ActionExtended {
        return {
            type: AppActions.GET_SYSTEM_CONFIGURATIONS_SUCCESS,
            payload: configurations
        }
    }

    setEnablePassbook(value): ActionExtended {
        return {
            type: AppActions.SET_ENABLE_PASSBOOK,
            payload: value
        }
    }
}
