<div class="public-page-container">
  <div *ngIf="isLoading">
    <div class="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="public-page-container__holder">
    <div class=" public-page-container__holder_data">
      <ul class="cinline-block float-right" *ngIf="downloadFileText.length !== 0">
        <li>
          <div>
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE"
              animationDuration="2s"></p-progressSpinner>
          </div>
        </li>
        <li #profile class="profile-item">

          <span>{{downloadFileText}}</span>
        </li>
      </ul>
      <div class="header-container">
        <div class="header">
          <div class="header-logo">
            <img src="{{logoUrl}}" class="client-logo margin-left-32">
          </div>
          <div class="page-title">{{currentType=='CAMPAIGN' ?  'Campaign' : 'Plan'}} Tracker</div>

        </div>

      </div>
      <div class="client-information-section margin-left-32">
        <div class="line-height-22">
          <span class="font-18"> {{displayName}} </span><br>
          <span class="font-14">{{billingName}}<br>
            {{from}}{{currentType=='CAMPAIGN' ? (campaign.campaignStartDate  | date:'ddMMMyy') : (plan.campaignStartDate | date:'ddMMMyy')}}<br>
            {{to}}{{ currentType=='CAMPAIGN'  ? (campaign.campaignEndDate |  date:'ddMMMyy') : (plan.campaignEndDate | date:'ddMMMyy')}}</span>

        </div>
        <div style="margin-right: 20px; margin-top:52px;">
          <span class="md-inputfield">
            <input class="input-field" id="searchField" trim="blur" type="text" pInputText name="searchField"
              [(ngModel)]="searchTerm" #searchField="ngModel" maxlength="100" (keyup)="searchInGrid()" pTooltip="{{ getCommaSeparatedFields() }}"/>
            <label for="height">Search
              <span class="required"></span>
            </label>

            <span class="font-16 margin-right-7 dialog-button map-button"><a target="_blank" style="cursor: pointer;"
                [routerLink]="currentType=='CAMPAIGN' ? ['../../../plans/public/view/map/' + campaignId + '/' + x_tenant,  { campaignTracker: true } ] : ['../../../../plans/public/view/map/' + planId + '/' + x_tenant,  { planTracker: true } ]"><span
                  class="button-label"><i class="fa fa-map-marker map-icon"></i>View
                  Map</span></a></span>
          </span>
          <div class="legendContainer margin-top-15" *ngIf="tabIndex === 0 && currentType=='CAMPAIGN'">
            <div class="inline-block padding-4">
              <div class="legendItem approved">
              </div>
              <span>Approved</span>
            </div>
            <div class="inline-block padding-4">
              <div class="legendItem mounted">
              </div>
              <span>Mounted</span>
            </div>
            <div class="inline-block padding-4">
              <div class="legendItem expired">
              </div>
              <span>Expired</span>
            </div>
            <div class="inline-block padding-4">
              <div class="legendItem pending-approve">
              </div>
              <span>Pending Approve</span>
            </div>
          </div>
        </div>
      </div>


      <div class="grid-container">
        <div class="grid-content" [ngStyle]="{right: currentType!=='CAMPAIGN' ? '200px' : ''}">
          <div #exportMenu class="export-menu">
            <p-tieredMenu #exportMenu popup="popup" [model]="exportItem" appendTo="body" [style]="{fontSize: '14px'}">
            </p-tieredMenu>
            <label class="font-12 font-bold label-vCenter">Export</label>
            <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-15 font-button margin-left-10"
              title="Export" (click)="exportMenu.toggle($event)" icon="fa fa-download"></button>
          </div>
          <div *ngIf="tabIndex > 0">
            <button class="btn-primary" pButton label="View Photos" (click)="viewImages()"></button>
          </div>
        </div>


        <ng-template [ngIf]="currentType=='CAMPAIGN'">
        <p-tabView [activeIndex]="tabIndex" (onChange)="onTabChange($event)">
          <p-tabPanel header="Sites({{campaign.totalItems}})">
            <sib-totalsites [items]="campaignItems" (eEmitTotalItems)="setCampaignItemsLength($event)"
              (eEmitImages)="viewPhotos($event)">
            </sib-totalsites>
          </p-tabPanel>
          <p-tabPanel header="Mounting({{campaign.totalMountedItems}}/{{campaign.totalMountItems}})">
            <sib-mountedsites [campaignMountingItems]="campaignMountingItems"
              (eEmitTotalMountingItems)="setCampaignMountingItems($event)" (eEmitImages)="viewPhotos($event)">
            </sib-mountedsites>
          </p-tabPanel>
          <p-tabPanel header="Monitoring({{campaign.totalMonitoredItems}}/{{campaign.totalMonitoringItems}})">
            <sib-monitoringsites [campaignMonitoringItems]="campaignMonitoringItems"
              (eEmitTotalMonitoringItems)="setCampaignMonitoringItems($event)" (eEmitImages)="viewPhotos($event)">
            </sib-monitoringsites>
          </p-tabPanel>
          <p-tabPanel header="Unmounting({{campaign.totalUnmountedItems}}/{{campaign.totalUnmountingItems}})">
            <sib-unmountingsites [campaignUnmountingItems]="campaignUnmountingItems"
              (eEmitTotalUnmountingItems)="setCampaignUnmountingItems($event)" (eEmitImages)="viewPhotos($event)">
            </sib-unmountingsites>
          </p-tabPanel>
        </p-tabView>
      </ng-template>

      <ng-template [ngIf]="currentType=='PLAN'">
        <p-tabView [activeIndex]="tabIndex" (onChange)="onTabChange($event)">
          <p-tabPanel header="Sites({{plan.totalItems}})">
            <sib-totalsites [source]="'PLAN'" [items]="planItems" (eEmitTotalItems)="setPlanItemsLength($event)"
              (eEmitImages)="viewPhotos($event)">
            </sib-totalsites>
          </p-tabPanel>
          </p-tabView>
      </ng-template>

      </div>
    </div>

  </div>

</div>

<p-dialog header="No Images" [(visible)]="displayNoImageDialog" [modal]="true" [width]="370" [height]="170"
  (onHide)="displayNoImageDialog = false">
  There are no images in the {{displaySlideshowText}}.
  <div class="ctext-center padding-top-10">
    <button (click)="displayNoImageDialog = false" class="mtab-primary btn-confirm" label="OK" pButton></button>
  </div>
</p-dialog>

<!-- Dialog for PPT imagetype multiselection and then download the PPT -->
<p-dialog header="Download PPT" *ngIf="displayPPTdialog" [(visible)]="displayPPTdialog" [modal]="true"
  [responsive]="true" [autoAlign]="false" [minWidth]="300" [width]="330" (onHide)="hidePPTDialog()"
  [blockScroll]="true">
  <div class="ui-g-12">
    <p-multiSelect *ngIf="displayPPTdialog" [options]="imageTypeOptions" name="name" [(ngModel)]="selectedImageType"
      (onChange)="onFilterChange()" [style]="{'width': '100%'}" appendTo="body"
      [panelStyleClass]="'multiselect-drop-down'">
    </p-multiSelect>
  </div>
  <p-footer>
    <div style="text-align:center;">
      <button pButton class="mtab-primary margin-top-10" label="Download" (click)="downloadPPT()"
        [disabled]="!selectedImgType"></button>
    </div>
  </p-footer>
</p-dialog>

<div class="photo-dialog">
  <sib-photo-dialog (displayReset)="changePhotoDisplay($event)"></sib-photo-dialog>
</div>
