<p-dialog
  #helpDialog
  [(visible)]="display"
  showEffect="slide"
  [responsive]="true"
  [width]="400"
  [resizable]="true"
  [height]="450"
  [positionLeft]="null"
  [positionTop]="null"
  styleClass="help-support-dialog"
  (onHide)="hideHelpDialog()"
  class="help-dialog"
>
  <p-header class="dialog-header">
    <span class="help-header">{{ moduleSelected }}</span>
    <div class="header-flex">
      <!-- <span class="help-header">{{moduleSelected}}</span> -->
      <p-menu
        #modulesMenu
        [popup]="true"
        [model]="modules"
        class="menu-items"
        [appendTo]="helpDialog"
      ></p-menu>
      <span class="margin-right-5">
        <!-- <a pTooltip="SIB Videos" (click)="showPlaylistDialog($event)" class="icon">All Vidoes</a> -->
        <i
          class="fa fa-play-circle icon margin-5 font-25"
          aria-hidden="true"
          pTooltip="SIB Videos"
          (click)="showPlaylistDialog($event)"
        ></i>
      </span>
      <button
        type="button"
        pButton
        label="Select Module"
        (click)="modulesMenu.toggle($event)"
        class="module-button"
      ></button>
    </div>

    <div class="search-field">
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
      <input
        #helpSearchInput
        id="search"
        trim="blur"
        maxlength="40"
        type="text"
        pInputText
        name="search"
        class="search-box"
        (keyup)="keyUp.next($event)"
        [(ngModel)]="helpSearch"
      />
    </div>
  </p-header>

  <div
    *ngIf="filteredArticles.length > 0 && showFilterContainer"
    class="filtered-articles"
  >
    <div
      *ngFor="let article of filteredArticles"
      (click)="filteredArticle(article)"
      style="cursor: pointer"
    >
      <div *ngIf="article.module === 'CUSTOMER'" class="article-padding">
        <span class="ui-inputgroup-addon"><i class="icon-customer"></i></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'CONTRACT'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-contract-type"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'INVENTORY'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-hoarding icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'PLAN'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-plans icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'CAMPAIGN'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-campaign-4 icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'OPERATION'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-operations icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'BILLING'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-billings-2 icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'USER'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-users icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'MASTERS'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-masters icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'PHOTO_INTERFACE'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-photo-interface icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'ACTIVITY'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-activity-log icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>

      <div *ngIf="article.module === 'DOCUMENT'" class="article-padding">
        <span class="ui-inputgroup-addon"
          ><i class="icon-documents icon-color"></i
        ></span>
        <span class="filtered-content">{{ article.title }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!articleIsSelected">
    <p-tabView
      (onChange)="selectedDeviceCategory($event)"
      class="dialog-content"
    >
      <p-tabPanel header="Web">
        <div>
          <div *ngFor="let article of webArticles" class="help-support-content">
            <div style="font-weight: 500; font-size: 16px; padding-left: 8px">
              {{ article.title }}
            </div>
            <!-- commented because iframe was not visible instead used primeng editor to show embeded video -->
            <!-- <div [innerHTML]=article.description>
            </div> -->
            <div>
              <p-editor
                [readonly]="'true'"
                [(ngModel)]="article.description"
              ></p-editor>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Mobile">
        <div *ngIf="selectedDevice === 'MOBILE'">
          <div
            *ngFor="let article of mobileArticles"
            class="help-support-content"
          >
            <div style="font-weight: 500; font-size: 16px; padding-left: 8px">
              {{ article.title }}
            </div>
            <!-- commented because iframe was not visible instead used primeng editor to show embeded video -->
            <!-- <div [innerHTML]=article.description>
            </div> -->
            <div>
              <p-editor
                [readonly]="'true'"
                [(ngModel)]="article.description"
              ></p-editor>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Both">
        <div *ngIf="selectedDevice === 'BOTH'">
          <div
            *ngFor="let article of selectedModuleArticles"
            class="help-support-content"
          >
            <div style="font-weight: 500; font-size: 16px; padding-left: 8px">
              {{ article.title }}
            </div>
            <!-- commented because iframe was not visible instead used primeng editor to show embeded video -->
            <!-- <div [innerHTML]=article.description>
            </div> -->
            <div>
              <p-editor
                [readonly]="'true'"
                [(ngModel)]="article.description"
              ></p-editor>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngIf="articleIsSelected">
    <div class="help-support-content" style="width: 374px">
      <div style="font-weight: 500; font-size: 16px; padding-left: 8px">
        {{ selectedArticle.title }}
      </div>
      <!-- <div [innerHTML]=selectedArticle.description>
      </div> -->
      <div>
        <p-editor
          [readonly]="'true'"
          [(ngModel)]="selectedArticle.description"
        ></p-editor>
      </div>
    </div>
  </div>
</p-dialog>

<!-- View Playlist -->
<p-dialog
  #playlist
  [header]="'ShowItMax Videos'"
  *ngIf="viewPlaylist"
  [(visible)]="viewPlaylist"
  showEffect="fade"
  [responsive]="true"
  [width]="750"
  [height]="480"
  blockScroll="true"
  [modal]="true"
  (onHide)="hidePlaylistDialog($event)"
>
  <div class="iframe-container">
    <iframe
      src="https://vimeo.com/showcase/6608013/embed"
      allowfullscreen
      frameborder="0"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</p-dialog>
