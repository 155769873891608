<!-- Please do not remove this commented code -->
<!-- <div class="ui-g fyear-dropdown">
  <span class="ui-float-label">
    <p-dropdown [options]="fiscalYears" [dropdownIcon]="'fa fa-calendar'" [styleClass]="'fiscal-dropdown'"
      [(ngModel)]="selectedFiscalYear" [style]="{'width':'100px'}" placeholder="Financial Year"
      (onChange)="onFiscalYearSelection()"></p-dropdown>
    <label>Financial Year</label>
  </span>
</div> -->
<div class="superbillChart-container">
  <div
    class="loading-specific-area billing-chart-loader"
    *ngIf="columnChartLoader"
  >
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div
    class="flex-container float-right margin-top--40"
    *ngIf="columnChartData.length > 0 && columnChartConfig && !showChartTitle"
  >
    <div
      class="flex-item margin-10 icon"
      (mouseenter)="showOverlay($event)"
      (mouseleave)="overlay.hide($event)"
    >
      <strong> Total = {{ totalSuperbillAmount }}</strong>
    </div>
  </div>
  <sib-chart
    *ngIf="superbillChartData.length > 0 && columnChartConfig"
    [data]="superbillChartData"
    [columnConfig]="columnChartConfig"
    [chartType]="'columnChart'"
    [elementId]="lineElementId"
    (eEmitColumnChartLoaded)="columnChartLoader = false"
  ></sib-chart>

  <p-overlayPanel #overlay [style]="{ width: '240px' }">
    <ol class="ordered-list">
      <li>Superbill Total With Tax = {{ totalSuperbillAmountWithTax }}</li>
      <li>Superbill Total Without Tax = {{ totalSuperbillAmount }}</li>
    </ol>
  </p-overlayPanel>
</div>
