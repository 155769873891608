import { Injectable } from "@angular/core";
import { HttpConfigurationService } from "./http-configuration.service";

@Injectable()
export class UiConfigurationService {

  validationErrorsArray: { [key: string]: any} = {};
  
  constructor(private httpConfigurationService: HttpConfigurationService) {}

  getEmailConfig() {
    return this.httpConfigurationService.get(null, "/search/emailConfig");
  }

  getEmailProviderConfig(emailProvider) {
    return this.httpConfigurationService.get(
      emailProvider,
      "/search/smtpConfig"
    );
  }

  saveEmailConfig(emailObject) {
    return this.httpConfigurationService.create(
      emailObject,
      null,
      "/save/emailConfig"
    );
  }
}
