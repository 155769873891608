import { Annotation } from './annotation/annotation';
// import { ChartArea } from './chart-area/chart-area';
import { TextStyle } from './chart-legend/text-style';

export class ColumnChartConfig {
    backgroundColor?: string;
    bar?: Bar = new Bar();
    chartArea?: ChartArea = new ChartArea();
    chart?: Chart = new Chart();
    colors: string[] = [];
    dataOpacity: number;
    enableInteractivity: boolean;
    fontSize?: number;
    fontName?: number;
    hAxis?: HAxis = new HAxis();
    vAxis?: VAxis = new VAxis();
    height?: number;
    isStacked: boolean | string;
    title?: string;
    titleTextStyle: TextStyle = new TextStyle();
    width?: number;
    onSelect?: Function;
    // componentInstance: any;
    //in out none
    axisTitlesPosition?: string;
    legend?: Legend = new Legend();
    series?: any;
    annotations?: Annotation = new Annotation();
    bars?: any;
    tooltip?: any;
    vAxes?: any;
}

export class Bar {
    groupWidth: number | string;
}

export class ChartArea {
    left?: string | number;
    top?: string | number;
    width?: string | number;
    height?: string | number;
    backgroundColor?: string;
}

export class Chart {
    subtitle: string;
    title: string;
}

export class HAxis {
    baseline?: number;
    baselineColor?: number;
    direction = 1 | -1;
    format?: string;
    gridlines?: any;
    title: string;
    textStyle?: TextStyle = new TextStyle();
}

export class VAxis {
    baseline?: number;
    baselineColor?: number;
    direction = 1;
    format?: string;
    gridlines?: GridLines = new GridLines();
    title: string;
    textStyle?: TextStyle = new TextStyle();
    viewWindow?: {
        min?: number
        max?: number
    }
}

export class GridLines {
    color?: string;
    count?: number;
    units?: any;
    minorGridLines?: any;

}

export class Legend {
    position?: string;
    alignment?: string;
}