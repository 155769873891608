<!-- <hotkeys-cheatsheet></hotkeys-cheatsheet> -->
<div *ngIf="isLoading">
  <div class="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div
  class="layout-wrapper"
  [ngClass]="{ 'layout-compact': layoutCompact }"
  (click)="onLayoutClick()"
>
  <div
    #layoutContainer
    class="layout-container"
    [ngClass]="{
      'menu-layout-static': !isOverlay(),
      'menu-layout-overlay': isOverlay(),
      'layout-menu-overlay-active': overlayMenuActive,
      'menu-layout-horizontal': isHorizontal(),
      'menu-layout-slim': isSlim(),
      'layout-menu-static-inactive': staticMenuDesktopInactive,
      'layout-menu-static-active': staticMenuMobileActive
    }"
  >
    <app-topbar></app-topbar>

    <div
      class="layout-menu"
      [ngClass]="{ 'layout-menu-dark': darkMenu }"
      (click)="onMenuClick($event)"
    >
      <div #layoutMenuScroller class="nano">
        <div class="nano-content menu-scroll-content">
          <!-- <inline-profile *ngIf="profileMode=='inline'&&!isHorizontal()"></inline-profile> -->

          <sib-profile
            *ngIf="profileMode == 'inline' && !isHorizontal()"
          ></sib-profile>

          <!-- <app-menu [reset]="resetMenu"></app-menu> -->
          <sib-main-menu [reset]="resetMenu" [model]="model"></sib-main-menu>
        </div>
      </div>
    </div>

    <div class="layout-main">
      <sib-notifications></sib-notifications>
      <router-outlet></router-outlet>

      <!-- Parth Below footer is removed: -->
      <!-- <app-footer></app-footer> -->
    </div>

    <div class="layout-mask"></div>
  </div>
</div>
<!-- <sib-app-chat *permission="'enable:chat'"></sib-app-chat> -->
