export enum WebNotificationsBodyEnum {
    link = "Link",
    title = "Title",
    customId = "Custom Id",
    displayName = "Display Name",
    campaignStartDate = "Campaign Start Date",
    campaignEndDate = "Campaign End Date",
    companyName = "Company Name",
    salesRep = "Employee",
    item = "Item",
    oldRate = "Old Rate",
    newRate = "New Rate",
    invoiceId = "Invoice Id",
    reason = "Reason"
}

