import { AbstractInventory } from './abstract-inventory';
import { Expenses } from './expenses';

export class InventoryExpenses extends AbstractInventory {
    materialExpense: Expenses[] = [];
    corporateTax: Expenses[] = [];
    electricityExpense: Expenses[] = [];
    maintenanceExpense: Expenses[] = []
    miscellaneousExpense: Expenses[] = [];
    rent: Expenses[] = [];
}