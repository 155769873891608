<div class="tasks">

    <div *ngIf="isLoading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>

    <div class="ConfiguationContainer">

        <p-tabView (onChange)="onTabChange($event.index)" [activeIndex]="activeIndex">

            <p-tabPanel header="Unbilled ({{totalPendingUnbilledRecords}})" *permission="'read:pending-unbilled-tasks'" [selected]="activeIndex == 0">
                <div class="pending-unbilled-tasks-grid-scroller">
                    <sib-pending-unbilled-tasks *ngIf="activeIndex === activeIndex - checkTasksActiveIndex('read:pending-unbilled-tasks') " (eEmitTotalPendingUnbilledRecords)="setTotalPendingUnbilledRecords($event)">
                    </sib-pending-unbilled-tasks>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Invoice ({{totalPendingInvoiceRecords}})" *permission="'read:invoice-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:invoice-tasks')">
                <div class="ui-g-12" *ngIf="activeIndex  ===   checkTasksActiveIndex('read:invoice-tasks')">
                    <div class="ui-g-10">
                        <sib-task-cards *ngIf="invoiceTaskCards?.length > 0" [invoiceTaskCards]="invoiceTaskCards" (eEmitFilterSelected)="setCustomFilter($event)"></sib-task-cards>
                    </div>
                </div>
                <div class="ui-g-12 margin-top--70" *ngIf="activeIndex ===   checkTasksActiveIndex('read:invoice-tasks')">
                    <div class="invoice-tasks-grid-scroller">
                        <sib-superbill-tasks *ngIf="customFilter" [showEmployee]="showEmployee" [customFilter]="customFilter" (eEmitTotalPendingInvoiceRecords)="setTotalPendingInvoiceRecords($event)">
                        </sib-superbill-tasks>
                    </div>
                </div>
                <div *ngIf="activeIndex ===  checkTasksActiveIndex('read:invoice-tasks') && !customFilter" class="padding-left-5">
                    No Pending Tasks For User
                </div>
            </p-tabPanel>

            <p-tabPanel header="Campaign ({{totalPendingCampaignRecords}})" *permission="'read:campaign-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:campaign-tasks')">
                <div class="campaign-tasks-grid-scroller">
                    <sib-campaign-tasks *ngIf="activeIndex ===   checkTasksActiveIndex('read:campaign-tasks')" (eEmitTotalPendingCampaignRecords)="setTotalPendingCampaignRecords($event)">
                    </sib-campaign-tasks>
                </div>
            </p-tabPanel>

            <!-- <p-tabPanel header="Invoice ({{totalPendingSrRecords}})" *permission="'read:sr-tasks'">
                <div class="SR-tasks-grid-scroller">
                    <sib-sr-tasks *ngIf="activeIndex ===   checkTasksActiveIndex('read:sr-tasks')"
                        [showEmployee]="showEmployee" (eEmitTotalPendingSrRecords)="setTotalPendingSrRecords($event)">
                    </sib-sr-tasks>
                </div>
            </p-tabPanel> -->
            <p-tabPanel header="Credit Notes ({{totalPendingCreditNotesRecords}})" *permission="'read:cn-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:cn-tasks')">
                <div class="ui-g-12" *ngIf="activeIndex ===   checkTasksActiveIndex('read:cn-tasks')">
                    <div class="ui-g-10">
                        <sib-credit-notes-tasks-cards *ngIf="creditNotesTaskCards?.length > 0" [creditNotesTaskCards]="creditNotesTaskCards" (eEmitFilterSelected)="setCustomFilter($event)"></sib-credit-notes-tasks-cards>
                    </div>
                </div>
                <div class="ui-g-12" *ngIf="activeIndex ===  checkTasksActiveIndex('read:cn-tasks')">
                    <sib-credit-notes-tasks *ngIf="creditNotesTaskCards?.length > 0" [showEmployee]="showEmployee" [customFilter]="customFilter" (eEmitTotalPendingCreditNoteTaskRecords)="setTotalPendingCreditNotesRecords($event)" (eEmitActionResult)="isActionPerformedSuccess($event)"></sib-credit-notes-tasks>
                </div>
                <div *ngIf="creditNotesTaskCards?.length === 0" class="padding-left-5">
                    No Pending Tasks For User
                </div>
            </p-tabPanel>

            <p-tabPanel header="Conflict ({{tasksCards.length !== 0 ? totalPendingTasks : tasksCards.length}})" *permission="'read:pending-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:pending-tasks')">
                <div class="ui-g-12">
                    <div class="ui-g-10" *ngIf="activeIndex ===  checkTasksActiveIndex('read:pending-tasks')">


                        <sib-pending-tasks-cards *ngIf="tasksCards?.length > 0" [tasksCards]="tasksCards" (eEmitSelectedTask)="setTask($event)" (eEmitModuleName)="getModuleName($event)">
                        </sib-pending-tasks-cards>
                    </div>
                </div>
                <div class="ui-g-12 margin-top--70" *ngIf="activeIndex ===  checkTasksActiveIndex('read:pending-tasks')">

                    <div class="invoice-tasks-grid-scroller">
                        <sib-pending-tasks *ngIf="tasksCards?.length > 0" [moduleName]="moduleName" (eEmitActionPerformed)="isActionPerformed($event)" [showEmployee]="showEmployee" (eEmitPendingTasks)="getPendingTasks($event)">
                        </sib-pending-tasks>
                    </div>
                </div>
                <div *ngIf="tasksCards?.length === 0" class="padding-left-5">
                    No Pending Tasks For User
                </div>
            </p-tabPanel>
            <p-tabPanel header="Expiring Sites ({{totalSitesExpirationTaskRecords}})" *permission="'read:sites-expiring-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:sites-expiring-tasks')">
                <div class="campaign-tasks-grid-scroller" *ngIf="activeIndex ===   checkTasksActiveIndex('read:sites-expiring-tasks')">
                    <sib-site-expiration-tasks (eEmitTotalPendingExpirationSites)="setTotalPendingSiteExpiration($event)">
                    </sib-site-expiration-tasks>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Rate Approval ({{totalRatePendingTasksRecords}})" *permission="'read:rate-approval-tasks'" [selected]="activeIndex == checkTasksActiveIndex('read:rate-approval-tasks')">
                <div class="campaign-tasks-grid-scroller" *ngIf="activeIndex === checkTasksActiveIndex('read:rate-approval-tasks')">
                    <sib-rate-approval-tasks (eEmitTotalRateApprovalTasks)="setTotalRatePendingRecords($event)">
                    </sib-rate-approval-tasks>
                </div>
            </p-tabPanel>



        </p-tabView>

        <!-- <ng-container
            *ngIf="!(checkPermission('read:pending-unbilled-tasks')) && !(checkPermission('read:invoice-tasks')) && !(checkPermission('read:campaign-tasks')) && !(checkPermission('read:sr-tasks'))">
            <div class="no-tab">
                No tab permission assigned to the user
            </div>
        </ng-container> -->

    </div>

</div>