import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class HoardingMasterColService {
  hoardingSelectedColumns: Subject<any[]> = new Subject();
  kioskSelectedColumns: Subject<any[]> = new Subject();
  pillarSelectedColumns: Subject<any[]> = new Subject();
  bqsSelectedColumns: Subject<any[]> = new Subject();
  gantrySelectedColumns: Subject<any[]> = new Subject();
  pickupStandSelectedColumns: Subject<any[]> = new Subject();
  unipoleSelectedColumns: Subject<any[]> = new Subject();
  signboardSelectedColumns: Subject<any[]> = new Subject();
  utilitySelectedColumns: Subject<any[]> = new Subject();
  ledSelectedColumns: Subject<any[]> = new Subject();
  selectedColumns: Subject<any[]> = new Subject();

  hoardingDefaultColumns: Subject<any[]> = new Subject();
  kioskDefaultColumns: Subject<any[]> = new Subject();
  pillarDefaultColumns: Subject<any[]> = new Subject();
  bqsDefaultColumns: Subject<any[]> = new Subject();
  gantryDefaultColumns: Subject<any[]> = new Subject();
  pickupStandDefaultColumns: Subject<any[]> = new Subject();
  unipoleDefaultColumns: Subject<any[]> = new Subject();
  signboardDefaultColumns: Subject<any[]> = new Subject();
  utilityDefaultColumns: Subject<any[]> = new Subject();
  ledDefaultColumns: Subject<any[]> = new Subject();

  defaultColumns: Subject<any[]> = new Subject();

  siteFromDate: Subject<Date> = new Subject();
  statuses: Subject<any[]> = new Subject();
  globalFilter: Subject<any> = new Subject();
  monthlyRateValues: Subject<any> = new Subject();
}
