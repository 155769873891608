<div class="capacity-chart-container">
  <div [ngClass]="{'transparent-background': areaChartLoader}">
    <div *ngIf="areaChartLoader" class="loader">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="ui-g header-text" *ngIf="showTitle">
      <h2 class="margin-0 header-text__description">{{title}}</h2>
    </div>

    <div>
      <div>
      <ul class="cinline-block float-right">

        <!-- <li>
          <i style="cursor: pointer;" class="fa fa-download" title="Download XLSX" (click)="downloadXLSX()"></i>
         </li> -->

        <li class="input-switch" *ngIf="graphCustomization">
          <p-inputSwitch name='capacityChartInputSwitch' [(ngModel)]="capacityChartVisibility"
          (onChange)="onCapacityChartVisibilityChange($event)">
        </p-inputSwitch>
        </li>

       

        <!-- [maxDate]="eDate.value" -->
        <li class="sites-from-text">
          <div>
            Start Date
          </div>
        </li>
        <li class="margin-top-5">
          <p-calendar
          [styleClass]="'site-from-button-hm'"
          dateFormat="ddMy"
          appendTo="body"
          placeholder=" Ex-01Jan18"
          (onInput)="onDateSelection($event,true)"
          (onSelect)="onDateSelection($event,true)"
          [(ngModel)]="startDate"
          [utc]="true"
          [showIcon]="true"
          [disabled]="graphCustomization"      
          #stDate="ngModel"
          name="stDate"
        ></p-calendar>
        </li>

        <li class="sites-from-text">
          <div>
            End Date
          </div>
        </li>
        <li class="margin-top-5">
          <p-calendar
          [styleClass]="'site-from-button-hm'"
          dateFormat="ddMy"
          appendTo="body"
          [disabled]="!startDate || graphCustomization"
          placeholder=" Ex-01Jan18"
          (onInput)="onDateSelection($event,false)"
          (onSelect)="onDateSelection($event,false)"
          [(ngModel)]="endDate"
          [utc]="true"
          [showIcon]="true"
          [minDate]="stDate.value"
          #eDate="ngModel"
          name="eDate"
        ></p-calendar>
        </li>

        <li>
          <div> Select City(s)</div>
        </li>
        <li>
          <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
          (onPanelHide)="handleSelectedCities(selectedCities)" (onPanelShow)="filterCities()" [resetFilterOnHide]="true"
          selectedItemsLabel="{0} cities selected" defaultLabel="Choose" [disabled]="graphCustomization">
        </p-multiSelect>
        </li>

        <li>
          <div class="flex-action-item padding-left-5">
            <label class="font-12 font-bold label-vCenter padding-right-5">Export XLSX</label>
            <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" style="font-size:10px;margin-bottom:0.7rem"
              title="Export Xlsx" icon="fa fa-file-excel-o" (click)="downloadXLSX()"></button>
          </div>
        </li>

      </ul>
    </div>
    <div style="display:flex;justify-content: start;align-items: center;margin-left: 2rem;margin-top:1rem">
      <span style="font-weight: bold;margin-right:1rem">Utilization Percentage By: </span>
       <p-dropdown
      [options]="chartTypeOptions"
      name="chartType"
      [(ngModel)]="selectedChartType"
      (onChange)="setRequestData()"
    ></p-dropdown>
  </div>
    </div>


    <!-- <div class="float-right input-switch" *ngIf="graphCustomization">
      <p-inputSwitch name='capacityChartInputSwitch' [(ngModel)]="capacityChartVisibility"
        (onChange)="onCapacityChartVisibilityChange($event)">
      </p-inputSwitch>
    </div>
    <div class="multiselect-container">
      <span class="city-label"> Select City(s)</span>
      <div style="position: relative; top: 15px; ">
        <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
          (onPanelHide)="handleSelectedCities(selectedCities)" (onPanelShow)="filterCities()"
          selectedItemsLabel="{0} cities selected" defaultLabel="Choose" [disabled]="graphCustomization">
        </p-multiSelect>
      </div> -->

    <div>
    <sib-chart class="area-chart" #sibAreaChart *ngIf="areaChartData.length > 0 " [data]="areaChartData"
      [areaChartConfig]="areaChartConfig" [elementId]="areaElementId" [chartType]="'areaChart'"
      (eEmitAreaChartLoaded)="areaChartLoader = false"></sib-chart>
    </div>

    <!-- (eEmitAreaChartLoaded)="areaChartLoader = false" -->

    <div *ngIf="viewCapacityChartDrilldown && showAction">
      <sib-capacity-chart-drilldown #capacityChartDrilldown [viewCapacityChartDrilldown]="viewCapacityChartDrilldown"
        [clickedData]="chartClickedData" [isCapacityChartClicked]="isCapacityChartClicked"
        (eEmitIsCapacityChartClicked)="getIsCapacityChartClicked($event)">
      </sib-capacity-chart-drilldown>
    </div>
  </div>
</div>