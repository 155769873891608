/**
* @file datatable.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { SelectItem } from "primeng/primeng";
import { CampaignItem } from "../../../modals/campaigns/campaignItem";

@Component({
    selector: 'sib-common-data-table',
    templateUrl: './dataTable.component.html',
    styleUrls: ['./dataTable.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CommonDataTableComponent implements OnInit {
    @Input() values: any[] = [];
    @Input() columns: any[] = [];
    @Input() paginator = false;
    @Input() showFilter = false;
    @Input() showColorLegends = false;
    @Input() selectionMode = 'null';
    @Input() loading = true;
    @Input() selected: any;
    @Input() monitoringCampaignItem: CampaignItem[] = [];

    @Output() eEmitRemoveIndex: EventEmitter<any> = new EventEmitter();
    @Output() eEmitRemoveInventory: EventEmitter<any> = new EventEmitter();
    @Output() eEmitViewItemDetail: EventEmitter<any> = new EventEmitter();
    @Output() eEmitRemovePlanHoarding: EventEmitter<any> = new EventEmitter();
    @Output() eEmitOnRowSelect: EventEmitter<any> = new EventEmitter();
    @Output() eEmitRemoveMonitoringItem: EventEmitter<any> = new EventEmitter();
    @Output() eEmitEditMonitoringItem: EventEmitter<any> = new EventEmitter();

    selectedColumn: any[] = [];
    totalColumns: any[] = [];
    modifiedColumn: SelectItem[] = [];
    columnsToDisplay: any[] = [];
    filterValue: any[] = [];
    displayPhotoDialog = false;
    imageData: any;
    displayStatusQueueDialog = false;
    email: string;
    authorized = false;
    newValues: any[] = [];
    // selected: any;  // for selection binding of datatable
    newColumns = [  // passing value of datatable columns for status queue dialog 
        { name: 'customId', style: { 'width': '4vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Id', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'companyName', style: { 'width': '4vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Company Name', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'displayName', style: { 'width': '4vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Display Name', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'employee', style: { 'width': '4vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Employee', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        // { name: 'campaignStartDate', style: { 'width': '6vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Campaign Start', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        // { name: 'campaignEndDate', style: { 'width': '6vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Campaign End', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'campaignStartDate', style: { 'width': '6vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Site Start Date', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'campaignEndDate', style: { 'width': '6vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Site End Date', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: true },
        { name: 'status', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Status', filter: false, filterMatchMode: 'contains', source: 'dataTable', type: 'text', hidden: false, permanent: true, sortable: false },
    ];

    constructor(
        private router: Router
    ) { }

    ngOnInit() {

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const roles = userInfo[0].app_metadata.authorization.roles;
        const permissions = userInfo[0].app_metadata.authorization.permissions;

        this.email = userInfo[0].email;

        roles.forEach((role) => {
            if (role === "admin" || role === "accountant") {
                this.authorized = true;
            }
        })

        for (let i = 0; i < this.columns.length; i++) {
            if ((this.columns[i].hidden === false || !this.columns[i].hidden) && (this.columns[i].permanent === false || !this.columns[i].permanent)) {
                this.modifiedColumn.push({ label: this.columns[i].header, value: this.columns[i].header });
                this.totalColumns.push(this.columns[i]);
                if ((this.columns[i].default === true) || (!this.columns[i].default)) {
                    if (this.columns[i].default !== false) {
                        this.selectedColumn.push(this.columns[i].header);
                    }
                }
            }
        }
        this.setColumnsToDisplay();
    }
    /**
     * setting columns to display for data table
     * 
     * @memberof CommonDataTableComponent
     */
    setColumnsToDisplay() {
        if (this.showFilter === false) {
            this.columnsToDisplay = this.columns.filter((column) => {
                if (column.hidden === false || !column.hidden) {
                    return column;
                }
            });
        } else {
            this.totalColumns.forEach((col) => {
                col.hidden = false;
            });
            for (let i = 0; i < this.totalColumns.length; i++) {
                this.filterValue = [];
                for (let j = 0; j < this.selectedColumn.length; j++) {
                    if (this.totalColumns[i].header === this.selectedColumn[j]) {
                        this.filterValue.push(i);
                    }
                }
                if (this.filterValue.length === 0) {
                    this.totalColumns[i].hidden = true;
                }
            }
            this.columnsToDisplay = this.columns.filter((col) => {
                if (col.hidden === false) {
                    return col;
                }
            });
        }
    }


    /**
     * filtering the data table 
     * 
     * @param {any} value 
     * @param {any} name 
     * @param {any} filterMatchMode 
     * @param {any} dt 
     * @param {any} col 
     * @memberof CommonDataTableComponent
     */
    filter(value, name, filterMatchMode, dt, col) {
        dt.filter(value, name, filterMatchMode);
    }

    filterByStatus(dt, value) {
        const name = 'status', filterMatchMode = 'equals';
        dt.filter(value, name, filterMatchMode);
    }


    /**
     * remove hoarding from selected values in hoarding component
     * 
     * @param {any} index 
     * @memberof CommonDataTableComponent
     */
    removeHoarding(item, index) {
        this.eEmitRemoveIndex.emit(index);
        this.eEmitRemoveInventory.emit({ rowData: item, rowIndex: index });
    }


    /**
     * remove hoarding/ group from plan 
     * 
     * @param {any} rowData 
     * @memberof CommonDataTableComponent
     */
    removePlanHoarding(rowData) {
        this.eEmitRemovePlanHoarding.emit(rowData);
    }


    /**
     * emitting value to navigate to the details link
     * 
     * @param {any} rowData 
     * @memberof CommonDataTableComponent
     */
    viewItemDetail(rowData) {
        this.eEmitViewItemDetail.emit(rowData);
    }

    viewPhotos(rowData) {
        this.imageData = rowData;
        this.displayPhotoDialog = true;
    }

    changePhotoDisplay($event) {
        this.displayPhotoDialog = false;
    }

    /**
     * showing the details of status queue
     * 
     * @param {any} rowData 
     * @memberof CommonDataTableComponent
     */
    viewDetail(rowData) {
        this.displayStatusQueueDialog = true;
        this.newValues = rowData.statusQueues;
    }


    /**
     * navigate to plan / campaign from the status queue 
     * 
     * @param {any} rowData 
     * @memberof CommonDataTableComponent
     */
    viewNewItemDetail(rowData) {
        if (rowData.status.indexOf("Blocked") !== undefined) {
            this.router.navigate(['/plans/view/' + rowData.id]);
        } else {
            // console.log("id of campaign is", rowData.id);
        }
    }

    hideStatusQueueDialog() {
        this.displayStatusQueueDialog = false;
    }

    onRowSelect(event) {
        this.eEmitOnRowSelect.emit(event.data);
    }

    removeMonitoringItem(rowData, rowIndex) {
        this.eEmitRemoveMonitoringItem.emit(rowIndex);
    }

    editMonitoringItem(rowData, rowIndex) {
        this.eEmitEditMonitoringItem.emit(rowIndex);
    }

}
