import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { User } from "../../modals/user/user";
import { MenuItem } from "primeng/primeng";
import { EmailConfigService } from "../../services/shared/email-config.service";
import { AuthService } from "../../login-service/auth.service";

@Component({
  selector: "sib-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild("sibpassword") password: ElementRef;
  @ViewChild("confirmpassword") confirmPassword: ElementRef;
  /**
   * toggling display and hide of dialog
   *
   * @type {boolean}
   * @memberof UserDetailsComponent
   */
  @Input() display: boolean = false;

  /**
   * user object
   *
   * @type {User}
   * @memberof UserDetailsComponent
   */
  @Input() user: User = new User();

  @Input() isloading: boolean = false;

  /**
   * Emitting the save Event
   *
   * @type {EventEmitter<User>}
   * @memberof UserDetailsComponent
   */
  @Output() eSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  smtpEdit: boolean = false;
  stepperItems: MenuItem[];
  imageName: string = "assets/layout/images/customer-image.png";
  activeIndex: number = 0;
  emailSelected: any;
  isUserEmailVerified: boolean = false;
  confirmPasswordValue: string;
  sibConfirmPassword: string;
  sibPassword: string;
  sibPasswordVerified: boolean = false;
  organizationEmail: string;
  emailConfigPassword: string;
  emptyRegex = /^$/;
  passwordPolicy = `1. Maximum Characters should be 15. \n2. Should be at least 8 characters long. \n3. Should be alphanumeric. \n4. Should have one capital alphabet and one small alphabet. \n5. Should contain atleast one special character which includes (@,#,$,%,^,&,*).`;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  //   ^	The password string will start this way
  // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
  // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
  // (?=.*[0-9])	The string must contain at least 1 numeric character
  // (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  // (?=.{8,})	The string must be eight characters or longer

  constructor(
    private emailConfigService: EmailConfigService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.stepperItems = [
      { label: "Verify Information" },
      { label: "System credentials" },
      { label: "Your Email Setup" },
    ];

    this.emailConfigService
      .get(null, "/search/emailConfig")
      .subscribe((emailConfig) => {
        this.organizationEmail = emailConfig["data"]["username"];
      });
  }

  ngAfterViewInit() {
    if (this.user.organizationEmail) {
      this.emailSelected = "orgEmail";
    } else {
      this.emailSelected = "userEmail";
    }
  }

  saveUser(user) {
    if (this.emailSelected === "orgEmail") {
      user.organizationEmail = true;
    } else {
      user.organizationEmail = false;
      user.emailConfig.username = this.user.emailId;
      user.emailConfig.password = this.emailConfigPassword;
    }

    this.isloading = true;
    this.eSave.emit({ user: user, password: this.sibPassword });
  }

  onShowDialog() {}

  onHideDialog() {
    this.isloading = false;
    this.eEmit.emit(false);
  }

  onNextClick() {
    this.activeIndex = this.activeIndex + 1;
  }

  onPreviousClick() {
    this.activeIndex = this.activeIndex - 1;
  }

  verifyUserPassword(value) {
    if (value === this.emailConfigPassword) {
      this.isUserEmailVerified = true;
    } else {
      this.isUserEmailVerified = false;
    }
  }

  verifySibPassword() {
    if (this.sibPassword === this.sibConfirmPassword) {
      this.sibPasswordVerified = true;
    } else {
      this.sibPasswordVerified = false;
    }
  }

  logout() {
    this.auth.logout();
  }
  
  togglePasswordVisibility(valueType) {
    if (valueType === "confirmPassword") {
      if (this.confirmPassword.nativeElement.type === "password") {
        this.confirmPassword.nativeElement.type = "text";
      } else {
        this.confirmPassword.nativeElement.type = "password";
      }
    } else {
      if (this.password.nativeElement.type === "password") {
        this.password.nativeElement.type = "text";
      } else {
        this.password.nativeElement.type = "password";
      }
    }
  }
}
