export class TenantList {
  CHITRA_B = "SIB020";
  ABHIK = "SIB005";
  SITEMAX = "SIB019";
  MANTRA = "SIB026";
  PRIYA = "SIB027";
  GAC = "SIB022";
  DIYA = "SIB021";
  COCONUT = "SIB023";
  SARJAK = "SIB025";
  SUNOUTDOOR = "SIB024";
}
