import { NgModule, forwardRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { SharedModule, InputTextModule, ButtonModule, TooltipModule, TabViewModule, TieredMenuModule, DialogModule, MultiSelectModule, DropdownModule, ProgressSpinnerModule, CalendarModule } from "primeng/primeng";
import { SibGridModule } from "../components/sib-forms/grid/grid.module";
import { MapModule } from '../shared/components/map/map.module';
import { PhotoDialogModule } from '../shared/components/photo-dialog/photo-dialog.module';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { InventoryExpenseService } from '../services/shared/inventory-expenses.service';
import { ExpensesBreakupComponent } from './expenses-breakup/expenses-breakup.component';
import { MounterReportComponent } from './mounter-report/mounter-report.component';
import { ViewInventoryModule } from '../shared/components/view-inventory/view-inventory.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        SibGridModule,
        TabViewModule,
        TieredMenuModule,
        DialogModule,
        MultiSelectModule,
        DropdownModule,
        ProgressSpinnerModule,
        MapModule,
        PhotoDialogModule,
        ReportsRoutingModule,
        CalendarModule,
        ViewInventoryModule,
    ],

    declarations: [
        ReportsComponent,
        ExpenseReportComponent,
        ExpensesBreakupComponent,
        MounterReportComponent

    ],

    providers: [
        InventoryExpenseService
    ],

    exports: [
        ReportsComponent,
        ExpenseReportComponent
    ]
})
export class ReportsModule { }
