import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Http, Response } from "@angular/http";
import { ApiService } from '../../services/base-service/api.service';
import { Subject } from 'rxjs';

@Injectable()
export class PackagesService extends ApiService<any, any, any> {
    urlPath: string = 'packages';
    $packageSubscribed: Subject<any> = new Subject;
    $subscriptionActive: Subject<any> = new Subject;

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

}