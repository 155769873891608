import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MenuItem, SelectItem } from "primeng/primeng";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ActionDispatcher } from "../../app state/action-dispatcher/action-dispatcher";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import {
  GridConfig,
  GridPaginationEvent,
  SelectionMode,
} from "../../components/sib-forms/grid/grid.config";
import { ContractUiService } from "../../contracts/services/contract-ui.services";
import { ErrorUtil } from "../../helpers/error.utils";
import * as utils from "../../helpers/utils";
import { HoardingGroupsComponent } from "../../hoarding-groups/hoarding-groups.component";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";
import { Contracts } from "../../modals/contracts/contracts";
import { HoardingGroup } from "../../modals/hoardings/hoardings-group";
import { Inventory } from "../../modals/hoardings/inventory";
import { ImageDataDisplay } from "../../modals/image/image-data";
import { ImageFolderConfig } from "../../modals/image/image-folder-config";
import { ImportHoardingConfig } from "../../modals/import-hoarding/import-hoarding-config";
import { LoaderSubjects } from "../../modals/loader-subjects/loader-subjects";
import { PPTLoader } from "../../modals/loader-subjects/pptloader";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { AreaListService } from "../../services/shared/areaList.service";
import { AreaListGetService } from "../../services/shared/areaListGet.service";
import { CreateHoardingService } from "../../services/shared/createHoarding.service";
import { FirebaseService } from "../../services/shared/firebase.service";
import { HoardingMasterColService } from "../../services/shared/hoarding-master-column.service";
import { InventoryService } from "../../services/shared/inventory.service";
import { KioskService } from "../../services/shared/kiosk.service";
import { NotificationsMessages } from "../../services/shared/notifications-messages";
import { SystemService } from "../../services/shared/system.service";
import { UploadDocumentService } from "../../services/shared/upload-document.service";
import { AppUrls } from "../../services/urls";
import { CommonHoardingsComponent } from "../../shared/components/common-hoardings/common-hoardings.component";
import { CommonInventoryGridComponent } from "../../shared/components/common-inventory-grid/common-inventory-grid.component";
import { CommonKiosksComponent } from "../../shared/components/common-kiosks/common-kiosks.component";
import { HoardingDialogComponent } from "../../shared/components/hoarding-dialog/hoarding-dialog.component";
import { PhotoDialogComponent } from "../../shared/components/photo-dialog/photo-dialog.component";
import { ContractStatusEnum } from "../../shared/constants/contract-status-enum";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { HoardingStatusEnum } from "../../shared/constants/hoarding-status-enum";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { ContractsActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { NewContractComponent } from "../new-contract/new-contract.component";
import { ContractService } from "../services/contract.services";
import { HoardingService } from "../services/hoardings.service";

export type GridDataRequestEvent = any;

export type GridDataResponse<T> = any;

export class TreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: TreeNode[] = [];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: TreeNode;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;
}

@Component({
  selector: "sib-contract-hoarding",
  templateUrl: "./contract-hoarding.component.html",
  styleUrls: ["./contract-hoarding.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContractHoardingComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() displayCreateHoarding: boolean = false;
  @Input() editMode: boolean;
  @Input() hoardingData: any;
  @Input() displayPhotoDialog: boolean = false;

  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() editReset: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<ContractHoardings>;

  // @ViewChild(ListHoardingsComponent)
  // hoardingListChildRef: ListHoardingsComponent;

  @ViewChild(CommonHoardingsComponent)
  hoardingListChildRef: CommonHoardingsComponent;

  // @ViewChild(KiosksComponent)
  // kioskChildRef: KiosksComponent

  @ViewChild(CommonKiosksComponent)
  kioskChildRef: CommonKiosksComponent;

  @ViewChild("pillarComponent") pillarChildRef: CommonInventoryGridComponent;

  @ViewChild("bqsComponent") bqsChildRef: CommonInventoryGridComponent;

  @ViewChild("gantryComponent") gantryChildRef: CommonInventoryGridComponent;

  @ViewChild("pickupStandComponent")
  pickupStandChildRef: CommonInventoryGridComponent;
  @ViewChild("unipoleComponent") unipoleChildRef: CommonInventoryGridComponent;
  @ViewChild("signboardComponent")
  signboardChildRef: CommonInventoryGridComponent;
  @ViewChild("utilityComponent")
  utilityChildRef: CommonInventoryGridComponent;
  @ViewChild("ledComponent") ledChildRef: CommonInventoryGridComponent;
  /**
   * Creating a object of childComponent to access its function
   *
   * @type {NewContractComponent}
   * @memberof ContractsListComponent
   */
  @ViewChild(NewContractComponent)
  contractForm: NewContractComponent;

  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  @ViewChild(HoardingDialogComponent)
  hoardingDialogComponent: HoardingDialogComponent;

  @ViewChild(HoardingGroupsComponent)
  hoardingGroupChildRef: HoardingGroupsComponent;

  // contractHoardingGroup: FormGroup;
  contract: Contracts = new Contracts();
  hoardingGroup: HoardingGroup = new HoardingGroup();
  newHoarding: ContractHoardings = new ContractHoardings();
  displayHoardingSearch: boolean = false;
  display: boolean = false;
  displayClubHoarding: boolean = false;
  isGroupSaved: boolean = false;
  treeTableCols: any[] = [];
  hoardingGroups: HoardingGroup[] = [];
  treeData: TreeNode[] = [];
  hoardingListColumns: any[] = [];
  imageData: any;
  gridConfig: GridConfig<Inventory> = new GridConfig<Inventory>();
  displayContractForm: boolean = false;
  title: string;
  loading: boolean = false;
  contractId: any;
  numberOfHoardings: any;
  editHoardingMode = false;
  editHoardingData: ContractHoardings = new ContractHoardings();
  rowData: any;
  displayStatusQueueDialog = false; // status queue dialog
  statusStyle: any; //style object for contract status
  importHoardingConfig: ImportHoardingConfig = new ImportHoardingConfig();
  activeIndex: number = 0;
  kioskGridConfig: GridConfig<Inventory> = new GridConfig<Inventory>();
  kioskListColumns: any[] = [];
  mediaType: string;
  columns: SelectItem[] = [];
  selectedColumns: any[] = [];
  subscription: Subscription[] = [];
  totalHoardings: number;
  totalGroups: number;
  numberOfGroups: number;
  totalKiosks: number;
  numberOfKiosks: number;
  importTypes: MenuItem[] = [];
  displayImportFromCsv: boolean = false;
  csvFile: File;
  hoardingTemplateUrl: string;
  hoardingGroupColumns: any[] = [];
  selectedStatus: any[] = [];
  status: SelectItem[] = []; // status drop down
  createHoardingMode: boolean = false;
  displayUploadDocDialog: boolean = false;
  imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();

  isFileDownloading = false;
  searchEvent: GridPaginationEvent;
  hoardingSearchEvent: GridPaginationEvent;
  hoaridngGroupSearchEvent: GridPaginationEvent;
  kioskSearchEvent: GridPaginationEvent;
  xlsLoader: PPTLoader = new PPTLoader();
  resetXlsLoader: PPTLoader = new PPTLoader();
  isExpired: boolean = false;
  expiredInventory: number = 0;
  hoardings: any[] = [];
  kiosks: any[] = [];

  displaySetupIncompleteDialog: boolean = false;
  importInformation: boolean = false;

  hideCommonInventoryDialog: boolean = false;

  /**
   * @description for inventory dialog
   * @type {boolean}
   * @memberof ContractHoardingComponent
   */
  displayCreateInventory: boolean = false;

  /**
   * @description list of media types
   * @type {*}
   * @memberof ContractHoardingComponent
   */
  mediaTypes: any;

  showParentSites: boolean = false;

  mediaTypesMap: Map<number, string> = new Map();

  showHoardingGroups = false;
  showKioskGroups = false;
  showPillarGroups = false;
  showBQSGroups = false;
  showGantryGroups = false;
  showPickupStandGroups = false;
  showUnipoleGroups = false;
  showSignboardGroups = false;
  showUtilityGroups = false;
  showLedGroups = false;

  pillarColumns: any[];
  totalPillars: number;
  numberOfPillars: number;

  /**
   * @description  BQS media variables
   * @type {any[]}
   * @memberof ContractHoardingComponent
   */
  bqsColumns: any[];
  totalBQS: number;
  numberOfBQS: number;

  /**
   * @description Gantry media variables
   * @type {any[]}
   * @memberof ContractHoardingComponent
   */
  gantryColumns: any[];
  totalGantry: number;
  numberOfGantry: number;

  pickupStandColumns: any[];
  totalPickupStands: number;
  numberOfPickupStands: number;

  unipoleColumns: any[];
  totalUnipoles: number;
  numberOfUnipoles: number;

  signboardColumns: any[];
  totalSignboards: number;
  numberOfSignboards: number;

  utilityColumns: any[];
  totalUtilities: number;
  numberOfUtilities: number;

  ledColumns: any[];
  totalLeds: number;
  numberOfLeds: number;

  mediaTypeKeyValueMap: Map<string, string> = new Map();
  contractStatus: string = "";

  showXlsExport: boolean = false;

  constructor(
    public actionDispatcher: ContractsActionDispatcher,
    private appActionDispatcher: ActionDispatcher,
    public contractService: ContractService,
    private _route: ActivatedRoute,
    private areaListGetService: AreaListGetService,
    private createHoardingService: CreateHoardingService,
    private router: Router,
    private areaListService: AreaListService,
    private firebaseService: FirebaseService,
    private element: ElementRef,
    private hoardingService: HoardingService,
    private cd: ChangeDetectorRef,
    private currencyHelperPipe: CurrencyHelperPipe,
    private kioskService: KioskService,
    private hmColService: HoardingMasterColService,
    private notificationServcie: NotificatoinsService,
    private contractUiService: ContractUiService,
    private pageTitle: Title,
    private uploadDocumentService: UploadDocumentService,
    private loaderSubjects: LoaderSubjects,
    private inventoryService: InventoryService,
    private systemService: SystemService
  ) {
    // this.actionDispatcher.resetContract();
  }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle
      ? this.pageTitle.setTitle(SIBConstants.CONTRACT_DETAILS)
      : this.pageTitle.setTitle(
          SIBConstants.CONTRACT_DETAILS + " - " + appTitle
        );

    //Raveena: get the media types available
    this.getMediaTypes();
    this.showXlsExport = utils.checkPermission("enable:excel") ? true : false;
    this.setFilterDropdowns();
    this.hoardingListColumns = [
      "customId",
      // 'district',
      "city",
      "location",
      "size",
      "height",
      "width",
      "size",
      "quantity",
      "startDate",
      "endDate",
      "minimumPrice",
      "grossPrice",
      "image",
      "statusQueues",
      "statusQueues.displayName",
      "statusQueues.employee",
      "areaName",
      "lightType",
      "id",
      "documents",
      "upload",
      "authorityTax",
      "inventoryType",
    ];

    this.setHoardingGridConfig();

    this.hoardingGroupColumns = [
      "customId",
      "contract.id",
      "geoCoordinate.latitude",
      "geoCoordinate.longitude",
      "district",
      "city",
      "areaName",
      "location",
      "width",
      "height",
      "size",
      "squareFeet",
      "category",
      "lightType",
      "grossPrice",
      "minimumPrice",
      "startDate",
      "endDate",
      "campaignStartDate",
      "campaignEndDate",
      "statusQueues",
      "statusQueues.displayName",
      "statusQueues.employee",
      "image",
      "id",
    ];

    this.kioskListColumns = [
      "customId",
      // 'district',
      "city",
      "location",
      "startDate",
      "endDate",
      "width",
      "height",
      "size",
      "quantity",
      "minimumPrice",
      "grossPrice",
      "image",
      "statusQueues",
      "statusQueues.displayName",
      "statusQueues.employee",
      "areaName",
      "lightType",
      "note",
      "id",
      "documents",
      "upload",
      "authorityTax",
      "inventoryType",
    ];

    this.pillarColumns = [
      "customId",
      // 'district',
      "city",
      "location",
      "startDate",
      "endDate",
      "width",
      "height",
      "quantity",
      "size",
      "minimumPrice",
      "grossPrice",
      "image",
      "statusQueues",
      "statusQueues.displayName",
      "statusQueues.employee",
      "areaName",
      "lightType",
      "note",
      "id",
      "documents",
      "upload",
      "inventoryType",
      "authorityTax",
    ];

    this.bqsColumns = this.pillarColumns;

    this.gantryColumns = this.pillarColumns;

    this.pickupStandColumns = this.pillarColumns;

    this.unipoleColumns = this.pillarColumns;
    this.signboardColumns = this.pillarColumns;
    this.utilityColumns = this.pillarColumns;
    this.ledColumns = this.pillarColumns;

    this.setKioskGridObjectConfig();

    this.subscription.push(
      this.actionDispatcher.contractById.subscribe((contract) => {
        this.contract = contract;
        this.getStatus();
        // this.cd.detectChanges();
        if (this.contractId) {
          // this.actionDispatcher.getGroupsByContractId(this.contractId);
          this.contractExpiryCheck();
        }
      })
    );

    this.subscription.push(
      this._route.params.subscribe((params: { contractId: string }) => {
        this.contractId = params.contractId;
        if (!this.contractId) {
          this.router.navigate(["/contracts"]);
        } else {
          this.changeHoardingGroupGridConfig();
          this.getUserColumnConfig();
          this.actionDispatcher.getContractById(this.contractId);
        }
      })
    );

    this.subscription.push(
      this.actionDispatcher.isHoardingsAddedToContract.subscribe(
        (isHoardingsAddedToContract) => {
          if (isHoardingsAddedToContract) {
            this.displayHoardingSearch = false;
            this.refreshAllGrids();
            this.actionDispatcher.resetHoardingAddedToContract();
          }
        }
      )
    );

    this.subscription.push(
      this.actionDispatcher.getContractByIdFailedFlag.subscribe(
        (contractByIdFailedFlag) => {
          if (contractByIdFailedFlag === true) {
            this.loading = false;
            this.router.navigate(["/contracts"]);
          }
        }
      )
    );

    this.subscription.push(
      this.actionDispatcher.hoardingGroups.subscribe((hoardingGroups) => {
        this.hoardingGroups = hoardingGroups;
        /* if(hoardingGroups.length > 0) {
        this.cd.detectChanges();
      } */
        if (this.hoardingGroups.length > 0) {
          this.hoardingGroups.forEach((hoardingGroup, i) => {
            this.treeData[i] = new TreeNode();
            this.treeData[i].data = hoardingGroup;
            if (hoardingGroup.hoardings && hoardingGroup.hoardings.length > 0) {
              this.treeData[i].children = [];
              hoardingGroup.hoardings.forEach((hoarding, index) => {
                this.treeData[i].children[index] = new TreeNode();
                this.treeData[i].children[index].data = hoarding;
              });
              // this.loading = false;
            }
          });
        }
      })
    );

    this.treeTableCols = [
      { field: "id", header: "GID" },
      { field: "location", header: "Locations" },
      { field: "width", header: "Size" },
      { field: "minimumPrice", header: "Rate" },
      { field: "status", header: "Status" },
    ];

    this.setImportMenu();
    this.hideCommonInventoryDialog = false;
  }

  ngAfterViewInit() {
    this.getStatus();
    // if (this.hoardingGroupChildRef) {
    //   this.changeHoardingGroupGridConfig()
    // }

    // this.getUserColumnConfig();

    // this.subscription.push(this.hmColService.defaultColumns.subscribe((defaultColumns) => {

    //   defaultColumns.forEach((column) => {
    //     if (column['default'] === true && column['permanent'] === false) {
    //       if (this.selectedColumns.indexOf(column['header']) < 0) {
    //         this.selectedColumns.push(column['header'])
    //       }
    //     }
    //   })

    //   if (this.selectedColumns.length > 0) {
    //     this.hmColService.selectedColumns.next(this.selectedColumns);
    //   }

    // }))
  }

  /**
   * @description sets hoarding grid config
   * @author Raveena Nathani
   * @date 2020-01-07
   * @memberof ContractHoardingComponent
   */
  setHoardingGridConfig() {
    this.gridConfig.getDefaultModel = () => new Inventory();
    this.gridConfig.model = Inventory;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = !this.showHoardingGroups ? false : true;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.rowExpandMode = "single";
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showColorCode = true;
    // this.gridConfig.rowsPerPage = 10;    // remove for bug - 94240
    this.gridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      var filter = {
        contractId: { value: this.contractId, matchMode: "contains" },
      };
      Object.assign(paginationEvent.filters, filter);

      // if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
      //   Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
      // }

      if (
        !paginationEvent.filters.status ||
        (paginationEvent.filters.status &&
          paginationEvent.filters.status.value.length === 0)
      ) {
        if (this.selectedStatus.length) {
          let statuses = [];
          this.selectedStatus.forEach((item) => {
            statuses.push(item);
          });
          Object.assign(paginationEvent.filters, {
            status: { value: statuses, matchMode: "in" },
          });
        } else {
          Object.assign(paginationEvent.filters, {
            status: {
              value: ["AVAILABLE", "NOT_AVAILABLE", "BLOCKED"],
              matchMode: "in",
            },
          });
        }
      }

      Object.assign(paginationEvent.filters, this.setMediaType());
      Object.assign(paginationEvent.filters, {
        hasChild: { value: this.showHoardingGroups, matchMode: "is" },
      });

      this.hoardingSearchEvent = paginationEvent;
      return this.inventoryService
        .create(paginationEvent, paginationEvent, "/search")
        .pipe(
          map((response) => {
            this.hoardingListChildRef.getUserGridConfig();
            this.loading = false; // added after hoarding tree is loaded
            this.hoardings = response["data"]["content"];
            this.totalHoardings = response["data"].totalElements;
            this.numberOfHoardings = response["data"].numberOfElements;
            this.getHeader("HOARDING");
            this.getExpiredStatus();
            return response["data"]["content"];
          })
        );
    };
  }

  setKioskGridObjectConfig() {
    this.loading = true;
    this.kioskGridConfig.getDefaultModel = () => new Inventory();
    this.kioskGridConfig.model = Inventory;
    this.kioskGridConfig.selectionMode = SelectionMode.None;
    this.kioskGridConfig.editable = true;
    this.kioskGridConfig.expandableRows = !this.showKioskGroups ? false : true;
    this.kioskGridConfig.checkboxSelection = false;
    this.kioskGridConfig.resizableColumns = false;
    this.kioskGridConfig.rowExpandMode = "single";
    this.kioskGridConfig.shouldAddRowOnDelete = false;
    this.kioskGridConfig.lazy = true;
    this.kioskGridConfig.enablePagination = true;
    this.kioskGridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.kioskGridConfig.scrollable = true;
    // this.gridConfig.rowsPerPage = 10;    // remove for bug - 94240
    this.kioskGridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      var filter = {
        contractId: { value: this.contractId, matchMode: "contains" },
      };
      Object.assign(paginationEvent.filters, filter);
      // if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
      //   Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
      // }

      if (
        !paginationEvent.filters.status ||
        (paginationEvent.filters.status &&
          paginationEvent.filters.status.value.length === 0)
      ) {
        if (this.selectedStatus.length) {
          let statuses = [];
          this.selectedStatus.forEach((item) => {
            statuses.push(item);
          });
          Object.assign(paginationEvent.filters, {
            status: { value: statuses, matchMode: "in" },
          });
        } else {
          Object.assign(paginationEvent.filters, {
            status: {
              value: ["AVAILABLE", "NOT_AVAILABLE", "BLOCKED"],
              matchMode: "in",
            },
          });
        }
      }

      Object.assign(paginationEvent.filters, {
        mediaType: { value: "KIOSK", matchMode: "is" },
      });
      Object.assign(paginationEvent.filters, {
        hasChild: { value: this.showKioskGroups, matchMode: "is" },
      });

      this.kioskSearchEvent = paginationEvent;
      return this.inventoryService
        .create(paginationEvent, paginationEvent, "/search")
        .pipe(
          map((response) => {
            this.kioskChildRef.getUserGridConfig();
            this.loading = false; // added after hoarding tree is loaded
            this.kiosks = response["data"]["content"];
            this.numberOfKiosks = response["data"].numberOfElements;
            this.totalKiosks = response["data"].totalElements;
            this.getHeader("KIOSK");
            this.getExpiredStatus();
            return response["data"]["content"];
          })
        );
    };
  }

  setFilterDropdowns() {
    this.status = utils.createDropdown(HoardingStatusEnumLocal, false);
  }

  setImportMenu() {
    this.importTypes = [
      {
        label: "From Contract",
        command: (onclick) => {
          this.importHoardings();
        },
      },
      {
        label: "From Excel",
        command: (onclick) => {
          this.importFromCSV();
        },
      },
    ];
  }
  contractExpiryCheck() {
    if (this.contract.status === "EXPIRED") {
      this.hoardingListColumns.pop();
      this.hoardingListColumns = JSON.parse(
        JSON.stringify(this.hoardingListColumns)
      );
      // this.cd.detectChanges();
      //To do : sanchit
      // this.buttonConfig.add.disabled = () => {
      //   return true;
      // }
    }
  }

  /**
   * @description creates inventory
   * @author Raveena Nathani
   * @date 2019-12-27
   * @memberof ContractHoardingComponent
   */
  createInventory() {
    this.displayCreateInventory = true;
    this.editMode = false;
  }

  createHoarding() {
    this.mediaType = "HOARDING";
    this.createHoardingMode = true;
    this.displayCreateHoarding = true;
    this.editMode = false;
  }

  createKiosk() {
    this.mediaType = "KIOSK";
    this.createHoardingMode = true;
    this.displayCreateHoarding = true;
    this.editMode = false;
  }

  onClubHoardingClick() {
    this.displayClubHoarding = true;
  }

  saveSelectedHoardings(event: ContractHoardings[]) {
    event.forEach((element) => {
      element.contract = this.contract;
    });
    this.actionDispatcher.addHoardingsToContract(event);
  }

  toggleDisplayFlag(event) {
    this.displayHoardingSearch = false;
  }

  toggleGroupDialogDisplayFlag(event) {
    this.displayClubHoarding = false;
    this.isGroupSaved = false;
    this.hoardingGroup = new HoardingGroup();
    this.actionDispatcher.resetHoardingGroupFromContract();
    this.hoardingGroupChildRef.refreshGrid();
    this.actionDispatcher.getGroupsByContractId(this.contract.id);
  }

  editGroup(rowData, col) {
    const newImage: any[] = [];
    this.hoardingGroup = rowData.data;
    this.displayClubHoarding = true;
    this.isGroupSaved = true;
  }

  changeCreateHoardingDisplay(event) {
    this.refreshAllGrids();
    // this.hoardingGroupChildRef.refreshGrid();
    this.displayCreateHoarding = false;
  }

  editContract() {
    // this.router.navigate(['contracts/edit/' + this.contract.id]);
    this.displayContractForm = true;
  }

  getSitesStatus(status) {
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i].value === status) {
        return this.status[i].label;
      }
    }
  }

  emitSelectedStatus() {
    this.hmColService.statuses.next(this.selectedStatus);
  }

  removeStatus(i) {
    this.selectedStatus.splice(i, 1);
    this.emitSelectedStatus();
  }

  viewImages(event) {
    // this.displayPhotoDialog = true;
    this.imageData = event;
    this.loading = true;
    this.photoDialogComponent.imageData = this.getImageData(event);
    this.photoDialogComponent.showImages();
  }

  getImageData(data) {
    var imageData = [];
    for (let i = 0; i < data.images.length; i++) {
      imageData.push(new ImageDataDisplay().getImageData(data, data.images[i]));
    }
    return imageData;
  }

  viewDetail(rowData) {
    // rowData.statusQueues.forEach((item) => {
    //   item.customId = rowData.customId;
    // });
    this.rowData = rowData;
    this.displayStatusQueueDialog = true;
  }

  hideStatusQueueDialog() {
    this.displayStatusQueueDialog = false;
  }

  changePhotoDisplay(event) {
    this.displayPhotoDialog = false;
  }

  openContractForm() {
    this.title = "Edit Contract (" + this.contract.id + ")";
    this.contractForm.handleDialogOpen(true);
  }

  backToContractList() {
    this.actionDispatcher.resetContract();
    this.actionDispatcher.resetHoardingGroups();
    this.router.navigate(["/contracts/"]);
  }

  toggleLoader(event) {
    this.loading = false;
  }

  editHoarding(rowData) {
    this.editMode = true;
    this.hoardingData = _.cloneDeep(rowData);
    this.createHoardingMode = false;
    this.displayCreateHoarding = true;
  }

  changeDisplay($event) {
    this.displayCreateHoarding = false;
    this.hoardingListChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.kioskChildRef.refreshGrid();
    this.actionDispatcher.getContractById(this.contract.id);
  }

  changeEditMode() {
    this.refreshAllGrids();
    this.editMode = false;
  }

  updateHoarding(editedHoarding) {
    // this.actionDispatcher.updateHoarding(editedHoarding);
    this.subscription.push(
      this.actionDispatcher.isHoardingUpdated.subscribe((isHoardingUpdated) => {
        if (isHoardingUpdated) {
          this.hoardingDialogComponent.loading = false;
          this.displayCreateHoarding = false;
          this.hoardingDialogComponent.editContractHoardingMode = false;
          this.actionDispatcher.resetIsHoardingUpdated();
          this.changeEditMode();
        }
      })
    );
    this.subscription.push(
      this.actionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.hoardingDialogComponent.loading = false;
          this.displayCreateHoarding = false;
          this.hoardingDialogComponent.editContractHoardingMode = false;
          this.newHoarding = new ContractHoardings();
          this.actionDispatcher.resetError();
          this.changeEditMode();
        }
      })
    );
  }

  getValue(status) {
    return HoardingStatusEnum[status];
  }

  /**
   * @description on inventory creation
   * @author Raveena Nathani
   * @date 2020-01-01
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  inventoryCreated(event) {
    if (event) {
      this.actionDispatcher.getContractById(this.contract.id);
    } else {
      // when inventory prefixes setup is incomplete
      this.importInformation = false;
      this.displaySetupIncompleteDialog = true;
    }
    this.closeInventoryDialog(event);
  }

  onDialogClose() {
    this.actionDispatcher.getContractById(this.contract.id);
  }

  getLightType(key) {
    return HoardingLightEnum[key];
  }

  hideContractEditDialog(event) {
    this.displayContractForm = false;
    this.refreshAllGrids();
  }

  getStatus() {
    if (this.contract) {
      this.setStatusStyle();
      this.contractStatus = ContractStatusEnum[this.contract.status];
    } else {
      this.contractStatus = "-";
    }
  }

  setStatusStyle() {
    this.statusStyle = {
      color:
        this.contract.status === "ACTIVE"
          ? "green"
          : this.contract.status === "INACTIVE"
          ? "red"
          : "black",
      "font-weight": "bold",
    };
  }

  importHoardings() {
    this.importHoardingConfig.showImportHoarding = true;
    this.importHoardingConfig.contract = this.contract;
  }

  closeImportHoardingDialog(event) {
    this.importHoardingConfig.showImportHoarding = event;
    this.refreshAllGrids();
    this.actionDispatcher.getGroupsByContractId(this.contract.id);
    this.actionDispatcher.getContractById(this.contract.id);
  }

  onTabChange(event) {
    this.activeIndex = event.index;
  }

  editKiosk(kiosk) {
    this.editMode = true;
    this.hoardingData = _.cloneDeep(kiosk);
    this.createHoardingMode = false;
    this.displayCreateHoarding = true;
  }

  changeHoardingGroupGridConfig() {
    if (this.hoardingGroupChildRef) {
      this.hoardingGroupChildRef.groupGridConfig.selectionMode =
        SelectionMode.None;
      this.hoardingGroupChildRef.groupGridConfig.checkboxSelection = false;
    }
  }

  getUserColumnConfig() {
    if (this.hoardingListChildRef) {
      // this.columns = this.hoardingListChildRef.getModifiedColumns();
    }
  }

  emitSetColumns() {
    this.hmColService.selectedColumns.next(this.selectedColumns);
  }

  updatingUserGridConfig() {
    this.hoardingListChildRef
      ? this.hoardingListChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.kioskChildRef
      ? this.kioskChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.pillarChildRef
      ? this.pillarChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.bqsChildRef
      ? this.bqsChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.gantryChildRef
      ? this.gantryChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.pickupStandChildRef
      ? this.pickupStandChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.unipoleChildRef
      ? this.unipoleChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.signboardChildRef
      ? this.signboardChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.utilityChildRef
      ? this.utilityChildRef.updatingUserGridConfig(this.selectedColumns)
      : "";
    this.ledChildRef
    ? this.ledChildRef.updatingUserGridConfig(this.selectedColumns)
    : "";
  }

  setNumberOfGroups($event) {
    this.numberOfGroups = $event;
  }

  setTotalGroupRecords($event) {
    this.totalGroups = $event;
  }

  importFromCSV() {
    this.contractUiService.getHoardingTemplateUrl().subscribe(
      (templateUrl) => {
        if (templateUrl) {
          this.hoardingTemplateUrl = templateUrl["data"];
        }
      },
      (error) => {
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          if (
            errorObject.message ===
            "Tenant Setup is incomplete please contact ShowItMax Support!"
          ) {
            this.closeUploadCSVDialog();
            this.importInformation = true;
            this.displaySetupIncompleteDialog = true;
          } else {
            this.notificationServcie.info(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TRY_AGAIN,
              NotificationsMessages.INFORMATION
            );
          }
        } else {
          this.notificationServcie.error(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
        }
      }
    );
    this.displayImportFromCsv = true;
  }

  onImportFromCsvOpen() {}

  onCsvFileSelection(event) {
    this.csvFile = event.files[0];
  }

  uploadCsvFile() {
    this.loading = true;
    let formData: FormData = new FormData();
    formData.append("file", this.csvFile, this.csvFile.name);
    this.inventoryService
      .create(
        formData,
        { fileName: this.csvFile.name, file: this.csvFile },
        `/import/${this.contract.id}`,
        { enctype: "multipart/form-data" },
        "arraybuffer"
      )
      .subscribe(
        (response) => {
          this.loading = false;
          this.notificationServcie.success(
            "File Uploaded Succesfully",
            "File Upload."
          );
          this.downloadXlsx("XLS");
          this.downLoadFile(response);
          this.closeUploadCSVDialog();
          this.refreshAllGrids();
          this.actionDispatcher.getContractById(this.contractId);
        },
        (error) => {
          this.loading = false;
          this.csvFile = new File([""], "");
          this.closeUploadCSVDialog();

          const errorObject = ErrorUtil.getErrorObject(error);
          if (errorObject.code === 417 || errorObject.code === 412) {
            if (
              errorObject.message ===
              "Tenant Setup is incomplete please contact ShowItMax Support!"
            ) {
              this.closeUploadCSVDialog();
              this.importInformation = true;
              this.displaySetupIncompleteDialog = true;
            } else {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            }
          } else {
            this.notificationServcie.error(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TECHNICAL_ISSUE,
              NotificationsMessages.ERROR
            );
          }
        }
      );
  }

  /**
   * @description download XLS for error success Report
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} fileType
   * @memberof ContractHoardingComponent
   */
  downloadXlsx(fileType) {
    this.createLoaderObject(fileType, this.xlsLoader);
    this.loaderSubjects.xlsLoader.next(this.xlsLoader);
  }

  /**
   * @description download error success report
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} response
   * @memberof ContractHoardingComponent
   */
  downLoadFile(response) {
    let blob;
    let url;
    let anchor;
    blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    url = URL.createObjectURL(blob);
    anchor = document.createElement("a");
    anchor.download = "Hoardings Report.xlsx";
    anchor.href = url;
    anchor.click();
    this.createLoaderResetObject(this.resetXlsLoader);
    this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
  }

  onImportFromCsvClose() {}

  /**
   * @description reset loader object
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} object
   * @memberof ContractHoardingComponent
   */
  createLoaderResetObject(object) {
    object.fileType = null;
    object.text = null;
    object.isLoading = false;
    object.data = null;
    object.pptData = null;
  }

  /**
   * @description close upload CSV dialog
   * @author Divya Sachan
   * @date 2020-02-05
   * @memberof ContractHoardingComponent
   */
  closeUploadCSVDialog() {
    this.csvFile = new File([""], "");
    this.displayImportFromCsv = false;
  }

  /**
   * @description create loader object for XLS
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} fileType
   * @param {*} object
   * @memberof ContractHoardingComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = "Downloading XLS...";
    object.fileType = fileType;
    // object.searchEvent = this.searchEvent;
  }

  uploadDoc() {
    this.displayUploadDocDialog = true;
    this.setImageFolderConfig();
  }

  setImageFolderConfig() {
    this.imageFolderConfig.itemId = this.contract.id;
    this.imageFolderConfig.moduleName = "contracts";
  }

  showUploadDocDialog() {
    this.displayUploadDocDialog = true;
  }

  hideUploadDocDialog() {
    this.displayUploadDocDialog = false;
    this.gridViewChildRef.refresh();
  }

  setDocument(event) {
    // console.log(event)
    this.contract.documents = [...this.contract.documents, ...event];
    this.uploadDocumentService
      .uploadDocument(this.contract.documents, this.imageFolderConfig.itemId)
      .subscribe((response) => {
        this.notificationServcie.success(
          "Document Uploaded Successfully",
          "Document Upload"
        );
        this.displayUploadDocDialog = false;
      });
  }

  setHoardingGroupSearchEvent(event) {
    this.hoaridngGroupSearchEvent = event;
  }

  // exportXLSX() {
  //   switch (this.activeIndex) {
  //     case 0: {
  //       this.isFileDownloading = true;
  //       this.searchEvent = this.hoardingSearchEvent;
  //       this.createLoaderObject("XLS", this.xlsLoader);
  //       this.loaderSubjects.hoardingXlsLoader.next(this.xlsLoader);
  //       this.isFileDownloading = false;
  //       break;
  //     }
  //     // case 1: {
  //     //   this.isFileDownloading = true;
  //     //   this.searchEvent = this.hoaridngGroupSearchEvent;
  //     //   this.createLoaderObject("XLS", this.xlsLoader);
  //     //   this.loaderSubjects.hoardingGroupXlsLoader.next(this.xlsLoader);
  //     //   this.isFileDownloading = false;
  //     //   break;
  //     // }
  //     case 1: {
  //       this.isFileDownloading = true;
  //       this.searchEvent = this.kioskSearchEvent;
  //       this.createLoaderObject("XLS", this.xlsLoader);
  //       this.loaderSubjects.kioskXlsLoader.next(this.xlsLoader);
  //       this.isFileDownloading = false;
  //       break;
  //     }
  //   }
  // }

  // createLoaderObject(fileType, object) {
  //   object.isLoading = true;
  //   object.text = 'Downloading XLS...';
  //   object.fileType = fileType;
  //   object.searchEvent = this.searchEvent;
  //   object.source = this.contract.name;
  // }

  getExpiredStatus() {
    this.isExpired = false;
    this.expiredInventory = 0;
    switch (this.activeIndex) {
      case 0: {
        this.hoardings.forEach((hoarding) => {
          if (
            hoarding.status &&
            HoardingStatusEnum[hoarding.status] === HoardingStatusEnum.EXPIRED
          ) {
            this.expiredInventory += 1;
          }
        });
        if (
          this.expiredInventory === this.numberOfHoardings &&
          this.expiredInventory > 0
        ) {
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        this.hoardingListChildRef.setColumns();
        break;
      }
      case 2: {
        this.kiosks.forEach((kiosk) => {
          if (
            kiosk.status &&
            HoardingStatusEnum[kiosk.status] === HoardingStatusEnum.EXPIRED
          ) {
            this.expiredInventory += 1;
          }
        });
        if (
          this.expiredInventory === this.numberOfKiosks &&
          this.expiredInventory > 0
        ) {
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        this.kioskChildRef.setColumns();
        break;
      }
    }
  }

  closeInformationDialog() {
    this.displaySetupIncompleteDialog = false;
  }

  openInformationDialog() {
    this.importInformation = false;
    this.displaySetupIncompleteDialog = true;
  }

  /**
   * @description sets media type
   * @author Raveena Nathani
   * @date 2020-01-06
   * @memberof ContractHoardingComponent
   */
  setMediaType() {
    return { mediaType: { value: "HOARDING", matchMode: "is" } };
  }

  /**
   * @description edits inventory
   * @author Raveena Nathani
   * @date 2020-01-06
   * @param {*} rowData
   * @memberof ContractHoardingComponent
   */
  editInventory(rowData) {
    this.displayCreateInventory = true;

    this.editMode = true;
    this.hoardingData = _.cloneDeep(rowData);
    // this.createHoardingMode = false;
  }

  /**
   * @description on inventory dialog closing
   * @author Raveena Nathani
   * @date 2020-01-06
   * @memberof ContractHoardingComponent
   */
  closeInventoryDialog(event) {
    this.displayCreateInventory = false;
    this.hideCommonInventoryDialog = true;
    // this.refreshAllGrids();
    // this.refreshAllGrids()
    this.refreshGrid(event);
  }

  /**
   * @description refreshes that inventory grid whose inventory is created and edited
   * @author Raveena Nathani
   * @date 2020-02-26
   * @param {*} event
   * @returns
   * @memberof ContractHoardingComponent
   */
  refreshGrid(event) {
    switch (event) {
      case "HOARDING": {
        this.hoardingListChildRef
          ? this.hoardingListChildRef.refreshGrid()
          : "";
        break;
      }
      case "KIOSK": {
        this.kioskChildRef ? this.kioskChildRef.refreshGrid() : "";
        break;
      }
      case "PILLAR": {
        this.pillarChildRef ? this.pillarChildRef.refreshGrid() : "";
        break;
      }
      case "BQS": {
        this.bqsChildRef ? this.bqsChildRef.refreshGrid() : "";
        break;
      }
      case "GANTRY": {
        this.gantryChildRef ? this.gantryChildRef.refreshGrid() : "";
        break;
      }
      case "PICKUP STAND": {
        this.pickupStandChildRef ? this.pickupStandChildRef.refreshGrid() : "";
        break;
      }
      case "UNIPOLE": {
        this.unipoleChildRef ? this.unipoleChildRef.refreshGrid() : "";
        break;
      }
      case "SIGNBOARD": {
        this.signboardChildRef ? this.signboardChildRef.refreshGrid() : "";
        break;
      }
      case "UTILITY": {
        this.utilityChildRef ? this.utilityChildRef.refreshGrid() : "";
        break;
      }
      case "LED": {
        this.ledChildRef ? this.ledChildRef.refreshGrid() : "";
        break;
      }
      default: {
        this.hoardingListChildRef
          ? this.hoardingListChildRef.refreshGrid()
          : "";
        this.kioskChildRef ? this.kioskChildRef.refreshGrid() : "";
        this.pillarChildRef ? this.pillarChildRef.refreshGrid() : "";
        this.bqsChildRef ? this.bqsChildRef.refreshGrid() : "";
        this.gantryChildRef ? this.gantryChildRef.refreshGrid() : "";
        this.pickupStandChildRef ? this.pickupStandChildRef.refreshGrid() : "";
        this.unipoleChildRef ? this.unipoleChildRef.refreshGrid() : "";
        this.signboardChildRef ? this.signboardChildRef.refreshGrid() : "";
        this.utilityChildRef ? this.utilityChildRef.refreshGrid() : "";
        this.ledChildRef ? this.ledChildRef.refreshGrid() : "";
      }
    }
  }

  /**
   * @description list of media types
   * @author Raveena Nathani
   * @date 2020-01-07
   * @memberof ContractHoardingComponent
   */
  getMediaTypes() {
    this.systemService.get(null, AppUrls.MEDIA_TYPES).subscribe((response) => {
      this.mediaTypes = response["data"];
      this.mediaTypes.forEach((item) => {
        this.mediaTypesMap.set(item.tabIndex, item.key);
        this.mediaTypeKeyValueMap.set(item.key, item.mediaTypeValue);
      });
    });
  }

  /**
   * @description get the header of the tabs
   * @author Divya Sachan
   * @date 2020-02-04
   * @param {*} index
   * @returns
   * @memberof ContractHoardingComponent
   */
  getHeader(index) {
    // return this.mediaTypesMap.get(index + 1);
    let mediaType = "";
    if (typeof index === "number") {
      // if type of index is number
      mediaType = this.mediaTypesMap.get(index + 1);
    } else {
      // otherwise
      mediaType = index;
    }

    switch (mediaType) {
      case "HOARDING": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfHoardings && this.totalHoardings
              ? "(" + this.numberOfHoardings + "/" + this.totalHoardings + ")"
              : "(0/0)"),
        ];
      }
      case "KIOSK": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfKiosks && this.totalKiosks
              ? "(" + this.numberOfKiosks + "/" + this.totalKiosks + ")"
              : "(0/0)"),
        ];
      }
      case "PILLAR": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfPillars && this.totalPillars
              ? "(" + this.numberOfPillars + "/" + this.totalPillars + ")"
              : "(0/0)"),
        ];
      }
      case "BQS": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfBQS && this.totalBQS
              ? "(" + this.numberOfBQS + "/" + this.totalBQS + ")"
              : "(0/0)"),
        ];
      }
      case "GANTRY": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfGantry && this.totalGantry
              ? "(" + this.numberOfGantry + "/" + this.totalGantry + ")"
              : "(0/0)"),
        ];
      }
      case "PICKUP STAND": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfPickupStands && this.totalPickupStands
              ? "(" +
                this.numberOfPickupStands +
                "/" +
                this.totalPickupStands +
                ")"
              : "(0/0)"),
        ];
      }
      case "UNIPOLE": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfUnipoles && this.totalUnipoles
              ? "(" + this.numberOfUnipoles + "/" + this.totalUnipoles + ")"
              : "(0/0)"),
        ];
      }
      case "SIGNBOARD": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfSignboards && this.totalSignboards
              ? "(" + this.numberOfSignboards + "/" + this.totalSignboards + ")"
              : "(0/0)"),
        ];
      }
      case "UTILITY": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfUtilities && this.totalUtilities
              ? "(" + this.numberOfUtilities + "/" + this.totalUtilities + ")"
              : "(0/0)"),
        ];
      }
      case "LED": {
        return [
          mediaType,
          this.mediaTypeKeyValueMap.get(mediaType) +
            (this.numberOfLeds && this.totalLeds
              ? "(" + this.numberOfLeds + "/" + this.totalLeds + ")"
              : "(0/0)"),
        ];
      }
      default: {
        return [mediaType, this.mediaTypeKeyValueMap.get(mediaType)];
      }
    }
  }

  /**
   * @description get total pillars and number of pillars on current page
   * @author Divya Sachan
   * @date 2020-02-04
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  getPillarCount(event) {
    this.numberOfPillars = event[0];
    this.totalPillars = event[1];
  }

  /**
   * @description get total BQS and number of BQS on current page
   * @author Divya Sachan
   * @date 2020-02-04
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  getBQSCount(event) {
    this.numberOfBQS = event[0];
    this.totalBQS = event[1];
  }

  /**
   * @description get total Gantry and number of Gantry on current page
   * @author Divya Sachan
   * @date 2020-02-04
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  getGantryCount(event) {
    this.numberOfGantry = event[0];
    this.totalGantry = event[1];
  }

  getPickupStandCount(event) {
    this.numberOfPickupStands = event[0];
    this.totalPickupStands = event[1];
  }

  getUnipoleCount(event) {
    this.numberOfUnipoles = event[0];
    this.totalUnipoles = event[1];
  }

  getSignboardCount(event) {
    this.numberOfSignboards = event[0];
    this.totalSignboards = event[1];
  }

  getUtilityCount(event) {
    this.numberOfUtilities = event[0];
    this.totalUtilities = event[1];
  }

  getLedCount(event){
    this.numberOfLeds = event[0];
    this.totalLeds = event[1];
  }

  /**
   * @description sets child filter
   * @author Raveena Nathani
   * @date 2020-01-07
   * @returns
   * @memberof ContractHoardingComponent
   */
  setChildFilter() {
    return {
      hasChild: { value: this.showParentSites ? true : false, matchMode: "is" },
    };
  }

  /**
   * @description called when show hoarding groups toggle changes
   * @author
   * @date 2020-08-26
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  onShowHoardingGroupsToggle(event) {
    this.showHoardingGroups = event.checked;
    this.setHoardingGridConfig();
  }

  /**
   * @description called when show kiosk groups toggle changes
   * @author
   * @date 2020-08-26
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  onShowKioskGroupsToggle(event) {
    this.showKioskGroups = event.checked;
    this.setKioskGridObjectConfig();
  }

  /**
   * @description called when show pillar groups toggle changes
   * @author
   * @date 2020-08-26
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  onShowPillarGroupsToggle(event) {
    this.showPillarGroups = event.checked;
  }

  /**
   * @description called when show bqs groups toggle changes
   * @author
   * @date 2020-08-26
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  onShowBQSGroupsToggle(event) {
    this.showBQSGroups = event.checked;
  }

  /**
   * @description called when show gantry groups toggle changes
   * @author
   * @date 2020-08-26
   * @param {*} event
   * @memberof ContractHoardingComponent
   */
  onShowGantryGroupsToggle(event) {
    this.showGantryGroups = event.checked;
  }

  onShowPickupStandGroupsToggle(event) {
    this.showPickupStandGroups = event.checked;
  }

  onShowUnipoleGroupsToggle(event) {
    this.showUnipoleGroups = event.checked;
  }

  onShowSignboardGroupsToggle(event) {
    this.showSignboardGroups = event.checked;
  }

  onShowUtilityGroupsToggle(event) {
    this.showUtilityGroups = event.checked;
  }

  onShowLedGroupsToggle(event) {
    this.showLedGroups = event.checked;
  }

  /**
   * @description refresh all grids
   * @author Divya Sachan
   * @date 2020-02-18
   * @memberof ContractHoardingComponent
   */
  refreshAllGrids() {
    this.hoardingListChildRef ? this.hoardingListChildRef.refreshGrid() : "";
    this.kioskChildRef ? this.kioskChildRef.refreshGrid() : "";
    this.pillarChildRef ? this.pillarChildRef.refreshGrid() : "";
    this.bqsChildRef ? this.bqsChildRef.refreshGrid() : "";
    this.gantryChildRef ? this.gantryChildRef.refreshGrid() : "";
    this.pickupStandChildRef ? this.pickupStandChildRef.refreshGrid() : "";
    this.unipoleChildRef ? this.unipoleChildRef.refreshGrid() : "";
    this.signboardChildRef ? this.signboardChildRef.refreshGrid() : "";
    this.utilityChildRef ? this.utilityChildRef.refreshGrid() : "";
    this.ledChildRef ? this.ledChildRef.refreshGrid() : "";
  }

  ngOnDestroy() {
    this.actionDispatcher.resetHoardingGroups();
    this.actionDispatcher.resetContract();
    this.actionDispatcher.resetContractIdFailedFlag();
    this.contract = new Contracts();
    this.contractId = undefined;
    this.hmColService.defaultColumns.next([]);
    this.subscription.forEach((s) => s.unsubscribe());
  }
}

export enum HoardingStatusEnumLocal {
  AVAILABLE = "Available",
  BLOCKED = "Blocked",
  INACTIVE = "Inactive",
  NOT_AVAILABLE = "Not-Available",
  EXPIRED = "Expired",
}
