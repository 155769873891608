
import { empty as observableEmpty, Observable } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { AppActions } from "../actions/app-actions";
import { PaginationData } from "../../modals/paginationdata";
import { ActionExtended } from "../../shared/actionextended";
import { IndustryService } from "../../services/shared/industry.service";
import { GroupService } from "../../services/shared/group.service";
import { ContractTypeService } from "../../services/shared/contract-type.service";
import { StateService } from "../../services/shared/states.service";
import { DistrictService } from "../../services/shared/district.service";
import { CityService } from "../../services/shared/cities.service";
import { AreaService } from "../../services/shared/area.service";
import { AreaListGetService } from "../../services/shared/areaListGet.service";
import { AreaListService } from "../../services/shared/areaList.service";
import { AreaPostService } from "../../services/shared/areaPost.service";
import { DistrictPostService } from "../../services/shared/districtPost.service";
import "rxjs";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { Store } from "@ngrx/store";
import { AppModuleState } from "../app-module.reducers";
import { SettingService } from "../../masters/setting/services/setting.service";
import { UploadImageService } from "../../services/shared/upload-images.service";
import { RemoveMonitoringService } from "../../services/shared/update-monitoring.service";
import { VendorService } from "../../services/shared/vendor.service";
import { SystemService } from "../../services/shared/system.service";
import { ofType } from '@ngrx/effects';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from '../../services/shared/notifications-messages';

@Injectable()

export class AppEffects {
    constructor(
        private actions: Actions,
        private appActions: AppActions,
        private industryService: IndustryService,
        private groupService: GroupService,
        private contractTypeService: ContractTypeService,
        private stateService: StateService,
        private districtService: DistrictService,
        private cityService: CityService,
        private areaService: AreaService, // cityId
        private areaListGetService: AreaListGetService,  // projection
        private areaListService: AreaListService, // areas
        private areaPostService: AreaPostService,  // save
        private districtPostService: DistrictPostService,
        private notificationServcie: NotificatoinsService,
        private store: Store<AppModuleState>,
        private settingService: SettingService,
        private uploadImageService: UploadImageService,
        private removeMonitoringService: RemoveMonitoringService,
        private vendorService: VendorService,
        private systemService: SystemService
    ) { }


    @Effect()
    getIndustry = this.actions.pipe(
        ofType(AppActions.GET_INDUSTRY),
        switchMap(() => {
            return this.industryService.getIndustries().pipe(
                map((industries) => {
                    return this.appActions.getIndustrySuccess(industries['_embedded']['industries']);
                }));
        }));

    @Effect()
    getGroup = this.actions.pipe(
        ofType(AppActions.GET_GROUP),
        switchMap(() => {
            return this.groupService.getGroups().pipe(
                map((groups) => {
                    return this.appActions.getGroupSuccess(groups['_embedded']['groups']);
                }));
        }));

    @Effect()
    getContractType = this.actions.pipe(
        ofType(AppActions.GET_CONTRACT_TYPE),
        switchMap(() => {
            return this.contractTypeService.getContractTypes().pipe(
                map((contractTypes) => {
                    return this.appActions.getContractTypeSuccess(contractTypes['_embedded']['contractTypes']);
                }));
        }));

    @Effect()
    createIndustry = this.actions.pipe(
        ofType(AppActions.CREATE_INDUSTRY),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            let isEdit: boolean = (payload.id) ? true : false;
            return this.industryService.create(payload,null,'/add').pipe(
                map((industries) => {
                    let payload = { data: industries, isEdit: isEdit };
                    this.notificationServcie.success('Industry Created Successfully', 'Industry Creation');
                    return this.appActions.createIndustrySuccess(payload);
                }));
        }),
        catchError(error => {
            console.log('error is: ',error);
            this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
            this.store.dispatch(this.appActions.createIndustryFailure(true));
            return observableEmpty();
        }));

        @Effect()
        updateIndustry = this.actions.pipe(
            ofType(AppActions.UPDATE_INDUSTRY),
            map((action: ActionExtended) => action.payload),
            switchMap((payload) => {
                let isEdit: boolean = (payload.id) ? true : false;
                return this.industryService.patch(payload,null,'update').pipe(
                    map((industries) => {
                        let payload = { data: industries, isEdit: isEdit };
                        this.notificationServcie.success('Industry Updated Successfully', 'Industry Updation');
                        return this.appActions.updateIndustrySuccess(payload);
                    }));
            }),
            catchError(error => {
                this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                this.store.dispatch(this.appActions.updateIndustryFailure(true));
                return observableEmpty();
            }));

    @Effect()
    createGroup = this.actions.pipe(
        ofType(AppActions.CREATE_GROUP),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            // let isEdit: boolean = (payload.id) ? true : false;
            // return this.groupService.create(payload)
            return this.groupService.createGroup(payload).pipe(
                map((groups) => {
                    // let payload = { data: groups, isEdit: isEdit };
                    // if (isEdit) {
                    //     this.notificationServcie.success('Group Updated', 'Group Updated Successfully');
                    // } else {
                    this.notificationServcie.success('Group Created Successfully', 'Group Creation');
                    // }
                    return this.appActions.createGroupSuccess(payload);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.createGroupFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    createContractType = this.actions.pipe(
        ofType(AppActions.CREATE_CONTRACT_TYPE),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            // let isEdit: boolean = (payload.id) ? true : false;
            return this.contractTypeService.create(payload, null, '/save').pipe(
                map((contractType) => {
                    this.notificationServcie.success('Contract Type Created Successfully', 'Contract Type Creation');
                    return this.appActions.createContractTypeSuccess(contractType);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.createContractTypeFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    updateContractType = this.actions.pipe(
        ofType(AppActions.UPDATE_CONTRACT_TYPE),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.contractTypeService.create(payload, null, '/update').pipe(
                map((contractType) => {
                    this.notificationServcie.success('Contract Type Updated Successfully', 'Contract Type Update');
                    return this.appActions.updateContractTypeSuccess(contractType);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.updateContractTypeFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    createArea = this.actions.pipe(
        ofType(AppActions.CREATE_AREA),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            // let isEdit: boolean = (payload.id) ? true : false;
            return this.areaPostService.create(payload, null, '/save').pipe(
                map((area) => {
                    this.notificationServcie.success('Custom Area Created Successfully', 'Custom Area Creation');
                    return this.appActions.createAreaSuccess(area);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.createAreaFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    updateArea = this.actions.pipe(
        ofType(AppActions.UPDATE_AREA),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.areaPostService.create(payload, null, '/update').pipe(
                map((area) => {
                    this.notificationServcie.success('Custom Area Updated Successfully', 'Custom Area Update');
                    return this.appActions.updateAreaSuccess(area);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.updateAreaFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    createDistrict = this.actions.pipe(
        ofType(AppActions.CREATE_DISTRICT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            // let isEdit: boolean = (payload.id) ? true : false;
            return this.districtPostService.create(payload).pipe(
                map((district) => {
                    this.notificationServcie.success('District Created Successfully', ' Custom District Creation');
                    return this.appActions.createDistrictSuccess(district);
                }), catchError(error => {
                    // this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                      this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                      this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    this.store.dispatch(this.appActions.createDistrictFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    updateDistrict = this.actions.pipe(
        ofType(AppActions.UPDATE_DISTRICT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.districtPostService.patch(payload).pipe(
                map((district) => {
                    this.notificationServcie.success('District Updated Successfully', 'Custom District Update');
                    return this.appActions.updateDistrictSuccess(district);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.updateDistrictFailure());
                    return observableEmpty();
                }));
        }));


    @Effect()
    getStates = this.actions.pipe(
        ofType(AppActions.GET_STATES),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.stateService.get(payload).pipe(
                map((states) => {
                    return this.appActions.getStatesSuccess(states['_embedded']['states']);
                }));
        }));

    @Effect()
    getStatesList = this.actions.pipe(
        ofType(AppActions.GET_STATES_LIST),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.stateService.get(payload).pipe(
                map((states) => {
                    return this.appActions.getStatesListSuccess(states['_embedded']['states']);
                }));
        }));



    @Effect()
    getDistricts = this.actions.pipe(
        ofType(AppActions.GET_DISTRICTS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.districtService.get(payload).pipe(
                map((districts) => {
                    return this.appActions.getDistrictsSuccess(districts['_embedded']['districts']);
                }));
        }));

    @Effect()
    getCities = this.actions.pipe(
        ofType(AppActions.GET_CITIES),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.cityService.get(payload).pipe(
                map((cities) => {
                    return this.appActions.getCitiesSuccess(cities['_embedded']['cities']);
                }));
        }));


    @Effect()
    getAreas = this.actions.pipe(
        ofType(AppActions.GET_AREAS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.areaService.get(payload).pipe(
                map((areas) => {
                    return this.appActions.getAreasSuccess(areas['_embedded']['areas']);
                }));
        }));


    @Effect()
    getAreaList = this.actions.pipe(
        ofType(AppActions.GET_AREA_LIST),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.areaListService.get(payload).pipe(
                map((areas) => {
                    return this.appActions.getAreaListSuccess(areas['_embedded']['areas']);
                }));
        }));

    @Effect()
    getAreaListFirst = this.actions.pipe(
        ofType(AppActions.GET_AREA_LIST_FIRST),
        switchMap(() => {
            return this.areaListGetService.getAreaListFirst().pipe(
                map((areas) => {
                    return this.appActions.getAreaListFirstSuccess(areas['_embedded']['areas']);
                }));
        }));

    @Effect()
    getAreaByCriteria = this.actions.pipe(
        ofType(AppActions.GET_AREA_BY_CRITERIA),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.areaService.getAreaByCriteria(payload).pipe(
                map((areas) => {
                    //Raveena | 16-05-2019
                    return this.appActions.getAreaByCriteriaSuccess(areas);
                }), catchError((error) => {
                    this.notificationServcie.error(error.error, 'Unable to get Area');
                    this.store.dispatch(this.appActions.getAreaByCriteriaFailure());
                    return observableEmpty();
                }))
        }));

    @Effect()
    saveConfig = this.actions.pipe(
        ofType(AppActions.SAVE_CONFIG),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.settingService.saveConfig(payload).pipe(
                map((reponse) => {
                    this.notificationServcie.success('Configuration updated successfully', 'Configuration Updation');
                    return this.appActions.saveConfigurationSuccess(reponse['_embedded']['configs']);
                }), catchError(error => {
                    this.notificationServcie.error('Configuration Updation failed', 'Configuration Updation');
                    return observableEmpty();
                }));
        }));



    @Effect()
    getConfig = this.actions.pipe(
        ofType(AppActions.GET_CONFIG),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.settingService.get(payload).pipe(
                map((cities) => {
                    return this.appActions.getConfigurationSuccess(cities['_embedded']['configs']);
                }));
        }));

    @Effect()
    uploadImage = this.actions.pipe(
        ofType(AppActions.UPLOAD_IMAGE),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.uploadImageService.create(payload).pipe(
                map((selectedRow) => {
                    this.notificationServcie.success('Image Uploaded Successfully', 'Image Upload');
                    return this.appActions.uploadImageSuccess(selectedRow);
                }), catchError(error => {
                    this.notificationServcie.error(error.message ? error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.uploadImageFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    removeMonitoringItem = this.actions.pipe(
        ofType(AppActions.REMOVE_MONITORING),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.removeMonitoringService.create(payload, null, '/remove/monitoring-item').pipe(
                map((rowData) => {
                    this.notificationServcie.success('Monitoring Removed Successfully', 'Monitoring Remove');
                    return this.appActions.removeMonitoringItemSuccess(rowData);
                }), catchError(error => {
                    this.notificationServcie.error(error.message ? error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.removeMonitoringItemFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    updateMonitoring = this.actions.pipe(
        ofType(AppActions.UPDATE_MONITORING),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.removeMonitoringService.create(payload, null, '/update/monitoring-item ').pipe(
                map((rowData) => {
                    this.notificationServcie.success('Monitoring Updated Successfully', 'Monitoring Update');
                    return this.appActions.updateMonitoringSuccess(rowData);
                }), catchError(error => {
                    this.notificationServcie.error(error.message ? error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.updateMonitoringFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    saveVendorDetails = this.actions.pipe(
        ofType(AppActions.SAVE_VENDOR_DETAILS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.vendorService.create(payload, null, '').pipe(
                map((vendor) => {
                    this.notificationServcie.success('Details saved Successfully', 'Company Details');
                    return this.appActions.saveVendorSuccess(vendor)
                }))/* .catch(error => {

        }) */
        }))

    @Effect()
    getSytemModules = this.actions.pipe(
        ofType(AppActions.GET_SYSTEM_MODULES),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.systemService.get(null, '/modules').pipe(
                map((modules) => {
                    return this.appActions.getSystemModulesSuccess(modules)
                }))/* .catch(error => {

        }) */
        }))

    @Effect()
    getConfigurationsByModule = this.actions.pipe(
        ofType(AppActions.GET_CONFIGURATION_BY_MODULE),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.systemService.get(payload, '/search/by-module').pipe(
                map((configurations) => {
                    return this.appActions.getConfigurationByModuleSuccess(configurations)
                    // ['_embedded']['systemProperties']
                }))/* .catch(error => {
    
            }) */
        }))

    @Effect()
    saveConfigurations = this.actions.pipe(
        ofType(AppActions.SAVE_PASSBOOK_CONFIGURATION),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.systemService.create(payload, null, '/update/passbook').pipe(
                map((configurations) => {
                    this.notificationServcie.success('Parameters Updated Successfully', 'Paramters Update');
                    return this.appActions.saveParametersSuccess(configurations['data'])
                }), catchError(error => {
                    this.notificationServcie.error(error.message ? error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.saveParametersFailure());
                    return observableEmpty();
                }));
        }))

    @Effect()
    savePassbookConfigurations = this.actions.pipe(
        ofType(AppActions.SAVE_CONFIGURATIONS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.systemService.create(payload['parameters'], null, '/update/' + payload['module']).pipe(
                map((configurations) => {
                    this.notificationServcie.success('Parameters Updated Successfully', 'Paramters Update');
                    return this.appActions.saveParametersSuccess(configurations)
                }), catchError(error => {
                    if (error.error.code === 412 || error.status === 412) {
                        this.notificationServcie.info(error.error.message ? error.error.message : 'Some technical issue', 'Parameters Update');
                    } else {
                        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.appActions.saveParametersFailure());
                    return observableEmpty();
                }));
        }))

    @Effect()
    getVendorDetails = this.actions.pipe(
        ofType(AppActions.GET_VENDOR_DETAILS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.vendorService.get(null, payload).pipe(
                map((vendorDetails) => {
                    return this.appActions.getVendorDetailsSuccess(vendorDetails)
                }))/* .catch(error => {
            
                    }) */
        }));

    @Effect()
    updateGroup = this.actions.pipe(
        ofType(AppActions.UPDATE_GROUP),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.groupService.updateGroup(payload).pipe(
                map((group) => {
                    this.notificationServcie.success('Group Updated Successfully', 'Group Update');
                    return this.appActions.updateGroupSuccess(group);
                }), catchError(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.appActions.updateGroupFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    getSystemConfigurations = this.actions.pipe(
        ofType(AppActions.GET_SYSTEM_CONFIGURATIONS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.systemService.get().pipe(
                map((configurations) => {
                    return this.appActions.getSystemConfiguationsSuccess(configurations)
                }))/* .catch(error => {
            
                    }) */
        }))
}
