import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from "@angular/core";
import { FirebaseService } from '../../../services/shared/firebase.service';
import { EditPOService } from './services/edit-po.service';
import { FileItem } from '../../../modals/files/file-item';
import * as fileUtils from '../../../helpers/file.util';
import { ChangesService } from '../../../services/shared/changes.service';
import { Subject } from 'rxjs';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { AppUrls } from '../../../services/urls';
import { ActivityLogModuleEnum } from '../../constants/activity-log-module-enum';
import * as _ from 'lodash';

@Component({
    selector: 'sib-edit-po',
    templateUrl: './edit-po.component.html',
    styleUrls: ['./edit-po.component.scss'],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditPoComponent implements OnInit, AfterViewInit, OnDestroy {

    _row: any;
    @Input() set row(row) {
        this._row = JSON.parse(JSON.stringify(row));
        if (this.row.purchaseOrders.poDate) {
            this._row.purchaseOrders.poDate = new Date(this._row.purchaseOrders.poDate);
        }
        this.oldName = this.row.purchaseOrders.name;
        // this.extension = fileUtils.getFileExtension(this.oldName);
        this.extension = this.row.purchaseOrders.extension;
        this.changesService.setInitialPurchaseOrder(_.cloneDeep(this._row));
    }

    get row() {
        return this._row;
    }

    fileType: string = null;
    extension: string = null;
    oldName: string = null;
    changedName: string = null; // to check the changed name while editing po
    disableSave = false;
    newFileItem: FileItem = null;
    metadataUnsub: Subject<boolean> = new Subject<boolean>();
    fileItemsUnsub: Subject<boolean> = new Subject<boolean>();
    nameChanged = false;
    inputName: string = null;

    @Output() eEmitCancelChanges: EventEmitter<boolean> = new EventEmitter();

    @Output() eEmitRowChanged: EventEmitter<any> = new EventEmitter();

    @ViewChild('editPOForm')
    editPOForm;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private firebaseService: FirebaseService,
        private editPOService: EditPOService,
        private changesService: ChangesService,
        private notificationServcie: NotificatoinsService,
    ) { }

    ngOnInit() {
        // console.log(this._row, "called this row")

        this.firebaseService.fileItems$.takeUntil(this.fileItemsUnsub).subscribe((fileItems) => {
            if (fileItems && fileItems.length > 0) {
                this.fileItemsUnsub.next(true);
                this.updateURLForObject(fileItems);
                this.firebaseService.resetFileItems();


            }
        });
        this.firebaseService.fileMetadata$.takeUntil(this.metadataUnsub).subscribe((metadata) => {
            if (metadata) {
                this.fileType = metadata["contentType"];
                this.metadataUnsub.next(true);
                this.createFileObjectFromURL();
                this.firebaseService.resetMetadata();

            }
        });

    }

    ngAfterViewInit() { }

    onNameInput(name) {
        this.inputName = name;
        this.nameChanged = true;
    }

    onNameChange(name) {
        const ext = fileUtils.getFileExtension(this.oldName);
        // if (ext) {
        if (this.oldName !== name) {
            // if (this.changedName !== name) {
            this.disableSave = true;
            this.editPOService.showLoader$.next(true);
            // this.row.purchaseOrders.name = name + this.extension;
            // this.changedName = name + this.extension;
            if (fileUtils.getFileExtension(name)) {
                if (fileUtils.getFileExtension(name) === ext) {
                    this.row.purchaseOrders.name = name;
                    this.changedName = name;
                } else {
                    this.row.purchaseOrders.name = name + ext;
                    this.changedName = name + ext;
                }
            } else {
                this.row.purchaseOrders.name = name + ext;
                this.changedName = name + ext;
            }
            // this.row.purchaseOrders.name = name;
            // this.changedName = name;
            this.getFileTypeFromFirebase();
            // } 
            // else {
            //     this.nameChanged = false;
            //     this.saveChanges();
            // }
        }
        else {
            this.nameChanged = false;
            this.saveChanges();
        }
        // } 
        // else {
        //     this.row.purchaseOrders.name = name + this.extension;
        //     if (this.oldName !== (name + this.extension)) {
        //         if (this.changedName !== name) {
        //             this.disableSave = true;
        //             this.editPOService.showLoader$.next(true);
        //             // this.row.purchaseOrders.name = name + this.extension;
        //             this.changedName = name + this.extension;
        //             this.getFileTypeFromFirebase();
        //         } else {
        //             this.nameChanged = false;
        //             this.saveChanges();
        //         }
        //     } else {
        //         // this.row.purchaseOrders.name = name + this.extension;
        //         this.nameChanged = false;
        //         this.saveChanges();
        //     }
        // }
    }



    updateURLForObject(fileItems) {
        if (fileItems && fileItems.length) {
            this.firebaseService.deleteFile(this.oldName, this.row.purchaseOrders.folder);
            this.row.purchaseOrders.url = fileItems[0].url;
            this.disableSave = false;
            this.editPOService.showLoader$.next(false);
            this.eEmitRowChanged.emit(this.row);
        }
    }

    onCheckboxSelectionChange(event) {
        if (!event) {
            this.row.purchaseOrders.poNumber = null;
            this.row.purchaseOrders.poDate = null;
            // this.editPOForm.reset({ name: this.row.purchaseOrders.name });
            // this.detectChagnes();
        }
    }

    saveChanges() {
        if (this.nameChanged) {
            this.onNameChange(this.inputName);
            // this.onNameIsChanged(this.inputName);
        }
        else {
            this.disableSave = true;
            this.editPOService.showLoader$.next(true);
            if (this.newFileItem) {
                this.firebaseService.uploadImageToFirebase(this.newFileItem);
            } else {
                this.disableSave = false;
                this.editPOService.showLoader$.next(false);
                this.eEmitRowChanged.emit(this.row);
            }
        }
        this.setActivityLog();

    }



    cancelChanges() {
        this.eEmitCancelChanges.emit(false);
    }

    getFileTypeFromFirebase() {
        this.firebaseService.getMetadata(this.oldName, this.row.purchaseOrders.folder);
    }

    createFileObjectFromURL() {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = new Blob([xhr.response], { type: this.fileType });
            const file = new File([blob], this.row.purchaseOrders.name, { type: this.fileType, lastModified: Date.now() });
            this.newFileItem = new FileItem(file, this.row.purchaseOrders.folder);
            // this.disableSave = false;
            // this.editPOService.showLoader$.next(false);
            this.nameChanged = false;
            this.saveChanges();
        };
        xhr.open('GET', this.row.purchaseOrders.url);
        xhr.send();
    }

    detectChagnes() {
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    setActivityLog() {
        this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
            (response) => {
                // console.log("response", response);
                this.changesService.resetData();
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
            }
        );
    }
    getChangedType() {
        return ActivityLogModuleEnum.PURCHASE_ORDER;

    }
    ngOnDestroy() {
        this.editPOService.resetLoader();
        this.metadataUnsub.unsubscribe();
        this.fileItemsUnsub.unsubscribe();
    }

}
