import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { PaginationData } from "../../modals/paginationdata";
import { AppUrls } from '../../services/urls';


@Injectable()
export class DashboardUiService {

    constructor(
        private dashboardService: DashboardService
    ) {

    }

    getInventoryCount() {
        return this.dashboardService.get(null, '/inventory-count')
    }

    getCampaignCount() {
        return this.dashboardService.get(null, '/campaign-status-count')
    }

    getCustomerCount() {
        return this.dashboardService.get(null, '/customer-count')
    }

    getUserCount() {
        return this.dashboardService.get(null, '/user-count')
    }

    getPlanCount() {
        return this.dashboardService.get(null, '/plan-count')
    }

    getTopHoardings(body) {
        var paginationData: PaginationData = {
            page: 0,
            size: 5
        }
        return this.dashboardService.create(body, paginationData, '/top-hoarding')
    }

    getBillingCardData(billingSearch) {
        return this.dashboardService.create(billingSearch, null, '/billing-card');
    }

    getPlanActionCount() {
        return this.dashboardService.get(null, '/plan-action-count')
    }

    getTotalInventorySqft(searchEvent) {
        return this.dashboardService.create(searchEvent, null, AppUrls.INVSQFT);
    }
    
    getPlanCampaignComparisonCount(searchEvent) {
        return this.dashboardService.create(searchEvent, null, '/plan-action-count')
    }
}