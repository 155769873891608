import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KeyToValuePipe } from "./KeyToValuePipe";
import { ValueToKeyPipe } from "./ValueToKeyPipe";

@NgModule({
  declarations: [KeyToValuePipe, ValueToKeyPipe],
  imports: [CommonModule],
  exports: [KeyToValuePipe, ValueToKeyPipe],
})
export class KeyValueMainPipe {}
