import { ChartArea } from "./chart-area/chart-area";
import { HAxis } from "./hAxis/hAxis";
import { VAxis } from "./vAxis/vAxis";

export class LineChartConfig {
  /**
   * How multiple data selections are rolled up into tooltips:
   *   'category': Group selected data by x-value.
   *    'series': Group selected data by series.
   *    'auto': Group selected data by x-value if all selections have the same x-value, and by series otherwise.
   *    'none': Show only one tooltip per selection.
   *
   * @type {string}
   * @memberof LineChartConfig
   */
  aggregationTarget: string;
  /* Text to display above the chart. */
  title: string;
  /* An object with members to configure various horizontal axis elements. To specify properties of this object, you can use object literal notation, as shown here: */
  hAxis: HAxis;
  /* An object with members to configure various vertical axis elements. To specify properties of this object, you can use object literal notation, as shown here: */
  vAxis: VAxis;
  /* Width of the chart, in pixels. */
  width: number;
  /* Height of the chart, in pixels. */
  height: number;
  /* Determines whether points will be displayed. Set to false to hide all points. You can override values for individual series using the series property. If you're using a trendline, the pointsVisible option will affect the visibility of the points on all trendlines unless you override it with the trendlines.n.pointsVisible option.
    This can also be overridden using the style role in the form of "point {visible: true}". */
  pointsVisible: boolean;
  legend: { position: string };
  chartArea: ChartArea = new ChartArea();
  curveType: string = "";
  series: any;
  constructor(
    aggregationTarget?: string,
    title?: string,
    hAxis?: HAxis,
    vAxis?: VAxis,
    width?: number,
    height?: number,
    pointsVisible?: boolean,
    legendPosition?: any,
    chartArea?: ChartArea,
    curveType?: string,
    series?: any
  ) {
    this.aggregationTarget = aggregationTarget;
    this.title = title;
    this.hAxis = hAxis;
    this.vAxis = vAxis;
    this.height = height;
    this.width = width;
    this.pointsVisible = pointsVisible;
    this.legend = { position: legendPosition };
    this.chartArea = chartArea;
    this.curveType = curveType ? curveType : "none";
    this.series = series;
  }
}
