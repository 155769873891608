<div class="mounter-report-container">
    <div class="flex-action-container margin-top-14 margin-right-5">
        <div class="action-buttion-container">
            <ul class="cinline-block">
                <li class="padding-right-10 margin-top--7 float-left">
                    <h3 class="margin-0 grid-header-text">{{title}}</h3>
                </li>
                <li class="margin-top-4 padding-left-5 float-right">
                    <label class="font-14 font-bold label-vCenter padding-right-5 toggle-switch-label">Export XLSX</label>
                    <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn top--2 font-12" (click)="exportXls()" title="Export Xlsx" icon="fa fa-file-excel-o">
                  </button>
                </li>
                <li class="margin-top-5 calendar-container float-right">
                    <p-calendar [styleClass]="'dates-button-hm'" [minDate]="stDate.value" #endDate="ngModel" appendTo="body" dateFormat="ddMy" yearRange="2000:2050" placeholder=" Ex-01Jan18" [(ngModel)]="calendarEndDate" [utc]="true" [showIcon]="true" readonlyInput="true"
                        (onSelect)="onEndDateChange($event)">
                    </p-calendar>
                </li>
                <li class="dates-text float-right">
                    <div>End Date</div>
                </li>
                <li class="margin-top-5 calendar-container float-right">
                    <p-calendar [styleClass]="'dates-button-hm'" [maxDate]="endDate.value" #stDate="ngModel" appendTo="body" placeholder=" Ex-01Jan18" dateFormat="ddMy" yearRange="2000:2050" [utc]="true" readonlyInput="true" [showIcon]="true" [(ngModel)]="calendarStartDate"
                        (onSelect)="onStartDateChange($event)">
                    </p-calendar>
                </li>
                <li class="dates-text float-right">
                    <div>Start Date</div>
                </li>
            </ul>
        </div>
    </div>
    <div class="mounter-report-grid-container ui-g-12 padding-0">
        <p-tabView (onChange)="onTabChange($event)">
            <p-tabPanel header="Mounting{{'(' + totalMountingRecords+')'}}">
                <div class="mounting-report-grid-scroller">
                    <sib-view-inventory #mountingReport [gridNameEnum]="'mountingReport'" [showFilter]="true" [columns]="mountingColumns" [gridConfig]="mountingGridConfig" [totalRecords]="totalMountingRecords"></sib-view-inventory>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Monitoring{{'(' + totalMonitoringRecords+')'}}">
                <div class="monitoring-report-grid-scroller">
                    <sib-view-inventory #monitoringReport [gridNameEnum]="'monitoringReport'" [showFilter]="true" [columns]="monitoringColumns" [gridConfig]="monitoringGridConfig" [totalRecords]="totalMonitoringRecords"></sib-view-inventory>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Unmounting{{'(' + totalUnmountingRecords+')'}}">
                <div class="unmounting-report-grid-scroller">
                    <sib-view-inventory #unmountingReport [gridNameEnum]="'unmountingReport'" [showFilter]="true" [columns]="unmountingColumns" [gridConfig]="unmountingGridConfig" [totalRecords]="totalUnmountingRecords"></sib-view-inventory>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>