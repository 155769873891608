import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';
import { CommonHoardingsComponent } from './common-hoardings.component';
import { ListHoardingsModule } from '../list-hoardings/list-hoardings.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        SharedModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DirectiveModules,
        ListHoardingsModule,
    ],

    declarations: [
        CommonHoardingsComponent
    ],

    exports: [
        CommonHoardingsComponent
    ]
})

export class CommonHoardingsModule { }
