<div class="reserve-credit-notes">
    <div class="ui-g" *ngIf="showTitle">
        <h2 class="margin-0 header-text">{{title}}</h2>
    </div>
    <div class="ui-g list-container" *ngIf="showAction">
        <ul>
            <li>
                <label class="font-12 font-weight-500 label-vCenter label-padding"> LAST CN ID: </label>
                <span style="border-bottom: 1px dotted black">{{lastCNId}}</span>
            </li>
            <li>
                <label class="font-12 font-weight-500 label-vCenter label-padding"> RESERVE CN COUNT: </label>
                <input class="width30" id="reserveCount" type="number" pInputText pKeyFilter="pint" [min]="0"
                    style="height: 18px" [(ngModel)]="reserveCount" />
            </li>
            <li>
                <button type="button" pButton label="SUBMIT" class="mtab-save margin-hori-5"
                    (click)="submitReserveCount()" [disabled]="!reserveCount"></button>
            </li>
        </ul>
    </div>
    <form [formGroup]="reservedCNGroup">
        <sib-grid name="sibGrid" #sibGrid [columns]="columns" [buttons]="buttonConfig" [config]="gridConfig"
            [totalRecords]="totalRecords" [showXlsExport]="true" (eSort)="onSort($event)" [showFilter]="true"
            (eFilterObject)="onFilter($event)" (eEmitExportXlsx)="exportXlsx()"
            (eEmitColumnSelection)="updatingUserGridConfig($event)" formControlName="gridControl" ngDefaultControl>
            <sib-grid-column field="srNo">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span>
                        {{rowIndex+1}}
                    </span>
                </ng-template>
            </sib-grid-column>
            <sib-grid-column field="utilized">
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown [options]="utilizedDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                        appendTo="body" placeholder="Select"
                        (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
                    </p-dropdown>
                </ng-template>
            </sib-grid-column>
            <sib-grid-column field="id">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div aria-hidden="true" pTooltip="{{rowData.utilized ? 'Edit Details' : 'Add Details'}}">
                        <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-10"
                            (click)="addReserveCNDetails(rowData)"
                            title="{{rowData.utilized ? 'Edit Details' : 'Add Details'}}"
                            icon="{{rowData.utilized ? 'fa fa-edit' : 'fa fa-plus'}}">
                        </button>
                    </div>
                </ng-template>
            </sib-grid-column>
            <sib-grid-column field="uploadDoc">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div pTooltip="Upload Documents">
                        <button pButton (click)="uploadDoc(rowData)" [disabled]="!rowData.utilized"
                            class="mtab-secondary c-edit-btn border-rad-2  margin-right-15"
                            icon="fa fa-upload"></button>
                    </div>
                </ng-template>
            </sib-grid-column>
            <sib-grid-column field="viewDoc">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div pTooltip="View Documents">
                        <a *ngIf="rowData?.documents?.length > 0" class="custom-grid-icon icon-contract"
                            title="Document" (click)="viewDocuments(rowData)"></a>
                    </div>
                    <div *ngIf="rowData?.documents?.length === 0">-</div>
                </ng-template>
            </sib-grid-column>
            <sib-grid-column field="delete">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span>
                        <i class="fa fa-trash-o info-red size-16" title="Delete Reserved CN"
                            (click)="deleteReservedCN(rowData)" aria-hidden="true"></i>
                    </span>
                </ng-template>
            </sib-grid-column>
        </sib-grid>
    </form>
    <sib-reserve-credit-note-detail-dialog *ngIf="displayReserveCNDetailDialog && rowData"
        [displayReserveCNDetailDialog]="displayReserveCNDetailDialog" [rowData]="rowData"
        (eEmitsaveCNDetails)="saveCNDetails($event)" (eEmitUnutilizeCN)="saveCNDetails($event)"
        (eEmitDisplayReset)="hideCNDetailsDialog()">
    </sib-reserve-credit-note-detail-dialog>
    <sib-upload-document *ngIf="displayUploadDocumentDialog" [displayUploadDocumentDialog]="displayUploadDocumentDialog"
        [folderConfig]="folderConfig" (displayReset)="hideUploadDocumentDialog()"
        (eEmitFiles)="setUploadedFiles($event)" (displayUploadDocument)="displayUploadDocumentDialog = false">
    </sib-upload-document>

    <p-dialog *ngIf="displayDocumentComponent" header="{{documentEdit ? 'Edit Document' : 'View Documents'}}"
        [closeOnEscape]="true" [(visible)]="displayDocumentComponent" [modal]="true" showEffect="fade" [width]="1000"
        (onHide)="hideViewDocumentDialog()" [baseZIndex]=10000 [autoZIndex]="true">
        <p-header>
            <span *ngIf="showLoader" class="process-loader">
                <p-progressSpinner [style]="{width: '20px', height: '20px', top: '5px' }" strokeWidth="8" fill="#EEEEEE"
                    animationDuration="2s"></p-progressSpinner> Processing...
            </span>
        </p-header>
        <sib-document #documentGrid *ngIf="displayDocumentComponent" [moduleData]="rowData"
            [viewDocumentsFromBilling]="!showAction? true : false"
            (eEmitPurchaseOrdersToEdit)="editPurchaseOrder($event)"
            (eEmitPurchaseOrdersToDelete)="deletePurchaseOrder($event)"
            (eEmitToggleHeader)="documentEdit = !documentEdit"></sib-document>
    </p-dialog>

    <p-confirmDialog header="Confirmation"   width="425" #cd>
        <p-footer>
            <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
            <button type="button" pButton class="mtab-secondary" label="NO" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog>
</div>