import { ApiService, BASE_URL } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AppUrls } from '../urls';


@Injectable()
export class CampaignItemService extends ApiService<any, any, any> {
    urlPath: string = 'campaignItems';
    baseUrl: string;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    downloadMounterReport(fileType, data, type) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLSX') {
                const fileUrl = this.baseUrl + this.urlPath + (type === "Mounting Report" ? AppUrls.EXPORT_MOUNTING_REPORT : type === "Monitoring Report" ? AppUrls.EXPORT_MONITORIING_REPORT : type === "Unmounting Report" ? AppUrls.EXPORT_UNMOUNTING_REPORT : '');
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }));
            }
        }
    }
}

