import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule, MultiSelectModule, ProgressSpinnerModule, TabViewModule, ButtonModule, StepsModule, TieredMenuModule, DialogModule, DropdownModule, InputSwitchModule, CalendarModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapacityChartComponent } from '../capacity-chart/capacity-chart.component';
import { CityService } from '../../masters/city/service/city.service';
import { DirectiveModules } from '../../modules/directive.modules';
import { UserPropertiesService } from '../../services/shared/user-properties.service';
import { SibChartModule } from '../../sib-chart/sib-chart.module';
import { CapacityChartDrilldownComponent } from './capacity-chart-drilldown/capacity-chart-drilldown.component';
import { SibGridModule } from '../../components/sib-forms/grid/grid.module';
import { ChartDrilldownHoardingsComponent } from '../../shared/components/chart-drilldown-hoardings/chart-drilldown-hoardings.component';
import { ChartDrilldownKiosksComponent } from '../../shared/components/chart-drilldown-kiosks/chart-drilldown-kiosks.component';
import { SibBackModule } from "../../shared/components/sib-back/sib-back.module";
import { CommonDataTableModule } from "../../shared/components/dataTable/dataTable.module";
import { AssignToPlanDialogModule } from '../../shared/components/assignToPlanDialog/assign-to-plan-dialog.module';
import { StatusQueueDialogModule } from "../../shared/components/status-queue-dialog/status-queue-dialog.module";
import { PhotoDialogModule } from "../../shared/components/photo-dialog/photo-dialog.module";
import { ViewInventoryModule } from "../../shared/components/view-inventory/view-inventory.module";
import { ChartDrilldownInventoriesComponent } from '../../shared/components/chart-drilldown-inventories/chart-drilldown-inventories.component';
import { CapacityChartOldComponent } from './capacity-chart-old/capacity-chart-old.component';

@NgModule({
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    ButtonModule,
    StepsModule,
    TieredMenuModule,
    DialogModule,
    DropdownModule,
    DirectiveModules,
    SibChartModule,
    SibGridModule,
    SibBackModule,
    CommonDataTableModule,
    AssignToPlanDialogModule,
    StatusQueueDialogModule,
    PhotoDialogModule,
    ViewInventoryModule,
    InputSwitchModule,
    CalendarModule
  ],
  declarations: [
    CapacityChartComponent,
    CapacityChartDrilldownComponent,
    ChartDrilldownHoardingsComponent,
    ChartDrilldownKiosksComponent,
    ChartDrilldownInventoriesComponent,
    CapacityChartOldComponent
  ],
  exports: [
    CapacityChartComponent,
    CapacityChartOldComponent
  ],
  providers: [
    CityService,
    UserPropertiesService
  ]
})
export class CapacityChartModule { }
