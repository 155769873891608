import { Picture } from "../hoardings/picture";
import { SlideFormat } from "./slide-format-enum";
import { PptFields } from "./ppt-fields-enum";
import { PPTLogoPositionEnum } from "./ppt-logo-position-enum";
import { SlideLayoutEnum } from "./slide-layout-enum";
import { ImagePosition } from "./image-position";
import { SlideSizeRatio } from "./slide-size-ratio-enum";

export class PptConfig {
  logo: Picture = new Picture();
  headerSlide: Picture = new Picture();
  footerSlide: Picture = new Picture();
  backGroundImage: Picture = new Picture();
  logoPosition: PPTLogoPositionEnum;
  middleSlideLayout: SlideLayoutEnum;
  middleSlideFields: PptFields[];
  middleSlideBorder: boolean;
  middleSlideFormat: SlideFormat;
  middleSlideImagePosition: ImagePosition;
  slideRatio: SlideSizeRatio;
}
