import { distinctUntilChanged, debounceTime, single } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  AfterViewInit,
} from "@angular/core";
import { MapMarker } from "../../../modals/map/MapMarker";
import { PlaceAutoComplete } from "./services/place-autocomplete.service";
import { Subject } from "rxjs";
import { PlaceDetailService } from "./services/place-detail.service";
import { GMap, OverlayPanel } from "primeng/primeng";
import * as _ from "lodash";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { CalculationService } from "../../../services/shared/calculationService";
import { DateUtil } from "../../../helpers/date.util";
import { AuthService } from "../../../login-service/auth.service";

@Component({
  selector: "sib-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MapComponent implements OnInit, AfterViewChecked, AfterViewInit {
  _overlays: any[] = [];
  mapMarkers: MapMarker[] = [];
  @Input() campaignTrackerMap: boolean = false;

  @Input() planTrackerMap:boolean = false;

  _options: any;
  @Input() set options(options) {
    this._options = options;
  }

  get options() {
    return this._options;
  }

  @Input() allowAddMarker: boolean;

  @Input() showSearch = false;

  @Input() mapFromHoarding: boolean;

  @Input() planCreationMap: boolean = false;

  @Input() showNotification: boolean;

  @Input() removeMarker: any;
  @Input()
  set overlays(val: any[]) {
    this.mapMarkers = _.cloneDeep(val);

    if (
      this.gmap &&
      this.gmap.map &&
      this.mapMarkers &&
      this.mapMarkers.length
    ) {
      this.setCenter(this.mapMarkers[0]);
    }
    this._overlays = [];
    val.forEach((data) => {
      if (data.metadata) {
        this._overlays.push(
          this.createMarker(
            data.latitude,
            data.longitude,
            data.metadata.customId,
            data.metadata
          )
        );
      } else {
        this._overlays.push(
          this.createMarker(data.latitude, data.longitude, data.location)
        );
        // this.setCenter(this.options['center']);
      }
      if (this.selectedInventoryIds.length) {
        this.setSelectedMarkers();
      }
    });
  }

  get overlays(): any[] {
    return this._overlays;
  }

  @Input() markerTitle: string;
  @Input() source: string = "";
  @Input() xlsxMap: boolean = false;

  _selectedInventoryIds: string[] = [];
  @Input() set selectedInventoryIds(selectedInventoryIds: string[]) {
    this._selectedInventoryIds = selectedInventoryIds;
    if (this.overlays.length) {
      this.setSelectedMarkers();
    }
  }

  get selectedInventoryIds() {
    return this._selectedInventoryIds;
  }

  _resetSearchBox: boolean;
  @Input() set resetSearchBox(resetSearchBox: boolean) {
    this._resetSearchBox = resetSearchBox;
    if (this._resetSearchBox) {
      this.input.nativeElement.value = "";
    }
  }

  @Output()
  eEmitMarker: EventEmitter<MapMarker> = new EventEmitter<MapMarker>();

  @Output() eEmitSeachboxChanges: EventEmitter<string> = new EventEmitter();

  @Output() eEmitSelectedMarkerIds: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  eEmitCoordinatesForLatLng: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("gmap") gmap: GMap;

  @ViewChild("searchinput") input: ElementRef;

  selectedPosition: any;
  draggable: boolean = true;
  infoWindow: any;
  dialogVisible: boolean = false;
  mapMarker: MapMarker = new MapMarker();
  places: any[] = [];
  placeSearchTerm = new Subject<any>();
  selectedPlace: any;
  map: any;
  markerInfowindow: any;
  iconBase: string = "assets/layout/images/";
  icons = {
    Available: {
      icon: this.iconBase + "green32.png",
    },
    "Not Available": {
      icon: this.iconBase + "red32.png",
    },
    Blocked: {
      icon: this.iconBase + "orange32.png",
    },
    Selected: {
      icon: this.iconBase + "location-pointer.png",
    },
    Mounted: {
      icon: this.iconBase + "orange32.png",
    },
    Unmounted: {
      icon: this.iconBase + "green32.png",
    },
  };

  selectedMarkerIds: string[] = [];

  coordinateX1: number;
  coordinateY1: number;
  coordinateX2: number;
  coordinateY2: number;

  constructor(
    private cd: ChangeDetectorRef,
    private placeAutocomplete: PlaceAutoComplete,
    private placeDetailService: PlaceDetailService,
    private notificationService: NotificatoinsService,
    private calculationService: CalculationService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.infoWindow = new google.maps.InfoWindow();
    this.selectedPlace = "";
    this.placeSearchTerm
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchValue) => {
        // console.log("inside first subscribe", searchValue);
        this.placeAutocomplete
          .getPlaceSuggestions({
            input: searchValue,
            key: "AIzaSyADnXYikeAj_6sZ3S2F-YWyqw-IJxuJ6Pw",
            session_token: "1234567890",
          })
          .subscribe((placeSuggestions) => {
            this.places = placeSuggestions["predictions"];
            //console.log("inside second subscribe", this.places);
          });
      });

    if (!this.showSearch) {
      this.input.nativeElement.style.display = "none";
    } else {
      this.input.nativeElement.style.display = "block";
    }
  }

  ngAfterViewInit() {}

  onMapReady(event) {
    // if (!this.options) {
    //   this.options = {
    //     center: { lat: 23.02579, lng: 72.58727 },
    //     zoom: 12
    //   }
    // }

    var mapComponent = this;
    var map = event.map;
    var mapComponent = this;
    var bounds = map.getBounds();
    // var coordinateX1, coordinateY1, coordinateX2, coordinateY2;
    var searchBox = new google.maps.places.SearchBox(this.input.nativeElement);
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.input.nativeElement
    );
    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(
      document.getElementById("mapLegend")
    );

    // if (this.mapFromHoarding) {
    // if (this.options) {
    this.source !== "Hoarding Master"
      ? this.setCenter(this.options["center"])
      : "";

    // } else {
    //   this.options = {
    //     center: { lat: 23.02579, lng: 72.58727 },
    //     zoom: 12
    //   }
    //   this.setCenter(this.options['center']);

    // }
    // }
    var self = this;
    map.setOptions({
      gestureHandling: "cooperative",
    });

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });

    map.addListener("zoom_changed", function () {
      if (self.planCreationMap) {
        let bounds = Object.keys(map.getBounds());
        if (bounds && bounds.length) {
          self.coordinateX1 = map.getBounds()[bounds[0]].i;
          self.coordinateY1 = map.getBounds()[bounds[0]].j;
          self.coordinateX2 = map.getBounds()[bounds[1]]
            ? map.getBounds()[bounds[1]].i
            : 0;
          self.coordinateY2 = map.getBounds()[bounds[1]]
            ? map.getBounds()[bounds[1]].j
            : 0;
          // console.log("coordinates...", self.coordinateX2, self.coordinateX1, self.coordinateY2, self.coordinateY1);
          self.eEmitCoordinatesForLatLng.emit([
            self.coordinateX2,
            self.coordinateX1,
            self.coordinateY2,
            self.coordinateY1,
          ]);
        }
      }
    });

    var markers = [];
    //   this.dialogVisible = true;

    searchBox.addListener("places_changed", () => {
      var places = searchBox.getPlaces();
      // console.log("event is 1", this.input);

      this.eEmitSeachboxChanges.emit(places[0].formatted_address);

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });

      markers = [];

      var bounds = new google.maps.LatLngBounds();

      places.forEach(function (place) {
        if (!place.geometry) {
          // console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        //     /* mapComponent._overlays.push({ position: { lat: markers[0].position.lat(), lng: markers[0].position.lng() }, title: markers[0].title, draggable: mapComponent.draggable })
        //     console.log("The overlay is", mapComponent._overlays) */

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
    if (this.mapMarkers && this.mapMarkers.length && this.mapFromHoarding) {
      this.setCenter(this.mapMarkers[0]);
    }
  }

  ngAfterViewChecked() {}

  handleMapClick(event) {
    if (this.allowAddMarker) {
      this.selectedPosition = event.latLng;
      this.addMarker();
    }
  }

  handleOverlayClick(event) {
    // console.log("overlay click", event);
  }

  addMarker() {
    // bugId: 95395 making the array blank before adding any marker. To avoid multiple marker.
    this._overlays = [];
    this._overlays.push(
      new google.maps.Marker({
        position: {
          lat: this.selectedPosition.lat(),
          lng: this.selectedPosition.lng(),
        },
        title: this.markerTitle,
        draggable: this.draggable,
      })
    );
    // this.markerTitle = null;
    this.dialogVisible = false;
    this.mapMarker.latitude = this.selectedPosition.lat().toFixed(6);
    this.mapMarker.longitude = this.selectedPosition.lng().toFixed(6);
    // this.eEmitMarker.emit(this.mapMarker);
  }

  createMarker(lat, lng, title, metadata?: any) {
    var infowindow = new google.maps.InfoWindow({});
    if (metadata) {
      var availableStatus = metadata.status ? metadata.status.substring(0, 9):'';
      var notAvailableStatus =metadata.status ? metadata.status.substring(0, 13):'';
      var blockedStatus = metadata.status ?metadata.status.substring(0, 7):'';
      var mountedStatus = metadata.status ?metadata.status.substring(0, 7):'';
    }

    if (typeof lat === "string") {
      lat = parseInt(lat);
    }
    if (typeof lng === "string") {
      lng = parseInt(lng);
    }

    if (availableStatus === "Available" || availableStatus === "AVAILABLE") {
      status = "Available";
    } else if (
      notAvailableStatus === "Not Available" ||
      notAvailableStatus === "NOT_AVAILABLE" ||
      notAvailableStatus === "Not-Available"
    ) {
      status = "Not Available";
    } else if (blockedStatus === "Blocked" || blockedStatus === "BLOCKED") {
      status = "Blocked";
    } else if (mountedStatus === "MOUNTED" || mountedStatus === "Mounted") {
      status = "Mounted";
    } else {
      status = "Unmounted";
    }

    var marker = metadata
      ? new google.maps.Marker({
          position: { lat: lat, lng: lng },
          title: title,
          icon: this.icons[status].icon,
        })
      : new google.maps.Marker({
          position: { lat: lat, lng: lng },
          title: title,
        });

    var markerInfo;

    if (metadata) {
      this.markerInfowindow = new google.maps.InfoWindow();
      if (this.planCreationMap) {
        google.maps.event.addListener(
          marker,
          "click",
          (function (markers, metadatas, map, self, markerWindow) {
            return function () {
              if (!self.selectedMarkerIds.includes(marker.getTitle())) {
                marker.setIcon(self.icons["Selected"].icon);
                self.selectedMarkerIds.push(marker.getTitle());
              } else {
                // if (this.mapFromHoarding) {
                let status: string;
                status = self.getStatusIconFromMetadataStatus(metadata.status);
                marker.setIcon(self.icons[status].icon);
                const index = self.selectedMarkerIds.findIndex(
                  (id) => id === marker.getTitle()
                );
                if (index !== -1) {
                  self.selectedMarkerIds.splice(index, 1);
                } else {
                  // do nothing
                }
                // }
              }
              self.eEmitSelectedMarkerIds.emit(self.selectedMarkerIds);
            };
          })(marker, metadata, this.map, this, this.markerInfowindow)
        );
      }

      google.maps.event.addListener(
        marker,
        "mouseover",
        (function (markers, metadatas, map, self, markerWindow) {
          return function () {
            !self.campaignTrackerMap && !self.planTrackerMap
              ? markerWindow.setContent(self.setMetadata(metadatas))
              : markerWindow.setContent(
                  self.setCampaignTrackerMetaData(metadatas)
                );
            markerWindow.open(map, markers);
          };
        })(marker, metadata, this.map, this, this.markerInfowindow)
      );

      google.maps.event.addListener(
        marker,
        "click",
        (function (markers, metadatas, map, self, markerWindow) {
          return function () {
            !self.campaignTrackerMap && !self.planTrackerMap
              ? markerWindow.setContent(self.setMetadata(metadatas))
              : markerWindow.setContent(
                  self.setCampaignTrackerMetaData(metadatas)
                );
            markerWindow.open(map, markers);
          };
        })(marker, metadata, this.map, this, this.markerInfowindow)
      );

      google.maps.event.addListener(
        marker,
        "mouseout",
        (function (markers, metadatas, map, self, markerWindow) {
          return function () {
            markerWindow.close();
          };
        })(marker, metadata, this.map, this, this.markerInfowindow)
      );
    }

    return marker;
  }

  getStatusIconFromMetadataStatus(metadataStatus) {
    let status: string;
    switch (metadataStatus) {
      case "Available":
      case "AVAILABLE": {
        status = "Available";
        break;
      }
      case "Not Available":
      case "NOT_AVAILABLE":
      case "Not-Available": {
        status = "Not Available";
        break;
      }
      case "Blocked":
      case "BLOCKED": {
        status = "Blocked";
        break;
      }

      case "MOUNTED":
      case "Mounted": {
        status = "Mounted";
        break;
      }

      case "PENDING_FLEX":
      case "PENDING":
      case "PENDING_MOUNTING":
      case "RUNNING":
      case "PENDING_UNMOUNTING":
      case "EXPIRED": {
        status = "UNMOUNTED";
        break;
      }
    }
    return status;
  }

  resetMarker() {
    this._overlays = [];
  }

  handleDragEnd(data) {
    this._overlays = [];
    this._overlays.push(
      new google.maps.Marker({
        position: {
          lat: data.originalEvent.latLng.lat(),
          lng: data.originalEvent.latLng.lng(),
        },
        title: this.markerTitle,
        draggable: this.draggable,
      })
    );
    this.mapMarker.latitude = data.originalEvent.latLng.lat().toFixed(6);
    this.mapMarker.longitude = data.originalEvent.latLng.lng().toFixed(6);
  }

  addLocation() {
    this.selectedPlace = "";
    this.eEmitMarker.emit(this.mapMarker);
  }

  search(event) {
    this.placeSearchTerm.next(event.query);
  }

  onPlaceSelect() {
    this.placeDetailService
      .getPlaceDetails({
        placeid: this.selectedPlace.place_id,
        key: "AIzaSyADnXYikeAj_6sZ3S2F-YWyqw-IJxuJ6Pw",
      })
      .subscribe((placeDetails) => {
        if (placeDetails && this.mapFromHoarding) {
          this.setCenter(placeDetails["result"]["geometry"]["location"]);
        }
      });
  }

  setCenter(location) {
    if (location.lat !== undefined && location.lng !== undefined) {
      this.options = {
        center: { lat: location.lat, lng: location.lng },
        zoom: 12,
      };
    } else if (
      location.latitude !== undefined &&
      location.longitude !== undefined
    ) {
      this.options = {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 12,
      };
    }

    // if (this.options) {
    this.gmap.map.setCenter(this.options.center);
    // } else {
    //   this.options = {
    //     center: { lat: 23.02579, lng: 72.58727 },
    //     zoom: 12
    //   }

    this._overlays.push(
      new google.maps.Marker({
        position: {
          lat: location.lat
            ? location.lat
            : location.latitude
            ? location.latitude
            : 0,
          lng: location.lng
            ? location.lng
            : location.longitude
            ? location.longitude
            : 0,
        },
        title: this.markerTitle,
        draggable: this.draggable,
      })
    );
  }

  getInfoContent(metadata) {
    if (metadata.size) {
      return (
        "%imageView%" +
        // '<div id="mapcontent">' +
        '<div id="mapcontent">' +
        "<table>" +
        '<tr> <th colspan="3" style="padding-bottom:10px;text-align:center;">%hid% - %city%, %location% - %size% </th> </tr>' +
        '<tr><td class="tdTitle"><b>Light Type</b></td> <td> : </td> <td class="tdValue">%lighttype% </td> </tr>' +
        "<tr><td ><b>%rateLabel%</b></td><td> : </td><td>%rate% </td></tr>" +
        "<tr><td><b>Status</b></td><td> : </td><td>%status% </td></tr>" +
        "<tr><td><b>Available From</b></td><td> : </td><td>%availablefrom% </td></tr>" +
        // "<tr>" +
        // "<td><b>Photo</b></td>" +
        // "<td> : </td>" +
        // "<td><a #infoWindowLink href='%imagelink%' target='_blank'>View</td>" +
        // "</tr>" +
        // "<script>$(document).getElemetById('infoWindowLink').onclick='viewImage('%link%')'</script>" +
        "</table>" +
        "</div>"
      );
    } else {
      return (
        "%imageView%" +
        // '<div id="mapcontent">' +
        '<div id="mapcontent">' +
        "<table>" +
        '<tr> <th colspan="3" style="padding-bottom:10px;text-align:center;">%hid% - %city%, %location% </th> </tr>' +
        '<tr><td class="tdTitle"><b>Light Type</b></td> <td> : </td> <td class="tdValue">%lighttype% </td> </tr>' +
        "<tr><td ><b>%rateLabel%</b></td><td> : </td><td>%rate% </td></tr>" +
        "<tr><td><b>Status</b></td><td> : </td><td>%status% </td></tr>" +
        "<tr><td><b>Available From</b></td><td> : </td><td>%availablefrom% </td></tr>" +
        // "<tr>" +
        // "<td><b>Photo</b></td>" +
        // "<td> : </td>" +
        // "<td><a #infoWindowLink href='%imagelink%' target='_blank'>View</td>" +
        // "</tr>" +
        // "<script>$(document).getElemetById('infoWindowLink').onclick='viewImage('%link%')'</script>" +
        "</table>" +
        "</div>"
      );
    }
  }

  getCampaignTrackerInfoContent(metadata) {

    if(this.campaignTrackerMap){
      if (metadata.size) {
        return (
          '<div id="trackerMapContent">' +
          "<table>" +
          '<tr> <th colspan="3" style="padding-bottom:10px">%hid% - %city%, %location% - %size% - %lighttype%  </th> </tr>' +
          "<tr><td ><b>Start Date</b></td><td> : </td><td>%startdate% </td></tr>" +
          "<tr><td><b>End Date</b></td><td> : </td><td>%enddate% </td></tr>" +
          "<tr>" +
          "<td><b>Mounting Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #mountinginfoWindowLink href='%mountimagelink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('mountinginfoWindowLink').onclick='viewImage('%mountlink%')'</script>" +
          "<tr>" +
          "<td><b>Unmounting Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #unmountinginfoWindowLink href='%unmountimagelink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('unmountinginfoWindowLink').onclick='viewImage('%monitorlink%')'</script>" +
          "<tr>" +
          "<td><b>Monitoring Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #monitoringinfoWindowLink href='%monitorlink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('monitoringinfoWindowLink').onclick='viewImage('%munountlink%')'</script>" +
          "</table>" +
          "</div>"
        );
      } else {
        return (
          '<div id="trackerMapContent">' +
          "<table>" +
          '<tr> <th colspan="3" style="padding-bottom:10px">%hid% - %city%, %location% - %lighttype%  </th> </tr>' +
          "<tr><td ><b>Start Date</b></td><td> : </td><td>%startdate% </td></tr>" +
          "<tr><td><b>End Date</b></td><td> : </td><td>%enddate% </td></tr>" +
          "<tr>" +
          "<td><b>Mounting Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #mountinginfoWindowLink href='%mountimagelink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('mountinginfoWindowLink').onclick='viewImage('%mountlink%')'</script>" +
          "<tr>" +
          "<td><b>Unmounting Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #unmountinginfoWindowLink href='%unmountimagelink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('unmountinginfoWindowLink').onclick='viewImage('%monitorlink%')'</script>" +
          "<tr>" +
          "<td><b>Monitoring Photo</b></td>" +
          "<td> : </td>" +
          "<td><a #monitoringinfoWindowLink href='%monitorlink%' target='_blank'>View</td>" +
          "</tr>" +
          "<script>$(document).getElemetById('monitoringinfoWindowLink').onclick='viewImage('%munountlink%')'</script>" +
          "</table>" +
          "</div>"
        );
      }
    }
    else if(this.planTrackerMap){
      if (metadata.size) {
        return (
          '<div id="trackerMapContent">' +
          "<div id='mapData'><img  id='infoWindowViewImage'   style='max-height:100px;' src='%mountimagelink%'></div>"+
          "<table>" +
          '<tr> <th colspan="3" style="padding-bottom:10px">%hid% - %city%, %location% - %size% - %lighttype%  </th> </tr>' +
          "<tr><td ><b>Start Date</b></td><td> : </td><td>%startdate% </td></tr>" +
          "<tr><td><b>End Date</b></td><td> : </td><td>%enddate% </td></tr>" +
          "</table>" +
          "</div>"
        );
      } else {
        return (
          '<div id="trackerMapContent">' +
          "<table>" +
          '<tr> <th colspan="3" style="padding-bottom:10px">%hid% - %city%, %location% - %lighttype%  </th> </tr>' +
          "<tr><td ><b>Start Date</b></td><td> : </td><td>%startdate% </td></tr>" +
          "<tr><td><b>End Date</b></td><td> : </td><td>%enddate% </td></tr>" +
          "<tr>" +
          "</table>" +
          "</div>"
        );
      }
    }

    
  }

  setMetadata(metadata) {
    var content;
    if (metadata.status === "Available" || metadata.status === "AVAILABLE") {
      metadata.status = "Available";
    } else if (
      metadata.status === "Not Available" ||
      metadata.status === "NOT_AVAILABLE" ||
      metadata.status === "Not-Available"
    ) {
      metadata.status = "Not Available";
    } else if (metadata.status === "Blocked" || metadata.status === "BLOCKED") {
      metadata.status = "Blocked";
    } else if (metadata.status === "MOUNTED" || metadata.status === "Mounted") {
      metadata.status = "Mounted";
    } else {
      metadata.status = "Unmounted";
    }
    // metadata.availableFrom = DateUtil.formatDateDDMMYY(new Date(this.calculationService.extendDate(metadata.availableFrom, 1)));
    // metadata.availableFrom = this.extendByDays(metadata.availableFrom, 1);
    content = this.getInfoContent(metadata).replace("%hid%", metadata.customId);
    content = content.replace("%city%", metadata.city);
    content = content.replace("%location%", metadata.location);
    content = content.replace("%size%", metadata.size);
    content = content.replace("%lighttype%", metadata.lightType);
    content = content.replace("%rateLabel%", this.setMonthlyRateLabel());
    content = content.replace("%rate%", metadata.rate);
    content = content.replace("%status%", metadata.status);
    content =
      metadata.status === "Not Available" || metadata.status === "Not-Available"
        ? content.replace("%availablefrom%", metadata.availableFrom)
        : content.replace(
            "<tr><td><b>Available From</b></td><td> : </td><td>%availablefrom% </td></tr>",
            ""
          );

    content =
      metadata.image && metadata.image.url
        ? content.replace("%link%", metadata.image.url)
        : content;
    content =
      metadata.image && metadata.image.url
        ? content.replace(
            "%imageView%",
            // "Image DIV HIJACKED..."
            '<div id="mapData"><img id="infoWindowViewImage" src="%imagelink%" (load)="onImageLoad($event)" style="max-height:100px;max-width: 200px"></div>'
          )
        : content.replace("%imageView%", "");
    content =
      metadata.image && metadata.image.url
        ? metadata.image && metadata.image.thumbUrl
          ? content.replace("%imagelink%", metadata.image.thumbUrl)
          : content.replace("%imagelink%", metadata.image.url)
        : content;
    return content;
  }

  setCampaignTrackerMetaData(metadata) {
    var content;

    content = this.getCampaignTrackerInfoContent(metadata).replace(
      "%hid%",
      metadata.customId
    );
    content = content.replace("%city%", metadata.city);
    content = content.replace("%location%", metadata.location);
    content = content.replace("%size%", metadata.size);
    content = content.replace("%lighttype%", metadata.lightType);
    content = content.replace("%startdate%", metadata.startDate);
    content = content.replace("%enddate%", metadata.endDate);

    content =
      metadata.mountingImage !== undefined && metadata.mountingImage !== null
        ? content.replace("%mountimagelink%", metadata.mountingImage.url)
        : content.replace(
            "<tr>" +
              "<td><b>Mounting Photo</b></td>" +
              "<td> : </td>" +
              "<td><a #mountinginfoWindowLink href='%mountimagelink%' target='_blank'>View</td>" +
              "</tr>",
            ""
          );
    content =
      metadata.mountingImage !== undefined && metadata.mountingImage !== null
        ? content.replace("%mountlink%", metadata.mountingImage.url)
        : content;

    content =
      metadata.monitoringImage !== undefined &&
      metadata.monitoringImage !== null
        ? content.replace("%monitorimagelink%", metadata.monitoringImage.url)
        : content.replace(
            "<tr>" +
              "<td><b>Monitoring Photo</b></td>" +
              "<td> : </td>" +
              "<td><a #monitoringinfoWindowLink href='%monitorlink%' target='_blank'>View</td>" +
              "</tr>",
            ""
          );
    content =
      metadata.monitoringImage !== undefined &&
      metadata.monitoringImage !== null
        ? content.replace("%monitorlink%", metadata.monitoringImage.url)
        : content;

    content =
      metadata.unmountingImage !== undefined &&
      metadata.unmountingImage !== null
        ? content.replace("%unmountimagelink%", metadata.unmountingImage.url)
        : content.replace(
            "<tr>" +
              "<td><b>Unmounting Photo</b></td>" +
              "<td> : </td>" +
              "<td><a #unmountinginfoWindowLink href='%unmountimagelink%' target='_blank'>View</td>" +
              "</tr>",
            ""
          );
    content =
      metadata.unmountingImage !== undefined &&
      metadata.unmountingImage !== null
        ? content.replace("%munountlink%", metadata.unmountingImage.url)
        : content;
    return content;
  }

  viewImage() {
    // console.log("link is clicked");
  }

  setBounce(item) {
    this.overlays.forEach((overlay) => {
      if (overlay.getTitle() === item.customId) {
        overlay.setAnimation(google.maps.Animation.BOUNCE);
      }
    });
    if (this.mapFromHoarding) {
      this.setCenter(item.geoCoordinate);
    }
  }
  resetBounce(item) {
    this.overlays.forEach((overlay) => {
      if (overlay.getTitle() === item.customId) {
        overlay.setAnimation(null);
      }
    });
  }

  setCenterForMap() {}

  openPhotoDialog() {
    // console.log("Photo dialog Open");
  }

  setSelectedMarkers() {
    // adding selected inventory ids to map's selected marker ids
    if (this.selectedInventoryIds.length) {
      this.selectedInventoryIds.forEach((id) => {
        const markerOverlay = this.overlays.find(
          (overlay) => overlay.getTitle() === id
        );
        if (markerOverlay) {
          markerOverlay.setIcon(this.icons["Selected"].icon);
        }
        if (this.selectedMarkerIds.includes(id)) {
          // do nothing
        } else {
          this.selectedMarkerIds.push(id);
        }
      });
    }

    // removing unselected inventory ids from map's selected marker ids
    const idsToRemove = this.selectedMarkerIds.filter(
      (id) => this.selectedInventoryIds.findIndex((item) => item === id) === -1
    );
    idsToRemove.forEach((id) => {
      const markerOverlay = this.overlays.find(
        (overlay) => overlay.getTitle() === id
      );
      // const marker = this.mapMarkers.find(marker => marker.metadata.customId === id);
      const status = this.getStatusIconFromMetadataStatus(
        this.removeMarker.status
      );
      if (markerOverlay) {
        markerOverlay.setIcon(this.icons[status].icon);
        const index = this.selectedMarkerIds.findIndex(
          (id) => id === markerOverlay.getTitle()
        );
        if (index !== -1) {
          this.selectedMarkerIds.splice(index, 1);
        } else {
          // do nothing -> will not happen
        }
      }
    });
  }

  setMonthlyRateLabel() {
    return this.auth.setMonthlyRateLabel();
  }
}
