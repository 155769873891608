import * as FileSaver from "file-saver";
import JSZip from 'jszip';
import { Subject } from "rxjs";
import { TallyXmlEnum } from '../shared/constants/tally-xml-enum';
import * as utils from '../helpers/utils';
import { CapitalizePipe } from '../shared/helpers/capitalize-pipe/capitalize-pipe';
import { DateUtil } from './date.util';


export var isFileDownloaded: Subject<boolean> = new Subject<boolean>();

export function createImageDescription(obj, name?: string) {
    const hid = obj.customId ? obj.customId : '';
    const city = obj.city ? obj.city : '';
    const location = obj.location ? obj.location : '';
    const size = obj.size ? obj.size : '';
    if (!name) {
        return hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + (size && size !== '' && size !== null ? ' - ' + size : '');
    } else {
        const extension = getImageExtension(name);
        return hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + (size && size !== '' && size !== null ? ' - ' + size : '') + (extension && extension !== '' && extension !== null ? extension : '.jpeg');
    }
}

export function downloadImageFile(imageUrl, imageName, imageExtension) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
        const blob = new Blob([xhr.response], { type: 'image/*' });
        FileSaver.saveAs(blob, imageName + imageExtension);
    };
    xhr.open('GET', imageUrl);
    xhr.send();
}

export function createFileBlobs(imgs, fileName,loaderSubjects?) {
    const blobs: any[] = [];
    const events: any[] = [];
    imgs.forEach((img, ind) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            blobs.push(xhr.response);
            events.push(event);
            if (imgs.length === blobs.length) {
                if(loaderSubjects){
                    downloadZipFile(imgs, blobs, events, fileName,loaderSubjects);
                }
                else{
                    downloadZipFile(imgs, blobs, events, fileName);
                }
            }
        };
        xhr.open('GET', imgs[ind].url);
        xhr.send();
    });
}

export function downloadZipFile(imgs, blobs, events, name,loaderSubjects?) {
    let download: { blob: any, name: string }[] = [];
    const fileName = name;
    const zip = new JSZip();
    JSZip.support.nodebuffer = false;


    events.forEach((event, index) => {
        imgs.forEach((img) => {
            if (event.currentTarget.responseURL === img.url) {
                download.push({ blob: blobs[index], name: img.description });
            }
        });
    });


    download = renameDuplicateNames(download, 'name');

    // items inside zip file
    download.forEach((down, index) => {
        zip.file(down.name, down.blob);
    });

    // generating the zip
    zip.generateAsync({ type: "blob" })
        .then(function (blob) {
            // FileSaver.saveAs(blob, fileName + "-images.zip");
            FileSaver.saveAs(blob, fileName + "-" + DateUtil.dategridFormatter(new Date()) + ".zip");
            isFileDownloaded.next(true);
            if(loaderSubjects){
                loaderSubjects.isAllImagesDownloading.next(false);
            }
        });
}

export function renameDuplicateNames(items, field) {
    const renamedItems: any = [];
    items.forEach((item) => {
        let foundIndex = getIndex(renamedItems, item, field);
        if (foundIndex === -1) {
            renamedItems.push(item);
        } else {
            let counter = 0;
            while (foundIndex !== -1) {
                ++counter;
                const nameWithoutExtension = getNameWithoutExtension(item[field]);
                const nameWithoutCounter = getNameWithoutCounter(nameWithoutExtension);
                const extension = getImageExtension(item[field]);
                item[field] = nameWithoutCounter + '[' + counter + ']' + extension;
                foundIndex = getIndex(renamedItems, item, field);
            }
            renamedItems.push(item);
        }
    });
    return renamedItems;
}

export function createZipFile(values, zipFileName, fieldForName) {
    const zip = new JSZip();
    const fileName = zipFileName;
    JSZip.support.nodebuffer = false;
    values.forEach((value, index) => {
        const xml = jsonToXml(value);
        const parsedXml = parseToTallyXML(xml);
        zip.file(encodeURIComponent(value[fieldForName]) + ".xml", parsedXml);
    });

    // generating the zip
    zip.generateAsync({ type: "blob" })
        .then(function (blob) {
            FileSaver.saveAs(blob, fileName + ".zip");
            isFileDownloaded.next(true);
        });
}

export function jsonToXml(obj) {
    let xml = '';
    // tslint:disable-next-line: forin
    for (const prop in obj) {
        xml += obj[prop] instanceof Array ? '' : "<" + getValueFromEnum(prop, TallyXmlEnum) + ">";
        if (obj[prop] instanceof Array) {
            xml += "<" + getValueFromEnum(prop, TallyXmlEnum) + ".LIST" + " " + "type=" + '"' + new CapitalizePipe().transform(typeof (prop)) + '"' + ">";
            // tslint:disable-next-line: forin
            for (const array in obj[prop]) {
                xml += "<" + getValueFromEnum(prop, TallyXmlEnum) + ">";
                xml += jsonToXml(new Object(obj[prop][array]));
                xml += "</" + getValueFromEnum(prop, TallyXmlEnum) + ">";
            }
            xml += "</" + getValueFromEnum(prop, TallyXmlEnum) + ".LIST>";
        } else if (typeof obj[prop] == "object") {
            xml += jsonToXml(new Object(obj[prop]));
        } else if (obj[prop] == undefined) {
            // do nothing
            // console.log("undefined props", obj[prop]);
        } else {
            xml += getValueFromEnum(obj[prop], TallyXmlEnum);
        }
        xml += obj[prop] instanceof Array ? '' : "</" + getValueFromEnum(prop, TallyXmlEnum) + ">";
    }
    xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml;
}


export function parseToTallyXML(xml) {
    let txml = "";
    txml += '<?xml version="1.0"?>';
    txml += "<ENVELOPE><HEADER><TALLYREQUEST>Import Data</TALLYREQUEST></HEADER><BODY><IMPORTDATA><REQUESTDESC><REPORTNAME>Vouchers</REPORTNAME>";
    txml += "<STATICVARIABLES><SVCURRENTCOMPANY>Chitra Publicity Company (OOH) Gujarat</SVCURRENTCOMPANY></STATICVARIABLES></REQUESTDESC>";
    txml += '<REQUESTDATA><TALLYMESSAGE xmlns:UDF="TallyUDF">';
    txml += '<VOUCHER REMOTEID="7bdd7720-3e36-46cb-bb25-5c418c05d50b-00010fd0" VCHKEY="7bdd7720-3e36-46cb-bb25-5c418c05d50b-0000aa43:000000c0" VCHTYPE="Sales" ACTION="Create" OBJVIEW="Invoice Voucher View">';
    txml += xml;
    txml += '</VOUCHER></TALLYMESSAGE>';
    txml += '<TALLYMESSAGE xmlns:UDF="TallyUDF"><COMPANY><REMOTECMPINFO.LIST MERGE="Yes"><NAME>7bdd7720-3e36-46cb-bb25-5c418c05d50b</NAME>';
    txml += '<REMOTECMPNAME>Chitra Publicity Company (OOH) Gujarat</REMOTECMPNAME><REMOTECMPSTATE>Gujarat</REMOTECMPSTATE></REMOTECMPINFO.LIST></COMPANY></TALLYMESSAGE>';
    txml += '<TALLYMESSAGE xmlns:UDF="TallyUDF"><COMPANY><REMOTECMPINFO.LIST MERGE="Yes"><NAME>7bdd7720-3e36-46cb-bb25-5c418c05d50b</NAME>';
    txml += '<REMOTECMPNAME>Chitra Publicity Company (OOH) Gujarat</REMOTECMPNAME><REMOTECMPSTATE>Gujarat</REMOTECMPSTATE></REMOTECMPINFO.LIST></COMPANY></TALLYMESSAGE>';
    txml += "</REQUESTDATA></IMPORTDATA></BODY></ENVELOPE>";
    return txml;
}

export function getValueFromEnum(key, enumName) {
    if (enumName[key]) {
        return enumName[key];
    } else {
        return String(key).toUpperCase();
    }
}

export function getIndex(items, item, field) {
    return items.findIndex(rItem => rItem[field] === item[field]);
}

export function getNameWithoutExtension(imageName) {
    return imageName.substring(0, imageName.lastIndexOf('.'));
}

export function getNameWithoutCounter(nameWithoutExtension) {
    const index = nameWithoutExtension.lastIndexOf('[');
    if (index !== -1) {
        return nameWithoutExtension.substring(0, index);
    }
    return nameWithoutExtension;
}

export function getImageExtension(imageName) {
    const splitImage = imageName.split('.');
    return '.' + splitImage[splitImage.length - 1];
}

export function getFileExtension(name) {
    return name.lastIndexOf('.') !== -1 ? name.substring(name.lastIndexOf('.')) : null;
}

