<div class="subscription-plans-container">
  <h3 class="subscription-plans-container__title">Choose Your Plan</h3>
  <div class="subscription-plans">
    <ng-container *ngFor="let package of subscriptionPackages">
      <section class="subscription-plan"
        [ngClass]="{'light-background' : package.name === 'Light', 'pro-background': package.name === 'Pro', 'plus-background': package.name === 'Plus'}">
        <sib-subscription-package [package]="package" (eEmitSelectedPackage)="onSubcriptionBuy($event)">
        </sib-subscription-package>
      </section>
    </ng-container>
  </div>
</div>

<p-dialog *ngIf="showConfirmationDialog" [(visible)]="showConfirmationDialog" [header]="confirmationDialogHeader"
  [width]="350" modal="true" (onHide)="closeConfirmationDialog()" class="confimation-dialog__container">

  Do you want to subscribe this plan?
  <p-footer>
    <button type="button" pButton label="Yes" class="mtab-save margin-hori-5" (click)="subscribePlan()"></button>
    <button type="button" pButton label="No" class="mtab-secondary margin-hori-5"
      (click)="closeConfirmationDialog()"></button>
  </p-footer>

</p-dialog>