import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';
import { CommonKiosksComponent } from './common-kiosks.component';
import { KiosksModule } from '../kiosks/kiosks.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        SharedModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DirectiveModules,
        KiosksModule,
    ],

    declarations: [
        CommonKiosksComponent
    ],

    exports: [
        CommonKiosksComponent
    ]
})

export class CommonKiosksModule { }
