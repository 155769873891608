/**
 * @file  Plan.
 * @author ParthL@meditab.com
 * @copyright Meditab Software 2017
 */

import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { PurchaseOrder } from "../campaigns/purchaseOrder";
import { User } from "../campaigns/user";
import { Industry } from "../industry/industry";
import { Customer } from "../KYC/customer";
import { PptUrlData } from "../ppt/ppt-url-data";
import { MountingPrice } from "./mountingPrice";
import { PlanSummary } from "./planSummary";
import { Price } from "./price";
import { PrintingPrice } from "./printingPrice";
import { Tax } from "./tax";

export class Plan extends SibBaseObject {
  displayName: string;
  customer: Customer = new Customer();
  // Raveena | 16-05-2019 Optimization change
  // hoardingItems: PlanItem[] = [];
  // hoardingGroupItems: PlanItem[] = [];
  campaignStartDate: Date;
  campaignEndDate: Date;
  totalItems: number;
  totalSqFt: number;
  price: Price = new Price();
  printingPrice: PrintingPrice = new PrintingPrice();
  mountingPrice: MountingPrice = new MountingPrice();
  tax: Tax = new Tax();
  discount: number;
  discountedPrice: number;
  summary: PlanSummary = new PlanSummary();
  status: any;
  pending: boolean;
  blockedDate: Date;
  employee: User = new User();
  lspPctDiff: boolean;
  blockedCount: number;
  rotational: boolean;
  // Raveena | 16-05-2019 Optimization change
  // kioskItems: PlanItem[] = [];
  kioskPrintingPrice: PrintingPrice = new PrintingPrice();
  kioskMountingPrice: MountingPrice = new MountingPrice();

  purchaseOrders: PurchaseOrder[] = [];
  // calculateOnMonthlyBasis = true;

  //new inventory changes
  mediaTypeToPrintingPrice: any;
  mediaTypeToMountingPrice: any;
  category: Industry = new Industry();
  pptUrls: PptUrlData = new PptUrlData();
  hasTA: boolean;
}
