import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { SelectItem } from 'primeng/primeng';
import { Subscription } from "rxjs";
import { GridColumn } from '../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../helpers/date.util';
import { RowsReorderUtil } from '../../helpers/rows-reorder.util';
import * as utils from '../../helpers/utils';
import { AddressComparision } from '../../modals/billings/address-comparision';
import { Invoice } from "../../modals/billings/invoice";
import { PurchaseOrder } from "../../modals/campaigns/purchaseOrder";
import { Address } from '../../modals/contracts/address';
import { GstBranchAddress } from '../../modals/KYC/gst-branch-address';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { CalculationService } from '../../services/shared/calculationService';
import { CommonService } from '../../services/shared/common.service';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';
import { SystemService } from '../../services/shared/system.service';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { InvoiceSummaryGridComponent } from '../invoice-summary-grid/invoice-summary-grid.component';
import { BillingUIService } from '../services/billingUI.service';


@Component({
    selector: 'sib-invoice-preview',
    templateUrl: './invoice-preview.component.html',
    styleUrls: ['./invoice-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class InvoicePreviewComponent implements OnInit, AfterViewInit, OnDestroy {

    poDetailsList: PurchaseOrder[] = [];

    _invoice: Invoice = new Invoice();
    @Input() set invoice(invoice) {
        this._invoice = invoice;
        this._invoice = JSON.parse(JSON.stringify(this._invoice));
    }
    get invoice() {
        return this._invoice;
    }

    _tempInvoice: Invoice = new Invoice();
    @Input() set tempInvoice(tempInvoice) {
        this._tempInvoice = tempInvoice;
        this._tempInvoice = JSON.parse(JSON.stringify(this._tempInvoice));
    }

    get tempInvoice() {
        return this._tempInvoice;
    }

    _showChanges: boolean = false;
    @Input() set showChanges(showChanges) {
        this._showChanges = showChanges;
        this._showChanges = JSON.parse(JSON.stringify(this._showChanges));
    }

    get showChanges() {
        return this._showChanges;
    }

    _revert = false;
    @Input() set revert(revert) {
        this._revert = revert;
        this._revert = JSON.parse(JSON.stringify(this._revert));
    }

    get revert() {
        return this._revert;
    }

    _migratedInvoice = false;
    @Input() set migratedInvoice(migratedInvoice) {
        this._migratedInvoice = migratedInvoice;
        this._migratedInvoice = JSON.parse(JSON.stringify(this._migratedInvoice));
    }

    @Input() showSortRearrange = false;

    get migratedInvoice() {
        return this._migratedInvoice;
    }

    _invoiceFormat: InvoiceFormatEnum;
    @Input() set invoiceFormat(invoiceFormat) {
        if (invoiceFormat) {
            this._invoiceFormat = invoiceFormat;
            this._invoiceFormat = JSON.parse(JSON.stringify(this._invoiceFormat));
        }
    }

    get invoiceFormat() {
        return this._invoiceFormat;
    }

    @Input() showChildren: boolean;
    @Input() isInvFormatFullDetail: boolean;

    @Output() eEmitCampAndLspDiff: EventEmitter<any> = new EventEmitter();

    @Output() eEmitRowsOrder: EventEmitter<any> = new EventEmitter();

    @Output() eEmitSelectedInvoiceFormat: EventEmitter<any> = new EventEmitter();

    @ViewChild('summaryGrid')
    summaryGrid: InvoiceSummaryGridComponent;

    isLoading = false;
    subscription: Subscription[] = [];
    currentDate: Date;
    displayRowsRearrangeDialog = false;
    rowsToRearrange: Array<any> = [];
    columnsToRearrange: Array<GridColumn> = [];
    invoiceFormats: SelectItem[] = [];
    selectedInvoiceFormat: any;
    isInvoiceFormatFullDetail: boolean = false; // toggle variable
    fullDetailInvoiceFormat: string = 'FULL_DETAIL';
    addComparision: AddressComparision = new AddressComparision();
    customerAddComparision: AddressComparision = new AddressComparision();
    invoiceOverDueDays: number;
    invoiceOverdueDate: Date;
    tempInvoiceOverdueDate: Date;
    sacCode: string;

    constructor(
        private notificationServcie: NotificatoinsService,
        private invoiceChartService: InvoiceChartService,
        private commonService: CommonService,
        public billingUIService: BillingUIService,
        private systemService: SystemService,
        private calculationService: CalculationService
    ) { }

    ngOnInit() {
        this.isInvoiceFormatFullDetail = (this.checkPermission('readAll') && (this.invoice.status === 'ADMIN_EDIT_PENDING' || this.invoice.status === 'ADMIN_GEN_PENDING' || this.invoice.status === 'ADMIN_CANCEL_PENDING' || this.invoice.status === 'ADMIN_FULL_CN_PENDING') && this.isInvFormatFullDetail) ? true : false;
        this.currentDate = new Date();
        this.setInvoiceFormatDropdown();
        this.getInvoiceOverdueDays();
        this.getSacCode();
    }

    ngAfterViewInit() { }

    getInvoiceOverdueDays() {
        this.systemService.get({ module: 'BILLING' }, '/search/by-module').subscribe(
            (response) => {
                if (response) {
                    this.invoiceOverDueDays = response.filter(param => param['key'] === 'INVOICE_DUE_DAYS')[0]['value'];
                    if (this.invoiceOverDueDays) {
                        if (this.invoice && this.invoice.billGeneratedDate) {
                            this.invoiceOverdueDate = this.calculationService.extendDate(this.calculationService.setToBeginning(this.invoice.billGeneratedDate), this.invoiceOverDueDays);
                        }
                        if (this.tempInvoice && this.tempInvoice.billGeneratedDate) {
                            this.tempInvoiceOverdueDate = this.calculationService.extendDate(this.calculationService.setToBeginning(this.tempInvoice.billGeneratedDate), this.invoiceOverDueDays);
                        }
                    }
                }
            },
            (error) => {
                this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Error");
            }
        );
    }

    getSacCode() {
        this.systemService.get({ module: 'TAX' }, '/search/by-module').subscribe(
            (response) => {
                if (response) {
                    this.sacCode = response.filter(param => param['key'] === 'SAC_CODE')[0]['value'];
                }
            },
            (error) => {
                this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Error");
            }
        );
    }

    setInvoiceFormatDropdown() {
        this.invoiceFormats = utils.createDropdown(InvoiceFormatEnum, false);
        this.setSelectedInvoiceFormat();
    }

    setSelectedInvoiceFormat() {
        if (this.invoice && this.invoice.invoiceFormat) {
            this.selectedInvoiceFormat = this.invoice.invoiceFormat;
            this.onInvoiceFormatChange();
        }
    }

    onInvoiceFormatChange() {
        this.eEmitSelectedInvoiceFormat.emit(this.selectedInvoiceFormat);
    }

    setCampAndLspDiff(event) {
        this.eEmitCampAndLspDiff.emit(event);

    }


    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

    // function for the po details if there are more than one POs
    openPoDetails(invoice) {
        for (let i = 0; i < invoice.purchaseOrders.length; i++) {
            this.poDetailsList = invoice.purchaseOrders;
        }
    }

    compareValues(value1, value2) {
        // return (JSON.parse(JSON.stringify(value1)) === JSON.parse(JSON.stringify(value2))) ? false : true;
        return (JSON.stringify(value1) === JSON.stringify(value2)) ? false : true;
    }

    getFormattedDate(date) {
        return DateUtil.dategridFormatter(date);
    }

    /**
     * @description to sort the rows in default mode - by city
     * called when default sort button is clicked
     * @author Pulkit Bansal
     * @date 2019-10-06
     * @memberof InvoicePreviewComponent
     */
    onDefaultSort() {
        // this.isLoading = true;
        this.summaryGrid.onDefaultSort();
        // this.isLoading = false;
    }

    /**
     * @description to show the rearrange rows dialog
     * called when rearrange rows button is clicked
     * @author Pulkit Bansal
     * @date 2019-10-06
     * @memberof InvoicePreviewComponent
     */
    showRowsRearrangeDialog() {
        this.setRowsToRearrange(this.summaryGrid.getInvoiceGridOrder());
        this.columnsToRearrange = RowsReorderUtil.getColsToDisplay(this.summaryGrid.columns, "Invoice", true);
        this.rowsToRearrange = RowsReorderUtil.generateValuesForRearrangement(this.columnsToRearrange, this.rowsToRearrange, "Invoice");


        if (this.rowsToRearrange && this.rowsToRearrange.length && this.columnsToRearrange && this.columnsToRearrange.length) {
            this.displayRowsRearrangeDialog = true;
        } else {
            this.notificationServcie.info("Rows Reorder", "No rows present to reorder");
        }

    }

    /**
     * @description to hide the rearrange rows dialog
     * called when rearrange rows dialog is closed
     * @author Pulkit Bansal
     * @date 2019-10-06
     * @memberof InvoicePreviewComponent
     */
    hideRowsRearrangeDialog() {
        this.displayRowsRearrangeDialog = false;
        // to reset the amount column header from cost to package rate
        this.summaryGrid.setColumns(false);
    }

    /**
     * @description set the rows of the array into an array of select item
     * @author Pulkit Bansal
     * @date 2019-10-06
     * @param {Array<any>} rows
     * @memberof InvoicePreviewComponent
     */
    setRowsToRearrange(rows: Array<any>) {
        this.rowsToRearrange = rows;
        this.isLoading = false;
    }

    /**
     * @description to update the order of rows in the invoice
     * @author Pulkit Bansal
     * @date 2019-10-09
     * @param {string[]} rowsOrder
     * @memberof InvoicePreviewComponent
     */
    updateRowsOrder(rowsOrder: string[]) {
        this.isLoading = true;
        this._invoice.rowsOrder = rowsOrder;
        this.eEmitRowsOrder.emit(rowsOrder);
        this._invoice = JSON.parse(JSON.stringify(this._invoice));
        this.summaryGrid.updateItemsOrder(rowsOrder);
        // check if invoice is being generated or already generated
        if (this.invoice.status) {
            this.saveRowsConfig();
        } else {
            this.isLoading = false;
        }
    }

    /**
     * @description to save the updated order in db
     * @author Pulkit Bansal
     * @date 2019-10-09
     * @memberof InvoicePreviewComponent
     */
    saveRowsConfig() {
        // this.invoiceChartService.create(
        //     this.invoice.rowsOrder, { type: (this.migratedInvoice ? SIBConstants.MIGRATED_INVOICE_PARAM : SIBConstants.INVOICE_PARAM), invoiceId: this.invoice.id }, AppUrls.SAVE_ROWS_ORDER
        // ).pipe()
        //     .subscribe(
        // (response) => {
        //     this.notificationServcie.success("Rows Reordered Successfully", "Rows Reorder");
        //     this.isLoading = false;
        // },
        // (error) => {
        //     this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        //     this.isLoading = false;
        // }
        //     );
        this.commonService.saveBillingRowsConfig(this.invoice.rowsOrder, this.migratedInvoice ? SIBConstants.MIGRATED_INVOICE_PARAM : SIBConstants.INVOICE_PARAM, this.invoice.id)
            .subscribe(
                (response) => {
                    this.notificationServcie.success("Rows Reordered Successfully", "Rows Reorder");
                    this.isLoading = false;
                },
                (error) => {
                    this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                    this.isLoading = false;
                }
            );
    }

    /**
     * @description check whether the user has specific permission or not
     * @author Divya Sachan
     * @date 2019-12-10
     * @param {*} permission
     * @returns
     * @memberof InvoicePreviewComponent
     */
    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    getAddressComparisonObject() {
        if (this.invoice) {
            if (this.invoice.supplier && this.invoice.supplier.supplierGstDetail) {
                if (this.invoice.supplier.supplierGstDetail.gstNo) {
                    this.addComparision.invGstNo = this.invoice.supplier.supplierGstDetail.gstNo;
                    if (this.invoice.supplier.supplierGstDetail.branchAddress) {
                        this.createComparisionObject(this.addComparision.invAdd, this.invoice.supplier.supplierGstDetail.branchAddress);
                    }
                } else {
                    this.addComparision.invGstNo = 'N/A';
                    if (this.invoice.supplier.address) {
                        this.createComparisionObject(this.addComparision.invAdd, this.invoice.supplier.address);
                    }
                }
                this.addComparision.invAdd2 = this.createComparisionObjectAddress(this.addComparision.invAdd);
                this.addComparision.invStateWithCode = this.createComparisionObjectStateWithCode(this.addComparision.invAdd);
            }
        }
        if (this.tempInvoice) {
            if (this.tempInvoice.supplier && this.tempInvoice.supplier.supplierGstDetail) {
                if (this.tempInvoice.supplier.supplierGstDetail.gstNo) {
                    this.addComparision.tempInvGstNo = this.tempInvoice.supplier.supplierGstDetail.gstNo;
                    if (this.tempInvoice.supplier.supplierGstDetail.branchAddress) {
                        this.createComparisionObject(this.addComparision.tempInvAdd, this.tempInvoice.supplier.supplierGstDetail.branchAddress);
                    }
                } else {
                    this.addComparision.tempInvGstNo = 'N/A';
                    if (this.tempInvoice.supplier.address) {
                        this.createComparisionObject(this.addComparision.tempInvAdd, this.tempInvoice.supplier.address);
                    }
                }
                this.addComparision.tempInvAdd2 = this.createComparisionObjectAddress(this.addComparision.tempInvAdd);
                this.addComparision.tempInvStateWithCode = this.createComparisionObjectStateWithCode(this.addComparision.tempInvAdd);
            }
        }
        return this.addComparision;
    }

    createComparisionObject(targetObj: Address, sourceObj: Address | GstBranchAddress) {
        targetObj.address1 = sourceObj.address1;
        targetObj.address2 = sourceObj.address2;
        targetObj.city = sourceObj.city;
        targetObj.district = sourceObj.district;
        targetObj.state = sourceObj.state;
        targetObj.postalCode = sourceObj.postalCode;
    }

    createComparisionObjectAddress(sourceObj: Address) {
        let targetObj = "";
        if (sourceObj.address2) {
            if (sourceObj.city && sourceObj.city.name) {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.address2 + ', ' + sourceObj.city.name + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.address2 + ', ' + sourceObj.city.name;
                }
            } else {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.address2 + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.address2;
                }
            }
        } else {
            if (sourceObj.city && sourceObj.city.name) {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.city.name + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.city.name;
                }
            } else {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.postalCode;
                } else {
                    targetObj = '-';
                }
            }
        }
        return targetObj;
    }

    createComparisionObjectStateWithCode(sourceObj: Address) {
        let targetObj = "";
        if (sourceObj.state && sourceObj.state.name) {
            if (sourceObj.state.code) {
                targetObj = "State Name: " + sourceObj.state.name + ', ' + 'Code: ' + sourceObj.state.code;
            } else {
                targetObj = "State Name: " + sourceObj.state.name + ', ' + 'Code: ' + 'N/A';
            }
        } else {
            if (sourceObj.state && sourceObj.state.code) {
                targetObj = "State Name: " + 'N/A' + ', ' + 'Code: ' + sourceObj.state.code;
            } else {
                targetObj = "State Name: " + 'N/A' + ', ' + 'Code: ' + 'N/A';
            }
        }
        return targetObj;
    }

    getCustomerAddComparisionObj() {
        if (this.invoice) {
            if (this.invoice.invoiceGstDetail) {
                if (this.invoice.invoiceGstDetail.gstNo) {
                    this.customerAddComparision.invGstNo = this.invoice.invoiceGstDetail.gstNo;
                    if (this.invoice.invoiceGstDetail.branchAddress) {
                        this.createComparisionObject(this.customerAddComparision.invAdd, this.invoice.invoiceGstDetail.branchAddress);
                    }
                } else {
                    this.customerAddComparision.invGstNo = "N/A";
                    if (this.invoice.campaign.customer) {
                        this.createComparisionObject(this.customerAddComparision.invAdd, this.invoice.campaign.customer);
                    }
                }
                this.customerAddComparision.invAdd2 = this.createComparisionObjectAddress(this.customerAddComparision.invAdd);
                this.customerAddComparision.invStateWithCode = this.createComparisionObjectStateWithCode(this.customerAddComparision.invAdd);
            }
        }
        if (this.tempInvoice) {
            if (this.tempInvoice.invoiceGstDetail) {
                if (this.tempInvoice.invoiceGstDetail.gstNo) {
                    this.customerAddComparision.tempInvGstNo = this.tempInvoice.invoiceGstDetail.gstNo;
                    if (this.tempInvoice.invoiceGstDetail.branchAddress) {
                        this.createComparisionObject(this.customerAddComparision.tempInvAdd, this.tempInvoice.invoiceGstDetail.branchAddress);
                    }
                } else {
                    this.customerAddComparision.tempInvGstNo = "N/A";
                    if (this.tempInvoice.campaign.customer) {
                        this.createComparisionObject(this.customerAddComparision.tempInvAdd, this.tempInvoice.campaign.customer);
                    }
                }
                this.customerAddComparision.tempInvAdd2 = this.createComparisionObjectAddress(this.customerAddComparision.tempInvAdd);
                this.customerAddComparision.tempInvStateWithCode = this.createComparisionObjectStateWithCode(this.customerAddComparision.tempInvAdd);
            }
        }
        return this.customerAddComparision;
    }
}
