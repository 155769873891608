import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { GridColumn } from '../../../components/sib-forms/grid/grid.config';

/**
 * @description implements order list primeng component to order a list
 * @author Pulkit Bansal
 * @date 2019-10-06
 * @export
 * @class RowsRearrangeComponent
 */
@Component({
    selector: 'sib-rows-rearrange',
    templateUrl: './rows-rearrange.component.html',
    styleUrls: ['./rows-rearrange.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RowsRearrangeComponent implements OnInit, OnDestroy {

    rentRows: Array<any> = [];
    printingRows: Array<any> = [];
    mountingRows: Array<any> = [];
    rentHSNCode: number;
    printHSNCode: number;
    mountHSNCode: number;

    /**
     * @description array of rows to rearrange
     * @type {Array<any>}
     * @memberof RowsRearrangeComponent
     */
    // @Input() rows: Array<any> = [];

    _rows: Array<any> = [];
    @Input() set rows(value: Array<any>) {
        this._rows = value;
        this.rentRows = this._rows.filter(item => item.type === "Rent");
        this.printingRows = this._rows.filter(item => item.type === "Printing");
        this.mountingRows = this._rows.filter(item => item.type === "Mounting");

        this.rentHSNCode = this.rentRows.length > 0 ? this.rentRows.find(item => item.hsnCode !== 0 || item.hsnCode !== null || item.hsnCode !== undefined).hsnCode : 0;
        this.printHSNCode = this.printingRows.length > 0 ? this.printingRows.find(item => item.hsnCode !== 0 || item.hsnCode !== null || item.hsnCode !== undefined).hsnCode : 0;
        this.mountHSNCode = this.mountingRows.length > 0 ? this.mountingRows.find(item => item.hsnCode !== 0 || item.hsnCode !== null || item.hsnCode !== undefined).hsnCode : 0;
    }

    get rows() {
        return this._rows;
    }

    /**
      * @description setting the _columns
      * @memberof RowsRearrangeComponent
      */
    @Input() set columns(columns: Array<GridColumn>) {
        this._columns = columns;
        if (this.header) {
            this._columns.filter((col) => {
                col = this.setColumnWidth(col);
            });
        }
    }

    get columns() {
        return this._columns;
    }

    /**
     * @description header for the order list
     * @memberof RowsRearrangeComponent
     */
    @Input() set header(header: string) {
        this._header = header;
        if (this.columns) {
            this._columns.filter((col) => {
                col = this.setColumnWidth(col);
            });
        }
    }

    get header() {
        return this._header;
    }

    @Input() displayRowsRearrangeDialog: boolean;

    /**
     * @description to emit the new order of rows
     * @type {EventEmitter<any>}
     * @memberof RowsRearrangeComponent
     */
    @Output() eEmitSaveRowsConfig: EventEmitter<any> = new EventEmitter();

    /**
     * @description to close the dialog
     * @type {EventEmitter<boolean>}
     * @memberof RowsRearrangeComponent
     */
    @Output() eEmitCancelRowsConfig: EventEmitter<boolean> = new EventEmitter();

    _columns: Array<GridColumn> = [];

    _header: string = null;

    /**
      * @description array of item ids based on the current row order
      * @type {String[]}
      * @memberof RowsRearrangeComponent
      */
    rowsOrder: String[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    /**
     * @description to get the value corresponding to a particular column for a row
     * @param {any} row
     * @param {string} field
     * @returns {string}
     * @memberof RowsRearrangeComponent
     */
    getValueForACol(row: any, field: string): string {
        if (row && field) {
            return row[field];

        }
        return;
    }



    /**
     * @description to set the width of a column
     * 
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @param {*} column
     * @memberof RowsRearrangeComponent
     */
    setColumnWidth(column: any) {
        switch (this.header) {
            case "Invoice Rows": {
                switch (column.field) {
                    case "srNo": {
                        column.width = '2vw';
                        break;
                    }
                    case "inventory": {
                        column.width = (this.columns.length === 8) ? "55.6vw" : (this.columns.length === 7 ? "60.6vw" : (this.columns.length === 3 ? "83vw" : "78vw"));
                        break;
                    }
                    case "hsnCode": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "6vw" : "6vw";
                        break;
                    }
                    case "itemStartDate": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "6vw" : "0%";
                        break;
                    }
                    case "itemEndDate": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "6vw" : "0%";
                        break;
                    }
                    case "days": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "4vw" : "0%";
                        break;
                    }
                    case "rate": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "7vw" : "0%";
                        break;
                    }
                    case "amount": {
                        column.width = (this.columns.length === 8 || this.columns.length === 7) ? "5vw" : "5vw";
                        break;
                    }
                }
                return column;
                break;
            }
        }
    }


    saveRowsConfig() {
        this.rowsOrder = [...this.rentRows.map(row => row.id), ...this.printingRows.map(row => row.id), ...this.mountingRows.map(row => row.id)];
        this.eEmitSaveRowsConfig.emit(this.rowsOrder);
        this.cancelRowsConfig();
    }

    cancelRowsConfig() {
        this.eEmitCancelRowsConfig.emit(false);
    }

    onRowsReorder(event, type) {
        switch (type) {
            case 'Rent': {
                this.rentRows.forEach((item, index) => {
                    index === 0 ? item.hsnCode = this.rentHSNCode : item.hsnCode = null;
                });
                break;
            }
            case 'Printing': {
                this.printingRows.forEach((item, index) => {
                    index === 0 ? item.hsnCode = this.printHSNCode : item.hsnCode = null;
                });
                break;
            }
            case 'Mounting': {
                this.mountingRows.forEach((item, index) => {
                    index === 0 ? item.hsnCode = this.mountHSNCode : item.hsnCode = null;
                });
                break;
            }
        }
    }


    ngOnDestroy() {
        this.columns = [];
        this.rows = [];
        this.rentRows = [];
        this.printingRows = [];
        this.mountingRows = [];
    }

}
