import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./chart.base.service";
import { TableChartConfig } from "../../modals/charts/tableChart.config";

declare var google: any;

@Injectable()
export class GoogleTableChartService extends GoogleChartsBaseService {

  constructor() { super(); }

  public buildTableChart(elementId: String, data: any, config: TableChartConfig): void {
    const chartFunc = () => new google.visualization.Table(document.getElementById(elementId + ''));

    const options = {
      alternatingRowStyle: config.alternatingRowStyle,
      cssClassNames: config.cssClassNames,
      firstRowNumber: config.firstRowNumber,
      frozenColumns: config.frozenColumns,
      height: config.height,
      showRowNumber: config.showRowNumber,
      sort: config.sort,
      sortAscending: config.sortAscending,
      sortColumn: config.sortColumn,
      width: config.width
    }
    this.buildChart(data, chartFunc, options);
  }
}
