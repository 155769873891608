import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { Inventory } from '../hoardings/inventory';

export class RentItem extends CampaignItemWrapper {
    itemStartDate: Date;
    itemEndDate: Date;
    validationItemStartDate: Date;
    validationItemEndDate: Date;
    days: number;
    actualAmount: number;
    isGlobalSet?: boolean;
    globalRateSet?: boolean;
    note: string;
    campaignItemId: string;
    rateChanged: boolean;
    childInventories: any[] = [];
    // hsnCode?: number;
}
