import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GridConfig } from "../../../components/sib-forms/grid/grid.config";
import { Inventory } from "../../../modals/hoardings/inventory";
import { LoaderSubjects } from "../../../modals/loader-subjects/loader-subjects";
import { PPTLoader } from "../../../modals/loader-subjects/pptloader";
import { MediaTypeEnum } from "../../constants/media-type-enum";
import { KiosksComponent } from "../kiosks/kiosks.component";

@Component({
  selector: "sib-common-kiosks",
  templateUrl: "./common-kiosks.component.html",
  styleUrls: ["./common-kiosks.component.css"],
})
export class CommonKiosksComponent implements OnInit {
  @ViewChild("sibParentGrid") gridParentViewRef: KiosksComponent;
  @ViewChild("sibChildrenGrid") gridChildViewRef: KiosksComponent;

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
  }
  get isExpired() {
    return this._isExpired;
  }

  _gridTitle: string;
  @Input() set gridTitle(gridTitle) {
    this._gridTitle = gridTitle;
  }
  get gridTitle() {
    return this._gridTitle;
  }

  _searchEvent: any;
  @Input() set searchEvent(searchEvent) {
    this._searchEvent = searchEvent;
    this.createLoaderObject("XLS", this.xlsLoader);
  }
  get searchEvent() {
    return this._searchEvent;
  }

  @Input() totalRecords: number;
  @Input() gridConfig: GridConfig<Inventory> = new GridConfig();
  @Input() columns: any[];
  @Input() showFilter: boolean;
  @Input() source: string;
  @Input() showGroups: boolean;
  @Input() sidebarValues: any[];
  @Input() hideCommonInventoryDialog: boolean = false;
  @Input() advanceSearch: any = {};

  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitEditKiosk: EventEmitter<any> = new EventEmitter();
  @Output() eEmitShowGroups: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckKiosk: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewHistory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImageUrl: EventEmitter<any> = new EventEmitter();

  selectedChildKiosk: any[] = [];
  selectedParentKiosk: any[] = [];
  isFileDownloading: boolean = false;
  xlsLoader: PPTLoader = new PPTLoader();

  constructor(private loaderSubjects: LoaderSubjects) {}

  ngOnInit() {}

  /**
   * @description view images of kiosks
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  viewImages(event) {
    this.eEmitViewImage.emit(event);
  }

  /**
   * @description view statusqueue of kiosks
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  viewDetail(event) {
    this.eEmitViewDetail.emit(event);
  }

  /**
   * @description edit kiosks
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  editInventory(event) {
    this.eEmitEditKiosk.emit(event);
  }

  /**
   * @description show kiosk groups
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  showKioskGroups(event) {
    this.eEmitShowGroups.emit(event);
    // this.eRowSelection.emit(this.selectedKiosks);
  }

  /**
   * @description refresh grid
   * @author Divya Sachan
   * @date 2020-01-28
   * @memberof CommonKiosksComponent
   */
  refreshGrid() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.refreshGrid();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.refreshGrid();
    }
  }

  /**
   * @description update user grid column config
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} selectedColumns
   * @memberof CommonKiosksComponent
   */
  updatingUserGridConfig(selectedColumns) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.updatingUserGridConfig(selectedColumns);
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.updatingUserGridConfig(selectedColumns);
    }
  }

  /**
   * @description set columns
   * @author Divya Sachan
   * @date 2020-01-28
   * @memberof CommonKiosksComponent
   */
  setColumns() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.setColumns();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.setColumns();
    }
  }

  /**
   * @description on child Kiosk selection
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  onChildRowSelection(event) {
    this.selectedChildKiosk = event;
    this.eRowSelection.emit([
      ...this.selectedParentKiosk,
      ...this.selectedChildKiosk,
    ]);
  }

  /**
   * @description on parent Kiosk selection
   * @author Divya Sachan
   * @date 2020-01-29
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  onParentRowSelection(event) {
    this.selectedParentKiosk = event;
    this.eRowSelection.emit([
      ...this.selectedParentKiosk,
      ...this.selectedChildKiosk,
    ]);
  }

  // /**
  //  * @description on kiosk selection
  //  * @author Divya Sachan
  //  * @date 2020-01-28
  //  * @param {*} event
  //  * @memberof CommonKiosksComponent
  //  */
  // onKioskSelection(event) {
  //   this.selectedKiosks = [...this.selectedKiosks, ...event];
  //   let kiosks = Array.from(new Set(this.selectedKiosks.map(item => item.id)));
  //   let selectedKiosks = [];
  //   kiosks.forEach(kioskId => {
  //     selectedKiosks.push(this.selectedKiosks.find(item => item.id === kioskId));
  //   });
  //   this.selectedKiosks = selectedKiosks;
  //   this.eRowSelection.emit(this.selectedKiosks);
  // }

  /**
   * @description push value to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} value
   * @memberof CommonKiosksComponent
   */
  addBackToKioskList(data, value) {
    if (this.gridChildViewRef && !value.hasChild) {
      this.gridChildViewRef.addBackToKioskList(data, value);
    }
    if (this.gridParentViewRef && value.hasChild) {
      this.gridParentViewRef.addBackToKioskList(data, value);
    }
  }

  /**
   * @description push values to the grid
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} data
   * @param {*} values
   * @memberof CommonKiosksComponent
   */
  addMultipleBackToKioskList(data, values) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.addMultipleBackToKioskList(
        data,
        this.getChildren(values)
      );
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.addMultipleBackToKioskList(
        data,
        this.getParent(values)
      );
    }
    // this.gridViewChildRef.pushMultipleValues(data, values);
  }

  /**
   * @description filter data
   * @author Divya Sachan
   * @date 2020-01-27
   * @param {*} totalRecords
   * @param {*} values
   * @param {*} comparator
   * @memberof CommonKiosksComponent
   */
  filterData(totalRecords, values, comparator) {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.filterData(
        totalRecords,
        this.getChildren(values),
        comparator
      );
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.filterData(
        totalRecords,
        this.getChildren(values),
        comparator
      );
    }
  }

  /**
   * @description reset selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonKiosksComponent
   */
  resetSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.selected = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.selected = [];
    }
  }

  resetAndReloadTable() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.resetAndReloadTable();
    }
  }

  refresh() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.refreshFormTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.refreshFormTable();
    }
  }

  /**
   * @description reset previously selected rows
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonKiosksComponent
   */
  resetPreviousSelectedRows() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.gridViewChildRef.previousSeleceted = [];
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.gridViewChildRef.previousSeleceted = [];
    }
  }

  /**
   * @description reset datatable
   * @author Divya Sachan
   * @date 2020-01-27
   * @memberof CommonKiosksComponent
   */
  resetDataTable() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.resetDataTable();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.resetDataTable();
    }
  }

  setSelectedRows() {
    if (this.gridChildViewRef) {
      if (this.gridChildViewRef.gridViewChildRef) {
        // this.gridChildViewRef.gridViewChildRef.selected = this.getChildren(this.selectedKiosk);
        this.gridChildViewRef.gridViewChildRef.selected = this.selectedChildKiosk;
      }
    }
    if (this.gridParentViewRef) {
      if (this.gridParentViewRef.gridViewChildRef) {
        // this.gridParentViewRef.gridViewChildRef.selected = this.getParent(this.selectedKiosk);
        this.gridParentViewRef.gridViewChildRef.selected = this.selectedParentKiosk;
      }
    }
  }

  /**
   * @description reset selected Kiosk
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} rowData
   * @memberof CommonKiosksComponent
   */
  resetSelectedRow(rowData) {
    if (rowData.hasChild) {
      this.selectedParentKiosk = this.selectedParentKiosk.filter(
        (item) => item.id !== rowData.id
      );
    }
    if (!rowData.hasChild) {
      this.selectedChildKiosk = this.selectedChildKiosk.filter(
        (item) => item.id !== rowData.id
      );
    }
    this.setSelectedRows();
  }

  /**
   * @description get parent's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonKiosksComponent
   */
  getParent(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === true)
      : [];
  }

  /**
   * @description get children's list
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} data
   * @returns
   * @memberof CommonKiosksComponent
   */
  getChildren(data) {
    return data && data.length
      ? data.filter((item) => item.hasChild === false)
      : [];
  }

  /**
   * @description export xls for Kiosk
   * @author Divya Sachan
   * @date 2020-01-30
   * @memberof CommonKiosksComponent
   */
  exportXlsx(event) {
    if (event) {
      this.isFileDownloading = true;
      this.createLoaderObject("XLS", this.xlsLoader);
      if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
        this.loaderSubjects.advanceSearchKioskXlsLoader.next(this.xlsLoader);
      } else {
        this.loaderSubjects.kioskXlsLoader.next(this.xlsLoader);
      }
      this.isFileDownloading = false;
    }
  }

  /**
   * @description loader object for Xls
   * @author Divya Sachan
   * @date 2020-01-30
   * @param {*} fileType
   * @param {*} object
   * @memberof CommonKiosksComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = "Downloading XLS...";
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
    object.source = this.source;
    object.mediaType = MediaTypeEnum.KIOSK;
  }

  /**
   * @description
   * @author Divya Sachan
   * @date 2020-02-03
   * @memberof CommonKiosksComponent
   */
  getUserGridConfig() {
    if (this.gridChildViewRef) {
      this.gridChildViewRef.getUserGridConfig();
    }
    if (this.gridParentViewRef) {
      this.gridParentViewRef.getUserGridConfig();
    }
  }

  /**
   * @description check Kiosk when adding from campaign
   * @author Divya Sachan
   * @date 2020-02-10
   * @param {*} event
   * @memberof CommonKiosksComponent
   */
  checkKiosk(event) {
    this.eEmitCheckKiosk.emit(event);
  }

  viewHistory(event) {
    this.eEmitViewHistory.emit(event);
  }

  viewImageOnHover(event) {
    this.eEmitImageUrl.emit(event);
  }
}
