<sib-grid #sibGrid [columns]="columns" [config]="gridConfig" [selectedColumn]="hmSelectedColumns" [showFilter]="showFilter" [totalRecords]="totalRecords" (eEmitColumnSelection)="updatingUserGridConfig($event)" [title]="title">
    <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <span>
        {{rowIndex+1}}
      </span>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="invoiceId">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData.invoiceId; else dashTemplate">
                <div *ngIf="authorized">
                    <a class="link-1  after" target="_blank" (click)="getInvoice(rowData)">{{rowData.invoiceId}}</a>
                </div>
                <div *ngIf="!authorized">
                    {{rowData.invoiceId}}
                </div>
            </div>
            <ng-template #dashTemplate>
                -
            </ng-template>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="campaignItem.lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="rentItems.lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="printingItems.lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="mountingItems.lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="campaign.displayName">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData.campaign.displayName; else dashTemplate">
                <div *ngIf="authorized">

                    <a class="link-1  after" target="_blank" (click)="getSuperbill(rowData)">{{rowData.campaign.displayName}}</a>
                </div>
                <div *ngIf="!authorized">
                    {{rowData.campaign.displayName}}
                </div>
            </div>
            <ng-template #dashTemplate>
                -
            </ng-template>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="status">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="invoiceStatusDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Status" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="remove">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <i class="fa fa-times" (click)="removeHoarding(rowIndex)" style="cursor: pointer"></i>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="mountingItems.imageStatus">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect [options]="mountingItemImageStatusDropdown" [style]="{'width':'100%','margin-top':'8px'}" name="imageStatus" maxSelectedLabels="1" selectedItemsLabel="{0} items" appendTo="body" placeholder="Search" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="mountingItems.mounter.fullName">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect #mounterdd [options]="mounters" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" maxSelectedLabels="1" selectedItemsLabel="{0} items" placeholder="Choose" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="unmountingItems.imageStatus">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect [options]="unmountingItemImageStatusDropdown" [style]="{'width':'100%','margin-top':'8px'}" name="imageStatus" maxSelectedLabels="1" selectedItemsLabel="{0} items" appendTo="body" placeholder="Choose" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="unmountingItems.mounter.fullName">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect #mounterdd [options]="mounters" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" maxSelectedLabels="1" selectedItemsLabel="{0} items" placeholder="Choose" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column>
    <sib-grid-column field="monitoringItems.status">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect [options]="monitoringItemImageStatusDropdown" [style]="{'width':'100%','margin-top':'8px'}" name="imageStatus" maxSelectedLabels="1" selectedItemsLabel="{0} items" appendTo="body" placeholder="Choose" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column>
    <!-- <sib-grid-column field="monitoringItems.photographer.fullName">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-multiSelect #mounterdd [options]="mounters" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" maxSelectedLabels="1" selectedItemsLabel="{0} items" placeholder="Choose" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-multiSelect>
        </ng-template>
    </sib-grid-column> -->
</sib-grid>