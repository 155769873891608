import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { ReportsComponent } from './reports.component';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { ExpensesBreakupComponent } from './expenses-breakup/expenses-breakup.component';
import { MounterReportComponent } from './mounter-report/mounter-report.component';

const routes: Routes = [
    {
        path: '', component: ReportsComponent, children: [
            { path: 'expense-report', component: ExpenseReportComponent, data: { title: "Expense Report" } },
            { path: 'expenses-breakup', component: ExpensesBreakupComponent, data: { title: 'Expense Breakup' } },
            { path: 'mounter-report', component: MounterReportComponent, data: { title: 'Mounter Report' } }
        ]
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class ReportsRoutingModule { }
