import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Contracts } from "../contracts/contracts";
import { Picture } from "./picture";
import { GeoCoordinate } from "./geo-coordinate";
import { Area } from "../Area/area";
import { Media } from "./media";
import { StatusQueue } from "./status-queue";
import { HoardingStatusEnum } from "../../shared/constants/hoarding-status-enum";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { HoardingCategoryEnum } from "../../shared/constants/hoarding-category-enum";
import { InventoryArea } from "../Area/inventoryArea";
import { InventoryContract } from "../contracts/inventoryContract";
import { PersonInfo } from "../contracts/personinfo";
import { InventoryElevation } from "../../shared/constants/inventory-elevation";
import { SibDocument } from "../sib-document";
import { AuthorityTax } from "./authority-tax";
import { PumpCategoryEnum } from "../../shared/constants/pump-category-enum";
import { KioskTypeEnum } from "../../shared/constants/kiosk-type.enum";
import { InventoryType } from "./inventory-type";
import { Inventory } from "./inventory";

export class AbstractInventory extends Media {
  //Raveena | 16-05-2019 Optimzation change
  // contract: Contracts = new Contracts();
  contract: InventoryContract = new InventoryContract();
  district: string;
  city: string;
  state: string;
  areaName: string;
  //Raveena | 16-05-2019 Optimzation change
  // area: Area = new Area();
  area: InventoryArea = new InventoryArea();
  location: string;
  geoCoordinate: GeoCoordinate = new GeoCoordinate();
  width: number;
  height: number;
  size: number;
  squareFeet: number;
  category: HoardingCategoryEnum;
  lightType: HoardingLightEnum;
  kioskType: KioskTypeEnum;
  startDate: Date;
  endDate: Date;
  grossPrice: number;
  minimumPrice: number;
  status: HoardingStatusEnum;
  images: Picture[] = [];
  campaignStartDate: Date;
  campaignEndDate: Date;
  statusQueues: StatusQueue[] = [];

  documents: SibDocument[] = [];
  elevation: InventoryElevation;
  elevationHeight: number;
  numberOfStories: number;
  storiesStartFrom: number;
  storiesEndAt: number;
  gpsAzimuth: number;
  eMeterNo: string;
  contractor: PersonInfo = new PersonInfo();
  siteEngineer: PersonInfo = new PersonInfo();
  authorityTax: AuthorityTax = new AuthorityTax();
  pumpName: string;
  pumpCode: string;
  pumpCategory: PumpCategoryEnum;

  oldCustomId: string;
  mediaType: string;
  hasChild: boolean;

  childInventories: Inventory[] = [];
  hasDimension: boolean;
  inventoryType: InventoryType;
  manualInactive: boolean = false;
}
