<!-- <p-confirmDialog header="Information"  width="425" acceptLabel="OK" [rejectVisible]="false"> -->
<!-- </p-confirmDialog> -->
<!-- Displaying the user Details Dialog when user logins for the first time -->
<sib-user-details *ngIf="displayUserDetailDialog" [user]="user" [display]="displayUserDetailDialog" (eSave)="saveUser($event)" (eEmit)="closeDialog()"></sib-user-details>


<router-outlet></router-outlet>

<div *ngIf="!(online$ | async)" class="connection-message">
    There is no internet connection.
    <br> Connect with your internet and try again.
</div>

<p-dialog header="Information" *ngIf="showRefreshScreenDialog" [(visible)]="showRefreshScreenDialog" modal="true" [width]="280" [closable]="false" [blockScroll]="true" [autoAlign]="false" [styleClass]="'connection-message-dialog'">
    <div class="connection-message-wrapper text-align-center">
        <div>Connection established.</div>
        <div class="margin-10"><button pButton label="Refresh" class="mtab-primary" (click)="refreshPage()"></button>
        </div>
    </div>
</p-dialog>
<!-- 
<p-dialog *ngIf="!subscriptionActive && !checkForCurrentPageUrl() && authenticated" header="Information"
  [closable]="false" modal="true" [width]="350" [(visible)]="!subscriptionActive" [blockScroll]="true"
  [autoAlign]="false">
  {{subscriptionActive === null ? 'Subscribe your Subscription Plan first.' : 'Your Subscription Plan has been expired. Subscribe it'}}

  <p-footer>
    <button type="button" pButton label="Continue" class="mtab-save margin-hori-5"
      (click)="goToSubscriptionPlansPage()"></button>
  </p-footer>
</p-dialog> -->