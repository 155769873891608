import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as utils from '../../helpers/utils';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { GridConfig, GridPaginationEvent, SelectionMode } from '../../components/sib-forms/grid/grid.config';
import { MountingMaster } from '../../modals/mounting/mounting-master';
import { MonitoringMaster } from '../../modals/monitoring/monitoring-master';
import { CampaignItem } from '../../modals/campaigns/campaignItem';
import { CampaignItemService } from '../../services/shared/campaignItems.service';
import { AppUrls } from '../../services/urls';
import { catchError, map } from 'rxjs/operators';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { empty as observableEmpty } from 'rxjs';
import * as mountingReportColumnConfig from '../../modals/column-configurations/mounting-report-column-config';
import * as monitoringReportColumnConfig from '../../modals/column-configurations/monitoring-report-column-config';
import * as unmountingReportColumnConfig from '../../modals/column-configurations/unmounting-report-column-config';
import { ViewInventoryComponent } from '../../shared/components/view-inventory/view-inventory.component';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';

@Component({
  selector: 'sib-mounter-report',
  templateUrl: './mounter-report.component.html',
  styleUrls: ['./mounter-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MounterReportComponent implements OnInit {

  @ViewChild('mountingReport') mountingReportGrid: ViewInventoryComponent;
  @ViewChild('monitoringReport') monitoringReportGrid: ViewInventoryComponent;
  @ViewChild('unmountingReport') unmountingReportGrid: ViewInventoryComponent;

  title: string = "Mounter Report";
  calendarStartDate = new Date();
  calendarEndDate = new Date();
  selectedTabIndex: number = 0;

  mountingColumns: any[] = [];
  monitoringColumns: any[] = [];
  unmountingColumns: any[] = [];

  mountingGridConfig: GridConfig<MountingMaster> = new GridConfig();
  monitoringGridConfig: GridConfig<MonitoringMaster> = new GridConfig();
  unmountingGridConfig: GridConfig<CampaignItem> = new GridConfig();

  totalMountingRecords: number = 0;
  totalMonitoringRecords: number = 0;
  totalUnmountingRecords: number = 0;
  paginationEvent: GridPaginationEvent;

  xlsLoader: PPTLoader = new PPTLoader();
  resetXlsLoader: PPTLoader = new PPTLoader();

  mountingSearchEvent: GridPaginationEvent;
  monitoringSearchEvent: GridPaginationEvent;
  unmountingSearchEvent: GridPaginationEvent;

  minDate: Date;

  constructor(
    private pageTitle: Title,
    private campaignItemService: CampaignItemService,
    private notificationService: NotificatoinsService,
    private loaderSubjects: LoaderSubjects,
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.MOUNTER_REPORT) : this.pageTitle.setTitle(SIBConstants.MOUNTER_REPORT + ' - ' + appTitle);
    this.mountingColumns = mountingReportColumnConfig.MountingReportColumns;
    this.monitoringColumns = monitoringReportColumnConfig.MonitoringReportColumns;
    this.unmountingColumns = unmountingReportColumnConfig.UnmountingReportColumns;
    this.calendarStartDate.setDate(1);
    this.setGridConfigObject();
  }

  onStartDateChange(event) {
    this.refreshAllGrids();
  }

  onEndDateChange(event) {
    this.minDate = new Date(this.calendarStartDate);
    this.refreshAllGrids();
  }

  setGridConfigObject() {
    this.setMountingGridConfig();
    this.setMonitoringGridConfig();
    this.setUnmountingGridConfig();
  }

  setMountingGridConfig() {
    this.mountingGridConfig.getDefaultModel = () => new MountingMaster();
    this.mountingGridConfig.model = MountingMaster;
    this.mountingGridConfig.selectionMode = SelectionMode.None;
    this.mountingGridConfig.editable = true;
    this.mountingGridConfig.expandableRows = false;
    this.mountingGridConfig.checkboxSelection = false;
    this.mountingGridConfig.resizableColumns = false;
    this.mountingGridConfig.shouldAddRowOnDelete = false;
    this.mountingGridConfig.showColorCode = false;
    this.mountingGridConfig.lazy = true;
    this.mountingGridConfig.enablePagination = true;
    this.mountingGridConfig.scrollable = true;
    this.mountingGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      let paginationData = {
        filters: Object.assign(paginationEvent.filters, { 'mountingItems.date': { value: [this.calendarStartDate, this.calendarEndDate], matchMode: 'dateBetween' } }),
        sortField: 'mountingItems.date',
        sortOrder: -1
      }

      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent = Object.assign(paginationEvent, paginationData)
      }

      return this.campaignItemService.create(paginationEvent, paginationEvent, AppUrls.MOUNTING_REPORT).pipe(
        map((response) => {
          this.mountingSearchEvent = paginationEvent;
          this.totalMountingRecords = response['data'].totalElements;
          return response['data']['content']
        }),
        catchError(error => {
          if (error.error.code === 412) {
            this.notificationService.info(error.error.message ? error.error.message : 'Server Error', 'Please Refresh');
          } else {
            this.notificationService.error(error.error.message ? error.error.message : 'Server Error', 'Please Refresh.');
          }
          return observableEmpty();
        }))
    }
  }

  setMonitoringGridConfig() {
    this.monitoringGridConfig.getDefaultModel = () => new MonitoringMaster();
    this.monitoringGridConfig.model = MonitoringMaster;
    this.monitoringGridConfig.selectionMode = SelectionMode.None;
    this.monitoringGridConfig.editable = true;
    this.monitoringGridConfig.expandableRows = false;
    this.monitoringGridConfig.checkboxSelection = false;
    this.monitoringGridConfig.resizableColumns = false;
    this.monitoringGridConfig.shouldAddRowOnDelete = false;
    this.monitoringGridConfig.showColorCode = false;
    this.monitoringGridConfig.lazy = true;
    this.monitoringGridConfig.enablePagination = true;
    this.monitoringGridConfig.scrollable = true;
    this.monitoringGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      let paginationData = {
        filters: Object.assign(paginationEvent.filters, { 'monitoringItems.monitoringDate': { value: [this.calendarStartDate, this.calendarEndDate], matchMode: 'dateBetween' } }),
        sortField: 'monitoringItems.monitoringDate',
        sortOrder: -1
      }

      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent = Object.assign(paginationEvent, paginationData)
      }

      return this.campaignItemService.create(paginationEvent, paginationEvent, AppUrls.MONITORIING_REPORT).pipe(
        map((response) => {
          this.monitoringSearchEvent = paginationEvent;
          this.totalMonitoringRecords = response['data'].totalElements;
          return response['data']['content']
        }),
        catchError(error => {
          if (error.error.code === 412) {
            this.notificationService.info(error.error.message ? error.error.message : 'Server Error', 'Please Refresh');
          } else {
            this.notificationService.error(error.error.message ? error.error.message : 'Server Error', 'Please Refresh.');
          }
          return observableEmpty();
        }))
    }
  }

  setUnmountingGridConfig() {
    this.unmountingGridConfig.getDefaultModel = () => new CampaignItem();
    this.unmountingGridConfig.model = CampaignItem;
    this.unmountingGridConfig.selectionMode = SelectionMode.None;
    this.unmountingGridConfig.editable = true;
    this.unmountingGridConfig.expandableRows = false;
    this.unmountingGridConfig.checkboxSelection = false;
    this.unmountingGridConfig.resizableColumns = false;
    this.unmountingGridConfig.shouldAddRowOnDelete = false;
    this.unmountingGridConfig.showColorCode = false;
    this.unmountingGridConfig.lazy = true;
    this.unmountingGridConfig.enablePagination = true;
    this.unmountingGridConfig.scrollable = true;
    this.unmountingGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      let paginationData = {
        filters: Object.assign(paginationEvent.filters, { 'unmountingItems.date': { value: [this.calendarStartDate, this.calendarEndDate], matchMode: 'dateBetween' } }),
        sortField: 'unmountingItems.date',
        sortOrder: -1
      }

      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent = Object.assign(paginationEvent, paginationData)
      }

      return this.campaignItemService.create(paginationEvent, paginationEvent, AppUrls.UNMOUNTING_REPORT).pipe(
        map((response) => {
          this.unmountingSearchEvent = paginationEvent;
          this.totalUnmountingRecords = response['data'].totalElements;
          return response['data']['content']
        }),
        catchError(error => {
          if (error.error.code === 412) {
            this.notificationService.info(error.error.message ? error.error.message : 'Server Error', 'Please Refresh');
          } else {
            this.notificationService.error(error.error.message ? error.error.message : 'Server Error', 'Please Refresh.');
          }
          return observableEmpty();
        }))
    }
  }

  onTabChange(event) {
    this.selectedTabIndex = event.index;
  }

  refreshAllGrids() {
    this.mountingReportGrid.refresh();
    this.monitoringReportGrid.refresh();
    this.unmountingReportGrid.refresh();
  }

  createLoaderObject(fileType, object, searchEvent) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = searchEvent;
  }

  exportXls() {
    switch (this.selectedTabIndex) {
      case 0: {
        if (this.totalMountingRecords) {
          this.createLoaderObject("XLS", this.xlsLoader, this.mountingSearchEvent);
          this.loaderSubjects.mountingReportXlsLoader.next(this.xlsLoader);
        } else {
          this.notificationService.info("No Items Present", "Cannot Download XLSX");
        }
        break;
      }
      case 1: {
        if (this.totalMonitoringRecords) {
          this.createLoaderObject("XLS", this.xlsLoader, this.monitoringSearchEvent);
          this.loaderSubjects.monitoringReportXlsLoader.next(this.xlsLoader);
        } else {
          this.notificationService.info("No Items Present", "Cannot Download XLSX");
        }
        break;
      }
      case 2: {
        if (this.totalUnmountingRecords) {
          this.createLoaderObject("XLS", this.xlsLoader, this.unmountingSearchEvent);
          this.loaderSubjects.unmountingReportXlsLoader.next(this.xlsLoader);
        } else {
          this.notificationService.info("No Items Present", "Cannot Download XLSX");
        }
        break;
      }
    }
  }

}
