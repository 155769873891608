import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KiosksComponent } from "./kiosks.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { HttpModule } from "@angular/http";
import {
  ButtonModule,
  DropdownModule,
  SliderModule,
  SharedModule,
  OverlayPanelModule,
  DialogModule,
  TooltipModule,
  InputTextModule,
  MultiSelectModule,
  ProgressSpinnerModule,
} from "primeng/primeng";
import { CalendarModule } from "primeng/components/calendar/calendar";
import { DirectiveModules } from "../../../modules/directive.modules";
import { RouterModule } from "@angular/router";
import { UploadDocDialogModule } from "../../../shared/components/upload-doc-dialog/upload-doc-dialog.module";
import { MapModule } from "../map/map.module";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SibGridModule,
    HttpModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    SliderModule,
    SharedModule,
    DirectiveModules,
    RouterModule,
    UploadDocDialogModule,
    OverlayPanelModule,
    DialogModule,
    TooltipModule,
    InputTextModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    MapModule
  ],
  declarations: [KiosksComponent],
  exports: [KiosksComponent],
})
export class KiosksModule {}
