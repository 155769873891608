<div class="industrial-chart-container">
  <div>
    <div
      [ngClass]="{'chart-container-no-sales-height' : showNoSalesMessage, 'chart-container-sales-height': !showNoSalesMessage}">
      <ul class="cinline-block" [ngClass]="{'ul-width' : showNoSalesMessage}">
        <li class="input-switch" *ngIf="graphCustomization">
          <p-inputSwitch name='industrialChartInputSwitch' [(ngModel)]="industrialChartVisibility"
            (onChange)="onIndustrialChartVisibilityChange($event)">
          </p-inputSwitch>

        </li>
        <li class="margin-top-5">
          <p-calendar [styleClass]="'site-from-button-hm'" #endDateCalender dateFormat="ddMy" appendTo="body"
            [disabled]="!startDate || graphCustomization" [minDate]="startDate" placeholder=" Ex-01Jan18"
            (onInput)="onDateSelection()" (onSelect)="onDateSelection()" [(ngModel)]="endDate" [utc]="true"
            [showIcon]="true"></p-calendar>
        </li>
        <li class="sites-from-text">
          <div>End Date</div>
        </li>
        <li class="margin-top-5">
          <p-calendar [styleClass]="'site-from-button-hm'" #startDateCalender dateFormat="ddMy" appendTo="body"
            placeholder=" Ex-01Jan18" (onInput)="onDateSelection()" (onSelect)="onDateSelection()"
            [(ngModel)]="startDate" [utc]="true" [showIcon]="true" [disabled]="graphCustomization"></p-calendar>
        </li>
        <li class="sites-from-text">
          <div>Start Date</div>
        </li>
        <li style="float:left;">
          <h3 class="margin-6">
            Industrial Sales Chart
          </h3>
        </li>
      </ul>

      <div class="chart-container">
        <div *ngIf="isLoading" class="loader">
          <p-progressSpinner></p-progressSpinner>

        </div>
        <div *ngIf="showNoSalesMessage && !isLoading" class="no-sales-message">
          <h3> No sales on current selected period.</h3>
        </div>
        <sib-chart *ngIf="industrialSalesChartData.length > 0" #industrialChartRef [data]="industrialSalesChartData"
          [stackedBarConfig]="industrialSalesBarChartConfig" [chartType]="'stackedBarChart'"
          [elementId]="chartElementId" (eEmitStackedBarChartLoaded)="isLoading = false">
        </sib-chart>
      </div>

    </div>

  </div>

</div>