import { InventoryType } from "./inventory-type";
import { AbstractInventory } from "./abstract-inventory";
import { SupplierVendor } from "../supplier/supplier-vendor";

export class Inventory extends AbstractInventory {
  tradedAsset: boolean;
  clubbed = false;
  vendorName: string;
  rating = 0;
  quantity: number = 1;
  supplierVendor: SupplierVendor = new SupplierVendor();
  numberOfDuplicateChild: number;
}
