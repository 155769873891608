import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Area } from "../Area/area";
import { AuthUserGetModel } from "./auth-user-get-model";
import { SibUserRoleEnum } from "../../shared/constants/sib-user-role-enum";
import { EmailConfig } from "./emailConfig";
import * as utils from '../../helpers/utils';
import { City } from "../Area/city";
import { District } from "../Area/district";
import { State } from "../Area/state";
import { BloodGroupEnum } from "../../shared/constants/blood-group-enum";
import { GenderEnum } from "../../shared/constants/gender-enum";


export class User extends SibBaseObject {
    emailId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    number: string;
    roles: string[] = [];
    authId: string;
    areas: Area[] = [];
    permissions: string[] = [];
    connection: string;
    organizationEmail: boolean = true;
    emailConfig: EmailConfig = new EmailConfig();
    gridToColumns: Map<string, Array<string>>;

    /* M= Mobile, W= Web, A= All ,N= No permission added */
    appPermission: string = 'N';

    /* Flag to identify whether user is logged-in and filled all details or not. */
    userRegistered: boolean;

    gender: GenderEnum

    dateOfBirth: Date;
    bloodGroup: BloodGroupEnum;
    city: City;
    district: District;
    state: State;
    postalCode: string;
    emergencyContactNumber: string;
    emergencyContactPersonName: string

    //Sanchit Mirg | 02-01-2019 Changes to selected columns while sending xsls.
    gridColumnToXsls: Map<string, Array<string>>;
    authPassword: string;

    toLocal(authUserGetModel: AuthUserGetModel): User {
        this.emailId = authUserGetModel.email;
        this.firstName = authUserGetModel.user_metadata && authUserGetModel.user_metadata.firstname ? authUserGetModel.user_metadata.firstname : null;
        this.lastName = authUserGetModel.user_metadata && authUserGetModel.user_metadata.lastname ? authUserGetModel.user_metadata.lastname : null;
        this.number = authUserGetModel.user_metadata && authUserGetModel.user_metadata.number ? authUserGetModel.user_metadata.number : null;
        this.roles = authUserGetModel.app_metadata && authUserGetModel.app_metadata.authorization && authUserGetModel.app_metadata.authorization.roles ? this.mapUserRoles(authUserGetModel.app_metadata.authorization.roles) : null;
        this.permissions = authUserGetModel.app_metadata && authUserGetModel.app_metadata.authorization && authUserGetModel.app_metadata.authorization.permissions ? authUserGetModel.app_metadata.authorization.permissions : null;
        this.connection = authUserGetModel.identities[0].connection;
        this.authId = authUserGetModel.user_id;
        this.isActive = !authUserGetModel.blocked;
        this.gender = authUserGetModel.user_metadata && authUserGetModel.user_metadata.gender ? authUserGetModel.user_metadata.gender : null;
        this.dateOfBirth = authUserGetModel.user_metadata && authUserGetModel.user_metadata.dateOfBirth ? authUserGetModel.user_metadata.dateOfBirth : null;
        this.bloodGroup = authUserGetModel.user_metadata && authUserGetModel.user_metadata.bloodGroup ? authUserGetModel.user_metadata.bloodGroup : null;
        this.city = authUserGetModel.user_metadata && authUserGetModel.user_metadata.city ? authUserGetModel.user_metadata.city : null;
        this.district = authUserGetModel.user_metadata && authUserGetModel.user_metadata.district ? authUserGetModel.user_metadata.district : null;
        this.state = authUserGetModel.user_metadata && authUserGetModel.user_metadata.state ? authUserGetModel.user_metadata.state : null;
        this.postalCode = authUserGetModel.user_metadata && authUserGetModel.user_metadata.postalCode ? authUserGetModel.user_metadata.postalCode : null;
        this.emergencyContactNumber = authUserGetModel.user_metadata && authUserGetModel.user_metadata.emergencyNumber ? authUserGetModel.user_metadata.emergencyNumber : null;
        this.emergencyContactPersonName = authUserGetModel.user_metadata && authUserGetModel.user_metadata.contactPerson ? authUserGetModel.user_metadata.contactPerson : null;
        return this;
    }

    mapUserRole(authRoles) {

        var userRole = [];

        for (let item in SibUserRoleEnum) {
            if (authRoles.indexOf(SibUserRoleEnum[item]) >= 0) {
                userRole.push(item);
            }
        }
        return userRole;
    }

    mapUserRoles(authRoles) {
        var userRole = [];
        authRoles.forEach((data) => {
            if (utils.getKey(SibUserRoleEnum, data)) {
                userRole.push(utils.getKey(SibUserRoleEnum, data))
            }
        });
        return userRole;
    }
}
