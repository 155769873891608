import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SelectItem } from "primeng/primeng";
import { UserService } from "../../services/shared/user.service";
import { AppUrls } from "../../services/urls";
import { DashboardUiService } from "../../dashboard/service/dashboard-ui.service";
import { HAxis } from "../../modals/charts/hAxis/hAxis";
import { LineChartConfig } from "../../modals/charts/lineChart.config";
import { VAxis } from "../../modals/charts/vAxis/vAxis";
import { SibChartComponent } from "../../sib-chart/sib-chart.component";
import * as utils from "../../helpers/utils";
import { DateUtil } from "../../helpers/date.util";

@Component({
  selector: "sib-plan-campaign-comparison-chart",
  templateUrl: "./plan-campaign-comparison-chart.component.html",
  styleUrls: ["./plan-campaign-comparison-chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlanCampaignComparisonChartComponent implements OnInit {
  @ViewChild(SibChartComponent) sibChartRef: SibChartComponent;
  @Input() graphCustomization: boolean = false;
  @Input() chartVisibility: boolean = false;

  @Output()
  eEmitChartVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  lineChartData: any[] = [];
  lineChartConfig: LineChartConfig;
  lineElementId: string = "";
  hAxis: HAxis = new HAxis();
  vAxis: VAxis = new VAxis();
  width: number;
  height: number;

  startDate: Date = new Date();
  endDate: Date = new Date();
  employees: SelectItem[] = [];
  selectedEmployee = [];

  showNoMessage: boolean = false;
  isLoading: boolean = false;

  constructor(
    private dashboardUiService: DashboardUiService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.startDate = DateUtil.getLastNMonthStartDateEndDate(
      new Date().getMonth() + 1,
      3
    )[0];
    this.isLoading = true;
    this.getAllSalesReps();
    this.getPlanCampaignComparisonData();
  }

  getPlanCampaignComparisonData() {
    this.isLoading = true;
    this.dashboardUiService
      .getPlanCampaignComparisonCount({
        startDate: this.startDate,
        endDate: this.endDate,
        emailId: this.selectedEmployee,
      })
      .subscribe((response) => {
        this.showNoMessage = false;
        this.setLineChart(response["data"]);
      });
  }

  getAllSalesReps() {
    // this.isLoading = true;
    this.employees = [];
    this.selectedEmployee = [];
    this.userService
      .get({ role: "SALES_REPRESENTATIVE" }, AppUrls.SEARCH + AppUrls.BY_ROLE)
      .subscribe(
        (response) => {
          if (response) {
            const resp = response["_embedded"]["users"];
            resp.forEach((res) => {
              this.employees.push({ label: res.fullName, value: res.emailId });
            });
            this.employees.sort(function (a, b) {
              return a.label.localeCompare(b.label);
            });
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  setLineChart(data) {
    if (data && data != null) {
      this.lineElementId = "PlanCampaignLineChart";
      this.lineChartData = [];
      if (data.length === 0) {
        this.showNoMessage = true;
        this.isLoading = false;
        return;
      }
      this.lineChartData.push(["Month", "Total Plans", "Plan", "Campaign"]);
      data.forEach((data) => {
        this.lineChartData.push([
          data.month,
          data.createdCount,
          data.createdCount - data.approvedCount,
          data.approvedCount,
        ]);
      });
      this.setHAxis();
      this.setVAxis();
      let chartArea = {
        height: "70%",
        width: "80%",
        left: "9%",
        top: "15%",
      };
      let series = {
        1: { color: color.ORANGE },
        2: { color: color.GREEN },
      };
      this.lineChartConfig = new LineChartConfig(
        null,
        "Plan vs Campaign",
        this.hAxis,
        this.vAxis,
        innerWidth - 130,
        230,
        true,
        null,
        chartArea,
        "function",
        series
      );
    }
    this.isLoading = false;
  }

  setHAxis() {
    this.hAxis.title = "Month";
  }

  setVAxis() {
    this.vAxis.title = "Count";
  }

  refreshChart(height, width) {
    this.lineChartConfig.height = height;
    this.lineChartConfig.width = width;
    this.sibChartRef.cshowLineChart(this.lineChartConfig);
  }

  onChartVisibilityChange(event) {
    this.eEmitChartVisibility.emit(event.checked);
  }

  onDateSelection(event) {
    this.getPlanCampaignComparisonData();
  }

  onEmployeeChange(event) {
    this.getPlanCampaignComparisonData();
  }
}

export enum color {
  ORANGE = "#e69500",
  GREEN = "green",
}
