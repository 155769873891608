import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import {
  ConfirmDialogModule,
  ConfirmationService,
  ButtonModule,
  SharedModule,
  ProgressSpinnerModule,
  InputTextModule,
  TabViewModule,
  DropdownModule,
} from "primeng/primeng";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { NgModule } from "@angular/core";
import { DirectiveModules } from "../../../modules/directive.modules";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CurrencyMainPipe } from "../../helpers/currency.pipe/currency.main.pipe";
import { StatusQueueDialogModule } from "../status-queue-dialog/status-queue-dialog.module";
import { PendingTasksComponent } from "./pending-tasks.component";
import { KeyValueMainPipe } from "../../helpers/key-value-pipe/key-value.main.pipe";

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    ButtonModule,
    SibGridModule,
    SharedModule,
    InputTextModule,
    ProgressSpinnerModule,
    TabViewModule,
    DirectiveModules,
    ReactiveFormsModule,
    FormsModule,
    CurrencyMainPipe,
    RouterModule,
    DropdownModule,
    StatusQueueDialogModule,
    ConfirmDialogModule,
    KeyValueMainPipe,
  ],

  declarations: [PendingTasksComponent],

  exports: [PendingTasksComponent],

  providers: [ConfirmationService],
})
export class PendingTasksModule {}
