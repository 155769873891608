import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// import * as anime from 'animejs';
import { PlayGroundService } from '../services/shared/playGround.service.';

@Component({
  selector: 'sib-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PlaygroundComponent implements OnInit {
  options: any;
  cars: any[] = [
    { "brand": "VW", "year": 2012, "color": "Orange", "vin": "dsad231ff" },
    { "brand": "Audi", "year": 2011, "color": "Black", "vin": "gwregre345" },
    { "brand": "Renault", "year": 2005, "color": "Gray", "vin": "h354htr" },
    { "brand": "BMW", "year": 2003, "color": "Blue", "vin": "j6w54qgh" },
    { "brand": "Mercedes", "year": 1995, "color": "Orange", "vin": "hrtwy34" },
    { "brand": "Volvo", "year": 2005, "color": "Black", "vin": "jejtyj" },
    { "brand": "Honda", "year": 2012, "color": "Yellow", "vin": "g43gr" },
    { "brand": "Jaguar", "year": 2013, "color": "Orange", "vin": "greg34" },
    { "brand": "Ford", "year": 2000, "color": "Black", "vin": "h54hw5" },
    { "brand": "Fiat", "year": 2013, "color": "Red", "vin": "245t2s" },
    { "brand": "VW", "year": 2012, "color": "Orange", "vin": "dsad231ff" },
    { "brand": "Audi", "year": 2011, "color": "Black", "vin": "gwregre345" },
    { "brand": "Renault", "year": 2005, "color": "Gray", "vin": "h354htr" },
    { "brand": "BMW", "year": 2003, "color": "Blue", "vin": "j6w54qgh" },
    { "brand": "Mercedes", "year": 1995, "color": "Orange", "vin": "hrtwy34" },
    { "brand": "Volvo", "year": 2005, "color": "Black", "vin": "jejtyj" },
    { "brand": "Honda", "year": 2012, "color": "Yellow", "vin": "g43gr" },
    { "brand": "Jaguar", "year": 2013, "color": "Orange", "vin": "greg34" },
    { "brand": "Ford", "year": 2000, "color": "Black", "vin": "h54hw5" },
    { "brand": "Fiat", "year": 2013, "color": "Red", "vin": "245t2s" }
  ]

  scrollHeight: any;
  card: any;
  playing: boolean = false;
  playmap = new Map();
  constructor(
    private cd: ChangeDetectorRef,
    private playGorundService: PlayGroundService,
  ) { }

  ngOnInit() {
    // this.scrollHeight = (window.screen.height - 200) + 'px';
    // this.cd.detectChanges();

    // this.card = document.querySelector(".card");
    // this.playing = false;


    this.playGorundService.test1().subscribe((response: any) => {
      // console.log(response);
      this.playmap = this.buildMap(response);
      // console.log(response);
    });

    this.options = {
      center: { lat: 23.0225, lng: 72.577417 },
      zoom: 10,
      mapTypeId: 'roadmap'
    };

  }
  buildMap(obj) {
    let map = new Map();
    Object.keys(obj).forEach(key => {
      map.set(key, obj[key]);
    });
    return map;
  }


  loadData(event) {
    // console.log("inside load data")
    //event.first = First row offset
    //event.rows = Number of rows per page
    //add more records to the cars array
  }

  cardClick(i) {

    this.card = document.querySelector(".cards");
    this.playing = false;

    if (this.playing)
      return;

    this.playing = true;
    /* anime({
      targets: this.card,
      scale: [{ value: 1 }, { value: 1 }, { value: 1, delay: 250 }],
      rotateY: { value: '+=180', delay: 200 },
      easing: 'easeInOutSine',
      duration: 400,
      
    }); */

    this.playing = false
  }



}
