<div class="pendingtasks">
  <div>
    <form [formGroup]="actionTaskGroup">
      <sib-grid
        name="taskGrid"
        #sibGrid
        [buttons]="buttonConfig"
        [showFilter]="true"
        [showColorLegends]="false"
        [totalRecords]="totalRecords"
        (eEmitColumnSelection)="updatingUserGridConfig($event)"
        [columns]="taskColumns"
        [config]="gridConfig"
        formControlName="taskGridControl"
        (eRowExpand)="onRowExpand($event)"
        ngDefaultControl
      >
        <sib-grid-column field="srNo">
          <ng-template
            #bodyTemplate
            let-rowData="rowData"
            let-rowIndex="rowIndex"
            let-col="col"
            let-column="column"
          >
            <span>
              {{ rowIndex + 1 }}
            </span>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="moduleData.displayKey">
          <ng-template
            #bodyTemplate
            let-rowData="rowData"
            let-rowIndex="rowIndex"
            let-col="col"
            let-column="column"
          >
            <a
              class="link-1 after"
              target="_blank"
              [routerLink]="['/campaigns/view/', rowData.moduleData.primaryKey]"
              >{{ rowData.moduleData.displayKey }}</a
            >
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="data.campaignDisplayName">
          <ng-template
            #bodyTemplate
            let-rowData="rowData"
            let-rowIndex="rowIndex"
            let-col="col"
            let-column="column"
          >
            <a
              class="link-1 after"
              target="_blank"
              *ngIf="rowData.data.campaignDisplayName; else defaultTemplate"
              [routerLink]="['/campaigns/view/', rowData.data.itemCampaignId]"
              >{{ rowData.data.campaignDisplayName }}</a
            >
            <ng-template #defaultTemplate> - </ng-template>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="data.statusQueues">
          <ng-template
            #bodyTemplate
            let-rowData="rowData"
            let-rowIndex="rowIndex"
            let-col="col"
            let-column="column"
          >
            <div
              *ngIf="
                rowData?.data?.statusQueues?.length > 1;
                else falsyTemplate
              "
            >
              <a class="link-1 after" (click)="viewDetail(rowData)"
                >View Detail</a
              >
            </div>
            <ng-template #falsyTemplate>
              <div
                *ngIf="
                  rowData?.data?.statusQueues?.length === 1;
                  else defaultTemplate
                "
              >
                {{ rowData.data.statusQueues[0].status }}
              </div>
              <ng-template #defaultTemplate>
                {{ rowData.status | keyToValue: "CampaignStatusEnum" }}
              </ng-template>
            </ng-template>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="approveReject">
          <ng-template
            #bodyTemplate
            let-rowData="rowData"
            let-rowIndex="rowIndex"
            let-col="col"
            let-column="column"
          >
            <div>
              <span class="padding-right-5">
                <!-- <i class="fa fa-circle custom-icon true-color cursor-pointer " title="Adjust"
                  (click)="adjustCampaignItem(rowData)"><span class="adjust-remove-icon right-14">A</span></i> -->
                <button
                  type="submit"
                  pButton
                  class="mtab-save"
                  label="Adjust Dates"
                  (click)="adjustCampaignItem(rowData)"
                  style="padding: 3px 8px"
                ></button>
              </span>
              <span>
                <!-- <i class="fa fa-circle custom-icon false-color cursor-pointer" title="Remove"
                  (click)="removeCampaignItem(rowData)"><span class="adjust-remove-icon  right-13">R</span></i> -->
                <button
                  type="button"
                  pButton
                  class="mtab-danger"
                  label="Drop Site"
                  (click)="removeCampaignItem(rowData)"
                ></button>
              </span>
            </div>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="data.lightType">
          <ng-template
            #filterTemplate
            pTemplate="filter"
            let-col="col"
            let-column="column"
          >
            <p-dropdown
              #lighttypedd
              [options]="lightTypeDropdown"
              [style]="{ width: '100%', 'margin-top': '8px' }"
              appendTo="body"
              placeholder="Light"
              (onChange)="
                sibGrid.filter($event.value, col.field, col.filterMatchMode)
              "
            >
            </p-dropdown>
          </ng-template>
        </sib-grid-column>
        <ng-template
          let-rowData="rowData"
          let-rowIndex="rowIndex"
          let-col="col"
          let-column="column"
          mtabGridTemplate="rowExpansion"
        >
          <div class="padding-0" style="background-color: whitesmoke">
            <table>
              <ng-template
                let-inventory
                let-last="last"
                ngFor
                [ngForOf]="units"
              >
                <tr>
                  <td style="width: 3vw"></td>
                  <td
                    style="
                      width: 5vw;
                      word-break: break-all;
                      word-wrap: break-word;
                    "
                  >
                    <div *ngIf="inventory?.customId">
                      {{ inventory.customId }}
                    </div>
                    <div *ngIf="!inventory?.customId">-</div>
                  </td>
                  <td style="width: 7vw" *ngIf="isColumnPresent('District')">
                    {{ inventory.area.city.district.name }}
                  </td>
                  <td style="width: 7vw" *ngIf="isColumnPresent('City')">
                    {{ inventory.area.city.name }}
                  </td>
                  <td style="width: 7vw" *ngIf="isColumnPresent('Area')">
                    {{ inventory.area.name }}
                  </td>
                  <td style="width: 12vw">{{ inventory.location }}</td>
                  <td style="width: 4vw">{{ inventory.size }}</td>
                  <td style="width: 4vw" *ngIf="isColumnPresent('Light')">
                    {{ getLight(inventory.lightType) }}
                  </td>

                  <td style="width: 7vw">
                    <a
                      class="link-1 after"
                      target="_blank"
                      [routerLink]="['/campaigns/view/', currentCampaignId]"
                    >
                      {{ currentCampaign }}</a
                    >
                  </td>
                  <td
                    style="width: 7vw"
                    *ngIf="isColumnPresent('Current Campaign Employee')"
                  >
                    {{ currentCampaignEmployee }}
                  </td>
                  <td style="width: 7vw">
                    <a
                      class="link-1 after"
                      target="_blank"
                      [routerLink]="['/campaigns/view/', conflictedCampaignId]"
                      >{{ conflictedCampaign }}</a
                    >
                  </td>
                  <td
                    style="width: 7vw"
                    *ngIf="isColumnPresent('Conflict Campaign Employee')"
                  >
                    {{ conflictedCampaignEmployee }}
                  </td>

                  <td style="width: 5vw">
                    <div *ngIf="inventory?.customId; else noStatusTemplate">
                      <div
                        *ngIf="
                          inventory?.statusQueues?.length > 1;
                          else falsyTemplate
                        "
                      >
                        <div
                          *ngIf="
                            inventory?.status === 'INACTIVE' ||
                              inventory?.status === 'MIGRATED' ||
                              inventory?.status === 'EXPIRED';
                            else detailTemplate
                          "
                        >
                          {{ getValue(inventory.status) }}
                        </div>
                      </div>
                      <ng-template #detailTemplate>
                        <a class="link-1 after" (click)="viewDetail(inventory)"
                          >View Detail</a
                        >
                      </ng-template>
                      <ng-template #falsyTemplate>
                        <div
                          *ngIf="
                            inventory?.statusQueues?.length === 1;
                            else defaultTemplate
                          "
                        >
                          <div
                            *ngIf="
                              inventory.statusQueues[0].status &&
                                inventory.statusQueues[0].status !== 'BLOCKED';
                              else singleTemplate
                            "
                          >
                            {{ inventory?.statusQueues[0]?.status }}
                          </div>
                        </div>
                        <ng-template #singleTemplate>
                          {{ inventory.statusQueues[0].status }}
                        </ng-template>
                        <ng-template #defaultTemplate>
                          <div *ngIf="inventory?.status">
                            {{ getValue(inventory.status) }}
                          </div>
                          <div *ngIf="!inventory?.status">-</div>
                        </ng-template>
                      </ng-template>
                    </div>
                    <ng-template #noStatusTemplate> - </ng-template>
                  </td>
                  <td style="width: 5vw">-</td>
                  <td style="width: 17vw"></td>
                </tr>
              </ng-template>
            </table>
          </div>
        </ng-template>
      </sib-grid>
    </form>
  </div>
  <sib-status-queue-dialog
    *ngIf="displayStatusQueueDialog"
    [displayStatusQueueDialog]="displayStatusQueueDialog"
    [rowData]="rowData"
    [values]="values"
    (eEmitChangeStatusQueueDialogDisplay)="hideStatusQueueDialog()"
  >
  </sib-status-queue-dialog>

  <p-confirmDialog
    header="Confirmation"
     
    width="425"
    #cd
    key="dropConflictItem"
  >
    <p-footer>
      <button
        type="button"
        pButton
        class="mtab-save margin-hori-5"
        label="YES"
        (click)="cd.accept()"
      ></button>
      <button
        type="button"
        pButton
        class="mtab-secondary"
        label="CANCEL"
        (click)="cd.reject()"
      ></button>
    </p-footer>
  </p-confirmDialog>
</div>
