import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { TableChartConfig } from '../../modals/charts/tableChart.config';
import { AppUrls } from '../../services/urls';
import { SIBConstants } from '../../shared/constants/SIBConstant';

@Component({
  selector: 'sib-employee-table-chart',
  templateUrl: './employee-table-chart.component.html',
  styleUrls: ['./employee-table-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeTableChartComponent implements OnInit {

  userProfile: any;
  emailId: any;
  endDate: Date;
  startDate: Date;
  employeesData = [];
  chartElementId: string;
  employeesChartData: any;
  employeesChartConfig: TableChartConfig = new TableChartConfig();
  isLoading = false;

  @Input() graphCustomization = false;
  @Input() employeeChartVisibility = false;

  @Output() eEmitChartVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.endDate = new Date();
    this.startDate = this.setInitialStartDate();
    this.getEmployeesSalesData();
  }


  /**
   * @description sets initial start date
   * @author Raveena Nathani
   * @date 2020-05-25
   * @returns
   * @memberof EmployeeTableChartComponent
   */
  setInitialStartDate() {
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    startDate.setDate(startDate.getDate() + 1);
    return startDate;

  }


  /**
   * @description gets employees data
   * @author Raveena Nathani
   * @date 2020-05-25
   * @memberof EmployeeTableChartComponent
   */
  getEmployeesSalesData() {
    this.isLoading = true;
    const requestData = {
      "itemStartDate": this.startDate,
      "itemEndDate": this.endDate
    };
    this.dashboardService.create(requestData, null, AppUrls.EMPLOYEES_SALES_DATA).subscribe((response) => {
      if (response['data']) {
        this.employeesData = response['data'];
        !this.employeesData.length ? this.isLoading = false : this.isLoading = true;
        this.setEmployeesChart();
      }
    });
  }


  /**
   * @description sets employees chart
   * @author Raveena Nathani
   * @date 2020-05-25
   * @memberof EmployeeTableChartComponent
   */
  setEmployeesChart() {
    this.chartElementId = SIBConstants.EMPLOYEES_SALES_CHART_ID;
    this.setEmployeesChartData();
  }


  /**
   * @description sets employees chart data
   * @author Raveena Nathani
   * @date 2020-05-25
   * @memberof EmployeeTableChartComponent
   */
  setEmployeesChartData() {

    const columns = ['Employee', 'Estimate', 'Sales(Billed)', 'Unbilled', 'Credit Notes', 'Effective Sales'];
    const rows = [];
    this.employeesData.forEach((employee) => {
      rows.push([employee.fullName, this.getDecimalValue(employee.campaignAmount), this.getDecimalValue(employee.billedAmount), this.getDecimalValue(employee.unbilledAmount), this.getDecimalValue(employee.cnAmount), this.getDecimalValue(employee.effectiveSales)]);
    });
    this.employeesChartData = {
      columns: columns,
      rows: rows
    };
    this.setEmployeesChartConfig();

  }


  /**
   * @description sets employees table chart config
   * @author Raveena Nathani
   * @date 2020-05-25
   * @memberof EmployeeTableChartComponent
   */
  setEmployeesChartConfig() {
    this.employeesChartConfig.alternatingRowStyle = false;
    this.employeesChartConfig.cssClassNames = { headerRow: 'header-alignment' };
    this.employeesChartConfig.height = '100%';
    this.employeesChartConfig.showRowNumber = true;
    this.employeesChartConfig.width = "100%";
  }


  /**
   * @description get 2 places decimal value
   * @author Raveena Nathani
   * @date 2020-05-25
   * @param {*} value
   * @returns
   * @memberof EmployeeTableChartComponent
   */
  getDecimalValue(value) {
    return Number(Math.fround(value).toFixed(2));
  }



  /**
   * @description on start/end date change
   * @author Raveena Nathani
   * @date 2020-05-25
   * @memberof EmployeeTableChartComponent
   */
  onDateSelection() {
    this.getEmployeesSalesData();
  }

  onEmployeeChartVisibilityChange(event) {
    this.eEmitChartVisibility.emit(event.checked);
  }

}
