/**
 * @file  PrintingPrice
 * @author ParthL@meditab.com
 * @copyright Meditab Software 2017
 */
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { PlanHoarding } from "./planHoarding";
import { Price } from "./price";

export class PrintingPrice {
  flNlPrice: number = 0; //if light type is flnl and calculation is perSqft
  blPrice: number = 0; //if light type is bl and calculation is perSqft
  pricePerUnitFl: number = 0; //if light type is flnl and calculation is perUnit
  pricePerUnitBl: number = 0; //if light type is Bl and calculation is perUnit
  variousPrice: number = 0; //if light type is various
  perUnit: boolean = false; //set true if perUnit calculation is selected
}
