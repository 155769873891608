import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { SIBConstants } from '../../constants/SIBConstant';
import { MonthEnumEnum } from '../../constants/month-enum';
import { Title } from '@angular/platform-browser';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { SelectItem } from 'primeng/primeng';
import { CalculationService } from '../../../services/shared/calculationService';
import { UserBO } from '../../../modals/user/user-bo';
import { TransactionSearch } from '../../../modals/passbook/transaction-search';
import { PaginationData } from '../../../modals/paginationdata';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { Transaction } from '../../../modals/passbook/transaction';
import { TransactionEventEnum } from '../../constants/transaction-event-enum';
import { TransactionTypeEnum } from '../../constants/transaction-type-enum';
import * as utils from '../../../helpers/utils';
import { Alignment, GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { Passbook } from '../../../modals/passbook/passbook';
import { PassbookService } from './services/passbook.service';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { CustomerUiService } from '../../../customers/services/customer-ui.service';
import { SibModuleEnum } from '../../constants/sib-module-enum';
import * as _ from 'lodash';
import { DateUtil } from '../../../helpers/date.util';
import { MonthEnum } from '../../constants/month.enum';
import { map, catchError } from 'rxjs/operators';
import { AppUrls } from '../../../services/urls';
import { empty as observableEmpty } from 'rxjs';

export interface SearchFilter {
    filters?: any;
}

@Component({
    selector: 'sib-passbook',
    templateUrl: './passbook.component.html',
    styleUrls: ['./passbook.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class PassbookComponent implements OnInit, OnDestroy {

    @ViewChild('passbookForm') passbookForm;
    @ViewChild('userDropdown') userDropdown;
    @ViewChild('passbookGrid') passbookGridViewChildRef: GridComponent<Passbook>;
    @ViewChild('transactionGrid') transactionGridViewChildRef: GridComponent<Transaction>;

    isLoading = false;
    title: string;
    startDate = new Date();
    endDate = new Date();
    currentDate = new Date();

    selectedUser: any = null;

    /**
     * stores all transactions
     *
     * @type {Transaction[]}
     * @memberof PassbookComponent
     */
    transactions: Transaction[] = [];

    /**
     * stores all passbook 
     *
     * @type {Passbook[]}
     * @memberof PassbookComponent
     */
    passbook: Passbook[] = [];

    /**
     * transaction Search Object
     *
     * @type {TransactionSearch}
     * @memberof PassbookComponent
     */
    transactionSearch: TransactionSearch = new TransactionSearch();

    availableBalance: number;

    /**
     * total passbook records
     *
     * @type {number}
     * @memberof PassbookComponent
     */
    totalRecords: number = 0;

    /**
     * total transaction records
     *
     * @type {number}
     * @memberof PassbookComponent
     */
    totalTransactionRecords: number = 0;

    /**
     * passbook Grid Object
     *
     * @type {GridConfig<Passbook>}
     * @memberof PassbookComponent
     */
    gridConfig: GridConfig<Passbook> = new GridConfig<Passbook>();

    /**
     * Transaction Grid Object
     *
     * @type {GridConfig<Transaction>}
     * @memberof PassbookComponent
     */
    transactionGridConfig: GridConfig<Transaction> = new GridConfig<Transaction>();

    /**
     * variable for transaction dialog
     *
     * @type {boolean}
     * @memberof PassbookComponent
     */
    displayTransactionDialog: boolean = false;

    /**
     * stores user email
     *
     * @type {string}
     * @memberof PassbookComponent
     */
    userEmailId: string;

    /**
     * column filter dropdown for transaction module
     *
     * @type {SelectItem[]}
     * @memberof PassbookComponent
     */
    moduleDropdown: SelectItem[] = [];

    /**
     * column  filter dropdown for transaction type
     *
     * @type {SelectItem[]}
     * @memberof PassbookComponent
     */
    transactionTypeDropdown: SelectItem[] = [];

    /**
     * month Dropdown
     *
     * @type {SelectItem[]}
     * @memberof PassbookComponent
     */
    monthDropdown: SelectItem[] = [];

    /**
     * stores selected month
     *
     * @type {string}
     * @memberof PassbookComponent
     */
    selectedMonth: string;

    /**
     * store month start and end date
     *
     * @type {any[]}
     * @memberof PassbookComponent
     */
    dates: any[] = [];

    /**
     * set passbook grid columns
     *
     * @type {Array<GridColumn>}
     * @memberof PassbookComponent
     */
    columns: Array<GridColumn> = [
        {
            field: 'user.fullName',
            name: 'user.fullName',
            header: 'Employee',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search'
            },
        },
        {
            field: 'availableBalance',
            name: 'availableBalance',
            header: 'Available Credit (₹)',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data && data.availableBalance && data.availableBalance !== 'NaN') {
                    return this.currencyPipe.transform(utils.getDecimalNum(data.availableBalance));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'totalAmount',
            name: 'totalAmount',
            header: 'Total Credit (₹)',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data && data.totalAmount && data.totalAmount !== 'NaN') {
                    return this.currencyPipe.transform(utils.getDecimalNum(data.totalAmount));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'percentage',
            name: 'percentage',
            header: 'Used (%)',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '3vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.CUSTOM,
        },
    ];

    /**
     * set transaction grid columns
     *
     * @type {Array<GridColumn>}
     * @memberof PassbookComponent
     */
    transactionColumns: Array<GridColumn> = [
        {
            field: 'icon',
            name: '',
            header: '',
            required: true,
            editable: false,
            sortable: false,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '2vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.CUSTOM,
        },
        {
            field: 'transactions.transactionType',
            name: 'transactions.transactionType',
            header: 'Type',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '5.5vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                if (data && data.transactions) {
                    return this.getTransactionType(data.transactions.transactionType);
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'transactions.module',
            name: 'transactions.module',
            header: 'Module',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '5.5vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                if (data && data.transactions) {
                    return this.getTransactionModule(data.transactions.module);
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'transactions.description',
            name: 'transactions.description',
            header: 'Description',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '5.5vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search'
            },
            displayFn: (data) => {
                if (data && data.transactions) {
                    return data.transactions.description;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'transactions.amount',
            name: 'transactions.amount',
            header: 'Amount (₹)',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '5.5vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search'
            },
            displayFn: (data) => {
                if (data && data.transactions) {
                    return this.currencyPipe.transform(utils.getDecimalNum(data.transactions.amount));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'transactions.availableBalance',
            name: 'transactions.availableBalance',
            header: 'Available Balance (₹)',
            required: true,
            editable: false,
            sortable: true,
            styleClass: 'word-break-wrap',
            align: Alignment.LEFT,
            width: '5.5vw',
            permanent: true,
            default: true,
            hidden: false,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search'
            },
            displayFn: (data) => {
                if (data && data.transactions) {
                    return this.currencyPipe.transform(utils.getDecimalNum(data.transactions.availableBalance));
                } else {
                    return '-';
                }
            }
        }

    ];

    constructor(
        private pageTitle: Title,
        private notificationServcie: NotificatoinsService,
        private passbookService: PassbookService,
        private calculationService: CalculationService,
        private currencyPipe: CurrencyHelperPipe,
        private customerUiService: CustomerUiService,
    ) { }

    ngOnInit() {
        this.setPageTitle();
        this.setFilterDropdown();
        this.userEmailId = this.customerUiService.getUserEmail(); // get the user email

        this.setMonthFilter();

        this.setCurrentMonth();
        this.setPassbookGridConfigObject();
        this.setTransactionGridConfigObject();
        if (this.checkPermission('read:passbook') && this.checkPermission('readAll')) {
            this.selectedUser = this.userEmailId;
        } else if (this.checkPermission('read:passbook') && !this.checkPermission('readAll')) {
            this.selectedUser = this.userEmailId;
        }
    }

    /**
     * set the title
     *
     * @memberof PassbookComponent
     */
    setPageTitle() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.PASSBOOK) : this.pageTitle.setTitle(SIBConstants.PASSBOOK + ' - ' + appTitle);
        this.title = SIBConstants.PASSBOOK;
    }

    /**
     * set the month dropdown
     *
     * @memberof PassbookComponent
     */
    setMonthFilter() {
        Object.keys(MonthEnumEnum).forEach((month) => {
            this.monthDropdown.push({ label: MonthEnumEnum[month], value: month })
        });
    }

    /**
     * set Filter Dropdown for grid
     *
     * @memberof PassbookComponent
     */
    setFilterDropdown() {
        // set Transaction Type dropdown
        this.transactionTypeDropdown.push({ label: "All", value: "ALL" });
        Object.keys(TransactionTypeEnum).forEach((type) => {
            this.transactionTypeDropdown.push({ label: TransactionTypeEnum[type], value: type });
        });

        //set module dropdown
        this.moduleDropdown.push({ label: "All", value: "ALL" },
            { label: "Plan", value: "PLAN" },
            { label: "Campaign", value: "CAMPAIGN" },
            { label: "Others", value: "OTHERS" },
        );
    }

    /**
     * set the current month in dropdown
     *
     * @memberof PassbookComponent
     */
    setCurrentMonth() {
        let currentMonth: number;
        currentMonth = this.currentDate.getMonth();
        this.selectedMonth = DateUtil.setMonthName(currentMonth);
        this.setSelectedMonth();
    }

    /**
     * set selected month's start date and end date
     *
     * @memberof PassbookComponent
     */
    setSelectedMonth() {
        this.dates = DateUtil.getMonthStartAndEndDate(Number(MonthEnum[this.selectedMonth]), null, true);
        this.startDate = new Date(this.dates[0]);
        this.endDate = new Date(this.dates[1]);
    }

    /**
     * action to be performed when month is selected from dropdown
     *
     * @memberof PassbookComponent
     */
    onMonthChange() {
        this.setSelectedMonth();
        // this.validateDates();
        if (this.checkPermission('read:passbook') && this.checkPermission('readAll')) { // for Admin
            this.passbookGridViewChildRef.refresh();
        } else if (this.checkPermission('read:passbook') && !this.checkPermission('readAll')) { // for Sales Rep
            this.transactionGridViewChildRef.refresh();
        }
    }

    validateDates() {
        if (this.startDate && this.endDate && new Date(this.calculationService.setToBeginning(this.startDate)).valueOf() > new Date(this.calculationService.setToBeginning(this.endDate)).valueOf()) {
            this.endDate = new Date(this.startDate);
        }
        if (!this.startDate) {
            this.startDate = this.endDate = null;
        }
        if (this.startDate && !this.endDate) {
            this.endDate = null;
        }
    }

    /**
     * set passbook grid config
     *
     * @memberof PassbookComponent
     */
    setPassbookGridConfig() {
        this.gridConfig.getDefaultModel = () => new Passbook();
        this.gridConfig.model = Passbook;
        this.gridConfig.selectionMode = SelectionMode.Multi;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = true;
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.scrollable = true;
        this.gridConfig.showColorCode = false;
    }

    /**
     * set transaction grid config
     *
     * @memberof PassbookComponent
     */
    setTransactionGridConfig() {
        this.transactionGridConfig.getDefaultModel = () => new Transaction();
        this.transactionGridConfig.model = Transaction;
        this.transactionGridConfig.selectionMode = SelectionMode.Multi;
        this.transactionGridConfig.editable = true;
        this.transactionGridConfig.expandableRows = false;
        this.transactionGridConfig.checkboxSelection = false;
        this.transactionGridConfig.resizableColumns = false;
        this.transactionGridConfig.shouldAddRowOnDelete = false;
        this.transactionGridConfig.showColorCode = false;
        this.transactionGridConfig.lazy = true;
        this.transactionGridConfig.enablePagination = true;
        this.transactionGridConfig.scrollable = true;
    }

    /**
     * set Passbook grid config object
     *
     * @memberof PassbookComponent
     */
    setPassbookGridConfigObject() {
        this.setPassbookGridConfig();

        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            this.isLoading = true;
            this.passbook = [];
            this.transactionSearch = {
                startDate: this.startDate ? new Date(this.startDate) : null,
                endDate: this.endDate ? new Date(this.endDate) : null
            }
            return this.passbookService.create({ transactionSearch: this.transactionSearch, searchEvent: paginationEvent }, paginationEvent, AppUrls.GET_ALL).pipe(map(
                (response) => {
                    if (response && response['data']['content'].length > 0) { // action on response
                        this.passbook = response['data']['content'];
                        this.totalRecords = response['data'].totalElements; // total passbook records
                        this.setPaginationEvent(paginationEvent);
                        this.isLoading = false;
                        return response['data']['content'];
                    } else { // Otherwise
                        this.isLoading = false;
                    }
                }), catchError(error => { // Error
                    if (error.error.code === 422) {
                        this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
                        this.passbookGridViewChildRef.resetAndReloadTable();
                    } else {
                        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                        return observableEmpty();
                    }
                }));
        }
    }

    /**
     * set Transaction grid config object
     *
     * @memberof PassbookComponent
     */
    setTransactionGridConfigObject() {
        this.setTransactionGridConfig();

        this.transactionGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen

            this.isLoading = true;
            this.transactions = [];

            this.setTransactionPaginationEvent(paginationEvent);
            return this.passbookService.create(paginationEvent, paginationEvent, AppUrls.TRANSACTIONS).pipe(map(
                (response) => {
                    if (response && response['data']['content'].length > 0) { // action on response
                        this.availableBalance = response['data']['content'][0].availableBalance; // available Balance 
                        this.filterTransactionsOnAmount(response['data']); // filter Transactions on amount
                        this.setPaginationEvent(paginationEvent);
                        this.isLoading = false;
                        return this.transactions;
                    } else { // Otherwise
                        this.isLoading = false;
                    }

                }), catchError(error => { // Error
                    if (error.error.code === 422) {
                        this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
                        this.transactionGridViewChildRef.resetAndReloadTable();
                    } else {
                        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
                        return observableEmpty();
                    }
                }));
        }
    }

    /**
     * filter transactions on basis of amount
     *
     * @param {PaginationData} paginationEvent
     * @memberof PassbookComponent
     */
    filterTransactionsOnAmount(transactions) {
        let transactionRecordsNotToBeIncluded: number = 0;
        transactions['content'].forEach((transaction) => {
            if (this.getIntegerRoundedOffValue(transaction['transactions'].amount) !== 0) { // transactions having zero amount shouldn't be visible
                this.transactions.push(transaction);
            } else {
                // Do Nothing
                transactionRecordsNotToBeIncluded += 1;
            }
        })
        this.totalTransactionRecords = transactions.totalElements - transactionRecordsNotToBeIncluded; // total transaction records
        // console.log(this.totalTransactionRecords)
    }
    /**
     * set the transaction pagination event
     *
     * @param {PaginationData} paginationEvent
     * @memberof PassbookComponent
     */
    setTransactionPaginationEvent(paginationEvent: PaginationData) {
        Object.assign(paginationEvent.filters, {
            'user.emailId': { value: this.selectedUser, matchMode: 'is' },
            'transactions.date': { value: [this.startDate, this.endDate], matchMode: 'dateBetween' },
        })
    }

    /**
     * get Transaction Event value from TransactionEventEnum
     *
     * @param {*} value
     * @returns
     * @memberof PassbookComponent
     */
    getTransactionEvent(value) {
        return TransactionEventEnum[value];
    }

    /**
     * get Transaction Type value from TransactionTypeEnum
     *
     * @param {*} value
     * @returns
     * @memberof PassbookComponent
     */
    getTransactionType(value) {
        return TransactionTypeEnum[value];
    }

    /**
     * get transaction module value from sibModuleEnum
     *
     * @param {*} value
     * @returns
     * @memberof PassbookComponent
     */
    getTransactionModule(value) {
        return SibModuleEnum[value];
    }

    /**
     * get value parsed to 2 decimal places
     *
     * @param {*} value
     * @returns
     * @memberof PassbookComponent
     */
    getParsedValueToDecimalTwoPlaces(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    /**
     * checks permission
     *
     * @param {*} permission
     * @returns
     * @memberof PassbookComponent
     */
    checkPermission(permission) {
        return utils.checkPermission(permission);
    }

    /**
     * get rounded off value
     *
     * @param {*} value
     * @returns
     * @memberof PassbookComponent
     */
    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    /**
     * calculate used percentage of SR
     *
     * @param {*} rowData
     * @returns
     * @memberof PassbookComponent
     */
    setUsedPercentageValue(rowData) {
        let usedPercentage: Number;
        if (rowData && rowData.percentage && rowData.percentage !== 'NaN') {
            usedPercentage = Number(utils.getDecimalNumFixedToTwoPlaces(rowData.percentage));
        } else {
            usedPercentage = 0;
        }
        return (usedPercentage < 0) ? 0 : (usedPercentage > 100) ? 100 : usedPercentage;
    }

    /**
     * view SR's transaction 
     *
     * @param {*} rowData
     * @memberof PassbookComponent
     */
    showTransactionDialog(rowData) {
        if (rowData !== undefined) { // set selected User and opens the transaction dialog
            this.selectedUser = rowData.user.emailId;
            this.displayTransactionDialog = true;
        } else {
            //Do Nothing
        }
    }

    /**
     * set pagination Event
     * 
     * @param {*} paginationEvent
     * @memberof PassbookComponent
     */
    setPaginationEvent(paginationEvent) {
        paginationEvent.sortField = 'updatedDate';
        Object.assign(paginationEvent, paginationEvent);
    }

    ngOnDestroy() { }

}
