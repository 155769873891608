export enum ImageCategoryEnum {
  HOARDING = "hoardings",
  // HOARDING_GROUP = 'hoarding-groups',
  KIOSK = "kiosks",
  PILLAR = "pillars",
  GANTRY = "gantry",
  WALL_WRAP = "wall-wrap",
  BQS = "bqs",
  BUS = "bus",
  SIGN_BOARD = "sign-board",
  "PICKUP STAND" = "pickup-stand",
  UNIPOLE = "unipole",
  SIGNBOARD = "signboard",
  UTILITY = "utility",
  LED = "LED",
}
