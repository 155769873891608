import { takeUntil, catchError } from "rxjs/operators";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CampaignService } from "../../services/shared/campaign.service";
import { Campaign } from "../../modals/campaigns/campaign";
import { CalculationService } from "../../services/shared/calculationService";
import { SuperbillHoardingsComponent } from "../superbill-hoardings/superbill-hoardings.component";
import { BillingGridService } from "../services/billing-grid.service";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Invoice } from "../../modals/billings/invoice";
import { BillingGrid } from "../../modals/billings/billing-grid";
import { RentItem } from "../../modals/billings/rent-item";
import { AdditionalItem } from "../../modals/billings/additional-item";
import { FirebaseService } from "../../services/shared/firebase.service";
import { Subscription, Subject } from "rxjs";
import * as utils from "../../helpers/utils";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { PieChartConfig } from "../../modals/charts/pieChart.config";
import { ChartArea } from "../../modals/charts/chart-area/chart-area";
import { ChartLegend } from "../../modals/charts/chart-legend/chart-legend";
import { StackedBarChartConfig } from "../../modals/charts/stackedBarChart.config";
import { BillingConfigurationService } from "../../services/shared/billing-configuration.service";
import { TaxConfigurationService } from "../../services/shared/tax-configuration.service";
import { CampaignStatusEnum } from "../../shared/constants/campaign-status-enum";
import { HAxis } from "../../modals/charts/hAxis/hAxis";
import { ConfirmationService } from "primeng/primeng";
import { SuperBillsService } from "../services/superbill.service";
import { AppUrls } from "../../services/urls";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { SuperBillStatusEnum } from "../../shared/constants/superbill-status-enum";
import { Title } from "@angular/platform-browser";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { MigratedInvoiceService } from "../services/migrated-invoice.service";
import { MigratedInvoice } from "../../modals/billings/migrated-invoice";

@Component({
  selector: "sib-superbill-view",
  templateUrl: "./superbill-view.component.html",
  styleUrls: ["./superbill-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SuperbillViewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("grids")
  gridsViewChildRef: SuperbillHoardingsComponent;

  @ViewChild("superbillInvoiceGrid")
  superbillInvoiceGrid;

  isLoading = false;
  campaignId = null;
  campaign: Campaign = new Campaign();
  billed: boolean = null;
  pieChartData: any[] = [];
  pieConfig: PieChartConfig;
  pieElementId: string;
  rentStackedBarElementId: string;
  printingStackedBarElementId: string;
  mountingStackedBarElementId: string;
  pieChartArea: ChartArea = new ChartArea();
  pieChartLegend: ChartLegend = new ChartLegend();
  pieChartColors: string[];
  rentStackedBarChartData: any[] = [];
  printingStackedBarChartData: any[] = [];
  mountingStackedBarChartData: any[] = [];
  stackedBarConfig: StackedBarChartConfig = new StackedBarChartConfig();
  stackedBarChartArea: ChartArea = new ChartArea();
  stackedBarChartLegend: ChartLegend = new ChartLegend();
  stackedBarChartColors: string[];
  stackedBarChartHeight: number | string;
  stackedBarChartWidth: number | string;
  stackedBarChartHAxis: HAxis = new HAxis();
  invoices: Invoice[] = [];
  invoiceAmounts: any[] = [];
  pendingAmount: number;
  PO: any[5] = [1, 2, 3, 4, 5];
  rentItems: RentItem[] = [];
  printingItems: AdditionalItem[] = [];
  mountingItems: AdditionalItem[] = [];
  pendingRentItems: RentItem[] = [];
  pendingPrintingItems: AdditionalItem[] = [];
  pendingMountingItems: AdditionalItem[] = [];
  totalPendingRentalAmount: number = 0;
  totalPendingPrintingAmount: number = 0;
  totalPendingMountingAmount: number = 0;
  totalOverdueRentalAmount: number = 0;
  totalOverduePrintingAmount: number = 0;
  totalOverdueMountingAmount: number = 0;
  subscription: Subscription[] = [];
  displayBillingNotes = false; // for billing notes component
  selectedInvoice: Invoice = new Invoice(); // for billing notes component
  billedAmount: number;
  unbilledAmount: number;
  unbilledPrice: string;
  showGenerate = true;
  currentDate: Date;
  rentItemsSet = false;
  printItemsSet = false;
  mountItemsSet = false;
  rentItemsSetSuperbill = false;
  printItemsSetSuperbill = false;
  mountItemsSetSuperbill = false;
  billMarginParameter: number;
  amountOverdueDaysParameter: number;
  invoiceFreezeDaysParameter: number;
  igstParameter: number;
  gstParameter: number;
  cgstParameter: number;
  sgstParameter: number;
  billedAmountWithoutTax: number;
  noState = true; // to check if address is not present
  completelyBilled = false; // to check if there is any unbilled amount left
  campaignExpired = false;
  rentUnsub: Subject<boolean> = new Subject<boolean>();
  printUnsub: Subject<boolean> = new Subject<boolean>();
  mountUnsub: Subject<boolean> = new Subject<boolean>();
  disableAdjust = false;
  showAdjustDialog = false;
  // admin = false;
  // accountant = false;
  // salesRepresentative = false;
  // billHandler = false;
  isCampaignBalanced = false;
  pieChartLoader = true;
  stackedBarChartLoader = true;
  enableGenerate = true;
  superBillAmount: number;
  superBillAmountSet = false;
  migratedInvoice: MigratedInvoice = new MigratedInvoice();

  @ViewChild("sibRentStackedBarChart")
  rentChart;

  @ViewChild("sibPrintingStackedBarChart")
  printChart;

  @ViewChild("sibMountingStackedBarChart")
  mountChart;

  @ViewChild("sibPieChart")
  pieChart;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.rentChart.showChart();
    this.printChart.showChart();
    this.mountChart.showChart();
    this.pieChart.showChart();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private calculationService: CalculationService,
    private firebaseService: FirebaseService,
    private billingActionDispatcher: BillingActionDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private billingConfigurationService: BillingConfigurationService,
    private taxConfigurationService: TaxConfigurationService,
    private confirmationService: ConfirmationService,
    private superBillsService: SuperBillsService,
    private notificationServcie: NotificatoinsService,
    private currencyPipe: CurrencyHelperPipe,
    private billingGridService: BillingGridService,
    private migratedInvoiceService: MigratedInvoiceService,
    private pageTitle: Title
  ) {}

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle
      ? this.pageTitle.setTitle(SIBConstants.VIEW_SUPER_BILL)
      : this.pageTitle.setTitle(
          SIBConstants.VIEW_SUPER_BILL + " - " + appTitle
        );
    this.isLoading = true;
    this.currentDate = new Date();
    this.getBillingConfiguration();
    this.getTaxConfiguration();
    this.getRouteId();
    // this.setRoles();
    // this.getCampaign(); // moved after igst is fetched
    this.getInvoices();
    this.subscription.push(
      this.billingActionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.isLoading = false;
          this.billingActionDispatcher.resetError();
        }
      })
    );

    // this.pieChart.pieChartService.chartLoaded.subscribe((value) => {
    //     if (value) {
    //       this.pieChartLoader = false;
    //     }
    //   });

    //   this.mountChart.stackedBarChartService.chartLoaded.subscribe((value) => {
    //     if (value) {
    //       this.stackedBarChartLoader = false;
    //     }
    //   });
  }

  shouldEnableGenerate() {
    this.subscription.push(
      this.billingGridService
        .get(null, AppUrls.GENERATE_ENABLED + AppUrls.SLASH + this.campaign.id)
        .subscribe(
          (response) => {
            this.enableGenerate = response["data"];
          },
          (error) => {
            // console.log(error);
          }
        )
    );
  }

  getRouteId() {
    this.subscription.push(
      this.route.params.subscribe((params: { id: string }) => {
        this.campaignId = params.id;
      })
    );
  }

  getCampaign() {
    this.subscription.push(
      this.campaignService
        .getCampaign(this.campaignId)
        .subscribe((response: any) => {
          if (response) {
            this.campaign = response["data"];
            this.isCampaignBalanced =
              SuperBillStatusEnum[this.campaign.superBillStatus] ===
              SuperBillStatusEnum.BALANCED
                ? true
                : false;
            this.showGenerate = !this.isCampaignBalanced;
            // this.campaignExpired =
            //   CampaignStatusEnum[this.campaign.status] ===
            //   CampaignStatusEnum.EXPIRED
            //     ? true
            //     : false;
            this.noState = this.campaign.customer.state ? false : true;
            this.billedAmount = 0;
            this.billedAmount = this.getDecimalValue(
              this.campaign.billingAmount,
              2
            ); // with tax
            this.billedAmountWithoutTax = this.getDecimalValue(
              this.calculationService.calculateAmountWithoutTax(
                this.billedAmount,
                this.igstParameter
              ),
              2
            );
            // console.log("billed amount", this.billedAmountWithoutTax);
            this.billedAmountWithoutTax = JSON.parse(
              JSON.stringify(this.billedAmountWithoutTax)
            );
            this.shouldEnableGenerate();
            this.setPendingItems();
            this.setSuperBillAmount();
          }
        })
    );
  }

  getInvoices() {
    this.billingActionDispatcher.getInvoices(this.campaignId);
    this.subscription.push(
      this.billingActionDispatcher.invoices.subscribe((invoices) => {
        // if (invoices !== undefined && invoices['_embedded'] !== undefined) {
        //     this.isLoading = false;
        //     if (invoices.length !== 0) {
        //         this.invoices = invoices['_embedded']['invoices'];
        //         // console.log("Invoices....", this.invoices);
        //     } else if (invoices['_embedded']['invoices'].length === 0) {
        //         this.invoices = [];
        //     }
        // }
        if (invoices !== undefined) {
          this.isLoading = false;
          if (invoices.length !== 0) {
            this.invoices = invoices;
            // console.log("Invoices....", this.invoices);
          } else if (invoices.length === 0) {
            this.invoices = [];
          }
        }
      })
    );
  }

  // getMigratedInvoices() {
  //     this.migratedInvoiceService.get();

  // }

  setRoles() {
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // const roles = userInfo[0].app_metadata.authorization.roles;
    // const permissions = userInfo[0].app_metadata.authorization.permissions;
    // this.admin = (permissions.indexOf('approve:invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
    // this.accountant = (permissions.indexOf('approve:account-invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
    // this.salesRepresentative = (permissions.indexOf('request:edit-invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
    // this.billHandler = (permissions.indexOf('update:invoice-print') !== -1) ? true : false;
  }

  ngAfterViewInit() {}

  getBillingConfiguration() {
    this.billingConfigurationService.getBillingConfiguration();

    this.subscription.push(
      this.billingConfigurationService.billMargin.subscribe((billMargin) => {
        this.billMarginParameter = billMargin;
      })
    );

    this.subscription.push(
      this.billingConfigurationService.invoiceFreezeDays.subscribe(
        (invoiceFreezeDays) => {
          this.invoiceFreezeDaysParameter = invoiceFreezeDays;
        }
      )
    );

    this.subscription.push(
      this.billingConfigurationService.amountOverDueDays.subscribe(
        (amountOverDueDays) => {
          this.amountOverdueDaysParameter = amountOverDueDays;
        }
      )
    );
  }

  getTaxConfiguration() {
    this.taxConfigurationService.getTaxConfiguration();

    this.subscription.push(
      this.taxConfigurationService.gst.subscribe((gst) => {
        this.gstParameter = gst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.cgst.subscribe((cgst) => {
        this.cgstParameter = cgst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.sgst.subscribe((sgst) => {
        this.sgstParameter = sgst;
      })
    );

    this.subscription.push(
      this.taxConfigurationService.igst.subscribe((igst) => {
        this.igstParameter = igst;
        this.getCampaign();
      })
    );
  }

  /**
   * setting pending bill card
   *
   * @memberof SuperbillViewComponent
   */
  setPendingItems() {
    this.isLoading = true;
    this.unbilledAmount = 0;
    this.pendingRentItems = [];
    this.pendingPrintingItems = [];
    this.pendingMountingItems = [];
    const pendingBillingGrid: BillingGrid = new BillingGrid();
    pendingBillingGrid.billed = false;
    pendingBillingGrid.campaignId = this.campaignId;
    pendingBillingGrid.itemStartDate = new Date(
      this.campaign.campaignStartDate
    );
    pendingBillingGrid.itemEndDate = new Date(this.campaign.campaignEndDate);
    pendingBillingGrid.eligibleToGenBill = true;
    this.billingActionDispatcher.getPendingRentItems(pendingBillingGrid);
    this.billingActionDispatcher.getPendingPrintingItems(pendingBillingGrid);
    this.billingActionDispatcher.getPendingMountingItems(pendingBillingGrid);
    this.getPendingRentItems();
  }

  setSuperBillAmount() {
    this.isLoading = true;
    this.superBillAmount = 0;
    const billingGrid: BillingGrid = new BillingGrid();
    // billingGrid.billed = false;
    billingGrid.campaignId = this.campaignId;
    billingGrid.itemStartDate = new Date(this.campaign.campaignStartDate);
    billingGrid.itemEndDate = new Date(this.campaign.campaignEndDate);
    billingGrid.eligibleToGenBill = true;
    this.getRentItems(billingGrid);
  }

  getRentItems(billingGrid) {
    this.billingGridService
      .create(billingGrid, null, AppUrls.GET_RENT_ITEMS)
      .subscribe(
        (rentItems) => {
          this.rentItems = rentItems["data"];
          this.getPrintingItems(billingGrid);
        },
        (error) => {
          this.notificationServcie.error(
            error.error ? error.error.message : "Some technical Error",
            "Error"
          );
        }
      );
  }

  getPrintingItems(billingGrid) {
    this.billingGridService
      .create(billingGrid, null, AppUrls.GET_PRINTING_ITEMS)
      .subscribe(
        (printItems) => {
          this.printingItems = printItems["data"];
          this.getMountingItems(billingGrid);
        },
        (error) => {
          this.notificationServcie.error(
            error.error ? error.error.message : "Some technical Error",
            "Error"
          );
        }
      );
  }

  getMountingItems(billingGrid) {
    this.billingGridService
      .create(billingGrid, null, AppUrls.GET_MOUNTING_ITEMS)
      .subscribe(
        (mountingtems) => {
          this.mountingItems = mountingtems["data"];
          this.setAllSuperbillItems();
        },
        (error) => {
          this.notificationServcie.error(
            error.error ? error.error.message : "Some technical Error",
            "Error"
          );
        }
      );
  }

  getPendingRentItems() {
    this.subscription.push(
      this.billingActionDispatcher.pendingRentItems
        .pipe(takeUntil(this.rentUnsub))
        .subscribe((pendingRentItems) => {
          if (pendingRentItems) {
            this.pendingRentItems = pendingRentItems["data"];
            this.rentUnsub.next(true);
            this.getPendingPrintingItems();
          }
        })
    );
  }

  getPendingPrintingItems() {
    this.subscription.push(
      this.billingActionDispatcher.pendingPrintingItems
        .pipe(takeUntil(this.printUnsub))
        .subscribe((pendingPrintingItems) => {
          if (pendingPrintingItems) {
            this.pendingPrintingItems = pendingPrintingItems["data"];
            this.printUnsub.next(true);
            this.getPendingMountingItems();
          }
        })
    );
  }

  getPendingMountingItems() {
    this.subscription.push(
      this.billingActionDispatcher.pendingMountingItems
        .pipe(takeUntil(this.mountUnsub))
        .subscribe((pendingMountingItems) => {
          if (pendingMountingItems) {
            this.pendingMountingItems = pendingMountingItems["data"];
            this.mountUnsub.next(true);
            this.setAllItems();
          }
        })
    );
  }

  setAllSuperbillItems() {
    this.setRentItems();
    this.setPrintingItems();
    this.setMountingItems();
    this.superBillAmount = JSON.parse(JSON.stringify(this.superBillAmount));
    this.superBillAmountSet = true;
    // this.isLoading = false;
    this.setAllCharts();
  }

  setRentItems() {
    this.rentItems.forEach((item) => {
      this.superBillAmount += item.cost;
    });
  }

  setPrintingItems() {
    this.printingItems.forEach((item) => {
      this.superBillAmount += item.amount;
    });
  }

  setMountingItems() {
    this.mountingItems.forEach((item) => {
      this.superBillAmount += item.amount;
    });
  }

  setAllItems() {
    this.setPendingRentItems();
    this.setPendingPrintingItems();
    this.setPendingMountingItems();
    this.billingActionDispatcher.resetPendingRentItems();
    this.billingActionDispatcher.resetPendingPrintingItems();
    this.billingActionDispatcher.resetPendingMountingItems();
    this.rentItemsSet = true;
    this.printItemsSet = true;
    this.mountItemsSet = true;
    this.unbilledAmount += this.getParsedValueToDecimalTwoPlaces(
      Number(
        Number(this.totalPendingRentalAmount) +
          Number(this.totalPendingPrintingAmount) +
          Number(this.totalPendingMountingAmount)
      )
    );
    // this.disableAdjust = (this.campaignExpired && this.unbilledAmount === 0 && this.pendingRentItems.length === 0 && this.pendingPrintingItems.length === 0 && this.pendingMountingItems.length === 0) ? false : true;
    this.disableAdjust =
      this.campaignExpired &&
      this.getIntegerRoundedOffValue(this.unbilledAmount) !== 0
        ? false
        : true;
    this.setAllCharts();
  }

  setAllCharts() {
    this.setRentStackedBarChart();
    this.setPrintingStackedBarChart();
    this.setMountingStackedBarChart();
    this.setPieChart();
  }

  // setPendingRentItems() {
  //     this.totalPendingRentalAmount = 0;
  //     this.pendingRentItems.forEach((item) => {
  //         const overDueDate = this.setOverDueDate(new Date(item.itemStartDate));
  //         if (new Date(this.calculationService.setToBeginning(this.currentDate)) >= new Date(this.calculationService.setToBeginning(overDueDate))) {
  //             let days: number;
  //             if (new Date(this.calculationService.setToBeginning(this.currentDate)) < new Date(this.calculationService.setToBeginning(new Date(item.itemEndDate)))) {
  //                 days = (this.amountOverdueDaysParameter === 0) ? this.calculationService.calculateDays(overDueDate, this.currentDate) : this.calculationService.calculateDays(overDueDate, this.currentDate, false);
  //             } else {
  //                 days = (this.amountOverdueDaysParameter === 0) ? this.calculationService.calculateDays(overDueDate, new Date(item.itemEndDate)) : this.calculationService.calculateDays(overDueDate, new Date(item.itemEndDate), false);
  //             }
  //             this.totalOverdueRentalAmount += this.calculationService.calculateCostOfHoarding(item.rate, days);
  //         }
  //         this.totalPendingRentalAmount += item.cost;
  //     });
  // }

  setPendingRentItems() {
    this.totalPendingRentalAmount = 0;
    this.pendingRentItems.forEach((item) => {
      let days: number;
      // if current date is less than (item end date + overdue days)
      // then current date minus overdue days will give you the date upto which overdue amount is to be shown
      // else overdue amount for the duration of the item will be shown
      if (this.amountOverdueDaysParameter) {
        // if amount overdue days exists
        if (
          new Date(this.calculationService.setToBeginning(this.currentDate)) <
          new Date(
            this.calculationService.setToBeginning(
              this.calculationService.extendDate(
                item.itemEndDate,
                this.amountOverdueDaysParameter
              )
            )
          )
        ) {
          // if current date is less than (item end date + overdue days)
          let overdueDate = this.calculationService.extendDate(
            this.currentDate,
            -Number(this.amountOverdueDaysParameter)
          );
          if (
            new Date(overdueDate).getTime() -
              new Date(item.itemStartDate).getTime() <
            0
          ) {
            // if overdue date is less than item start date
            days = 0;
          } else {
            // otherwise
            days = this.calculationService.calculateDays(
              item.itemStartDate,
              overdueDate
            );
          }
        } else {
          // Otherwise
          days = this.calculationService.calculateDays(
            item.itemStartDate,
            item.itemEndDate
          );
        }
      } else {
        // Otherwise
        if (
          new Date(this.calculationService.setToBeginning(this.currentDate)) <
          new Date(this.calculationService.setToBeginning(item.itemEndDate))
        ) {
          days = this.calculationService.calculateDays(
            item.itemStartDate,
            this.currentDate
          );
        } else {
          days = this.calculationService.calculateDays(
            item.itemStartDate,
            item.itemEndDate
          );
        }
      }
      this.totalOverdueRentalAmount += this.calculationService.calculateCostOfHoarding(
        item.rate,
        days
      );
      this.totalPendingRentalAmount += item.cost;
    });
  }

  setPendingPrintingItems() {
    this.totalPendingPrintingAmount = 0;
    this.pendingPrintingItems.forEach((item) => {
      const overDueDate = this.setOverDueDate(new Date(item.serviceDate));
      if (
        new Date(this.calculationService.setToBeginning(this.currentDate)) >=
        new Date(this.calculationService.setToBeginning(overDueDate))
      ) {
        this.totalOverduePrintingAmount += item.amount;
      }
      this.totalPendingPrintingAmount += item.amount;
    });
  }

  setPendingMountingItems() {
    this.totalPendingMountingAmount = 0;
    this.pendingMountingItems.forEach((item) => {
      const overDueDate = this.setOverDueDate(new Date(item.serviceDate));
      if (
        new Date(this.calculationService.setToBeginning(this.currentDate)) >=
        new Date(this.calculationService.setToBeginning(overDueDate))
      ) {
        this.totalOverdueMountingAmount += item.amount;
      }
      this.totalPendingMountingAmount += item.amount;
    });
  }

  setOverDueDate(date: Date) {
    const startMilSec = new Date(
      this.calculationService.setToBeginning(date)
    ).valueOf();
    const overDueMillSec = this.calculationService.getMilSecFromDays(
      this.amountOverdueDaysParameter
    );
    const totalMillSec = startMilSec + overDueMillSec;
    const overDueDate: Date = this.calculationService.getDateFromMillSec(
      totalMillSec
    );
    return overDueDate;
  }

  getDecimalValue(value, fractionPlaces) {
    return Number(Math.fround(value).toFixed(fractionPlaces));
  }

  getParsedValueToDecimalTwoPlaces(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  setRentStackedBarChart() {
    this.setStackedBarChartHeightAndWidth();
    this.setStackedBarChartArea();
    this.setStackedBarLegend();
    this.setStackedBarColors();
    this.setStackedBarHAxis();
    this.setStackedBarChart();
  }

  setPrintingStackedBarChart() {
    this.setStackedBarChartHeightAndWidth();
    this.setStackedBarChartArea();
    this.setStackedBarLegend();
    this.setStackedBarColors();
    this.setStackedBarHAxis();
    this.setStackedBarChart();
  }

  setMountingStackedBarChart() {
    this.setStackedBarChartHeightAndWidth();
    this.setStackedBarChartArea();
    this.setStackedBarLegend();
    this.setStackedBarColors();
    this.setStackedBarHAxis();
    this.setStackedBarChart();
  }

  setStackedBarChartHeightAndWidth() {
    this.stackedBarChartHeight = "10%"; // 52
    this.stackedBarChartWidth = "90%"; // 90%
  }

  setStackedBarChartArea() {
    this.stackedBarChartArea.height = 150; // 15
    this.stackedBarChartArea.width = 300; // 90%
    this.stackedBarChartArea.left = 80;
    this.stackedBarChartArea.top = 0;
  }

  setStackedBarLegend() {
    this.stackedBarChartLegend.position = "bottom";
    this.stackedBarChartLegend.alinment = "center";
    this.stackedBarChartLegend.textStyle.bold = false;
    this.stackedBarChartLegend.textStyle.italic = true;
    this.stackedBarChartLegend.textStyle.fontSize = "10";
  }

  setStackedBarColors() {
    this.stackedBarChartColors = ["orange", "#11426d"];
  }

  setStackedBarHAxis() {
    this.stackedBarChartHAxis.maxValue = this.getDecimalValue(
      Number(this.campaign.summary.grandTotal) -
        Number(this.campaign.summary.totalGST),
      0
    );
    this.stackedBarChartHAxis.maxValue = JSON.parse(
      JSON.stringify(this.stackedBarChartHAxis.maxValue)
    );
  }

  setStackedBarChart() {
    if (this.rentItemsSet && this.printItemsSet && this.mountItemsSet) {
      this.mountingStackedBarChartData = [
        ["Type", "Overdue", "Pending"],
        [
          "Rental",
          this.getIntegerRoundedOffValue(this.totalOverdueRentalAmount),
          this.getIntegerRoundedOffValue(
            Math.abs(
              this.totalPendingRentalAmount - this.totalOverdueRentalAmount
            )
          ),
        ],
        [
          "Printing",
          this.getIntegerRoundedOffValue(this.totalOverduePrintingAmount),
          this.getIntegerRoundedOffValue(
            Math.abs(
              this.totalPendingPrintingAmount - this.totalOverduePrintingAmount
            )
          ),
        ],
        [
          "Mounting",
          this.getIntegerRoundedOffValue(this.totalOverdueMountingAmount),
          this.getIntegerRoundedOffValue(
            Math.abs(
              this.totalPendingMountingAmount - this.totalOverdueMountingAmount
            )
          ),
        ],
      ];
      // this.stackedBarConfig = new StackedBarChartConfig(null, true, 'vertical', this.stackedBarChartHeight, this.stackedBarChartWidth, this.stackedBarChartArea, this.stackedBarChartLegend, this.stackedBarChartColors, this.stackedBarChartHAxis);
      this.stackedBarConfig.isStacked = true;
      this.stackedBarConfig.bars = "vertical";
      this.stackedBarConfig.height = this.stackedBarChartHeight;
      this.stackedBarConfig.width = this.stackedBarChartWidth;
      this.stackedBarConfig.chartArea = this.stackedBarChartArea;
      this.stackedBarConfig.chartLegend = this.stackedBarChartLegend;
      this.stackedBarConfig.colors = this.stackedBarChartColors;
      this.stackedBarConfig.hAxis = this.stackedBarChartHAxis;

      this.mountingStackedBarElementId = "mountingStackedBarChart";
    }
  }

  /**
   * to set the header section
   *
   * @memberof SuperbillViewComponent
   */
  setPieChart() {
    if (
      this.rentItemsSet &&
      this.printItemsSet &&
      this.mountItemsSet &&
      this.superBillAmountSet
    ) {
      this.billedAmountWithoutTax = JSON.parse(
        JSON.stringify(this.billedAmountWithoutTax)
      );
      this.pieChartData = [
        ["Type", "Amount"],
        ["Billed", this.getIntegerRoundedOffValue(this.billedAmountWithoutTax)],
        ["Unbilled", this.getIntegerRoundedOffValue(this.unbilledAmount)],
      ];
      this.setPieChartArea();
      this.setPieChartLegend();
      this.setPieChartColors();
      let slices = !this.getIntegerRoundedOffValue(this.unbilledAmount)
        ? {
            0: {
              textStyle: {
                color: "black",
              },
            },
          }
        : {
            0: {
              textStyle: {
                color: "white",
              },
            },
          };
      this.pieConfig = new PieChartConfig(
        null,
        0.5,
        false,
        null,
        null,
        this.pieChartArea,
        this.pieChartLegend,
        this.pieChartColors,
        null,
        slices,
        {
          color: "black",
        }
      );
      this.pieElementId = "myPieChart";
      if (
        this.unbilledAmount === 0 &&
        this.pendingRentItems.length === 0 &&
        this.pendingPrintingItems.length === 0 &&
        this.pendingMountingItems.length === 0
      ) {
        this.completelyBilled = true;
      } else {
        this.completelyBilled = false;
      }
      this.isLoading = false;
    }
  }

  setPieChartArea() {
    this.pieChartArea.height = "90%";
    this.pieChartArea.width = "90%";
    this.pieChartArea.left = 0;
    this.pieChartArea.top = "3%";
  }

  setPieChartLegend() {
    this.pieChartLegend.position = "left";
    this.pieChartLegend.alinment = "center";
    this.pieChartLegend.textStyle.bold = false;
    this.pieChartLegend.textStyle.italic = true;
    this.pieChartLegend.textStyle.fontSize = "12";
  }

  setPieChartColors() {
    this.pieChartColors = ["#11426d", "#dfe0ef"];
  }

  adjustSuperBill() {
    // this.showAdjustDialog = true;
    this.confirmationService.confirm({
      message:
        "Amount of Rs." +
        new CurrencyHelperPipe().transform(
          this.getIntegerRoundedOffValue(this.unbilledAmount)
        ) +
        " will be adjusted. Are you sure?",
      accept: () => {
        this.superBillsService
          .get(null, AppUrls.ADJUSTMENT + "/" + this.campaign.id)
          .subscribe(
            (response) => {
              this.notificationServcie.success(
                "Superbill Adjusted",
                "Superbill Adjusted Successfully"
              );
              this.getCampaign();
              // this.getTaxConfiguration();
            },
            (error) => {
              this.notificationServcie.error(
                error.error ? error.error.message : "Some technical Error",
                "Error"
              );
            }
          );
      },
    });
  }

  /**
   * to go to the bill detail screen
   *
   * @memberof SuperbillViewComponent
   */
  viewBills(invoice) {
    this.router.navigate(["billings/bills/view/" + invoice.id]);
  }

  detectChanges() {
    if (!this.changeDetectorRef["destroyed"]) {
      this.changeDetectorRef.detectChanges();
    }
  }

  viewPO(PO) {
    this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
      window.open(response);
    });
  }

  viewNotes(invoice) {
    this.displayBillingNotes = true;
    this.selectedInvoice = invoice;
  }

  hideBillingNotesComponent() {
    this.displayBillingNotes = false;
  }

  getRoundOffValue(value1, value2) {
    const value = Number(value1 - value2);
    return Math.round(value);
  }

  getIntegerRoundedOffValue(value) {
    return utils.getIntegerRoundedOffValue(value);
  }

  getAbsoluteValue(value) {
    return Math.abs(value);
  }

  superbillDifference() {
    // const differenceValue = this.getParsedValueToDecimalTwoPlaces(this.billedAmountWithoutTax) + this.getParsedValueToDecimalTwoPlaces(this.unbilledAmount) - this.getParsedValueToDecimalTwoPlaces(this.campaign.summary.grandTotal - this.campaign.summary.totalGST);
    const differenceValue =
      this.getParsedValueToDecimalTwoPlaces(this.billedAmountWithoutTax) +
      this.getParsedValueToDecimalTwoPlaces(this.unbilledAmount) -
      this.getParsedValueToDecimalTwoPlaces(this.superBillAmount);
    return differenceValue;
  }

  onInvoiceDeletion(event) {
    if (event.isLoading === true && event.isDeleted === false) {
      this.isLoading = true;
    } else if (event.isLoading === false && event.isDeleted === true) {
      this.isLoading = false;
      this.getCampaign();
      this.getInvoices();
    } else if (event.isLoading === false && event.isDeleted === false) {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
    this.rentUnsub.unsubscribe();
    this.printUnsub.unsubscribe();
    this.mountUnsub.unsubscribe();
  }
}
