import { of as observableOf, Observable, Subscription } from "rxjs";
import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  EditableGridFieldTypes,
  GridColumn,
  GridConfig,
  SelectionMode,
  GridPaginationEvent,
} from "../../components/sib-forms/grid/grid.config";
import { InvoiceStatus } from "../../shared/constants/invoice-status-enum";
import { DateUtil } from "../../helpers/date.util";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Invoice } from "../../modals/billings/invoice";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import {
  FormGroup,
  FormControl,
} from "../../../../node_modules/@angular/forms";
import { InvoiceService } from "../../services/shared/invoice.service";
import { Router } from "@angular/router";
import { SelectItem, ConfirmationService } from "primeng/primeng";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import * as utils from "../../helpers/utils";
import { StatusVisualizationNode } from "../../modals/billings/status-visualization-node";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { UserGridConfigService } from "../../services/shared/user-grid-config.service";
import { GridNameEnum } from "../../shared/constants/grid-name.enum";
import { BillingUIService } from "../services/billingUI.service";
import { MigratedInvoice } from "../../modals/billings/migrated-invoice";
import { CustomerUiService } from "../../customers/services/customer-ui.service";
import { MigratedInvoiceService } from "../services/migrated-invoice.service";

@Component({
  selector: "sib-superbill-invoices",
  templateUrl: "./superbill-invoices.component.html",
  styleUrls: ["./superbill-invoices.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SuperbillInvoicesComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("campaignInvoicesSibGrid")
  campaignInvoicesSibGridRef;

  invoiceEditPendingOrRejected = false;
  generatePressed = false; // to be used in deactivae service

  @Input() set invoices(invoices) {
    this._invoices = invoices;
    this._invoices = JSON.parse(JSON.stringify(this._invoices));
    this._invoices.forEach((inv) => {
      if (
        InvoiceStatus[inv.status] === InvoiceStatus.EDIT_PENDING ||
        InvoiceStatus[inv.status] === InvoiceStatus.ADMIN_GEN_REJECT ||
        InvoiceStatus[inv.status] === InvoiceStatus.ADMIN_EDIT_REJECT
      ) {
        this.invoiceEditPendingOrRejected = true;
      }
    });
    this._invoices.sort(this.customSort);
    if (this.campaignInvoicesGridConfig.dataLoadFunction !== undefined) {
      this.campaignInvoicesSibGridRef.refresh();
    }
  }

  get invoices() {
    return this._invoices;
  }

  _activeIndex: number;
  set activeIndex(activeIndex) {
    this._activeIndex = activeIndex;
    this.ngOnInit();
  }

  get activeIndex() {
    return this._activeIndex;
  }

  @Input() showGenerate = true;

  @Input() noState = true;

  @Input() campaignId = null;

  @Input() completelyBilled = false;

  @Input() enableGenerate = true;

  @Input() campaignExpired = false;

  @Input() disableAdjust = true;

  @Input() itemRateChanged = false;

  @Output() eEmitAdjustSuperBill: EventEmitter<any> = new EventEmitter();
  @Output() eEmitIsInvoiceItemDeleted: EventEmitter<any> = new EventEmitter();

  subscription: Subscription[] = [];
  _invoices: Invoice[] = [];
  campaignInvoicesGroup: FormGroup;
  campaignInvoicesGridConfig: GridConfig<Invoice> = new GridConfig<Invoice>();
  campaignInvoicesButtonConfig: GridActionButtonConfig;
  campaignInvoicesTotalRecords: number;

  invoiceStatusDropdown: any[] = [];

  showAddressDialog = false;
  invoiceType: string = "";
  migratedInvoice: MigratedInvoice[] = [];

  // admin = false;
  // accountant = false;
  // salesRepresentative = false;
  statusVisualizationNodes: StatusVisualizationNode[] = [];

  // to set the values of visualization chars and titles
  pendingAt: any[] = [];
  charAt: any[] = [];
  charTitleAt: any[] = [];
  rowsPerPage: number;

  // to check in which flow the invoice is present
  generateFlowStatus = [];
  editFlowStatus = [];
  cancelFlowStatus = [];

  // to check with whom the invoice is pending
  srPendingStatus = [];
  adminPendingStatus = [];
  accountantPendingStatus = [];
  flowCompleteWithApprovalStatus = [];
  flowCompleteWithRejectionStatus = [];

  filterApplied = false;
  sortApplied = false;
  userRole: string = "";
  selectedTabIndex: number = 0;
  showTab: boolean = false;
  campaignInvoicesColumns: Array<GridColumn> = [
    {
      field: "srNo",
      name: "srNo",
      header: "Sr",
      required: true,
      width: "2vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: "sr-number",
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: "campaign.campaignId",
      name: "campaign.campaignId",
      header: "Campaign Id",
      required: true,
      width: "5.6vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign && data.campaign.campaignId) {
          return data.campaign.campaignId;
        } else {
          return "-";
        }
      },
    },
    //------------------------------Start --Sanchit Mirg 18-04-02019 Commented the Id field becuase now we are showing invoiceId----
    /* {
            field: 'id',
            name: 'id',
            header: 'Invoice No',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        }, */
    {
      field: "invoiceId",
      name: "invoiceId",
      header: "Invoice No",
      required: true,
      width: "8vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
    },
    {
      field: "campaign.displayName",
      name: "campaign.displayName",
      header: "Display Name",
      required: true,
      width: "6vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: "contains",
        placeHolder: "Search",
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign && data.campaign.displayName) {
          return data.campaign.displayName;
        } else {
          return "-";
        }
      },
    },
    //-----------------------------------------Sanchit Mirg 18-04-2019 End------------------------------------------------
    // {
    //     field: 'campaign.customer.company',
    //     name: 'campaign.customer.company',
    //     header: 'Company',
    //     required: true,
    //     width: '6vw',
    //     editable: false,
    //     default: true,
    //     hidden: false,
    //     permanent: true,
    //     sortable: true,
    //     styleClass: 'word-break-wrap ',
    //     type: EditableGridFieldTypes.TEXT,
    //     filter: {
    //         matchMode: 'contains',
    //         placeHolder: 'Search',
    //         custom: false,
    //     },
    //     displayFn: (data) => {
    //         if (data.campaign.customer.company) {
    //             return data.campaign.customer.company;
    //         } else {
    //             return '-';
    //         }
    //     },
    // },
    {
      field: "billGeneratedDate",
      name: "billGeneratedDate",
      header: "Bill Generated Date",
      required: true,
      width: "5vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billGeneratedDate) {
          return DateUtil.dategridFormatter(data.billGeneratedDate);
        } else {
          return "-";
        }
      },
    },
    {
      field: "billStartDate",
      name: "billStartDate",
      header: "Bill Start Date",
      required: true,
      width: "5vw",
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billStartDate) {
          return DateUtil.dategridFormatter(data.billStartDate);
        } else {
          return "-";
        }
      },
    },
    {
      field: "billEndDate",
      name: "billEndDate",
      header: "Bill End Date",
      required: true,
      width: "5vw",
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billEndDate) {
          return DateUtil.dategridFormatter(data.billEndDate);
        } else {
          return "-";
        }
      },
    },
    // {
    //     field: 'campaign.displayName',
    //     name: 'campaign.displayName',
    //     header: 'Display Name',
    //     required: true,
    //     width: '6vw',
    //     editable: false,
    //     default: true,
    //     hidden: false,
    //     permanent: true,
    //     sortable: true,
    //     styleClass: 'word-break-wrap ',
    //     type: EditableGridFieldTypes.CUSTOM,
    //     filter: {
    //         matchMode: 'contains',
    //         placeHolder: 'Search',
    //         custom: false,
    //     },
    // },
    {
      field: "status",
      name: "status",
      header: "Status",
      required: true,
      width: "10vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: true,
      // },
      displayFn: (data) => {
        if (data.status) {
          return InvoiceStatus[data.status];
        } else {
          return "-";
        }
      },
    },
    {
      field: "rentAmount",
      name: "rentAmount",
      header: "Rent Amount",
      required: true,
      width: "5vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.rentAmount !== null) {
          return this.currencyPipe.transform(
            utils.parseValueToDecimalTwoPlaces(data.rentAmount)
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "printAmount",
      name: "printAmount",
      header: "Printing Amount",
      required: true,
      width: "5vw",
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.printAmount !== null) {
          return this.currencyPipe.transform(
            utils.parseValueToDecimalTwoPlaces(data.printAmount)
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "mountAmount",
      name: "mountAmount",
      header: "Mounting Amount",
      required: true,
      width: "5.5vw",
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.mountAmount !== null) {
          return this.currencyPipe.transform(
            utils.parseValueToDecimalTwoPlaces(data.mountAmount)
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "totalAmount",
      name: "totalAmount",
      header: "Bill Amount",
      required: true,
      width: "5.5vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.totalAmount !== null) {
          return this.currencyPipe.transform(
            utils.getIntegerRoundedOffValue(data.totalAmount)
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "lspDiff",
      name: "lspDiff",
      header: "LSP Difference",
      required: true,
      width: "6vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.lspDiff !== null) {
          return this.currencyPipe.transform(data.lspDiff);
        } else {
          return "-";
        }
      },
    },
    {
      field: "campaignDiff",
      name: "campaignDiff",
      header: "Campaign Difference",
      required: true,
      width: "6vw",
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: "word-break-wrap ",
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.campaignDiff !== null) {
          return this.currencyPipe.transform(data.campaignDiff);
        } else {
          return "-";
        }
      },
    },
    // {
    //     field: 'PO',
    //     name: 'PO',
    //     header: 'PO',
    //     required: true,
    //     width: '4vw',
    //     editable: false,
    //     default: true,
    //     hidden: false,
    //     permanent: true,
    //     sortable: false,
    //     type: EditableGridFieldTypes.CUSTOM
    // },
    // {
    //   field: 'details',
    //   name: 'details',
    //   header: 'Details',
    //   required: true,
    //   width: '4vw',
    //   editable: false,
    //   default: true,
    //   hidden: false,
    //   permanent: true,
    //   sortable: false,
    //   type: EditableGridFieldTypes.CUSTOM
    // },
    {
      field: "delTempInvoice",
      name: "",
      header: "",
      required: true,
      editable: false,
      width: "3vw",
      hidden: false,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      // align: Alignment.CENTER
    },
  ];

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private billingActionDispatcher: BillingActionDispatcher,
    private userGridConfigService: UserGridConfigService,
    private router: Router,
    private invoiceService: InvoiceService,
    private notificationServcie: NotificatoinsService,
    private billingUIService: BillingUIService,
    private confirmationService: ConfirmationService,
    private customerUiService: CustomerUiService,
    private migratedInvoiceService: MigratedInvoiceService
  ) {}

  ngOnInit() {
    this.userRole = this.customerUiService.getUserRole();
    this.generatePressed = false;
    this.filterApplied = false;
    this.sortApplied = false;
    this.setUserGridConfig();

    this.setFormGroupObjects();

    this.setFilterDropdowns();
    this.setFlows();
    this.setGridConfigObject();

    // this.setRoles();
  }

  onTabChange(event) {
    this.selectedTabIndex = event.index;
    // if (this.selectedTabIndex === 1) {
    //     this.getMigratedInvoices();
    // }
  }

  setUserGridConfig() {
    this.userGridConfigService
      .create([GridNameEnum["SUPERBILL_INVOICE"]])
      .subscribe((invoiceListGridConfig) => {
        this.campaignInvoicesColumns = utils.getUserConfiguredColumns(
          invoiceListGridConfig[GridNameEnum["SUPERBILL_INVOICE"]],
          this.campaignInvoicesColumns
        );
        this.campaignInvoicesSibGridRef.setAllColumns();
      });
  }

  setFormGroupObjects() {
    this.campaignInvoicesGroup = new FormGroup({
      campaignInvoicesGridControl: new FormControl(),
    });
  }

  setFilterDropdowns() {
    this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatus, true);
    // this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatusEnum, true);
  }

  setGridConfigObject() {
    this.campaignInvoicesGridConfig.getDefaultModel = () => new Invoice();
    this.campaignInvoicesGridConfig.model = Invoice;
    this.campaignInvoicesGridConfig.selectionMode = SelectionMode.Multi;
    this.campaignInvoicesGridConfig.editable = true;
    this.campaignInvoicesGridConfig.expandableRows = false;
    this.campaignInvoicesGridConfig.checkboxSelection = false;
    this.campaignInvoicesGridConfig.resizableColumns = false;
    // this.campaignInvoicesGridConfig.rowExpandMode = 'single';
    this.campaignInvoicesGridConfig.shouldAddRowOnDelete = false;
    this.campaignInvoicesGridConfig.showColorCode = true;
    this.campaignInvoicesGridConfig.lazy = false;
    this.campaignInvoicesGridConfig.enablePagination = true;
    this.campaignInvoicesGridConfig.scrollable = true;
    this.campaignInvoicesGridConfig.showColorCode = false;

    this.campaignInvoicesGridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      // for rendering the data on the screen
      if (paginationEvent && paginationEvent.size > 0) {
        this.rowsPerPage = paginationEvent.size;
      }
      this.setPendingAt();
      this.setCharAt();
      this.setStatusVisualizationNode();
      return observableOf(this._invoices);
    };
  }

  setRoles() {
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // const roles = userInfo[0].app_metadata.authorization.roles;
    // const permissions = userInfo[0].app_metadata.authorization.permissions;
    // this.admin = (permissions.indexOf('approve:invoices') !== -1 && permissions.indexOf('reject:invoices') !== -1) ? true : false;
    // this.accountant = (permissions.indexOf('approve:account-invoices') !== -1 && permissions.indexOf('reject:account-invoices') !== -1) ? true : false;
    // this.salesRepresentative = (permissions.indexOf('request:edit-invoices') !== -1) ? true : false;
  }

  ngAfterViewInit() {
    if (this.campaignInvoicesGridConfig.dataLoadFunction !== undefined) {
      this.campaignInvoicesSibGridRef.refresh();
    }
  }

  viewInvoiceDetails(rowData, event) {
    if (event.ctrlKey) {
      // do nothing
    } else {
      const id = rowData.id;
      const encId = encodeURIComponent(id);
      this.router.navigate(["billings/bills/view/" + encId]);
    }
    // this.router.navigate(['billings/bills/view/' + rowData.id]);
  }

  getEncodedURI(id) {
    return encodeURIComponent(id);
  }

  /**
   * to go to the generate bill screen
   *
   * @memberof SuperbillInvoicesComponent
   */
  generateBill() {
    this.generatePressed = true;
    if (this.enableGenerate) {
      if (this.completelyBilled) {
        this.notificationServcie.info(
          "There is no unbilled amount left to create invoice",
          "Generation Prohibited"
        );
      } else {
        if (!this.noState) {
          if (!this.invoiceEditPendingOrRejected) {
            this.router.navigate([
              "billings/generateBill/" + this.campaignId,
              { campaign: true },
            ]);
          } else {
            this.notificationServcie.info(
              "An invoice is pending for editing",
              "Generation Prohibited"
            );
          }
        } else {
          this.showAddressDialog = true;
        }
      }
    } else {
      this.notificationServcie.info(
        "There are no mounted items present to generate the invoice",
        "Generation Prohibited"
      );
    }
  }

  generateMigratedBill() {
    this.generatePressed = true;
    if (this.enableGenerate) {
      if (this.completelyBilled) {
        this.notificationServcie.info(
          "There is no unbilled amount left to create invoice",
          "Generation Prohibited"
        );
      } else {
        if (!this.noState) {
          if (!this.invoiceEditPendingOrRejected) {
            this.router.navigate([
              "billings/generateBill/" + this.campaignId,
              { campaign: true, migrated: true },
            ]);
          } else {
            this.notificationServcie.info(
              "An invoice is pending for editing",
              "Generation Prohibited"
            );
          }
        } else {
          this.showAddressDialog = true;
        }
      }
    } else {
      this.notificationServcie.info(
        "There are no mounted items present to generate the invoice",
        "Generation Prohibited"
      );
    }
  }

  setFlows() {
    // this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
    // this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
    // this.cancelFlowStatus = ['ACCOUNTANT_INVOICE_CANCEL', 'ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
    // this.srPendingStatus = ['ADMIN_GEN_REJECT', 'ADMIN_GEN_APPROVE', 'ACCOUNTANT_GEN_REJECT', 'BILL_GENERATED', 'EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ADMIN_EDIT_APPROVE', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
    // this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_INVOICE_CANCEL', 'ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION'];
    // this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];

    // ============================================= addditional status for completion of flow ====================================================================

    this.generateFlowStatus = [
      "ADMIN_GEN_PENDING",
      "ACCOUNTANT_GEN_PENDING",
      "ADMIN_GEN_REJECT",
      "ACCOUNTANT_GEN_REJECT",
      "ADMIN_GEN_APPROVE",
    ];
    this.editFlowStatus = [
      "EDIT_PENDING",
      "ADMIN_EDIT_PENDING",
      "ACCOUNTANT_EDIT_PENDING",
      "ADMIN_EDIT_REJECT",
      "ACCOUNTANT_EDIT_REJECT",
      "ADMIN_EDIT_APPROVE",
    ];
    this.cancelFlowStatus = [
      "ADMIN_CANCEL_PENDING",
      "ACCOUNTANT_CANCEL_PENDING",
      "ADMIN_CANCEL_REJECT",
      "ACCOUNTANT_CANCEL_REJECT",
      "INVOICE_CANCEL",
      "INVOICE_CANCEL_WITH_RETENTION",
      "ADMIN_FULL_CN_PENDING",
      "PARTIAL_CN_APPLIED",
      "FULL_CN_REJECTED",
      "FULL_CN_APPROVED",
      "PARTIAL_CN_APPROVED",
    ];
    this.srPendingStatus = ["EDIT_PENDING"];
    this.adminPendingStatus = [
      "ADMIN_GEN_PENDING",
      "ADMIN_EDIT_PENDING",
      "ADMIN_CANCEL_PENDING",
      "ADMIN_FULL_CN_PENDING",
    ];
    this.accountantPendingStatus = [
      "ACCOUNTANT_GEN_PENDING",
      "ACCOUNTANT_EDIT_PENDING",
      "ACCOUNTANT_CANCEL_PENDING",
    ];
    this.flowCompleteWithApprovalStatus = [
      "ADMIN_GEN_APPROVE",
      "ADMIN_EDIT_APPROVE",
      "BILL_GENERATED",
      "INVOICE_CANCEL",
      "INVOICE_CANCEL_WITH_RETENTION",
      "FULL_CN_APPROVED",
      "PARTIAL_CN_APPROVED",
    ];
    this.flowCompleteWithRejectionStatus = [
      "ADMIN_GEN_REJECT",
      "ACCOUNTANT_GEN_REJECT",
      "ADMIN_EDIT_REJECT",
      "ACCOUNTANT_EDIT_REJECT",
      "ADMIN_CANCEL_REJECT",
      "ACCOUNTANT_CANCEL_REJECT",
      "FULL_CN_REJECTED",
    ];
  }

  setStatusVisualizationNode() {
    this.statusVisualizationNodes = [
      { header: "SR", title: "Sales Representative" },
      // { header: "ACC", title: "Accountant" },
      { header: "ADM", title: "Admin" },
      { header: "CMP", title: " " }, // for APP or REJ
    ];
  }

  setChar(status, index) {
    if (this.generateFlowStatus.includes(status)) {
      this.charAt[index] = "G";
      this.charTitleAt[index] = SIBConstants.STATUS_GEN_ICON_TOOLTIP;
    }
    if (this.editFlowStatus.includes(status)) {
      this.charAt[index] = "E";
      this.charTitleAt[index] = SIBConstants.STATUS_MOD_ICON_TOOLTIP;
    }
    if (this.cancelFlowStatus.includes(status)) {
      if (status === "FULL_CN_APPROVED") {
        this.charAt[index] = "F";
        this.charTitleAt[index] =
          SIBConstants.STATUS_FULL_CN_APPROVED_ICON_TOOLTIP;
      } else if (status === "PARTIAL_CN_APPROVED") {
        this.charAt[index] = "P";
        this.charTitleAt[index] =
          SIBConstants.STATUS_PARTIAL_CN_APPROVED_ICON_TOOLTIP;
      } else {
        this.charAt[index] = "C";
        this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
      }
      // this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
    }
  }

  setPendingAt() {
    this.pendingAt = [];
    if (!this.filterApplied) {
      this.invoices.forEach((invoice, index) => {
        this.setBackground(invoice.status, index);
      });
    } else {
      this.campaignInvoicesSibGridRef.filteredValue.forEach(
        (invoice, index) => {
          this.setBackground(invoice.status, index);
        }
      );
    }
    if (this.sortApplied) {
      this.campaignInvoicesSibGridRef
        .getDataTableInstance()
        ["_value"].forEach((invoice, index) => {
          this.setBackground(invoice.status, index);
        });
    }
  }

  setCharAt() {
    this.charAt = [];
    this.charTitleAt = [];
    if (!this.filterApplied) {
      this.invoices.forEach((invoice, index) => {
        this.setChar(invoice.status, index);
      });
    } else {
      this.campaignInvoicesSibGridRef.filteredValue.forEach(
        (invoice, index) => {
          this.setChar(invoice.status, index);
        }
      );
    }
    if (this.sortApplied) {
      this.campaignInvoicesSibGridRef
        .getDataTableInstance()
        ["_value"].forEach((invoice, index) => {
          this.setChar(invoice.status, index);
        });
    }
  }

  setBackground(status, index) {
    if (this.srPendingStatus.includes(status)) {
      // this.pendingAt[index] = "sr";
      this.pendingAt[index] = "SR";
    }
    if (this.adminPendingStatus.includes(status)) {
      // this.pendingAt[index] = "admin";
      this.pendingAt[index] = "ADM";
    }
    if (this.accountantPendingStatus.includes(status)) {
      // this.pendingAt[index] = "accountant";
      this.pendingAt[index] = "ACC";
    }

    // ====================== changes for addition of new visualization node of flow completion =====================================================

    // if (this.flowCompleteWithApprovalStatus.includes(status) || this.flowCompleteWithRejectionStatus.includes(status)) {
    //     this.pendingAt[index] = "CMP";
    // }
    if (this.flowCompleteWithApprovalStatus.includes(status)) {
      this.pendingAt[index] = "APP";
    }
    if (this.flowCompleteWithRejectionStatus.includes(status)) {
      this.pendingAt[index] = "REJ";
    }
  }

  getClassForCharacter(index) {
    switch (this.charAt[index]) {
      case "G": {
        return "generate";
        break;
      }
      case "E": {
        return "edit";
        break;
      }
      case "C": {
        return "cancel";
        break;
      }
      case "P": {
        return "partialCnApproved";
      }
      case "F": {
        return "fullCnApproved";
      }
    }
  }

  customSort(item1, item2) {
    return (
      new Date(item2.billGeneratedDate).valueOf() -
      new Date(item1.billGeneratedDate).valueOf()
    );
  }

  onFilter(event) {
    // this.invoices = event.filteredValue;
    this.filterApplied = true;
    this.setPendingAt();
    this.setCharAt();
  }

  onSort(event) {
    this.sortApplied = true;
    this.setPendingAt();
    this.setCharAt();
  }

  adjustSuperBill() {
    this.eEmitAdjustSuperBill.emit(true);
  }

  /* Getting decimal point in Number datatype
   *  @param  {} source
   */
  getDecimalNum(target, decimal) {
    // return Number(target.toFixed(decimal));
    return Number(Math.fround(target).toFixed(decimal));
  }

  parseValueToDecimal(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getAbsoluteValue(value) {
    return Number(Math.abs(Number(value)));
  }

  updatingUserGridConfig(columnArray) {
    let invoiceListGridConfig: Map<string, string> = new Map();
    invoiceListGridConfig = utils.getGridConfigMap(
      columnArray,
      this.campaignInvoicesColumns
    );
    this.userGridConfigService
      .patch(
        utils.buildObject(invoiceListGridConfig),
        null,
        GridNameEnum["SUPERBILL_INVOICE"]
      )
      .subscribe((result) => {});
  }

  deleteTempInvoice(rowData) {
    this.eEmitIsInvoiceItemDeleted.emit({ isLoading: true, isDeleted: false });
    this.confirmationService.confirm({
      message: "Are you sure that you want to delete the item?",
      accept: () => {
        this.invoiceService.deleteInvoiceItem(rowData.id).subscribe(
          (response) => {
            if (response.status === 200) {
              this.eEmitIsInvoiceItemDeleted.emit({
                isLoading: false,
                isDeleted: true,
              });
            }
          },
          (error) => {
            if (error) {
              this.notificationServcie.error(
                "Error deleting invoice item",
                "Item Deletion"
              );
              this.eEmitIsInvoiceItemDeleted.emit({
                isLoading: false,
                isDeleted: false,
              });
            }
          }
        );
      },
      reject: () => {
        this.eEmitIsInvoiceItemDeleted.emit({
          isLoading: false,
          isDeleted: false,
        });
      },
    });
  }

  // getMigratedInvoices() {
  //     this.migratedInvoiceService.get({ campaignId: decodeURIComponent(this.campaignId) }, '/byCampaignId?').subscribe((response) => {
  //         if (response.data !== undefined) {
  //             if (response.data.length !== 0) {
  //                 this.migratedInvoice = response.data;
  //                 console.log("Invoices....", this.migratedInvoice);
  //             } else if (response.data.length === 0) {
  //                 this.migratedInvoice = [];
  //             }
  //         }
  //     });

  // }
  //==================================================================================================================

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
  }
}
