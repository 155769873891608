<p-dialog
  [width]="650"
  [styleClass]="'user-detail-dialog'"
  [modal]="true"
  [(visible)]="display"
  (onShow)="onShowDialog()"
  (onHide)="onHideDialog()"
  [closable]="false"
  [autoAlign]="false"
>
  <p-header>
    <button
      pButton
      class="mtab-secondary c-edit-btn border-rad-2 margin-right-10 user-info-logout"
      (click)="logout()"
      icon="fa fa-sign-out"
      title="Signout"
    ></button>
  </p-header>

  <div *ngIf="isloading">
    <p-progressSpinner class="loading"></p-progressSpinner>
  </div>

  <p-steps
    [model]="stepperItems"
    [(activeIndex)]="activeIndex"
    styleClass="steps-custom1"
    [readonly]="true"
    [(activeIndex)]="activeIndex"
  ></p-steps>
  <form #userDetails="ngForm">
    <div class="information-form ui-g">
      <div class="information-form-container">
        <div *ngIf="activeIndex === 0" class="slide-right">
          <header class="header">
            <span class="header-primary">
              <h3>Welcome</h3>
            </span>
            <span class="header-secondary">
              {{ user?.firstName }} {{ user?.lastName }}
            </span>
          </header>
          <div class="ui-g-12 ui-fluid inputfield padding-left-0">
            <span class="md-inputfield">
              <input
                id="firstname"
                type="text"
                minlength="0"
                maxlength="100"
                pInputText
                [(ngModel)]="user.firstName"
                name="firstname"
                required
              />
              <label for="firstname"
                >First Name <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield padding-left-0">
            <span class="md-inputfield">
              <input
                id="lastname"
                type="text"
                minlength="0"
                maxlength="100"
                pInputText
                [(ngModel)]="user.lastName"
                name="lastname"
                required
              />
              <label for="lastname"
                >Last Name <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield padding-left-0">
            <span class="md-inputfield">
              <input
                id="number"
                type="text"
                minlength="0"
                maxlength="10"
                pInputText
                [(ngModel)]="user.number"
                name="number"
                required
              />
              <label for="number">Phone <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield padding-left-0">
            <span class="md-inputfield">
              <input
                disabled
                id="email"
                type="text"
                minlength="0"
                maxlength="100"
                pInputText
                [(ngModel)]="user.emailId"
                name="email"
              />
              <label for="email">Email</label>
            </span>
          </div>
        </div>

        <div *ngIf="activeIndex === 2" class="slide-left">
          <header class="header-1">
            <span class="header-primary-1">
              <h3>Configure Email</h3>
            </span>
            <span class="header-secondary-1">
              Please configure your email for client communication.
            </span>
          </header>

          <div class="personal-email-container ui-g-12 ui-fluid inputfield">
            <span>Organization Email</span>
            <p-radioButton
              name="emailgroup"
              [styleClass]="'uf-radiobutton'"
              value="orgEmail"
              label="{{ organizationEmail }}"
              [(ngModel)]="emailSelected"
            ></p-radioButton>
          </div>
        </div>

        <div *ngIf="activeIndex === 1" class="slide-left">
          <header class="header-1">
            <span class="header-primary-1">
              <h3>ShowItMax System Credentials</h3>
            </span>
            <span class="header-secondary-1">
              Please update your ShowItMax System password here.
            </span>
          </header>
          <div
            class="password-info-container ui-g-12 ui-fluid inputfield padding-left-0"
          >
            <span class="username-label">User Name</span>
            <span class="username-value">{{ user.emailId }}</span>

            <div
              class="password-value ui-g-12 ui-fluid inputfield padding-left-0"
            >
              <span> Set Password </span>
              <div class="ui-g-12 ui-fluid inputfield padding-left-0">
                <span class="md-inputfield">
                  <input
                    #sibpassword
                    #sibNewPassword="ngModel"
                    id="passsword"
                    type="password"
                    minlength="0"
                    maxlength="15"
                    pInputText
                    [(ngModel)]="sibPassword"
                    name="password"
                    [pattern]="
                      sibPassword && sibPassword.length
                        ? passwordPattern
                        : emptyRegex
                    "
                    pTooltip="{{
                      sibPassword &&
                      sibPassword.length &&
                      sibNewPassword.hasError('pattern')
                        ? passwordPolicy
                        : ''
                    }}"
                  />
                  <label for="passsword"
                    >Password <span class="required">*</span></label
                  >
                  <i
                    class="passwordVisibility"
                    [ngClass]="
                      password
                        ? password.nativeElement.type === 'text'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                        : 'fa fa-eye-slash'
                    "
                    (click)="togglePasswordVisibility('password')"
                  ></i>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield padding-left-0">
                <span class="md-inputfield">
                  <input
                    #confirmpassword
                    #sibNewConfirmPassword="ngModel"
                    id="confirmpasssword"
                    type="password"
                    minlength="0"
                    maxlength="15"
                    pInputText
                    (keyup)="verifySibPassword()"
                    [(ngModel)]="sibConfirmPassword"
                    name="confirmpassword"
                    [class.ng-invalid]="sibPassword !== sibConfirmPassword"
                    [pattern]="
                      sibConfirmPassword && sibConfirmPassword.length
                        ? passwordPattern
                        : emptyRegex
                    "
                    pTooltip="{{
                      sibConfirmPassword && sibConfirmPassword.length
                        ? sibNewConfirmPassword.hasError('pattern')
                          ? passwordPolicy
                          : sibPassword !== sibConfirmPassword
                          ? 'New Password and Confirm Password does not match.'
                          : ''
                        : ''
                    }}"
                  />
                  <label for="confirmpasssword"
                    >Confirm Password <span class="required">*</span></label
                  >
                  <i
                    class="passwordVisibility"
                    [ngClass]="
                      confirmPassword
                        ? confirmPassword.nativeElement.type === 'text'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                        : 'fa fa-eye-slash'
                    "
                    (click)="togglePasswordVisibility('confirmPassword')"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uf-button-container">
      <button
        *ngIf="activeIndex === 0"
        class="mtab-primary"
        [disabled]="!userDetails.form.valid"
        type="submit"
        label="NEXT"
        (click)="onNextClick()"
        pButton
      ></button>
      <button
        *ngIf="activeIndex === 1"
        class="mtab-primary next-button"
        [disabled]="
          !userDetails.form.valid ||
          !sibPassword ||
          !sibConfirmPassword ||
          (sibPassword &&
            sibPassword.length &&
            sibNewPassword &&
            sibNewPassword.hasError('pattern')) ||
          (sibConfirmPassword &&
            sibConfirmPassword.length &&
            sibNewConfirmPassword &&
            sibNewConfirmPassword.hasError('pattern')) ||
          (sibPassword &&
            sibPassword.length &&
            sibConfirmPassword &&
            sibConfirmPassword.length &&
            sibPassword !== sibConfirmPassword)
        "
        type="submit"
        label="NEXT"
        (click)="onNextClick()"
        pButton
      ></button>
      <button
        *ngIf="activeIndex === 1 || activeIndex === 2"
        class="mtab-primary prev-button margin-right-10"
        type="submit"
        label="PREVIOUS"
        (click)="onPreviousClick()"
        pButton
      ></button>
      <button
        *ngIf="activeIndex === 2"
        class="mtab-primary"
        [disabled]="
          !emailSelected ||
          (emailSelected === 'userEmail' && !isUserEmailVerified)
        "
        type="submit"
        label="SAVE"
        (click)="saveUser(user)"
        pButton
      ></button>
    </div>
  </form>
</p-dialog>
<!-- (!sibPassword && !sibConfirmPassword
            ? false
            : sibPassword === sibConfirmPassword
            ? false
            : true) -->
