import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import { Dropdown, MenuItem, SelectItem } from "primeng/primeng";
import { Subject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { GridPaginationEvent } from "../../../components/sib-forms/grid/grid.config";
import { SupplierVendorService } from "../../../contracts/services/supplier-vendor.service";
import { ActionDispatcher } from "../../../app state/action-dispatcher/action-dispatcher";
import { ContractsActionDispatcher } from "../../../contracts/action-dispatcher/action-dispatcher";
import { ContractUiService } from "../../../contracts/services/contract-ui.services";
import { ErrorUtil } from "../../../helpers/error.utils";
import * as utils from "../../../helpers/utils";
import { HoardingActionDispatcher } from "../../../hoardings/action-dispatcher/action-dispatcher";
import { Area } from "../../../modals/Area/area";
import { Contracts } from "../../../modals/contracts/contracts";
import { FileItem } from "../../../modals/files/file-item";
import { GeoCoordinate } from "../../../modals/hoardings/geo-coordinate";
import { Inventory } from "../../../modals/hoardings/inventory";
import { ImageDataDisplay } from "../../../modals/image/image-data";
import { MapMarker } from "../../../modals/map/MapMarker";
import { AreaQueryParams } from "../../../modals/queryparams/area-queryparams";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { CalculationService } from "../../../services/shared/calculationService";
import { ChangesService } from "../../../services/shared/changes.service";
import { FirebaseService } from "../../../services/shared/firebase.service";
import { HoardingConfigurationService } from "../../../services/shared/hoarding-configuration.service";
import { ImageService } from "../../../services/shared/image.service";
import { InventoryService } from "../../../services/shared/inventory.service";
import { NotificationsMessages } from "../../../services/shared/notifications-messages";
import { SystemService } from "../../../services/shared/system.service";
import { AppUrls } from "../../../services/urls";
import { ActivityLogModuleEnum } from "../../constants/activity-log-module-enum";
import { HoardingLightEnum } from "../../constants/hoarding-light-enum";
import { HoardingStatusEnum } from "../../constants/hoarding-status-enum";
import { ImageCategoryEnum } from "../../constants/image-category-enum";
import { InventoryElevation } from "../../constants/inventory-elevation";
import { InventoryTypeEnum } from "../../constants/inventory-type.enum";
import { MultiMediaTypeEnum } from "../../constants/multi-media-type-enum";
import { CurrencyHelperPipe } from "../../helpers/currency.pipe/currencyHelperPipe";
import { SupplierVendor } from "../../../modals/supplier/supplier-vendor";

@Component({
  selector: "sib-common-inventory",
  templateUrl: "./common-inventory.component.html",
  styleUrls: ["./common-inventory.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonInventoryComponent implements OnInit, OnDestroy {
  @ViewChild("mapComponent") mapComponent;
  @ViewChild("inventoryForm") inventoryForm;
  @ViewChild("mediaTypeDropdown") mediaTypeDropdown: ElementRef;
  @ViewChild("supplierVendorDropdown") supplierVendorDropdown: Dropdown;

  @Input() createInventoryMode: boolean;
  @Input() contract: Contracts = new Contracts();
  @Input() displayCreateInventory: boolean = false;

  @Input() parentInventoryDetails: Inventory;

  _editContractInventoryMode: boolean;
  @Input()
  set editContractInventoryMode(value: boolean) {
    this._editContractInventoryMode = value;
  }

  get editContractInventoryMode(): boolean {
    return this._editContractInventoryMode;
  }

  _totalImages: any;
  @Input()
  set totalImages(value: any) {
    this._totalImages = value;
  }

  get totalImages(): any {
    return this._totalImages;
  }

  @Input() parentInventory: boolean = true; //when inventory creation dialog is for parent inventory then true otherwise false

  _parentItem: Inventory;
  @Input()
  set parentItem(value: Inventory) {
    this._parentItem = value;
    if (this._parentItem) {
      this.inventory = _.cloneDeep(this._parentItem);
      if (this.inventory.supplierVendor && this.inventory.supplierVendor.name) {
        this.isTradedAssets = true;
        this.inventory.tradedAsset = true;
      }
    }
  }

  get parentItem(): Inventory {
    return this._parentItem;
  }

  /**
   * @description when dialog opens in edit mode
   * @type {boolean}
   * @memberof CommonInventoryComponent
   */
  @Input() editMode: boolean = false;

  _inventoryData: Inventory;
  @Input()
  set inventoryData(value: Inventory) {
    this._inventoryData = value;
    value && value.numberOfDuplicateChild
      ? (this.minNoOfDuplicateUnits = Number(value.numberOfDuplicateChild))
      : "";
    this.copyOfNumberOfDuplicateChild =
      value && value.numberOfDuplicateChild
        ? _.cloneDeep(Number(value.numberOfDuplicateChild))
        : _.cloneDeep(0);
    this.regExpForDuplicateUnitsFieldEditMode = new RegExp(
      "^(?!(?:[0-" +
        (this.minNoOfDuplicateUnits > 1 ? this.minNoOfDuplicateUnits - 1 : 1) +
        "])$)\\d+|!(^$)"
    );
    if (value && value.tradedAsset) {
      this.setSelectedSupplier();
    }
  }

  get inventoryData(): Inventory {
    return this._inventoryData;
  }

  _createChild: boolean;
  @Input()
  set createChild(value: boolean) {
    this._createChild = value;
    if (this._createChild) {
      this.inventory = new Inventory();
      this.dFooter = "CREATE";
      this.setInitialValueForChanges();
    }
  }

  get createChild(): boolean {
    return this._createChild;
  }

  _subInventories: Inventory[];
  @Input()
  set subInventories(value: Inventory[]) {
    this._subInventories = value;
    if (this._subInventories !== null || this.subInventories !== undefined) {
      this.inventory.childInventories = this._subInventories;
      this.inventory.childInventories.forEach((item) => {
        if (item.supplierVendor && item.supplierVendor.name) {
          item.tradedAsset = true;
        }
      });
    }
  }

  get subInventories(): Inventory[] {
    return this._subInventories;
  }

  _subInventoryEdit: Inventory;
  @Input()
  set subInventoryEdit(value: Inventory) {
    this._subInventoryEdit = value;
    if (value !== undefined && !this._createChild) {
      this.inventoryData = this._subInventoryEdit;
      if (
        this.inventoryData.supplierVendor &&
        this.inventoryData.supplierVendor.name
      ) {
        this.isTradedAssets = true;
        this.inventoryData.tradedAsset = true;
      }
      this.dFooter = "SAVE";
      this.editSubInventory = true;
    }
  }

  get subInventoryEditMode(): Inventory {
    return this._subInventoryEdit;
  }

  /**
   * @description to get the last used temporary id
   * @type {number}
   * @memberof CommonInventoryComponent
   */
  _previousSubInventoryCreated: number;
  @Input()
  set previousSubInventoryCreated(value: number) {
    this._previousSubInventoryCreated = value;
    if (this._previousSubInventoryCreated) {
      this.temporarySubInventoryId = this.previousSubInventoryCreated;
    }
  }

  get previousSubInventoryCreated(): number {
    return this._previousSubInventoryCreated;
  }

  _finalImages: FileItem[];
  @Input()
  set finalImages(value: FileItem[]) {
    this._finalImages = value;
    this.images = this._finalImages;
    this.duplicateImages = _.cloneDeep(this.images);
  }

  get finalImages(): FileItem[] {
    return this._finalImages;
  }

  @Input() totalChildren: number;
  @Input() previousMediaType: string;
  @Input() previousArea: any;

  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() editReset: EventEmitter<boolean> = new EventEmitter();
  @Output() eEmitAddChildren: EventEmitter<boolean> = new EventEmitter();
  @Output() eEmitChildren: EventEmitter<Inventory[]> = new EventEmitter();
  @Output() eEmitInventoryCreated: EventEmitter<string> = new EventEmitter();
  @Output() eEmitCancelChanges: EventEmitter<boolean> = new EventEmitter();
  @Output()
  eEmitEditedSubInventory: EventEmitter<Inventory> = new EventEmitter();
  @Output()
  eEmitTotalSubInventoriesCreated: EventEmitter<number> = new EventEmitter();
  @Output() eEmitParentItem: EventEmitter<Inventory> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();
  @Output() eEmitNewMediaTypeValue: EventEmitter<string> = new EventEmitter();
  @Output() eEmitNewAreaValue: EventEmitter<string> = new EventEmitter();
  @Output() eEmitResetSubInventories: EventEmitter<any> = new EventEmitter();

  decimalNumbers: RegExp = /^(\d*\.)?\d+$/; // for decimal number fields
  areaFieldColor: boolean = false;
  editSubInventory: boolean = false;
  /**
   * @description labels of active items
   * @type {MenuItem[]}
   * @memberof CommonInventoryComponent
   */
  stepperItems: MenuItem[];

  /**
   * @description active index of steps of stepper
   * @type {number}
   * @memberof CommonInventoryComponent
   */
  activeIndex: number = 0;

  /**
   * @description inventory light types
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  lightTypes: SelectItem[] = [];

  /**
   * @description for single side double side
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  inventoryTypes: any[] = [];

  /**
   * @description inventory elevation options
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  elevationOptions: any[] = [];

  /**
   * @description pump categories
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  pumpCategoryOptions: any[] = [];

  /**
   * @description inventory object
   * @type {(Inventory | any)}
   * @memberof CommonInventoryComponent
   */
  inventory: Inventory | any = new Inventory();

  /**
   * @description gets the filtered area list
   * @type {Area[]}
   * @memberof CommonInventoryComponent
   */
  filteredAreaList: Area[] = [];

  /**
   * @description list of all the areas
   * @type {Area[]}
   * @memberof CommonInventoryComponent
   */
  areaList: Area[] = [];

  /**
   * @description area query params for area api
   * @type {AreaQueryParams}
   * @memberof CommonInventoryComponent
   */
  areaQueryParams: AreaQueryParams = new AreaQueryParams();

  /**
   * @description it is a subject for getting the searched area
   * @private
   * @memberof CommonInventoryComponent
   */
  private searchTerm = new Subject<any>();

  /**
   * @description when areas are searched from autocomplete
   * @type {string}
   * @memberof CommonInventoryComponent
   */
  query: string = "";

  /**
   * @description
   * @type {Subscription[]}
   * @memberof CommonInventoryComponent
   */
  subscription: Subscription[] = [];

  initialImage: boolean = false;

  /**
   * @description image object
   * @type {{ source: any, title: string, width?: string, height?: string, featuredMedia?: boolean, multiMediaType: string, imageType?: any }[]}
   * @memberof CommonInventoryComponent
   */
  imageObject: {
    source: any;
    title: string;
    width?: string;
    height?: string;
    featuredMedia?: boolean;
    multiMediaType: string;
    imageType?: any;
  }[] = [];

  updateHoardingUnsub: Subject<boolean> = new Subject<boolean>();

  files: FileItem[] = [];
  childrenInventoriesImages: FileItem[] = [];
  images: FileItem[] = [];
  filesUrl = [];

  /**
   * @description object for image data
   * @type {ImageDataDisplay}
   * @memberof CommonInventoryComponent
   */
  imageData: ImageDataDisplay = new ImageDataDisplay();

  /**
   * @description shows loader
   * @type {boolean}
   * @memberof CommonInventoryComponent
   */
  loading: boolean = false;

  display: boolean = false;

  /**
   * @description default % discount on gross price
   * this is set in Hoarding Settings
   * @type {number}
   * @memberof CommonInventoryComponent
   */
  minPricePercentage: number;

  /**
   * @description status of an inventory
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  inventoryStatus: any[] = [];

  /**
   * @description min enable date on start date calendar
   * @type {Date}
   * @memberof CommonInventoryComponent
   */
  startDateMin: Date;

  /**
   * @description max enable date on start date calendar
   * @type {Date}
   * @memberof CommonInventoryComponent
   */
  startDateMax: Date;

  /**
   * @description min enable date on end date calendar
   * @type {Date}
   * @memberof CommonInventoryComponent
   */
  endDateMin: Date;

  /**
   * @description max enable date on end date calendar
   * @type {Date}
   * @memberof CommonInventoryComponent
   */
  endDateMax: Date;

  /**
   * @description contract id
   * @memberof CommonInventoryComponent
   */
  CID = "";

  editable = true;

  /**
   * @description inventory dialog header
   * @type {string}
   * @memberof CommonInventoryComponent
   */
  dHeader: string;

  /**
   * @description inventory button text
   * @type {string}
   * @memberof CommonInventoryComponent
   */
  dFooter: string;
  /**
   * @description map dialog header
   * @type {string}
   * @memberof CommonInventoryComponent
   */
  mapDialogHeader: string;

  /**
   * @description to set the images of new inventory
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  image: any[] = [];

  isTradedAssets: boolean = false;

  msg: string;

  minInventoryEndDate: Date;

  oldInventory: boolean = false;

  /**
   * @description media types list
   * @type {any[]}
   * @memberof CommonInventoryComponent
   */
  mediaTypes: any[] = [];

  options: any;

  /**
   * @description total sub inventories
   * @type {number}
   * @memberof CommonInventoryComponent
   */
  totalSubInventories: number = 1;

  valueError: boolean = false;

  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  showMapDialog: boolean = false;

  /**
   * @description for newly created sub inventories
   * @type {number}
   * @memberof CommonInventoryComponent
   */
  temporarySubInventoryId: number = 0;

  childrenLabelText: string = "";

  inventoryUpdated: boolean = false;
  searchEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    sortField: "updatedDate",
    sortOrder: 1,
    filters: {},
    globalFilter: "",
  };

  supplierOptions: SelectItem[] = [];
  isLoading: boolean = false;

  minNoOfDuplicateUnits: number = 2;
  regExpForDigitsExcept1 = /^(?!(?:1)$)\d+/;
  regExpForDuplicateUnitsFieldEditMode = new RegExp(
    "^(?!(?:[0-" +
      (this.minNoOfDuplicateUnits > 1 ? this.minNoOfDuplicateUnits - 1 : 1) +
      "])$)\\d+"
  );
  copyOfNumberOfDuplicateChild: number = 0;
  duplicateImages: FileItem[];
  firebaseUrls = [];
  constructor(
    private appActionDispatcher: ActionDispatcher,
    private actionDispatcher: ContractsActionDispatcher,
    private hoardingActionDispatcher: HoardingActionDispatcher,
    private firebaseService: FirebaseService,
    private cd: ChangeDetectorRef,
    private changesService: ChangesService,
    private notificationService: NotificatoinsService,
    private imageService: ImageService,
    private hoardingConfigurationService: HoardingConfigurationService,
    private calculationService: CalculationService,
    private systemService: SystemService,
    private contractUiService: ContractUiService,
    private inventoryService: InventoryService,
    private currencyHelperPipe: CurrencyHelperPipe,
    private supplierVendorService: SupplierVendorService
  ) {}

  ngOnInit() {
    this.inventory.numberOfDuplicateChild = this.inventory
      .numberOfDuplicateChild
      ? this.inventory.numberOfDuplicateChild
      : 0;
    this.getMediaTypes();
    if (!this.parentInventory) {
      this.setAddress();
      this.setDates();
    } else {
      if (this.inventory && this.inventory.area) {
        this.previousArea = this.inventory.area;
      }
    }
    this.stepperItems = [
      { label: "Select & Create Inventory" },
      { label: "Add Children" },
    ];

    // gets the areas
    this.subscription.push(
      this.searchTerm.pipe(distinctUntilChanged()).subscribe((searchObject) => {
        this.appActionDispatcher.getAreaByCriteria(this.areaQueryParams);
      })
    );

    //gets filtered area list on autocomplete search
    this.subscription.push(
      this.appActionDispatcher.areaList.subscribe((areaList) => {
        if (areaList !== undefined && areaList.length !== 0) {
          this.areaList = areaList;
          this.filteredAreaList = this.areaList;
        }
      })
    );

    this.subscription.push(
      this.appActionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.appActionDispatcher.resetError();
        }
      })
    );

    this.firebaseService.resetFileItems();

    //firebase images subscription
    this.subscription.push(
      this.firebaseService.filteItems$.subscribe((fileItems) => {
        if (fileItems && fileItems.length > 0) {
          this.setUrlToInventoryImageObject(fileItems);
          this.firebaseService.resetFileItems();
          //not sure why we are using this.
          this.initialImage = true;
          if (this.editMode || this.editContractInventoryMode) {
            // this.hoardingActionDispatcher.updateHoarding(this.inventory);
            this.inventoryUpdated = true;
          }
          if (
            this.inventory.customId &&
            this.inventory.images &&
            this.inventory.images.length
          ) {
            this.updateSite(this.inventory, fileItems);
          }
        }
      })
    );

    //on inventory update
    this.subscription.push(
      this.actionDispatcher.isHoardingUpdated
        .takeUntil(this.updateHoardingUnsub)
        .subscribe((isInventoryUpdated) => {
          if (isInventoryUpdated) {
            this.updateHoardingUnsub.next(true);
            this.loading = false;
            this.setActivityLog();
            this.actionDispatcher.resetIsHoardingUpdated();
            this.initialImage = false;
            this.displayCreateInventory = false;
            this.files = [];
            this.displayReset.emit(false);
          }
        })
    );

    // when image is updated
    this.subscription.push(
      this.actionDispatcher.hoardingWithImageUpdated.subscribe((isUpdated) => {
        if (isUpdated) {
          this.imageService.setFirebaseImageFolder(
            this.files,
            ImageCategoryEnum[this.inventory.mediaType],
            this.inventory.customId
          );
          this.imageService.setDatabaseImageFolder(
            this.inventory.images,
            ImageCategoryEnum[this.inventory.mediaType],
            this.inventory.customId
          );
          this.imageService.setDatatbaseImageName(
            this.inventory.images,
            this.imageData.getImageNameData(this.inventory)
          );
          this.imageService.setFirebaseImageNames(
            this.files,
            this.inventory.images
          );
          this.firebaseService.uploadImagesToFirebase(this.files);
          this.setActivityLog();
          this.actionDispatcher.resetIsHoardingUpdated();
          this.actionDispatcher.resetUpdateHoardingWithImage();
        }
      })
    );

    this.subscription.push(
      this.actionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.loading = false;
          this.actionDispatcher.resetError();
        }
      })
    );

    this.subscription.push(
      this.actionDispatcher.errorCreatingContractHoarding.subscribe((error) => {
        if (error) {
          this.loading = false;
          this.actionDispatcher.resetError();
        }
      })
    );

    this.subscription.push(
      this.hoardingActionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.loading = false;
          this.display = false;
          this.inventory = new Inventory();
          this.hoardingActionDispatcher.resetError();
        }
      })
    );

    // when inventory is created
    this.subscription.push(
      this.hoardingActionDispatcher.isHoardingCreated.subscribe(
        (isInventoryCreated) => {
          if (isInventoryCreated) {
            this.loading = false;
            this.displayCreateInventory = false;
            this.setActivityLog();
            this.hoardingActionDispatcher.resetHoarding();
          }
        }
      )
    );

    this.subscription.push(
      this.hoardingActionDispatcher.hoardingWithImageUpdatedHM.subscribe(
        (isUpdated) => {
          if (isUpdated) {
            this.imageService.setFirebaseImageFolder(
              this.files,
              ImageCategoryEnum[this.inventory.mediaType],
              this.inventory.customId
            );
            this.imageService.setDatabaseImageFolder(
              this.inventory.images,
              ImageCategoryEnum[this.inventory.mediaType],
              this.inventory.customId
            );
            this.imageService.setDatatbaseImageName(
              this.inventory.images,
              this.imageData.getImageNameData(this.inventory)
            );
            this.imageService.setFirebaseImageNames(
              this.files,
              this.inventory.images
            );
            this.firebaseService.uploadImagesToFirebase(this.files);
            this.setActivityLog();
            this.hoardingActionDispatcher.resetUpdateHoardingWithImageHM();
          }
        }
      )
    );

    this.getMinPriceConfiguration();
    this.inventoryStatus = utils.createDropdown(
      LocalInventoryStatusEnum,
      false
    );
    this.inventoryTypes = utils.createDropdown(InventoryTypeEnum, false);
    this.lightTypes = utils.createDropdown(HoardingLightEnum, false);

    if (
      this.parentInventory &&
      (this.editMode || this.editContractInventoryMode) &&
      this.inventoryData
    ) {
      if (
        HoardingLightEnum[this.inventoryData.lightType] !==
        HoardingLightEnum["VARIOUS"]
      ) {
        this.lightTypes = this.lightTypes.filter(
          (item) => item.label !== "Various"
        );
      } else {
        // do nothing
      }
    } else if (
      !this.parentInventory ||
      (!this.editContractInventoryMode && !this.editMode)
    ) {
      this.lightTypes = this.lightTypes.filter(
        (item) => item.label !== "Various"
      );
    }
    if (!this.parentInventory)
      this.elevationOptions = utils.createDropdown(InventoryElevation, false);
    this.pumpCategoryOptions = utils.createDropdown(
      PumpCategoryEnumLocal,
      false
    );

    if (!this.inventory.startDate || !this.inventory.endDate) {
      this.setInventoryMinMaxDates();
    }
    this.showInventoryDialog();
    this.setSupplierDropdown();
    this.setDefaultInventoryType();
  }

  /** sets parent area
   * @description
   * @author Raveena Nathani
   * @date 2020-01-21
   * @memberof CommonInventoryComponent
   */
  setAddress() {
    this.inventory.area = this.parentInventoryDetails.area;
    this.inventory.district = this.parentInventoryDetails.district;
    this.inventory.city = this.parentInventoryDetails.city;
    this.inventory.state = this.parentInventoryDetails.state;
  }

  setDates() {
    this.inventory.startDate = new Date(this.parentInventoryDetails.startDate);
    this.inventory.endDate = new Date(this.parentInventoryDetails.endDate);
  }

  /**
   * @description sets default inventory type as single side
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  setDefaultInventoryType() {
    this.inventory.inventoryType = "SINGLE_SIDE";
  }

  /**
   * @description get the list of media types and create dropdown of that
   * @author Raveena Nathani
   * @date 2020-01-01
   * @memberof CommonInventoryComponent
   */
  getMediaTypes() {
    this.systemService.get(null, AppUrls.MEDIA_TYPES).subscribe((response) => {
      this.mediaTypes = utils.createDropdownFromObjectArray(
        response["data"],
        "mediaTypeValue",
        false
      );
    });
  }

  /**
   * @description gets minimum price
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  getMinPriceConfiguration() {
    this.hoardingConfigurationService.getHoardingConfiguration();

    this.subscription.push(
      this.hoardingConfigurationService.minPricePercentage.subscribe(
        (minPricePercentage) => {
          this.minPricePercentage = minPricePercentage; //default % discount on gross price
        }
      )
    );
  }

  /**
   * @description filters areas on autocomplete search
   * @author Raveena Nathani
   * @date 2019-12-27
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  filterAreas(event) {
    const query = event.query;
    if (query !== "") {
      this.query = query;
      this.areaQueryParams.name = query;
      this.searchTerm.next({ searchTerm: query, event: event });
    } else {
      this.query = query;
      this.inventory.city = "";
      this.inventory.district = "";
      this.inventory.state = "";
      this.resetLatLng();
      this.mapComponent ? this.mapComponent.resetMarker() : "";
    }
  }

  /**
   * @description on area selection
   * @author Raveena Nathani
   * @date 2019-12-31
   * @memberof CommonInventoryComponent
   */
  selectArea() {
    this.inventory.city = this.inventory.area.city.name;
    this.inventory.district = this.inventory.area.city.district.name;
    this.inventory.state = this.inventory.area.city.district.state.name;

    this.actionDispatcher.resetAreasList();

    this.resetLatLng();
    this.options = {
      center: { lat: 22.258652, lng: 71.1923805 },
      zoom: 15,
    };
    if (!this.previousArea) {
      //when no last area value available
      this.previousArea = this.inventory.area;
    }

    if (this.previousArea && this.previousArea.id !== this.inventory.area.id) {
      this.eEmitNewAreaValue.emit(this.inventory.area);
    }
  }

  /**
   * @description resets the lat long
   * @author Raveena Nathani
   * @date 2019-12-27
   * @memberof CommonInventoryComponent
   */
  resetLatLng() {
    this.inventory.geoCoordinate.latitude = null;
    this.inventory.geoCoordinate.longitude = null;
  }

  /**
   * @description when create/edit inventory dialog is opened
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  showInventoryDialog() {
    this.actionDispatcher.resetAreasList();
    this.filteredAreaList = [];
    if (this.contract && this.contract.id) {
      this.CID = this.contract.id;
      this.inventory.contract = this.contract;
    }
    if (
      this.editMode ||
      this.editContractInventoryMode ||
      this.editSubInventory
    ) {
      this.childrenLabelText =
        this.inventoryData.numberOfDuplicateChild > 0
          ? "Edit Units"
          : "Add/Edit Units";
      this.dFooter = "SAVE";
      this.setInitialValueForChanges();
      setTimeout(() => {
        if (this.inventoryData) {
          this.editInventoryForm(this.inventoryData);
        }
      }, 500);
    } else {
      this.childrenLabelText = "Add Units";
      this.image = [];
      this.imageObject = [];
      this.CID = this.contract.id;
      this.displayInventoryForm();
    }
  }

  /**
   * @description sets the url to inventory object
   * @author Raveena Nathani
   * @date 2019-12-30
   * @param {*} fileItems
   * @memberof CommonInventoryComponent
   */
  setUrlToInventoryImageObject(fileItems) {
    if (fileItems) {
      if (this.editContractInventoryMode || this.editMode) {
        if (fileItems.length === this.inventory.images.length) {
          this.inventory.images.forEach((image, index) => {
            this.inventory.images[index].url = fileItems[index].url;
          });
        } else {
          let j = 0;
          for (let i = this.inventory.images.length - 1; i >= 0; i--) {
            if (j < fileItems.length) {
              this.inventory.images[i].url = fileItems[j].url;
              j++;
            }
          }
        }
      } else {
        if (Number(this.inventory.numberOfDuplicateChild) > 0) {
          this.inventory.images.forEach((image, index) => {
            fileItems.forEach((fileItem) => {
              if (image.name === fileItem.name) {
                this.inventory.images[index].url = fileItem.url;
              }
            });
          });

          this.inventory.childInventories.forEach((child, ind) => {
            child.images.forEach((image, index) => {
              fileItems.forEach((fileItem) => {
                if (image.name === fileItem.name) {
                  image.url = fileItem.url;
                }
              });
            });
          });
        } else {
          this.inventory.images.forEach((image, index) => {
            fileItems.forEach((fileItem) => {
              if (image.name === fileItem.file.name) {
                this.inventory.images[index].url = fileItem.url;
              }
            });
          });

          this.inventory.childInventories.forEach((child, ind) => {
            child.images.forEach((image, index) => {
              fileItems.forEach((fileItem) => {
                if (image.name === fileItem.file.name) {
                  image.url = fileItem.url;
                }
              });
            });
          });
        }
      }
    }
  }

  /**
   * @description resets inventory data on dialog close
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  resetInventoryData() {
    this.inventory.geoCoordinate = new GeoCoordinate();
    this.inventory = new Inventory();
    this.inventoryData = new Inventory();
    this.image = [];
    this.imageObject = [];
    this.filteredAreaList = [];
    this.areaList = [];
    this.msg = "";
    this.isTradedAssets = false;
    if (this.editMode || this.editContractInventoryMode) {
      this.editMode = false;
      this.editContractInventoryMode = false;
      this.editReset.emit(false);
    }
    this.displayReset.emit(false);
    this.cd.detach();
    this.displayCreateInventory = false;
  }

  /**
   * @description sets inventory min max start and end dates
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  setInventoryMinMaxDates() {
    if (
      !this.editContractInventoryMode &&
      !this.editMode &&
      this.parentInventory
    ) {
      // commented because of user missed inventories
      // if (new Date() > new Date(this.contract.startDate)) {
      //   this.inventory.startDate = new Date();
      // } else {
      this.inventory.startDate = new Date(this.contract.startDate);
      // }

      this.startDateMin = this.inventory.startDate;
      this.startDateMax = new Date(this.contract.endDate);

      this.inventory.endDate = new Date(this.contract.endDate);

      this.endDateMin = this.inventory.startDate;
      this.endDateMax = new Date(this.contract.endDate);
      this.inventory.campaignEndDate = new Date(this.inventory.campaignEndDate);
    } else if (this.editContractInventoryMode || this.editMode) {
      if (this.inventoryData) {
        if (
          HoardingStatusEnum[this.inventoryData.status] ===
          HoardingStatusEnum["NOT_AVAILABLE"]
        ) {
          this.startDateMin = new Date(this.inventory.startDate);
          this.startDateMax = new Date(this.inventory.startDate);
          //Raveena:  user should be able to reduce the end date of the hoarding if it more than the campaign end date it is in
          this.endDateMin = new Date(this.inventoryData.campaignEndDate);
        } else if (
          HoardingStatusEnum[this.inventoryData.status] ===
            HoardingStatusEnum["BLOCKED"] ||
          HoardingStatusEnum[this.inventoryData.status] ===
            HoardingStatusEnum["AVAILABLE"] ||
          HoardingStatusEnum[this.inventoryData.status] ===
            HoardingStatusEnum["INACTIVE"]
        ) {
          this.inventory.startDate = new Date(this.inventoryData.startDate);
          var currentDate = new Date();

          if (
            this.calculationService
              .setToBeginning(new Date(currentDate))
              .valueOf() ===
            this.calculationService
              .setToBeginning(new Date(this.inventoryData.startDate))
              .valueOf()
          ) {
            this.startDateMin = new Date(this.inventoryData.startDate);
            this.startDateMax = new Date(this.contract.endDate);
            this.endDateMin = new Date(this.inventory.startDate);
          } else if (
            this.calculationService
              .setToBeginning(new Date(currentDate))
              .valueOf() <
            this.calculationService
              .setToBeginning(new Date(this.inventoryData.startDate))
              .valueOf()
          ) {
            this.startDateMin =
              new Date(this.contract.startDate) > new Date()
                ? new Date(this.contract.startDate)
                : new Date();
            this.startDateMax = new Date(this.contract.endDate);
            this.endDateMin = new Date(this.inventory.startDate);
          } else if (
            this.calculationService
              .setToBeginning(new Date(currentDate))
              .valueOf() >
            this.calculationService
              .setToBeginning(new Date(this.inventoryData.startDate))
              .valueOf()
          ) {
            this.oldInventory = true;
            this.endDateMin = new Date(currentDate);
          }
        }
        this.minInventoryEndDate = new Date(this.inventoryData.endDate);
      }
      this.endDateMax = new Date(this.contract.endDate);
    }
  }

  /**
   * @description to reset the value of vendor name when checkbox is deselected
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  tradedAssetCheckboxChange() {
    if (!this.isTradedAssets) {
      this.inventory.supplierVendor = new SupplierVendor();
    }
  }

  /**
   * @description updating image folder after it has been saved
   * @author Raveena Nathani
   * @date 2019-12-30
   * @param {*} hoarding
   * @memberof CommonInventoryComponent
   */
  updateSite(inventory, imgs?) {
    this.inventoryService.create(inventory, null, AppUrls.UPDATE).subscribe(
      () => {
        if (
          (this.editMode || this._editContractInventoryMode) &&
          !this.inventoryUpdated
        ) {
          if (!imgs.length) {
            this.eEmitInventoryCreated.emit(inventory.mediaType);
            this.notificationService.success(
              NotificationsMessages.INVENTORY_UPDATED_SUCCESS,
              NotificationsMessages.INVENTORY_UPDATED
            );
          } else {
            // this.loading = true;
            this.loading = false;
            this.eEmitInventoryCreated.emit(inventory.mediaType);
            this.notificationService.success(
              NotificationsMessages.INVENTORY_UPDATED_SUCCESS,
              NotificationsMessages.INVENTORY_UPDATED
            );
          }
        } else if (this.inventoryUpdated) {
          this.inventoryUpdated = false;
          this.loading = false;
          this.eEmitInventoryCreated.emit(inventory.mediaType);
          this.notificationService.success(
            NotificationsMessages.INVENTORY_UPDATED_SUCCESS,
            NotificationsMessages.INVENTORY_UPDATED
          );
        } else {
          this.loading = false;
          this.eEmitInventoryCreated.emit(inventory.mediaType);
          this.notificationService.success(
            NotificationsMessages.INVENTORY_CREATION_SUCCESS,
            NotificationsMessages.INVENTORY_CREATED
          );
        }
        this.subscription.forEach((s) => {
          s.unsubscribe();
        });
        this.updateHoardingUnsub.unsubscribe();
        this.setActivityLog();
      },
      (error) => {
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          this.notificationService.info(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TRY_AGAIN,
            NotificationsMessages.INFORMATION
          );
        } else {
          this.notificationService.error(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
        }
        this.loading = false;
      }
    );

    // this.cd.detectChanges();
  }

  /**
   * @description edit Inventory form
   * @author Raveena Nathani
   * @date 2019-12-30
   * @param {*} _inventoryData
   * @memberof CommonInventoryComponent
   */
  editInventoryForm(inventoryData) {
    this.loading = true;
    if (inventoryData.status === "NOT_AVAILABLE" || inventoryData.null) {
      this.editable = false;
    } else {
      this.editable = true;
    }

    this.mapDialogHeader =
      `Inventory Location (  ${inventoryData.customId} -  ${inventoryData.city}, ${inventoryData.location}` +
      (inventoryData.size ? " - " + ` ${inventoryData.size} ` : "") +
      (inventoryData.statusQueues.length &&
      inventoryData.statusQueues[0].displayName
        ? ` - ${inventoryData.statusQueues[0].displayName} )`
        : ")");
    this.dFooter = "SAVE";
    this.inventory = inventoryData;
    if (this.inventory.width === 0 || this.inventory.height === 0) {
      this.inventory.width = null;
      this.inventory.height = null;
    }
    this.isTradedAssets = this.inventory.tradedAsset;
    this.inventory.startDate = new Date(inventoryData.startDate);
    this.inventory.endDate = new Date(inventoryData.endDate);
    this.CID = inventoryData.contract.id;
    if (this.inventory.rating) {
      this.msg = "You have rated " + this.inventory.rating;
    } else {
      this.msg = "";
    }

    this.setInitialValueForChanges();
    this.loading = false;
  }

  /**
   * @description sets activity logs for inventory
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  setActivityLog() {
    this.changesService
      .create(this.changesService.getActivityLogs(), null, AppUrls.SAVE)
      .subscribe(
        (response) => {
          // console.log("response", response);
          this.changesService.resetData();
        },
        (error) => {
          this.notificationService.error(
            error.error
              ? error.error.message
                ? error.error.message
                : error.message
              : "Some technical issue",
            "Error!!"
          );
        }
      );
  }

  /**
   * @description called when create inventory form is displayed
   * @author Raveena Nathani
   * @date 2019-12-30
   * @memberof CommonInventoryComponent
   */
  displayInventoryForm() {
    this.editMode = false;
    this.dHeader = `Create Inventory`;
    this.dFooter = "CREATE";

    this.setInitialValueForChanges();
  }

  setInitialValueForChanges() {
    // if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.HOARDING) {
    //   this.changesService.setInitialHoarding(_.cloneDeep(this.inventory));
    // } else if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
    //   this.changesService.setInitialKiosk(_.cloneDeep(this.inventory));
    // }else if(MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.PILLAR){
    //   this.changesService.setInitialPillar(_.cloneDeep(this.inventory));
    // }else if(MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.GANTRY){
    //   this.changesService.setInitialGantry(_.cloneDeep(this.inventory));
    // }else if(MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.BQS){
    //   this.changesService.setInitialBQS(_.cloneDeep(this.inventory));
    // }else {
    //   // do nothing
    // }
    this.changesService.setInitialInventory(_.cloneDeep(this.inventory));
  }

  /**
   * @description toggles traded assets
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  toggleIsTradedAssets() {
    this.isTradedAssets = !this.isTradedAssets;
  }

  /**
   * @description setting rating message
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  handleRating(event) {
    // rating + comment
    this.msg = "You have rated " + event.value;
  }

  /**
   * @description setting cancel rating message
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  handleCancelRating(event) {
    this.msg = "Rating Cancelled";
  }

  /**
   * @description when area dropdown is clicked
   * @author Raveena Nathani
   * @date 2019-12-31
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  onDropdownClick(event) {
    if (this.query === "" && event.query === "") {
      this.query = "a";
      this.areaQueryParams.name = "a";
      this.searchTerm.next({ searchTerm: this.query, event: event });
    } else if (event.query !== "") {
      this.areaQueryParams.name = event.query;
      this.searchTerm.next({ searchTerm: event.query, event: event });
    }
  }

  /**
   * @description sets lsp on basis of minimum price percentage
   * @author Raveena Nathani
   * @date 2019-12-31
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  setLsp(event: any) {
    this.inventory.minimumPrice = Math.ceil(
      (this.minPricePercentage / 100) * event.target.value
    );
  }

  /**
   * @description adds/edits sub inventories
   * @author Raveena Nathani
   * @date 2019-12-31
   * @memberof CommonInventoryComponent
   */
  addEditChildren() {
    this.eEmitParentItem.emit(this.inventory);
    this.eEmitAddChildren.emit(true);
  }

  /**
   * @description check whether creating inventory or sub inventory
   * @author Raveena Nathani
   * @date 2020-01-01
   * @returns
   * @memberof CommonInventoryComponent
   */
  checkParent() {
    if (this.parentInventory) {
      this.createInventory();
    } else {
      if (this.editSubInventory) {
        this.eEmitEditedSubInventory.emit(this.inventory);
      } else {
        if (!this.validateDates) return;
        this.inventory.tradedAsset = this.isTradedAssets;
        this.temporarySubInventoryId++;
        this.inventory.temporarySubInventoryId = this.temporarySubInventoryId;
        this.eEmitChildren.emit(this.inventory);
      }
    }
  }

  /**
   * @description create inventory
   * @author Raveena Nathani
   * @date 2020-01-01
   * @returns
   * @memberof CommonInventoryComponent
   */
  createInventory() {
    if (!this.validateDates) return;
    this.checkParentDimensions();
    this.checkSubInventoriesAvailability();
    if (this.editMode || this.editContractInventoryMode) {
      if (!this.inventory.width || !this.inventory.height) {
        delete this.inventory["width"];
        delete this.inventory["height"];
      }
      this.resetChildInventoriesInChild();
      this.updateInventory();
    } else {
      this.loading = true;
      this.inventory.tradedAsset = this.isTradedAssets;

      this.inventory.contract = this.contractUiService.getContractWrapper(
        this.contract
      );
      this.resetChildInventoriesInChild();
      this.setChildrenDates();

      this.inventoryService
        .create(this.inventory, null, AppUrls.SAVE)
        .subscribe(
          (response) => {
            this.cd.detectChanges();
            if (
              response["data"].id !== undefined &&
              response["data"].id !== null
            ) {
              if (
                response["data"].customId !== null &&
                response["data"].customId !== undefined
              ) {
                let imgs = [];
                let childImgs;
                let finalChildrenImages = [];
                this.firebaseUrls = [];

                if (response["data"].images.length > 0) {
                  imgs = response["data"].images;
                }
                // if child inventories images present
                if (response["data"].childInventories.length > 0) {
                  response["data"].childInventories.forEach((item) => {
                    childImgs = [];
                    let dupChildImgs = _.cloneDeep(this.duplicateImages);
                    if (item.images.length > 0 && !item.id) {
                      imgs = imgs.concat(item.images);
                    } else if (item.images.length > 0 && item.id) {
                      childImgs = item.images;
                      let finalUnitImages = [];
                      if (
                        !this.editMode &&
                        !this.editContractInventoryMode &&
                        Number(this.inventory.numberOfDuplicateChild) > 0
                      ) {
                        finalUnitImages = _.cloneDeep([...dupChildImgs]);
                      } else {
                        childImgs = childImgs.filter(
                          (img) =>
                            img.name.includes(item.customId) ||
                            this.images.findIndex(
                              (image) => img.name === image.file.name
                            ) !== -1
                        ); /// for new uploaded images and second conditon is for already present images of same custom id

                        childImgs.forEach((img) => {
                          const index = this.images.findIndex(
                            (image) => img.name === image.file.name
                          );
                          if (index !== -1) {
                            finalUnitImages.push(this.images[index]);
                          }
                        });
                      }
                      finalChildrenImages = finalChildrenImages.concat(
                        finalUnitImages
                      );
                      if (finalChildrenImages.length > 0) {
                        this.imageService.setFirebaseImageFolder(
                          finalUnitImages,
                          ImageCategoryEnum[item.mediaType],
                          item.customId
                        );
                        this.imageService.setDatabaseImageFolder(
                          childImgs,
                          ImageCategoryEnum[item.mediaType],
                          item.customId
                        );
                        this.imageService.setDatatbaseImageName(
                          childImgs,
                          this.imageData.getImageNameData(item)
                        );
                        this.imageService.setFirebaseImageNames(
                          finalUnitImages,
                          childImgs
                        );
                      }
                    }
                  });
                }
                if (this.images.length !== imgs.length) {
                  if (
                    !this.editMode &&
                    !this.editContractInventoryMode &&
                    Number(this.inventory.numberOfDuplicateChild) > 0
                  ) {
                  } else {
                    imgs = imgs.filter(
                      (img) =>
                        img.name.includes(response["data"].customId) ||
                        this.images.findIndex(
                          (image) => img.name === image.file.name
                        ) !== -1
                    ); /// for new uploaded images and second conditon is for already present images of same custom id
                  }
                }

                let finalImages = [];
                if (
                  !this.editMode &&
                  !this.editContractInventoryMode &&
                  Number(this.inventory.numberOfDuplicateChild) > 0
                ) {
                  finalImages = _.cloneDeep([...this.images]);
                } else {
                  imgs.forEach((img) => {
                    const index = this.images.findIndex(
                      (image) => img.name === image.file.name
                    );
                    if (index !== -1) {
                      finalImages.push(this.images[index]);
                    }
                  });
                }

                this.imageService.setFirebaseImageFolder(
                  finalImages,
                  ImageCategoryEnum[response["data"].mediaType],
                  response["data"].customId
                );
                this.imageService.setDatabaseImageFolder(
                  imgs,
                  ImageCategoryEnum[response["data"].mediaType],
                  response["data"].customId
                );
                this.imageService.setDatatbaseImageName(
                  imgs,
                  this.imageData.getImageNameData(response["data"])
                );
                this.imageService.setFirebaseImageNames(finalImages, imgs);
                this.firebaseService.uploadImagesToFirebase(
                  finalImages.concat(finalChildrenImages),
                  Number(this.inventory.numberOfDuplicateChild) > 0 &&
                    !this.editMode &&
                    !this.editContractInventoryMode
                );
                response["data"].startDate = new Date(
                  response["data"].startDate
                );
                response["data"].endDate = new Date(response["data"].endDate);
                this.inventory = _.cloneDeep(response["data"]);

                this.cd.detectChanges();

                if (this.images.length === 0) {
                  this.loading = false;
                  this.displayCreateInventory = false;
                  this.eEmitInventoryCreated.emit(this.inventory.mediaType);
                  this.notificationService.success(
                    NotificationsMessages.INVENTORY_CREATION_SUCCESS,
                    NotificationsMessages.INVENTORY_CREATED
                  );
                }
                this.setActivityLog();
              }
            }
          },
          (error) => {
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              if (
                errorObject.message ===
                "Tenant Setup is incomplete please contact ShowItMax Support!"
              ) {
                this.displayCreateInventory = false;
                this.eEmitInventoryCreated.emit(this.inventory.mediaType);
              } else {
                this.notificationService.info(
                  errorObject.message
                    ? errorObject.message
                    : NotificationsMessages.TRY_AGAIN,
                  NotificationsMessages.INFORMATION
                );
              }
            } else {
              this.notificationService.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
            this.loading = false;
          }
        );
    }
  }

  sortingImages() {}

  /**
   * @description resets child inventories array for all children
   * @author Raveena Nathani
   * @date 2020-01-27
   * @memberof CommonInventoryComponent
   */
  resetChildInventoriesInChild() {
    if (this.inventory.childInventories.length > 0) {
      this.inventory.childInventories.forEach((item) => {
        item.childInventories = [];
      });
    }
  }

  setChildrenDates() {
    if (this.inventory.childInventories.length > 0) {
      this.inventory.childInventories.forEach((item) => {
        if (!item.customId) {
          item.startDate = this.setChildrenStartDateToParentStartDate();
          item.endDate = this.setChildrenEndDateToParentEndDate();
        }
      });
    }
  }

  setChildrenStartDateToParentStartDate() {
    return this.inventory.startDate;
  }

  setChildrenEndDateToParentEndDate() {
    return this.inventory.endDate;
  }

  /**
   * @description to check whether parent has width and height
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  checkParentDimensions() {
    if (
      this.inventory.width &&
      this.inventory.height &&
      !this.inventory.numberOfDuplicateChild
    ) {
      this.inventory.hasDimension = true;
    } else {
      this.inventory.hasDimension = false;
    }
  }

  /**
   * @description checks whether sub inventories present or not
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  checkSubInventoriesAvailability() {
    if (
      this.inventory.childInventories.length > 0 ||
      (!this.editMode &&
        !this.editContractInventoryMode &&
        Number(this.inventory.numberOfDuplicateChild) > 0) ||
      ((this.editMode || this.editContractInventoryMode) &&
        this.minNoOfDuplicateUnits > 0 &&
        this.totalChildren > 0)
    ) {
      this.inventory.hasChild = true;
    } else {
      this.inventory.hasChild = false;
    }
  }

  /**
   * @description start date and end date validations
   * @author Raveena Nathani
   * @date 2020-01-01
   * @returns
   * @memberof CommonInventoryComponent
   */
  validateDates() {
    if (
      this.calculationService
        .setToBeginning(new Date(this.inventory.startDate))
        .valueOf() >
      this.calculationService
        .setToBeginning(new Date(this.inventory.endDate))
        .valueOf()
    ) {
      this.inventory.endDate = new Date(this.inventory.startDate);
      this.endDateMin = new Date(this.inventory.endDate);
      this.endDateMax = new Date(this.contract.endDate);
    }
    var inventoryStartDate = this.inventory.startDate;
    var inventoryEndDate = this.inventory.endDate;

    if (
      inventoryStartDate < this.startDateMin ||
      inventoryStartDate > this.startDateMax ||
      inventoryStartDate > inventoryEndDate
    ) {
      this.inventory.startDate = new Date(this.startDateMin);
      this.inventory.endDate = new Date(this.startDateMax);
      return false;
    }

    if (
      inventoryEndDate < this.endDateMin ||
      inventoryEndDate > this.endDateMax ||
      inventoryEndDate < inventoryStartDate
    ) {
      this.inventory.endDate = this.endDateMax;
      return false;
    }

    return true;
  }

  /**
   * @description calls when inventory needs to be update
   * @author Raveena Nathani
   * @date 2020-01-01
   * @memberof CommonInventoryComponent
   */
  updateInventory() {
    this.loading = true;
    this.inventory.tradedAsset = this.isTradedAssets;
    //if the screen is opened from contract-hoarding Module then this block will be executed
    if (this.editContractInventoryMode) {
      let imgs = [];
      let childImgs;
      let finalChildrenImages = [];

      if (this.inventory.images.length > 0) {
        imgs = this.inventory.images;
      }
      if (this.inventory.childInventories.length > 0) {
        this.inventory.childInventories.forEach((item) => {
          childImgs = [];
          if (item.images.length > 0 && !item.id) {
            imgs = imgs.concat(item.images);
          } else if (item.images.length > 0 && item.id) {
            childImgs = item.images;
            let finalUnitImages = [];
            childImgs = childImgs.filter(
              (img) =>
                img.name.includes(item.customId) ||
                this.images.findIndex(
                  (image) => img.name === image.file.name
                ) !== -1
            ); /// for new uploaded images and second conditon is for already present images of same custom id

            childImgs.forEach((img) => {
              const index = this.images.findIndex(
                (image) => img.name === image.file.name
              );
              if (index !== -1) {
                finalUnitImages.push(this.images[index]);
              }
            });
            finalChildrenImages = finalChildrenImages.concat(finalUnitImages);
            if (finalChildrenImages.length > 0) {
              this.imageService.setFirebaseImageFolder(
                finalUnitImages,
                ImageCategoryEnum[item.mediaType],
                item.customId
              );
              this.imageService.setDatabaseImageFolder(
                childImgs,
                ImageCategoryEnum[item.mediaType],
                item.customId
              );
              this.imageService.setDatatbaseImageName(
                childImgs,
                this.imageData.getImageNameData(item)
              );
              this.imageService.setFirebaseImageNames(
                finalUnitImages,
                childImgs
              );
            }
          }
        });
      }

      if (this.images.length !== imgs.length) {
        imgs = imgs.filter(
          (img) =>
            img.name.includes(this.inventory.customId) ||
            this.images.findIndex((image) => img.name === image.file.name) !==
              -1
        ); /// for new uploaded images and second conditon is for already present images of same custom id
      }

      const finalImages = [];
      imgs.forEach((img) => {
        const index = this.images.findIndex(
          (image) => img.name === image.file.name
        );
        if (index !== -1) {
          finalImages.push(this.images[index]);
        }
      });

      if (finalImages.length > 0) {
        this.imageService.setFirebaseImageFolder(
          finalImages,
          ImageCategoryEnum[this.inventory.mediaType],
          this.inventory.customId
        );
        this.imageService.setDatabaseImageFolder(
          imgs,
          ImageCategoryEnum[this.inventory.mediaType],
          this.inventory.customId
        );
        this.imageService.setDatatbaseImageName(
          imgs,
          this.imageData.getImageNameData(this.inventory)
        );
        this.imageService.setFirebaseImageNames(finalImages, imgs);
      }

      if (finalImages.concat(finalChildrenImages).length > 0) {
        this.firebaseService.uploadImagesToFirebase(
          finalImages.concat(finalChildrenImages)
        );
      }

      if (this.images.length === 0) {
        this.updateSite(this.inventory, imgs);
      }
    } else {
      // this is called when edited from hoarding Master module
      this.updateInventoryFromHoardingModule();
    }
  }

  /**
   * @description called when inventory updation is taking place from contract module
   * @author Raveena Nathani
   * @date 2020-01-01
   * @memberof CommonInventoryComponent
   */
  updateInventoryFromContractModule() {
    if (this.files.length > 0) {
      this.actionDispatcher.updateHoardingWithImage(this.inventory);
    } else {
      this.actionDispatcher.updateHoardingFromContractModule(this.inventory);
    }
  }

  /**
   * @description called when inventory updation is from inventory module
   * @author Raveena Nathani
   * @date 2020-01-01
   * @memberof CommonInventoryComponent
   */
  updateInventoryFromHoardingModule() {
    if (this.files.length > 0) {
      //HM stands for Hoarding Module
      this.hoardingActionDispatcher.updateHoardingWithImageHM(this.inventory);
    } else {
      this.hoardingActionDispatcher.resetHoarding();
      this.hoardingActionDispatcher.updateHoarding(this.inventory);
    }
  }

  /**
   * @description check for no of sub inventories
   * @author Raveena Nathani
   * @date 2020-01-02
   * @memberof CommonInventoryComponent
   */
  checkSubInventoriesNumber() {
    if (!this.totalSubInventories || this.totalSubInventories === 0) {
      this.totalSubInventories = 1;
    }
  }

  /**
   * @description checks for quantity of
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  checkQuantity() {
    if (
      !this.inventory.quantity ||
      this.inventory.quantity === 0 ||
      this.inventory.numberOfDuplicateChild > 0
    ) {
      this.inventory.quantity = 1;
    }
  }

  /**
   * @description removes images
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} index
   * @memberof CommonInventoryComponent
   */
  removeImage(index) {
    this.loading = true;
    this.files.splice(index, 1);
    this.inventory.images.splice(index, 1);
    this.image.splice(index, 1);
    this.loading = false;
    this.updateActivityLog(
      "images",
      "Inventory Photos",
      this.inventory.images,
      "image",
      "Inventory Photo(s) Removed",
      "-",
      null
    );
  }

  /**
   * @description cancel inventory creation changes
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  cancelInventoryCreation() {
    this.resetInventoryData();
    this.eEmitCancelChanges.emit(true);
  }

  /**
   * @description check value of minimum cost and gross price
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  checkValues() {
    if (
      Number(this.inventory.minimumPrice) > Number(this.inventory.grossPrice)
    ) {
      this.inventory.minimumPrice = null;
      this.valueError = true;
    } else {
      this.valueError = false;
    }
  }

  /**
   * @description when image is selected
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  onFileSelection(event) {
    const files = this.imageService.getFirebaseImageObjects(event.files);
    const supportedFiles = files.filter((fileItems) =>
      fileItems.file.type.includes("image")
    );
    if (supportedFiles.length === event.files.length) {
      this.imageService
        .getFirebaseImageObjects(event.files)
        .forEach((object) => {
          this.files.push(object);
          this.eEmitImages.emit(object);
        });
      this.imageService.getDatabaseImageObjects(
        event.files,
        MultiMediaTypeEnum["PICTURE"]
      );
      this.subscription.push(
        this.imageService.databaseImageObjects$.subscribe(
          (databaseImageObjects) => {
            if (databaseImageObjects && databaseImageObjects.length > 0) {
              this.inventory.images = this.inventory.images || [];
              databaseImageObjects.forEach((object) => {
                this.inventory.images.push(object);
                this.filesUrl.push(object.url);
              });
              this.updateActivityLog(
                "images",
                "Inventory Photos",
                this.inventory.images,
                "image",
                "Inventory Photo(s) Uploaded",
                "-",
                null
              );
            }
          }
        )
      );
    } else {
      this.notificationService.info(
        "Uploaded File(s) format does not support.",
        "Not supportable file(s) format"
      );
    }
  }

  /**
   * @description opens map
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  showMap() {
    if (
      this.inventory.geoCoordinate.latitude &&
      this.inventory.geoCoordinate.longitude &&
      this.inventory.geoCoordinate.longitude != 0 &&
      this.inventory.geoCoordinate.latitude != 0
    ) {
      this.options = {
        center: {
          lat:
            typeof this.inventory.geoCoordinate.latitude === "string"
              ? parseFloat(this.inventory.geoCoordinate.latitude)
              : this.inventory.geoCoordinate.latitude,
          lng:
            typeof this.inventory.geoCoordinate.longitude === "string"
              ? parseFloat(this.inventory.geoCoordinate.longitude)
              : this.inventory.geoCoordinate.longitude,
        },
        zoom: 12,
      };

      this.mapMarker.latitude =
        typeof this.inventory.geoCoordinate.latitude === "string"
          ? parseFloat(this.inventory.geoCoordinate.latitude)
          : this.inventory.geoCoordinate.latitude;
      this.mapMarker.longitude =
        typeof this.inventory.geoCoordinate.longitude === "string"
          ? parseFloat(this.inventory.geoCoordinate.longitude)
          : this.inventory.geoCoordinate.longitude;
      this.overlays.push(this.mapMarker);
    } else {
      this.options = {
        center: { lat: 23.02579, lng: 72.58727 },
        zoom: 12,
      };
    }
    this.showMapDialog = true;
  }

  /**
   * @description sets latitude longitude of inventory
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  setLatLng(event) {
    this.inventory.geoCoordinate.latitude = event.latitude;
    this.inventory.geoCoordinate.longitude = event.longitude;
    this.showMapDialog = false;
  }

  /**
   * @description sets end date on start date change
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  onStartDateChange() {
    this.endDateMin = this.inventory.startDate;
    if (this.editContractInventoryMode || this.editMode) {
      this.validateDates();
    }
    if (
      this.inventory.endDate !== null &&
      !this.editContractInventoryMode &&
      !this.editMode
    ) {
      if (this.inventory.endDate < this.inventory.startDate) {
        this.inventory.endDate = null;
      }
    }
  }

  getSeperators(value) {
    return this.currencyHelperPipe.transform(value);
  }

  onParentDetailsSelected(event) {
    if (event) {
      this.inventory.contract.name = this.parentInventoryDetails.contract.name;
      this.inventory.area = this.parentInventoryDetails.area;
      this.inventory.district = this.parentInventoryDetails.district;
      this.inventory.city = this.parentInventoryDetails.city;
      this.inventory.state = this.parentInventoryDetails.state;
      this.inventory.startDate = this.parentInventoryDetails.startDate;
      this.inventory.endDate = this.parentInventoryDetails.endDate;
      this.inventory.width = this.parentInventoryDetails.width;
      this.inventory.height = this.parentInventoryDetails.height;
      this.inventory.geoCoordinate.longitude = this.parentInventoryDetails.geoCoordinate.longitude;
      this.inventory.geoCoordinate.latitude = this.parentInventoryDetails.geoCoordinate.latitude;
      this.inventory.grossPrice = this.parentInventoryDetails.grossPrice;
      this.inventory.minimumPrice = this.parentInventoryDetails.minimumPrice;
      this.inventory.lightType = this.parentInventoryDetails.lightType;
      this.inventory.inventoryType = this.parentInventoryDetails.inventoryType;
      this.inventory.customId = null;
      this.inventory.location = "";
      this.inventory.quantity = 1;
    } else {
      this.inventory.contract.name = this.parentInventoryDetails.contract.name;
      this.inventory.area = this.parentInventoryDetails.area;
      this.inventory.district = this.parentInventoryDetails.district;
      this.inventory.city = this.parentInventoryDetails.city;
      this.inventory.state = this.parentInventoryDetails.state;
      this.inventory.startDate = this.parentInventoryDetails.startDate;
      this.inventory.endDate = this.parentInventoryDetails.endDate;
      this.inventory.width = this.parentInventoryDetails.width
        ? null
        : this.inventory.width;
      this.inventory.height = this.parentInventoryDetails.height
        ? null
        : this.inventory.height;
      this.inventory.geoCoordinate.longitude = this.parentInventoryDetails
        .geoCoordinate.longitude
        ? null
        : this.inventory.geoCoordinate.longitude;
      this.inventory.geoCoordinate.latitude = this.parentInventoryDetails
        .geoCoordinate.latitude
        ? null
        : this.inventory.geoCoordinate.latitude;
      this.inventory.grossPrice = this.parentInventoryDetails.grossPrice
        ? null
        : this.inventory.grossPrice;
      this.inventory.minimumPrice = this.parentInventoryDetails.minimumPrice
        ? null
        : this.inventory.minimumPrice;
      this.inventory.lightType = this.parentInventoryDetails.lightType
        ? null
        : this.inventory.lightType;
      this.inventory.inventoryType = this.parentInventoryDetails.inventoryType
        ? null
        : this.inventory.inventoryType;
    }
  }

  /**
   * @description on map close
   * @author Raveena Nathani
   * @date 2020-01-03
   * @memberof CommonInventoryComponent
   */
  onHideMap() {
    this.mapComponent.resetMarker();
    this.overlays = [];
  }

  /**
   * @description checks status for group and if it is set to inactive then set manualInactive to true
   * @author Raveena Nathani
   * @date 2020-01-29
   * @memberof CommonInventoryComponent
   */
  checkStatusForGroup() {
    if (this.parentInventory) {
      if (this.inventory.status === "INACTIVE") {
        this.inventory.manualInactive = true;
      } else {
        this.inventory.manualInactive = false;
      }
    }
  }

  getChangedType() {
    // if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.HOARDING) {
    //   return ActivityLogModuleEnum.HOARDING;
    // } else if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
    //   return ActivityLogModuleEnum.KIOSK;
    // } else if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.PILLAR) {
    //   return ActivityLogModuleEnum.PILLAR;
    // } else if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.GANTRY) {
    //   return ActivityLogModuleEnum.GANTRY;
    // } else if (MediaSubCategoryEnum[this.inventory.mediaSubCategory] === MediaSubCategoryEnum.BQS) {
    //   return ActivityLogModuleEnum.BQS;
    // } else {
    //   return null;
    // }
    return ActivityLogModuleEnum.INVENTORY;
  }

  updateActivityLog(
    changedField: string,
    changedFieldDisplayName: string,
    changedValue: any,
    formatterType: string,
    changeDescription: string,
    changeAdditionalDescription: string,
    changedFieldArrayVariable: string
  ) {
    this.changesService.updateObjectMap(
      this.getChangedType(),
      changedField,
      changedFieldDisplayName,
      changedValue,
      formatterType,
      changeDescription,
      changeAdditionalDescription,
      changedFieldArrayVariable
    );
  }

  getDate(date) {
    return new Date(date);
  }

  /**
   * @description sets date format
   * @author Raveena Nathani
   * @date 2020-01-03
   * @param {*} date
   * @returns
   * @memberof CommonInventoryComponent
   */
  setDateFormat(date) {
    date = new Date(date);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return date;
  }

  /**
   * @description checks pervious media type
   * @author Raveena Nathani
   * @date 2020-01-23
   * @param {*} event
   * @memberof CommonInventoryComponent
   */
  checkPreviousMediaValue(event) {
    if (this.previousMediaType !== event.value) {
      this.eEmitNewMediaTypeValue.emit(event.value);
    }
  }

  setSupplierDropdown() {
    this.isLoading = true;
    this.supplierVendorService
      .create(this.searchEvent, this.searchEvent, AppUrls.SEARCH)
      .subscribe((response) => {
        if (response["data"]["content"]) {
          this.supplierOptions = utils.createDropdownFromArray(
            response["data"]["content"],
            "name",
            false
          );
          this.setSelectedSupplier();
        }
        this.isLoading = false;
      }),
      (error) => {
        this.isLoading = false;
        this.notificationService.error(
          error.error.message
            ? error.error.message
            : "Unable to fetch supplier list.",
          "Please Refresh."
        );
      };
  }

  setSelectedSupplier() {
    if (this.inventory.supplierVendor && this.inventory.supplierVendor.id) {
      let selectedSupplier = this.supplierOptions.find(
        (sItem) => sItem.value.id === this.inventory.supplierVendor.id
      );
      selectedSupplier && this.supplierVendorDropdown
        ? (this.supplierVendorDropdown.value = selectedSupplier.value)
        : "";
    }
  }

  setNumberOfDuplicateUnits() {
    this.inventory.numberOfDuplicateChild = Number(
      this.inventory.numberOfDuplicateChild
    );
    if (
      (!this.editMode &&
        !this.editContractInventoryMode &&
        Number(this.inventory.numberOfDuplicateChild) > 0) ||
      ((this.editMode || this.editContractInventoryMode) &&
        this.minNoOfDuplicateUnits > 0 &&
        this.totalChildren > 0)
    ) {
      this.inventory.quantity = 1;
      this.inventory.hasChild = true;
    } else {
      this.inventory.hasChild = false;
    }
    this.editMode || this.editContractInventoryMode
      ? ""
      : this.eEmitResetSubInventories.emit(true);
  }

  ngOnDestroy() {
    this.overlays = [];
    this.cd.detach();

    // this.subscription.forEach((s) => {
    //   s.unsubscribe();
    // });
    // this.updateHoardingUnsub.unsubscribe();
  }
}

export enum LocalInventoryStatusEnum {
  AVAILABLE = "Active",
  INACTIVE = "Inactive",
}

export enum PumpCategoryEnumLocal {
  NATIONAL_HIGHWAY = "National Highway", //for active
  URBAN_Z = "Urban - Z", //urban-z
  RURAL = "Rural", //rural
  HIGHWAY = "Highway", // Highway
}
