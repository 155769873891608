import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class PlanConfigurationService {
    lsp: Subject<number> = new Subject();
    blockLimit: Subject<number> = new Subject();
    blockPeriod: Subject<number> = new Subject();
    chunkSize: Subject<number> = new Subject();

    constructor(
        private systemService: SystemService
    ) { }

    getPlanConfiguration() {
        this.systemService.get({ module: "PLAN" }, '/search/by-module')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                this.getInventoryConfiguration();
                this.setBlockLimit(configurations)
                this.setBlockPeriod(configurations)
                this.setChunkSize(configurations)
            });
    }

    getInventoryConfiguration() {
        this.systemService.get({ module: 'INVENTORY' }, '/search/by-module')
            .subscribe((configuration) => {
                this.setLsp(configuration);
            })
    }

    setLsp(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'MIN_PRICE_PERCENTAGE') {
                this.lsp.next(configurations[i]['value']);
            }
        }
    }

    setBlockLimit(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'BLOCK_LIMIT') {
                this.blockLimit.next(configurations[i]['value']);
            }
        }
    }

    setBlockPeriod(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'BLOCK_PERIOD') {
                this.blockPeriod.next(configurations[i]['value']);
            }
        }
    }

    setChunkSize(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'PPT_CHUNKS') {
                this.chunkSize.next(configurations[i]['value']);
            }
        }
    }
}