import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { AngularFireModule } from "angularfire2";
import { FileItem } from "../../modals/files/file-item";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { NotificatoinsService } from "../notifications/notifications.service";
@Injectable()
export class FirebaseService {
  // tslint:disable
  private IMAGES_FOLDER: string = "customers";

  fileItem: Subject<any> = new Subject();
  filteItems$: Subject<any[]> = new Subject();
  fileItems$: Subject<FileItem[]> = new Subject();
  fileItems: any[] = [];
  filteItems: any[] = [];
  fileMetadata$: Subject<any> = new Subject();

  constructor(
    public af: AngularFireModule,
    private notificationServcie: NotificatoinsService
  ) {}

  uploadImagesToFirebase(files: FileItem[], duplicate = false) {
    let storageRef = firebase.storage().ref();

    _.each(files, (item: FileItem) => {
      item.isUploading = true;
      let uploadTask: firebase.storage.UploadTask = storageRef
        .child(
          `${item.imagesFolder}/${duplicate ? item["name"] : item.file.name}`
        )
        .put(item.file);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          item.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          return Observable.throw(error);
        },
        () => {
          // item.url = uploadTask.snapshot.downloadURL;
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            if (url) {
              item.url = url;
              item.isUploading = false;
              if (!this.isUrlExist(this.filteItems, url)) {
                this.filteItems.push(item);
              }
              if (files.length === this.filteItems.length) {
                this.filteItems$.next(this.filteItems);
              }
            }
          });
          /* item.isUploading = false;
                    this.filteItems.push(item);
                    if (files.length === this.filteItems.length) {
                        this.filteItems$.next(this.filteItems)
                    } */
        }
      );
    });

    // return Observable.empty();
  }

  uploadImageToFirebase(item: FileItem) {
    let storageRef = firebase.storage().ref();
    item.isUploading = true;
    let uploadTask: firebase.storage.UploadTask = storageRef
      .child(`${item.imagesFolder}/${item.file.name}`)
      .put(item.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        item.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        return Observable.throw(error);
      },
      () => {
        /* item.url = uploadTask.snapshot.downloadURL;
                item.isUploading = false;
                this.fileItems.push(item)
                this.fileItems$.next(this.fileItems) */
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          if (url) {
            item.url = url;
            item.isUploading = false;
            this.fileItems.push(item);
            this.fileItems$.next(this.fileItems);
          }
        });
      }
    );
  }

  downloadImage(fileName: string, firebaseFolder: string) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${firebaseFolder}/${fileName}`);
    let uri: string = "";
    return storageRef
      .getDownloadURL()
      .then((url) => {
        return (uri = url);
      })
      .catch((error: any) => {
        // console.log("error", error);
        this.notificationServcie.error(
          error.message ? error.message : "File not found",
          "Error!!"
        );
      });
  }

  deleteFile(fileName: string, firebaseFolder: string) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${firebaseFolder}/${fileName}`);
    storageRef.delete();
  }

  removeItemFromFileItem(fileName: string, firebaseFolder: string) {
    this.fileItems = this.fileItems.filter((item) => {
      if (item.file.name !== fileName && item.imagesFolder === firebaseFolder) {
        return item;
      }
    });
    // this.fileItems$ = this.fileItems$.filter((item) => {
    //     if (item.file.fileNmae !== fileName && item.file.imagesFolder === firebaseFolder) {
    //         return item;
    //     }
    // });
  }

  getMetadata(fileName: string, firebaseFolder: string) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${firebaseFolder}/${fileName}`);
    storageRef.getMetadata().then((metadata) => {
      // console.log('metadata is', metadata);
      this.fileMetadata$.next(metadata);
    });
  }

  resetMetadata() {
    this.fileMetadata$.next();
  }

  /**
   * @description reset the file Items
   * @memberof FirebaseService
   */
  resetFileItems() {
    this.filteItems = [];
    this.fileItems = [];
    this.filteItems$.next();
    this.fileItems$.next();
  }

  isUrlExist(fileItems, url) {
    if (fileItems.length === 0) {
      return false;
    }
    fileItems.forEach((element, index) => {
      if (element.url === url) {
        return true;
      } else if (element.url !== url && index === fileItems.length - 1) {
        return false;
      }
    });
  }
}
