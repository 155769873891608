import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/primeng";
import { AuthService } from "../../login-service/auth.service";
import { ActionDispatcher } from "../../app state/action-dispatcher/action-dispatcher";
import { ErrorUtil } from "../../helpers/error.utils";
import * as utils from "../../helpers/utils";
import { AreaQueryParams } from "../../modals/queryparams/area-queryparams";
import { User } from "../../modals/user/user";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { AuthUserAPIService } from "../../services/shared/auth-user.service";
import { CityService } from "../../services/shared/cities.service";
import { DistrictService } from "../../services/shared/district.service";
import { EmailConfigService } from "../../services/shared/email-config.service";
import { EncrDecrService } from "../../services/shared/encr-decr.service";
import { NotificationsMessages } from "../../services/shared/notifications-messages";
import { StateService } from "../../services/shared/states.service";
import { UserService } from "../../services/shared/user.service";
import { BloodGroupEnum } from "../../shared/constants/blood-group-enum";
import { GenderEnum } from "../../shared/constants/gender-enum";

@Component({
  selector: "sib-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, AfterViewInit {
  @ViewChild("sibpassword") password: ElementRef;
  @ViewChild("confirmpassword") confirmPassword: ElementRef;
  user: User = new User();
  imageName: string = "assets/layout/images/customer-image.png";
  genders: SelectItem[] = [];
  bloodGroups: SelectItem[] = [];
  pattern = /^[A-Za-z]+$/;
  // Maximum Characters should be 15. \n
  passwordPolicy = `1. Maximum Characters should be 15. \n2. Should be at least 8 characters long. \n3. Should be alphanumeric. \n4. Should have one capital alphabet and one small alphabet. \n5. Should contain atleast one special character which includes (@,#,$,%,^,&,*).`;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  //   ^	The password string will start this way
  // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
  // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
  // (?=.*[0-9])	The string must contain at least 1 numeric character
  // (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  // (?=.{8,})	The string must be eight characters or longer
  /**
   * storing state List
   *
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  stateList: any[] = [];
  filteredStateList: any[] = [];

  /**
   * storing district list and Filtered district List of autocomplete
   *
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  districtList: any[] = [];
  filteredDistrictList: any[] = [];

  /**
   * stroing cities list and filtered City list
   *
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  citiesList: any[] = [];
  filteredCitiesList: any[] = [];

  areaQueryParams: AreaQueryParams = new AreaQueryParams();
  sibPassword: string;
  sibConfirmPassword: string;
  sibPasswordVerified: boolean = false;

  emailSelected: any;
  isUserEmailVerified: boolean = false;
  organizationEmail: string;
  isloading: boolean = false;

  firstName: string;
  lastName: string;

  showMessage = false;

  constructor(
    private userService: UserService,
    private appActionDispatcher: ActionDispatcher,
    private stateService: StateService,
    private districtService: DistrictService,
    private cityService: CityService,
    private notificationServcie: NotificatoinsService,
    private authUserApiService: AuthUserAPIService,
    private emailConfigService: EmailConfigService,
    private encrDecrService: EncrDecrService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));

    this.userService
      .get(null, "/search/byEmailId?emailId=" + userDetails[0].email)
      .subscribe((user) => {
        if (user) {
          if (user.dateOfBirth) {
            user.dateOfBirth = new Date(user.dateOfBirth);
          }
          this.user = user;
          this.firstName = this.user.firstName;
          this.lastName = this.user.lastName;

          if (this.user.organizationEmail) {
            this.emailSelected = "orgEmail";
          } else {
            this.emailSelected = "userEmail";
          }
        }
      });

    this.emailConfigService
      .get(null, "/search/emailConfig")
      .subscribe((emailConfig) => {
        this.organizationEmail = emailConfig["data"]["username"];
      });

    this.fetchStates();

    Object.keys(BloodGroupEnum).forEach((key) => {
      this.bloodGroups.push({ label: BloodGroupEnum[key], value: key });
    });

    Object.keys(GenderEnum).forEach((key) => {
      this.genders.push({ label: GenderEnum[key], value: key });
    });
  }

  ngAfterViewInit() {}

  fetchStates() {
    this.areaQueryParams.countryCode = "IN";
    this.stateService.get(this.areaQueryParams).subscribe((states) => {
      this.stateList = states["_embedded"]["states"];
      this.filteredStateList = this.stateList;
    });
  }

  filterStateList(event) {
    this.filteredStateList = [];
    this.filteredStateList = utils.filterData(this.stateList, "name", event);
  }

  onStateSelect() {
    this.areaQueryParams = new AreaQueryParams();
    if (this.user.state) {
      this.areaQueryParams.stateId = this.user.state.id;
      this.fetchDistricts();
    }
  }

  onSelectDistrict() {
    this.areaQueryParams = new AreaQueryParams();
    if (this.user.district) {
      this.areaQueryParams.districtId = this.user.district.id;
      this.fetchCities();
    }
  }

  fetchDistricts() {
    this.districtService.get(this.areaQueryParams).subscribe((districts) => {
      this.districtList = districts["_embedded"]["districts"];
      this.filteredDistrictList = this.districtList;
    });
  }

  fetchCities() {
    this.cityService.get(this.areaQueryParams).subscribe((cities) => {
      this.citiesList = cities["_embedded"]["cities"];
      this.filteredCitiesList = this.citiesList;
    });
  }

  filterDistrictList(event) {
    this.filteredDistrictList = [];
    this.filteredDistrictList = utils.filterData(
      this.districtList,
      "name",
      event
    );
  }

  filterCityList(event) {
    this.filteredCitiesList = [];
    this.filteredCitiesList = utils.filterData(this.citiesList, "name", event);
  }

  verifySibPassword() {
    if (this.sibPassword === this.sibConfirmPassword) {
      this.sibPasswordVerified = true;
    } else {
      this.sibPasswordVerified = false;
    }
  }

  verifyUserPassword(value) {
    if (value === this.user.emailConfig.password) {
      this.isUserEmailVerified = true;
    } else {
      this.isUserEmailVerified = false;
    }
  }

  updateUser() {
    this.isloading = true;
    if (this.emailSelected === "orgEmail") {
      this.user.organizationEmail = true;
    } else {
      this.user.organizationEmail = false;
      this.user.emailConfig.username = this.user.emailId;
    }

    this.user.authPassword = this.sibPassword
      ? this.encrDecrService.set(this.sibPassword)
      : this.user.authPassword;

    this.userService.create(this.user, null, "/update").subscribe(
      (user) => {
        this.notificationServcie.success(
          "User saved Successfully",
          "User Save"
        );
        this.isloading = false;
        if (
          this.sibPassword &&
          this.sibPassword.length &&
          this.sibConfirmPassword &&
          this.sibConfirmPassword.length &&
          this.sibPassword === this.sibConfirmPassword
        ) {
          this.auth.logout();
        }
      },
      (error) => {
        this.isloading = false;
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          this.notificationServcie.info(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TRY_AGAIN,
            NotificationsMessages.INFORMATION
          );
        } else {
          this.notificationServcie.error(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
        }
      }
    );
  }

  onFileSelection(event) {}

  checkValidation(name) {
    if (!this.pattern.test(name)) {
      return true;
    }
    return false;
  }

  togglePasswordVisibility(valueType) {
    if (valueType === "confirmPassword") {
      if (this.confirmPassword.nativeElement.type === "password") {
        this.confirmPassword.nativeElement.type = "text";
      } else {
        this.confirmPassword.nativeElement.type = "password";
      }
    } else {
      if (this.password.nativeElement.type === "password") {
        this.password.nativeElement.type = "text";
      } else {
        this.password.nativeElement.type = "password";
      }
    }
  }
}
