import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { InventoryExpenseService } from "../../services/shared/inventory-expenses.service";
import {
  GridColumn,
  GridConfig,
  EditableGridFieldTypes,
  GridPaginationEvent,
  SelectionMode,
} from "../../components/sib-forms/grid/grid.config";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { AppUrls } from "../../services/urls";
import { map } from "rxjs/operators";
import { InventoryExpenses } from "../../modals/hoardings/inventory-expenses";
import { ExpenseTypeEnum } from "../../shared/constants/expense-type.enum";
import { ExpenseEnum } from "../../shared/constants/expense-enum";
import { DateUtil } from "../../helpers/date.util";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import { PPTLoader } from "../../modals/loader-subjects/pptloader";
import { LoaderSubjects } from "../../modals/loader-subjects/loader-subjects";
import { MediaTypeEnum } from "../../shared/constants/media-type-enum";

@Component({
  selector: "sib-expenses-breakup",
  templateUrl: "./expenses-breakup.component.html",
  styleUrls: ["./expenses-breakup.component.scss"],
})
export class ExpensesBreakupComponent implements OnInit {
  gridColumnConfig: Array<GridColumn>;
  lightTypeDropdown: any[] = [];
  inventoryTypes: any[] = [];
  mediaTypeDropdown: any[] = [];
  expensesTypeDropdown: any[] = [];
  gridConfig: GridConfig<any> = new GridConfig<any>();
  totalInventoryRecords: number;

  isFileDownloading: boolean = false;
  searchEvent: GridPaginationEvent;
  xlsLoader: PPTLoader = new PPTLoader();
  resetXlsLoader: PPTLoader = new PPTLoader();

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<any>;

  @Input() rowData: any;
  @Input() startDate: Date;
  @Input() endDate: Date;

  _selectedExpenses: any;
  @Input() set selectedExpenses(selectedExpenses) {
    this._selectedExpenses = selectedExpenses;
    this.refreshGrid();
  }

  get selectedExpenses(): any {
    return this._selectedExpenses;
  }
  _exportLevel2Xlsx: boolean;
  @Input() set exportLevel2Xlsx(exportLevel2Xlsx) {
    this._exportLevel2Xlsx = exportLevel2Xlsx;
    if (this._exportLevel2Xlsx) {
      this.exportXlsLevel2();
    }
  }

  get exportLevel2Xlsx(): any {
    return this._exportLevel2Xlsx;
  }

  @Output() eEmitTotalAmount: EventEmitter<number> = new EventEmitter();
  @Output() eEmitXlsxDownloaded: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private inventoryExpensesService: InventoryExpenseService,
    private loaderSubjects: LoaderSubjects
  ) {}

  ngOnInit() {
    this.gridColumnConfig = this.getGridColumnConfig();
    this.mediaTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(MediaTypeEnum).forEach((type) => {
      this.mediaTypeDropdown.push({ label: MediaTypeEnum[type], value: type });
    });

    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({
        label: HoardingLightEnum[light],
        value: light,
      });
    });

    this.expensesTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(ExpenseTypeEnum).forEach((expense) => {
      this.expensesTypeDropdown.push({
        label: ExpenseTypeEnum[expense],
        value: ExpenseEnum[expense],
      });
    });

    this.setGridConfigObject();
  }

  setGridConfig() {
    this.gridConfig.getDefaultModel = () => new InventoryExpenses();
    this.gridConfig.model = InventoryExpenses;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    // this.gridConfig.expandableRows = true;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.rowExpandMode = "single";
    // this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
  }

  setGridConfigObject() {
    this.setGridConfig();
    this.gridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      this.setPaginationData(paginationEvent);
      this.getTotalExpenseAmount(paginationEvent);
      this.searchEvent = paginationEvent;
      return this.inventoryExpensesService
        .create(paginationEvent, paginationEvent, AppUrls.GET_EXPENSE_DETAILS)
        .pipe(
          map((response) => {
            this.totalInventoryRecords = response["data"].totalElements;
            return response["data"]["content"];
          })
        );
    };
  }

  getGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: "srNo",
        name: "srNo",
        header: "Sr ",
        required: true,
        width: "35px",
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        sortable: false,
        styleClass: "sr-number",
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "customId",
        name: "customId",
        header: "HID",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: "8vw",
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "contractId",
        name: "contractId",
        header: "CID",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: "5.5vw",
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.contractId) {
            return data.contractId;
          } else {
            return "-";
          }
        },
      },
      {
        field: "district",
        name: "district",
        header: "District",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: "7vw",
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "city",
        name: "city",
        header: "City",
        required: true,
        editable: false,
        hidden: false,
        width: "8vw",
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "areaName",
        name: "area",
        header: "Area",
        required: true,
        width: "9vw",
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "location",
        name: "location",
        header: "Location",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: "16vw",
        default: true,
        sortable: true,
        styleClass: "text-right",
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "size",
        name: "size",
        header: "Size",
        width: "4vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "mediaType",
        name: "mediaType",
        header: "Media",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.mediaType) {
            return MediaTypeEnum[data.mediaType];
          } else {
            return "-";
          }
        },
      },
      {
        field: "lightType",
        name: "lightType",
        header: "Light",
        width: "4vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return "-";
          }
        },
      },
      {
        field: "allExpenses.expenseType",
        name: "allExpenses.expenseType",
        header: "Expense Type",
        width: "7vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.allExpenses.expenseType) {
            let expense = ExpenseTypeEnum[data.allExpenses.expenseType];
            expense = expense.substring(
              0,
              (expense.indexOf("Expense") === -1
                ? expense.indexOf("Tax")
                : expense.indexOf("Expense")) - 1
            );
            return expense;
          } else {
            return "-";
          }
        },
      },
      {
        field: "allExpenses.amount",
        name: "allExpenses.amount",
        header: "Amount",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.allExpenses.amount) {
            return data.allExpenses.amount;
          } else {
            return "-";
          }
        },
      },
      {
        field: "allExpenses.expenseDate",
        name: "allExpenses.expenseDate",
        header: "Expense Date",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.allExpenses.expenseDate) {
            return DateUtil.dategridFormatter(data.allExpenses.expenseDate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "allExpenses.rentStartDate",
        name: "allExpenses.rentStartDate",
        header: "Rent St. Date",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.allExpenses.rentStartDate) {
            return DateUtil.dategridFormatter(data.allExpenses.rentStartDate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "allExpenses.rentEndDate",
        name: "allExpenses.rentEndDate",
        header: "Rent End Date",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.allExpenses.rentEndDate) {
            return DateUtil.dategridFormatter(data.allExpenses.rentEndDate);
          } else {
            return "-";
          }
        },
      },
    ];
    // this.filterColumns();
    return columns;
  }

  setPaginationData(paginationEvent) {
    if (paginationEvent.sortField === "updatedDate") {
      paginationEvent.sortField = "allExpenses.amount";
      paginationEvent.sortOrder = -1;
    }
    let selectedExpenses = [];
    if (this.selectedExpenses.length) {
      this.selectedExpenses.forEach((item) => {
        selectedExpenses.push(ExpenseEnum[item]);
      });
    } else {
      Object.keys(ExpenseEnum).forEach((item) => {
        selectedExpenses.push(ExpenseEnum[item]);
      });
    }

    Object.assign(paginationEvent.filters, {
      startDate: { value: this.startDate, matchMode: "gt" },
    });
    Object.assign(paginationEvent.filters, {
      endDate: { value: this.endDate, matchMode: "lt" },
    });
    Object.assign(paginationEvent.filters, {
      customId: { value: this.rowData.customId, matchMode: "is" },
    });
    Object.assign(paginationEvent.filters, {
      expenseType: { value: selectedExpenses, matchMode: "is" },
    });
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

  getTotalExpenseAmount(paginationEvent) {
    this.inventoryExpensesService
      .create(paginationEvent, paginationEvent, AppUrls.GET_TOTAL_EXPENSE)
      .subscribe((response) => {
        this.eEmitTotalAmount.emit(response.data);
      });
  }

  exportXlsLevel2() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader);
    this.loaderSubjects.expenseLevel2Loader.next(this.xlsLoader);
    this.isFileDownloading = false;
    this.eEmitXlsxDownloaded.emit(false);
  }

  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = "Downloading XLS...";
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
  }
}
