<p-dialog header="Rearrange Rows" [(visible)]="displayRowsRearrangeDialog" modal="modal" showEffect="fade"
    [styleClass]="'fullscreen-dialog'" (onHide)="cancelRowsConfig()" blockScroll="true">
    <section class="rowsRearrange">
        <section class="row-rearrange-container">
            <section *ngIf="rentRows.length > 0">
                <h4>Rent Items</h4>
                <section class="table-header" [ngClass]="{'table-header-rent':columns.length !== 8}">

                    <section class="header-columns" *ngFor="let col of columns" [style.width]=col.width>
                        {{col?.header}}
                    </section>
                </section>
                <section class="order-list">
                    <div class="items-type-section">
                        <ng-container *ngTemplateOutlet="itemTypeTemplate ; context: { type: 'Rent' }">
                        </ng-container>
                    </div>
                </section>
            </section>
            <section *ngIf="printingRows.length > 0">
                <h4>Printing Items</h4>
                <section class="table-header table-header-printing-mounting"
                    [ngClass]="{'table-header-print-mount' : columns.length !== 8}">
                    <section class="header-columns" *ngFor="let col of columns" [style.width]=col.width>
                        {{col?.header}}
                    </section>
                </section>
                <section class="order-list">
                    <div class="items-type-section">
                        <ng-container *ngTemplateOutlet="itemTypeTemplate ; context: { type: 'Printing' }">
                        </ng-container>
                    </div>
                </section>
            </section>
            <section *ngIf="mountingRows.length > 0">
                <h4>Mounting Items</h4>
                <section class="table-header table-header-printing-mounting"
                    [ngClass]="{'table-header-print-mount' : columns.length !== 8}">
                    <section class="header-columns" *ngFor="let col of columns" [style.width]=col.width>
                        {{col?.header}}
                    </section>
                </section>
                <section class="order-list">

                    <div class="items-type-section">
                        <ng-container *ngTemplateOutlet="itemTypeTemplate ; context: { type: 'Mounting' }">
                        </ng-container>
                    </div>
                </section>
            </section>
            <section class="action-buttons">
                <button class="mtab-save area-d-save margin-hori-5" pButton (click)="saveRowsConfig()"
                    label=" SAVE "></button>
                <button class="mtab-secondary" pButton (click)="cancelRowsConfig()" label="CANCEL"></button>
            </section>

            <ng-template #orderListTemplate let-key='key' let-value='value'>
                <section [innerHtml]='value' class="inline-block">
                </section>
            </ng-template>

            <ng-template #itemTypeTemplate let-type='type'>
                <p-orderList [value]="type === 'Rent' ? rentRows : (type === 'Printing' ? printingRows : mountingRows)"
                    [responsive]="true" dragdrop="true" [styleClass]="'reorder'"
                    (onReorder)="onRowsReorder($event, type)">
                    <ng-template let-row let-i="index" pTemplate="item">
                        <ng-container *ngFor="let col of columns">
                            <span *ngIf="col?.field === 'srNo'" [style.width]=col.width class="inline-block">
                                {{i + 1}}
                            </span>
                            <span *ngIf="col?.field !== 'srNo'" [style.width]=col.width class="inline-block">
                                <ng-container
                                    *ngTemplateOutlet="orderListTemplate ; context: { key: col?.field, value: getValueForACol(row, col?.field) }">
                                </ng-container>
                            </span>
                        </ng-container>
                    </ng-template>
                </p-orderList>
            </ng-template>
        </section>

    </section>
</p-dialog>