export enum MediaSubCategoryEnum {
  HOARDING = "Hoarding",
  HOARDING_GROUP = "Hoarding Group",
  KIOSK = "Kiosk",
  PILLAR = "Pillar",
  GANTRY = "Gantry",
  // WALL_WRAP = 'wall-wrap',
  BQS = "BQS",
  // BUS = 'bus',
  SIGNBOARD = "Sign Board",
  "PICKUP STAND" = "Pickup Stand",
  UNIPOLE = "Unipole",
  UTILITY = "Utility",
  LED = "LED",
  // BUS_SHELTER = "Bus Shelter",
  // BUS = "Bus",
  // RAILWAY_STATION = "Railway Station",
  // SKYWALK = "SkyWalk",
  // GANTRY = "Gantry",
  // TRAFFIC_BOOTH = "TRAFFIC_BOOTH"
}
