import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  HostListener,
  ViewEncapsulation,
} from "@angular/core";
import { MapMarker } from "../../../modals/map/MapMarker";
import { MapMetadata } from "../../../modals/map/map-metadata";
import { HoardingLightEnum } from "../../constants/hoarding-light-enum";
import { DateUtil } from "../../../helpers/date.util";
import * as utils from "../../../helpers/utils";
import {
  GridPaginationEvent,
  GridColumn,
  Alignment,
  EditableGridFieldTypes,
  GridConfig,
} from "../../../components/sib-forms/grid/grid.config";
import { PaginationData } from "../../../modals/paginationdata";
import { SearchFilter } from "../photo-interface/photo-interface/photo-interface.component";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { AppUrls } from "../../../services/urls";
import {
  concat,
  map,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { HoardingService } from "../../../services/shared/hoardings.service";
import { DataScroller, SelectItem } from "primeng/primeng";
import { MapComponent } from "../map/map.component";
import { HoardingMasterColService } from "../../../services/shared/hoarding-master-column.service";
import { Subject, Observable } from "rxjs";
import { HoardingStatusEnum } from "../../constants/hoarding-status-enum";
import { KioskService } from "../../../services/shared/kiosk.service";
import { HoardingGroupService } from "../../../services/shared/hoardingGroup.service";
import { HoardingGroup } from "../../../modals/hoardings/hoardings-group";
import { ContractHoardings } from "../../../modals/contracts/contract-hoarding";
import { Kiosk } from "../../../modals/hoardings/kiosk";
import { MediaSubCategoryEnum } from "../../constants/media-sub-category-enum";
import * as _ from "lodash";
import { Plan } from "../../../modals/plans/plan";
import { PlanWrapper } from "../../../modals/plans/planWrapper";
import { HoardingFunctionalService } from "../../../hoardings/services/hoarding-functional.service";
import { SavePlanService } from "../../../services/shared/savePlan.service";
import { UpdatePlanService } from "../../../services/shared/updatePlan.service";
import { Router } from "@angular/router";
import { GridComponent } from "../../../components/sib-forms/grid/grid.component";
import { InventoryService } from "../../../services/shared/inventory.service";
import { ErrorUtil } from "../../../helpers/error.utils";
import { NotificationsMessages } from "../../../services/shared/notifications-messages";
import { Inventory } from "../../../modals/hoardings/inventory";
import { MediaTypeEnum } from "../../constants/media-type-enum";
import { MountingPrice } from "../../../modals/plans/mountingPrice";
import { PrintingPrice } from "../../../modals/plans/printingPrice";
import { AuthService } from "../../../login-service/auth.service";

@Component({
  selector: "sib-hoarding-map-view",
  templateUrl: "./hoarding-map-view.component.html",
  styleUrls: ["./hoarding-map-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HoardingMapViewComponent implements OnInit, AfterViewInit {
  // noImageUrl = 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89';
  noImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno-image.png?alt=media&token=3f6489c0-df74-41bc-892e-77b3573f0778";
  // itemsToShow: any[] = [];
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  scrollHeight: string = window.innerHeight - 100 + "px";
  isLoading = false;
  loading = false;
  showMap = false;
  mapMetadata: MapMetadata = new MapMetadata();
  itemsPerPage = 50;
  pageSize = 50;
  firstIndex = 0;
  totalItemsCount: number;
  monthlyRateFrom = 0;
  monthlyRateTo = 500000;
  monthlyRateValues: number[] = [0, 500000];
  dscrollerPaginationEvent: GridPaginationEvent;
  // globalSearchData: Subject<any> = new Subject();
  globalSearchData: string;
  sitesFromDate: Date;

  viewSidebar = false;
  selectedInventories: any[] = []; // for sidebar menu
  selectedInventoryIds: string[] = [];
  hoardingItems: Inventory[] = [];
  hoardingGroupItems: HoardingGroup[] = [];
  kioskItems: Inventory[] = [];
  filteredInventories: any[] = []; // list of hoardings, hoarding groups and kiosks
  selectedInventoryType: any; // inventory type selected from dropdown
  lastInventoryType: any; // type of the last inventory selected
  inventoryTypeDropdown: SelectItem[] = [];
  inventoryStatusDropdown: SelectItem[] = [];
  selectedInventoryStatus: any; // inventory status selected from dropdown
  // loading = false; // for sidebar menu data table
  dataTableColumns: any[] = [];
  checked = [];
  globalFilterData: Subject<any> = new Subject();
  globalFilterValue: any;
  onInlineScroll: any;
  displayPlanDialog = false;
  planWrapper: PlanWrapper = new PlanWrapper();
  currentHoardingPage: number;
  currentHoardingGroupPage: number;
  currentKioskPage: number;
  currentPillarPage: number;
  currentGantryPage: number;
  currentBqsPage: number;
  currentPickupStandPage: number;
  currentUnipolePage: number;
  currentSignboardPage: number;
  currentUtilityPage: number;
  currentLedPage: number;
  inventoryTypeChanged = false;

  mapFromHoarding: boolean = false;
  planCreationMap: boolean = true;
  page: number;
  searchedWithGlobalFilter: boolean = false;
  removeMarker: any;

  inventories = [];

  mapCoordinatesChanged: boolean = false;

  coordinateX1: number;
  coordinateY1: number;
  coordinateX2: number;
  coordinateY2: number;

  pillarItems: Inventory[] = [];
  bqsItems: Inventory[] = [];
  gantryItems: Inventory[] = [];
  pickupStandItems: Inventory[] = [];
  unipoleItems: Inventory[] = [];
  signboardItems: Inventory[] = [];
  utilityItems: Inventory[] = [];
  ledItems: Inventory[] = [];

  mapZoomed: boolean = false;

  showMessage: boolean = false;
  resetSearchBox: boolean = false;
  mapLoaded: boolean = false;

  inventoryGlobalFilterFields: string[] = [
    "HID",
    "City",
    "Location",
    "Size",
    "Note",
    "Area",
    "District",
  ];

  @ViewChild("dscroller") dscroller: DataScroller;
  @ViewChild(MapComponent) mapComponent: MapComponent;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setScrollHeightAndImagesPerPage();
  }

  @HostListener("scroll", ["$event"])
  onscroll($event) {
    this.setScrollHeightAndImagesPerPage();
  }

  constructor(
    private hoardingService: HoardingService,
    private hoardingGroupService: HoardingGroupService,
    private kioskService: KioskService,
    private notificationServcie: NotificatoinsService,
    private hmColService: HoardingMasterColService,
    private hoardingFunctionalService: HoardingFunctionalService,
    private savePlanService: SavePlanService,
    private updatePlanService: UpdatePlanService,
    private router: Router,
    private inventoryService: InventoryService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.setInventoryTypeDropdown();
    this.setInventoryStatusDropdown();
    this.setDataTableColumns();
    this.filteredInventories = [];
    this.hmColService.siteFromDate.subscribe((sitesFromDate) => {
      this.sitesFromDate = sitesFromDate;
      this.overlays = [];
      this.getData();
    });
    this.hmColService.monthlyRateValues.subscribe((monthlyRateValues) => {
      this.monthlyRateValues = monthlyRateValues;
      this.overlays = [];
      this.getData();
    });

    this.globalFilterData
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.globalFilterValue = searchValue;
        this.getData();
      });
    this.setInitialPaginationData();
    // this.onInlineScroll = this.inlineScrollerListener.bind(this);
    // this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
  }

  ngAfterViewInit() {
    this.setScrollHeightAndImagesPerPage();
    this.onInlineScroll = this.inlineScrollerListener.bind(this);
    this.dscroller.contentViewChild.nativeElement.addEventListener(
      "scroll",
      this.onInlineScroll
    );
    this.inlineScrollerListener();
    // this.getData();
  }

  setInventoryTypeDropdown() {
    this.inventoryTypeDropdown = utils.createDropdown(MediaTypeEnum, false);
    this.setDefaultSelectedInventoryType();
  }

  setInventoryStatusDropdown() {
    this.inventoryStatusDropdown = utils.createDropdown(
      HoardingStatusEnum,
      true
    );
    this.inventoryStatusDropdown = this.inventoryStatusDropdown.filter(
      (item) =>
        item.label === "All" ||
        item.label === HoardingStatusEnum.AVAILABLE ||
        item.label === HoardingStatusEnum.NOT_AVAILABLE ||
        item.label === HoardingStatusEnum.BLOCKED
    );
    this.selectedInventoryStatus = this.inventoryStatusDropdown[0].value;
  }

  setDefaultSelectedInventoryType() {
    this.selectedInventoryType = this.inventoryTypeDropdown[0].value;
    this.lastInventoryType = this.inventoryTypeDropdown[0].value;
    this.currentHoardingPage = 0;
    // this.currentHoardingGroupPage = 0;
    this.currentKioskPage = 0;
    this.currentPillarPage = 0;
    this.currentGantryPage = 0;
    this.currentBqsPage = 0;
    this.currentPickupStandPage = 0;
    this.currentUnipolePage = 0;
    this.currentSignboardPage = 0;
    this.currentUtilityPage = 0;
    this.currentLedPage = 0;
  }

  onInventoryDropdownChange(event) {
    this.selectedInventoryType = event.value;
    this.filteredInventories = [];
    this.setPageForLastInventory();
    this.dscrollerPaginationEvent.page = this.getCurrentPageForInventory();
    this.inventoryTypeChanged = true;
    this.getData();
    this.dscrollerPaginationEvent.page++;
  }

  onInventoryStatusDropdownChange(event) {
    this.selectedInventoryStatus = event.value;
    this.filteredInventories = [];
    this.dscrollerPaginationEvent.page = 0;
    this.getData();
  }

  setPageForLastInventory() {
    switch (this.lastInventoryType) {
      case "HOARDING": {
        this.currentHoardingPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "KIOSK": {
        this.currentKioskPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "GANTRY": {
        this.currentGantryPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "PILLAR": {
        this.currentPillarPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "BQS": {
        this.currentBqsPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "PICKUP STAND": {
        this.currentPickupStandPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "UNIPOLE": {
        this.currentUnipolePage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "SIGNBOARD": {
        this.currentSignboardPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "UTILITY": {
        this.currentUtilityPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case "LED": {
        this.currentLedPage = this.dscrollerPaginationEvent.page;
        break;
      }
    }
  }

  getCurrentPageForInventory() {
    switch (this.selectedInventoryType) {
      case "HOARDING": {
        return this.currentHoardingPage;
      }
      // case 'HOARDING_GROUP': {
      //   return this.currentHoardingGroupPage;
      // }
      case "KIOSK": {
        return this.currentKioskPage;
      }

      case "PILLAR": {
        return this.currentPillarPage;
      }

      case "BQS": {
        return this.currentBqsPage;
      }

      case "GANTRY": {
        return this.currentGantryPage;
      }
      case "PICKUP STAND": {
        return this.currentPickupStandPage;
      }
      case "UNIPOLE": {
        return this.currentUnipolePage;
      }
      case "SIGNBOARD": {
        return this.currentSignboardPage;
      }
      case "UTILITY": {
        return this.currentUtilityPage;
      }
      case "LED": {
        return this.currentLedPage;
      }
    }
  }

  setDataTableColumns() {
    this.dataTableColumns = [
      // passing value of datatable columns
      {
        name: "customId",
        style: {
          width: "20vw !important",
          "text-align": "left !important",
          "word-break": "break-all !important",
          "white-space": "normal !important",
          "word-wrap": "break-all !important",
        },
        header: "Id",
        filter: false,
        filterMatchMode: "contains",
        source: "hoardings",
        type: "text",
        hidden: false,
        permanent: true,
        sortable: false,
      },
      {
        name: "location",
        style: {
          width: "50vw !important",
          "text-align": "left !important",
          "word-wrap": "break-all !important",
          "white-space": "normal !important",
          "word-break": "break-all !important",
        },
        header: "Location",
        filter: false,
        filterMatchMode: "contains",
        source: "hoardings",
        type: "text",
        hidden: false,
        permanent: true,
        sortable: false,
      },
      {
        name: "remove",
        style: { width: "20vw !important", "text-align": "right !important" },
        header: "",
        filter: false,
        filterMatchMode: "contains",
        source: "hoardings",
        type: "text",
        hidden: false,
        permanent: true,
        sortable: false,
      },
    ];
  }

  inlineScrollerListener() {
    const scrollTop = this.dscroller.contentViewChild.nativeElement.scrollTop;
    const scrollHeight =
      this.dscroller.contentViewChild.nativeElement.scrollHeight;
    const viewportHeight =
      this.dscroller.contentViewChild.nativeElement.clientHeight;

    if (scrollTop >= scrollHeight - viewportHeight) {
      // this.displayLoaderButton(true);
      this.inventoryTypeChanged = true;
      this.isLoading = true;
      // console.log("scroller test..", this.mapCoordinatesChanged);

      // if (this.dscrollerPaginationEvent.page !== 1 && this.dscrollerPaginationEvent.page !== 0) {
      this.getData();
      this.dscrollerPaginationEvent.page += 1;

      // }
      // this.dscrollerPaginationEvent.page += 1;
    } else {
      // this.displayLoaderButton(false);
    }
  }

  handleMonthlyRateRange(event, values) {
    this.hoardingItems = [];
    // this.hoardingGroupItems = [];
    this.kioskItems = [];
    this.bqsItems = [];
    this.pillarItems = [];
    this.gantryItems = [];
    this.pickupStandItems = [];
    this.unipoleItems = [];
    this.signboardItems = [];
    this.utilityItems = [];
    this.ledItems = [];

    this.dscrollerPaginationEvent.page = 0;
    this.monthlyRateFrom = values[0];
    this.monthlyRateTo = values[1];
    this.hmColService.monthlyRateValues.next(this.monthlyRateValues);
  }
  lazyLoadHandler(event) {
    // console.log("lazy load data...", event);
    // this.itemsPerPage = event.rows;
    this.pageSize = event.rows;
    this.firstIndex = this.globalSearchData ? 0 : event.first;
    this.getData();
    // if (this.dscrollerPaginationEvent.page === 0) {
    this.dscrollerPaginationEvent.page += 1;

    // }
  }

  getData() {
    this.isLoading = true;
    this.overlays = [];
    this.setPaginationData();

    this.getInventoryData();
  }

  getInventoryData() {
    Object.assign(this.dscrollerPaginationEvent.filters, {
      mediaType: { value: this.selectedInventoryType, matchMode: "is" },
    });
    this.inventoryService
      .create(
        this.dscrollerPaginationEvent,
        this.dscrollerPaginationEvent,
        "/search"
      )
      .subscribe(
        (response) => {
          if (response) {
            this.setInventoryItems(response["data"]["content"]);
            this.totalItemsCount = response["data"].totalElements;
            this.mapCoordinatesChanged = false;
            this.resetSearchBox = false;
            this.mapLoaded = true;
          }
        },
        (error) => {
          const errorObject = ErrorUtil.getErrorObject(error);
          if (errorObject.code === 417 || errorObject.code === 412) {
            this.notificationServcie.info(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TRY_AGAIN,
              NotificationsMessages.INFORMATION
            );
          } else {
            this.notificationServcie.error(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TECHNICAL_ISSUE,
              NotificationsMessages.ERROR
            );
          }
        }
      );
  }

  setInventoryItems(data) {
    switch (this.selectedInventoryType) {
      case "HOARDING": {
        // this.getHoardingData();
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter ||
          this.mapCoordinatesChanged
        ) {
          this.hoardingItems = [];
        }
        this.hoardingItems = [...this.hoardingItems, ...data];
        this.filteredInventories = this.hoardingItems;
        if (this.hoardingItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      case "KIOSK": {
        // this.getKioskData();
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.kioskItems = [];
        }
        this.kioskItems = [...this.kioskItems, ...data];
        this.filteredInventories = this.kioskItems;
        if (this.kioskItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }

      case "PILLAR": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.pillarItems = [];
        }
        this.pillarItems = [...this.pillarItems, ...data];
        this.filteredInventories = this.pillarItems;
        if (this.pillarItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }

      case "GANTRY": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.gantryItems = [];
        }
        this.gantryItems = [...this.gantryItems, ...data];
        this.filteredInventories = this.gantryItems;
        if (this.gantryItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }

      case "BQS": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.bqsItems = [];
        }
        this.bqsItems = [...this.bqsItems, ...data];
        this.filteredInventories = this.bqsItems;
        if (this.bqsItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      case "PICKUP STAND": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.pickupStandItems = [];
        }
        this.pickupStandItems = [...this.pickupStandItems, ...data];
        this.filteredInventories = this.pickupStandItems;
        if (this.pickupStandItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }

      case "UNIPOLE": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.unipoleItems = [];
        }
        this.unipoleItems = [...this.unipoleItems, ...data];
        this.filteredInventories = this.unipoleItems;
        if (this.unipoleItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      case "SIGNBOARD": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.signboardItems = [];
        }
        this.signboardItems = [...this.signboardItems, ...data];
        this.filteredInventories = this.signboardItems;
        if (this.signboardItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      case "UTILITY": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.utilityItems = [];
        }
        this.utilityItems = [...this.utilityItems, ...data];
        this.filteredInventories = this.utilityItems;
        if (this.utilityItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      case "LED": {
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.ledItems = [];
        }
        this.ledItems = [...this.ledItems, ...data];
        this.filteredInventories = this.ledItems;
        if (this.ledItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.setMapMarker();
          this.isLoading = false;
        }
        break;
      }
      default: {
        // this.getHoardingData();
        if (
          this.dscrollerPaginationEvent.page === 0 ||
          this.dscrollerPaginationEvent.globalFilter
        ) {
          this.hoardingItems = [];
        }
        this.hoardingItems = [...this.hoardingItems, ...data];
        if (this.hoardingItems.length) {
          this.setMapMarker();
          this.setCheckedInventory();
        } else {
          this.isLoading = false;
        }
        break;
      }
    }

    if (this.filteredInventories.length > 0) {
      this.showMessage = false;
    } else {
      this.showMessage = true;
    }
  }

  setInitialPaginationData() {
    this.dscrollerPaginationEvent = {
      filters: {},
      globalFilter: "",
      page: 0,
      // size: this.itemsPerPage,
      size: this.pageSize,
      sortField: "updatedDate",
      sortOrder: 1,
    };
  }

  setPaginationData() {
    this.dscrollerPaginationEvent.globalFilter = this.globalFilterValue;
    Object.assign(
      this.dscrollerPaginationEvent.filters,
      this.setPriceRangeFilter()
    );
    Object.assign(
      this.dscrollerPaginationEvent.filters,
      this.setSitesAvailableFromFilter()
    );
    Object.assign(this.dscrollerPaginationEvent.filters, {
      status: { value: this.selectedInventoryStatus, matchMode: "is" },
    });
    // if (this.dscrollerPaginationEvent.filters.status.value === 'AVAILABLE' || this.dscrollerPaginationEvent.filters.status.value === 'NOT_AVAILABLE' || this.dscrollerPaginationEvent.filters.status.value === 'BLOCKED') {
    //   this.dscrollerPaginationEvent.page = 0;
    // }
    if (
      !this.dscrollerPaginationEvent.filters.status ||
      this.dscrollerPaginationEvent.filters.status.value === "ALL"
    ) {
      Object.assign(this.dscrollerPaginationEvent.filters, {
        status: {
          value: ["MIGRATED", "EXPIRED", "INVALID", "INACTIVE"],
          matchMode: "notIn",
        },
      });
    }

    this.dscrollerPaginationEvent.size = this.pageSize;

    if (this.mapCoordinatesChanged) {
      if (this.coordinateX2 > this.coordinateY2) {
        Object.assign(this.dscrollerPaginationEvent.filters, {
          "geoCoordinate.longitude": {
            value: [this.coordinateY2, this.coordinateX2],
            matchMode: "between",
          },
        });
      } else if (this.coordinateX2 < this.coordinateY2) {
        Object.assign(this.dscrollerPaginationEvent.filters, {
          "geoCoordinate.longitude": {
            value: [this.coordinateX2, this.coordinateY2],
            matchMode: "between",
          },
        });
      }

      if (this.coordinateX1 > this.coordinateY1) {
        Object.assign(this.dscrollerPaginationEvent.filters, {
          "geoCoordinate.latitude": {
            value: [this.coordinateY1, this.coordinateX1],
            matchMode: "between",
          },
        });
      } else if (this.coordinateX1 < this.coordinateY1) {
        Object.assign(this.dscrollerPaginationEvent.filters, {
          "geoCoordinate.latitude": {
            value: [this.coordinateX1, this.coordinateY1],
            matchMode: "between",
          },
        });
      }
      this.dscrollerPaginationEvent.page = 0;
    } else {
      Object.assign(this.dscrollerPaginationEvent.filters, {
        "geoCoordinate.latitude": { value: 0, matchMode: "neq" },
      });
      Object.assign(this.dscrollerPaginationEvent.filters, {
        "geoCoordinate.longitude": { value: 0, matchMode: "neq" },
      });
    }

    if (this.inventoryTypeChanged) {
      this.dscrollerPaginationEvent.page = this.dscrollerPaginationEvent.page;
    } else {
      // if (this.dscrollerPaginationEvent.page === 1) {
      //   this.firstIndex = 100;
      // }
      // this.dscrollerPaginationEvent.page = Number(this.firstIndex / this.pageSize);
    }
    this.inventoryTypeChanged = false;

    if (this.dscrollerPaginationEvent.globalFilter) {
      this.searchedWithGlobalFilter = true;
      this.page = _.cloneDeep(this.dscrollerPaginationEvent.page);
      this.dscrollerPaginationEvent.page = undefined;
      this.dscroller.contentViewChild.nativeElement.removeEventListener(
        "scroll",
        this.onInlineScroll,
        true
      );
    } else {
      this.page = this.dscrollerPaginationEvent.page;
      this.dscroller.contentViewChild.nativeElement.addEventListener(
        "scroll",
        this.onInlineScroll
      );
      if (this.searchedWithGlobalFilter) {
        this.page = 0;
        this.dscrollerPaginationEvent.page = this.page;
        this.searchedWithGlobalFilter = false;
      }
    }
  }

  setSitesAvailableFromFilter() {
    return this.sitesFromDate
      ? {
          campaignEndDate: {
            value: this.sitesFromDate,
            matchMode: "availableFrom",
          },
        }
      : {};
  }
  setPriceRangeFilter() {
    return this.monthlyRateValues
      ? { grossPrice: { value: this.monthlyRateValues, matchMode: "between" } }
      : {};
  }

  setScrollHeightAndImagesPerPage() {
    this.scrollHeight = window.innerHeight - 100 + "px";
    // this.itemsPerPage = 50;
    this.itemsPerPage =
      utils.getIntegerValue(Number(this.scrollHeight.split("px")[0]) / 100) * 5; // 100 - random vlaue < 150 (image height), 5 - images in a line
  }

  searchBoxChanges(event: string) {
    this.globalSearchData = event.split(",")[0];
    this.firstIndex = 0;
    this.overlays = [];
    if (!this.planCreationMap) {
      this.getData();
    }
  }

  setMapMarker() {
    this.filteredInventories.forEach((inventory) => {
      if (inventory && inventory.geoCoordinate) {
        this.mapMarker = new MapMarker().setMapMarkerFromInventory(inventory);
        this.overlays.push(this.mapMarker);
      }
    });
    this.overlays = _.cloneDeep(this.overlays);
    if (this.overlays.length === 0) {
      this.overlays.push(new MapMarker());
      this.options = {
        center: { lat: 23.02579, lng: 72.58727 },
        zoom: 12,
      };
    } else {
      this.options = {
        center: {
          lat:
            typeof this.overlays[0].latitude === "string"
              ? parseFloat(this.overlays[0].latitude)
              : this.overlays[0].latitude,
          lng:
            typeof this.overlays[0].longitude === "string"
              ? parseFloat(this.overlays[0].longitude)
              : this.overlays[0].longitude,
        },
        // center: { lat: centLat, lng: centLong },
        zoom: 8,
      };
    }

    this.showMap = true;
    this.isLoading = false;
  }

  inputSitesAvailableFrom(event) {
    this.dscrollerPaginationEvent.page = 0;
    this.resetItems();
    if (event instanceof Date) {
      this.hmColService.siteFromDate.next(event);
    } else {
      this.hmColService.siteFromDate.next(null);
    }
  }

  globalFiltering(value) {
    this.globalFilterData.next(value);
  }

  getLightType(lightType) {
    return HoardingLightEnum[lightType];
  }
  cardHover(item) {
    this.mapComponent.setBounce(item);
  }

  cardOut(item) {
    this.mapComponent.resetBounce(item);
  }

  toggleSidebar() {
    this.viewSidebar = !this.viewSidebar;
  }

  // removeInventory(event) {
  //   // resetting the checkbox
  //   this.setSelectedInventoriesFromCheckbox(false, event.rowData);
  // }
  removeInventory(inventory) {
    // resetting the checkbox
    this.setSelectedInventoriesFromCheckbox(false, inventory["rowData"]);
    this.removeMarker = inventory["rowData"];
  }

  onCheckboxSelectionChange(event, item, index) {
    this.setSelectedInventoriesFromCheckbox(event, item);
  }

  setCheckedInventory() {
    this.checked = [];
    this.filteredInventories.forEach((inventory, index) => {
      const ind = this.selectedInventories.findIndex(
        (inv) => inventory.customId === inv.customId
      );
      if (ind !== -1) {
        this.checked[index] = true;
      } else {
        this.checked[index] = false;
      }
    });
  }

  setSelectedInventoriesFromCheckbox(event, item) {
    const index = this.selectedInventories.findIndex(
      (inventory) => inventory.customId === item.customId
    );
    if (event) {
      // checkbox is checked
      if (index === -1) {
        // selected inventries do not include the checked item
        this.selectedInventories.push(item);
      } else {
        // selected inventories already include the checked item --> should never happen
        // do nothing
      }
    } else {
      // checkbox is unchecked
      if (index === -1) {
        // selected inventories do not include the unchecked item --> should never happen
        // do nothing
      } else {
        // selected inventories already include the checked item
        this.removeMarker = item;
        this.selectedInventories.splice(index, 1);
      }
    }
    this.selectedInventories = JSON.parse(
      JSON.stringify(this.selectedInventories)
    );
    this.selectedInventoryIds = this.selectedInventories.map(
      (inventory) => inventory.customId
    );
    this.setCheckedInventory();
    // console.log("selected inventories", this.selectedInventories);
  }

  setSelectedInventoriesFromMap(ids) {
    // adding selected map markers to selected inventory list
    ids.forEach((id) => {
      if (
        this.selectedInventories.findIndex(
          (inventory) => inventory.customId === id
        ) !== -1
      ) {
        // do nothing
      } else {
        // console.log("push", id);
        const inventory = this.filteredInventories.find(
          (item) => item.customId === id
        );
        this.selectedInventories.push(inventory);
        // this.selectedInventories = JSON.parse(JSON.stringify(this.selectedInventories));
        // this.setCheckedInventory();
      }
    });

    // removing unselected map markers from selected inventory list
    const idsToRemove = this.selectedInventoryIds.filter(
      (id) => ids.findIndex((mapMarkerId) => mapMarkerId === id) === -1
    );
    idsToRemove.forEach((id) => {
      const index = this.selectedInventories.findIndex(
        (item) => item.customId === id
      );
      if (index !== -1) {
        this.selectedInventories.splice(index, 1);
      } else {
        // do nothing -> will not happen
      }
    });

    this.selectedInventories = JSON.parse(
      JSON.stringify(this.selectedInventories)
    );
    this.selectedInventoryIds = this.selectedInventories.map(
      (inventory) => inventory.customId
    );
    this.setCheckedInventory();
  }

  assignToPlan() {
    this.displayPlanDialog = true;
  }

  showPlanDialog() {
    this.displayPlanDialog = true;
  }

  hidePlanDialog() {
    this.displayPlanDialog = false;
  }

  createPlan(plan: Plan) {
    const planItemBo = this.hoardingFunctionalService.getPlanItemsWrapper(
      this.selectedInventories
    );
    var mediaTypes = Array.from(
      new Set(this.selectedInventories.map((item) => item.mediaType))
    );
    this.setPlanPrintingPrice(mediaTypes, plan);
    this.setPlanMountingPrice(mediaTypes, plan);
    this.getPlanWrapper(plan, planItemBo);
    this.savePlanService.create(this.planWrapper).subscribe(
      (response) => {
        if (response) {
          // console.log("response is", response);
          this.notificationServcie.success(
            "Plan Created Successfully",
            "Plan Creation"
          );
          this.hidePlanDialog();
          this.router.navigate(["/plans/view/" + response["plan"]["id"]]);
        }
      },
      (error) => {
        // console.log("error is", error);
        this.hidePlanDialog();
        this.notificationServcie.error(
          error.error.message ? error.error.message : "Some technical issue",
          "Error!!"
        );
      }
    );
  }

  setPlanPrintingPrice(mediaTypes, plan) {
    plan.mediaTypeToPrintingPrice = {};
    mediaTypes.forEach((type) => {
      switch (type) {
        case "HOARDING":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            HOARDING: { printingPrice: new PrintingPrice() },
          });
          break;
        case "KIOSK":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            KIOSK: { printingPrice: new PrintingPrice() },
          });
          break;
        case "PILLAR":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            PILLAR: { printingPrice: new PrintingPrice() },
          });
          break;
        case "BQS":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            BQS: { printingPrice: new PrintingPrice() },
          });
          break;
        case "GANTRY":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            GANTRY: { printingPrice: new PrintingPrice() },
          });
          break;
        case "PICKUP STAND":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            "PICKUP STAND": { printingPrice: new PrintingPrice() },
          });
          break;
        case "UNIPOLE":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            UNIPOLE: { printingPrice: new PrintingPrice() },
          });
          break;
        case "SIGNBOARD":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            SIGNBOARD: { printingPrice: new PrintingPrice() },
          });
          break;
        case "UTILITY":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            UTILITY: { printingPrice: new PrintingPrice() },
          });
          break;
        case "LED":
          Object.assign(plan.mediaTypeToPrintingPrice, {
            LED: { printingPrice: new PrintingPrice() },
          });
        break;
      }
    });
  }

  setPlanMountingPrice(mediaTypes, plan) {
    plan.mediaTypeToMountingPrice = {};
    mediaTypes.forEach((type) => {
      switch (type) {
        case "HOARDING":
          Object.assign(plan.mediaTypeToMountingPrice, {
            HOARDING: { mountingPrice: new MountingPrice() },
          });
          break;
        case "KIOSK":
          Object.assign(plan.mediaTypeToMountingPrice, {
            KIOSK: { mountingPrice: new MountingPrice() },
          });
          break;
        case "PILLAR":
          Object.assign(plan.mediaTypeToMountingPrice, {
            PILLAR: { mountingPrice: new MountingPrice() },
          });
          break;
        case "BQS":
          Object.assign(plan.mediaTypeToMountingPrice, {
            BQS: { mountingPrice: new MountingPrice() },
          });
          break;
        case "GANTRY":
          Object.assign(plan.mediaTypeToMountingPrice, {
            GANTRY: { mountingPrice: new MountingPrice() },
          });
          break;
        case "PICKUP STAND":
          Object.assign(plan.mediaTypeToMountingPrice, {
            "PICKUP STAND": { mountingPrice: new MountingPrice() },
          });
          break;
        case "UNIPOLE":
          Object.assign(plan.mediaTypeToMountingPrice, {
            UNIPOLE: { mountingPrice: new MountingPrice() },
          });
          break;
        case "SIGNBOARD":
          Object.assign(plan.mediaTypeToMountingPrice, {
            SIGNBOARD: { mountingPrice: new MountingPrice() },
          });
          break;
        case "UTILITY":
          Object.assign(plan.mediaTypeToMountingPrice, {
            UTILITY: { mountingPrice: new MountingPrice() },
          });
          break;
        case "LED":
          Object.assign(plan.mediaTypeToMountingPrice, {
            LED: { mountingPrice: new MountingPrice() },
          });
        break;
      }
    });
  }

  savePlan(plan: Plan) {
    const planItemBo = this.hoardingFunctionalService.getPlanItemsWrapper(
      this.selectedInventories
    );
    this.getPlanWrapper(plan, planItemBo);
    this.updatePlanService.create(this.planWrapper).subscribe(
      (response) => {
        if (response) {
          // console.log("response is", response);
          this.notificationServcie.success(
            "Plan Updated Successfully",
            "Plan Update"
          );
          this.hidePlanDialog();
          this.router.navigate(["/plans/view/" + plan.id, { itemAdded: true }]);
        }
      },
      (error) => {
        // console.log("error is", error);
        this.hidePlanDialog();
        this.notificationServcie.error(
          error.error.message ? error.error.message : "Some technical issue",
          "Error!!"
        );
      }
    );
  }

  getPlanWrapper(plan, planItemBo) {
    this.planWrapper = new PlanWrapper();
    this.planWrapper.plan = plan;
    this.planWrapper.items = planItemBo;
  }

  clearSidebar() {
    if (this.selectedInventories.length) {
      this.setSelectedInventoriesFromCheckbox(
        false,
        this.selectedInventories[0]
      );
      this.clearSidebar();
    } else {
      // do nothing
    }
  }

  /**
   * @description to get the coordinates of the map view
   * @author Raveena Nathani
   * @date 2020-03-30
   * @param {*} event
   * @memberof HoardingMapViewComponent
   */
  filterInventoriesOnCoordinates(event) {
    this.mapCoordinatesChanged = true;
    this.coordinateX2 = event[0];
    this.coordinateX1 = event[1];
    this.coordinateY2 = event[2];
    this.coordinateY1 = event[3];
    // this.dscrollerPaginationEvent.page = this.getCurrentPageForInventory();
    this.mapZoomed = true;
    this.getData();
    // this.setFilteredDataBasedOnCoordinates();
  }

  resetMap() {
    this.mapZoomed = false;
    this.dscrollerPaginationEvent.page = 0;
    this.resetItems();
    delete this.dscrollerPaginationEvent.filters["geoCoordinate.latitude"];
    delete this.dscrollerPaginationEvent.filters["geoCoordinate.longitude"];
    this.getData();
    this.dscrollerPaginationEvent.page++;
    this.resetSearchBox = true;
    this.getData();
  }

  resetItems() {
    this.filteredInventories = [];
    this.hoardingItems = [];
    this.kioskItems = [];
    this.pillarItems = [];
    this.gantryItems = [];
    this.bqsItems = [];
    this.pickupStandItems = [];
    this.unipoleItems = [];
    this.signboardItems = [];
    this.utilityItems = [];
    this.ledItems = [];
  }

  getCommaSeparatedFields() {
    let globalFilterFields: any = [];
    var fields;

    globalFilterFields = this.inventoryGlobalFilterFields;

    if (globalFilterFields && globalFilterFields.length > 0) {
      for (let i = 0; i < globalFilterFields.length; i++) {
        var fieldName = globalFilterFields[i];
        if (i === 0) {
          fields = fieldName;
        } else {
          fields = fields + ", " + fieldName;
        }
      }
    } else {
      fields = "No Fields";
    }
    return fields;
  }

  setMonthlyRateLabel() {
    return this.auth.setMonthlyRateLabel();
  }
}
