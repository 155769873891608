import { Component, OnInit, ViewChild, EventEmitter, ViewEncapsulation, Output } from '@angular/core';
import { EditableGridFieldTypes, GridColumn, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../../helpers/date.util';
import { GridNameEnum } from '../../constants/grid-name.enum';
import * as utils from '../../../helpers/utils';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { CampaignItem } from '../../../modals/campaigns/campaignItem';
import { FormGroup, FormControl } from '@angular/forms';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { NotificationsMessages } from '../../../services/shared/notifications-messages';
import { CampaignItemService } from '../../../services/shared/campaignItems.service';
import { AppUrls } from '../../../services/urls';
import { OverlayPanel } from 'primeng/primeng';
import { CampaignSummary } from '../../../modals/campaigns/campaignSummary';
import { TaxConfigurationService } from '../../../services/shared/tax-configuration.service';


@Component({
  selector: 'sib-rate-approval-tasks',
  templateUrl: './rate-approval-tasks.component.html',
  styleUrls: ['./rate-approval-tasks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateApprovalTasksComponent implements OnInit {

  @ViewChild(GridComponent) gridViewChildRef: GridComponent<CampaignItem>;
  gridConfig: GridConfig<CampaignItem> = new GridConfig<CampaignItem>();

  @ViewChild('campaignSummaryOverlay') campaignSummaryOverlay: OverlayPanel

  tasksGroup: FormGroup;
  totalRecords: number;
  @Output() eEmitTotalRateApprovalTasks: EventEmitter<any> = new EventEmitter();
  rateApprovalSites: any[] = [];
  oldSummary: CampaignSummary = new CampaignSummary();
  newSummary: CampaignSummary = new CampaignSummary();
  rowItem: CampaignItem = new CampaignItem();
  // isLoading: boolean = false;
  gstParameter: number = 0;

  taskColumns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    {
      field: 'customId',
      name: 'customId',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      width: '5vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.customId) {
          return (data.customId);
        }
      },
    },
    {
      field: 'district',
      name: 'district',
      header: 'District',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.district) {
          return (data.district);
        }
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.city) {
          return (data.city);
        }
      },
    },
    {
      field: 'areaName',
      name: 'areaName',
      header: 'Area',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.areaName) {
          return (data.areaName);
        }
      },
    },
    {
      field: 'location',
      name: 'location',
      header: 'Location',
      required: true,
      editable: false,
      width: '12vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.location) {
          return (data.location);
        }
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      width: '4vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.size) {
          return (data.size);
        }
      },
    },

    {
      field: 'campaign.customer.billingName',
      name: 'campaign.',
      header: 'Billing Name',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      default: false,
      sortable: true,
      permanent: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.campaign.customer.billingName) {
          return (data.campaign.customer.billingName);
        } else {
          return '-';
        }
      },
    },


    {
      field: 'displayName',
      name: 'displayName',
      header: 'Display Name',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      default: false,
      sortable: true,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
    },

    {
      field: 'itemStartDate',
      name: 'itemStartDate',
      header: 'Site Start Date',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemStartDate) {
          return DateUtil.dategridFormatter(data.itemStartDate);
        }
      }
    },
    {
      field: 'itemEndDate',
      name: 'itemEndDate',
      header: 'Site End Date',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemEndDate) {
          return DateUtil.dategridFormatter(data.itemEndDate);
        }
      }
    },
    {
      field: 'employeeName',
      name: 'employeeName',
      header: 'Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      // hidden: !this.showEmployee,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data && data.employeeName) {
          return data.employeeName;
        } else {
          return '-';
        }
      },
    },

    {
      field: 'action',
      name: 'action',
      header: '',
      permanent: true,
      sortable: false,
      hidden: false,
      required: true,
      width: '5vw',
      editable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },
  ]

  constructor(
    private userGridConfigService: UserGridConfigService,
    private notificationService: NotificatoinsService,
    private campaignItemsService: CampaignItemService,
    private taxConfigurationService: TaxConfigurationService
  ) { }

  ngOnInit() {
    this.getGstParameter();
    this.getUserGridConfig();
    this.setFormGroupObjects();
    this.setGridConfigObject();
    this.setColumns();
  }

  setColumns(){
    this.taskColumns.filter((col)=>{
      switch(col.field){
        case 'displayName': {
          col.hidden = utils.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
      }
    })
  }


  getGstParameter() {
    this.taxConfigurationService.gst
      .subscribe((gst) => {
        this.gstParameter = gst;
      })
  }

  /**
   * gets user grid config
   *
   * @memberof RateApprovalTasksComponent
   */
  getUserGridConfig() {
    this.userGridConfigService.create([GridNameEnum['RATE_APPROVAL']])
      .subscribe((tasksGridConfig) => {
        this.taskColumns = utils.getUserConfiguredColumns(tasksGridConfig[GridNameEnum['RATE_APPROVAL']], this.taskColumns);
        this.gridViewChildRef.setAllColumns();
      });
  }


  /**
   * sets form group object
   *
   * @memberof RateApprovalTasksComponent
   */
  setFormGroupObjects() {
    this.tasksGroup = new FormGroup({
      tasksControl: new FormControl()
    });
  }



  /**
   * sets grid config object
   *
   * @memberof RateApprovalTasksComponent
   */
  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new CampaignItem();
    this.gridConfig.model = CampaignItem;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;

    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen


      return this.campaignItemsService.create(paginationEvent, paginationEvent, AppUrls.SEARCH + AppUrls.PENDING_RATE).pipe(
        map((response) => {
          this.rateApprovalSites = response['data']['content'];
          this.totalRecords = response['data'].totalElements;
          this.eEmitTotalRateApprovalTasks.emit(this.totalRecords);

          return response['data']['content'];
        }), catchError((error) => {
          // this.isLoading = false;
          this.notificationService.error(error.error ? error.error.message ? error.error.message : error.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR);
          // this.isLoading = false;
          return Observable.empty();
        }));
    };
  }


  /**
   * updates user grid config
   *
   * @param {*} columnArray
   * @memberof RateApprovalTasksComponent
   */
  updatingUserGridConfig(columnArray) {
    var userGridConfigService: Map<string, string> = new Map();
    userGridConfigService = utils.getGridConfigMap(columnArray, this.taskColumns);
    this.userGridConfigService.patch(utils.buildObject(userGridConfigService), null, GridNameEnum['RATE_APPROVAL'])
      .subscribe((result) => {
      })
  }


  /**
   *  show summary overlay
   *
   * @param {*} event
   * @param {*} rowData
   * @memberof RateApprovalTasksComponent
   */
  showSummaryOverlay(event, rowData) {
    this.rowItem = rowData;
    this.oldSummary = rowData.campaign.summary;
    this.newSummary = rowData.campaign.newSummary;
    this.campaignSummaryOverlay.show(event);
  }
  getItemDescription() {
    const hid = this.rowItem.customId ? this.rowItem.customId : '';
    const city = this.rowItem.city ? this.rowItem.city : '';
    const location = this.rowItem.location ? this.rowItem.location : '';
    const size = (this.rowItem.size ? this.rowItem.size : '').toString();
    // const displayName = this.updatedCampaignItem.displayName ? this.updatedCampaignItem.displayName : '';
    return hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + (size && size.toString() !== '' && size !== null ? ' - ' + size : '');
  }

  getDecimalNum(value) {
    return utils.getDecimalNum(value);
  }

  getAbsoluteValue(value) {
    return utils.getAbsoluteValue(value)
  }
  /**
    * approve rate or cost change of campaign item
    *
    * @memberof CampaignViewComponent
    */
  approveRateChanges() {
    this.campaignSummaryOverlay.hide();
    // this.isLoading = true;
    this.campaignItemsService.get({ itemId: this.rowItem.id }, AppUrls.APPROVE + AppUrls.RATE).subscribe(() => {

      this.notificationService.success(NotificationsMessages.APPROVE_ITEM_CHANGES, NotificationsMessages.CHANGES_APPROVED);
      this.refreshGrid();
    },
      (error) => {
        // this.isLoading = false;
        this.notificationService.error(error.error.message ? error.error.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.REFRESH);
      })
  }



  /**
   *  reject rate or cost change of campaign item
   *
   * @memberof CampaignViewComponent
   */
  rejectRateChanges() {
    // this.isLoading = true;
    this.campaignSummaryOverlay.hide();
    this.campaignItemsService.get({ itemId: this.rowItem.id }, AppUrls.REJECT + AppUrls.RATE).subscribe(() => {
      this.notificationService.success(NotificationsMessages.REJECT_ITEM_CHANGES, NotificationsMessages.CHANGES_REJECTED);
      this.refreshGrid();
    },
      (error) => {
        // this.isLoading = false;
        this.notificationService.error(error.error.message ? error.error.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.REFRESH);
      })
  }



  /**
   * refreshes the grid
   *
   * @memberof RateApprovalTasksComponent
   */
  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

}
