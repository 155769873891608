import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Notifications, AlertType } from "../../modals/notifications";
import { Router, NavigationStart } from "@angular/router";

@Injectable()
export class NotificatoinsService {
    private subject = new Subject<Notifications>();
    private keepAfterRouteChange = true;
    private notify = false;


    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = true;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, summary, keepAfterRouteChange = true) {

        if (navigator.onLine) {
            this.alert(AlertType['0'], summary, message, keepAfterRouteChange);

        }
    }

    error(message: string, summary, keepAfterRouteChange = true) {
        // console.log("inside error service", message);

        setTimeout(() => {                 //error message is coming even after net is disconnected
            if (navigator.onLine) {
                this.alert(AlertType['3'], summary, message, keepAfterRouteChange)
            }
        }, 1000)

    }

    info(message: string, summary, keepAfterRouteChange = true, notify?, notificationLink?) {
        let notification = notify ? notify : this.notify
        setTimeout(() => {                  //info message is coming even after net is disconnected 
            if (navigator.onLine) {
                this.alert(AlertType['1'], summary, message, keepAfterRouteChange, notification, notificationLink)

            }
        }, 1000)

    }

    warn(message: string, summary, keepAfterRouteChange = true) {
        if (navigator.onLine) {
            this.alert(AlertType['2'], summary, message, keepAfterRouteChange)

        }
    }

    alert(type: AlertType, summary: string, message: string, keepAfterRouteChange = true, notify?: boolean, notificationLink?: any) {
        let notification = notify ? notify : this.notify
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Notifications>{ severity: type, summary: summary, detail: message, notify: notification, link: notificationLink });
    }

    clear() {
        // clear alerts
        this.subject.next();
    }
}