export const PackageModulesEnum = {
    'CUSTOMER': "CUSTOMER",
    "CONTRACT": "CONTRACT",
    "INVENTORY": "INVENTORY",
    "PLAN": "PLAN",
    "CAMPAIGN": "CAMPAIGN",
    "OPERATION": "OPERATION",
    "BILLING": "BILLING",
    "ANALYTICS": "ANALYTICS",
    "DASHBOARD": "DASHBOARD"
}