import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, DataTableModule, InputTextModule, InputTextareaModule, MultiSelectModule, DropdownModule, DialogModule } from "primeng/primeng";
import { FormsModule } from '@angular/forms';
import { SubscriptionPlansComponent } from './subscription-plans.component';
import { SubscriptionPackageComponent } from './subscription-package/subscription-package.component';
import { PackagesService } from './services/packages.service';

@NgModule({
    declarations: [
        SubscriptionPlansComponent,
        SubscriptionPackageComponent
    ],

    imports: [
        CommonModule,
        FormsModule,
        DialogModule,
        DropdownModule,
        InputTextModule,
        ButtonModule,
        InputTextareaModule,
    ],
    exports: [
        SubscriptionPlansComponent
    ],

    providers: [],
})

export class SubscriptionPlansModule { }
