import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../helpers/date.util';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { CampaignItemStatusEnum } from '../../shared/constants/campaign-item-status-enum';

export const MonitoringReportColumns: Array<GridColumn> = [
  {
    field: 'srNo',
    name: 'srNo',
    header: 'Sr ',
    required: true,
    width: '2.7vw',  // changed from 3vw to 2.7vw --> Raveena
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: false,
    styleClass: 'sr-number',
    type: EditableGridFieldTypes.CUSTOM
  }, {
    field: 'customId',
    name: 'customId',
    header: 'HID',
    required: true,
    editable: false,
    width: '8vw',
    permanent: true,
    sortable: true,
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
  },
  {
    field: 'district',
    name: 'district',
    header: 'District',
    required: true,
    editable: false,
    hidden: false,
    permanent: false,
    width: '7vw',
    default: true,
    sortable: true,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
  },
  {
    field: 'city',
    name: 'city',
    header: 'City',
    required: true,
    editable: false,
    permanent: true,
    width: '6.2vw',
    sortable: true,
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
  },
  /* {
    field: 'district',
    name: 'district',
    header: 'District',
    permanent: true,
    sortable: true,
    required: true,
    width: '6vw',
    editable: false,
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
  }, */
  {
    field: 'areaName',
    name: 'areaName',
    header: 'Area',
    permanent: true,
    sortable: true,
    required: true,
    width: '10vw',
    editable: false,
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
    /* displayFn: (data) => {
      if (data.area) {
        return data.area.name;
      } else {
        return '-';
      }
    }, */
  },
  {
    field: 'location',
    name: 'location',
    header: 'Location',
    permanent: true,
    sortable: true,
    required: true,
    editable: false,
    width: '17vw',
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
  },
  {
    field: 'size',
    name: 'size',
    header: 'Size',
    permanent: true,
    sortable: true,
    required: true,
    width: '5vw',
    editable: false,
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
    displayFn: (data) => {
      if (data.size) {
        return data.size;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'employeeName',
    name: 'employee',
    header: 'Employee',
    required: true,
    editable: false,
    sortable: true,
    permanent: false,
    default: true,
    width: '7vw',
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search'
    },
    displayFn: (data) => {
      if (data) {
        return data.employeeName;
      } else {
        return '-';
      }
    }
  },
  {
    field: 'displayName',
    name: 'displayName',
    header: 'Display Name',
    permanent: true,
    sortable: false,
    required: true,
    width: '8vw',
    editable: false,
    hidden: false,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
    displayFn: (data) => {
      if (data) {
        return data.displayName;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'monitoringItems.monitoringDate',
    name: 'monitoringDate',
    header: 'Monitoring Date',
    permanent: true,
    default: true,
    sortable: true,
    required: true,
    editable: false,
    width: '8vw',
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    /* filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    }, */
    displayFn: (data) => {
      if (data.monitoringItems) {
        return DateUtil.dategridFormatter(data.monitoringItems.monitoringDate);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'monitoringItems.mode',
    name: 'mode',
    header: 'Mode',
    permanent: true,
    default: true,
    sortable: true,
    required: true,
    editable: false,
    width: '5vw',
    hidden: false,
    type: EditableGridFieldTypes.TEXT,
    /* filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    }, */
    displayFn: (data) => {
      if (data.monitoringItems) {
        return data.monitoringItems.mode;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'monitoringItems.photographer.fullName',
    name: 'photographer',
    header: 'Performed By',
    permanent: false,
    default: true,
    sortable: true,
    required: true,
    editable: false,
    width: '10vw',
    hidden: false,
    styleClass: 'word-break-wrap',
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false
    },
    displayFn: (data) => {
      if (data.monitoringItems.photographer && data.monitoringItems.photographer.fullName !== null) {
        return data.monitoringItems.photographer.fullName;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'monitoringItems.status',
    name: 'uploadImages',
    header: 'Status',
    required: true,
    editable: false,
    hidden: false,
    permanent: true,
    default: true,
    width: '12vw',
    sortable: false,
    filter: {
      matchMode: 'in',
      placeHolder: 'Search',
      custom: true
    },
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data.monitoringItems.status) {
        return CampaignItemStatusEnum[data.monitoringItems.status]
      } else {
        return '-';
      }
    },
  },
];

export const MonitoringReportMap = (() => {
  return mapUtils.createMap(MonitoringReportColumns);
})();
