export class NotificationsMessages {
  // all
  public static TECHNICAL_ISSUE = "Some technical issue";
  public static REFRESH = "Please Refresh";
  public static DOWNLOADING_PROGRESS = "Downloading is in progress";
  public static DOWNLOAD = "Download";
  public static ERROR = "Error!!";
  public static SERVER_ERROR = "Server Error";
  public static FILE_UPLOAD = "File Upload.";
  public static FILE_UPLOAD_SUCESSFULL =
    "File is processed successfully. Please check the downloaded report for more details.";
  public static TRY_AGAIN = "Please Try Again";
  public static INFORMATION = "Information";

  // plan
  public static MARK_ROTATIONAL = "Plan Marked As Rotational";
  public static ROTATIONAL_MARKED_SUCCESSFULL = "Plan Marked successfully";
  public static UNMARK_ROTATIONAL = "Plan Unmarked As Rotational";
  public static ROTATIONAL_UNMARKED_SUCCESSFULL = "Plan Unmarked successfully";
  public static CLONE_PLAN = "Plan Clone";
  public static CLONE_PLAN_SUCCESSFULL = "Plan Cloned sucessfully";
  public static ADD_CUSTOMER =
    "Add a customer before converting the plan into campaign";
  public static CONVERSION_ERROR = "Conversion Error";

  //campaign
  public static APPROVE_ITEM_CHANGES = "Item Changes Approved";
  public static CHANGES_APPROVED = "Changes Approved";
  public static REJECT_ITEM_CHANGES = "Item Changes Rejected";
  public static CHANGES_REJECTED = "Changes Rejected";
  public static RATE_UPDATED = "Rate Updated";
  public static UPDATION_REQUEST = "Updation Request Sent Successfully";

  //contract
  public static TEMPLATE_DOWNLOAD_ERROR = "Error downloading tempalate Url.";
  public static TEMPLATE_DOWNLOAD = "Template Download Url";
  public static ERROR_LOADING_FILE = "Error Loading File";
  public static NULL_SOURCE = "Source must not be null";
  public static UPLOAD_FILE_AGAIN = "Please Upload File Again";
  public static COLUMN_DATA_MISSING = "Column data is missing";
  public static INVENTORY_CREATION_SUCCESS = "Inventory Created Successfully";
  public static INVENTORY_CREATED = "Inventory Created";
  public static INVENTORY_UPDATED_SUCCESS = "Inventory Updated Successfully";
  public static INVENTORY_UPDATED = "Inventory Updated";
  public static EXPENSE_ADDED_SUCCESSFULLY = "Expense Added Successfully";
  public static EXPENSE_ADDED = "Expense Added";

  public static DUPLICATE_INV_ID = "Duplicate Invoice Id";
  public static MEDIA_TYPES_NOT_SAVED = "Media Types Updation Unsuccessful";
  public static MEDIA_TYPES_NOT_REMOVED = "Media Types Removal Unsuccessful";

  public static TALLY_UPLOADED_SUCCESSFULLY = "Tally Uploaded Successfully";
  public static TALLY_UPLOADED = "Tally Uploaded";

  public static PLAN_SUBSCRIBED_SUCCESSFULLY = "Plan Subscribed Successfully";
  public static PLAN_SUBSCRIBED = "Plan Subscribed";

  public static VENDOR_PARTNER_MARKED = "Vendor(s) Marked as Partner";
  public static VENDOR_PARTNER_MARKED_SUCCESSFULLY =
    "Vendor(s) Marked as Partner Successfully";
  public static VENDOR_REMOVED_AS_PARTNER = "Vendor(s) Removed as Partner";
  public static VENDOR_REMOVED_AS_PARTNER_SUCCESSFULLY =
    "Vendor(s) Removed as Partner Successfully";
  public static INVENTORIES_EXPOSED = "Inventories Exposed";
  public static INVENTORIES_EXPOSED_SUCCESSFULLY =
    "Inventories Exposed Successfully";

  public static IMAGE_UPLOADED_SUCCESSFULLY = "Image Uploaded Successfully";
  public static IMAGE_UPLOAD = "Image Upload";
  public static STATE_REMOVED_SUCCESSFULLY = "State Removed Successfully";
  public static STATE_REMOVE = "State Remove";

  public static ANNEXURE_CONFIGURATION_SAVED_SUCCESSFULLY =
    "Annexure Configuration Saved Successfully";
  public static CONFIGURATION_SAVED = "Configuration Saved";

  public static CUSTOMER_PPT_CONFIG_SAVED_SUCCESSFULLY =
    "Customer PPT Configuration Saved Successfully";
  public static CUSTOMER_PPT_CONFIG_SAVED = "Customer PPT Configuration Saved";

  public static CUSTOMER_PPT_CONFIG_DELETED_SUCCESSFULLY =
    "Customer PPT Configuration Deleted Successfully";
  public static CUSTOMER_PPT_CONFIG_DELETED =
    "Customer PPT Configuration Deleted";

  public static PPT_GENERATED_AND_SEND_SUCCESSFULLY =
    "PPT Generated and Sent Successfully";
  public static PPT_GENERATED_AND_SEND = "PPT Generated and Sent";
  public static XLSX_GENERATED_AND_SEND_SUCCESSFULLY =
    "XLSX Generated and Sent Successfully";
  public static XLSX_GENERATED_AND_SEND = "XLSX Generated and Sent";

  public static VENDOR_PPT_CONFIG_SAVED_SUCCESSFULLY =
    "PPT Configuration Saved Successfully";
  public static VENDOR_PPT_CONFIG_SAVED = "PPT Configuration Saved";
}
