import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SibMasterService } from '../services/sib-master.service';
import { SubscriptionDetail } from './subscriptions/subscription-detail';
import { PackagesService } from './services/packages.service';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { Router } from '@angular/router';
import { ErrorUtil } from '../helpers/error.utils';
import { NotificationsMessages } from '../services/shared/notifications-messages';
import { AppUrls } from '../services/urls';
import { CalculationService } from '../services/shared/calculationService';
import { PackageType } from './subscriptions/package-type';
import { PackageInfo } from './subscriptions/package-info';

@Component({
  selector: 'sib-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class SubscriptionPlansComponent implements OnInit {

  subscriptionPackages: any[] = [];
  showConfirmationDialog: boolean = false;
  selectedPackage: any;
  subscriptionDetail: SubscriptionDetail;
  activePackage: PackageInfo;
  confirmationDialogHeader: string;

  constructor(
    private sibMasterService: SibMasterService,
    private packagesService: PackagesService,
    private notificationService: NotificatoinsService,
    private router: Router,
    private calculationService: CalculationService
  ) { }

  ngOnInit() {
    this.getSubscriptionPlans()
  }


  /**
   * @description gets subscription plans
   * @author Raveena Nathani
   * @date 2020-06-05
   * @memberof SubscriptionPlansComponent
   */
  getSubscriptionPlans() {
    this.sibMasterService.getSubscriptionPlans().subscribe((response) => {
      if (response) {
        this.subscriptionPackages = response['data'];
      }
    });
  }



  /**
   * @description called when user subscribes plan
   * @author Raveena Nathani
   * @date 2020-06-04
   * @param {*} plan
   * @memberof SubscriptionPlansComponent
   */
  subscribePlan() {

    this.activePackage = new PackageInfo(this.selectedPackage.name, this.selectedPackage.packageType, this.selectedPackage.supportedModules, this.selectedPackage.costPerMonth);
    this.subscriptionDetail = {
      startDate: new Date(),
      endDate: this.calculationService.extendDate(new Date(), 30),
      active: true,
      activePackage: this.activePackage
    }
    this.packagesService.create(this.subscriptionDetail, null, AppUrls.SUBSCRIBE).subscribe((response) => {
      this.showConfirmationDialog = false;
      localStorage.setItem('subscriptionActive', "true");
      localStorage.setItem('subscribedPackage', JSON.stringify(this.activePackage));
      this.notificationService.success(this.activePackage.name + " " + NotificationsMessages.PLAN_SUBSCRIBED_SUCCESSFULLY, NotificationsMessages.PLAN_SUBSCRIBED)
      this.packagesService.$packageSubscribed.next(true);

      this.router.navigate(['/'])
    },
      (error) => {
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          this.notificationService.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
        } else {
          this.notificationService.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
        }
      })
  }


  /**
    * @description on subscription package buy button clicked
    * @author Raveena Nathani
    * @date 2020-06-04
    * @param {*} plan
    * @memberof SubscriptionPlansComponent
    */
  onSubcriptionBuy(plan) {
    this.selectedPackage = plan;
    this.confirmationDialogHeader = this.selectedPackage.name + ' Plan Confirmation'
    this.showConfirmationDialog = true;
  }



  /**
   * @description on confirmation dialog close
   * @author Raveena Nathani
   * @date 2020-06-04
   * @memberof SubscriptionPlansComponent
   */
  closeConfirmationDialog() {
    this.showConfirmationDialog = false;
  }

}
