<div class="activityGrid">
    <div class="padding-0">
        <sib-grid [title]="'Activity Logs'" [showFilter]="true" name="activityGrid" #sibGrid [buttons]="buttonConfig"
            [totalRecords]="totalRecords" (eEmitColumnSelection)="updatingUserGridConfig($event)"
            (eRowExpand)="onRowExpand($event)" [columns]="columns" [config]="gridConfig"
            [globalFilterFields]="globalFilterFields">

            <sib-grid-column field="srNo">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span>
                        {{rowIndex+1}}
                    </span>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="displayKey">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span *ngIf="rowData?.changes?.additionalDescription !== null">
                        <a (click)="openDescription(rowData)">{{rowData.displayKey}}</a> -
                        {{rowData.changes.additionalDescription}}
                    </span>
                    <span *ngIf="rowData?.changes?.additionalDescription === null">
                        <a (click)="openDescription(rowData)">{{rowData.displayKey}}</a>
                    </span>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="changes.description">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span [innerHTML]="rowData.changes.description">
                    </span>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="module">
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown [options]="moduleDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
                        [(ngModel)]="selectedMod" placeholder="Module" (onChange)="onModuleChange()"></p-dropdown>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="actionEvent">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span *ngIf="rowData?.actionEvent && rowData?.displayKey">
                        {{getActionEvent(rowData.actionEvent)}} - <a
                            (click)="openDescription(rowData)">{{rowData.displayKey}}</a>
                        <span>{{getAdditionalDescription(rowData)}}</span>
                    </span>
                    <span *ngIf="rowData?.actionEvent && !rowData?.displayKey">
                        {{getActionEvent(rowData.actionEvent)}}
                    </span>
                </ng-template>

                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown [options]="actionEventDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                        appendTo="body" [(ngModel)]="selectedAction" placeholder="Action"
                        (onChange)="onActionEventChange()"></p-dropdown>
                </ng-template>
            </sib-grid-column>

            <ng-template let-rowData let-rowIndex="rowIndex" mtabGridTemplate="rowExpansion">
                <div class="ui-grid ui-grid-responsive ui-fluid" style="background-color: #EDEEF4; width:100%"
                    *ngIf="activityLogs[rowIndex]?.changes?.length > 0">
                    <!-- class="activityChangeContainer" -->
                    <table style="border-collapse: separate;">
                        <tr>
                            <th style="width:10%; text-align: left; padding-left: 13px"></th>
                            <th style="width:20%; text-align: left; padding-left: 13px">Field Name</th>
                            <th style="width:20%; text-align: left; padding-left: 13px">Old Value</th>
                            <th style="width:20%; text-align: left; padding-left: 13px">New Value</th>
                            <th style="width:30%; text-align: left; padding-left: 13px">Description</th>
                        </tr>
                        <ng-template let-change let-last="last" ngFor [ngForOf]="changes">
                            <tr>
                                <td style="width:10%; text-align: left"></td>
                                <td style="width:20%; text-align: left">{{change.fieldName}}</td>
                                <td style="width:20%; text-align: left">
                                    {{formatValueBasedOnType(change.oldValue, change.formatterType)}}
                                </td>
                                <td style="width:20%; text-align: left">
                                    {{formatValueBasedOnType(change.newValue, change.formatterType)}}
                                </td>
                                <td style="width:30%; text-align: left">
                                    <!-- <span *ngIf="change?.additionalDescription !== null">
                                        <a (click)="openDescription(change, rowIndex)">{{displayKey}}</a> -
                                        {{change.additionalDescription}}
                                    </span>
                                    <span *ngIf="change?.additionalDescription === null">
                                        <a (click)="openDescription(change, rowIndex)">{{displayKey}}</a>
                                    </span> -->
                                    <span *ngIf="change?.description !== null">
                                        <!-- {{displayKey}} - {{change.additionalDescription}} -->
                                        {{change.description}}
                                    </span>
                                    <span *ngIf="change?.description === null">
                                        <!-- {{displayKey}} -->
                                        -
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </ng-template>

        </sib-grid>
    </div>
</div>