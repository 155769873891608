import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { SuperbillChartComponent } from "./superbill-chart.component";
import { SibChartModule } from "../../sib-chart/sib-chart.module";
import {
  DropdownModule,
  TooltipModule,
  OverlayPanelModule,
  ProgressSpinnerModule,
} from "primeng/primeng";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    RouterModule,
    SibChartModule,
    FormsModule,
    DropdownModule,
    TooltipModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
  ],

  declarations: [SuperbillChartComponent],

  exports: [SuperbillChartComponent],

  providers: [],
})
export class SuperbillChartModule {}
