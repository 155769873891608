import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { BillingUIService } from '../../../billings/services/billingUI.service';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { GridColumn, GridConfig, SelectionMode, GridPaginationEvent, EditableGridFieldTypes, Alignment } from '../../../components/sib-forms/grid/grid.config';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, EMPTY as empty, Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { CreditNote } from '../../../modals/billings/credit-note';
import { DateUtil } from '../../../helpers/date.util';
import { InvoiceStatus } from '../../constants/invoice-status-enum';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import * as _ from "lodash";
import * as utils from '../../../helpers/utils';
import { GridNameEnum } from '../../constants/grid-name.enum';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { SelectItem, OverlayPanel, Dropdown } from 'primeng/primeng';
import { StatusVisualizationNode } from '../../../modals/billings/status-visualization-node';
import { map } from 'rxjs/operators';
import { AppUrls } from '../../../services/urls';
import { PaginationData } from '../../../modals/paginationdata';
import { InvoiceCharEnum } from '../../../modals/billings/invoice-char-enum';
import { SIBConstants } from '../../constants/SIBConstant';
import { CreditNotesService } from '../../../services/shared/credit-note.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PPTLoader } from '../../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { ReserveCreditNotesComponent } from '../../../billings/reserve-credit-notes/reserve-credit-notes.component';
import { PrintConfig } from '../../../modals/billings/print-config';
import { CommonService } from '../../../services/shared/common.service';
import { CustomerUiService } from '../../../customers/services/customer-ui.service';
import { InvoiceService } from '../../../services/shared/invoice.service';
import * as $ from 'jquery';
import { ErrorUtil } from '../../../helpers/error.utils';
import { NotificationsMessages } from '../../../services/shared/notifications-messages';

@Component({
  selector: 'sib-credit-notes',
  templateUrl: './credit-notes.component.html',
  styleUrls: ['./credit-notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreditNotesComponent implements OnInit {

  _showEmployee: boolean;
  @Input() set showEmployee(showEmployee) {
    this._showEmployee = showEmployee;
    this.setColumns();
  }

  get showEmployee() {
    return this._showEmployee;
  }

  _campaignId: string = null;
  @Input() set campaignId(campaignId) {
    this._campaignId = campaignId;
  }

  get campaignId() {
    return this._campaignId;
  }

  @Input() showXlsExport = false;

  @Input() showTab: boolean = false;  // variable for showing the tabs or not

  @ViewChild('creditNoteGrid')
  creditNoteGridViewChildRef: GridComponent<CreditNote>;

  @ViewChild(ReserveCreditNotesComponent) reserveCreditNotesComponent: ReserveCreditNotesComponent;
  @ViewChild('op')
  overlay: OverlayPanel;

  @ViewChild('printMenu')
  printOverlay: OverlayPanel;

  @ViewChild('printedDropDown') printedDropDown: Dropdown;

  @ViewChild('tallyEnteredDropDown') tallyEnteredDropDown: Dropdown;

  @ViewChild('statusDropDown') statusDropDown: Dropdown;

  isLoading = false;
  creditNoteGroup: FormGroup;
  cnStatusDropdown: any[] = [];
  printDropdown: SelectItem[] = [];
  tallyEntryDropdown: SelectItem[] = [];
  statusVisualizationNodes: StatusVisualizationNode[] = [];
  rowData: any;
  rowIndex: number;
  safePdfUrl: SafeResourceUrl;
  safePdfName: string;
  currentDate = new Date();
  activeIndex: number = 0;
  // to check in which flow the invoice is present
  generateFlowStatus = [];
  editFlowStatus = [];
  cancelFlowStatus = [];
  showTitle: boolean = false;
  showAction: boolean = false;
  // to check with whom the invoice is pending
  srPendingStatus = [];
  adminPendingStatus = [];
  accountantPendingStatus = [];
  flowCompleteWithApprovalStatus = [];
  flowCompleteWithRejectionStatus = [];
  invoicePrintedCheckboxVisibleStatus: any[] = [];
  invoiceTallyEnteredCheckboxVisibleStatus: any[] = [];

  // to set the values of visualization chars and titles
  pendingAt: any[] = [];
  charAt: any[] = [];
  charTitleAt: any[] = [];

  // for printing options
  isDuplicate = false;
  isWithoutLetterHead = false; // for with/without letterhead switch

  invoicePrinted: Map<string, boolean> = new Map();
  invoiceTallyEntered: Map<string, boolean> = new Map();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    multiSortMetas: [],
    sortOrder: undefined,
    sortField: undefined
  };
  // searchEvent: GridPaginationEvent;
  creditNotes: CreditNote[] = [];
  rowsPerPage: number;
  totalRecords = 100;
  customFilterApplied = false; // for dropdown filters
  customFilter: any;

  isFileDownloading = false;
  xlsLoader: PPTLoader = new PPTLoader();

  buttonConfig: GridActionButtonConfig;
  gridConfig: GridConfig<CreditNote> = new GridConfig<CreditNote>();

  selectedInvoiceFormat: any;
  preview = false; // for printing
  userRole: string;
  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    /* {
        field: 'id',
        name: 'id',
        header: 'Invoice',
        required: true,
        width: '6vw',
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: true,
        styleClass: 'word-break-wrap ',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
    }, */
    {
      field: 'invoiceId',
      name: 'invoiceId',
      header: 'Credit Note',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'invoiceReferenceId',
      name: 'invoiceReferenceId',
      header: 'Invoice',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    // {
    //   field: 'campaign.customer.company',
    //   name: 'campaign.customer.company',
    //   header: 'Company',
    //   required: true,
    //   width: '7vw',
    //   editable: false,
    //   default: false,
    //   hidden: false,
    //   permanent: false,
    //   sortable: true,
    //   styleClass: 'word-break-wrap ',
    //   type: EditableGridFieldTypes.TEXT,
    //   filter: {
    //     matchMode: 'contains',
    //     placeHolder: 'Search',
    //     custom: false,
    //   },
    //   displayFn: (data) => {
    //     if (data.campaign.customer.company) {
    //       return data.campaign.customer.company;
    //     } else {
    //       return '-';
    //     }
    //   },
    // },
    {
      field: 'campaign.customer.billingName',
      name: 'campaign.customer.billingName',
      header: 'Billing Name',
      required: true,
      width: '7vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.customer.billingName) {
          return data.campaign.customer.billingName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaign.displayName',
      name: 'campaign.displayName',
      header: 'Display Name',
      required: true,
      width: '8vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.displayName) {
          return data.campaign.displayName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaign.employee.fullName',
      name: 'employee.fullName',
      header: 'Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      // hidden: !this.showEmployee,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign.employee) {
          return data.campaign.employee.fullName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billGeneratedDate',
      name: 'billGeneratedDate',
      header: 'Bill Generated Date',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billGeneratedDate) {
          return DateUtil.dategridFormatter(data.billGeneratedDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billStartDate',
      name: 'billStartDate',
      header: 'Bill Start Date',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billStartDate) {
          return DateUtil.dategridFormatter(data.billStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billEndDate',
      name: 'billEndDate',
      header: 'Bill End Date',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billEndDate) {
          return DateUtil.dategridFormatter(data.billEndDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'createdDate',
      name: 'createdDate',
      header: 'CN Gen Date',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data && data.createdDate) {
          return DateUtil.dategridFormatter(data.createdDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'status',
      name: 'status',
      header: 'Status',
      required: true,
      width: '12vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'min-width-10',
      type: EditableGridFieldTypes.CUSTOM,
      // align: Alignment.LEFT,
      filter: {
        matchMode: 'is',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.status) {
          return InvoiceStatus[data.status];
        } else {
          return '-';
        }
      },
    },
    {
      field: 'rentAmount',
      name: 'rentAmount',
      header: 'Rent Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.rentAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.rentAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'printAmount',
      name: 'printAmount',
      header: 'Print Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.printAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.printAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'mountAmount',
      name: 'mountAmount',
      header: 'Mount Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.mountAmount !== null) {
          return this.currencyPipe.transform(this.getDecimalNum(data.mountAmount, 2));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'totalAmount',
      name: 'totalAmount',
      header: 'Bill Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.totalAmount !== null) {
          return this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.totalAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'lspDiff',
      name: 'lspDiff',
      header: 'LSP Difference',
      required: true,
      width: '6vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.lspDiff !== null) {
          return this.currencyPipe.transform(data.lspDiff);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaignDiff',
      name: 'campaignDiff',
      header: 'Campaign Difference',
      required: true,
      width: '7vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'relational',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.campaignDiff !== null) {
          return this.currencyPipe.transform(data.campaignDiff);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'printed',
      name: 'printed',
      header: 'Printed',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.printed !== null && data.printed !== undefined) {
          return data.printed;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'markAsPrinted',
      name: 'markAsPrinted',
      header: 'Mark Print',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      // hidden: false,
      // permanent: false,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
    },
    {
      field: 'printInvoice',
      name: 'printInvoice',
      header: 'Print',
      required: true,
      width: '4vw',
      editable: false,
      default: false,
      // hidden: false,
      // permanent: false,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
    },
    {
      field: 'tallyEntered',
      name: 'tallyEntered',
      header: 'Tally Entry',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.tallyEntered !== null && data.tallyEntered !== undefined) {
          return data.tallyEntered;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'markAsTallyEntered',
      name: 'markAsTallyEntered',
      header: 'Mark Tally',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      // hidden: false,
      // permanent: false,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
    },
    // commented for changes in tally invoice id for vendor - chitra
    // {
    //     field: 'tallyInvoiceId',
    //     name: 'tallyInvoice',
    //     header: 'Tally Invoice',
    //     required: true,
    //     width: '6vw',
    //     editable: false,
    //     default: false,
    //     hidden: false,
    //     permanent: false,
    //     sortable: true,
    //     styleClass: 'word-break-wrap ',
    //     type: EditableGridFieldTypes.TEXT,
    //     filter: {
    //         matchMode: 'contains',
    //         placeHolder: 'Search',
    //         custom: false,
    //     },
    //     displayFn: (data) => {
    //         if (data.tallyInvoiceId !== null && data.tallyInvoiceId !== undefined) {
    //             return data.tallyInvoiceId;
    //         } else {
    //             return '-';
    //         }
    //     },
    // },
    {
      field: 'purchaseOrders',
      name: 'purchaseOrders',
      header: 'PO',
      required: true,
      width: '4vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM
    },
    // {
    //   field: 'details',
    //   name: 'details',
    //   header: 'Details',
    //   required: true,
    //   width: '4vw',
    //   editable: false,
    //   default: true,
    //   hidden: false,
    //   permanent: true,
    //   sortable: false,
    //   type: EditableGridFieldTypes.CUSTOM
    // },
  ];

  constructor(
    private notificationServcie: NotificatoinsService,
    private billingUIService: BillingUIService,
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private creditNoteService: CreditNotesService,
    private sanitizer: DomSanitizer,
    private loaderSubjects: LoaderSubjects,
    private commonService: CommonService,
    private customerUiService: CustomerUiService,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.userRole = this.customerUiService.getUserRole();
    this.setUserGridConfig();
    this.setFilterDropdowns();
    this.setFlows();
    this.setFormGroupObjects();
    this.setGridConfigObject();
    this.setColumns();
  }

  setUserGridConfig() {
    this.userGridConfigService.create([GridNameEnum['INVOICE']])
      .subscribe((creditNoteListGridConfig) => {
        this.columns = utils.getUserConfiguredColumns(creditNoteListGridConfig[GridNameEnum['INVOICE']], this.columns);
        this.creditNoteGridViewChildRef.setAllColumns();
      });
  }

  setFormGroupObjects() {
    this.creditNoteGroup = new FormGroup({
      creditNoteSiteGridControl: new FormControl()
    });
  }

  setFilterDropdowns() {
    this.setPrintDropdown();
    this.setTallyEntryDropdown();
    this.setCNStatusDropdown();
  }

  setPrintDropdown() {
    this.printDropdown = [
      { label: "All", value: "ALL" },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];
  }

  setTallyEntryDropdown() {
    this.tallyEntryDropdown = [
      { label: "All", value: "ALL" },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];
  }

  setCNStatusDropdown() {
    this.cnStatusDropdown = utils.createDropdown(InvoiceStatus, true);
    this.cnStatusDropdown = this.cnStatusDropdown.filter(item => (item.label === InvoiceStatus.FULL_CN_APPROVED || item.label === InvoiceStatus.PARTIAL_CN_APPROVED || item.label === 'All'));
  }

  onPrintedFilterChange(value, field, matchMode) {
    this.setFilterValues(value, field, matchMode);
  }

  onTallyEnteredFilterChange(value, field, matchMode) {
    this.setFilterValues(value, field, matchMode);
  }

  setFilterValues(value, field, matchMode) {
    this.customFilterApplied = true;
    switch (value) {
      case 'ALL': {
        value = ["ALL"];
        matchMode = 'in';
        break;
      }
      case true: {
        value = ['T'];
        matchMode = 'in';
        break;
      }
      case false: {
        value = ['N', 'F'];
        matchMode = 'in';
        break;
      }
    }
    this.setCustomFilter(value, field, matchMode);
  }

  setCustomFilter(value, field, matchMode) {
    switch (field) {
      case "printed": {
        this.customFilter = { "printed": { value: value, matchMode: matchMode } };
        break;
      }
      case "tallyEntered": {
        this.customFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
        break;
      }
      case "markAsPrinted": {
        this.customFilter = { "printed": { value: value, matchMode: matchMode } };
        break;
      }
      case "markAsTallyEntered": {
        this.customFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
      }
    }
    this.creditNoteGridViewChildRef.refresh();
  }

  setFlows() {
    this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
    this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
    this.cancelFlowStatus = ['ADMIN_CANCEL_PENDING', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'ADMIN_FULL_CN_PENDING', 'PARTIAL_CN_APPLIED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
    this.srPendingStatus = ['EDIT_PENDING'];
    this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ADMIN_CANCEL_PENDING', 'ADMIN_FULL_CN_PENDING'];
    this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];
    this.flowCompleteWithApprovalStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_EDIT_APPROVE', 'BILL_GENERATED', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
    this.flowCompleteWithRejectionStatus = ['ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'FULL_CN_REJECTED'];
    this.invoicePrintedCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'PARTIAL_CN_APPROVED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED'];
    this.invoiceTallyEnteredCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'PARTIAL_CN_APPROVED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED'];
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new CreditNote();
    this.gridConfig.model = CreditNote;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showColorCode = true;
    this.gridConfig.showRefreshIcon = true;

    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
      this.setPaginationEvent(paginationEvent);
      // this.searchEvent = paginationEvent;
      return this.creditNoteService.create(paginationEvent, paginationEvent, AppUrls.SEARCH).pipe(
        map((response) => {
          this.searchEvent = {
            filters: paginationEvent.filters,
            globalFilter: paginationEvent.globalFilter,
            multiSortMetas: paginationEvent.multiSortMetas,
            sortField: paginationEvent.sortField,
            sortOrder: paginationEvent.sortOrder
          };
          this.creditNotes = response['data']['content'];
          this.rowsPerPage = response["data"].size;
          this.invoicePrinted = new Map();
          this.invoiceTallyEntered = new Map();
          // Start - Divya 03-10-2019==================
          this.setInvoice();
          // this.setInvoicePrinted();
          // this.setInvoiceTallyEntered();
          // this.setPendingAt();
          // this.setCharAt();
          // End - Divya 03-10-2019===================
          this.setStatusVisualizationNode();
          this.totalRecords = response['data'].totalElements;
          return response['data']['content'];
        }));
    };
  }

  setInvoice() {
    this.pendingAt = [];
    this.charAt = [];
    this.charTitleAt = [];
    this.creditNotes.forEach((inv, index) => {
      this.setInvoicePrinted(inv);
      this.setInvoiceTallyEntered(inv);
      this.setPendingAt(inv, index);
      this.setCharAt(inv, index);
    })
  }

  setInvoicePrinted(inv) {
    // this.creditNotes.forEach((inv) => {
    (InvoiceCharEnum[inv.printed] === InvoiceCharEnum.F || InvoiceCharEnum[inv.printed] === InvoiceCharEnum.N) ? this.invoicePrinted.set(inv.id, false) : this.invoicePrinted.set(inv.id, true);
    // });
  }

  setInvoiceTallyEntered(inv) {
    // this.creditNotes.forEach((inv) => {
    (InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.F || InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.N) ? this.invoiceTallyEntered.set(inv.id, false) : this.invoiceTallyEntered.set(inv.id, true);
    // });
  }

  setPendingAt(invoice, index) {
    // this.pendingAt = [];
    // this.creditNotes.forEach((invoice, index) => {
    this.setBackground(invoice.status, index);
    // });
  }

  setCharAt(invoice, index) {
    // this.charAt = [];
    // this.charTitleAt = [];
    // this.creditNotes.forEach((invoice, index) => {
    this.setChar(invoice.status, index);
    // });
  }

  setBackground(status, index) {
    if (this.srPendingStatus.includes(status)) {
      this.pendingAt[index] = "SR";
    }
    if (this.adminPendingStatus.includes(status)) {
      this.pendingAt[index] = "ADM";
    }
    if (this.accountantPendingStatus.includes(status)) {
      this.pendingAt[index] = "ACC";
    }
    if (this.flowCompleteWithApprovalStatus.includes(status)) {
      this.pendingAt[index] = "APP";
    }
    if (this.flowCompleteWithRejectionStatus.includes(status)) {
      this.pendingAt[index] = "REJ";
    }
  }

  setChar(status, index) {
    if (this.generateFlowStatus.includes(status)) {
      this.charAt[index] = "G";
      this.charTitleAt[index] = SIBConstants.STATUS_GEN_ICON_TOOLTIP;
    }
    if (this.editFlowStatus.includes(status)) {
      this.charAt[index] = "E";
      this.charTitleAt[index] = SIBConstants.STATUS_MOD_ICON_TOOLTIP;
    }
    if (this.cancelFlowStatus.includes(status)) {
      this.charAt[index] = "C";
      this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
    }
  }

  setPaginationEvent(paginationEvent) {
    if (this.campaignId) {
      Object.assign(paginationEvent.filters, { "campaign._id": { value: this.campaignId, mathcMode: 'is' } });
    }
    if (this.customFilterApplied) {
      Object.assign(paginationEvent.filters, this.customFilter);
    }
    this.customFilterApplied = false;
  }

  getClassForCharacter(index) {
    switch (this.charAt[index]) {
      case 'G': {
        return "generate";
      }
      case 'E': {
        return "edit";
      }
      case 'C': {
        return "cancel";
      }
    }
  }

  setStatusVisualizationNode() {
    this.statusVisualizationNodes = [];
    this.statusVisualizationNodes = [
      { header: "SR", title: "Sales Representative" },
      // { header: "ACC", title: "Accountant" },
      { header: "ADM", title: "Admin" },
      { header: "CMP", title: " " }, // for APP or REJ
    ];
  }


  setColumns() {
    this.columns.filter((col) => {
      switch (col.field) {
        case 'campaign.employee.fullName': {
          col.permanent = !this.showEmployee;
          col.hidden = !this.showEmployee;
          break;
        }
        case 'markAsPrinted': {
          col.hidden = this.billingUIService.checkPermission('update:invoice-print') ? false : true;
          col.permanent = col.hidden;
          break;
        }
        case 'markAsTallyEntered': {
          col.hidden = this.billingUIService.checkPermission('update:tally-invoice') ? false : true;
          col.permanent = col.hidden;
          break;
        }
        case 'printInvoice': {
          col.hidden = this.billingUIService.checkPermission('print:invoice') ? false : true;
          col.permanent = col.hidden;
          break;
        }
        case 'campaign.displayName':{
          col.hidden = this.billingUIService.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
        case 'status': {
          col.filter = this.billingUIService.getBillingTabIndex() === '1' ? null : { matchMode: 'is', placeHolder: 'Search', custom: true, };
          break;
        }
      }
      return col;
    });
    if (this.creditNoteGridViewChildRef) {
      this.creditNoteGridViewChildRef.setAllColumns();
    }
  }

  checkPrintedStatus(status) {
    if (this.invoicePrintedCheckboxVisibleStatus.includes(status)) {
      return true;
    } else {
      return false;
    }
  }

  checkTallyEnteredStatus(status) {
    if (this.invoiceTallyEnteredCheckboxVisibleStatus.includes(status)) {
      return true;
    } else {
      return false;
    }
  }

  onMarkAsPrintedChange(row, event) {
    // this.invoiceService.markInvoiceAsPrinted((event) ? 'T' : 'F', row.id).subscribe((response) => {
    //     event ? this.notificationServcie.success("Invoice Marked As Printed", "Invoice Marked As Printed Successfully") : this.notificationServcie.success("Invoice Unmarked As Printed", "Invoice Unmarked As Printed Successfully");
    // this.creditNoteGridViewChildRef.refresh();
    // });
    this.isLoading = true;
    this.creditNoteService.markCNAsPrinted((event) ? 'T' : 'F', row.id).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          event ? this.notificationServcie.success("CN Marked As Printed", "CN Marked As Printed Successfully") : this.notificationServcie.success("CN Unmarked As Printed", "CN Unmarked As Printed Successfully");
          this.creditNoteGridViewChildRef.refresh();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  onMarkAsTallyEnteredChange(row, event) {
    // this.invoiceService.markInvoiceAsTallyEntered((event) ? 'T' : 'F', row.id, row.id).subscribe((response) => {
    //     event ? this.notificationServcie.success("Invoice Marked As Tally Entered", "Invoice Marked As Tally Entered Successfully") : this.notificationServcie.success("Invoice Unmarked As Tally Entered", "Invoice Unmarked As Tally Entered Successfully");
    //     this.creditNoteGridViewChildRef.refresh();
    // });
    this.isLoading = true;
    this.creditNoteService.markCNAsTallyEntered((event) ? 'T' : 'F', row.id, row.id).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          event ? this.notificationServcie.success("CN Marked As Tally Entered", "CN Marked As Tally Entered Successfully") : this.notificationServcie.success("CN Unmarked As Tally Entered", "CN Unmarked As Tally Entered Successfully");
          this.creditNoteGridViewChildRef.refresh();
          this.isLoading = false;
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  showOverlay($event, rowData, rowIndex) {
    this.rowData = rowData;
    this.rowIndex = rowIndex;
    this.overlay.show($event);
  }

  togglePrintMenu(event, rowData, rowIndex) {
    this.rowData = rowData;
    this.selectedInvoiceFormat = rowData.invoiceFormat;
    this.rowIndex = rowIndex;
    this.printOverlay.toggle(event);
  }

  setSelectedInvoiceFormat(event) {
    this.selectedInvoiceFormat = event;
    this.rowData.invoiceFormat = event;
  }

  onPrintTypeChange(event) {
    this.rowData.printConfiguration.original = event;
  }

  onPrintLetterHeadChange(event) {
    this.rowData.printConfiguration.withLetterHead = event;
  }

  savePrintingInstructions(printConfig: PrintConfig) {
    this.rowData.printConfiguration = printConfig;
    this.printOverlay.hide();
    this.commonService.saveBillingPrintConfig(this.rowData.printConfiguration, this.selectedInvoiceFormat, this.rowData.id, SIBConstants.CREDIT_NOTE_PARAM)
      .subscribe(
        (response) => {
          if (response) {
            this.notificationServcie.success('Configuration Saved Successfully', 'Configuration Saved');
          }
        },
        (error) => {
          this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        }
      );
  }

  previewPrintItems(printConfig: PrintConfig) {
    this.preview = true;
    this.printCn(printConfig);
  }

  printItems(printConfig: PrintConfig) {
    this.preview = false;
    this.printCn(printConfig);
  }

  printCn(printConfig: PrintConfig) {
    this.checkForAddBlock();
    this.creditNoteService.printCn(utils.createPrintWrapperObject(printConfig, this.rowData.invoiceFormat, this.preview, null), this.rowData.id)
      .subscribe(
        (response) => {
          if (response) {
            // console.log("response", response);
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
            window.open(url, "_blank").focus(); // need to disable adblock for this
          }
        },
        // (error) => {
        //   this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        //   this.isLoading = false;
        // }
        (error) => {
          const errorObject = ErrorUtil.getErrorObject(error);
          if (errorObject.code === 417 || errorObject.code === 412) {
            this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
          } else {
            this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
          }
        }
      );
  }

  checkForAddBlock() {
    if ($("#bottomAd").height() > 0) {
      // console.log("ad block inctive");
    } else {
      // console.log("ad block active");
      this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
    }
  }

  printingItem(isDuplicate, isWithoutLetterHead) {
    this.printOverlay.hide();
    if (isWithoutLetterHead) {
      this.printCNWithoutLetterHead(isDuplicate);
    } else {
      this.printCNWithletterHead(isDuplicate);
    }
  }

  printCNWithoutLetterHead(isDuplicate) {
    this.isLoading = true;
    this.creditNoteService.printCNWithoutLetterHead(this.rowData.id, isDuplicate).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
          this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;
          window.open(url, "_blank").focus(); // need to disable adblock for this
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  printCNWithletterHead(isDuplicate) {
    this.isLoading = true;
    this.creditNoteService.printCNWithLetterHead(this.rowData.id, isDuplicate).subscribe(
      (response) => {
        if (response) {
          // console.log("response", response);
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
          this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;
          window.open(url, "_blank").focus(); // need to disable adblock for this
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        this.isLoading = false;
      }
    );
  }

  exportXlsx() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader);
    this.loaderSubjects.creditNoteXlsLoader.next(this.xlsLoader);
    this.isFileDownloading = false;
  }

  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
  }

  updatingUserGridConfig(columnArray) {
    let creditNoteListGridConfig: Map<string, string> = new Map();
    creditNoteListGridConfig = utils.getGridConfigMap(columnArray, this.columns);
    this.userGridConfigService.patch(utils.buildObject(creditNoteListGridConfig), null, GridNameEnum['INVOICE'])
      .subscribe((result) => {
      });
  }

  getEncodedURI(id) {
    return encodeURIComponent(id);
  }

  parseValueToDecimal(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getAbsoluteValue(value) {
    return Number(Math.abs(Number(value)));
  }

  getDecimalNum(target, decimal) {
    return Number(Math.fround(target).toFixed(decimal));
  }

  resetStatus() {
    utils.resetDropDown(this.statusDropDown);
    utils.resetDropDown(this.printedDropDown);
    utils.resetDropDown(this.tallyEnteredDropDown);

  }

  onTabChange(event) {
    this.activeIndex = event.index;
    // this.activeIndex = JSON.parse(JSON.stringify(this.activeIndex));
    switch (this.activeIndex) {
      case 0: {
        setTimeout(() => {
          this.setGridConfigObject();
        }, 1000);
        // this.creditNoteGridViewChildRef.refresh();
        break;
      }
      case 1: {
        setTimeout(() => {
          this.reserveCreditNotesComponent.setGridConfigObject();
        }, 1000);
        // this.reserveCreditNotesComponent.gridViewChildRef.refresh();
        break;
      }
    }

  }

}
