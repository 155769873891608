import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { MediaTypeEnum } from '../../shared/constants/media-type-enum';
import { PaginationData } from '../paginationdata';

export class AdvancedSearch {
    areaIds: string[];
    cityIds: string[];
    location: string;
    medias: MediaTypeEnum[];
    categoryIds: string[];
    itemStartDate: Date;
    itemEndDate: Date;
    lightType: HoardingLightEnum[];
    customerIds: string[];
    searchEvent: PaginationData;
}