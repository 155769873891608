<div class="employee-table-container">

  <div>
    <ul class="cinline-block">
      <li class="input-switch" *ngIf="graphCustomization">
        <p-inputSwitch name='employeeTableInputSwitch' [(ngModel)]="employeeChartVisibility"
          (onChange)="onEmployeeChartVisibilityChange($event)">
        </p-inputSwitch>

      </li>
      <li class="margin-top-5">
        <p-calendar [styleClass]="'site-from-button-hm'" #endDateCalender dateFormat="ddMy" appendTo="body"
          [disabled]="!startDate || graphCustomization" [minDate]="startDate" placeholder=" Ex-01Jan18"
          (onInput)="onDateSelection()" (onSelect)="onDateSelection()" [(ngModel)]="endDate" [utc]="true"
          [showIcon]="true"></p-calendar>
      </li>
      <li class="sites-from-text">
        <div>End Date</div>
      </li>
      <li class="margin-top-5">
        <p-calendar [styleClass]="'site-from-button-hm'" #startDateCalender dateFormat="ddMy" appendTo="body"
          placeholder=" Ex-01Jan18" (onInput)="onDateSelection()" (onSelect)="onDateSelection()" [(ngModel)]="startDate"
          [utc]="true" [showIcon]="true" [disabled]="graphCustomization"></p-calendar>
      </li>
      <li class="sites-from-text">
        <div>Start Date</div>
      </li>
      <li>
        <h3 class="margin-6">Employee Sales Table</h3>
      </li>
    </ul>

    <div>
      <div *ngIf="isLoading" class="loader">
        <p-progressSpinner></p-progressSpinner>

      </div>
      <div class="chart-container">
        <sib-chart *ngIf="employeesChartData && employeesData.length > 0" #employeeChartRef [data]="employeesChartData"
          [tableChartConfig]="employeesChartConfig" [chartType]="'table'" [elementId]="chartElementId"
          (eEmitTableChartLoaded)="isLoading = false">
        </sib-chart>
        <div *ngIf="!employeesData.length  && !isLoading" class="no-sales-message">
          <h3> No sales of employees on current selected period.</h3>
        </div>
      </div>


    </div>

  </div>

</div>