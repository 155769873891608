import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { EditableGridFieldTypes, Alignment, GridConfig, GridColumn, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../helpers/date.util';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { FormGroup, FormControl } from '@angular/forms';
import { GridActionButtonConfig } from '../../components/sib-forms/grid/grid-action-button.config';
import * as utils from '../../helpers/utils';
import { Title } from '@angular/platform-browser';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { CreditNotesService } from '../../services/shared/credit-note.service';
import { AppUrls } from '../../services/urls';
import { ReservedCreditNoteMetadata } from '../../modals/billings/reserved-credit-note-metadata';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';
import { BillingUIService } from '../services/billingUI.service';
import { map } from 'rxjs/operators';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import { GridNameEnum } from '../../shared/constants/grid-name.enum';
import { UserGridConfigService } from '../../services/shared/user-grid-config.service';
import { ImageFolderConfig } from '../../modals/image/image-folder-config';
import { DocumentComponent } from '../../shared/components/documents/document.component';
import { DocumentService } from '../../shared/components/documents/services/document.service';
import { of as observableOf } from 'rxjs';

@Component({
    selector: 'sib-reserve-credit-notes',
    templateUrl: './reserve-credit-notes.component.html',
    styleUrls: ['./reserve-credit-notes.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ReserveCreditNotesComponent implements OnInit, AfterViewInit, OnDestroy {

    _showEmployee: boolean;
    userEmailId: any;
    utilized: any;
    @Input() set showEmployee(showEmployee) {
        this._showEmployee = showEmployee;
        this.setColumns();
    }

    get showEmployee() {
        return this._showEmployee;
    }

    @ViewChild('sibGrid')
    gridViewChildRef: GridComponent<ReservedCreditNoteMetadata>;
    @ViewChild("documentGrid") documentComponent: DocumentComponent;
    @Input() showTitle: boolean = true;
    @Input() showAction: boolean = true;

    isLoading = false;
    lastCNId: string = null;
    reservedCNs: ReservedCreditNoteMetadata[] = [];
    reserveCount = 0;
    rowData: ReservedCreditNoteMetadata = new ReservedCreditNoteMetadata(); // to store the row marked/unmarked as utilized
    title = "Reserved Credit Notes";
    totalRecords = 100;
    utilizedDropdown: SelectItem[] = [];
    displayReserveCNDetailDialog = false;
    isFileDownloading = false;
    xlsLoader: PPTLoader = new PPTLoader();
    searchEvent: any = {
        sortField: undefined,
        sortOrder: undefined,
        filters: {}
    };
    reservedCNGroup: FormGroup;
    gridConfig: GridConfig<ReservedCreditNoteMetadata> = new GridConfig<ReservedCreditNoteMetadata>();
    buttonConfig: GridActionButtonConfig;

    displayUploadDocumentDialog: boolean = false;
    folderConfig: ImageFolderConfig = new ImageFolderConfig();

    displayDocumentComponent: boolean = false;
    documentEdit: boolean = false;
    showDelete: boolean = false;

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'invoiceId',
            name: 'invoiceId',
            header: 'Credit Note',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'invoiceReferenceId',
            name: 'invoiceReferenceId',
            header: 'Invoice Id',
            required: true,
            editable: false,
            width: '10vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'utilized',
            name: 'utilized',
            header: 'Utilized',
            required: true,
            editable: false,
            // width: '4vw',
            hidden: false,
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true
            },
        },
        // {
        //     field: 'customer.company',
        //     name: 'customer.company',
        //     header: 'Company',
        //     required: true,
        //     editable: false,
        //     width: '7vw',
        //     permanent: false,
        //     hidden: false,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     displayFn: (data) => {
        //         if (data && data.customer && data.customer.company) {
        //             return data.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     },
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        // },
        {
            field: 'customer.billingName',
            name: 'customer.billingName',
            header: 'Billing Name',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.customer && data.customer.billingName) {
                    return data.customer.billingName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            editable: false,
            width: '10vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.displayName) {
                    return data.displayName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'reason',
            name: 'reason',
            header: 'Reason',
            required: true,
            editable: false,
            width: '8vw',
            hidden: false,
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.reason) {
                    return data.reason;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'employee.fullName',
            name: 'employee.fullName',
            header: 'Sales Rep',
            required: true,
            editable: false,
            width: '7vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.employee && data.employee.fullName) {
                    return data.employee.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'reservedBy.fullName',
            name: 'reservedBy',
            header: 'Reserved By',
            required: true,
            editable: false,
            width: '7vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.reservedBy) {
                    return data.reservedBy.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'tallyGeneratedDate',
            name: 'tallyGeneratedDate',
            header: 'CN Gen Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.tallyGeneratedDate) {
                    return DateUtil.dategridFormatter(data.tallyGeneratedDate) + ' (' + DateUtil.getTimeFromDate(data.tallyGeneratedDate) + ')';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'reservedDate',
            name: 'reservedDate',
            header: 'Reserved Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.reservedDate) {
                    return DateUtil.dategridFormatter(data.reservedDate) + ' (' + DateUtil.getTimeFromDate(data.reservedDate) + ')';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'utilizedBy.fullName',
            name: 'reservedBy',
            header: 'Utilized By',
            required: true,
            editable: false,
            width: '7vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.utilizedBy) {
                    return data.utilizedBy.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'utilizedDate',
            name: 'utilizedDate',
            header: 'Utilized Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.utilizedDate) {
                    return DateUtil.dategridFormatter(data.utilizedDate) + ' (' + DateUtil.getTimeFromDate(data.utilizedDate) + ')';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'uploadDoc',
            name: 'uploadDoc',
            header: '',
            permanent: true,
            sortable: false,
            hidden: false,
            required: true,
            editable: false,
            width: '4vw',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
        },
        {
            field: 'viewDoc',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '4vw',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
        {
            field: 'id',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '4vw',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
        {
            field: 'delete',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '50px',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
    ];

    constructor(
        private pageTitle: Title,
        private notificationServcie: NotificatoinsService,
        private creditNotesService: CreditNotesService,
        private invoiceChartService: InvoiceChartService,
        private currencyPipe: CurrencyHelperPipe,
        private loaderSubjects: LoaderSubjects,
        private billingUIService: BillingUIService,
        private customerUiService: CustomerUiService,
        private userGridConfigService: UserGridConfigService,
        private documentService: DocumentService,
        private confirmationService: ConfirmationService,
    ) { }

    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.RESERVE_CN_MENU) : this.pageTitle.setTitle(SIBConstants.RESERVE_CN_MENU + ' - ' + appTitle);
        this.userEmailId = this.customerUiService.getUserEmail();

        this.userGridConfigService.get(null, '/' + GridNameEnum['RESERVED_CREDIT_NOTES'])
            .subscribe((reservedCreditNotesGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(reservedCreditNotesGridConfig, this.columns);
                this.gridViewChildRef.ngOnInit();
            });

        if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) {
            this.showEmployee = true;
        } else {
            this.showEmployee = false;
        }

        this.setDeleteColumnVisibility();
        this.setUnutilizedDropdown();
        this.setFormGroupObjects();
        this.setGridConfigObject();
        this.getLastCNId();
        // this.getReservedCNs();
        // this.gridViewChildRef.refresh();
    }

    ngAfterViewInit() { }

    setUnutilizedDropdown() {
        if (this.showAction) {
            this.utilizedDropdown = utils.createTrueFalseDropdown(true);
        } else {
            this.utilizedDropdown.push({ label: 'True', value: true });
        }
    }

    setFormGroupObjects() {
        this.reservedCNGroup = new FormGroup({
            gridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new ReservedCreditNoteMetadata();
        this.gridConfig.model = ReservedCreditNoteMetadata;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = false;
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.scrollable = true;
        // this.gridConfig.sortField = 'updatedDate';
        // this.gridConfig.sortOrder = -1;
        // this.gridConfig.sortMode = 'single';
        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen;
            // setTimeout(() => { // to get the sorted value
            //     this.reservedCNs = this.gridViewChildRef.getDataTableInstance()['_value'];
            // }, 1);
            // return observableOf(this.reservedCNs);
            // this.isLoading = true;
            if (!this.checkPermission('readAll')) {
                this.utilized = true;
                Object.assign(paginationEvent.filters, { 'employee.emailId': { value: this.userEmailId, matchMode: 'is' } });
                Object.assign(paginationEvent.filters, { 'utilized': { value: this.utilized, matchMode: 'is' } });
            } else if (this.checkPermission('readAll') && this.showAction === false) {
                this.utilized = true;
                Object.assign(paginationEvent.filters, { 'utilized': { value: this.utilized, matchMode: 'is' } });
            }
            return this.creditNotesService.create(paginationEvent, paginationEvent, '/search/reservedCnMetadata').pipe(map(
                (response) => {
                    if (response) {
                        this.searchEvent = paginationEvent;
                        this.reservedCNs = response['data']['content'];
                        this.totalRecords = response['data']['totalElements'];
                        // this.gridViewChildRef.refresh();
                        this.isLoading = false;
                        return this.reservedCNs;
                    } else {
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.showErrorNotification(error);
                    this.isLoading = false;
                }
            ));
        };
    }

    onSort(event) {
        this.reservedCNs = this.gridViewChildRef.getDataTableInstance()['_value'];
        this.searchEvent.sortField = event.field;
        this.searchEvent.sortOrder = event.order;
    }

    onFilter(event) {
        this.reservedCNs = event.filteredValue;
        this.searchEvent.filters = this.setFiltersForSearchEvent(event.filters);
    }

    setFiltersForSearchEvent(filters) {
        const keys = Object.keys(filters);
        keys.forEach((key) => {
            if (filters[key].matchMode === "equals") {
                filters[key].matchMode = "is";
            }
        });
        return filters;
    }

    getLastCNId() {
        this.isLoading = true;
        this.creditNotesService.get(null, AppUrls.GET_LAST_CN_ID).subscribe(
            (response) => {
                if (response) {
                    this.lastCNId = response['data'];
                    this.isLoading = false;
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    // getReservedCNs() {
    //     this.isLoading = true;
    //     this.creditNotesService.get(null, AppUrls.FETCH + AppUrls.RESERVED_CN_METADATA).subscribe(
    //         (response) => {
    //             if (response) {
    //                 this.reservedCNs = response['data'];
    //                 this.totalRecords = this.reservedCNs.length;
    //                 this.gridViewChildRef.refresh();
    //                 this.isLoading = false;
    //             }
    //         },
    //         (error) => {
    //             this.showErrorNotification(error);
    //             this.isLoading = false;
    //         }
    //     );
    // }

    submitReserveCount() {
        this.isLoading = true;
        this.creditNotesService.get({ reservedCount: this.reserveCount }, AppUrls.RESERVE_CN_IDS).subscribe(
            (response) => {
                if (response) {
                    this.reserveCount = 0; // resetting the reserve count for display
                    this.showSuccessNotification('Invoices Reserved Successfully', 'Invoice Reservation');
                    // this.getReservedCNs();
                    this.gridViewChildRef.refresh();
                    this.getLastCNId();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    addReserveCNDetails(rowData) {
        this.rowData = JSON.parse(JSON.stringify(rowData));
        this.displayReserveCNDetailDialog = true;
    }

    toggleUtilized(reservedCreditNoteMetadata: ReservedCreditNoteMetadata) {
        this.isLoading = true;
        this.creditNotesService.create(reservedCreditNoteMetadata, null, AppUrls.MARK_UTILIZED_OR_NOT).subscribe(
            (response) => {
                if (response) {
                    this.hideCNDetailsDialog();
                    (reservedCreditNoteMetadata.utilized) ? this.showSuccessNotification('Marked as Utilized successfully', 'Mark CN As Utilized') : this.showSuccessNotification('Unmarked as Utilized successfully', 'Unmark CN As Utilized');
                    this.isLoading = false;
                }
            },
            (error) => {
                this.hideCNDetailsDialog();
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    exportXlsx() {
        this.isFileDownloading = true;
        this.createLoaderObject("XLS", this.xlsLoader);
        this.loaderSubjects.reservedCreditNoteXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.fileType = fileType;
        object.searchEvent = this.searchEvent;
    }

    saveCNDetails(rowData) {
        this.toggleUtilized(rowData);
        this.gridViewChildRef.refresh();
    }

    hideCNDetailsDialog() {
        this.displayReserveCNDetailDialog = false;
        // this.getReservedCNs();
        this.gridViewChildRef.refresh();
        this.getLastCNId();
    }

    showErrorNotification(error) {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
    }

    showSuccessNotification(message, header) {
        this.notificationServcie.success(message, header);
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    setColumns() {
        this.columns.filter((col) => {
            switch (col.field) {
                case 'employee.fullName': {
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
                case 'id': {
                    col.hidden = !this.showAction;
                    break;
                }
                case 'uploadDoc': {
                    col.hidden = !this.showAction;
                    break;
                }
                // case 'viewDoc': {
                //     col.hidden = !this.showAction;
                //     break;
                // }
                case 'delete': {
                    col.hidden = !this.showDelete && !this.showAction;
                    break;
                }
                case 'displayName': {
                    col.hidden = this.checkPermission('view:display-name') ? false : true;
                    col.permanent = col.hidden;
                    break;
                }
            }
            return col;
        });
        if (this.gridViewChildRef) {
            this.gridViewChildRef.setAllColumns();
        }
    }


    /**
     * opens the upload document 
     *
     * @param {*} row
     * @memberof ReserveCreditNotesComponent
     */
    uploadDoc(row) {
        this.setFolderConfig(row);
        this.displayUploadDocumentDialog = true;
    }

    viewDocs(row) {

    }

    updatingUserGridConfig(columnArray) {
        var reservedCreditNotesGridConfig: Map<string, string> = new Map();
        reservedCreditNotesGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(reservedCreditNotesGridConfig), null, GridNameEnum['RESERVED_CREDIT_NOTES'])
            .subscribe((result) => {
            })
    }


    /**
     * closes the upload document dialog
     *
     * @memberof ReserveCreditNotesComponent
     */
    hideUploadDocumentDialog() {
        this.displayUploadDocumentDialog = false;
    }

    /**
     * setting Folder configuration for file upload
     *
     * @param {*} row
     * @memberof ReserveCreditNotesComponent
     */
    setFolderConfig(row) {
        this.folderConfig.itemId = row.id;
        this.folderConfig.subModuleName = SIBConstants.RESERVE_CN;
        this.folderConfig.year = new Date(row.tallyGeneratedDate).getFullYear();
        this.folderConfig.month = DateUtil.setMonthName(new Date(row.tallyGeneratedDate).getMonth());
        this.folderConfig.moduleName = SIBConstants.BILLINGS + '/' + this.folderConfig.year + '/' + this.folderConfig.month + '/' + this.folderConfig.subModuleName;
    }

    /**
     * upload the files to the database
     *
     * @param {*} event
     * @memberof ReserveCreditNotesComponent
     */
    setUploadedFiles(event) {
        if (event && event.length > 0) {
            this.creditNotesService.create(event, null, AppUrls.RESERVED + AppUrls.UPLOAD_DOCUMENT + '/' + this.folderConfig.itemId).subscribe((response) => {
                // if (response) {
                this.notificationServcie.success('Document Uploaded Successfully', 'Document Upload');
                // this.hideUploadDocumentDialog();
                this.gridViewChildRef.refresh();
                // }

            }, (error) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Document Upload Unsuccessful', 'Document Upload');
            });
        }
    }

    viewDocuments(rowData) {
        this.rowData = rowData;
        this.displayDocumentComponent = true;
    }

    hideViewDocumentDialog() {
        this.displayDocumentComponent = false;
        this.documentEdit = false;
    }


    editPurchaseOrder(event) {
        if (event) {
            this.creditNotesService.patch(event.purchaseOrders, null, AppUrls.RESERVE + AppUrls.EDIT_DOCUMENT + '/' + event.id).subscribe((response) => {
                this.gridViewChildRef.refresh();
                this.notificationServcie.success('File Edited Successfully', 'File Editing');
            });
        }
    }

    deletePurchaseOrder(event) {
        if (event) {
            this.documentService.deleteReserveCNDocument(event.row.id, event.row.purchaseOrders.id).subscribe((response) => {
                this.gridViewChildRef.refresh();
            });
        }
    }

    /**
     * @description delete reserved credit note
     * @author Divya Sachan
     * @date 2019-10-17
     * @param {*} rowData
     * @memberof ReserveCreditNotesComponent
     */
    deleteReservedCN(rowData) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Reserved Credit Note?',
            accept: () => {
                this.isLoading = true;
                this.creditNotesService.delete(null, null, null, AppUrls.RESERVED_CN + AppUrls.DELETE + '/' + rowData.id).subscribe(
                    (response) => {
                        if (response) {
                            this.notificationServcie.success("Reserved Credit Note Deleted Successfully", "Reserved Credit Note Deletion");
                            // this.gridViewChildRef.refresh();
                            this.isLoading = false;
                            this.reservedCNs = this.reservedCNs.filter(reserveCN => reserveCN.id !== rowData.id); // updated the reservedCNs array
                            this.totalRecords = this.totalRecords - 1;
                            // Start ---> removed API call on reserveCN delete instead updated the reservedCNs array
                            this.gridConfig.lazy = false;
                            this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
                                return observableOf(this.reservedCNs);
                            }
                            this.gridViewChildRef.refresh();
                            this.setGridConfigObject();
                            // End
                        }
                    },
                    (error) => {
                        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Reserved Credit Note Deletion Unsuccessful', 'Reserved Credit Note Deletion');
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                // do nothing
            }
        });
    }

    /**
     * @description set delete column visibility
     * @author Divya Sachan
     * @date 2019-10-18
     * @memberof ReserveCreditNotesComponent
     */
    setDeleteColumnVisibility() {
        if (this.checkPermission('delete:reserved-creditNote')) {
            this.showDelete = true;
        } else {
            this.showDelete = false;
        }

    }

    ngOnDestroy() { }

}
