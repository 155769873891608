import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";

@Injectable()
export class SupplierVendorService extends ApiService<any, any, any> {
  urlPath: string = "supplier/vendor";
  baseUrl: string;

  constructor(private Http: HttpClient, private Injector: Injector) {
    super(Http, Injector);
    this.baseUrl = this.Injector.get(BASE_URL);
  }
}
