/**
* @file  CampaignState 
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { ActionExtended } from "../../shared/actionextended";
import { Campaign } from "../../modals/campaigns/campaign";
import { CampaignActions } from "../actions/campaign-actions";
import * as _ from 'lodash';
import { CampaignItem } from "../../modals/campaigns/campaignItem";


export class CampaignState {

    campaigns: Campaign[] = [];
    campaign: Campaign;
    campaignItems: CampaignItem[] = [];
    isCampaignCreated = false;
    isCampaignReverted = false;
    createdPlanId = '';
    isCampaignRejected = false;
    isError = false;
    isCampaignUpdated = false;
    isCampaignApproved = false;
    isCampaignDeleted = false;
    isItemDeleted = false;
    isMonitoringRemoved = false;
    isMountingRemoved = false;
    isMountingEdited = false;
    isUnmountingEdited = false;
    isUnmountingRemoved = false;
    isMonitoringSet = false;
    isMonitoringUpdated = false;
    isAdditionalPriceAdded = false;
    isImageUploaded = false;
    isPOUploaded = false;
    isImageUpdated = false;
}

const initialState: CampaignState = new CampaignState();

export function campaignReducer(state = initialState, action: ActionExtended) {

    switch (action.type) {

        case CampaignActions.SET_CAMPAIGN: {
            return Object.assign({}, state, { campaign: action.payload, isCampaignCreated: true });
        }

        case CampaignActions.REVERT_TO_PLAN_SUCCESS: {
            return Object.assign({}, state, { createdPlanId: action.payload.id });
        }

        case CampaignActions.REVERT_TO_PLAN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.ROLLBACK_TO_PLAN_SUCCESS: {
            return Object.assign({}, state, { createdPlanId: action.payload.id });
        }

        case CampaignActions.ROLLBACK_TO_PLAN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_REVERTED: {
            return Object.assign({}, state, { isCampaignReverted: false });
        }

        case CampaignActions.RESET_CREATED_PLAN_ID: {
            return Object.assign({}, state, { createdPlanId: '' });
        }

        case CampaignActions.REJECT_CAMPAIGN_SUCCESS: {
            return Object.assign({}, state, { isCampaignRejected: true });
        }

        case CampaignActions.REJECT_CAMPAIGN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_REJECTED: {
            return Object.assign({}, state, { isCampaignRejected: false });
        }

        case CampaignActions.SET_MONITORING_SUCCESS: {
            return Object.assign({}, state, { isMonitoringSet: true });
        }

        case CampaignActions.SET_MONITORING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.UPDATE_MONITORING_SUCCESS: {
            return Object.assign({}, state, { isMonitoringUpdated: true });
        }

        case CampaignActions.UPDATE_MONITORING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.REMOVE_MONITORING_SUCCESS: {
            return Object.assign({}, state, { isMonitoringRemoved: true });
        }

        case CampaignActions.REMOVE_MONITORING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }


        case CampaignActions.REMOVE_MOUNTING_SUCCESS: {
            return Object.assign({}, state, { isMountingRemoved: true });
        }

        case CampaignActions.REMOVE_MOUNTING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.EDIT_MOUNTING_ITEM_SUCCESS: {
            return Object.assign({}, state, { isMountingEdited: true });
        }

        case CampaignActions.EDIT_MOUNTING_ITEM_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_MOUNTING_EDITED: {
            return Object.assign({}, state, { isMountingEdited: false });
        }


        case CampaignActions.REMOVE_UNMOUNTING_SUCCESS: {
            return Object.assign({}, state, { isUnmountingRemoved: true });
        }

        case CampaignActions.REMOVE_UNMOUNTING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.EDIT_UNMOUNTING_ITEM_SUCCESS: {
            return Object.assign({}, state, { isUnmountingEdited: true });
        }

        case CampaignActions.EDIT_UNMOUNTING_ITEM_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_UNMOUNTING_EDITED: {
            return Object.assign({}, state, { isUnmountingEdited: false });
        }



        case CampaignActions.UPDATE_CAMPAIGN_SUCCESS: {
            // console.log("inisde campaign update success");
            return Object.assign({}, state, { campaign: action.payload, isCampaignUpdated: true });
        }

        case CampaignActions.UPDATE_CAMPAIGN_FAILURE: {
            // console.log("inside campaign update failure reducer");
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_CREATED: {
            return Object.assign({}, state, { isCampaignCreated: false });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_UPDATED: {
            return Object.assign({}, state, { isCampaignUpdated: false });
        }

        case CampaignActions.RESET_ERROR: {
            return Object.assign({}, state, { isError: false });
        }

        case CampaignActions.UPLOAD_IMAGE_SUCCESS: {
            return Object.assign({}, state, { isImageUploaded: true });
        }

        case CampaignActions.UPLOAD_IMAGE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.UPLOAD_MOUNTING_IMAGE_SUCCESS: {
            return Object.assign({}, state, { isImageUploaded: true });
        }

        case CampaignActions.UPLOAD_MOUNTING_IMAGE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.UPDATE_MOUNTING_IMAGE_SUCCESS:{
            return Object.assign({},state,{isImageUpdated:true});
        }

        case CampaignActions.UPDATE_MOUNTING_IMAGE_FAILURE:{
            return Object.assign({},state,{isImageUpdated:false});
        }

        case CampaignActions.RESET_IS_IMAGE_UPLOADED: {
            return Object.assign({}, state, { isImageUploaded: false });
        }

        case CampaignActions.APPROVE_CAMPAIGN_SUCCESS: {
            return Object.assign({}, state, { isCampaignApproved: true });
        }

        case CampaignActions.APPROVE_CAMPAIGN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_APPROVED: {
            return Object.assign({}, state, { isCampaignApproved: false });
        }

        case CampaignActions.DELETE_CAMPAIGN_SUCCESS: {
            return Object.assign({}, state, { isCampaignDeleted: true });
        }

        case CampaignActions.DELETE_CAMPAIGN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.DELETE_ITEM_SUCCESS: {
            return Object.assign({}, state, { isItemDeleted: true });
        }

        case CampaignActions.DELETE_ITEM_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_CAMPAIGN_DELETED: {
            return Object.assign({}, state, { isCampaignDeleted: false });
        }

        case CampaignActions.RESET_IS_ITEM_DELETED: {
            return Object.assign({}, state, { isItemDeleted: false });
        }

        case CampaignActions.RESET_IS_MONITORING_REMOVED: {
            return Object.assign({}, state, { isMonitoringRemoved: false });
        }

        case CampaignActions.RESET_IS_MOUNTING_REMOVED: {
            return Object.assign({}, state, { isMountingRemoved: false });
        }

        case CampaignActions.RESET_IS_MONITORING_SET: {
            return Object.assign({}, state, { isMonitoringSet: false });
        }

        case CampaignActions.RESET_IS_MONITORING_UPDATED: {
            return Object.assign({}, state, { isMonitoringUpdated: false });
        }

        case CampaignActions.SET_ADDITIONAL_PRICE_SUCCESS: {
            return Object.assign({}, state, { isAdditionalPriceAdded: true });
        }

        case CampaignActions.SET_ADDITIONAL_PRICE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_ADDITIONAL_PRICE_ADDED: {
            return Object.assign({}, state, { isAdditionalPriceAdded: false });
        }

        case CampaignActions.TEMP_SAVE_CAMPAIGN: {
            // console.log("temp save reducers");
            return Object.assign({}, state, { campaignItems: action.payload, isCampaignUpdated: true });
        }

        case CampaignActions.RESET_TEMP_SAVE_CAMPAIGN: {
            return Object.assign({}, state, { campaignItems: [] });
        }

        case CampaignActions.ADD_CAMPAIGN_ITEMS: {
            return Object.assign({}, state, { isCampaignUpdated: false });
        }

        case CampaignActions.ADD_CAMPAIGN_ITEMS_SUCCESS: {
            return Object.assign({}, state, { isCampaignUpdated: true });
        }

        case CampaignActions.ADD_CAMPAIGN_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case CampaignActions.RESET_IS_PO_UPLOADED: {
            return Object.assign({}, state, { isPOUploaded: false });
        }

        case CampaignActions.UPLOAD_PO_SUCCESS: {
            return Object.assign({}, state, { isPOUploaded: true });
        }

        case CampaignActions.UPLOAD_PO_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        default: {
            return state;
        }
    }
}

