import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { Dropdown, OverlayPanel, SelectItem } from "primeng/primeng";
import { empty as observableEmpty, Subscription } from "rxjs";
import { catchError, concat, map, take } from "rxjs/operators";
import { AppUrls } from "../../../services/urls";
import { GridComponent } from "../../../components/sib-forms/grid/grid.component";
import {
  Alignment,
  EditableGridFieldTypes,
  GridColumn,
  GridConfig,
  GridPaginationEvent,
  SelectionMode,
} from "../../../components/sib-forms/grid/grid.config";
import { HoardingService } from "../../../contracts/services/hoardings.service";
import { DateUtil } from "../../../helpers/date.util";
import * as utils from "../../../helpers/utils";
import { HoardingFunctionalService } from "../../../hoardings/services/hoarding-functional.service";
import { ContractHoardings } from "../../../modals/contracts/contract-hoarding";
import { AuthorityTax } from "../../../modals/hoardings/authority-tax";
import { Inventory } from "../../../modals/hoardings/inventory";
import { ImageFolderConfig } from "../../../modals/image/image-folder-config";
import { LoaderSubjects } from "../../../modals/loader-subjects/loader-subjects";
import { PPTLoader } from "../../../modals/loader-subjects/pptloader";
import { MapMarker } from "../../../modals/map/MapMarker";
import { PaginationData } from "../../../modals/paginationdata";
import { PlanTemplate } from "../../../modals/plans/planTemplate";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { HoardingMasterColService } from "../../../services/shared/hoarding-master-column.service";
import { InventoryService } from "../../../services/shared/inventory.service";
import { UploadDocumentService } from "../../../services/shared/upload-document.service";
import { UserGridConfigService } from "../../../services/shared/user-grid-config.service";
import { GridNameEnum } from "../../constants/grid-name.enum";
import { HoardingCategoryEnum } from "../../constants/hoarding-category-enum";
import { HoardingLightEnum } from "../../constants/hoarding-light-enum";
import { HoardingStatusEnum } from "../../constants/hoarding-status-enum";
import { InventoryTypeEnum } from "../../constants/inventory-type.enum";
import { MediaSubCategoryEnum } from "../../constants/media-sub-category-enum";
import { MediaTypeEnum } from "../../constants/media-type-enum";
import { CurrencyHelperPipe } from "../../helpers/currency.pipe/currencyHelperPipe";
import { PumpCategoryEnumLocal } from "../common-inventory/common-inventory.component";
import { AdvancedSearch } from "../../../modals/hoardings/advanced-search";
import { SupplierVendorService } from "../../../contracts/services/supplier-vendor.service";

@Component({
  selector: "sib-inventory-grid",
  templateUrl: "./inventory-grid.component.html",
  styleUrls: ["./inventory-grid.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryGridComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Inventory>;
  @ViewChild("doc") overlay: OverlayPanel;
  @ViewChild("ratingdd") ratingDd: Dropdown;
  @ViewChild("categorydd") categoryDd: Dropdown;
  @ViewChild("lighttypedd") lightTypeDd: Dropdown;
  @ViewChild("inventorytypedd") inventoryTypedd: Dropdown;
  @ViewChild("tradedassetdd") tradedAssetDd: Dropdown;
  @ViewChild("pumpCategorydd") pumpCategorydd: Dropdown;

  @Input() contractId: string;
  @Input() columns: any[] = [];
  @Input() showFilter: boolean = false;
  @Input() sitesAvailableFrom = false;
  @Input() totalRecords;
  @Input() status: SelectItem[] = [];
  @Input() gridTitle: string = "";
  _src: string = null;
  @Input() set source(source) {
    this._src = source;
    this.getGridColumnConfig();
  }
  get source() {
    return this._src;
  }

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
    if (this._isExpired) {
      this.ngOnInit();
    }
  }
  get isExpired() {
    return this._isExpired;
  }

  _tradedInventory: boolean;
  @Input() set tradedInventory(tradedInventory) {
    this._tradedInventory = tradedInventory;
    this.refreshGrid();
  }
  get tradedInventory() {
    return this._tradedInventory;
  }

  _searchPlanTemplate: boolean;
  @Input() set searchPlanTemplate(searchPlanTemplate) {
    this._searchPlanTemplate = searchPlanTemplate;
  }
  get searchPlanTemplate() {
    return this._searchPlanTemplate;
  }

  _siteTemplate: PlanTemplate;
  @Input() set siteTemplate(siteTemplate) {
    this._siteTemplate = siteTemplate;
    this.setGridConfigObject();
    this.gridViewChildRef.refresh();
  }
  get siteTemplate() {
    return this._siteTemplate;
  }

  _advanceSearch: AdvancedSearch;
  @Input() set advanceSearch(advanceSearch) {
    this._advanceSearch = advanceSearch;
    if (advanceSearch !== undefined) {
      this.gridColumnConfig = this.getGridColumnConfig();
      this.setGridConfigObject();
      this.gridViewChildRef.refresh();
    }
  }
  get advanceSearch() {
    return this._advanceSearch;
  }

  _showGroups: boolean = false;
  @Input() set showGroups(showGroups) {
    this._showGroups = showGroups;
    this.toggleSwitchValue = showGroups;
  }
  get showGroups() {
    return this._showGroups;
  }

  _mediaType: string;
  @Input() set mediaType(mediaType) {
    this._mediaType = mediaType;
  }
  get mediaType() {
    return this._mediaType;
  }

  @Input() planId: string;
  @Input() items: any[];
  @Input() sidebarValues: any[];
  @Input() selected: any;
  @Input() showColumns: boolean = true;

  @Input() isNotLazyNumberFilter: boolean = false;
  @Input() hideCommonInventoryDialog: boolean = false;
  @Input() selectedStatus: any;

  @Input() exposed: boolean = false;

  @Output() eEmitSelectedInventory: EventEmitter<
    ContractHoardings[]
  > = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckInventory: EventEmitter<{
    rowData;
    rowIndex;
  }> = new EventEmitter();
  @Output() eEmitEditInventory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewInventory: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eFilter: EventEmitter<any> = new EventEmitter();
  @Output() eSitesAvailableFrom: EventEmitter<any> = new EventEmitter();
  @Output() eEmitInventory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitShowGroups: EventEmitter<any> = new EventEmitter();
  @Output() eEmitExportXlsx: EventEmitter<any> = new EventEmitter();
  @Output() eEmitInventoryCount: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewHistory: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImageUrl: EventEmitter<any> = new EventEmitter();

  gridConfig: GridConfig<Inventory> = new GridConfig<Inventory>();

  tradedAssetDropdown: SelectItem[] = [];
  ratingDropdown: SelectItem[] = [];
  categoryDropdown: any[] = [];
  lightTypeDropdown: any[] = [];
  statusDropdown: any[] = [];
  pumpCategoryDropdown: any[] = [];

  gridColumnConfig: Array<GridColumn>;
  inventoryGridGroup: FormGroup;

  HoardingStatusEnum: HoardingStatusEnum;

  @Input() selectedInventory: Inventory[] = [];

  rowData: any;
  rowIndex: number;

  hmSelectedColumns: any[] = [];
  subscription: Subscription[] = [];
  showMapDialog = false;

  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant
  editable: boolean = false;
  dFooter: string = "";
  changes: boolean = false;
  // customId: string = '';

  displayUploadDocDialog: boolean = false;
  imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();
  clonedRowData: any;
  paginationEvent: GridPaginationEvent;
  inventories: Inventory[]; // for row expansion

  totalInventoryRecords: number = 0;
  numberOfInventory: number = 0;
  searchEvent: GridPaginationEvent;

  // showChildren: boolean = false;
  loading: boolean = false;

  expiredInventory: number = 0;
  inventory: Inventory[] = [];
  inventoryTypes: any[] = [];
  selectedColumns: any[] = [];

  displayAuthorityTaxDialog: boolean = false;
  authorityTax: AuthorityTax = new AuthorityTax();
  selectedHoardingId: string = "";
  customId: string = "";

  sitesFromDate: Date;
  pageSize: number = 50;
  toggleSwitchValue: boolean = false;
  showXlsExport: boolean = false;
  isFileDownloading: boolean = false;
  xlsLoader: PPTLoader = new PPTLoader();

  globalFilterValue: string = "";

  supplierOptions: SelectItem[] = [];
  supplierPaginationEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    sortField: "updatedDate",
    sortOrder: 1,
    filters: {},
    globalFilter: "",
  };

  showThumbImageLoader: boolean = false;
  inventoryCustomId: string = null;
  imageUrl: string = null;

  isMapVisible: boolean = false;
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  mapDialogHeader: string = "Inventory Location";

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private cd: ChangeDetectorRef,
    private uploadDocumentService: UploadDocumentService,
    private notificationServcie: NotificatoinsService,
    private hoardingService: HoardingService,
    private inventoryService: InventoryService,
    private hoardingFunctionalService: HoardingFunctionalService,
    private notificationService: NotificatoinsService,
    private loaderSubjects: LoaderSubjects, // private notificationServcie: NotificatoinsService
    private supplierVendorService: SupplierVendorService
  ) {
    // this.cd.detach();
  }

  ngOnInit() {
    this.gridColumnConfig = this.getGridColumnConfig();
    this.setFormGroupObjects();

    this.setGridConfigObject();

    this.tradedAssetDropdown = [
      { label: "All", value: "ALL" },
      { label: "True", value: true },
      { label: "False", value: false },
    ];

    if (!this.hideCommonInventoryDialog) {
      this.getUserGridConfig();
    }

    this.categoryDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingCategoryEnum).forEach((key) => {
      this.categoryDropdown.push({
        label: HoardingCategoryEnum[key],
        value: key,
      });
    });

    this.pumpCategoryDropdown.push({ label: "All", value: "ALL" });
    Object.keys(PumpCategoryEnumLocal).forEach((key) => {
      this.pumpCategoryDropdown.push({
        label: PumpCategoryEnumLocal[key],
        value: key,
      });
    });

    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({
        label: HoardingLightEnum[light],
        value: light,
      });
    });

    this.statusDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingStatusEnum).forEach((status) => {
      this.statusDropdown.push({
        label: HoardingStatusEnum[status],
        value: status,
      });
    });

    this.inventoryTypes.push({ label: "All", value: "ALL" });
    Object.keys(InventoryTypeEnum).forEach((type) => {
      this.inventoryTypes.push({ label: InventoryTypeEnum[type], value: type });
    });

    this.ratingDropdown = [
      { label: "All", value: "ALL" },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];

    this.setUserColumnConfig();
    this.setSupplierDropdown();
    this.subscription.push(
      this.hmColService.siteFromDate.subscribe((siteFromDate) => {
        this.sitesFromDate = siteFromDate;
        this.gridViewChildRef.selectSitesAvailableFrom(siteFromDate);
      })
    );

    this.subscription.push(
      this.hmColService.statuses.subscribe((status) => {
        this.selectedStatus = status;
        if (status) {
          this.gridViewChildRef.filterByStatuses(status);
        }
      })
    );

    this.subscription.push(
      this.hmColService.globalFilter.subscribe((globalFilterData) => {
        this.globalFilterValue = globalFilterData;
        this.gridViewChildRef.globalFilterHoardingMaster(globalFilterData);
      })
    );

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const roles = userInfo[0].app_metadata.authorization.roles;

    this.email = userInfo[0].email;

    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
    this.setColumns();

    this.showXlsExport =
      utils.checkPermission("enable:excel") &&
      (this.source === "Contract" || this.source === "Hoarding Master")
        ? true
        : false;

    this.inventoryService.$thumbImageLoader.subscribe((response) => {
      response === false ? (this.showThumbImageLoader = false) : "";
    });
  }

  setFormGroupObjects() {
    this.inventoryGridGroup = new FormGroup({
      gridControl: new FormControl(),
    });
  }

  /**
   * @description get saved column config
   * @author Divya Sachan
   * @date 2020-02-03
   * @memberof InventoryGridComponent
   */
  getUserGridConfig() {
    if (this.showColumns) {
      switch (this.mediaType) {
        case "HOARDING": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.hoardingDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "KIOSK": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.kioskDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "PILLAR": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.pillarDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "BQS": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.bqsDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "GANTRY": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.gantryDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "PICKUP STAND": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();

                // this.cd.detectChanges();
                // this.hmColService.pickupStandDefaultColumns.next(this.gridColumnConfig);
              })
          );
          break;
        }
        case "UNIPOLE": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();
              })
          );
          break;
        }
        case "SIGNBOARD": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();
              })
          );
          break;
        }
        case "UTILITY": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();
              })
          );
          break;
        }
        case "LED": {
          this.subscription.push(
            this.userGridConfigService
              .create([this.setGridNameEnum()])
              .subscribe((gridConfig) => {
                this.gridColumnConfig = utils.getUserConfiguredColumns(
                  gridConfig[this.setGridNameEnum()],
                  this.gridColumnConfig
                );
                this.gridViewChildRef.setAllColumns();
              })
          );
          break;
        }
      }
    }
  }

  getGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: "move",
        name: "",
        header: "",
        required: true,
        editable: false,
        hidden: false,
        width: "3vw",
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
        styleClass: "padding-0",
      },

      {
        field: "customId",
        name: "customId",
        header: "HID",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: "8vw",
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "contract.id",
        name: "contract",
        header: "CID",
        width: "6vw",
        required: true,
        editable: false,
        permanent: false,
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.contract) {
            return data.contract.id;
          } else {
            return "N/A";
          }
        },
      },

      {
        field: "district",
        name: "district",
        header: "District",
        required: true,
        editable: false,
        width: "7vw",
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "city",
        name: "city",
        header: "City",
        required: true,
        editable: false,
        hidden: false,
        width: "7vw",
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "areaName",
        name: "area",
        header: "Area",
        required: true,
        width: "9vw",
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        // default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        /* displayFn: (data) => {
          if (data.area) {
            return data.area.name;
          } else {
            return '-';
          }
        } */
      },
      {
        field: "location",
        name: "location",
        header: "Location",
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: "20vw",
        default: true,
        sortable: true,
        styleClass: "text-right",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      // {
      //   field: 'map',
      //   name: 'map',
      //   header: 'Map',
      //   width: '4vw',
      //   required: true,
      //   permanent: false,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   type: EditableGridFieldTypes.CUSTOM,

      // },
      {
        field: "width",
        name: "width",
        header: "W",
        width: "50px",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.NUMBER,
        displayFn: (data) => {
          if (data.width) {
            return data.width;
          } else {
            return "-";
          }
        },
      },
      {
        field: "height",
        name: "height",
        header: "H",
        width: "50px",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        sortable: true,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.height) {
            return data.height;
          } else {
            return "-";
          }
        },
      },
      {
        field: "size",
        name: "size",
        header: "Size",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        // default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "quantity",
        name: "quantity",
        header: "Qty",
        width: "6vw",
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        // default: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.quantity) {
            return data.quantity;
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "inventoryType",
        name: "inventoryType",
        header: "Type",
        width: "8vw", // changed qty width from  4vw to 7vw -->Raveena
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.inventoryType) {
            return InventoryTypeEnum[data.inventoryType];
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
      },
      {
        field: "squareFeet",
        name: "squareFeet",
        header: "Total SqFt",
        width: "5.5vw",
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        styleClass: "text-right",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.squareFeet) {
            return this.currencyPipe.transform(Math.ceil(data.squareFeet));
          } else {
            return "-";
          }
        },
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "lightType",
        name: "lightType",
        header: "Light",
        required: true,
        editable: false,
        width: "4.5vw",
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return "-";
          }
        },
      },
      {
        field: "geoCoordinate.latitude",
        name: "geoCoordinate.latitude",
        header: "Latitude",
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        sortable: true,
        default: false,
        width: "7vw",
        styleClass: "word-break-wrap",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.latitude;
          } else {
            return "N/A";
          }
        },
      },
      {
        field: "geoCoordinate.longitude",
        name: "geoCoordinate.longitude",
        header: "Longitude",
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        width: "7vw",
        styleClass: "word-break-wrap",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.longitude;
          } else {
            return "N/A";
          }
        },
      },
      {
        field: "image",
        name: "",
        header: "Photos",
        required: true,
        editable: false,
        permanent: true,
        // width: "4.5vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.LEFT,
      },
      {
        field: "grossPrice",
        name: "grossPrice",
        header: "Gross Price",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "relational",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return "-";
          }
        },
      },
      {
        field:"pricePerSqFt",
        name:'pricePerSqFt',
        header:'Price/Sq.Ft',
        required:true,
        editable:false,
        hidden:false,
        default:false,
        permanent:false,
        sortable:true,
        type: EditableGridFieldTypes.TEXT,
        filter:{
          matchMode:'relational',
          placeHolder:'Search',
          custom:false
        },
        displayFn: (data) => {
          if(data.grossPrice && data.squareFeet){
            return this.currencyPipe.transform((data.grossPrice/data.squareFeet).toFixed(2));
          }
          else{
            return "-"
          }
        }
      },
      {
        field: "minimumPrice",
        name: "minimumPrice",
        header: "LSP",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: "5.2vw", //width:'7vw';
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: this.isNotLazyNumberFilter ? "equals" : "relational",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return "-";
          }
        },
      },
      {
        field: "startDate",
        name: "startDate",
        header: "Start Date",
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: true,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.startDate) {
            return DateUtil.dategridFormatter(data.startDate);
          }
        },
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: true
        // },
      },
      {
        field: "endDate",
        name: "endDate",
        header: "End Date",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: true,
        width: "7vw",
        displayFn: (data) => {
          if (data.endDate) {
            return DateUtil.dategridFormatter(data.endDate);
          }
        },
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: true
        // },
      },
      {
        field: "campaignStartDate",
        name: "campaignStartDate",
        header: "Campaign Start Date",
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: false,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'after',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "campaignEndDate",
        name: "campaignEndDate",
        header: "Campaign End Date",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: "7vw",
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'before',
        //   placeHolder: 'Search',
        //   custom: false
        // },
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate);
          } else {
            return "-";
          }
        },
      },
      // {
      //   field: 'status',
      //   name: 'status',
      //   header: 'Status',
      //   required: true,
      //   editable: false,
      //   hidden: false,
      //   default: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   // displayFn: (data) => {
      //   //   if (data.isActive) {
      //   //     return 'Active';
      //   //   } else {
      //   //     return 'Inactive';
      //   //   }
      //   // }
      // },
      {
        field: "statusQueues",
        name: "statusQueues",
        header: "Status",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: "7.5vw",
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "statusQueues.displayName",
        name: "displayName",
        header: "Display Name",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: "9vw",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "statusQueues.employee",
        name: "employeeName",
        header: "Employee",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: "9vw",
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
      },
      {
        field: "campaignHistory",
        name: "campaignHistory",
        header: "Campaign History",
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        // sortable: true,
        width: "7.5vw",
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: "rating",
        name: "rating",
        header: "Rating",
        required: true,
        editable: false,
        default: false,
        width: "5vw",
        permanent: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.rating) {
            return data.rating;
          } else {
            return "-";
          }
        },
      },

      {
        field: "tradedAsset",
        name: "tradedAsset",
        header: "Traded Asset",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        width: "5.5vw",
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.tradedAsset) {
            return "True";
          } else {
            return "False";
          }
        },
      },
      {
        field: "supplierVendor.name",
        name: "supplierVendor.name",
        header: "Vendor Name",
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: "9vw",
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "in",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.supplierVendor && data.supplierVendor.name) {
            return (
              data.supplierVendor.name +
              (data.supplierVendor.code
                ? "(" + data.supplierVendor.code + ")"
                : "")
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "pumpName",
        name: "pumpName",
        header: "Pump Name",
        required: true,
        width: "8vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.pumpName) {
            return data.pumpName;
          } else {
            return "-";
          }
        },
      },

      {
        field: "pumpCode",
        name: "pumpCode",
        header: "CC Code",
        required: true,
        width: "7vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.pumpCode) {
            return data.pumpCode;
          } else {
            return "-";
          }
        },
      },

      {
        field: "pumpCategory",
        name: "pumpCategory",
        header: "Pump Category",
        required: true,
        width: "9vw",
        editable: false,
        default: false,
        permanent: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: "is",
          placeHolder: "Search",
          custom: true,
        },
        displayFn: (data) => {
          if (data.pumpCategory) {
            return PumpCategoryEnumLocal[data.pumpCategory];
          } else {
            return "-";
          }
        },
      },
      {
        field: "groupMCode",
        name: "groupMCode",
        header: "Group M Code",
        required: true,
        editable: false,
        permanent: false,
        sortable: true,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.groupMCode) {
            return data.groupMCode;
          } else {
            return "-";
          }
        },
      },
      {
        field: "groupMOldRate",
        name: "groupMOldRate",
        header: "Group M Old Rate",
        required: true,
        editable: false,
        permanent: false,
        sortable: true,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.groupMOldRate) {
            return this.currencyPipe.transform(data.groupMOldRate);
          } else {
            return "-";
          }
        },
      },
      {
        field: "trafficFrom",
        name: "trafficFrom",
        header: "Traffic From",
        required: true,
        editable: false,
        sortable: true,
        permanent: false,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.trafficFrom) {
            return data.trafficFrom;
          } else {
            return "-";
          }
        },
      },
      {
        field: "trafficTo",
        name: "trafficTo",
        header: "Traffic To",
        required: true,
        editable: false,
        sortable: true,
        permanent: false,
        width: "8vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.trafficTo) {
            return data.trafficTo;
          } else {
            return "-";
          }
        },
      },
      {
        field: "note",
        name: "note",
        header: "Note",
        required: true,
        editable: false,
        permanent: false,
        width: "6vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.note) {
            return data.note;
          } else {
            return "-";
          }
        },
      },
      {
        field: "remarks",
        name: "remarks",
        header: "Remarks",
        required: true,
        editable: false,
        permanent: false,
        width: "6vw",
        styleClass: "word-break-wrap",
        default: false,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: "contains",
          placeHolder: "Search",
          custom: false,
        },
        displayFn: (data) => {
          if (data.remarks) {
            return data.remarks;
          } else {
            return "-";
          }
        },
      },
      {
        field: "documents",
        name: "documents",
        header: "Doc",
        required: true,
        width: "4vw",
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "upload",
        name: "",
        header: "",
        required: true,
        width: "4vw",
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "id",
        name: "",
        header: "",
        required: true,
        permanent: true,
        editable: false,
        width: "4vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: "authorityTax",
        name: "",
        header: "",
        required: true,
        permanent: true,
        editable: false,
        width: "3vw",
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
    ];

    const filteredColumns = this.filterColumns(columns);

    return filteredColumns;
  }

  filterColumns(columns: any[]) {
    columns.forEach((column) => {
      if (this.columns.includes(column.field)) {
        column.hidden = false;
      } else {
        column.hidden = true;
      }
    });
    if (this.source !== "Contract") {
      columns = columns.filter(
        (col) => col.field !== "startDate" && col.field !== "endDate"
      );
      if (this.source === "Hoarding Master") {
        columns = columns.filter((col) => col.field !== "tradedAsset");
      }
    } else {
      columns = columns.filter((col) => col.field !== "contract.id");
    }
    if (!this.advanceSearch || !Object.keys(this.advanceSearch).length) {
      columns = columns.filter((col) => col.field !== "campaignHistory");
    }
    return columns;
  }

  ngAfterViewInit() {
    this.setSelectedColumns();
  }

  setGridConfig() {
    this.gridConfig.getDefaultModel = () => new Inventory();
    this.gridConfig.model = Inventory;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = !this.showGroups ? false : true;
    this.gridConfig.checkboxSelection =
      this.source !== undefined &&
      this.source !== "Hoarding Master" &&
      this.source !== "Import From Contract"
        ? false
        : true;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.rowExpandMode = "single";
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
  }

  setGridConfigObject() {
    this.setGridConfig();
    this.gridConfig.dataLoadFunction = (
      paginationEvent: GridPaginationEvent
    ) => {
      // API call to get the data and render on the screen
      if (this.mediaType) {
        if (
          this.source === "Contract" ||
          this.source === "Import From Contract"
        ) {
          // if source is contract
          var filter = {
            contractId: { value: this.contractId, matchMode: "contains" },
          };
          Object.assign(paginationEvent.filters, filter);
          if (this.source === "Contract") {
            if (
              !paginationEvent.filters.status ||
              (paginationEvent.filters.status &&
                paginationEvent.filters.status.value.length === 0)
            ) {
              if (this.selectedStatus && this.selectedStatus.length) {
                let statuses = [];
                this.selectedStatus.forEach((item) => {
                  statuses.push(item);
                });
                Object.assign(paginationEvent.filters, {
                  status: { value: statuses, matchMode: "in" },
                });
              } else {
                Object.assign(paginationEvent.filters, {
                  status: {
                    value: ["AVAILABLE", "NOT_AVAILABLE", "BLOCKED"],
                    matchMode: "in",
                  },
                });
              }
            }
          } else if (this.source === "Import From Contract") {
            if (
              !paginationEvent.filters.status ||
              (paginationEvent.filters.status &&
                paginationEvent.filters.status.value.length === 0)
            ) {
              if (this.selectedStatus && this.selectedStatus.length) {
                let statuses = [];
                this.selectedStatus.forEach((item) => {
                  statuses.push(item);
                });
                Object.assign(paginationEvent.filters, {
                  status: { value: statuses, matchMode: "in" },
                });
              } else {
                Object.assign(paginationEvent.filters, {
                  status: {
                    value: ["MIGRATED", "EXPIRED", "INACTIVE", "INVALID"],
                    matchMode: "notIn",
                  },
                });
              }
            }
          }

          Object.assign(paginationEvent.filters, {
            mediaType: { value: this.mediaType, matchMode: "is" },
          });
          Object.assign(paginationEvent.filters, this.setChildFilter());

          this.searchEvent = paginationEvent;
          return this.inventoryService
            .create(paginationEvent, paginationEvent, "/search")
            .pipe(
              map((response) => {
                this.getUserGridConfig();
                this.loading = false; // added after hoarding tree is loaded
                this.inventory = response["data"]["content"];
                this.totalInventoryRecords = response["data"].totalElements;
                this.numberOfInventory = response["data"].numberOfElements;
                this.getExpiredStatus();
                this.eEmitInventoryCount.emit([
                  this.numberOfInventory,
                  this.totalInventoryRecords,
                ]);
                this.setSelectedRows();
                return response["data"]["content"];
              })
            );
        } else {
          // otherwise

          if (
            !paginationEvent.filters.status ||
            (paginationEvent.filters.status &&
              paginationEvent.filters.status.value.length === 0)
          ) {
            Object.assign(paginationEvent.filters, {
              status: {
                value: ["MIGRATED", "EXPIRED", "INACTIVE", "INVALID"],
                matchMode: "notIn",
              },
            });
          }
          if (this.items && this.items.length > 0) {
            let itemsIds = [];
            this.items.forEach((item) => {
              if (
                this.source !== "addHoardingsFromCampaign" &&
                MediaSubCategoryEnum[item.inventory.mediaType] ===
                  MediaSubCategoryEnum[this.mediaType]
              ) {
                itemsIds.push(item.inventory.id);
              } else if (
                this.source === "addHoardingsFromCampaign" &&
                MediaSubCategoryEnum[item.mediaType] ===
                  MediaSubCategoryEnum[this.mediaType]
              ) {
                itemsIds.push(item.id);
              }
            });
            if (itemsIds && itemsIds.length) {
              Object.assign(paginationEvent.filters, {
                id: { value: itemsIds, matchMode: "notIn" },
              });
            }
          }
          // if (this.source !== "addHoardingsFromCampaign") {
          Object.assign(paginationEvent.filters, {
            tradedAsset: { value: this.tradedInventory, matchMode: "binary" },
          });
          // }
          Object.assign(
            paginationEvent.filters,
            this.setSitesAvailableFromFilter()
          );
          Object.assign(paginationEvent.filters, this.setStatusFilter());
          Object.assign(paginationEvent, this.setSortField(paginationEvent));
          Object.assign(
            paginationEvent,
            this.setGlobalFilterInPagination(paginationEvent)
          );

          Object.assign(paginationEvent.filters, this.setChildFilter());
          Object.assign(paginationEvent.filters, {
            mediaType: { value: this.mediaType, matchMode: "is" },
          });

          if (this.searchPlanTemplate) {
            // if plan template search is applied
            const paginationData: PaginationData = {
              sortField: "grossPrice",
              sortOrder: 1,
            };
            if (
              !paginationEvent.sortField ||
              paginationEvent.sortField === "updatedDate"
            ) {
              paginationEvent = Object.assign(paginationEvent, paginationData);
            }

            this.pageSize = paginationEvent.size;
            let requestData = {};
            if (this.siteTemplate) {
              requestData = {
                searchEvent: paginationEvent,
                id: this.siteTemplate.id,
                name: this.siteTemplate.name,
                states: this.siteTemplate.states,
                districts: this.siteTemplate.districts,
                cities: this.siteTemplate.cities,
                medias: this.siteTemplate.medias,
                lightTypes: this.siteTemplate.lightTypes,
                availableFromDays: this.siteTemplate.availableFromDays,
                fromOneCity: this.siteTemplate.fromOneCity,
                minimumPriceFrom: this.siteTemplate.minimumPriceFrom,
                minimumPriceTo: this.siteTemplate.minimumPriceTo,
                squareFeetFrom: this.siteTemplate.squareFeetFrom,
                squareFeetTo: this.siteTemplate.squareFeetTo,
                blocked: this.siteTemplate.blocked,
              };
              this.searchEvent = requestData;
            }
            return this.inventoryService
              .create(requestData, paginationEvent, "/planTemplateSearch")
              .pipe(
                concat(),
                map((response) => {
                  this.getUserGridConfig();
                  if (this.planId) {
                    response["data"][
                      "content"
                    ] = this.hoardingFunctionalService.setResponse(
                      response["data"]["content"],
                      this.items
                    );
                  }
                  response["data"][
                    "content"
                  ] = this.hoardingFunctionalService.setResponse(
                    response["data"]["content"],
                    this.sidebarValues
                  );
                  this.loading = false; // added after hoarding tree is loaded
                  this.totalInventoryRecords = response["data"].totalElements;
                  this.numberOfInventory = response["data"].numberOfElements;
                  this.inventory = response["data"]["content"];
                  this.eEmitInventoryCount.emit([
                    this.numberOfInventory,
                    this.totalInventoryRecords,
                  ]);
                  this.setSelectedRows();
                  return response["data"]["content"];
                })
              );
          } else {
            // otherwise
            if (this.planId && utils.isObjectEmpty(paginationEvent.filters)) {
              Object.assign(paginationEvent.filters, {
                status: {
                  value: ["INACTIVE", "EXPIRED", "MIGRATED", "INVALID"],
                  matchMode: "notIn",
                },
              });
            }
            if (this.items && this.items.length > 0) {
              let itemsIds = [];
              this.items.forEach((item) => {
                if (
                  this.source !== "addHoardingsFromCampaign" &&
                  MediaSubCategoryEnum[item.inventory.mediaType] ===
                    MediaSubCategoryEnum[this.mediaType]
                ) {
                  itemsIds.push(item.inventory.id);
                } else if (
                  this.source === "addHoardingsFromCampaign" &&
                  MediaSubCategoryEnum[item.mediaType] ===
                    MediaSubCategoryEnum[this.mediaType]
                ) {
                  itemsIds.push(item.id);
                }
              });
              if (itemsIds && itemsIds.length) {
                Object.assign(paginationEvent.filters, {
                  id: { value: itemsIds, matchMode: "notIn" },
                });
              }
            }

            //partner inventory screen
            if (this.exposed === true || this.exposed === false) {
              Object.assign(paginationEvent.filters, {
                exposed: { value: this.exposed, matchMode: "is" },
              });
            }

            this.searchEvent = paginationEvent;
            if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
              let advanceSearch = _.cloneDeep(this.advanceSearch);
              advanceSearch["searchEvent"] = {};
              let searchEvent = _.cloneDeep(paginationEvent);
              Object.keys(searchEvent.filters).forEach((param) => {
                searchEvent.filters["inventory." + param] =
                  searchEvent.filters[param];
                delete searchEvent.filters[param];
              });
              Object.assign(
                advanceSearch["searchEvent"],
                _.cloneDeep(searchEvent)
              );
              paginationEvent = advanceSearch;
              this.searchEvent = paginationEvent;
              return this.inventoryService
                .create(
                  paginationEvent,
                  paginationEvent["searchEvent"],
                  AppUrls.SEARCH + AppUrls.HISTORY
                )
                .pipe(
                  map((response) => {
                    this.getUserGridConfig();
                    this.loading = false; // added after hoarding tree is loaded
                    Object.keys(response["data"]["content"]).forEach((data) => {
                      response["data"]["content"][data]["inventory"][
                        "campaigns"
                      ] = response["data"]["content"][data]["campaigns"];
                      response["data"]["content"][data] =
                        response["data"]["content"][data]["inventory"];
                    });
                    if (this.planId || (this.items && this.items.length)) {
                      response["data"][
                        "content"
                      ] = this.hoardingFunctionalService.setResponse(
                        response["data"]["content"],
                        this.items
                      );
                    }
                    response["data"][
                      "content"
                    ] = this.hoardingFunctionalService.setResponse(
                      response["data"]["content"],
                      this.sidebarValues
                    ); // function to set response as required
                    this.totalInventoryRecords = response["data"].totalElements;
                    this.numberOfInventory = response["data"].numberOfElements;
                    this.setSelectedRows();
                    this.eEmitInventoryCount.emit([
                      this.numberOfInventory,
                      this.totalInventoryRecords,
                    ]);
                    this.eEmitInventory.emit(response["data"]["content"]);
                    return response["data"]["content"];
                  }),
                  catchError((error) => {
                    if (error.error) {
                      this.notificationService.error(
                        error.error.message
                          ? error.error.message
                          : "Some technical issue",
                        "Error!!"
                      );
                    }
                    return observableEmpty();
                  })
                );
            } else {
              return this.inventoryService
                .create(paginationEvent, paginationEvent, "/search")
                .pipe(
                  map((response) => {
                    this.getUserGridConfig();
                    this.loading = false; // added after hoarding tree is loaded
                    if (this.planId || (this.items && this.items.length)) {
                      response["data"][
                        "content"
                      ] = this.hoardingFunctionalService.setResponse(
                        response["data"]["content"],
                        this.items
                      );
                    }
                    response["data"][
                      "content"
                    ] = this.hoardingFunctionalService.setResponse(
                      response["data"]["content"],
                      this.sidebarValues
                    ); // function to set response as required
                    this.totalInventoryRecords = response["data"].totalElements;
                    this.numberOfInventory = response["data"].numberOfElements;
                    this.setSelectedRows();
                    this.eEmitInventoryCount.emit([
                      this.numberOfInventory,
                      this.totalInventoryRecords,
                    ]);
                    this.eEmitInventory.emit(response["data"]["content"]);
                    return response["data"]["content"];
                  }),
                  catchError((error) => {
                    if (error.error) {
                      this.notificationService.error(
                        error.error.message
                          ? error.error.message
                          : "Some technical issue",
                        "Error!!"
                      );
                    }
                    return observableEmpty();
                  })
                );
            }
          }
        }
      }
    };
  }

  handleChange() {
    this.changes = true;
  }

  checkInventory(rowData, rowIndex) {
    this.eEmitCheckInventory.emit({ rowData, rowIndex });
  }

  editInventory(rowData) {
    this.eEmitEditInventory.emit(rowData);
  }

  viewInventory(rowData) {
    this.eEmitViewInventory.emit(rowData);
  }

  viewDetail(rowData) {
    this.eEmitViewDetail.emit(rowData);
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }
  resetAndReloadTable() {
    this.gridViewChildRef.resetAndReloadTable();
    this.resetDropDownFilters();
  }

  refreshFormTable() {
    this.gridViewChildRef.refreshFormTable();
    this.resetDropDownFilters();
  }

  refresh() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.loadData();
    }
  }

  deselectAllRows() {
    this.gridViewChildRef.deselectAllRows();
  }

  /**
   * @description on inventory selection
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} rowData
   * @memberof InventoryGridComponent
   */
  rowSelect(rowData) {
    if (this.selected && this.selected.length) {
      if (rowData && rowData.length) {
        rowData.forEach((row) => {
          var findIndex = this.selected.findIndex(
            (selectedRow) => selectedRow.id === row.id
          );
          findIndex === -1 ? this.selected.push(row) : this.selected;
        });
      } else {
      }
    } else {
      this.selected = rowData;
    }
    this.eRowSelection.emit(this.selected);
  }

  /**
   * @description deselect single row
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} event
   * @memberof InventoryGridComponent
   */
  deselectRow(event) {
    if (this.selected && this.selected.length) {
      if (event) {
        this.selected = this.selected.filter(
          (selectedRow) => selectedRow.id !== event.id
        );
        this.gridViewChildRef.previousSeleceted = this.gridViewChildRef.previousSeleceted.filter(
          (previousSelectedRow) => previousSelectedRow.id !== event.id
        );
      }
    }
    this.eRowSelection.emit(this.selected);
  }

  /**
   * @description deselect all rows
   * @author Divya Sachan
   * @date 2020-02-03
   * @param {*} event
   * @memberof InventoryGridComponent
   */
  deselectAllRow(event) {
    if (event && event.length) {
      if (this.selected && this.selected.length) {
        event.forEach((row) => {
          this.selected = this.selected.filter(
            (selectedRow) => selectedRow.id !== row.id
          );
        });
      } else {
      }
    } else {
      this.selected = [];
    }
  }

  checkSelectedItems(items: any, dataKey: any) {
    this.gridViewChildRef.addToSelected(items, dataKey);
  }

  filterData(totalRecords, values, comparator) {
    this.gridViewChildRef.filterData(totalRecords, values, comparator);
  }

  pushData(data, value) {
    data.push(value);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  pushMultipleValues(data, values) {
    data.push(...values);
    this.gridViewChildRef.pushData(data);
    this.detectChanges();
  }

  navigate(rowData) {
    if (rowData.statusQueues[0].module === "Campaign") {
      window.open("/campaigns/view/" + rowData.statusQueues[0].id);
    } else {
      window.open("/plans/view/" + rowData.statusQueues[0].id);
    }
  }

  getValue(status) {
    return HoardingStatusEnum[status];
  }

  resetDataTable() {
    this.resetDropDownFilters();
    this.gridViewChildRef.resetDataTable();
  }

  resetDropDownFilters() {
    this.ratingDd ? (this.ratingDd.selectedOption = null) : "";
    this.lightTypeDd ? (this.lightTypeDd.selectedOption = null) : "";
    this.inventoryTypedd ? (this.inventoryTypedd.selectedOption = null) : "";
    this.categoryDd ? (this.categoryDd.selectedOption = null) : "";
    this.pumpCategorydd ? (this.pumpCategorydd.selectedOption = null) : "";
    this.tradedAssetDd ? (this.tradedAssetDd.selectedOption = null) : "";
  }

  updatingUserGridConfig(event) {
    let gridConfig: Map<string, string> = new Map();
    gridConfig = utils.getGridConfigMap(event, this.gridColumnConfig);
    this.userGridConfigService
      .patch(utils.buildObject(gridConfig), null, this.setGridNameEnum())
      .pipe(take(1))
      .subscribe((result) => {});
  }

  getModifiedColumns() {
    return this.gridViewChildRef.getModifiedColumns();
  }

  showOverlay($event, rowData, rowIndex) {
    this.clonedRowData = _.cloneDeep(rowData);
    this.rowData = rowData;
    this.rowIndex = rowIndex;
    this.overlay.show($event);
  }

  uploadDoc(rowData) {
    this.clonedRowData = _.cloneDeep(rowData);
    this.displayUploadDocDialog = true;
    this.setImageFolderConfig();
  }

  setImageFolderConfig() {
    this.imageFolderConfig.itemId = this.clonedRowData.id;
    this.imageFolderConfig.moduleName = MediaTypeEnum[this.mediaType];
  }

  showUploadDocDialog() {
    this.displayUploadDocDialog = true;
  }

  hideUploadDocDialog() {
    this.displayUploadDocDialog = false;
  }

  setDocument(event) {
    this.clonedRowData.documents = [...this.clonedRowData.documents, ...event];
    this.uploadDocumentService
      .uploadInventoryDocument(
        this.clonedRowData.documents,
        this.imageFolderConfig.itemId
      )
      .subscribe((response) => {
        this.notificationServcie.success(
          "Document Uploaded Successfully",
          "Document Upload"
        );
        this.displayUploadDocDialog = false;
        this.gridViewChildRef.refresh();
      });
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
    switch (this.mediaType) {
      case "HOARDING": {
        this.hmColService.hoardingDefaultColumns.next([]);
        break;
      }
      case "KIOSK": {
        this.hmColService.kioskDefaultColumns.next([]);
        break;
      }
      case "PILLAR": {
        this.hmColService.pillarDefaultColumns.next([]);
        break;
      }
      case "BQS": {
        this.hmColService.bqsDefaultColumns.next([]);
        break;
      }
      case "GANTRY": {
        this.hmColService.gantryDefaultColumns.next([]);
        break;
      }
      case "PICKUP STAND": {
        this.hmColService.pickupStandDefaultColumns.next([]);
        break;
      }
      case "UNIPOLE": {
        this.hmColService.unipoleDefaultColumns.next([]);
        break;
      }
      case "SIGNBOARD": {
        this.hmColService.signboardDefaultColumns.next([]);
        break;
      }
      case "UTILITY": {
        this.hmColService.utilityDefaultColumns.next([]);
        break;
      }
      case "LED": {
        this.hmColService.ledDefaultColumns.next([]);
        break;
      }
    }

    this.hmColService.statuses.next(undefined);
  }

  setColumns() {
    this.gridColumnConfig.filter((col) => {
      switch (col.field) {
        case "id": {
          col.hidden =
            this.isExpired ||
            this.source === "addHoardingsFromCampaign" ||
            this.source === "Import From Contract";
          break;
        }
        case "inventoryType":
        case "quantity": {
          col.permanent = this.mediaType === "KIOSK" ? true : false;
          col.default = col.permanent ? true : false;
          break;
        }
        case "statusQueues.displayName": {
          col.hidden = utils.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }

  onRowExpand($event) {
    this.inventories = $event.data.childInventories;
  }

  isColumnPresent(value) {
    let result = false;
    this.hmSelectedColumns.forEach((col) => {
      if (col === value) {
        result = true;
      }
    });
    if (value === "Start Date" && this.columns.includes("startDate")) {
      result = true;
    }
    if (value === "End Date" && this.columns.includes("endDate")) {
      result = true;
    }
    if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
      if (
        value === "Campaign History" &&
        this.columns.includes("campaignHistory")
      ) {
        result = true;
      }
    }
    return result;
  }

  getCategory(value) {
    if (value !== null) {
      return HoardingCategoryEnum[value];
    } else {
      return "-";
    }
  }

  getLight(value) {
    return HoardingLightEnum[value];
  }

  setChildFilter() {
    return { hasChild: { value: this.showGroups, matchMode: "is" } };
  }

  setSitesAvailableFromFilter() {
    return this.sitesFromDate
      ? {
          campaignEndDate: {
            value: this.sitesFromDate,
            matchMode: "availableFrom",
          },
        }
      : {};
  }

  setStatusFilter() {
    return this.selectedStatus && this.selectedStatus.length > 0
      ? { status: { value: this.selectedStatus, matchMode: "in" } }
      : {
          status: {
            value: ["INACTIVE", "EXPIRED", "MIGRATED", "INVALID"],
            matchMode: "notIn",
          },
        };
  }

  viewImages(event) {
    this.eEmitViewImage.emit(event);
  }

  setSortField(paginationEvent) {
    if (this.checkPaginationEventForSortField(paginationEvent)) {
      return paginationEvent;
    } else {
      paginationEvent.sortField = "grossPrice";
      return paginationEvent;
    }
  }

  setGlobalFilterInPagination(paginationEvent) {
    paginationEvent.globalFilter = this.globalFilterValue
      ? this.globalFilterValue
      : "";
    return paginationEvent;
  }

  checkPaginationEventForSortField(paginationEvent) {
    var filterKeys = Object.keys(paginationEvent.filters);
    if (filterKeys.length === 1) {
      if (
        paginationEvent.sortField &&
        paginationEvent.sortField !== "updatedDate" &&
        paginationEvent.sortField !== "grossPrice"
      ) {
        return true;
      } else {
        if (
          paginationEvent.globalFilter &&
          paginationEvent.globalFilter.length
        ) {
          return false;
        } else {
          if (!paginationEvent.sortField) {
            paginationEvent.sortField = "updateDate";
          }
          return true;
        }
      }
    } else if (filterKeys.length > 1) {
      if (
        paginationEvent.sortField &&
        paginationEvent.sortField !== "grossPrice"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * @description on show groups toggle
   * @author Divya Sachan
   * @date 2020-01-28
   * @param {*} event
   * @memberof InventoryGridComponent
   */
  onToggle(event) {
    this.eEmitShowGroups.emit(event);
  }

  getExpiredStatus() {
    this.isExpired = false;
    this.expiredInventory = 0;
    this.inventory.forEach((hoarding) => {
      if (
        hoarding.status &&
        HoardingStatusEnum[hoarding.status] === HoardingStatusEnum.EXPIRED
      ) {
        this.expiredInventory += 1;
      }
    });
    if (
      this.expiredInventory === this.numberOfInventory &&
      this.expiredInventory > 0
    ) {
      this.isExpired = true;
    } else {
      this.isExpired = false;
    }
    this.setColumns();
  }

  /**
   * @description get inventory type
   * @author Divya Sachan
   * @date 2020-01-20
   * @param {*} value
   * @returns
   * @memberof InventoryGridComponent
   */
  getInventoryType(value) {
    return InventoryTypeEnum[value];
  }

  /**
   * @description add authority tax
   * @author Divya Sachan
   * @date 2020-01-21
   * @param {*} rowData
   * @memberof InventoryGridComponent
   */
  addAuthorityTax(rowData) {
    this.authorityTax = new AuthorityTax();
    this.customId = rowData.customId;
    this.selectedHoardingId = rowData.id;
    this.displayAuthorityTaxDialog = true;

    if (rowData.authorityTax.licenseNo !== null) {
      this.authorityTax.licenseNo = rowData.authorityTax.licenseNo;
      this.authorityTax.startDate = new Date(rowData.authorityTax.startDate);
      this.authorityTax.endDate = new Date(rowData.authorityTax.endDate);
      this.authorityTax.taxAmount = rowData.authorityTax.taxAmount;
      this.editable = true;
      this.changes = false;
    }
    this.cd.detectChanges();
    if (this.editable) {
      this.dFooter = "UPDATE";
    } else {
      this.dFooter = "SAVE";
    }
  }

  /**
   * @description save authority tax
   * @author Divya Sachan
   * @date 2020-01-21
   * @memberof InventoryGridComponent
   */
  saveAuthorityTax() {
    this.inventoryService
      .create(
        this.authorityTax,
        null,
        "/updateAuthorityTax/" + this.selectedHoardingId
      )
      .subscribe((response) => {
        if (!this.editable) {
          this.notificationServcie.success(
            "Tax Saved successfully",
            "Tax Saved"
          );
        } else {
          this.notificationServcie.success(
            "Tax Updated successfully",
            "Tax Updated"
          );
        }
        this.hideDialog();
        this.refreshGrid();
      });
  }

  /**
   * @description hide the authority tax dialog
   * @author Divya Sachan
   * @date 2020-01-21
   * @memberof InventoryGridComponent
   */
  hideDialog() {
    this.displayAuthorityTaxDialog = false;
    this.editable = false;
    this.cd.detectChanges();
  }

  /**
   * @description reset authority tax value
   * @author Divya Sachan
   * @date 2020-01-21
   * @memberof InventoryGridComponent
   */
  resetData() {
    this.authorityTax = new AuthorityTax();
  }

  /**
   * @description get traded asset value
   * @author Divya Sachan
   * @date 2020-01-23
   * @param {*} value
   * @returns
   * @memberof InventoryGridComponent
   */
  getTradedAsset(value) {
    return this.inventoryService.getTradedAsset(value);
  }

  /**
   * @description set selected Inventory
   * @author Divya Sachan
   * @date 2020-01-29
   * @memberof InventoryGridComponent
   */
  setSelectedRows() {
    if (this.selectedInventory.length) {
      this.gridViewChildRef.selected = this.selectedInventory;
    }
  }
  /**
   * @description export xls for hoardings
   * @author Divya Sachan
   * @date 2020-01-30
   * @memberof InventoryGridComponent
   */
  exportXlsx(event) {
    if (event) {
      this.isFileDownloading = true;
      this.createLoaderObject("XLS", this.xlsLoader);
      switch (this.mediaType) {
        case "HOARDING": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchHoardingXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.hoardingXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "KIOSK": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchKioskXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.kioskXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "PILLAR": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchPillarXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.pillarXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "BQS": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchBqsXlsLoader.next(this.xlsLoader);
          } else {
            this.loaderSubjects.bqsXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "GANTRY": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchGantryXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.gantryXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "PICKUP STAND": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchPickupStandXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.pickupStandXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "UNIPOLE": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchUnipoleXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.unipoleXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "SIGNBOARD": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchSignboardXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.signboardXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "UTILITY": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchUtilityXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.utilityXlsLoader.next(this.xlsLoader);
          }
          break;
        }
        case "LED": {
          if (this.advanceSearch && Object.keys(this.advanceSearch).length) {
            this.loaderSubjects.advanceSearchLedXlsLoader.next(
              this.xlsLoader
            );
          } else {
            this.loaderSubjects.ledXlsLoader.next(this.xlsLoader);
          }
          break;
        }
      }
      this.isFileDownloading = false;
    }
  }

  /**
   * @description loader object for Xls
   * @author Divya Sachan
   * @date 2020-01-30
   * @param {*} fileType
   * @param {*} object
   * @memberof InventoryGridComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = "Downloading XLS...";
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
    object.source = this.source;
    object.mediaType = MediaTypeEnum[this.mediaType];
  }

  /**
   * @description set grid name enum according to the source
   * @author Divya Sachan
   * @date 2020-01-16
   * @returns
   * @memberof ListHoardingsComponent
   */
  setGridNameEnum() {
    // const mediaType = this.mediaType.indexOf(' ') !== -1 ? this.mediaType.spli
    if (this.source === "Contract") {
      return GridNameEnum["CONTRACT_" + this.mediaType];
    } else {
      return GridNameEnum[this.mediaType];
    }
  }

  detectChanges() {
    if (!this.cd["destroyed"]) {
      this.cd.detectChanges();
    }
  }

  /**
   * @description set user column config for all media types
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfig() {
    switch (this.mediaType) {
      case "HOARDING": {
        this.setUserColumnConfigForHoarding();
        break;
      }
      case "KIOSK": {
        this.setUserColumnConfigForKiosk();
        break;
      }
      case "PILLAR": {
        this.setUserColumnConfigForPillar();
        break;
      }
      case "BQS": {
        this.setUserColumnConfigForBQS();
        break;
      }
      case "GANTRY": {
        this.setUserColumnConfigForGantry();
        break;
      }
      case "PICKUP STAND": {
        this.setUserColumnConfigForPickupStands();
        break;
      }
      case "UNIPOLE": {
        this.setUserColumnConfigForUnipole();
        break;
      }
      case "SIGNBOARD": {
        this.setUserColumnConfigForSignboard();
        break;
      }
      case "UTILITY": {
        this.setUserColumnConfigForUtility();
        break;
      }
      case "LED": {
        this.setUserColumnConfigForLed();
        break;
      }
    }
  }
  /**
   * @description set hoarding user column config
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfigForHoarding() {
    this.subscription.push(
      this.hmColService.hoardingSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }
  /**
   * @description set kiosk user column config
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfigForKiosk() {
    this.subscription.push(
      this.hmColService.kioskSelectedColumns.subscribe((hmSelectedColumns) => {
        this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
        this.detectChanges();
        setTimeout(() => {
          this.gridViewChildRef.setColumns();
        }, 0);
      })
    );
  }

  /**
   * @description set pillar user column config
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfigForPillar() {
    this.subscription.push(
      this.hmColService.pillarSelectedColumns.subscribe((hmSelectedColumns) => {
        this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
        this.detectChanges();
        setTimeout(() => {
          this.gridViewChildRef.setColumns();
        }, 0);
      })
    );
  }

  /**
   * @description set BQS user column config
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfigForBQS() {
    this.subscription.push(
      this.hmColService.bqsSelectedColumns.subscribe((hmSelectedColumns) => {
        this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
        this.detectChanges();
        setTimeout(() => {
          this.gridViewChildRef.setColumns();
        }, 0);
      })
    );
  }

  /**
   * @description set Gantry user column config
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setUserColumnConfigForGantry() {
    this.subscription.push(
      this.hmColService.gantrySelectedColumns.subscribe((hmSelectedColumns) => {
        this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
        this.detectChanges();
        setTimeout(() => {
          this.gridViewChildRef.setColumns();
        }, 0);
      })
    );
  }

  setUserColumnConfigForPickupStands() {
    this.subscription.push(
      this.hmColService.pickupStandSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }

  setUserColumnConfigForUnipole() {
    this.subscription.push(
      this.hmColService.unipoleSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }

  setUserColumnConfigForSignboard() {
    this.subscription.push(
      this.hmColService.signboardSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }

  setUserColumnConfigForUtility() {
    this.subscription.push(
      this.hmColService.utilitySelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }

  setUserColumnConfigForLed() {
    this.subscription.push(
      this.hmColService.ledSelectedColumns.subscribe(
        (hmSelectedColumns) => {
          this.hmSelectedColumns = JSON.parse(
            JSON.stringify(hmSelectedColumns)
          );
          this.detectChanges();
          setTimeout(() => {
            this.gridViewChildRef.setColumns();
          }, 0);
        }
      )
    );
  }

  /**
   * @description set selected columns for all media types
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumns() {
    switch (this.mediaType) {
      case "HOARDING": {
        this.setSelectedColumnsForHoarding();
        this.setUserColumnConfigForHoarding();
        break;
      }
      case "KIOSK": {
        this.setSelectedColumnsForKiosk();
        this.setUserColumnConfigForKiosk();
        break;
      }
      case "PILLAR": {
        this.setSelectedColumnsForPillar();
        this.setUserColumnConfigForPillar();
        break;
      }
      case "BQS": {
        this.setSelectedColumnsForBQS();
        this.setUserColumnConfigForBQS();
        break;
      }
      case "GANTRY": {
        this.setSelectedColumnsForGantry();
        this.setUserColumnConfigForGantry();
        break;
      }
      case "PICKUP STAND": {
        this.setSelectedColumnsForPickupStand();
        this.setUserColumnConfigForPickupStands();
        break;
      }
      case "UNIPOLE": {
        this.setSelectedColumnsForUnipole();
        this.setUserColumnConfigForUnipole();
        break;
      }
      case "SIGNBOARD": {
        this.setSelectedColumnsForSignboard();
        this.setUserColumnConfigForSignboard();
        break;
      }
      case "UTILITY": {
        this.setSelectedColumnsForUtility();
        this.setUserColumnConfigForUtility();
        break;
      }
      case "LED": {
        this.setSelectedColumnsForLed();
        this.setUserColumnConfigForLed();
        break;
      }
    }
  }

  /**
   * @description set Hoarding selected columns
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumnsForHoarding() {
    this.subscription.push(
      this.hmColService.hoardingDefaultColumns.subscribe(
        (hoardingDefaultColumns) => {
          hoardingDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.hoardingSelectedColumns.next(
              this.selectedColumns
            );
          }
        }
      )
    );
  }

  /**
   * @description set Kiosk selected columns
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumnsForKiosk() {
    this.subscription.push(
      this.hmColService.kioskDefaultColumns.subscribe((kioskDefaultColumns) => {
        kioskDefaultColumns.forEach((column) => {
          if (column["default"] === true && column["permanent"] === false) {
            if (this.selectedColumns.indexOf(column["header"]) < 0) {
              this.selectedColumns.push(column["header"]);
            }
          }
        });
        if (this.selectedColumns.length > 0) {
          this.hmColService.kioskSelectedColumns.next(this.selectedColumns);
        }
      })
    );
  }

  /**
   * @description set pillar selected columns
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumnsForPillar() {
    this.subscription.push(
      this.hmColService.pillarDefaultColumns.subscribe(
        (pillarDefaultColumns) => {
          pillarDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.pillarSelectedColumns.next(this.selectedColumns);
          }
        }
      )
    );
  }

  /**
   * @description set BQS selected columns
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumnsForBQS() {
    this.subscription.push(
      this.hmColService.bqsDefaultColumns.subscribe((bqsDefaultColumns) => {
        bqsDefaultColumns.forEach((column) => {
          if (column["default"] === true && column["permanent"] === false) {
            if (this.selectedColumns.indexOf(column["header"]) < 0) {
              this.selectedColumns.push(column["header"]);
            }
          }
        });
        if (this.selectedColumns.length > 0) {
          this.hmColService.bqsSelectedColumns.next(this.selectedColumns);
        }
      })
    );
  }

  /**
   * @description set gantry selected columns
   * @author Divya Sachan
   * @date 2020-02-19
   * @memberof InventoryGridComponent
   */
  setSelectedColumnsForGantry() {
    this.subscription.push(
      this.hmColService.gantryDefaultColumns.subscribe(
        (gantryDefaultColumns) => {
          gantryDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.gantrySelectedColumns.next(this.selectedColumns);
          }
        }
      )
    );
  }

  setSelectedColumnsForPickupStand() {
    this.subscription.push(
      this.hmColService.pickupStandDefaultColumns.subscribe(
        (pickupStandDefaultColumns) => {
          pickupStandDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.pickupStandSelectedColumns.next(
              this.selectedColumns
            );
          }
        }
      )
    );
  }

  setSelectedColumnsForUnipole() {
    this.subscription.push(
      this.hmColService.unipoleDefaultColumns.subscribe(
        (unipoleDefaultColumns) => {
          unipoleDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.unipoleSelectedColumns.next(this.selectedColumns);
          }
        }
      )
    );
  }

  setSelectedColumnsForSignboard() {
    this.subscription.push(
      this.hmColService.signboardDefaultColumns.subscribe(
        (signboardDefaultColumns) => {
          signboardDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.signboardSelectedColumns.next(
              this.selectedColumns
            );
          }
        }
      )
    );
  }

  setSelectedColumnsForUtility() {
    this.subscription.push(
      this.hmColService.utilityDefaultColumns.subscribe(
        (utilityDefaultColumns) => {
          utilityDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.utilitySelectedColumns.next(this.selectedColumns);
          }
        }
      )
    );
  }

  setSelectedColumnsForLed() {
    this.subscription.push(
      this.hmColService.ledDefaultColumns.subscribe(
        (ledDefaultColumns) => {
          ledDefaultColumns.forEach((column) => {
            if (column["default"] === true && column["permanent"] === false) {
              if (this.selectedColumns.indexOf(column["header"]) < 0) {
                this.selectedColumns.push(column["header"]);
              }
            }
          });
          if (this.selectedColumns.length > 0) {
            this.hmColService.ledSelectedColumns.next(this.selectedColumns);
          }
        }
      )
    );
  }

  /**
   * @description get media Type value
   * @author Divya Sachan
   * @date 2020-02-24
   * @param {*} value
   * @returns
   * @memberof InventoryGridComponent
   */
  getMediaType(value) {
    return this.inventoryService.getMediaType(value);
  }

  getPumpCategory(category) {
    return PumpCategoryEnumLocal[category];
  }

  viewHistory(rowData) {
    this.eEmitViewHistory.emit(rowData);
  }

  setSupplierDropdown() {
    if (localStorage.getItem("supplierMultiselect")) {
      this.supplierOptions = JSON.parse(
        localStorage.getItem("supplierMultiselect")
      );
    } else {
      this.supplierVendorService
        .create(
          this.supplierPaginationEvent,
          this.supplierPaginationEvent,
          AppUrls.SEARCH
        )
        .subscribe((response) => {
          if (response["data"]["content"]) {
            this.supplierOptions = utils.createDropdownFromStringArray(
              response["data"]["content"].map((item) => item.name),
              false
            );
          }
        }),
        (error) => {
          this.notificationService.error(
            error.error.message
              ? error.error.message
              : "Unable to fetch supplier list.",
            "Please Refresh."
          );
        };
    }
  }

  viewImageOnHover(e, rowData) {
    let imageUrl =
      rowData.images && rowData.images.length
        ? rowData.images[0].thumbUrl
          ? rowData.images[0].thumbUrl
          : rowData.images[0].url
        : "";
    if (imageUrl) {
      this.showThumbImageLoader = true;
      this.imageUrl = imageUrl;
      this.inventoryCustomId = rowData.customId;
      this.eEmitImageUrl.emit([e, imageUrl]);
    } else {
      this.showThumbImageLoader = false;
      this.imageUrl = null;
      this.inventoryCustomId = null;
      this.eEmitImageUrl.emit([e, null]);
    }
  }

  hideImageOnLeave(e, rowData) {
    this.imageUrl = null;
    this.inventoryCustomId = null;
    this.eEmitImageUrl.emit([e, null]);
  }

  showMap(inventory) {
    this.mapDialogHeader = inventory.customId
      ? "Inventory Location (" + inventory.customId + ")"
      : "Inventory Location";
    this.mapMarker = new MapMarker().setMapMarkerFromInventory(inventory);
    this.overlays.push(this.mapMarker);
    this.overlays = _.cloneDeep(this.overlays);
    if (this.overlays.length === 0) {
      return;
    } else {
      this.options = {
        center: {
          lat:
            typeof this.overlays[0].latitude === "string"
              ? parseFloat(this.overlays[0].latitude)
              : this.overlays[0].latitude,
          lng:
            typeof this.overlays[0].longitude === "string"
              ? parseFloat(this.overlays[0].longitude)
              : this.overlays[0].longitude,
        },
        zoom: 12,
      };
    }
    this.isMapVisible = true;
  }

  hideMap() {
    this.mapDialogHeader = "Inventory Location";
    this.isMapVisible = false;
    this.mapMarker = new MapMarker();
    this.overlays = [];
    this.options = {};
  }
}
