import { SibModuleEnum } from '../../shared/constants/sib-module-enum';
import { PackageType } from './package-type';

export class PackageInfo {
    name: string;
    packageType: PackageType;
    supportedModules: SibModuleEnum[];
    costPerMonth: number;

    constructor(name, packageType, supportedModules, costPerMonth) {
        this.name = name;
        this.packageType = packageType;
        this.supportedModules = supportedModules;
        this.costPerMonth = costPerMonth;
    }
}