/**
 * @file photo-dialog.component
 * @author pulkitb@meditab.com
 * @copyright Meditab Software 2018
 */

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { FirebaseService } from "../../../services/shared/firebase.service";
import { Ng2PicaService } from "ng2-pica";
import { DomHandler } from "primeng/components/dom/domhandler";
import { Galleria } from "primeng/primeng";

/**
 * Shared Photo Dialog component to show the images.
 *
 * @export
 * @class PhotoDialogComponent
 * @implements {OnInit}
 */

@Component({
  selector: "sib-photo-dialog",
  templateUrl: "./photo-dialog.component.html",
  styleUrls: ["./photo-dialog.component.scss"],
  providers: [DomHandler],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoDialogComponent implements OnInit, AfterViewInit {
  _displayPhotoDialog = null;
  // @Input() displayPhotoDialog = false;
  @Input() set displayPhotoDialog(displayPhotoDialog) {
    this._displayPhotoDialog = displayPhotoDialog;
  }

  get displayPhotoDialog() {
    return this._displayPhotoDialog;
  }

  @Input() imageData: any[] = [];
  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() eToggleLoader: EventEmitter<boolean> = new EventEmitter();
  @Input() photoDisplayData: any;
  // @Input() set fromPhotoInterface(imageUrl) {
  //     if (imageUrl) {
  //         this.images = [];
  //         this.images.push({ source: imageUrl });
  //         this.onImageChange(0);
  //     }
  // }
  // @Input() set imagesUrls(urls) {
  //     if (urls) {
  //         this.images = [];
  //         urls.forEach((url) => {
  //             this.images.push({ source: url });
  //         });
  //     }
  // }
  // @Input() set index(index) {
  //     if (index !== '' && index !== undefined && index !== null) {
  //         this.activeIndex = index;
  //         this.onImageChange(index);
  //     } else {
  //         this.activeIndex = 0;
  //     }
  // }
  // @Input() set imageObjs(imageObjs) {
  //     if (imageObjs) {
  //         this.images = [];
  //         imageObjs.forEach((obj) => {
  //             this.images.push({ source: obj.source, alt: obj.alt, title: '' });
  //         });
  //     }
  // }
  // _photoIndex: number;
  // @Input() set photoIndex(photoIndex) {
  //     this._photoIndex = photoIndex;
  //     this._photoIndex = JSON.parse(JSON.stringify(this._photoIndex));
  // }

  // get photoIndex() {
  //     return this._photoIndex;
  // }
  @Input() photoIndex: number;

  /**
   * Accessing the div that contains gallery Module wrapper
   *
   * @type {ElementRef}
   * @memberof PhotoDialogComponent
   */
  @ViewChild("galleryModal") galleryModal: ElementRef;

  /**
   * Accessing the div that contains gallery Module
   *
   * @type {ElementRef}
   * @memberof PhotoDialogComponent
   */
  @ViewChild("galleryModalContent") galleryModalContent: ElementRef;

  private _galleryComponent: Galleria;
  @ViewChild("gallery")
  public get galleryComponent(): Galleria {
    return this._galleryComponent;
  }
  public set galleryComponent(value: Galleria) {
    this._galleryComponent = value;
    // if (this.galleryComponent) {
    //     this.galleryComponent.activeIndex = this.activeIndex;
    // }
    this.setGalleryComponentActiveIndex();
  }
  /**
   * Getting the image tag in which image is displayed
   *
   * @type {*}
   * @memberof PhotoDialogComponent
   */
  img: any;

  /**
   * Storing the images
   *
   * @type {any[]}
   * @memberof PhotoDialogComponent
   */
  images: any[] = [];

  /**
   * setting the width and height of gallery Module
   *
   * @type {*}
   * @memberof PhotoDialogComponent
   */
  panelWidth: any;
  panelHeight: any;

  /**
   * No Image Boolean to check if image is available or not
   *
   * @type {boolean}
   * @memberof PhotoDialogComponent
   */
  noImage: boolean = false;

  /**
   * Storing the screen width and height
   *
   * @type {*}
   * @memberof PhotoDialogComponent
   */
  screenWidth: any;
  screenHeight: any;
  captionPanel: any;

  imageElementsByClass: any[] = [];
  activeIndex = 0;
  nextButtonElement: any;
  previousButtonElement: any;
  showGallery = false;

  imagesPanel: any;
  loaded: boolean = false;

  currentImageElement: any;
  email: string;
  authorized: boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    private ng2PicaService: Ng2PicaService,
    private domHandler: DomHandler,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
   if(userInfo && userInfo[0]){
    const roles = userInfo[0].app_metadata.authorization.roles;
    this.email = userInfo[0].email;
    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
   }
  }

  ngAfterViewInit() {
    this.galleryModal.nativeElement.style.display = "none";
    // this.imagesPanel = this.domHandler.findSingle(this.galleryModalContent.nativeElement, '.ui-galleria-panel-wrapper');
    // this.imagesPanel.nativeElement.style.display = 'none';
  }
  /**
   * getting the images from firebase
   * when dialog is opened
   * @memberof PhotoDialogComponent
   */
  showPhotoDialog(fromLogoComponent?) {
    this.images = [];
    this.showGallery = false;
    if (!fromLogoComponent) {
      //when it is not called from logo screen
      for (let i = 0; i < this.imageData.length; i++) {
        this.images.push({
          source: this.imageData[i].image.url,
          alt: this.getImageDescription(this.imageData[i]),
          title: "",
        });
      }
    } else {
      //when it is called from logo screen
      for (let i = 0; i < this.imageData.length; i++) {
        this.images.push({
          source: this.imageData[i].url,
          alt: this.imageData[i].description,
        });
      }
    }

    // modifed by Pulkit to add the showGallery property which is set true only after all the images have been pushed into the array of images
    this.showGallery = true;
    this.cd.detectChanges();
    this.setImagePanel();
    if (this.photoIndex) {
      this.activeIndex = this.photoIndex;
      this.onImageChange(this.photoIndex);
    } else {
      this.activeIndex = 0;
      this.onImageChange(0);
    }
  }

  setImagePanel() {
    this.imagesPanel = this.domHandler.findSingle(
      this.galleryModalContent.nativeElement,
      ".ui-galleria-panel-wrapper"
    );
    this.imagesPanel.style.display = "none";
  }

  getImageDescription(imageData) {
    const hid = imageData.hid ? imageData.hid : "";
    const city = imageData.city ? imageData.city : "";
    const location = imageData.location ? imageData.location : "";
    const size = imageData.size ? imageData.size : "";
    const displayName = imageData.displayName ? imageData.displayName : "";
    return (
      hid +
      (city && city !== "" && city !== null ? " - " + city : "") +
      (location && location !== "" && location !== null
        ? ", " + location
        : "") +
      (size && size !== "" && size !== null ? " - " + size : "") +
      (displayName && displayName !== "" && displayName !== null
        ? " - " + displayName
        : "")
    );
  }

  // hidePhotoDialog() {
  //     this.displayPhotoDialog = false;
  //     this.displayReset.emit(false);
  // }

  showImages(fromLogoComponent?) {
    this.showPhotoDialog(fromLogoComponent);
  }

  // resetting the panel size while closing the dialog
  closeDisplay() {
    this.galleryModalContent.nativeElement.style.height = 400 + "px";
    this.galleryModalContent.nativeElement.style.width = 600 + "px";
    this.panelHeight = 400;
    this.panelWidth = 600;
    this.galleryModal.nativeElement.style.display = "none";
    this.currentImageElement = null;
    this.displayReset.emit(false);
    this.images = [];
    this.showGallery = false;
  }

  hideDialog() {
    this.noImage = false;
    this.galleryModal.nativeElement.style.display = "none";
    // this.closeDisplay();
  }

  /**
   * Get the img element corresponding to src attribute.
   *
   * @param {string} url
   * @returns
   * @memberof PhotoDialogComponent
   */
  findImageNodeByUrl(url: string) {
    var imageElements: any[];
    imageElements = this.getImageElementsByClass();

    for (let i = 0; i < imageElements.length; i++) {
      if (imageElements[i].src === url) {
        return imageElements[i];
      }
    }
  }

  /**
   * Fetching all the img elements corresponding to ui-panel-image class
   *
   * @returns
   * @memberof PhotoDialogComponent
   */
  getImageElementsByClass() {
    return this.domHandler.find(
      this.galleryModalContent.nativeElement,
      ".ui-panel-images"
    );
  }

  /**
   * Pass arguments with height and width to resize the images.
   *
   * @param {any} height
   * @param {any} width
   * @memberof PhotoDialogComponent
   */
  resizeImagePanel(height, width) {
    // this.img.height = height;
    // this.img.width = width;

    this.panelHeight = height;
    this.panelWidth = width;

    // setting the gallery module height width according to screen width and height
    this.galleryModalContent.nativeElement.style.height = height + "px";
    this.galleryModalContent.nativeElement.style.width = width + "px";
  }

  /**
   * Comparison of image with screen width and height,
   * then calling resize function to resize.
   *
   * @param {any} imageNode
   * @memberof PhotoDialogComponent
   */
  setImage(imageNode) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.resizeImagePanel(this.screenHeight - 150, this.screenWidth - 50);
  }

  onImageChange(index) {
    // modified by Pulkit to include active index and photo index
    // this assignment is necessary as we are checking the active index while right button in pressed - see the host listener function
    if (window.location.href.includes("photo-interface")) {
      this.createNewElement(index);
    }
    this.activeIndex = index;
    this.setGalleryComponentActiveIndex();

    if (this.images.length > 0) {
      this.img = null;

      // getting the image element to get Image height and width
      if (index) {
        this.img = this.domHandler.find(
          this.galleryModalContent.nativeElement,
          ".ui-panel-images"
        )[index];
        // const imgs = this.domHandler.find(this.galleryModalContent.nativeElement, '.ui-panel-images');
        // this.img = imgs[index];
      } else {
        this.img = this.domHandler.findSingle(
          this.galleryModalContent.nativeElement,
          ".ui-panel-images"
        );
      }
      const ImageNode = this.findImageNodeByUrl(this.images[index].source);

      // setting the image
      this.setImage(ImageNode);

      // after everything is done, then showing the div block to show image.
      this.galleryModal.nativeElement.style.display = "block";
      // this.imagesPanel.style.display = 'block';

      setTimeout(() => {
        this.imagesPanel.style.display = "block";
        // this.currentImageElement = document.querySelector('.ui-panel-images');

        this.currentImageElement = document.querySelectorAll(
          ".ui-panel-images"
        )[index];
      }, 10); // changed from 1500 to 10 ---> Pulkit

      // timeout is necessary because the view and onImageChange are set and called respectively at the same time
      setTimeout(() => {
        this.nextButtonElement = this.domHandler.findSingle(
          this.galleryModalContent.nativeElement,
          ".ui-galleria-nav-next"
        );
        if (!this.images[index + 1]) {
          this.nextButtonElement.style.display = "none";
        } else {
          this.nextButtonElement.style.display = "inline-block";
        }
      }, 10);
      this.eToggleLoader.emit(false);
    }
  }

  @HostListener("click", ["$event"])
  handleClickEvent(event: any) {
    if (this.currentImageElement) {
      var filmStripElement = document.querySelector(".ui-galleria-filmstrip");
      var nextNav = document.querySelector(".ui-galleria-nav-next");
      var prevNav = document.querySelector(".ui-galleria-nav-prev");
      var isClickOnFilmStrip = filmStripElement.contains(event.target);
      var isClickOnNextNav = nextNav ? nextNav.contains(event.target) : false;
      var isClickOnPrevNav = prevNav ? prevNav.contains(event.target) : false;
      var isClickInsideImage = this.currentImageElement.contains(event.target);

      if (prevNav) {
        if (
          !isClickOnPrevNav &&
          !isClickOnNextNav &&
          !isClickOnFilmStrip &&
          !isClickInsideImage
        ) {
          // this.closeDisplay();
        }
      } else if (!prevNav) {
        if (!isClickOnNextNav && !isClickOnFilmStrip && !isClickInsideImage) {
          // this.closSeDisplay();
        }
      }
    }
  }

  @HostListener("window:keyup", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.closeDisplay();
    }
    // modified by Pulkit to handle the keyboard right click event
    if (event.keyCode === 39) {
      event.stopImmediatePropagation();
      if (this.images[this.activeIndex + 1]) {
        this.galleryComponent.clickNavRight();
      }
    }
    // modified by Pulkit to handle the keyboard left click event
    if (event.keyCode === 37) {
      event.stopImmediatePropagation();
      if (this.images[this.activeIndex - 1]) {
        this.galleryComponent.clickNavLeft();
      }
    }
  }

  /**
   *
   * creating and appending new element with link in photo description
   * @param {*} indexOfImage
   * @memberof PhotoDialogComponent
   */
  createNewElement(indexOfImage) {
    var indexImage = indexOfImage;
    var galleriaClass = this.domHandler.findSingle(
      this.galleryModalContent.nativeElement,
      ".ui-galleria-caption"
    );
    var pTag = this.domHandler.findSingle(galleriaClass, "p");
    var span = this.domHandler.findSingle(galleriaClass, "span");
    var anchor = document.createElement("a");
    galleriaClass["innerHtml"] = "";
    if (this.images[indexImage].alt) {
      pTag["innerHtml"] = this.images[indexImage].alt = "";
    }
    for (var i = 0; i < this.imageData.length; i++) {
      if (this.imageData[i].image.url === this.images[indexImage].source) {
        anchor.innerHTML = "<u>" + this.imageData[i].displayName + "</u>";
        if (
          this.imageData[i].campaignId &&
          (this.imageData[i].employeeEmail === this.email || this.authorized)
        ) {
          anchor.href = this.imageData[i].campaignId
            ? "/campaigns/view/" + this.imageData[i].campaignId
            : "";
          anchor.target = "_blank";
          anchor.style.color = "red";
        } else {
          anchor.style.color = "#ffffffe6";
        }
        var spanTag = document.createElement("span");
        spanTag.innerHTML =
          this.imageData[i].hid +
          " - " +
          this.imageData[i].city +
          " - " +
          this.imageData[i].location +
          " - " +
          this.imageData[i].size +
          (this.imageData[i].displayName &&
          this.imageData[i].displayName !== "" &&
          this.imageData[i].displayName !== null
            ? " - " + anchor.outerHTML
            : "");
        pTag.appendChild(spanTag);
        break;
      }
    }
    if (span) {
      span["innerHTML"] = "";
      span["innerText"] = "";
      span["outerHTML"] = "";
    }
  }

  setGalleryComponentActiveIndex() {
    if (
      this.galleryComponent &&
      this.activeIndex !== undefined &&
      this.activeIndex !== null
    ) {
      this.galleryComponent.activeIndex = this.activeIndex;
    }
  }
}
