import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject, Subject } from "rxjs";
import { UserService } from "./user.service";
import { AuthService } from "../../login-service/auth.service";
import { AppUrls } from "../urls";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  totalMessages = new BehaviorSubject(null);
  userProfile: any;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private usersService: UserService,
    private angularFireMessaging: AngularFireMessaging,
    private auth: AuthService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });

    this.userProfile = this.auth.getUserInfo();
  }

  /**
   * @description updates token wheneven token is changed
   * @author Raveena Nathani
   * @date 2020-05-11
   * @param {*} previousToken
   * @param {*} newToken
   * @memberof MessagingService
   */
  updateToken(previousToken, newToken) {
    this.usersService
      .create(
        {
          newToken: newToken,
          oldToken: previousToken,
          emailId: this.userProfile.email,
        },
        null,
        AppUrls.SET_FCM_TOKEN
      )
      .subscribe();
  }

  /**
   * @description requets permission for notification from firebase cloud messaging
   * @author Raveena Nathani
   * @date 2020-05-11
   * @param {*} previousToken
   * @memberof MessagingService
   */
  requestPermission(previousToken) {
    this.angularFireMessaging.requestToken.subscribe(
      (newToken) => {
        if (newToken !== previousToken) {
          localStorage.setItem("deviceToken", newToken);
          this.updateToken(previousToken, newToken);
        }
      },
      (err) => {
        // console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      // console.log("new message received. ", payload);

      this.currentMessage.next(payload);
    });
  }
}
