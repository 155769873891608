
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';
import { CommonInventoryGridComponent } from './common-inventory-grid.component';
import { InventoryGridModule } from '../inventory-grid/inventory-grid.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        SharedModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DirectiveModules,
        InventoryGridModule,
    ],
    declarations: [
        CommonInventoryGridComponent
    ],
    exports: [
        CommonInventoryGridComponent
    ]
})
export class CommonInventoryGridModule {
}
