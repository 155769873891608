import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { AdditionalItem } from "./additional-item";
import * as utils from '../../helpers/utils';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';

export class AdditionalItemWrapper extends CampaignItemWrapper {
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    printingCost: number;
    unitPrintingCost: number;
    unitMountingCost: number;
    mountingCost: number;
    amount: number;
    serviceDate: Date;
    actualAmount: number;
    creative: string;
    note: string;
    hsnCode?: number;

    setWrapper(item: AdditionalItem): AdditionalItemWrapper {

        this.amount = item.amount;
        this.city = item.city;
        this.cost = utils.parseValueToDecimalTwoPlaces(item.cost);
        this.customId = item.customId;
        this.id = item.id;
        this.location = item.location;
        this.printingPrice = item.printingPrice;
        this.mountingPrice = item.mountingPrice;
        this.rate = utils.parseValueToDecimalTwoPlaces(item.rate);
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.lightType = item.lightType;
        this.mediaSubCategory = item.mediaSubCategory;
        this.mediaType = item.mediaType;
        this.serviceDate = new Date(item.serviceDate);
        this.note = item.note;
        this.actualAmount = utils.parseValueToDecimalTwoPlaces(item.actualAmount);
        this.quantity = item.quantity
        this.creative = item.creative;
        this.childInventories = [...item.childInventories];
        this.inventoryType = item.inventoryType;
        this.hasDimension = item.hasDimension;
        if (item.printingPrice.flNlPrice !== 0 && (HoardingLightEnum[this.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[this.lightType] === HoardingLightEnum.N_L)) {
            this.printingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.flNlPrice);
        } else if (item.printingPrice.blPrice !== 0 && HoardingLightEnum[this.lightType] === HoardingLightEnum.B_L) {
            this.printingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.blPrice);
        } else if (HoardingLightEnum[this.lightType] === HoardingLightEnum.VARIOUS && !item.printingPrice.perUnit) {
            this.printingCost = 0;
            if (item.printingPrice.variousPrice) {
                this.printingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.variousPrice);
            } else {
                item.childInventories.forEach((child) => {
                    this.printingCost += (child.lightType === "F_L" || child.lightType === "N_L" ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice) : (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice));
                })
            }
            // this.printingCost = item.printingPrice.variousPrice ? utils.parseValueToDecimalTwoPlaces(item.printingPrice.variousPrice) : utils.parseValueToDecimalTwoPlaces(item.printingPrice.flNlPrice) + utils.parseValueToDecimalTwoPlaces(item.printingPrice.blPrice);
        }

        if (item.printingPrice.pricePerUnitFl !== 0 && (HoardingLightEnum[this.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[this.lightType] === HoardingLightEnum.N_L)) {
            this.unitPrintingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.pricePerUnitFl)
        } else if (item.printingPrice.pricePerUnitBl && HoardingLightEnum[this.lightType] === HoardingLightEnum.B_L) {
            this.unitPrintingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.pricePerUnitBl);
        } else if (HoardingLightEnum[this.lightType] === HoardingLightEnum.VARIOUS && item.printingPrice.perUnit) {
            this.unitPrintingCost = item.printingPrice.variousPrice ? utils.parseValueToDecimalTwoPlaces(item.printingPrice.variousPrice) : utils.parseValueToDecimalTwoPlaces(item.printingPrice.pricePerUnitFl) + utils.parseValueToDecimalTwoPlaces(item.printingPrice.pricePerUnitBl);
        }

        if (item.mountingPrice.pricePerSqFt !== 0) {
            this.mountingCost = utils.parseValueToDecimalTwoPlaces(item.mountingPrice.pricePerSqFt);
        } else if (item.mountingPrice.perSqftForVarious !== 0) {
            this.mountingCost = utils.parseValueToDecimalTwoPlaces(item.mountingPrice.perSqftForVarious)
        } else if (item.mountingPrice.pricePerUnit !== 0) {
            this.unitMountingCost = utils.parseValueToDecimalTwoPlaces(item.mountingPrice.pricePerUnit);
        }

        this.hsnCode = item.hsnCode;
        return this;
    }
}
