
import { map, catchError } from 'rxjs/operators';
/* 
* @file Campaign service.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { ApiService, BASE_URL } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions, Headers } from "@angular/http";
import { Observable, Observer, Subject } from "rxjs";
import { Campaign } from "../../modals/campaigns/campaign";
import { ActivatedRoute } from "@angular/router";
import { NotificatoinsService } from '../notifications/notifications.service';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from './notifications-messages';

@Injectable({
    providedIn: 'root'
})
export class CampaignService extends ApiService<any, any, any> {
    // urlPath: string = 'campaigns?projection=campaignProjection';
    urlPath: string = 'campaigns';
    baseUrl: string;
    campaign: Campaign;
    public $search = new Subject<any>();
    public $triggerEvent = new Subject<any>();

    constructor(private Http: HttpClient,
        private Injector: Injector,
        private route: ActivatedRoute,
        private notificationServcie: NotificatoinsService,
    ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getRouteId(): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            // console.log("in get route");
            this.route.params.subscribe((params: { id: string }) => {
                // return params.id;
                observer.next(params.id);
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            }
            );
        });
    }

    /**
     * API call for gettign all the campaigns 
     */
    getAllCampaigns() {
        let headers = {
            headers: new HttpHeaders()
        };
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'campaigns');

        }
    }

    /**
   * 
   * API request to URL passed
   * @param  {} url
   * @returns Observable
   */
    getByURL(url): Observable<any> {
        if (navigator.onLine) {
            let headers = {
                headers: new HttpHeaders()
            }
            return this.Http.get(url).pipe(map((res: Response) => {
                return res;
            }), catchError((err) => {
                // TODO: Error handling
                // console.log(err);
                return err;
            }));
        }

    }

    /**
    * Settign Plan to this scope
    * @param  {} campaignData
    */
    setCampaign(campaignData) {
        this.campaign = campaignData;
    }

    /**
    * Fetching all the Hoardings based on campaignId
    * @param  {} campaignId
    */
    getHoardings(campaignId) {

        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            }
            return this.Http.get(this.baseUrl + 'campaignItems/by-campaign/' + campaignId).pipe(
                catchError((err) => {
                    // TODO: Error handling
                    // console.log(err);
                    return err;
                }));
        }


    }
    /**
     * to get the campaign by id
     * 
     * @param {any} id 
     * @returns 
     * @memberof CampaignService
     */
    getCampaign(id) {

        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'campaigns/' + id);

        }
    }


    /**
     * to get campaign with employee details
     * 
     * @param {any} id 
     * @returns 
     * @memberof CampaignService
     */
    getCampaignWithEmployee(id, campaignQueryParams) {

        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'campaigns/' + id + '?projection=campaignProjection');

        }
        // return this.Http.get(this.baseUrl + 'campaigns/' + id, campaignQueryParams);
    }

    getCampaignById(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'campaigns/view/' + id);

        }
    }



    /**
     * to get the status for campaigns
     * 
     * @returns 
     * @memberof CampaignService
     */
    getStatus() {

        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'campaigns/status');

        }
    }


    /**
     * to delete a campaign
     * 
     * @param {any} campaignId 
     * @returns {*} 
     * @memberof CampaignService
     */
    deleteCampaign(campaignId): any {
        if (navigator.onLine) {
            return this.Http.delete(this.baseUrl + 'campaigns/remove/' + campaignId);

        }
    }

    /**
   * to delete a item
   * 
   * @param {any} campaignId 
   * @returns {*} 
   * @memberof CampaignService
   */
    deleteItem(campaignQueryParams): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'campaigns/delete/' + campaignQueryParams);

        }
    }

    deleteCampaignsItems(campaignItemIds){
        const options = {
            headers : new HttpHeaders().set('Content-Type','application/json')
        }

        if(navigator.onLine){
            const body = JSON.stringify(campaignItemIds);
            return this.Http.post(this.baseUrl+'campaignItems/removeItems',body,options)
           .pipe(map((response)=>{
            return response;
           }))
        }
    }

    downloadFile(campaignId, fileType, subModule?, ids?, customIds?) {
        if (navigator.onLine) {
            let options = {};

            if (fileType === 'PPT_BETA') {
                const fileUrl = this.baseUrl + 'campaigns/generatePPT?campaignId=' + campaignId;
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, customIds, options).pipe(map((response: any) => {
                    return response;
                })
                    // , catchError((err) => {
                    // if (err.status === 412) {
                    //     this.notificationServcie.info(err.error.message ? err.error.message : 'There are no items with images', "PPT Download");
                    // } else {
                    //     this.notificationServcie.error("PPT Download Error", "Please refresh and retry");
                    // }
                    //     return err;
                    // }
                );
            } else if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaigns/generateXLSForCampaignItems/' + subModule;
                options = { responseType: "arraybuffer", params: { 'campaignId': campaignId } };
                return this.Http.post(fileUrl, ids, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {

                    const errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }


    }

    downloadCampaignTrackerFile(campaignId, fileType, tenantId, subModule?, customIds?) {
        if (navigator.onLine) {
            let options = {};

            if (fileType === 'PPT') {
                const fileUrl = this.baseUrl + 'campaigns/public/generatePPT';
                options = { responseType: "arraybuffer", params: { 'campaignId': campaignId, 'x_tenant': tenantId } };
                return this.Http.post(fileUrl, customIds, options).pipe(map((response: any) => {
                    return response;
                }));
            } else if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaigns/public/generateXLSForCampaignItems/' + subModule;
                options = { responseType: "arraybuffer", params: { 'campaignId': campaignId, 'x_tenant': tenantId } };
                return this.Http.post(fileUrl, customIds, options).pipe(map((response: any) => {
                    return response;
                }));
            }
        }

    }

    getCampaignTaskCount(searchEvent) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'campaigns/pending/count', searchEvent)

        }
    }

    printConfirmationQuote(campaignItemIds, id): Observable<any> {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'campaigns/generatePDF', campaignItemIds, { responseType: "arraybuffer", params: { campaignId: id } });
        }
    }
    
    setInvoiceNumber(campaignId, invoiceNumber) {
        if (navigator.onLine) {
          return this.Http.patch(
            this.baseUrl +
              "campaigns/" +
              campaignId +
              "/updateInvoiceNumber/" +
              invoiceNumber,
            ""
          );
        }
      }

      saveInvoiceNumberForCampaign(campaignId,invoiceNo){
        if(navigator.onLine){
            return this.Http.post(
                this.baseUrl+"campaigns/"+"saveInvoiceNumber/"+campaignId,invoiceNo
            )
        }
      }
}
