
import { AbstractItem } from "../hoardings/abstract-hoarding";
import { InventoryType } from '../hoardings/inventory-type';

export class ContractHoardings extends AbstractItem {
    tradedAsset: boolean;
    clubbed = false;
    vendorName: string;
    rating = 0;
    quantity: number;

    hasChild: boolean;
    childInventories: ContractHoardings[] = [];
    hasDimension: boolean;
    inventoryType: InventoryType;
}
