import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SelectItem } from 'primeng/primeng';
import { empty as observableEmpty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { GridConfig, GridPaginationEvent, SelectionMode } from '../../components/sib-forms/grid/grid.config';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { DateUtil } from '../../helpers/date.util';
import * as utils from '../../helpers/utils';
import { Roi } from '../../modals/analytics/Roi';
import * as roiContractDataColumnConfig from '../../modals/column-configurations/roi-contract-data-column-config';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { HoardingMasterColService } from '../../services/shared/hoarding-master-column.service';
import { UserGridConfigService } from '../../services/shared/user-grid-config.service';
import { AppUrls } from '../../services/urls';
import { GridNameEnum } from '../../shared/constants/grid-name.enum';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { UntappedRoiComponent } from './untapped-roi/untapped-roi.component';
import { ViewRoiContractInventoryComponent } from './view-roi-contract-inventory/view-roi-contract-inventory.component';

@Component({
  selector: 'sib-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoiComponent implements OnInit {

  @ViewChild(GridComponent) gridViewChildRef: GridComponent<Roi>;
  @ViewChild(UntappedRoiComponent) untappedRoiGridViewChildRef: UntappedRoiComponent;
  @ViewChild('contractInventory') inventoryGridViewChildRef: ViewRoiContractInventoryComponent;
  @ViewChild('chequeDetail') chequeDetailComponent;

  isLoading: boolean = false;

  gridConfig: GridConfig<Roi> = new GridConfig<Roi>();

  contractDataColumns = roiContractDataColumnConfig.ROIContractDataColumns;
  totalRecords: number;
  startDate: Date;
  endDate: Date;
  chequeDetails: any[] = [];
  viewContractInventory: boolean = false;
  contractId: string;

  showXlsExport = true;
  isFileDownloading = false;
  xlsLoader: PPTLoader = new PPTLoader();
  searchEvent: GridPaginationEvent;
  modifiedColumns: SelectItem[] = [];
  selectedColumns: any[] = [];
  checked: boolean = false; // contract toggle
  columns: any[] = [];
  endDateMin: Date;
  currentDate = new Date();
  contractName: string;

  constructor(
    private pageTitle: Title,
    private dashboardService: DashboardService,
    private notificationServcie: NotificatoinsService,
    private loaderSubjects: LoaderSubjects,
    private hmColService: HoardingMasterColService,
    private userGridConfigService: UserGridConfigService,
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.ROI_SUBMENU) : this.pageTitle.setTitle(SIBConstants.ROI_SUBMENU + ' - ' + appTitle);
    this.setUserGridConfig();
    this.setDefaultDates(); // set default start and end date
    this.setGridConfigObject(); // set grid configuration
  }

  /**
   * @description set grid column configuration
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof RoiComponent
   */
  setUserGridConfig() {
    this.userGridConfigService.get(null, AppUrls.SLASH + GridNameEnum['ROI']).subscribe((roiContractDataGridConfig) => {
      let columns = [];
      this.contractDataColumns = utils.getUserConfiguredColumns(roiContractDataGridConfig, this.contractDataColumns);
      // this.hmColService.defaultColumns.next(this.contractDataColumns);
      this.gridViewChildRef.ngOnInit();
      this.contractDataColumns.forEach(column => {
        if (column.permanent) {
          columns.push(column.header);
        } else {
          if (column.field === roiContractDataGridConfig[column.header]) {
            columns.push(column.header);
          }
        }
      });
      this.updatingUserGridConfig(columns);
    });
  }

  ngAfterViewInit() {
    this.modifiedColumns = this.gridViewChildRef.getModifiedColumns();
    this.hmColService.defaultColumns.subscribe((defaultColumns) => {
      this.selectedColumns = [];
      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          this.selectedColumns.push(column['header']);
        }
      });
      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }
    });
  }

  /**
   * @description set min end date
   * @author Divya Sachan
   * @date 2019-11-19
   * @memberof RoiComponent
   */
  setEndDateMin() {
    this.endDateMin = new Date(this.startDate);
  }

  /**
   * @description set start date as current financial year's start date and end date as today's date
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  setDefaultDates() {
    // let currentMonth = new Date().getMonth();
    let dates = String(DateUtil.getCurrentFiscalYear()).split('-');
    // let dates = DateUtil.getLastSixMonthStartDateEndDate(Number(currentMonth));
    this.startDate = new Date(Number(dates[0]), 3, 1, new Date().getHours());
    // this.endDate = new Date(Number(dates[1]), 3, 0, new Date().getHours());
    this.endDate = new Date();
  }
  /**
   * @description set grid config properties
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  setGridConfig() {
    this.gridConfig.getDefaultModel = () => new Roi();
    this.gridConfig.model = Roi;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
  }

  /**
   * @description set grid data
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  setGridConfigObject() {
    this.setGridConfig();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen;

      this.isLoading = true;

      this.setPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, paginationEvent, AppUrls.GETROIDATA).pipe(map(response => {
        if (response) {
          this.searchEvent = paginationEvent;
          this.totalRecords = response['data']['totalElements'];
          return response['data']['content'];
        }
      }), catchError(error => {

        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          this.isLoading = false;
          return observableEmpty();
        }
      }));
    };
  }

  /**
   * @description set pagination Event 
   * @author Divya Sachan
   * @date 2019-11-08
   * @param {*} paginationEvent
   * @memberof RoiComponent
   */
  setPaginationEvent(paginationEvent) {
    let filter = {
      "serviceDate": {
        value: [new Date(this.startDate), new Date(this.endDate)],
        matchMode: "dateBetween"
      }
    };
    if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
      paginationEvent.sortField = 'differenceAmount';
      paginationEvent.sortOrder = 1;
    }
    Object.assign(paginationEvent.filters, filter);
  }

  /**
   * @description validate dates and refresh on start date selection
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  onStartDateSelection(startDate) {
    this.setEndDateMin();
    if (new Date(startDate.value).valueOf() > new Date(this.endDate).valueOf()) {
      this.endDate = new Date(startDate.value);
    }
    if (this.endDate) {
      this.refresh();
    }

  }

  /**
   * @description  validate dates and refresh on end date selection
   * @author Divya Sachan
   * @date 2019-11-19
   * @param {*} endDate
   * @memberof RoiComponent
   */
  onEndDateSelection(endDate) {
    if (!DateUtil.dateComparator(endDate.value, this.startDate)) {
      this.endDate = null;
    }
    if (this.endDate) {
      this.refresh();
    }
  }

  /**
   * @description show the cheque details
   * @author Divya Sachan
   * @date 2019-11-08
   * @param {*} rowData
   * @memberof RoiComponent
   */
  openChequeDetails(rowData) {
    if (rowData.contract.cheques && rowData.contract.cheques.length > 1) {
      this.chequeDetails = [];
      for (let i = 1; i < rowData.contract.cheques.length; i++) {
        this.chequeDetails.push(rowData.contract.cheques[i]);
      }
    }
  }

  /**
   * @description view contract inventory
   * @author Divya Sachan
   * @date 2019-11-08
   * @param {*} event
   * @memberof RoiComponent
   */
  viewInventory(rowData) {
    this.contractId = rowData.contract.id;
    this.contractName = rowData.contract.name;
    this.viewContractInventory = true;
    if (this.inventoryGridViewChildRef && this.inventoryGridViewChildRef.gridViewChildRef) {
      this.inventoryGridViewChildRef.gridViewChildRef.refresh();
    }

  }

  /**
   * @description get rounded off value
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} value
   * @returns
   * @memberof RoiComponent
   */
  getIntegerRoundedOffValue(value) {
    return utils.getIntegerRoundedOffValue(value);
  }

  /**
   * @description view contract while traversing back from inventory
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} event
   * @memberof RoiComponent
   */
  viewContract(event) {
    this.viewContractInventory = event;
  }

  /**
   * @description save column configuration
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} event
   * @memberof RoiComponent
   */
  updatingUserGridConfig(event) {
    let roiContractDataGridConfig: Map<string, string> = new Map();
    roiContractDataGridConfig = utils.getGridConfigMap(event, this.contractDataColumns);
    this.userGridConfigService.patch(utils.buildObject(roiContractDataGridConfig), null, GridNameEnum['ROI'])
      .subscribe((result) => {
      });
  }

  /**
   * @description export XLS
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof RoiComponent
   */
  exportXlsx() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader);
    this.loaderSubjects.roiContractDataXlsLoader.next(this.xlsLoader);
    this.isFileDownloading = false;
  }

  /**
   * @description create Object for XLS 
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} fileType
   * @param {*} object
   * @memberof RoiComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
  }

  /**
   * @description show untapped contracts on toggle
   * @author Divya Sachan
   * @date 2019-11-14
   * @param {*} event
   * @memberof RoiComponent
   */
  showUntappedContracts(event) {
    this.refresh();
  }

  /**
   * @description refresh grid
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof RoiComponent
   */
  refresh() {
    if (this.checked) {
      this.untappedRoiGridViewChildRef.setGridConfigObject();
    } else {
      this.setUserGridConfig();
      this.gridViewChildRef.refresh();
    }

  }

  /**
   * @description get absolute value
   * @author Divya Sachan
   * @date 2019-11-19
   * @param {*} value
   * @returns
   * @memberof RoiComponent
   */
  getAbsoluteValue(value) {
    return utils.getAbsoluteValue(value);
  }

  /**
   * @description get decimal value fixed to 2 decimal places
   * @author Divya Sachan
   * @date 2019-11-28
   * @param {*} value
   * @returns
   * @memberof RoiComponent
   */
  getValueParsedToTwoDecimalPlaces(value) {
    return utils.getDecimalNumFixedToTwoPlaces(value);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.chequeDetailComponent.hide();
    }

  }
}
