/**
* @file BillingModule
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutoCompleteModule, ButtonModule, DataTableModule, InputTextModule, InputTextareaModule, MultiSelectModule, DropdownModule, CalendarModule, SliderModule, DialogModule, CheckboxModule, RatingModule, DragDropModule, SharedModule, TreeTableModule, FileUploadModule, GalleriaModule, LightboxModule, OverlayPanelModule, SpinnerModule, TabViewModule, TabMenuModule, PanelModule, ConfirmDialogModule, ConfirmationService, GrowlModule, GMapModule, ProgressSpinnerModule, MenuModule, MenubarModule, TieredMenuModule, ProgressBarModule, ChartModule, StepsModule, SlideMenuModule, InputSwitchModule, KeyFilterModule, TooltipModule } from "primeng/primeng";
import { SibGridModule } from "../components/sib-forms/grid/grid.module";

import { BillingEffects } from "./effects/billing.effects";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers } from "./billing-module.reducers";
import { BillingActions } from "./actions/billing-actions";
import { BillingRoutingModule } from "./billing-routing.module";
import { BillingComponent } from "./billing.component";
import { SharedModules } from "../modules/shared.modules"
import { MapDialogModule } from "../shared/components/map-dialog/map-dialog.module";
import { CurrencyMainPipe } from "../shared/helpers/currency.pipe/currency.main.pipe";
import { CompanyService } from "../services/shared/company.service";
import { AddHoardingDialogModule } from "../shared/components/add-hoarding-dialog/add-hoarding-dialog.module";
import { AssignToPlanDialogModule } from "../shared/components/assignToPlanDialog/assign-to-plan-dialog.module";
import { StatusQueueDialogModule } from "../shared/components/status-queue-dialog/status-queue-dialog.module";
import { CommonDataTableModule } from "../shared/components/dataTable/dataTable.module";

import { PreviewSendDialogModule } from "../shared/components/preview-send-dialogue/preview-send-dialog.module";
import { SibPPTPreviewModule } from "../shared/components/sib-ppt-preview/sib-ppt-preview.module";
import { SibEmailModule } from "../shared/components/sib-email/sib-email.module";
import { SuperbillListComponent } from './superbill-list/superbill-list.component';
import { SuperbillViewComponent } from "./superbill-view/superbill-view.component";
import { SuperbillHoardingsComponent } from "./superbill-hoardings/superbill-hoardings.component";
import { UploadPODialogModule } from "../shared/components/upload-PO/upload-PO-dialog.module";
import { BillViewComponent } from "./bill-view/bill-view.component";
import { BillingGridService } from "./services/billing-grid.service";
import { BillingActionDispatcher } from "./action-dispatcher/action-dispatcher";
import { InvoiceService } from "../services/shared/invoice.service";
import { SuperBillsService } from "./services/superbill.service";
import { MigratedInvoiceService } from "./services/migrated-invoice.service";
import { CompareBillsModule } from "../shared/components/compareBills/compareBills.module";
import { UploadPOService } from "../services/shared/uploadPO.service";
import { SibBackModule } from "../shared/components/sib-back/sib-back.module";
import { BillingNotesModule } from "../shared/components/billing-notes/billing-notes.module";
import { SuperbillInvoicesComponent } from "./superbill-invoices/superbill-invoices.component";
import { SibChartComponent } from "../sib-chart/sib-chart.component";
import { GooglePieChartService } from "../services/shared/pieChart.services";
import { GoogleStackedBarChartService } from "../services/stackedBarChart.service";
import { GenerateBillComponent } from "./generate-bill/generate-bill.component";
import { CombinedItemListComponent } from "./combined-item-list/combined-item-list.component";
import { AddInvoiceDetailsComponent } from "./add-invoice-details/add-invoice-details.component";
import { InvoicePreviewComponent } from "./invoice-preview/invoice-preview.component";
import { InvoiceSummaryComponent } from "./invoice-summary/invoice-summary.component";
import { CampaignService } from "../services/shared/campaign.service";
import { CampaignQueryParams } from "../modals/queryparams/campaign-queryparams";
import { InvoiceSummaryGridComponent } from "./invoice-summary-grid/invoice-summary-grid.component";
import { SuperbillTasksComponent } from "./superbill-tasks/superbill-tasks.component";
import { SuperbillCampaignsComponent } from "./superbill-campaigns/superbill-campaigns.component";
import { InvoiceListComponent } from "./invoice-list/invoice-list.component";
import { TaskCardsComponent } from "./task-cards/task-cards.component";
import { CanDeactivateGenerateInvoiceGuardService } from "./services/canDeactivateGenerateInvoiceGuard.service";
import { PolViewComponent } from "./po-view/po-view.component";
import { PendingInvoiceService } from "../services/shared/pending-invoice.service";
import { BillingConfigurationService } from "../services/shared/billing-configuration.service";
import { TaxConfigurationService } from "../services/shared/tax-configuration.service";
import { RouterStateSnapshot } from "@angular/router";
import { CanDeactivateSuperBillViewGuardService } from "./services/canDeactivateSuperBillViewGuard.service";
import { BillingUIService } from "./services/billingUI.service";
// import { CanActivateSuperBillListGuardService } from "./services/canActivateSuperBillListGuard.service";
import { CanActivateSuperBillViewGuardService } from "./services/canActivateSuperBillViewGuard.service";
import { CanActivateGenerateBillGuardService } from "./services/canActivateGenerateBillGuard.service";
import { CanActivateBillViewGuardService } from "./services/canActivateBillViewGuard.service";
import { StatusVisualizationModule } from "../shared/components/status-visualization/status-visualization.module";
import { SibChartModule } from "../sib-chart/sib-chart.module";
import { InvoiceChartComponent } from './invoice-chart/invoice-chart.component';
import { AdjsutSuperbillModule } from '../shared/components/adjust-superbill/adjust-superbill.module';
import { SuperbillChartComponent } from './superbill-chart/superbill-chart.component';
import { DirectiveModules } from '../modules/directive.modules';
import { InvoiceChartService } from '../services/shared/invoice-chart.service';
import { ReserveInvoicesComponent } from './reserve-invoices/reserve-invoices.component';
import { CanActivateReserveInvoiceGuardService } from './services/canActivateReserveInvoiceGuard.service';
import { CreateCustomerService } from '../customers/services/createCustomerService';
import { ReserveInvoiceDetailDialogModule } from '../shared/components/reserve-invoice-detail-dialog/reserve-invoice-detail-dialog.module';
import { MigratedInvoicesComponent } from './migrated-invoices/migrated-invoices.component';
import { fromPromise } from 'rxjs/internal-compatibility';
// import { MigratedInvoiceComponent } from './generate-bill/migrated-invoice/migrated-invoice.component';
// import { GenerateMigratedInvoiceComponent } from './generate-migrated-invoice/generate-migrated-invoice.component';
import { SuperbillInvoicesListComponent } from './superbill-invoices-list/superbill-invoices-list.component';
import { SuperbillMigratedListComponent } from './superbill-migrated-list/superbill-migrated-list.component';
import { UpdateInvoiceDialogModule } from '../shared/components/update-invoice-dialog/update-invoice-dialog.module';
import { CreditNotesService } from '../services/shared/credit-note.service';
import { CreditNotesModule } from '../shared/components/credit-notes/credit-notes.module';
import { CNViewComponent } from './cn-view/cn-view.component';
import { CanActivateCNViewGuardService } from './services/CanActivateCNlViewGuard.service';
import { CNPreviewComponent } from './cn-preview/cn-preview.component';
import { CNSummaryComponent } from './cn-summary/cn-summary.component';
import { CNSummaryGridComponent } from './cn-summary-grid/cn-summary-grid.component';
import { DocumentModule } from "../shared/components/documents/document.module";
import { ReserveCreditNotesComponent } from './reserve-credit-notes/reserve-credit-notes.component';
import { CanActivateReserveCreditNoteGuardService } from './services/canActivateReserveCreditNoteGuard.service';
import { ReserveCNDetailDialogComponent } from './reserve-credit-note-detail-dialog/reserve-credit-note-detail-dialog.component';
import { GoogleAreaChartService } from '../services/shared/area-chart.service';
// import { SuperbillInvoicesListnodeComponent } from './superbill-invoices-listnode/superbill-invoices-listnode.component';
import { InputTrimModule } from "../directives/input-trim/input-trim.module";
import { ReserveCreditNotesModule } from './reserve-credit-notes/reserve-credit-notes.module';
import { ReserveCreditNoteDialogModule } from './reserve-credit-note-detail-dialog/reserve-credit-note-dialog.module';
import { InvoicesPrintConfigurationService } from '../services/shared/invoices-print-configuration.service';
import { AnalyticsModule } from '../analytics/analytics.module';
import { SuperbillChartModule } from './superbill-chart/superbill-chart.module';
import { RowsRearrangeModule } from '../shared/components/rows-rearrange/rows-rearrange.module';

import { UploadDocumentModule } from '../shared/components/upload-document/upload-document.module';
import { CurrencyHelperPipe } from '../shared/helpers/currency.pipe/currencyHelperPipe';
import { PrintPannelLayoutModule } from '../shared/components/print-pannel-layout/print-pannel-layout.module';
import { CommonService } from '../services/shared/common.service';
import { StatesService } from '../services/shared/statesService.service';

@NgModule({
    imports: [
        CommonModule,
        BillingRoutingModule,
        TabViewModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        AutoCompleteModule,
        ButtonModule,
        SibGridModule,
        DataTableModule,
        InputTextModule,
        InputTextareaModule,
        // StoreModule.forRoot(reducers),
        StoreModule.forFeature('billings', reducers),
        MultiSelectModule,
        DropdownModule,
        CalendarModule,
        SliderModule,
        CheckboxModule,
        RatingModule,
        DragDropModule,
        DialogModule,
        TreeTableModule,
        SharedModule,
        FileUploadModule,
        GalleriaModule,
        LightboxModule,
        OverlayPanelModule,
        SpinnerModule,
        PanelModule,
        TabMenuModule,
        TabViewModule,
        ConfirmDialogModule,
        // EffectsModule.forRoot([BillingEffects]),
        EffectsModule.forFeature([BillingEffects]),
        GMapModule,
        MapDialogModule,
        ProgressSpinnerModule,
        AddHoardingDialogModule,
        AssignToPlanDialogModule,
        StatusQueueDialogModule,
        CommonDataTableModule,
        CurrencyMainPipe,
        MenuModule,
        TieredMenuModule,
        SlideMenuModule,
        PreviewSendDialogModule,
        ChartModule,
        ProgressBarModule,
        UploadPODialogModule,
        CompareBillsModule,
        SibBackModule,
        BillingNotesModule,
        StepsModule,
        StatusVisualizationModule,
        SibChartModule,
        AdjsutSuperbillModule,
        DirectiveModules,
        InputSwitchModule,
        KeyFilterModule,
        TooltipModule,
        ReserveInvoiceDetailDialogModule,
        UpdateInvoiceDialogModule,
        CreditNotesModule,
        DocumentModule,
        InputTrimModule,
        ReserveCreditNotesModule,
        ReserveCreditNoteDialogModule,
        AnalyticsModule,
        SuperbillChartModule,
        RowsRearrangeModule,
        UploadDocumentModule,
        PrintPannelLayoutModule
    ],

    declarations: [
        BillingComponent,
        SuperbillListComponent,
        SuperbillViewComponent,
        GenerateBillComponent,
        SuperbillHoardingsComponent,
        BillViewComponent,
        SuperbillInvoicesComponent,
        AddInvoiceDetailsComponent,
        CombinedItemListComponent,
        InvoicePreviewComponent,
        InvoiceSummaryComponent,
        InvoiceSummaryGridComponent,
        SuperbillCampaignsComponent,
        SuperbillTasksComponent,
        InvoiceListComponent,
        TaskCardsComponent,
        PolViewComponent,
        InvoiceChartComponent,
        // SuperbillChartComponent,
        ReserveInvoicesComponent,
        // ReserveCreditNotesComponent,
        MigratedInvoicesComponent,
        // MigratedInvoiceComponent,
        // GenerateMigratedInvoiceComponent,
        SuperbillInvoicesListComponent,
        SuperbillMigratedListComponent,
        CNViewComponent,
        CNPreviewComponent,
        CNSummaryComponent,
        CNSummaryGridComponent,
        // ReserveCNDetailDialogComponent
    ],

    exports: [
        SuperbillChartModule,
    ],
    providers: [
        ConfirmationService,
        BillingActionDispatcher,
        BillingActions,
        BillingGridService,
        InvoiceService,
        PendingInvoiceService,
        SuperBillsService,
        MigratedInvoiceService,
        UploadPOService,
        GooglePieChartService,
        GoogleStackedBarChartService,
        GoogleAreaChartService,
        CampaignService,
        CampaignQueryParams,
        CanDeactivateGenerateInvoiceGuardService,
        BillingConfigurationService,
        TaxConfigurationService,
        CanDeactivateSuperBillViewGuardService,
        BillingUIService,
        // CanActivateSuperBillListGuardService,
        CanActivateSuperBillViewGuardService,
        CanActivateGenerateBillGuardService,
        CanActivateBillViewGuardService,
        InvoiceChartService,
        CanActivateReserveInvoiceGuardService,
        CreateCustomerService,
        CreditNotesService,
        CanActivateCNViewGuardService,
        CanActivateReserveCreditNoteGuardService,
        InvoicesPrintConfigurationService,
        CommonService,
        StatesService
        // RouterStateSnapshot
    ]
})

export class BillingModule { }
