export enum ImageTypeEnum {
    NEWSPAPER = "Newspaper",
    // TRAFFIC = "Traffic",
    // DISTANCE = "Distance",
    SHORT = "Short",
    LONG = "Long",
    PRIMARY = "Primary",
    GPS = "GPS"
    // RANDOM = "Random"
}
