import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Address } from "./address";
import { Breakeven } from "./breakevenamount";
import { ChequeDetail } from "./chequedetail";
import { PersonInfo } from "./personinfo";
import { SecurityDeposit } from './security-deposit';

export class Contracts extends SibBaseObject {
    name: string;
    type: string;
    totalAssets: number;
    totalSqFt: number;
    groupedAssets: number;
    grouppedSqFt: number;
    unitAssets: number;
    unitSqFt: number;

    areas: any[] = [];
    startDate: Date;
    endDate: Date;
    breakevens: Breakeven[] = [];
    id: string;
    address: Address = new Address();
    person: PersonInfo = new PersonInfo();
    status: any;
    cheques: ChequeDetail[] = [];
    documents: Document[] = [];
    contractId: string;
    securityDeposit: SecurityDeposit = new SecurityDeposit();

}