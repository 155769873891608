/**
 * Programmer:  Sanchit Mirg
 * Date:        09-23-2017
 * Description: Declaring routes for the application
 * 
 */
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardDemo } from './demo/view/dashboarddemo';
import { SampleDemo } from './demo/view/sampledemo';
import { FormsDemo } from './demo/view/formsdemo';
import { DataDemo } from './demo/view/datademo';
import { PanelsDemo } from './demo/view/panelsdemo';
import { OverlaysDemo } from './demo/view/overlaysdemo';
import { MenusDemo } from './demo/view/menusdemo';
import { MessagesDemo } from './demo/view/messagesdemo';
import { MiscDemo } from './demo/view/miscdemo';
import { EmptyDemo } from './demo/view/emptydemo';
import { ChartsDemo } from './demo/view/chartsdemo';
import { FileDemo } from './demo/view/filedemo';
import { UtilsDemo } from './demo/view/utilsdemo';
import { Documentation } from './demo/view/documentation';
import { AppComponent } from './app.component';
import { AuthGuardService } from './login-service/auth-guard.service';
import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { ListPaginationSearchComponent } from './list-pagination-search/list-pagination-search.component';
import { TestListCompComponent } from './test-list-comp/test-list-comp.component';
import { HoardingsComponent } from './hoardings/hoardings.component';
import { PlanModule } from './plans/plan.module';
import { EventComponent } from './events/event.component';
import { PlaygroundComponent } from './playground/playground.component';
import { PublicPageComponent } from './public-page/public-page.component';
import { PhotoInterfaceComponent } from './shared/components/photo-interface/photo-interface/photo-interface.component';
import { ActivityLogComponent } from './shared/components/activity-log/activity-log.component';
import { CustomerProfilePageComponent } from './shared/components/customer-profile-page/customer-profile-page.component';
import { SibChartComponent } from './sib-chart/sib-chart.component';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentComponent } from './shared/components/documents/document.component';
import { TaskComponent } from './shared/components/tasks/tasks.component';
import { PassbookComponent } from './shared/components/passbook/passbook.component';
import { CanActivatePassbookGuardService } from './shared/components/passbook/services/canActivatePassbookGuard.service';
import { HoardingMapComponent } from './shared/components/hoarding-map/hoarding-map.component';
import { SuggestionViewComponent } from './suggestion-view/suggestion-view.component';
import { HoardingMapViewComponent } from './shared/components/hoarding-map-view/hoarding-map-view.component';

// import { HoardingMapViewComponent } from './hoardings/hoarding-map-view/hoarding-map-view.component';
import { SIBConstants } from './shared/constants/SIBConstant';
import { AppCustomPreloader } from './app-custom-preloader';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { CanActivateMenuGuard } from './canActivateMenuGuard.service';
import { UnauthorizedPageComponent } from './shared/components/unauthorized-page/unauthorized-page.component';
// import { AreaComponent } from './masters/area/area.component';
// import { IndustryComponent } from './masters/Industry/industry.component';



/**
 * Creating a constant app_routes and mentioning 
 * all the path and components that will be loadded corresponding to that path
 */
export const APP_ROUTES: Routes = [
  {
    /**
     * Before loading the Maincomponent AuthGaurd Service is called
     * which will check if the user is authenticated or not.
     * If user is authenticated then main dashboard will be loaded
     * else user will be redirected to auth lock screen for login
     */
    path: '', component: MainComponent,
    canActivate: [AuthGuardService],
    /*  children: [{
       component: DashboardDemo, path: '', data: { title: "Dashboard" },
     }, */
    children: [{
      component: DashboardComponent, path: '', data: { title: "Dashboard" },
    },
    /* {
      component: DashboardComponent, path: 'dashboard', data: { title: "Dashboard" },
    }, */
    {
      component: TestListCompComponent, path: 'datatable', data: { title: "Data Table" },
    },
    /**
     * Customer is lazy loaded Module
     */
    // canActivate: [CanActivateMenuGuard]
    { path: 'customers', loadChildren: './customers/customers.module#CustomersModule' },
    //   { path: 'area', loadChildren: './area/area.module#AreaModule'},
    //   {path: 'industry', loadChildren: './masters/masters.module#MastersModule'},
    { path: 'users', loadChildren: './users/users.module#UserModule' },
    { path: 'contracts', loadChildren: './contracts/contracts.module#ContractsModule' },
    { path: 'masters', loadChildren: './masters/masters.module#MastersModule' },
    // { path: 'hoardings', component: HoardingsComponent, data: {title: "Hoardings"} },
    { path: 'hoardings', loadChildren: './hoardings/hoarding.module#HoardingModule', data: { preload: true } },
    { path: 'plans', loadChildren: './plans/plan.module#PlanModule', data: { preload: true } },
    { path: 'campaigns', loadChildren: './campaigns/campaign.module#CampaignModule' },
    { path: 'events', component: EventComponent, data: { title: "Events" } },
    { path: 'hoardings-operation', loadChildren: './hoardings-operation/hoardings-operation.module#HoardingsOperationModule' },
    { path: 'operations', loadChildren: './hoardings-operation/hoardings-operation.module#HoardingsOperationModule' },
    { path: 'billings', loadChildren: './billings/billing.module#BillingModule' },

    { path: 'photo-interface', component: PhotoInterfaceComponent, data: { title: "Photo Interface" } },

    { path: 'activity', component: ActivityLogComponent, data: { title: "Activity Log" } },

    { path: 'documents', component: DocumentComponent, data: { title: "Documents" } },

    { path: 'tasks', component: TaskComponent, data: { title: "Tasks" } },

    { path: 'passbook', component: PassbookComponent, data: { title: "Passbook" }, canActivate: [CanActivatePassbookGuardService] },

    { path: 'suggestion', component: SuggestionViewComponent, data: { title: "Suggestinos" } },

    { path: 'hoardingMap', component: HoardingMapViewComponent, data: { title: "Hoarding Map" } },

    { path: 'customer-profile-page/:customerId', component: CustomerProfilePageComponent, data: { title: "Customer Profile" } },
    { path: 'profile', component: UserProfileComponent, data: { title: "Profile" } },
    { path: 'subscription-plans', component: SubscriptionPlansComponent, data: { title: "Subscription Plans" } },


    { path: 'sample', component: SampleDemo },
    { path: 'forms', component: FormsDemo },
    { path: 'data', component: DataDemo },
    { path: 'panels', component: PanelsDemo },
    { path: 'overlays', component: OverlaysDemo },
    { path: 'menus', component: MenusDemo },
    { path: 'messages', component: MessagesDemo },
    { path: 'misc', component: MiscDemo },
    { path: 'empty', component: EmptyDemo },
    { path: 'charts', component: ChartsDemo },
    { path: 'file', component: FileDemo },
    { path: 'utils', component: UtilsDemo },
    { path: 'documentation', component: Documentation },
    { path: 'playground', component: PlaygroundComponent },
    { path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsModule' },
    {
      path: 'reports', loadChildren: './reports/reports.module#ReportsModule'
    }
    ]
  },
  { path: 'plans/public/view/map/:id/:tenantId', component: HoardingMapComponent, data: { title: "Customer Profile" } },
  { path: 'public-page/:campaignId/:x_tenant', component: PublicPageComponent },
  { path: 'public-page/plan/:planId/:x_tenant',component:PublicPageComponent},
  { path: 'unauthorized',component:UnauthorizedPageComponent}
];

export const appRoutingProviders: any[] = [

];

/**
 * All the routes are registered with angular through RouterModule import
 * And the RouterModule is exported so that it can be included in the shared Module
 * and the shared module is imported in app module.
 * 
 * @export
 * @class AppRoutingModule
 */
@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { useHash: false, enableTracing: false, preloadingStrategy: AppCustomPreloader })],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES,{useHash:false,enableTracing:true});

export class AppRoutingModule { }
