/**
 * @file  Price.
 * @author ParthL@meditab.com
 * @copyright Meditab Software 2017
 */
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { PlanHoarding } from "./planHoarding";

export class Price {
  pricePerUnit: number = 0; // if perUnit selected
  pricePerSqFt: number = 0; // if lighttype is other than various and calculation is persqft
  perSqftForVarious: number = 0; // if lighttype is various and calculation is persqft
}
