import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { AreaChartConfig } from '../../modals/charts/areaChart.config';
import * as $ from 'jquery';
import { SIBConstants } from '../../shared/constants/SIBConstant';

declare var google: any;


export class GoogleChartsBaseService {
  chartF: any;
  dataTable: any;
  chartLoaded: Subject<boolean> = new Subject<boolean>();
  @Output() eEmitEvent: EventEmitter<any> = new EventEmitter<any>();
  selectedColumnLabel: string;

  constructor() {
    google.charts.load('current', { language: 'hi-IN', 'packages': ['corechart', 'gantt', 'timeline', 'table', 'bar'] });
    // , 'bar'
  }
  protected buildChart(data: any, chartFunc: any, options: any, config?: any): void {
    // tslint:disable-next-line:no-shadowed-variable
    const func = (chartFunc, options) => {
      // this.chartF = chartFunc();
      const chart = chartFunc();
      this.chartF = chart;
      this.dataTable = chart.container.id !== 'employeesSalesChart' ? google.visualization.arrayToDataTable(data) : new google.visualization.DataTable();

      if (chart.container.id === "myAreaChart") {
        this.dataTable.setColumnProperties(2, { role: 'tooltip', html: true });
        this.dataTable.setColumnProperty(3, 'role', 'annotation');
      } else if (chart.container.id === "columnChart") {
        this.dataTable.setColumnProperties(2, { role: 'tooltip', html: true });
        this.dataTable.setColumnProperties(4, { role: 'tooltip', html: true });
      } else if (chart.container.id === "superbillChart") {
        this.dataTable.setColumnProperties(2, { role: 'tooltip', html: true });
        this.dataTable.setColumnProperties(4, { role: 'tooltip', html: true });
      } else if (chart.container.id === 'employeesSalesChart') {
        data.columns.forEach((columnHeader) => {
          columnHeader === 'Employee' ? this.dataTable.addColumn('string', columnHeader) : this.dataTable.addColumn('number', columnHeader);
        })

        this.dataTable.addRows(data.rows);
      }

      if (chart.container.id === SIBConstants.SALES_CHART_ID) {
        chart.draw(this.dataTable, google.charts.Bar.convertOptions(options));
      } else {
        chart.draw(this.dataTable, options);
      }
      // chart.draw(this.dataTable, options);
      this.chartLoaded.next(true);
      google.visualization.events.addListener(chart, 'select', () => {
        var selectedData = this.chartF.getSelection();
        if (config && config.onSelect && data && data.length && selectedData.length !== 0) {
          if (selectedData[0].row !== null) {
            const selectedItem = this.dataTable.getValue(selectedData[0].row, selectedData[0].column);
            this.selectedColumnLabel = this.dataTable.getColumnLabel(selectedData[0].column);
            // this.dataTable.
            config.onSelect(data[selectedData[0].row + 1].concat(this.selectedColumnLabel), this);
          }
        }
      })
      /*  const datatable = google.visualization.arrayToDataTable(data);
       chartFunc().draw(datatable, options); */
      // this.chartLoaded.next(true);
    };
    const callback = () => func(chartFunc, options);
    google.charts.setOnLoadCallback(callback);
  }
}