<div class="mounted-image-container">
  <div class="photo-interface-contanier">

    <div *ngIf="isLoading">
      <div class="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>

    <!-- <div class="ui-g" *ngIf="mountImages?.length > 0"> -->
    <!-- *ngIf="filteredImages?.length >0" -->
    <!-- [loader]="loadButton" -->

    <div class="ui-g" style="height: 90%" *ngIf="!mountImages || mountImages.length === 0">
      <div class="no-image">
        <img src="assets/layout/images/no-images.png" height="195px">
      </div>
    </div>
    <div class="ui-g">
      <p-dataScroller #dscroller [value]="filteredImages" [rows]="imagesPerPage" [inline]="true"
        [scrollHeight]="scrollHeight" (onLazyLoad)="lazyLoadHandler($event)" [buffer]="'1'" [lazy]="true"
        [totalRecords]="totalMountImageCount">
        <ng-template let-t let-i="index" pTemplate="item">
          <div class="image-container">
            <div class="image">

              <div class="image-holder">
                <img src="{{t?.mountingItems?.images?.url}}" (click)="onImageClick(i)">
                <span class="hoarding-id-container1">
                  <p-checkbox name="groupname" value="val1" (onChange)="onCheckboxSelectionChange($event, t, i)"
                    binary="true" [(ngModel)]="checked[i]"></p-checkbox>
                </span>
              </div>
              <div class="image-overlapper">
                <div class="overlapper-content ui-g-12">
                  <div class="ui-g-10 context-cursor">
                    {{t.customId}} - {{t.city}}, {{t.location}} - {{t.size}} -
                    <a *ngIf="t.campaign && (t.campaign.employeeEmail === email || authorized)"
                      class="link-1  after photo-link info-red pointer" href="/campaigns/view/{{t.campaign.id}}"
                      target="_blank">{{
                      t.campaign.displayName}}</a>
                    <span *ngIf="t.campaign && t.campaign.employeeEmail !== email && !authorized">
                      {{ t?.campaign?.displayName}}
                    </span>
                  </div>
                  <div class="ui-g-2 pointer">
                    <i class="fa fa-download" (click)="downloadImage(t,i)"></i>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-template>
        <!-- <p-footer >
            <button  #loadButton type="text" icon="fa-refresh" pButton label="Load more"></button>
        </p-footer> -->
      </p-dataScroller>
    </div>
    <!-- 
    <div class="ui-g" style="height: 90%" *ngIf="!mountImages || mountImages.length === 0">
      <div class="no-image">
        <img src="assets/layout/images/no-images.png" height="250px">
      </div>
    </div> -->
  </div>
</div>