import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { CampaingWrapper } from "./campaign-wrapper";
// import { InvoiceStatus } from "../../shared/constants/invoice-status-enum";
import { RentItem } from "./rent-item";
import { AdditionalItem } from "./additional-item";
import { Note } from "./note";
import { BillingNote } from "./billingNote";
import { PurchaseOrder } from "../campaigns/purchaseOrder";
import { InvoiceGstDetail } from "./invoice-gst-detail";
import { User } from "../user/user";
import { InvoiceCharEnum } from "./invoice-char-enum";
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { PrintConfig } from './print-config';
import { SupplierWrapper } from './supplier-wrapper';
import { AnnexurePrintConfig } from './annexure-print-config';

export class Invoice extends SibBaseObject {
    billGeneratedDate: Date;
    billStartDate: Date;
    billEndDate: Date;
    campaign: CampaingWrapper = new CampaingWrapper();
    // status: InvoiceStatus;
    status: string; // type changed from enum to const
    rentItems: RentItem[] = [];
    printingItems: AdditionalItem[] = [];
    mountingItems: AdditionalItem[] = [];
    rentAmount: number;
    printAmount: number;
    mountAmount: number;
    totalAmount: number;
    actualAmount: number;
    lspDiff: number;
    lspDiffPctg: number;
    campaignDiff: number;
    campaignDiffPctg: number;
    isEdited = false;
    // notes: Note[] = [];
    billingNotes: BillingNote[] = [];
    purchaseOrders: PurchaseOrder[] = [];
    taxAmount: number;
    tallyInvoiceId: string;
    interState: boolean;
    updateGeneratedDate: boolean;
    employee: User = new User();
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    // supplierGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    supplier: SupplierWrapper = new SupplierWrapper();
    placeOfSupply: string;
    printed: InvoiceCharEnum;
    tallyEntered: InvoiceCharEnum;
    customerReceived: InvoiceCharEnum;
    cnRequest = false;
    grouped = false;
    internalNotes: string;
    temp: boolean; // to check if invoice is temp or not
    approveDate: Date;
    rejectDate: Date;

    // Sanchit 18-04-2019 Added invoice id field for temp invoice number
    invoiceId: string;

    remark: string;
    printConfiguration: PrintConfig = new PrintConfig();
    paymentReceived: boolean;
    rowsOrder: string[] = [];
    invoiceFormat: InvoiceFormatEnum;
    itemIds: string[] = [];

    annexurePrintConfiguration: AnnexurePrintConfig = new AnnexurePrintConfig();
}
