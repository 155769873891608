import { Injectable } from "@angular/core";
import { AuthConfigService } from "../authconfig.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IQueryParams } from "../base-service/rest-interfaces";
import { Observable } from "rxjs";

@Injectable()
export class AuthApiService {
  apiUrl: string;
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private authConfigService: AuthConfigService
  ) {
    // this.baseUrl = authConfigService.config.authApiUrl
    this.baseUrl = localStorage.getItem("authApiUrl");
  }

  getApiAccessToken() {
    // JSON.parse(localStorage.getItem('usermanagement-config'))
    // this.authConfigService.config.apiConfigCredentials
    return this.http.post(
      localStorage.getItem("apiAccessTokenUrl"),
      JSON.parse(
        localStorage.getItem("auth-api-config")
      ) /* {
            headers: new HttpHeaders().set('Origin', this.authConfigService.config.redirectUri)
        } */
    );
  }

  getAuthUsers() {
    // headers: new HttpHeaders().set('Authorization', 'Bearer '+apiAccessToken)
    const apiAccessToken = "Bearer " + localStorage.getItem("api_access_token");
    return this.http
      .get(this.baseUrl + "users", {
        headers: new HttpHeaders().set("Authorization", apiAccessToken),
      })
      .subscribe((response) => {});
  }

  getUserInfoByEmail(profile) {
    const apiToken = "Bearer " + localStorage.getItem("api_access_token");
    return this.http.get(
      this.baseUrl + "users-by-email?email=" + profile.email,
      {
        headers: new HttpHeaders().set("Authorization", apiToken),
      }
    );
  }

  getUserManagmentApiAccessToken() {
    return this.http.post(
      localStorage.getItem("apiAccessTokenUrl"),
      JSON.parse(localStorage.getItem("usermanagement-config"))
    );
  }
}
