import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DialogModule,
  ButtonModule,
  SharedModule,
  InputTextModule,
  TabViewModule,
  ProgressSpinnerModule,
  MultiSelectModule,
  CalendarModule,
  InputSwitchModule,
} from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { AddHoardingDialogComponent } from "./add-hoarding-dialog.component";
import { ListHoardingsModule } from "../list-hoardings/list-hoardings.module";
import { PhotoDialogModule } from "../photo-dialog/photo-dialog.module";
import { CommonDataTableModule } from "../dataTable/dataTable.module";
import { HoardingGroupDialogModule } from "../hoarding-group-dialog/hoarding-group-dialog.module";
import { GroupHoardingDialogModule } from "../group-hoarding-dialog/group-hoarding-dialog.module";
import { CommonTreeTableModule } from "../treeTable/tree-table.module";
import { StatusQueueDialogModule } from "../status-queue-dialog/status-queue-dialog.module";
import { HoardingGroupsModule } from "../../../hoarding-groups/hoarding-groups.module";
import { HoardingGroupEditDialogModule } from "../hoarding-group-edit-dialog/hoarding-group-edit-dialog.module";
import { HoardingDialogModule } from "../hoarding-dialog/hoarding-dialog.module";
import { PriceDialogModule } from "../price-dialog/price-dialog.module";
import { HoardingQueryParams } from "../../../modals/queryparams/hoarding-queryparams";
import { GetGroupHoardingsService } from "../../../services/shared/get-group-hoardings.service";
import { GroupCIDsQueryParams } from "../../../modals/queryparams/groupCIDs-queryparams";
import { KiosksModule } from "../kiosks/kiosks.module";
import { CommonHoardingsModule } from "../common-hoardings/common-hoardings.module";
import { CommonKiosksModule } from "../common-kiosks/common-kiosks.module";
import { CommonInventoryGridModule } from "../common-inventory-grid/common-inventory-grid.module";

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    HttpModule,
    ButtonModule,
    SharedModule,
    InputTextModule,
    TabViewModule,
    PhotoDialogModule,
    CommonDataTableModule,
    HoardingGroupDialogModule,
    GroupHoardingDialogModule,
    CalendarModule,
    CommonTreeTableModule,
    ListHoardingsModule,
    ProgressSpinnerModule,
    StatusQueueDialogModule,
    HoardingGroupsModule,
    HoardingGroupEditDialogModule,
    HoardingDialogModule,
    PriceDialogModule,
    MultiSelectModule,
    KiosksModule,
    CommonHoardingsModule,
    CommonKiosksModule,
    CommonInventoryGridModule,
    InputSwitchModule
  ],

  declarations: [AddHoardingDialogComponent],

  exports: [AddHoardingDialogComponent],

  providers: [
    HoardingQueryParams,
    GetGroupHoardingsService,
    GroupCIDsQueryParams,
  ],
})
export class AddHoardingDialogModule {}
