<div class="capacityChartDrilldown" *ngIf="viewCapacityChartDrilldown">
  <div *ngIf="isloading">
    <p-progressSpinner class="loading"></p-progressSpinner>
  </div>
  <div class="flex-action-container">
    <div class="flex-action-item margin-top-8">
      <button
        pButton
        label="MOVE TO PLAN ({{ totalSelectedInventory() }})"
        class="mtab-primary float-right"
        (click)="moveSelectedInventory()"
        [disabled]="
          selectedKiosks.length === 0 &&
          selectedHoardingRows.length === 0 &&
          selectedBqs.length === 0 &&
          selectedPillars.length === 0 &&
          selectedGantries.length === 0 &&
          selectedPickupStands.length === 0 &&
          selectedUnipoles.length === 0 &&
          selectedSignboards.length === 0 &&
          selectedUtilities.length === 0 &&
          selectedLeds.length === 0        
          "
      ></button>
    </div>
    <div class="flex-action-item margin-top-8">
      <button
        pButton
        label="CLEAR"
        class="mtab-primary float-right"
        (click)="resetRows()"
        [disabled]="
          selectedKiosks.length === 0 &&
          selectedHoardingRows.length === 0 &&
          selectedBqs.length === 0 &&
          selectedPillars.length === 0 &&
          selectedGantries.length === 0 &&
          selectedPickupStands.length === 0 &&
          selectedUnipoles.length === 0 &&
          selectedSignboards.length === 0 &&
          selectedUtilities.length === 0 &&
          selectedLeds.length === 0
          "
      ></button>
    </div>
    <div class="flex-action-item margin-top-12">
      <p-multiSelect
        [options]="modifiedColumns"
        defaultLabel="Show Columns"
        [style]="{ width: '132px' }"
        maxSelectedLabels="0"
        name="name"
        [(ngModel)]="selectedColumns"
        (onChange)="emitSetColumns()"
        [dropdownIcon]="'fa fa-cog'"
        appendTo="body"
        (onPanelHide)="updatingUserGridConfig()"
        [displaySelectedLabel]="false"
        [panelStyleClass]="'hm-showcol-drop-down'"
        [styleClass]="'show-column-multi-hoarding'"
        filterPlaceHolder="Search columns"
      >
      </p-multiSelect>
    </div>
    <div class="flex-action-item margin-top-15-4">
      <span
        ><b><span style="color: #564c50">Inventories on date: </span></b
        >{{ getFormattedDate() }}</span
      >
    </div>
  </div>

  <div class="content-pane">
    <p-tabView
      (onChange)="onTabChange($event)"
      [style]="{ 'margin-top': '-40px', padding: '0px' }"
    >
      <div *ngFor="let item of mediaTypes; let i = index">
        <p-tabPanel [header]="getHeader(i)[1]">
          <sib-chart-drilldown-hoardings
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('HOARDING')
            "
            [gridConfig]="hoardingGridConfig"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onHoardingRowSelection($event)"
            [totalRecords]="totalHoardingRecords"
          >
          </sib-chart-drilldown-hoardings>
          <!-- </p-tabPanel>
                  <p-tabPanel header="Kiosks ({{totalKioskRecords}})"> -->
          <sib-chart-drilldown-kiosks
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('KIOSK')
            "
            [gridConfig]="kioskGridConfig"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onKioskRowSelection($event)"
            [totalRecords]="totalKioskRecords"
          >
          </sib-chart-drilldown-kiosks>

          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) && getHeader(i)[0] && getHeader(i)[0].includes('BQS')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onBQSRowSelection($event)"
            (eEmitInventoryCount)="setBQSInventoryCount($event)"
          >
          </sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('PILLAR')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onPillarRowSelection($event)"
            (eEmitInventoryCount)="setPillarInventoryCount($event)"
          >
          </sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('GANTRY')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onGantryRowSelection($event)"
            (eEmitInventoryCount)="setGantryInventoryCount($event)"
          >
          </sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('PICKUP STAND')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onPickupStandRowSelection($event)"
            (eEmitInventoryCount)="setPickupStandInventoryCount($event)"
          >
          </sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('UNIPOLE')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onUnipoleRowSelection($event)"
            (eEmitInventoryCount)="setUnipoleInventoryCount($event)"
          >
          </sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('SIGNBOARD')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onSignboardRowSelection($event)"
            (eEmitInventoryCount)="setSignboardInventoryCount($event)"
          ></sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('UTILITY')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onUtilityRowSelection($event)"
            (eEmitInventoryCount)="setUtilityInventoryCount($event)"
          ></sib-chart-drilldown-inventories>
          <sib-chart-drilldown-inventories
            *ngIf="
              getHeader(i) &&
              getHeader(i)[0] &&
              getHeader(i)[0].includes('LED')
            "
            [clickedData]="clickedData"
            [mediaType]="getHeader(i)[0]"
            (eEmitViewImage)="viewImages($event)"
            (eEmitViewDetail)="viewDetail($event)"
            (eRowSelection)="onLedRowSelection($event)"
            (eEmitInventoryCount)="setLedInventoryCount($event)"
          ></sib-chart-drilldown-inventories>
        </p-tabPanel>
      </div>
    </p-tabView>
  </div>

  <p-dialog
    header="Create Plan"
    *ngIf="createPlan"
    [(visible)]="createPlan"
    [autoAlign]="true"
    [width]="activeIndex === 1 ? 370 : 1200"
    [modal]="true"
    (onHide)="onHideStepperDialog()"
    blockScroll="true"
  >
    <div class="ui-g margin-top--7">
      <div class="ui-g-4 ctext-left">
        <ul class="c-list-inline">
          <li *ngIf="activeIndex !== 0">
            <button
              class="mtab-primary step-btn"
              (click)="previousClick()"
              pButton
              icon="fa fa-angle-double-left"
            ></button>
          </li>
        </ul>
      </div>
      <div
        class="ctext-center"
        [ngClass]="activeIndex === 1 ? 'ui-g-8 margin-left--70' : 'ui-g-4'"
      >
        <ul class="c-list-inline">
          <li>
            <p-steps
              class="c-stepper"
              [model]="planStepItems"
              [(activeIndex)]="activeIndex"
              [readonly]="true"
              (activeIndexChange)="onActiveIndexChange(activeIndex)"
            >
              <!-- (activeIndexChange)="onActiveIndexChange()" -->
            </p-steps>
          </li>
        </ul>
      </div>

      <div class="ui-g-4 ctext-right" *ngIf="activeIndex !== 1">
        <ul class="c-list-inline">
          <li>
            <ul class="c-list-inline">
              <li *ngIf="activeIndex !== 1" class="margin-bottom-5">
                <button
                  class="mtab-primary margin-right-20 step-btn"
                  (click)="nextClick()"
                  pButton
                  icon="fa fa-angle-double-right"
                  [disabled]="selectedInventoryValues.length === 0"
                ></button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="createPlan && activeIndex === 0">
      <div class="ui-g gridScroller">
        <sib-view-inventory
          [columns]="dataTableColumns"
          [gridConfig]="dataTableGridConfig"
          (eEmitRemoveHoarding)="removeHoarding($event)"
        >
        </sib-view-inventory>
      </div>
    </div>

    <div *ngIf="createPlan && activeIndex === 1">
      <sib-assign-to-plan-dialog
        #assignToPlan
        [planEdit]="planEdit"
        [displayPlanDialog]="displayPlanDialog"
        (eEmitChangeDisplay)="hidePlanDialog()"
        (eEmitCreatePlan)="creatPlan($event)"
        (eEmitSavePlan)="creatPlan($event)"
      >
      </sib-assign-to-plan-dialog>
    </div>
  </p-dialog>

  <sib-status-queue-dialog
    *ngIf="displayStatusQueueDialog"
    [displayStatusQueueDialog]="displayStatusQueueDialog"
    [rowData]="rowData"
    [values]="rowData?.statusQueues"
    (eEmitChangeStatusQueueDialogDisplay)="hideStatusQueueDialog()"
  ></sib-status-queue-dialog>

  <sib-photo-dialog
    (displayReset)="changePhotoDisplay($event)"
  ></sib-photo-dialog>
</div>
