import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { AppUrls } from './urls';

export const MASTER_URL = new InjectionToken<string>('MASTER_URL');
export const MASTER_CONFIG = new InjectionToken<string>('MASTER_CONFIG');



@Injectable()
export class SibMasterService {


    public static authWebConfig = new Subject<any>();
    public baseUrl: string;
    public masterConfig: any;

    constructor(
        private Http: HttpClient,
        public injector: Injector
    ) {
        this.baseUrl = this.injector.get(MASTER_URL);
        this.masterConfig = this.injector.get(MASTER_CONFIG);

    }

    getMasterAccessToken() {
        return this.Http.post(this.masterConfig.masterTokenUrl, this.masterConfig.config);
    }

    getAuthConfig(url) {
        return this.Http.get(this.baseUrl + '/vendorConfigs/search/vendor-domain?vendorDomain=' + url)
    }

    getSubscriptionPlans() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + AppUrls.PACKAGES_LIST);
        }
    }

    getVendors(tenantId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + AppUrls.TENANTS + AppUrls.SLASH + tenantId);
        }
    }
    getPartnerVendors(tenantId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + AppUrls.TENANTS + AppUrls.RECEIVER_VENDORS + AppUrls.SLASH + tenantId);
        }
    }

    markVendorsAsPartner(tenantId, partnerIds) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + AppUrls.TENANTS + AppUrls.UPDATE_PARTNER_VENDORS, partnerIds, { params: { tenantId: tenantId } })
        }
    }

    removePartners(tenantId, partnerIds) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + AppUrls.TENANTS + AppUrls.REMOVE_PARTNER_VENDORS, partnerIds, { params: { tenantId: tenantId } })
        }
    }



    setAuthWebConfig(config) {
        SibMasterService.authWebConfig.next(config);
    }
}