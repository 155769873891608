/**
 * @file  Campaign
 * @author pulkitb@meditab.com
 * @copyright Meditab Software 2017
 */
import { BillingStatusEnum } from "../../shared/constants/billingStatus-enum";
import { SuperBillStatusEnum } from "../../shared/constants/superbill-status-enum";
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Industry } from "../industry/industry";
import { Customer } from "../KYC/customer";
import { Tax } from "../master/tax";
import { MountingPrice } from "./../plans/mountingPrice";
import { Price } from "./../plans/price";
import { PrintingPrice } from "./../plans/printingPrice";
import { CampaignSummary } from "./campaignSummary";
import { PurchaseOrder } from "./purchaseOrder";
import { User } from "./user";

export class Campaign extends SibBaseObject {
  campaignId: string;
  displayName: string;
  customer: Customer = new Customer();
  campaignStartDate: Date;
  campaignEndDate: Date;
  billStartDate: Date;
  billEndDate: Date;
  totalItems: number;
  totalSqFt: number;
  price: Price = new Price();
  printingPrice: PrintingPrice = new PrintingPrice();
  mountingPrice: MountingPrice = new MountingPrice();
  tax: Tax = new Tax();
  discount: number;
  discountedPrice: number;
  summary: CampaignSummary = new CampaignSummary();
  status: string;
  pending: boolean;
  employee: User;
  billingStatus: BillingStatusEnum;
  billingAmount: number;
  eligibleBillingAmount: number;
  unbilledAmount: number;
  purchaseOrders: PurchaseOrder[] = [];
  lspPctDiff: boolean;
  rotational: boolean;
  defaultMountedItems = 0;
  totalMountItems = 0;
  totalMountedItems = 0;
  totalMonitoringItems = 0;
  totalMonitoredItems = 0;
  totalUnmountedItems = 0;
  totalUnmountingItems = 0;
  kioskPrintingPrice: PrintingPrice = new PrintingPrice();
  kioskMountingPrice: MountingPrice = new MountingPrice();
  superBillStatus: SuperBillStatusEnum;
  extendDays?: number = 0; // field not in backend
  campaignDifference: number; // to store the value of campaign difference as invoices against this campaign are generated /updated
  itemRateApprovalPending: boolean = false; // if true then rate approval is pending
  itemRateChanged: boolean = false; // for superbill
  totalUnutilizedItems = 0;
  bookingDate: Date = new Date();
  mediaTypeToPrintingPrice: any;
  mediaTypeToMountingPrice: any;
  category: Industry = new Industry();
  hasTA: boolean;
}
