import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, DropdownModule, CalendarModule, SharedModule, DataScrollerModule, CheckboxModule, InputTextModule, TabViewModule, TooltipModule, ProgressSpinnerModule } from 'primeng/primeng';
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { WebNotificationsComponent } from './web-notifications.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        SharedModule,
        DataScrollerModule,
        CheckboxModule,
        SibGridModule,
        InputTextModule,
        TabViewModule,
        TooltipModule,
        ProgressSpinnerModule
    ],
    declarations: [
        WebNotificationsComponent
    ],
    exports: [
        WebNotificationsComponent
    ],
    providers: [
        // WebNotificationService
    ]
})
export class WebNotificationsModule { }
