import { MultiMedia } from "./multiMedia";
import { ImageTypeEnum } from "../../shared/constants/image-type-enum";


export class Picture extends MultiMedia {
    imageType?: ImageTypeEnum;  // for newspaper traffic etc
    uploadedDate: Date;
    size: number;
    sameAsFirstStateHeader: boolean;
    sameAsFirstStateFooter: boolean;

}
