<section class="package-container">
  <div class="ribbon" *ngIf="package.name === 'Pro'">
    <div class="wrap">
      <span class="ribbon-text">Recommended</span>
    </div>
  </div>
  <div class="package-title">
    {{package.name | uppercase}}
  </div>
  <div class="package-description">
    <span class="supported-modules-title">
      Supported Modules
    </span>
    <ul class="package-description__modules">
      <li class="package-description__module" *ngFor="let module of packageModules">
        <div *ngIf="package.supportedModules.includes(module)">
          <a class="bs-wizard-dot-supported-module">
            <div class="bs-wizard-dot-with-check"> </div>
          </a>
          <span class="package-description__module-text">{{module | titlecase}}</span>
        </div>
        <div *ngIf="!package.supportedModules.includes(module)">
          <a class="bs-wizard-dot-not-supported-module">
            <div><i class="fa fa-close bs-wizard-close"></i>
            </div>
          </a>
          <span class="package-description__module-text">{{module | titlecase}}</span>
        </div>
      </li>
    </ul>
    <div class="package-footer icon" (click)="onSubscriptionBuy(package)">
      <span class="package-footer-text">
        <span class="buy-title"> Buy @</span>
        <span class="money-description__container">
          <span class="rupee-symbol">
            ₹
          </span>
          {{kFormatter(package.costPerMonth)}}
          <span class="monthly-payment-description">
            / month
          </span>
        </span>
      </span>
    </div>
  </div>
</section>