import { NgModule } from '@angular/core';
import { SharedModules } from '../../../modules/shared.modules';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutoCompleteModule, ButtonModule, DataTableModule, OverlayPanelModule, CheckboxModule, GalleriaModule, InputTextModule, InputTextareaModule, DropdownModule, CalendarModule, SliderModule, DialogModule, ProgressSpinnerModule, DataScrollerModule, MenuModule, GMapModule, TooltipModule } from 'primeng/primeng';
import { InputTrimModule } from '../../../directives/input-trim';
import { PhotoDialogModule } from '../photo-dialog/photo-dialog.module';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { CommonDataTableModule } from '../dataTable/dataTable.module';
import { AssignToPlanDialogModule } from '../assignToPlanDialog/assign-to-plan-dialog.module';
import { MapModule } from '../map/map.module';
import { HoardingService } from '../../../contracts/services/hoardings.service';
import { FirebaseService } from '../../../services/shared/firebase.service';
import { HoardingMapViewComponent } from './hoarding-map-view.component';
import { NotificationsModule } from '../../../notifications/notifications.module';
import { DirectiveModules } from '../../../modules/directive.modules';
import { SibBackModule } from '../sib-back/sib-back.module';
import { SavePlanService } from '../../../services/shared/savePlan.service';
import { UpdatePlanService } from '../../../services/shared/updatePlan.service';

@NgModule({
    imports: [
        SharedModules,
        BrowserModule,
        HttpClientModule,
        SibGridModule,
        ReactiveFormsModule,
        FormsModule,
        AutoCompleteModule,
        ButtonModule,
        DataTableModule,
        OverlayPanelModule,
        CheckboxModule,
        GalleriaModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        InputTrimModule,
        PhotoDialogModule,
        CurrencyMainPipe,
        CommonDataTableModule,
        AssignToPlanDialogModule,
        ProgressSpinnerModule,
        DataScrollerModule,
        MenuModule,
        MapModule,
        GMapModule,
        NotificationsModule,
        DirectiveModules,
        SibBackModule,
        TooltipModule
    ],

    providers: [
        HoardingService,
        FirebaseService,
        SavePlanService,
        UpdatePlanService
    ],

    declarations: [
        HoardingMapViewComponent
    ],


})
export class HoardingMapViewModule { }
