import { MediaSubCategoryEnum } from '../../shared/constants/media-sub-category-enum';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { InventoryState } from '../Area/inventoryState';
import { InventoryDistrict } from '../Area/inventoryDistrict';
import { InventoryCity } from '../Area/inventoryCity';

export class PlanTemplate {
    id: string;
    name: string;
    states: InventoryState[] = [];
    districts: InventoryDistrict[] = [];
    cities: InventoryCity[] = [];
    medias: MediaSubCategoryEnum[] = [];
    lightTypes: HoardingLightEnum[] = [];
    squareFeetFrom: number;
    squareFeetTo: number;
    availableFromDays: number;
    blocked: boolean;
    minimumPriceFrom: number;
    minimumPriceTo: number;
    fromOneCity: number;
    updatedDate: Date;
}