<div id="bottomAd" style="display: none">&nbsp;</div>
<div class="invoice-list">
    <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
    <!-- <div #invoiceCardRef class="invoice-chart-container">
        <sib-invoice-chart *ngIf="billingUIService.getBillingTabIndex() === '2'" [height]="invoiceChartHeight"
            (eEmitChartClickedData)="chart($event)" [width]="invoiceChartWidth"
            (eEmitRefreshEvent)="refreshChart($event)"></sib-invoice-chart>
    </div> -->
    <form [formGroup]="billGroup">
        <!-- <div #invoiceCardRef class="invoice-chart-container">
            <sib-invoice-chart [height]="invoiceChartHeight" [width]="invoiceChartWidth"></sib-invoice-chart>
        </div> -->
        <sib-grid [showFilter]="true" name="billSiteGrid" #billSibGrid [columns]="billColumns"
            [showXlsExport]="showXlsExport" [buttons]="billButtonConfig" [config]="billGridConfig"
            [totalRecords]="invoiceTotalRecords" (eEmitExportXlsx)="exportXlsx()" formControlName="billSiteGridControl"
            [showTallyUpload]="true" (eEmitUploadTally)="uploadTally()"
            (eEmitColumnSelection)="updatingUserGridConfig($event)" ngDefaultControl (eResetDropdown)="resetStatus()">

            <sib-grid-column field="srNo">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span>
                        {{rowIndex+1}}
                    </span>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="invoiceId">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- (click)="viewInvoiceDetails(rowData, $event)" -->
                    <a class="link-1  after" [routerLink]="['/billings/bills/view/'+ getEncodedURI(rowData.id)]">
                        <span
                            *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'; else defaultTemplate">
                            <!-- <del>{{rowData.id}}</del> -->
                            <del>{{rowData.invoiceId}}</del>
                        </span>
                        <ng-template #defaultTemplate>
                            <!-- {{rowData.id}} -->
                            {{rowData.invoiceId}}
                        </ng-template>
                    </a>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="campaign.displayName">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div class="link-wrapper" *ngIf="admin">
                        <!-- (click)="viewCampaign(rowData)" -->
                        <a class="link-1  after"
                            [routerLink]="rowData.campaign.displayName">{{rowData?.campaign?.displayName}}</a>
                    </div>
                    <div class="link-wrapper" *ngIf="!admin">
                        {{rowData?.campaign?.displayName}}
                    </div>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="campaignDiff">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div *ngIf="rowData.campaignDiff < 0" class="negDiff">
                        {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiffPctg))
                        | CurrencyConverter}}%)
                    </div>
                    <div *ngIf="rowData.campaignDiff >= 0" class="posDiff">
                        {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiffPctg))
                        | CurrencyConverter}}%)
                    </div>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="lspDiff">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div *ngIf="rowData.lspDiff < 0" class="negDiff">
                        {{getAbsoluteValue(parseValueToDecimal(rowData.lspDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.lspDiffPctg))
                        | CurrencyConverter}}%)
                    </div>
                    <div *ngIf="rowData.lspDiff >= 0" class="posDiff">
                        {{parseValueToDecimal(rowData.lspDiff) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.lspDiffPctg))
                        | CurrencyConverter}}%)
                    </div>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="purchaseOrders">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- <img class="custom-grid-icon" src="assets/layout/images/details.svg"
                        alt="" title="" (click)="showOverlay($event, rowData, rowIndex)"> -->
                    <i *ngIf="rowData?.purchaseOrders?.length > 0" class="custom-grid-icon icon-contract"
                        (click)="showOverlay($event, rowData, rowIndex)" title="PO"></i>
                    <div *ngIf="rowData?.purchaseOrders?.length === 0">-</div>


                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="printed">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- <i class="icon-printer custom-icon" title="{{rowData?.printed==='T' ? 'True' : 'False'}}" [ngClass]="{'true-color': rowData?.printed === 'T'}"></i> -->
                    <i *ngIf="rowData?.printed === 'T'" class="fa fa-check-circle custom-icon true-color"
                        title="{{rowData?.printed==='T' ? 'True' : 'False'}}"></i>
                    <i *ngIf="rowData?.printed !== 'T'" class="fa fa-times-circle custom-icon false-color"
                        title="{{rowData?.printed==='T' ? 'True' : 'False'}}"></i>
                </ng-template>
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #printedDropDown [options]="printDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                        appendTo="body" placeholder="Select"
                        (onChange)="onPrintedFilterChange($event.value,col.field,col.filterMatchMode)"></p-dropdown>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="tallyEntered">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- <i class="icon-tally-1 custom-icon" [ngClass]="{'true-color': rowData?.tallyEntered === 'T', 'false-color': rowData?.tallyEntered !== 'T'}"></i> -->
                    <!-- <i class="fa fa-circle custom-icon" title="{{rowData?.tallyEntered==='T' ? 'True' : 'False'}}"
                        [ngClass]="{'true-color': rowData?.tallyEntered === 'T', 'false-color': rowData?.tallyEntered !== 'T'}"></i> -->
                    <i *ngIf="rowData?.tallyEntered === 'T'" class="fa fa-check-circle custom-icon true-color"
                        title="{{rowData?.tallyEntered==='T' ? 'True' : 'False'}}"></i>
                    <i *ngIf="rowData?.tallyEntered !== 'T'" class="fa fa-times-circle custom-icon false-color"
                        title="{{rowData?.tallyEntered==='T' ? 'True' : 'False'}}"></i>
                </ng-template>
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #tallyEnteredDropDown [options]="tallyEntryDropdown"
                        [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Select"
                        (onChange)="onTallyEnteredFilterChange($event.value,col.field,col.filterMatchMode)">
                    </p-dropdown>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="markAsPrinted">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <p-checkbox binary="true" name="checkPrint{{rowIndex}}"
                        [disabled]="rowData?.printed === 'N' || !checkPrintedStatus(rowData.status)"
                        [ngModel]="invoicePrinted.get(rowData?.id)" [ngModelOptions]="{standalone: true}"
                        (onChange)="onMarkAsPrintedChange(rowData, $event)">
                    </p-checkbox>
                </ng-template>
                <!-- <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #markAsPrintDropDown [options]="printDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
                        placeholder="Select"
                        (onChange)="onPrintedFilterChange($event.value,col.field,col.filterMatchMode)"></p-dropdown>
                </ng-template> -->
            </sib-grid-column>

            <sib-grid-column field="printInvoice">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div class="print-icon">
                        <i *ngIf="rowData?.printed !== 'N' && checkPrintedStatus(rowData.status)"
                            class="icon-printer custom-icon true-color" title="Print Invoice"
                            (click)="togglePrintMenu($event, rowData, rowIndex)"></i>
                        <i *ngIf="rowData?.printed === 'N' || !checkPrintedStatus(rowData.status)"
                            class="icon-printer custom-icon not-available-color"></i>
                    </div>
                    <!-- <div *ngIf="rouData?.printed === 'N'">
                        -
                    </div> -->
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="markAsTallyEntered">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <p-checkbox binary="true" name="checkTallyEntry{{rowIndex}}"
                        [disabled]="rowData?.tallyEntered === 'N' || !checkTallyEnteredStatus(rowData.status)"
                        [ngModel]="invoiceTallyEntered.get(rowData.id)" [ngModelOptions]="{standalone: true}"
                        (onChange)="onMarkAsTallyEnteredChange(rowData, $event)"></p-checkbox>
                </ng-template>
                <!-- <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #markAsTallyEnterDropDown [options]="tallyEntryDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                        appendTo="body" placeholder="Select"
                        (onChange)="onTallyEnteredFilterChange($event.value,col.field,col.filterMatchMode)">
                    </p-dropdown>
                </ng-template> -->
            </sib-grid-column>

            <sib-grid-column field="status">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- <div class="set-min-width">
                        <span class="square-text-small">
                            <span title="{{charTitleAt[rowIndex]}}" class="text-style-small pointer">
                                {{charAt[rowIndex]}}
                            </span>
                        </span>
                        <span class="circle-text-large" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='sr', '': pendingAt[rowIndex]!=='sr'}">
                            <span title="Sales Representative" class="text-style-large pointer" [ngClass]="{'set-text-color': pendingAt[rowIndex]==='sr', '': pendingAt[rowIndex]!=='sr'}">
                                SR
                            </span>
                        </span>
                        <span class="line"></span>
                        <span class="circle-text-large" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='accountant', '': pendingAt[rowIndex]!=='accountant'}">
                            <span title="Accountant" class="text-style-large pointer" [ngClass]="{'set-text-color': pendingAt[rowIndex]==='accountant', '': pendingAt[rowIndex]!=='accountant'}">
                                AC
                            </span>
                        </span>
                        <span class="line"></span>
                        <span class="circle-text-large" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='admin', '': pendingAt[rowIndex]!=='admin'}">
                            <span title="Admin" class="text-style-large pointer" [ngClass]="{'set-text-color': pendingAt[rowIndex]==='admin', '': pendingAt[rowIndex]!=='admin'}">
                                AD
                            </span>
                        </span>
                    </div> -->


                    <!-- <div class="ui-g row bs-wizard" style="border-bottom:0;">

                        <div class="bs-wizard-step">
                            <div class="square-text-small">
                                <span title="{{charTitleAt[rowIndex]}}" class="text-style-small pointer">
                                    {{charAt[rowIndex]}}
                                </span>
                            </div>
                        </div>

                        <div class="col-xs-3 bs-wizard-step">
                            <div class="text-center bs-wizard-stepnum">SR</div>
                            <div class="progress">
                            </div>
                            <a class="bs-wizard-dot" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='SR', '': pendingAt[rowIndex]!=='SR'}"
                                title="Sales Representative"></a>
                        </div>

                        <div class="col-xs-3 bs-wizard-step">
                            <div class="text-center bs-wizard-stepnum">ACC</div>
                            <div class="progress">
                            </div>
                            <a class="bs-wizard-dot" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='ACC', '': pendingAt[rowIndex]!=='ACC'}"
                                title="Accountant"></a>
                        </div>

                        <div class="col-xs-3 bs-wizard-step">
                            <div class="text-center bs-wizard-stepnum">ADM</div>
                            <div class="progress last-bar-child">
                            </div>
                            <a class="bs-wizard-dot" [ngClass]="{'set-circle-background': pendingAt[rowIndex]==='ADM', '': pendingAt[rowIndex]!=='ADM'}"
                                title="Admin"></a>
                        </div>

                    </div> -->


                    <div class="col-xs-0 padding-0 c-inline">
                        <span class="padding-0 float-left">
                            <div class="square-text-small background-{{getClassForCharacter(rowIndex % rowsPerPage)}}">
                                <span title="{{charTitleAt[rowIndex % rowsPerPage]}}"
                                    class="text-style-small pointer {{getClassForCharacter(rowIndex % rowsPerPage)}}">
                                    {{charAt[rowIndex % rowsPerPage]}}
                                </span>
                            </div>
                        </span>
                        <span class="padding-0">
                            <sib-status-visualization [nodes]="statusVisualizationNodes"
                                [selected]="pendingAt[rowIndex % rowsPerPage]"></sib-status-visualization>
                        </span>
                    </div>
                </ng-template>
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <!-- (onChange)="billSibGrid.filter($event.value,col.field,col.filterMatchMode)" -->
                    <p-dropdown #statusDropDown [options]="invoiceStatusDropdown"
                        [style]="{'width':'90%','margin-top':'8px'}" appendTo="body" placeholder="Status"
                        (onChange)="billingUIService.filterInvoiceStatus(billSibGrid, $event.value,col.field,col.filterMatchMode)">
                    </p-dropdown>
                </ng-template>
            </sib-grid-column>

        </sib-grid>
    </form>

    <p-overlayPanel #op [style]="{'width':'170px'}">
        <ol class="padding-left-10">
            <div class="ctext-center" style="font-weight: bold">Download PO</div>
            <br>
            <li *ngFor="let po of rowData?.purchaseOrders; let i = index" class="word-break-wrap">
                <!-- (click)="viewPO(po)" -->
                <a style="cursor: pointer;" class="link-1  after" href="{{po.url}}" target="_blank">{{po.name}}</a>
            </li>
        </ol>
    </p-overlayPanel>

    <p-overlayPanel #printMenu>
        <ng-container *ngIf="rowData?.printConfiguration">
            <sib-print-pannel-layout [printConfig]="rowData?.printConfiguration"
                [selectedInvoiceFormat]="rowData?.invoiceFormat"
                (eEmitSelectedInvoiceFormat)="setSelectedInvoiceFormat($event)"
                (eEmitSavePrintInstructions)="savePrintingInstructions($event)"
                (eEmitChangedPrintType)="onPrintTypeChange($event)"
                (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)"
                (eEmitPreviewPrintItems)="previewPrintItems($event)" (eEmitPrintItems)="printItems($event)">
            </sib-print-pannel-layout>
        </ng-container>
    </p-overlayPanel>

</div>