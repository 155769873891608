import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule, MultiSelectModule, ProgressSpinnerModule, TabViewModule, ButtonModule, StepsModule, TieredMenuModule, DialogModule, DropdownModule, OverlayPanelModule, TooltipModule, CalendarModule, InputSwitchModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsRoutingModule } from "./analytics-routing.module";
import { CityService } from '../masters/city/service/city.service';
import { DirectiveModules } from '../modules/directive.modules';
import { UserPropertiesService } from '../services/shared/user-properties.service';
import { SibChartModule } from '../sib-chart/sib-chart.module';
import { SibGridModule } from '../components/sib-forms/grid/grid.module';
import { SalesChartComponent } from './sales-chart/sales-chart.component';
import { SalesChartService } from '../services/shared/sales-chart.service';
import { AnalyticsComponent } from './analytics.component';
import { ViewInventoryModule } from '../shared/components/view-inventory/view-inventory.module';
import { CapacityChartModule } from './capacity-chart/capacity-chart.module';
import { SuperbillChartModule } from '../billings/superbill-chart/superbill-chart.module';
import { RoiComponent } from './roi/roi.component';
import { CurrencyMainPipe } from '../shared/helpers/currency.pipe/currency.main.pipe';
import { ViewRoiContractInventoryComponent } from './roi/view-roi-contract-inventory/view-roi-contract-inventory.component';
import { UntappedRoiComponent } from './roi/untapped-roi/untapped-roi.component';
import { SibBackModule } from '../shared/components/sib-back/sib-back.module';
import { IndustrialChartModule } from './industrial-chart/industrial-chart.module';
import { EmployeeTableChartModule } from './employee-table-chart/employee-table-chart.module';
import { PlanCampaignComparisonChartModule } from './plan-campaign-comparison-chart/plan-campaign-comparison-chart.module';
// import { EmployeeTableChartComponent } from './employee-table-chart/employee-table-chart.component';

@NgModule({
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    ButtonModule,
    StepsModule,
    TieredMenuModule,
    DialogModule,
    DropdownModule,
    OverlayPanelModule,
    TooltipModule,
    CalendarModule,
    InputSwitchModule,
    DirectiveModules,
    SibChartModule,
    SibGridModule,
    CapacityChartModule,
    ViewInventoryModule,
    SuperbillChartModule,
    CurrencyMainPipe,
    SibBackModule,
    IndustrialChartModule,
    EmployeeTableChartModule,
    InputSwitchModule,
    PlanCampaignComparisonChartModule,
  ],
  declarations: [
    AnalyticsComponent,
    SalesChartComponent,
    RoiComponent,
    ViewRoiContractInventoryComponent,
    UntappedRoiComponent,
  ],
  exports: [
    CapacityChartModule,
    IndustrialChartModule,
    EmployeeTableChartModule,
    SalesChartComponent,
  ],
  providers: [
    CityService,
    UserPropertiesService,
    SalesChartService,
  ]
})
export class AnalyticsModule { } 