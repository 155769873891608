import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import {
  ButtonModule,
  SharedModule,
  DropdownModule,
  CalendarModule,
  SliderModule,
  OverlayPanelModule,
  DialogModule,
  TooltipModule,
  InputTextModule,
  MultiSelectModule,
  ProgressSpinnerModule,
} from "primeng/primeng";
import { HoardingLightService } from "../../../services/shared/hoardingLight.service";
import { HoardingCategoryService } from "../../../services/shared/hoardingCategory.service";
import { UserGridConfigService } from "../../../services/shared/user-grid-config.service";
import { DirectiveModules } from "../../../modules/directive.modules";
import { RouterModule } from "@angular/router";
import { UploadDocDialogModule } from "../upload-doc-dialog/upload-doc-dialog.module";
import { InventoryGridComponent } from "./inventory-grid.component";
import { MapModule } from "../map/map.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SibGridModule,
    HttpModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    SliderModule,
    SharedModule,
    DirectiveModules,
    RouterModule,
    UploadDocDialogModule,
    OverlayPanelModule,
    DialogModule,
    TooltipModule,
    InputTextModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    // KeyValueMainPipe,
    MapModule,
  ],

  declarations: [InventoryGridComponent],

  exports: [InventoryGridComponent],
  providers: [
    HoardingCategoryService,
    HoardingLightService,
    UserGridConfigService,
  ],
})
export class InventoryGridModule {}
