import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import "rxjs";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../base-service/api.service";
import { Observable, Subject } from "rxjs";
import { AppUrls } from "../urls";
import { map, catchError } from "rxjs/operators";
import { NotificationsMessages } from "./notifications-messages";
import { NotificatoinsService } from "../notifications/notifications.service";
import { TradedAssetEnum } from "../../shared/constants/traded-asset-enum";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { MediaTypeEnum } from "../../shared/constants/media-type-enum";

@Injectable()
export class InventoryService extends ApiService<any, any, any> {
  public $mediaType = new Subject<any>();
  public $area = new Subject<any>();
  public $thumbImageLoader = new Subject<any>();
  public $refreshAfterPhotoUpdate = new Subject<any>();
  urlPath: string = "inventories";
  advanceSearch: any = {};

  constructor(
    private Http: HttpClient,
    private Injector: Injector,
    private http: Http,
    private notificationServcie: NotificatoinsService
  ) {
    super(Http, Injector);
  }

  /**
   * @description get children inventories
   * @author Divya Sachan
   * @date 2020-01-21
   * @param {*} paginationEvent
   * @returns
   * @memberof InventoryService
   */
  getSubInventories(paginationEvent) {
    if (navigator.onLine) {
      return this.Http.post(
        this.baseUrl + this.urlPath + AppUrls.SEARCH,
        paginationEvent
      ).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          this.notificationServcie.error(
            error.error.message
              ? error.error.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
          return error;
        })
      );
    }
  }

  /**
   * @description get value from key for TradedAsset
   * @author Divya Sachan
   * @date 2020-01-21
   * @param {*} value
   * @returns
   * @memberof InventoryService
   */
  getTradedAsset(value) {
    return TradedAssetEnum[value];
  }

  /**
   * @description get value from key for LightType
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} value
   * @returns
   * @memberof InventoryService
   */
  getLight(value) {
    return HoardingLightEnum[value];
  }

  /**
   * @description get value from key for MediaType
   * @author Divya Sachan
   * @date 2020-02-05
   * @param {*} value
   * @returns
   * @memberof InventoryService
   */
  getMediaType(value) {
    return MediaTypeEnum[value];
  }

  /**
   * @description download Inventory's XLS
   * @author Divya Sachan
   * @date 2020-01-30
   * @param {*} fileType
   * @param {*} data
   * @returns
   * @memberof InventoryService
   */
  downloadFile(fileType, data, source?) {
    if (navigator.onLine) {
      let options = {};

      if (fileType === "XLSX") {
        const fileUrl =
          this.baseUrl +
          (source === "advanceSearch"
            ? "inventories/advancedSearch/exportGrid/XLSX"
            : "inventories/exportGrid/XLSX");
        options = { responseType: "arraybuffer" };
        return this.Http.post(fileUrl, data, options).pipe(
          map((response: any) => {
            return response;
          })
        );
      }
    }
  }
}
