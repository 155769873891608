/**
 * Programmer: Sanchit Mirg
 *
 * Description: Auth service is called when user is not authenticated
 */
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
// import { JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from "@auth0/angular-jwt";
import * as auth0 from "auth0-js";
import * as jwt_decode from "jwt-decode";
import { Subject, Subscription, timer as observableTimer } from "rxjs";
import { ErrorUtil } from "../helpers/error.utils";
import { AuthConfigService } from "../services/authconfig.service";
import { NotificatoinsService } from "../services/notifications/notifications.service";
import { AuthApiService } from "../services/shared/auth-api.service";
import { NotificationsMessages } from "../services/shared/notifications-messages";
import { UserService } from "../services/shared/user.service";
import { AppUrls } from "../services/urls";
import { SIBLabelConstants } from "../shared/constants/SIBLabelConstants";
import { TenantList } from "../shared/constants/tenant-list";

@Injectable()
export class AuthService {
  // auth0 = new auth0.WebAuth();

  config: any;
  apiConfigCredentials: any;
  private permissions: string = "";
  // jwtHelper: JwtHelper = new JwtHelper();
  jwtHelper: JwtHelperService = new JwtHelperService();

  authSet: Subject<boolean> = new Subject();
  isUserSIMAdmin:boolean = false;

  @Output() emitProifle: EventEmitter<any> = new EventEmitter<any>();

  userProfile: any;
  userProfilePic: any;
  auth0: any;
  refreshSubscription: Subscription;
  tenants = new TenantList();
  constructor(
    public router: Router,
    private authConfigService: AuthConfigService,
    private userService: UserService,
    private notificationService: NotificatoinsService,
    private authApiService: AuthApiService
  ) {
    this.apiConfigCredentials = authConfigService.config.apiConfigCredentials;
    this.checkUserForSimAdmin();
    // this.auth0 = new auth0.WebAuth(authConfigService.config.authConfig)
  }

  public login(): void {
    if (!this.auth0) {
      // this.handleAuthentication();
      this.setConfig(JSON.parse(localStorage.getItem("auth-web-config")));
    }

    //remove after once code pushed in production -- Change Date 29-06-2020
    localStorage.getItem("usermanagement_access_token")
      ? localStorage.removeItem("usermanagement_access_token")
      : null;
    localStorage.getItem("authId") ? localStorage.removeItem("authId") : null;
    localStorage.getItem("id_token")
      ? localStorage.removeItem("id_token")
      : null;
    localStorage.getItem("userInfo") ? localStorage.removeItem("userInfo") : "";
    //====================================

    this.auth0.authorize();
  }

  setConfig(config) {
    this.config = config;
    this.auth0 = new auth0.WebAuth(this.config);
  }

  public handleAuthentication(): void {
    this.setConfig(JSON.parse(localStorage.getItem("auth-web-config")));
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        // window.location.hash = "";  // commented because due to this token api was failing and it was going into endless loop
        // commenting above code doesn't affect other browsers except firefox
        this.authApiService.getApiAccessToken().subscribe(
          (response) => {
            // storing the api_access_tokin in the local storage
            localStorage.setItem("api_access_token", response["access_token"]);
            this.setSession(authResult);
            this.setPermissions(authResult);
            this.getProfile((error, profile) => {
              localStorage.setItem("profile", JSON.stringify(profile));
              this.userProfile = profile;
            });
            // this.getProfilePicture()
            this.router.navigate([""]);
            // console.log("token received:::::::::::::");
          },
          (error) => {
            // console.log("Error in fetching AAT", error);
          }
        );
      } else if (err) {
        // this.router.navigate(['']);
        this.logout();
        // console.log(err);
      }
    });
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("Access token must exist to fetch profile");
    }

    // const self = this;
    // this.auth0.client.userInfo(accessToken, (err, profile) => {
    //     if (profile) {
    //         self.userProfile = profile;
    //         localStorage.setItem('profile', JSON.stringify(profile))
    //         this.emitProifle.emit(profile);
    //     }
    //     cb(err, profile);
    // });

    this.getLoggedInUserProfile();
  }

  public getLoggedInUserProfile() {
    this.userService.get(null, AppUrls.GET_PROFILE_PICTURE).subscribe(
      (profilePic) => {
        this.userProfile = profilePic["data"];
        localStorage.setItem("profile", JSON.stringify(this.userProfile));
        localStorage.setItem("profileSetOnLogin", JSON.stringify(true));
        this.emitProifle.emit(this.userProfile);
      },
      (error) => {
        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          this.notificationService.info(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TRY_AGAIN,
            NotificationsMessages.INFORMATION
          );
        } else {
          this.notificationService.error(
            errorObject.message
              ? errorObject.message
              : NotificationsMessages.TECHNICAL_ISSUE,
            NotificationsMessages.ERROR
          );
        }
      }
    );
  }

  public getToken(): string {
    return localStorage.getItem("access_token");
  }

  public getMasterToken(): string {
    return localStorage.getItem("master_access_token");
  }

  public getAuthApiToken(): string {
    return localStorage.getItem("api_access_token");
  }

  public getUserProfile(): any {
    this.userProfile = JSON.parse(localStorage.getItem("profile"));
    return this.userProfile;
  }

  public getUserProfilePic(): any {
    this.userProfilePic = JSON.parse(localStorage.getItem("profilePic"));
    return this.userProfilePic;
  }

  public getUserInfo(): any {
    var userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))[0]
      : null;
    return userInfo;
  }

  public getUsermanagementToken() {
    return localStorage.getItem("usermanagement_access_token");
  }

  setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    // localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // localStorage.setItem('authId', this.getDecodedAccessToken(authResult.accessToken).sub);

    this.scheduleRenewal();
    this.checkUserForSimAdmin();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem("access_token");
    // localStorage.removeItem('id_token');
    localStorage.removeItem("expires_at");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("profile");
    localStorage.removeItem("profileSetOnLogin");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("user-by-email");
    // localStorage.removeItem("auth_code");

    // this.login();
    if (!this.auth0) {
      // this.handleAuthentication();
      this.setConfig(JSON.parse(localStorage.getItem("auth-web-config")));
    }
    this.auth0.logout();

    // Go back to the home route
    // this.router.navigate(['/']);
    // this.unscheduleRenewal();
    // setTimeout(() => {
    //     this.login();
    // }, 500)
  }

  private setPermissions(authResult) {
    this.permissions = this.jwtHelper.decodeToken(authResult.accessToken).scope;
  }

  public getPermissions() {
    if(localStorage.getItem("access_token")){
    this.permissions = this.jwtHelper.decodeToken(
      localStorage.getItem("access_token")
    ).scope;
    }
    return this.permissions;
  }
  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time

    const expiresAt = JSON.parse(localStorage.getItem("master_expiry_time"));
    return new Date().getTime() < expiresAt;
  }

  public renewToken() {
    this.logout();
    // this.login();  // commented for the white screen and hanging issue 28-10-2020

    // let parent = this;

    // this.auth0.checkSession({},
    //     function(err, result) {
    //       if (err) {
    //         console.log(err);
    //       } else {
    //         parent.setSession(result);
    //       }
    //     }
    //   );
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) return;
    this.unscheduleRenewal();
    var expiresAt = JSON.parse(window.localStorage.getItem("expires_at"));
    var preExpiryAt = expiresAt;
    var delay = preExpiryAt - Date.now();

    // Start ---- commented for the white screen and hanging issue 28-10-2020
    // if (delay > 0) {
    //     const source = setTimeout(() => {
    //         this.renewToken();
    //     }, delay);
    // }
    // End
    const source = observableTimer(Math.max(1, preExpiryAt - Date.now()));
    /* const source = Observable.of(preExpiryAt).flatMap(
            preExpiryAt => {

                const now = Date.now();

                // Use the delay in a timer to
                // run the refresh at the proper time
                return Observable.timer(Math.max(1, preExpiryAt - now));
            }); */

    // expiresAt = JSON.parse("1534766580000");
    /* const source = Observable.of(expiresAt).flatMap(
        expiresAt => {
        const now = Date.now();
        // Use the delay in a timer to
        // run the refresh at the proper time
        return Observable.timer(Math.max(1, expiresAt - now));
        });
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSubscription = source.subscribe(() => {
        this.renewToken();
        this.scheduleRenewal();
        }); */
    // this.refreshSubscription =
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      // this.scheduleRenewal(); // commented for the white screen and hanging issue 28-10-2020
    });
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

  /**
   *to check the permission
   *
   * @param {string} permission
   * @returns
   * @memberof AuthService
   */
  public checkPermission(permission: string) {
    if (this.getPermissions().indexOf(permission) !== -1  || this.getPermissions().indexOf("sim:admin") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  public checkUserForSimAdmin(){
    this.isUserSIMAdmin = this.checkPermission("sim:admin");
  }

  public getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getTenantId() {
    return localStorage.getItem("id") ? localStorage.getItem("id") : "";
  }

  isTenantChitraB() {
    return this.getTenantId() === this.tenants.CHITRA_B;
  }

  setMonthlyRateLabel() {
    return this.isTenantChitraB()
      ? SIBLabelConstants.NET_RATE_PM
      : SIBLabelConstants.MONTHLY_RATE;
  }

  getMasterTokenData() {
    return this.jwtHelper.decodeToken(this.getMasterToken());
  }
}
