import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Observable } from "rxjs";
import { Industry } from "../../modals/industry/industry";
import { catchError, map } from 'rxjs/operators';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from './notifications-messages';
import { AppUrls } from '../urls';

@Injectable()

export class InventoryExpenseService extends ApiService<any, any, any> {
    urlPath: string = 'invExpenses';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    downloadFile(fileType, data, subpath) {
        if (navigator.onLine) {
            let options = {};

            if (fileType === 'XLSX') {
                const fileUrl = this.baseUrl + this.urlPath + AppUrls.EXPORT_GRID + subpath;
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {
                    const errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }
    }

}
