import { Component, OnInit, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AuthService } from '../login-service/auth.service';

@Component({
    selector: 'sib-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class ProfileComponent implements OnInit {
    active: boolean;
    userProfile: any = {};
    userProfilePic: any;
    image: string;
    constructor(private auth: AuthService,
        private cd: ChangeDetectorRef) {
        this.userProfile = this.auth.getUserProfile();
        // this.userProfilePic = this.auth.getUserProfilePic();

    }

    ngOnInit() {

        if (this.userProfile) {
            this.image = this.userProfile.picture;
        }
        this.auth.emitProifle.subscribe((profile) => {
            this.image = profile.picture;
        })

        // if (this.userProfilePic) {
        //     this.image = this.userProfilePic;
        // }

        // this.auth.emitProifle.subscribe((profilePic) => {
        //     this.image = profilePic;
        // })

    }

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    logout() {
        this.image = null;
        this.auth.logout();
        this.auth.login();
    }

}
