export enum GridNameEnum {
  HOARDING = "hoarding",
  PLANS = "plans",
  PLAN_ITEMS = "planItems",
  CAMPAIGNS = "campaigns",
  EXPIRED_CAMPAIGNS = 'expiredCampaigns',
  MOUNTING_ITEMS = "mountingItems",
  HOARDING_GROUPS = "hoardingGroups",
  MONITORING_ITEMS = "monitoringItems",
  CAMPAIGN_ITEMS = "campaignItems",
  CUSTOMERS = "customers",
  CONTRACTS = "contracts",
  MOUNTING_ITEMS_CAMP = "mountingItemsCamp",
  MONITORING_ITEM_CAMP = "monitoringItemsCamp",
  UNMOUNTING_ITEMS_CAMP = "unmountingItemsCamp",
  SUPERBILL = "superBill",
  INVOICE = "invoice",
  KIOSK = "kiosk",
  PLAN_XLSX_EMAIL = "planXlsxEmail",
  FLEX_ITEMS = "flexItems",
  UNMOUNTING_ITEMS = "unmountingItems",
  SUPERBILL_INVOICE = "superBillInvoice",
  SUPERBILL_MIGRATED = "superBillMigrated",
  ACTIVITY_GRID = "activityGrid",
  UNUTILIZED_CAMPAIGN_ITEMS = "unutilizedItems",
  PENDING_UNBILLED_TASKS = "pendingUnbilledTasks",
  TASKS = "tasks",
  PASSBOOK = "passbook",
  CHART_HOARDINGS = "chartHoardings",
  CHART_KIOSKS = "chartKiosks",
  RESERVED_INVOICES = "reservedInvoices",
  RESERVED_CREDIT_NOTES = "reservedCreditNotes",
  RENT_ITEMS = "rentItems",
  PRINT_ITEMS = "printItems",
  MOUNT_ITEMS = "mountItems",
  POINT_OF_CONTACTS = "pointOfContacts",
  UNBILLED_ITEMS = "unbilledItems",
  MIGRATED_ITEMS = "migratedItems",
  GROUPS = "groups",
  SITES_EXPIRATION = "sitesExpiration",
  RATE_APPROVAL = "rateApproval",
  ROI = "roi",
  ROI_ITEM = "roiItem",
  UNTAPPED_ROI = "untappedRoi",
  CONTRACT_HOARDING = "contractHoarding",
  CONTRACT_HOARDING_GROUPS = "contractHoardingGroups",
  CONTRACT_KIOSK = "contractKiosk",
  CASE_LIST = "caseList",
  PILLAR = "pillar",
  CONTRACT_PILLAR = "contractPillar",
  BQS = "bqs",
  CONTRACT_BQS = "contractBQS",
  GANTRY = "gantry",
  CONTRACT_GANTRY = "contractGantry",
  WALL_WRAP = "wallWrap",
  CONTRACT_WALL_WRAP = "contractWallWrap",
  BUS = "bus",
  CONTRACT_BUS = "contractBus",
  SIGN_BOARD = "signBoard",
  CONTRACT_SIGN_BOARD = "contractSignBoard",
  "PICKUP STAND" = "pickupStand",
  "CONTRACT_PICKUP STAND" = "contractPickupStand",
  HOARDING_EXPENSES = "hoardingExpenses",
  KIOSK_EXPENSES = "kioskExpenses",
  PILLAR_EXPENSES = "pillarExpenses",
  GANTRY_EXPENSES = "gantryExpenses",
  BQS_EXPENSES = "bqsExpenses",
  PICKUP_STAND_EXPENSES = "pickupStandExpenses",
  UNIPOLE_EXPENSES = "unipolexpenses",
  SIGNBOARD_EXPENSES = "signboardExpenses",
  UTILITY_EXPENSES = "utilityExpenses",
  LED_EXPENSES = "ledExpenses",
  MOUNTING_REPORT = "mountingReport",
  MONITORING_REPORT = "monitoringReport",
  UNMOUNTING_REPORT = "unmountingReport",
  UNIPOLE = "unipole",
  CONTRACT_UNIPOLE = "contractUnipole",
  SIGNBOARD = "signboard",
  CONTRACT_SIGNBOARD = "contractSignboard",
  UTILITY = "utility",
  CONTRACT_UTILITY = "contractUtility",
  LED = "LED",
  CONTRACT_LED = "contractLED",
}
