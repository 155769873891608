<div class="addInvoiceDetails">
    <div *ngIf="loading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>
    <div class="m-top">
        <form [formGroup]="invoiceDetailForm" autocomplete="off">
            <div class="ui-g padding-0">
                <div class="ui-g-4">
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">Display Name</div>
                        </div>
                        <div class="ui-g-6">
                            <input class="width100" id="displayName" type="text" pInputText
                                formControlName="displayName" />
                        </div>
                    </div>
                    <div class="ui-g padding-0" *ngIf="showSupplierBranches">
                        <div class="ui-g-4">
                            <div class="label-margin">Supplier Branches
                                <span *ngIf="supplierBranchDropdown" class="required">*</span>
                            </div>
                        </div>
                        <div class="ui-g-6 customDropdown">
                            <p-dropdown #supplierGroupedDropdown [options]="supplierBranches" [autoWidth]="false"
                                [style]="{'width':'100%'}" placeholder="Select a Branch" [group]="true"
                                [disabled]="invoice.id" (onChange)="onSupplierBranchChange($event)"
                                formControlNmae="supplierSelectedBranch" [autoDisplayFirst]="false"
                                [required]="supplierBranchDropdown" changes
                                [changedValue]="selectedSupplierBranch?.address?.branchName"
                                [changedType]="getChangedType()"
                                [changedField]="'supplierGstDetail.branchAddress.branchName'"
                                [changedFieldDisplayName]="'Supplier Company Branch'">
                                <ng-template let-group pTemplate="group">
                                    {{group.label}} - {{group.value.branchAddress[0]?.state?.name}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">Billing Name
                                <span class="required">*</span>
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <p-dropdown [options]="companies" [autoWidth]="false" [style]="{'width':'100%'}"
                                placeholder="Select a Company" filter="true" (onChange)="setBranches()"
                                formControlName="selectedCompany" [autoDisplayFirst]="false" required changes
                                [changedValue]="selectedCompany?.company" [changedType]="getChangedType()"
                                [changedField]="'campaign.customer.company'" [changedFieldDisplayName]="'Company'">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">Branch
                                <span *ngIf="branchDropdown" class="required">*</span>
                            </div>
                        </div>
                        <div class="ui-g-6 customDropdown">
                            <p-dropdown #groupedDropdown [options]="branches" [autoWidth]="false"
                                [style]="{'width':'100%'}" placeholder="Select a Branch" [group]="true"
                                (onChange)="onBranchChange($event)" formControlNmae="selectedBranch"
                                [autoDisplayFirst]="false" [required]="branchDropdown" changes
                                [changedValue]="selectedBranch?.address?.branchName" [changedType]="getChangedType()"
                                [changedField]="'invoiceGstDetail.branchAddress.branchName'"
                                [changedFieldDisplayName]="'Company Branch'">
                                <ng-template let-group pTemplate="group">
                                    {{group.label}} - {{group.value.branchAddress[0]?.state?.name}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g padding-0 z-index-1 position-relative">
                        <div class="ui-g-4">
                            <div class="label-margin">Note
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <input class="width100" id="invoiceNote" type="text" pInputText
                                (input)="setInvoiceNote($event)" formControlName="invoiceNote" changes
                                [changedValue]="invoiceNote" [changedType]="getChangedType()" [changedField]="'note'"
                                [changedFieldDisplayName]="'Note'" />
                        </div>
                    </div>
                </div>
                <div class="ui-g-4">
                    <div class="ui-g padding-0">
                        <div class="ui-g-3">
                            <div class="label-margin">GST</div>
                        </div>
                        <div class="ui-g-6">
                            <input class="width100" id="gst" type="text" pInputText formControlName="gst" />
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-3">
                            <div class="label-margin">State</div>
                        </div>
                        <div *ngIf="invoice?.invoiceGstDetail?.branchAddress" class="ui-g-6">
                            <input class="width100" id="state" type="text" pInputText formControlName="state" />
                        </div>
                        <div *ngIf="!invoice?.invoiceGstDetail?.branchAddress" class="ui-g-6">
                            <input class="width100" id="state" type="text" pInputText formControlName="state" />
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-3">
                            <div class="label-margin">Address</div>
                        </div>
                        <div *ngIf="invoice?.invoiceGstDetail?.branchAddress" class="ui-g-6"
                            [class.list-width]='address?.length >= 15'>
                            <input class="width100" id="address" type="text"
                                [ngClass]="{'address': address?.length >= 15}" title="{{address}}" pInputText
                                formControlName="address" />
                        </div>
                        <div *ngIf="!invoice?.invoiceGstDetail?.branchAddress" class="ui-g-6"
                            [class.list-width]='address?.length >= 15'>
                            <input class="width100" id="address" type="text" title="{{address}}"
                                [ngClass]="{'address': address?.length >= 15}" pInputText formControlName="address" />
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-3">
                            <div class="label-margin">Internal Note
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <input class="width100" id="adminNote" type="text" pInputText
                                formControlName="internalNotes" (input)="setInternalNotes($event)" changes
                                [changedValue]="internalNotes" [changedType]="getChangedType()"
                                [changedField]="'internalNotes'" [changedFieldDisplayName]="'Internal Note'" />
                        </div>
                    </div>
                    <div class="ui-g padding-0 z-index-1 position-relative" *ngIf="migratedInvoice">
                        <div class="ui-g-3">
                            <div class="label-margin">Tally Ref Id
                            </div>
                        </div>
                        <div class="ui-g-6">
                            <input class="width100" id="refInvoiceId" type="text" pInputText
                                (input)="setInvoiceRefId($event)" formControlName="refInvoiceId" />
                        </div>
                    </div>
                </div>


                <div class="ui-g-4">
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">PAN</div>
                        </div>
                        <div class="ui-g-8">
                            <input class="width100" id="pan" type="text" pInputText formControlName="pan" />
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin"> Place of Supply</div>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown [options]="placeOfSupply" [autoWidth]="false" [style]="{'width':'100%'}"
                                placeholder="Select a Supply Place" filter="true" (onChange)="setPlaceOfSupply()"
                                formControlName="selectedPlaceOfSupply" [autoDisplayFirst]="false" required changes
                                [changedValue]="selectedPlaceOfSupply" [changedType]="getChangedType()"
                                [changedField]="'placeOfSupply'" [changedFieldDisplayName]="'Place of Supply'">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">Purchase Order </div>
                        </div>
                        <div class="ui-g-8 multiSelect-margin">
                            <p-multiSelect [options]="purchaseOrders" formControlName="selectedPO"
                                defaultLabel="Choose PO" [style]="{'width':'100%'}" maxSelectedLabels="0"
                                selectedItemsLabel="{0} PO selected" (onChange)="emitPO()" changes
                                [changedValue]="selectedPO" [changedType]="getChangedType()"
                                [changedField]="'purchaseOrders'" [changedFieldDisplayName]="'Purchase Orders'"
                                [changedFieldArrayVariable]="'name'">
                                <ng-template let-po pTemplate="item">
                                    <span *ngIf="po?.value?.poNumber"> {{po.label}} - {{po.value.poNumber}} - <a
                                            (click)="viewPO(po)">View</a></span>
                                    <span *ngIf="!po?.value?.poNumber"> {{po.label}} - <a
                                            (click)="viewPO(po)">View</a></span>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                    </div>
                    <div class="ui-g padding-0">
                        <div class="ui-g-4">
                            <div class="label-margin">Invoice Format
                                <span class="required">*</span>
                            </div>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown [options]="invoiceFormats" [autoWidth]="false" [style]="{'width':'100%'}"
                                [styleClass]="'add-details-format-dropdown'" placeholder="Select an Invoice Format"
                                filter="true" (onChange)="onInvoiceFormatChange()"
                                formControlName="selectedInvoiceFormat" [autoDisplayFirst]="false" required changes
                                [changedValue]="selectedInvoiceFormat" [changedType]="getChangedType()"
                                [changedField]="'invoiceFormat'" [changedFieldDisplayName]="'Invoice Format'">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-g padding-0" [ngClass]="{'margin-top--45': migratedInvoice}">
                <div class="ui-g-12 ctext-right">
                    <ul class="button-list-inline {{showSupplierBranches && !migratedInvoice ? 'margin-top--45' : ''}}">
                        <li class="ctext-left printMenuClass"
                            *ngIf="selectedInvoiceFormat === 'WITH_ATTACHED_ANNEXURE'">

                            <label class="font-12 font-weight-500 label-vCenter margin-right-5">Annexure Printing
                                Options</label>
                            <button pButton class="mtab-secondary c-edit-btn border-rad-2 font-button" title="Print"
                                icon="fa fa-print" (click)="showAnnexurePrintOverlay($event)"></button>
                        </li>
                        <li class="ctext-left printMenuClass"
                            *ngIf="(userRole === 'Admin' || userRole === 'Sales Rep') && !migratedInvoice">

                            <label class="font-12 font-weight-500 label-vCenter margin-right-5">Invoice Printing
                                Options</label>
                            <button pButton class="mtab-secondary c-edit-btn border-rad-2 font-button" title="Print"
                                icon="fa fa-print" (click)="showPrintOverlay($event)"></button>
                        </li>
                        <li
                            *ngIf="((invoice?.printingItems && invoice?.printingItems?.length) || (invoice?.mountingItems && invoice?.mountingItems?.length)) && (!selectedInvoiceFormat || (selectedInvoiceFormat !== 'AS_PER_RO_INCLUSIVE' && selectedInvoiceFormat !== 'AS_PER_RO_EXCLUSIVE' && selectedInvoiceFormat !== 'WITH_ATTACHED_ANNEXURE'))">
                            <div class="checkbox-label" *ngIf="!grouped">
                                Group Printing/Mounting
                            </div>
                            <div class="checkbox-label" *ngIf="grouped">
                                Ungroup Printing/Mounting
                            </div>
                        </li>
                        <li *ngIf="((invoice?.printingItems && invoice?.printingItems?.length) || (invoice?.mountingItems && invoice?.mountingItems?.length)) && (!selectedInvoiceFormat || (selectedInvoiceFormat !== 'AS_PER_RO_INCLUSIVE' && selectedInvoiceFormat !== 'AS_PER_RO_EXCLUSIVE' && selectedInvoiceFormat !== 'WITH_ATTACHED_ANNEXURE' ))"
                            class="padding-right-1">
                            <p-checkbox [(ngModel)]="grouped" [ngModelOptions]="{standalone: true}" binary="true"
                                (onChange)="onGroupedValueChange($event)"></p-checkbox>
                        </li>

                        <li>
                            <button type="button" pButton label="UPLOAD PO" class="mtab-primary"
                                (click)="uploadPO()"></button>
                        </li>
                        <li *ngIf="invIdCreationStrategy === 'MANUAL' && !migratedInvoice">
                            <button type="button" pButton
                                label="{{!invoice.invoiceId ? 'ADD INV ID' : (!invEditPending ? 'CHANGE INV ID' : 'EDIT INV ID')}}"
                                class="mtab-primary" (click)="showInvoiceIdDialog()"></button>
                        </li>
                    </ul>
                </div>
            </div>
        </form>

    </div>
    <div>
        <sib-upload-PO-dialog [displayUploadPODialog]="displayUploadPODialog" [campaign]="campaign"
            [imageFolderConfig]="imageFolderConfig" (displayReset)="hideUploadPODialog()"
            (eEmitPurchseOrder)="setPO($event)"></sib-upload-PO-dialog>
    </div>

    <p-dialog *ngIf="displayInvoiceIdDialog"
        header="{{!invoice.invoiceId ? 'Add Invoice Id' : (!invEditPending ? 'Change Invoice Id' : 'Edit Invoice Id')}}"
        [modal]="true" showEffect="fade" blockScroll="true" [(visible)]="displayInvoiceIdDialog"
        (onShow)="showInvoiceIdDialog()" [width]="300" [autoAlign]="false" [closable]="invEditPending ? true : false"
        [closeOnEscape]="invEditPending ? true : false" (onHide)="hideInvoiceIdDialog()"
        [styleClass]="'invoice-id-dialog'">
        <form #invoiceIdDialog="ngForm">
            <div class="ui-g ui-float-label">
                <input type="text" pInputText style="width : 100%" name="invId" [(ngModel)]="invoiceId" required>
                <label>Invoice Id</label>
            </div>
            <div class="text-align-center margin-top-15">
                <button type="button" pButton
                    label="{{!invoice.invoiceId ? 'ADD' : (!invEditPending ? 'CHANGE' : 'SUBMIT')}}"
                    class="mtab-primary" [disabled]="!invoiceIdDialog.form.valid"
                    (click)="validateInvoiceId(invoiceId)"></button>
            </div>
        </form>
    </p-dialog>
</div>

<ng-container *ngIf="!migratedInvoice">
    <p-overlayPanel #printMenu>
        <!-- invoice?.printConfiguration -->
        <sib-print-pannel-layout [printConfig]="invoice?.printConfiguration"
            [annexurePrintConfig]="invoice?.annexurePrintConfiguration" [selectedInvoiceFormat]="selectedInvoiceFormat"
            [showPreview]="true" [showPrint]="false" [annexureMenu]="annexureMenu" [disableInvoiceFormatDropdown]="true"
            (eEmitSelectedInvoiceFormat)="setSelectedInvoiceFormat($event)"
            (eEmitSavePrintInstructions)="savePrintingOption($event)"
            (eEmitChangedPrintType)="onPrintTypeChange($event)" (eEmitPreviewPrintItems)="previewPrintItems($event)"
            (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)"
            (eEmitChangedChildrenVisibility)="onChildrenVisibilityChange($event)"
            (eEmitChangedAnnexureOtherWorks)="onAnnexureOtherWorksOptionsChange($event)"
            (eEmitChangedAnnexureUnitsVisibility)="onAnnexureUnitsVisibilityChange($event)">
        </sib-print-pannel-layout>
    </p-overlayPanel>
</ng-container>