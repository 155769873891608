import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { InventoryTypeEnum } from '../../shared/constants/inventory-type.enum';
import { MountingPrice } from "../plans/mountingPrice";
import { PrintingPrice } from "../plans/printingPrice";
import { AdditionalItem } from "./additional-item";
import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { RentItem } from "./rent-item";

export class CombinedItem extends CampaignItemWrapper {
    itemStartDate: Date;
    itemEndDate: Date;
    days: number;
    type: string;
    amount: number;
    printingPrice?: PrintingPrice = new PrintingPrice();
    mountingPrice?: MountingPrice = new MountingPrice();
    printRate?: number;
    mountRate?: number;
    creative?: string;
    statusType?: string = null;
    note: string;
    units: number;
    serialNumber: number;
    hasDimension: boolean;

    setRentItem(item: RentItem, type: string, hsnCode?: number, index?: number): CombinedItem {
        // this.serialNumber = serialNumber;
        this.type = type;
        this.itemStartDate = item.itemStartDate ? new Date(item.itemStartDate) : null;
        this.itemEndDate = item.itemEndDate ? new Date(item.itemEndDate) : null;
        this.days = item.days;
        this.amount = item.cost ? Number((item.cost).toFixed(2)) : null;
        this.id = item.id ? item.id : null;
        this.customId = item.customId ? item.customId : null;
        this.rate = item.rate ? item.rate : null;
        this.cost = item.cost ? item.cost : null;
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.location = item.location;
        this.city = item.city;
        this.lightType = item.lightType;
        this.printPrice = item.printPrice ? item.printPrice : null;
        this.mountPrice = item.mountPrice ? item.mountPrice : null;
        this.note = item.note;
        this.childInventories = item.childInventories;
        this.hasDimension = item.hasDimension;
        this.hsnCode = index === 0 ? hsnCode : null;
        return this;
    }

    setAdditionalItem(item: AdditionalItem, type: string, lightType?, printingPrice?, mountingPrice?, isChild?, hsnCode?: number, index?: number): CombinedItem {
        // this.serialNumber = serialNumber;

        this.lightType = item.lightType ? item.lightType : lightType;
        if (mountingPrice) {
            item.mountingPrice = item.mountingPrice ? item.mountingPrice : mountingPrice;
        }
        if (printingPrice) {
            item.printingPrice = item.printingPrice ? item.printingPrice : printingPrice;
        }
        this.type = type;
        if (type === 'Printing') {
            this.setPrintRate(item, isChild);
        }

        if (type === 'Mounting') {
            this.setMountRate(item);
        }
        var totalUnits = 0;
        if (item.childInventories && item.childInventories.length > 0) {
            item.childInventories.forEach((child) => {
                if (InventoryTypeEnum[child.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE']) {
                    totalUnits += Number(child.quantity) * 2;
                } else {
                    totalUnits += Number(child.quantity);
                }
            })
        }

        this.units = totalUnits > 0 ? (this.getDecimalNum(totalUnits * item.quantity, 2)) : (InventoryTypeEnum[item.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE'] ? this.getDecimalNum(item.quantity * 2, 2) : this.getDecimalNum(item.quantity, 2));
        this.printingPrice = item.printingPrice ? item.printingPrice : null;
        this.mountingPrice = item.mountingPrice ? item.mountingPrice : null;
        this.creative = item.creative;
        this.amount = item.amount ? Number((item.amount).toFixed(2)) : null;
        this.id = item.id ? item.id : null;
        this.customId = item.customId ? item.customId : null;
        this.rate = item.rate ? item.rate : null;
        this.cost = item.cost ? item.cost : null;
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.location = item.location;
        this.city = item.city;
        this.mediaType = item.mediaType;
        this.printPrice = item.printPrice ? item.printPrice : null;
        this.mountPrice = item.mountPrice ? item.mountPrice : null;
        this.note = item.note;
        this.hasDimension = item.hasDimension;
        this.childInventories = item.childInventories;
        this.amount = item.amount ? item.amount : null;
        this.hsnCode = index === 0 ? hsnCode : null;

        return this;
    }

    // setPrintingMountingItem(type, rate, squareFeet) {
    //     this.type = type;
    //     type === 'Printing' ? this.printRate = rate : this.mountRate = rate;
    //     this.amount = rate * squareFeet
    //     this.squareFeet = squareFeet;
    //     return this;
    // }

    setPrintingMountingItem(type, rate, obj, hsnCode?: number, index?: number) {
        // this.serialNumber = serialNumber;
        this.type = type;
        type === 'Printing' || type === "UnitPrinting" ? this.printRate = rate : this.mountRate = rate;
        this.id = type === "Printing" || type === "UnitPrinting" ? type + "@" + this.printRate : type + "@" + this.mountRate;
        // this.amount = rate * squareFeet
        // this.squareFeet = squareFeet;
        this.amount = obj.amount ? obj.amount : null;
        type === 'Printing' || type === 'Mounting' ? this.squareFeet = obj.squareFeet : this.units = obj.totalUnits;
        this.mediaType = obj.mediaType;
        this.hsnCode = index === 0 ? hsnCode : null;
        // this.hsnCode = obj.hsnCode;
        return this;
    }

    setPrintingMountingItemByAmount(type, amount, squareFeet) {
        this.type = type;
        this.amount = amount ? amount : null;
        type === 'Printing' ? this.printRate = Number(amount / squareFeet) : this.mountRate = Number(amount / squareFeet);
        this.squareFeet = squareFeet;
        return this;
    }

    getDecimalNum(target, decimal) {
        // return Number(target.toFixed(decimal));

        return Number(Math.fround(target).toFixed(decimal));
    }

    setPrintRate(item, isChild) {
        this.printRate = 0;
        if (HoardingLightEnum[item.lightType] === HoardingLightEnum['F_L'] || HoardingLightEnum[item.lightType] === HoardingLightEnum['N_L']) {
            this.printRate = item.printingPrice ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice) : null;
        } else if (HoardingLightEnum[item.lightType] === HoardingLightEnum['B_L']) {
            this.printRate = item.printingPrice ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice) : null;
        } else {
            if (!isChild) {
                if (item.printingPrice) {
                    item.childInventories.forEach((child) => {
                        this.printRate += (child.lightType === "F_L" || child.lightType === "N_L" ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice) : (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice))
                    })
                }
                // this.printRate = item.printingPrice ? (item.printingPrice.variousPrice ? item.printingPrice.variousPrice : (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl + item.printingPrice.pricePerUnitBl : item.printingPrice.flNlPrice + item.printingPrice.blPrice)) : null;
            } else {
                if (item.printingPrice) {
                    if (item.lightType === "F_L" || item.lightType === "N_L") {
                        this.printRate = item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice;
                    } else if (item.lightType === 'B_L') {
                        this.printRate = item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice;
                    } else {
                        item.childInventories.forEach((child) => {
                            this.printRate += (child.lightType === "F_L" || child.lightType === "N_L" ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice) : (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice))
                        })
                    }
                }
                // this.printRate = item.printingPrice ? (item.lightType === "F_L" || item.lightType === "N_L" ? (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitFl : item.printingPrice.flNlPrice) : (item.printingPrice.perUnit ? item.printingPrice.pricePerUnitBl : item.printingPrice.blPrice)) : null;

            }
        }
        // this.printRate = utils.divideDecimals(item.amount, item.squareFeet);

    }

    setMountRate(item) {
        this.mountRate = item.mountingPrice ? (item.mountingPrice.pricePerSqFt ? item.mountingPrice.pricePerSqFt : (item.mountingPrice.pricePerUnit ? item.mountingPrice.pricePerUnit : item.mountingPrice.perSqftForVarious)) : null;
    }
}


export class CombinePrintingItem {
    totalSqFt: number;
    printingPrice: number;
    type: string;
}