<section class="print-pannel-layout">
    <span
        [ngClass]="{'printing-header': !annexureMenu, 'annexure-printing-header': annexureMenu}">{{!annexureMenu ? 'Printing Options' : 'Annexure Printing Options'}}</span>
    <div *ngIf="!annexureMenu">
        <section class="invoice-type-dropdown" *ngIf="showInvoiceFormat">
            <label class="font-12 print-invoice-type">Format
                <span class="required">* </span>
            </label>
            <p-dropdown [options]="invoiceFormats" [autoWidth]="false" [style]="{'width':'235px'}" name="invoiceFormat"
                [styleClass]="'print-pannel-dropdown'" placeholder="Select an Invoice Format" filter="true"
                (onChange)="onInvoiceFormatChange()" [(ngModel)]="selectedInvoiceFormat" [autoDisplayFirst]="false"
                [disabled]="disableInvoiceFormatDropdown" required>
            </p-dropdown>
        </section>
        <section class="detail-options">
            <span class="print-original"> Duplicate</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="printConfig.original" (onChange)="onTypeChange($event)">
            </p-inputSwitch>
            <span class="print-options">Original</span>
        </section>
        <section *permission="'print:invoice-letterhead-option'" class="detail-options">
            <span class="print-options">W/O LetterHead</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="printConfig.withLetterHead" (onChange)="onLetterHeadChange($event)">
            </p-inputSwitch>
            <span class="print-options">With LetterHead</span>
        </section>
        <section class="detail-options">
            <span class="print-children ">W/O Units</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="printConfig.withChildren" (onChange)="onChildrenVisibilityChange($event)">
            </p-inputSwitch>
            <span class="print-options">With Units</span>
        </section>
    </div>
    <div *ngIf="annexureMenu">

        <section class="detail-options">
            <span class="print-options">W/O Printing Mounting</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="annexurePrintConfig.withOtherWorks"
                (onChange)="onAnnexureWithOtherWorksOptionChange($event)">
            </p-inputSwitch>
            <span class="print-options">With Printing Mounting</span>
        </section>
        <section class="detail-options">
            <span class="print-children padding-right-70">W/O Units</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="annexurePrintConfig.withUnits" (onChange)="onAnnexureChildrenVisibilityChange($event)">
            </p-inputSwitch>
            <span class="print-options">With Units</span>
        </section>
    </div>

    <section class="print-button" [ngClass]="{'padding-top-20' :annexureMenu}">
        <button *ngIf="((userRole === 'Sales Rep' || userRole === 'Admin') && showSave)"
            class="mtab-primary margin-hori-5" pButton label="SAVE" (click)="savePrintingInstructions()"
            [disabled]="!selectedInvoiceFormat"></button>
        <button *ngIf="showPreview" class="mtab-primary margin-hori-5" pButton label="PREVIEW" (click)="previewItems()"
            [disabled]="!selectedInvoiceFormat"></button>
        <button *ngIf="showPrint" class="mtab-primary" pButton label="PRINT" (click)="printItems()"
            [disabled]="!selectedInvoiceFormat"></button>
    </section>
</section>