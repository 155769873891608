import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import * as utils from '../../helpers/utils';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { AppUrls } from '../../services/urls';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { StackedBarChartConfig } from '../../modals/charts/stackedBarChart.config';
import { ChartLegend } from '../../modals/charts/chart-legend/chart-legend';
import { Annotation } from '../../modals/charts/annotation/annotation';
import { VAxis, HAxis, ChartArea, Bar } from '../../modals/charts/columnChart.config';
import { AuthService } from '../../login-service/auth.service';
import { CustomerUiService, LocalUserRoleEnum } from '../../customers/services/customer-ui.service';

@Component({
  selector: 'sib-industrial-chart',
  templateUrl: './industrial-chart.component.html',
  styleUrls: ['./industrial-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IndustrialChartComponent implements OnInit {

  emailId: string;
  chartElementId: string;
  industrialSalesBarChartConfig: StackedBarChartConfig = new StackedBarChartConfig();
  industrialSalesChartArea: ChartArea = new ChartArea()
  industrialSalesChartLegend: ChartLegend = new ChartLegend();
  industrialSalesChartHeight: number;
  industrialSalesChartData: any[] = [];
  annotations: Annotation = new Annotation();
  hAxis: HAxis = new HAxis();
  vAxis: VAxis = new VAxis();
  width: number;
  chartData: any;
  bar: Bar = new Bar();
  startDate: Date;
  endDate: Date;
  isLoading: boolean = false;
  showNoSalesMessage: boolean = false;
  userProfile: any;
  userRole: string;
  userInfo: string;

  @Input() graphCustomization: boolean = false;
  @Input() industrialChartVisibility: boolean = false;

  @Output() eEmitChartVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    private dashboardService: DashboardService,
    private auth: AuthService,
    private customerUiService: CustomerUiService
  ) {


  }

  ngOnInit() {

    this.customerUiService.$userRole.subscribe((role) => {
      this.userRole = role;
      this.endDate = new Date();
      this.startDate = this.setInitialStartDate();
      if (!this.emailId) {
        this.emailId = utils.getLoggedInUserEmail();
      }
      this.emailId = this.userRole === 'Admin' ? 'ALL' : this.emailId;
      this.getIndustrialChartData();
    })

    this.auth.emitProifle.subscribe((profile) => {
      this.userProfile = profile;
      this.endDate = new Date();
      this.startDate = this.setInitialStartDate();
      this.emailId = profile.email;
      // this.userRole = this.customerUiService.getUserRole();

    })



    // this.userInfo = localStorage.getItem('userInfo');
    this.userProfile = this.auth.getUserInfo();
    if (this.userProfile) {
      this.endDate = new Date();
      this.startDate = this.setInitialStartDate();
      this.emailId = this.userProfile.emailId ? this.userProfile.emailId : this.userProfile.email;
      if (this.userProfile && this.userProfile['app_metadata']['authorization']['permissions'].indexOf('readAll') > -1) {
        this.userRole = LocalUserRoleEnum['ADMIN'];
      }

      this.emailId = this.userRole === 'Admin' ? 'ALL' : this.emailId;
      this.getIndustrialChartData();
    }

  }


  /**
   * @description sets end date
   * @author Raveena Nathani
   * @date 2020-05-22
   * @memberof IndustrialChartComponent
   */
  setInitialStartDate() {
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    startDate.setDate(startDate.getDate() + 1);
    return startDate;

  }

  /**
   * @description get data for industrial chart
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  getIndustrialChartData() {
    this.isLoading = true;
    let requestBody = {
      "itemStartDate": this.startDate,
      "itemEndDate": this.endDate,
      "userEmailId": this.emailId
    }
    this.dashboardService.create(requestBody, null, AppUrls.INDUSTRIAL_SALES_CHART).subscribe((response) => {
      if (response) {
        this.showNoSalesMessage = false;
        this.chartData = response['data'];
        this.setIndustrialChart();

      }
    })
  }


  /**
   * @description set industrial chart
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialChart() {
    this.chartElementId = SIBConstants.INDUSTRIAL_CHART_ID;
    this.setIndustrialChartData();

  }


  /**
   * @description set industrial chart data
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialChartData() {
    this.chartData = this.chartData.filter((data) => data.id);
    this.industrialSalesChartData = [];

    if (this.chartData.length === 0) {
      this.showNoSalesMessage = true;
      this.isLoading = false;
    } else {
      this.industrialSalesChartData.push(['Industries', 'Sales'])
      this.chartData.forEach((data) => {
        this.industrialSalesChartData.push([data.name, data.amount])
      })
      this.setIndustrialChartConfig();
    }
  }


  /**
   * @description sets industrial chart config
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialChartConfig() {
    this.setIndustrialSalesChartHeight();
    this.setIndustrialSalesChartArea();
    // this.setIndustrialSalesChartAnnotation();
    this.setHAxis();
    this.setVAxis();
    this.setIndustrialSalesChartLegend();
    this.width = innerWidth - 100;
    this.bar.groupWidth = '25';
    // this.industrialSalesBarChartConfig.title = "Industrial Sales Chart"
    this.industrialSalesBarChartConfig.titleTextStyle = { color: "#222222", bold: true }
    this.industrialSalesBarChartConfig.isStacked = false;
    this.industrialSalesBarChartConfig.width = this.width;
    this.industrialSalesBarChartConfig.chartArea = this.industrialSalesChartArea;
    this.industrialSalesBarChartConfig.chartLegend = this.industrialSalesChartLegend;
    this.industrialSalesBarChartConfig.hAxis = this.hAxis;
    this.industrialSalesBarChartConfig.vAxis = this.vAxis;
    this.industrialSalesBarChartConfig.bar = this.bar;
    this.industrialSalesBarChartConfig.annotations = this.annotations;

  }



  /**
   * @description sets industrial sales chart height
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialSalesChartHeight() {
    let industrialSalesChartDataLength = this.industrialSalesChartData.length
    if (industrialSalesChartDataLength >= 1 && industrialSalesChartDataLength < 5) {
      this.industrialSalesBarChartConfig.height = 400;
    } else if (industrialSalesChartDataLength >= 5 && industrialSalesChartDataLength <= 8) {
      this.industrialSalesBarChartConfig.height = 450;
    } else if (industrialSalesChartDataLength >= 9 && industrialSalesChartDataLength <= 11) {
      this.industrialSalesBarChartConfig.height = 500;
    } else {
      this.industrialSalesBarChartConfig.height = 550;
    }
  }


  /**
   * @description sets industrial chart area
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialSalesChartArea() {
    this.industrialSalesChartArea.height = "70%";
    this.industrialSalesChartArea.width = "68%";
    this.industrialSalesChartArea.left = "18%";
    this.industrialSalesChartArea.top = "15%";
  }


  /**
   * @description sets industrial sales chart annotation
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setIndustrialSalesChartAnnotation() {
    this.annotations.textStyle = {
      color: '#000',
      bold: true
    }
    this.annotations.alwaysOutside = true;
    this.annotations.stemColor = 'none'
  }



  /**
   * @description sets horizontal axis for industrial sales chart
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setHAxis() {
    this.hAxis.title = "Sales";
    this.hAxis.direction = 1;
  }


  /**
   * @description set vertical axis for industrial sales chart
   * @author Raveena Nathani
   * @date 2020-05-21
   * @memberof IndustrialChartComponent
   */
  setVAxis() {
    this.vAxis.title = "Industries";
    this.vAxis.textStyle.fontSize = '13px';
  }

  setIndustrialSalesChartLegend() {
    this.industrialSalesChartLegend.position = 'top';
  }


  /**
   * @description when either start date or end date selected or entered
   * @author Raveena Nathani
   * @date 2020-05-22
   * @memberof IndustrialChartComponent
   */
  onDateSelection() {
    this.getIndustrialChartData();
  }


  /**
   * @description called when chart visibility changed from dashboard
   * @author Raveena Nathani
   * @date 2020-05-27
   * @param {*} event
   * @memberof IndustrialChartComponent
   */
  onIndustrialChartVisibilityChange(event) {
    this.eEmitChartVisibility.emit(event.checked);

  }

}
