import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import * as utils from '../../../helpers/utils';
import { GridNameEnum } from "../../constants/grid-name.enum";
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { FormGroup, FormControl } from '@angular/forms';
import { PaginationData } from '../../../modals/paginationdata';
import { TasksService } from '../../../services/shared/tasks.service';
import { AppUrls } from '../../../services/urls';
import { map } from 'rxjs/operators';
import { TaskQueryParams } from '../../../modals/queryparams/task.queryparams';
import { Task } from '../../../modals/tasks/tasks';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { TaskActionQueryParams } from '../../../modals/queryparams/task-action-queryparams';
import { HoardingLightEnum } from '../../constants/hoarding-light-enum';
import { DateUtil } from '../../../helpers/date.util';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { TaskStatus } from '../../constants/task-status.enum';
import { Dropdown, ConfirmationService } from 'primeng/primeng';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { Inventory } from '../../../modals/hoardings/inventory';

@Component({
  selector: 'sib-pending-tasks',
  templateUrl: './pending-tasks.component.html',
  styleUrls: ['./pending-tasks.component.scss']
})
export class PendingTasksComponent implements OnInit {

  _showEmployee: boolean;
  @Input() set showEmployee(showEmployee) {
    this._showEmployee = showEmployee;
    this.setColumns();
  }

  get showEmployee() {
    return this._showEmployee;
  }

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Task>;
  @ViewChild('lighttypedd') lightTypeDd: Dropdown;

  @Input() moduleName: string;
  @Output() eEmitActionPerformed: EventEmitter<any> = new EventEmitter();
  @Output() eEmitPendingTasks: EventEmitter<any> = new EventEmitter()

  isLoading = false;
  rowData: any;
  actionTaskGroup: FormGroup;
  gridConfig: GridConfig<Task> = new GridConfig<Task>();
  taskQueryParam: TaskQueryParams = new TaskQueryParams();
  taskActionQueryParams: TaskActionQueryParams = new TaskActionQueryParams();
  pendingTasks: Task[] = [];
  filteredTasks: Task[] = [];
  buttonConfig: GridActionButtonConfig;
  displayStatusQueueDialog: boolean = false;
  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  }
  totalRecords: number;

  lightTypeDropdown: any[] = [];
  subscription: any[] = [];
  hmSelectedColumns: any[] = [];
  units: Inventory[] = [];

  currentCampaign: string;
  currentCampaignId: string;
  currentCampaignEmployee: string;
  conflictedCampaign: string;
  conflictedCampaignId: string;
  conflictedCampaignEmployee: string;
  values: any[] = [];

  taskColumns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    {
      field: 'data.customId',
      name: 'data.customId',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      width: '5vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.data.customId) {
          return (data.data.customId);
        }
      },
    },
    {
      field: 'data.district',
      name: 'data.district',
      header: 'District',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.data.district) {
          return (data.data.district);
        }
      },
    },
    {
      field: 'data.city',
      name: 'data.city',
      header: 'City',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.data.city) {
          return (data.data.city);
        }
      },
    },
    {
      field: 'data.area.name',
      name: 'data.area.name',
      header: 'Area',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.data.area.name) {
          return (data.data.area.name);
        }
      },
    },
    {
      field: 'data.location',
      name: 'data.location',
      header: 'Location',
      required: true,
      editable: false,
      width: '12vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.data.location) {
          return (data.data.location);
        }
      },
    },
    {
      field: 'data.size',
      name: 'data.size',
      header: 'Size',
      required: true,
      editable: false,
      width: '4vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,

      displayFn: (data) => {
        if (data.data.size) {
          return (data.data.size);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'data.lightType',
      name: 'data.lightType',
      header: 'Light',
      required: true,
      editable: false,
      width: '4vw',
      hidden: false,
      default: false,
      sortable: true,
      permanent: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'is',
        placeHolder: 'Search',
        custom: true
      },
      displayFn: (data) => {
        if (data.data.lightType) {
          return HoardingLightEnum[data.data.lightType];
        }
      },
    },

    {
      field: 'data.campaignDisplayName',
      name: 'data.campaignDisplayName',
      header: 'Current Campaign',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      default: false,
      sortable: true,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      }
    },

    {
      field: 'data.campaignEmployee.fullName',
      name: 'data.campaignEmployee.fullName',
      header: 'Current Campaign Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.data.campaignEmployee) {
          return data.data.campaignEmployee.fullName;
        } else {
          return '-';
        }
      },
    },

    {
      field: 'moduleData.displayKey',
      name: 'moduleData.displayKey',
      header: 'Conflict Campaign',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },

    },
    {
      field: 'responsiblePerson.name',
      name: 'responsiblePerson.name',
      header: 'Conflict Campaign Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.responsiblePerson) {
          return data.responsiblePerson.name;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'data.statusQueues',
      name: 'data.statusQueues',
      header: 'Status',
      required: true,
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.CUSTOM,
    },
    {
      field: 'data.newItemStartDate',
      name: 'data.newItemStartDate',
      header: 'New Start Date',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: true,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.data.newItemStartDate) {
          return DateUtil.dategridFormatter(data.data.newItemStartDate);
        }
      }
    },

    {
      field: 'approveReject',
      name: 'approveReject',
      header: 'Action',
      permanent: true,
      sortable: false,
      hidden: false,
      required: true,
      width: '17vw',
      editable: false,
      type: EditableGridFieldTypes.CUSTOM,
      // align: Alignment.CENTER,
    },

  ]


  constructor(
    private userGridConfigService: UserGridConfigService,
    private tasksService: TasksService,
    private notificationService: NotificatoinsService,
    private confirmationService: ConfirmationService,
    private hmColService: HoardingMasterColService,

  ) { }

  ngOnInit() {
    this.getUserGridConfig();
    this.setFormGroupObjects();
    this.setGridConfigObject();
    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
    });
    this.getPendingTasks();
    this.subscription.push(this.hmColService.selectedColumns.subscribe((hmSelectedColumns) => {
      this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
      setTimeout(() => {
        this.gridViewChildRef.setColumns();
      }, 0)
    }));

  }

  getUserGridConfig() {
    this.userGridConfigService.create([GridNameEnum['TASKS']])
      .subscribe((tasksGridConfig) => {
        this.taskColumns = utils.getUserConfiguredColumns(tasksGridConfig[GridNameEnum['TASKS']], this.taskColumns);
        this.gridViewChildRef.setAllColumns();
      });
  }

  setFormGroupObjects() {
    this.actionTaskGroup = new FormGroup({
      taskGridControl: new FormControl()
    });
  }

  getPendingTasks() {
    this.refreshGrid();
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new Task();
    this.gridConfig.model = Task;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    // this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.shouldAddRowOnDelete = false;
    // this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.expandableRows = true;
    this.gridConfig.rowExpandMode = 'single';

    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen

      this.taskQueryParam.moduleName = this.moduleName;

      var filter = { status: { value: "PENDING", matchMode: "contains" } }
      Object.assign(paginationEvent.filters, filter);
      return this.tasksService.create(paginationEvent, { moduleName: this.moduleName, page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, AppUrls.SEARCH).pipe(
        map((response) => {
          this.pendingTasks = response['data']['tasks']['content'];
          this.totalRecords = response['data']['tasks'].totalElements;
          this.eEmitPendingTasks.emit(this.totalRecords)
          return this.pendingTasks;
        }));
    };

  }

  updatingUserGridConfig(columnArray) {
    var userGridConfigService: Map<string, string> = new Map();
    userGridConfigService = utils.getGridConfigMap(columnArray, this.taskColumns);
    this.userGridConfigService.patch(utils.buildObject(userGridConfigService), null, GridNameEnum['TASKS'])
      .subscribe((result) => {
      })
  }

  viewDetail(rowData) {
    this.rowData = rowData;
    this.values = rowData.statusQueues ? rowData.statusQueues : rowData.data.statusQueues;
    this.displayStatusQueueDialog = true;
  }

  hideStatusQueueDialog() {
    this.displayStatusQueueDialog = false
  }

  adjustCampaignItem(item) {
    this.isLoading = true;
    this.taskActionQueryParams.taskId = item.id;
    this.taskActionQueryParams.action = "ADJUST";
    this.tasksService.create(null, this.taskActionQueryParams, AppUrls.CI_EXTEND).subscribe((response) => {
      // if (response) {
      this.notificationService.success('Dates for Item Adjusted Successfully', 'Item Adjust');
      this.eEmitActionPerformed.emit(true);
      this.refreshGrid();
      this.isLoading = false;
      // }
    },
      (error) => {
        this.notificationService.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        this.refreshGrid();
        this.isLoading = false;
      });
  }

  removeCampaignItem(item) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to drop ' + item.data.customId + ' from ' + item.moduleData.displayKey + '?',
      key: 'dropConflictItem',
      accept: () => {
        this.isLoading = true;
        this.taskActionQueryParams.taskId = item.id;
        this.taskActionQueryParams.action = "REMOVE";
        this.tasksService.create(null, this.taskActionQueryParams, AppUrls.CI_EXTEND).subscribe((response) => {
          // if (response) {
          this.notificationService.success('Item Removed Successfully', 'Item Remove');
          this.eEmitActionPerformed.emit(true);
          this.refreshGrid();
          this.isLoading = false;
          // }
        },
          (error) => {
            this.notificationService.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
            this.refreshGrid();
            this.isLoading = false;
          });
      }
    });
  }

  setColumns() {
    this.taskColumns.filter((col) => {
      switch (col.field) {
        case 'responsiblePerson.name': {
          col.permanent = !this.showEmployee;
          col.hidden = !this.showEmployee;
          break;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

  isColumnPresent(value) {
    let result = false;
    this.hmSelectedColumns.forEach((col) => {
      if (col === value) {
        result = true;
      }
    });
    return result;
  }

  /** gets light type of inventory
   * @description
   * @author Raveena Nathani
   * @date 2020-02-27
   * @param {*} value
   * @returns
   * @memberof PendingTasksComponent
   */
  getLight(value) {
    return HoardingLightEnum[value];
  }

  /**
   * @description when group without dimensions or group with various lightype then the expand the group for its units 
   * @author Raveena Nathani
   * @date 2020-02-27
   * @param {*} event
   * @memberof PendingTasksComponent
   */
  onRowExpand(event) {
    this.getCurrentAndConflictedCampaignsData(event.data);
    this.units = event.data.data.childInventories;
  }


  /**
   * @description get current and conflicted campaign data
   * @author Raveena Nathani
   * @date 2020-02-27
   * @param {*} data
   * @memberof PendingTasksComponent
   */
  getCurrentAndConflictedCampaignsData(data) {
    this.currentCampaign = data.data.campaignDisplayName;
    this.currentCampaignId = data.data.itemCampaignId;
    this.currentCampaignEmployee = data.data.campaignEmployee.fullName;
    this.conflictedCampaign = data.moduleData.displayKey;
    this.conflictedCampaignId = data.moduleData.primaryKey;
    this.conflictedCampaignEmployee = data.responsiblePerson.name;
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    })
  }
}


