import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  HostListener,
  AfterViewInit,
  ElementRef,
  Input,
} from "@angular/core";
import { PhotoInterfaceService } from "../../../../../services/shared/photo-interface.service";
import { MountImage } from "../../../../../modals/photo-interface/mount-image";
import * as fileUtils from "../../../../../helpers/file.util";
import { MenuItem, SelectItem, DataScroller, ConfirmationService } from "primeng/primeng";
import { MenuConstants } from "../../../../constants/menu-constants";
import { PaginationData } from "../../../../../modals/paginationdata";
import { PhotoInterfaceGlobalSrevice } from "../services/photo-interface-global.service";
import { Subscription } from "rxjs";
import * as utils from "../../../../../helpers/utils";
import { ImageTypeEnum } from "../../../../constants/image-type-enum";
import { ImageDataDisplay } from "../../../../../modals/image/image-data";
import { PhotoDialogComponent } from "../../../photo-dialog/photo-dialog.component";
import { AppUrls } from "../../../../../services/urls";
import { SearchFilter } from "../photo-interface.component";
import * as _ from "lodash";
import { ErrorUtil } from "../../../../../helpers/error.utils";
import { NotificatoinsService } from "../../../../../services/notifications/notifications.service";
import { NotificationsMessages } from "../../../../../services/shared/notifications-messages";
import * as $ from "jquery";
import { LoaderSubjects } from "../../../../../modals/loader-subjects/loader-subjects";

@Component({
  selector: "sib-mounted-image",
  templateUrl: "./mounted-image.component.html",
  styleUrls: ["./mounted-image.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MountedImageComponent implements OnInit, AfterViewInit, OnDestroy {
  _activeIndex = -1;
  @Input() set activeIndex(activeIndex) {
    this._activeIndex = activeIndex;
    this.getData();
  }

  get activeIndex() {
    return this._activeIndex;
  }

  @Output() eEmitSelectedMountImages: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImagesAndIndex: EventEmitter<any> = new EventEmitter();

  mountImages: MountImage[] = [];
  selectedMountImages: MountImage[] = [];
  imageObjs: any[];
  filteredImages: MountImage[] = [];
  index: number;
  globalFilterFields = ["HID", "City", "Location", "Size", "Display Name"];
  // searchData: PaginationData = {
  //   filters: {},
  //   globalFilter: ''
  // };
  searchData: SearchFilter = {
    filters: "",
    globalFilter: "",
  };
  subscription: Subscription[] = [];
  mountFilterOptions: SelectItem[] = [];
  scrollHeight: string;
  filterField = "mountingItems.images.imageType"; // used to set filter object in parent component
  isLoading = false; // for loader
  showMountPhotoDialog = false;
  imagesPerPage = 20; // no of images to display at a time
  checked: any[] = []; // to show pre selected images
  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant

  dscrollerPaginationEvent: PaginationData;
  totalMountImageCount:number;
  @Output() totalMountingImagesCount: EventEmitter<number> = new EventEmitter;
  @ViewChild("dscroller") dscroller: DataScroller;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setScrollHeightAndImagesPerPage();
    if (this.mountImages.length < this.imagesPerPage) {
      this.getData();
    }
  }

  // @ViewChild('loadButton') loadbutton: ElementRef;
  onInlineScroll: any;
  constructor(
    private photoInterfaceService: PhotoInterfaceService,
    private photoInterfaceGlobalSrevice: PhotoInterfaceGlobalSrevice,
    private notifcationService: NotificatoinsService,
    private confirmationService:ConfirmationService,
    private loaderSubjects:LoaderSubjects
  ) {}

  ngOnInit() {
    $("body").addClass("overflow-hidden");

    this.setMountFilterOptions();
    // this.getData(); // commented for tab load
    this.subscription.push(
      this.photoInterfaceGlobalSrevice.globalFilter.subscribe(
        (globalFilterData) => {
          this.searchData.globalFilter = globalFilterData;
          this.setGlobalFilterInPaginationData();
          // this.getData();
          //-------Sanchit Commented because now we are not doing front-end filtering. We are making API call for filter.
          // this.filterData(this.searchData);
        }
      )
    );
    this.subscription.push(
      this.photoInterfaceGlobalSrevice.filterObject.subscribe((filterValue) => {
        if (
          filterValue.selectedFilter.tabIndex === 0 &&
          filterValue.selectedFilter.filter
        ) {
          // this.onFilterChange(filterValue.selectedFilter.filter);
          this.searchData.filters = [...filterValue.selectedFilter.filter];
          this.setImageTypeFilter();
          // this.filterData(this.searchData);
        }
      })
    );
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const roles = userInfo[0].app_metadata.authorization.roles;
    this.email = userInfo[0].email;
    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
  }

  ngAfterViewInit() {
    this.setScrollHeightAndImagesPerPage();
    this.onInlineScroll = this.inlineScrollerListener.bind(this);
    this.dscroller.contentViewChild.nativeElement.addEventListener(
      "scroll",
      this.onInlineScroll
    );
    // this.displayLoaderButton(false);
  }

  inlineScrollerListener() {
    let scrollTop = this.dscroller.contentViewChild.nativeElement.scrollTop;
    let scrollHeight = this.dscroller.contentViewChild.nativeElement
      .scrollHeight;
    let viewportHeight = this.dscroller.contentViewChild.nativeElement
      .clientHeight;

    if (scrollTop >= scrollHeight - viewportHeight) {
      // this.displayLoaderButton(true);
    } else {
      // this.displayLoaderButton(false);
    }
  }

  // setScrollHeightAndImagesPerPage() {
  //   this.scrollHeight =
  //     this.mountImages && this.mountImages.length !== 0
  //       ? window.innerHeight - 50 + "px"
  //       : "0px";
  //   this.imagesPerPage =
  //     utils.getIntegerValue(Number(this.scrollHeight.split("px")[0]) / 100) * 5; // 100 - random vlaue < 150 (image height), 5 - images in a line
  //   // this.imagesPerPage = 20
  // }

  setScrollHeightAndImagesPerPage() {
    let imageHeight = 150; // Already set in CSS file
    let imageWidth = 250; // Already set in CSS file
    this.scrollHeight = window.innerHeight - 50 + "px";
    this.imagesPerPage =
      utils.getIntegerValue(
        Number(this.scrollHeight.split("px")[0]) / imageHeight
      ) *
        utils.getIntegerValue(Number(window.innerWidth - 90) / imageWidth) +
      utils.getIntegerValue(Number(window.innerWidth - 90) / imageWidth);
  }

  /* displayLoaderButton(show: boolean) {
    if(!show) {
      this.loadbutton.nativeElement.style.display = 'none'      
    } else if(show) {
      this.loadbutton.nativeElement.style.display = 'inline'
      
    }
  } */

  getData() {
    if (this.activeIndex === 0) {
      // added for tab load
      this.isLoading = true;
      // this.photoInterfaceService.create(this.searchData, this.searchData, AppUrls.MOUNT_ITEM)
      if (this.dscrollerPaginationEvent) {
        this.photoInterfaceService
          .create(
            this.dscrollerPaginationEvent,
            this.dscrollerPaginationEvent,
            AppUrls.MOUNT_ITEM
          )
          .subscribe(
            (response) => {
              this.checked = [];
              if (this.dscrollerPaginationEvent.page === 0) {
                this.mountImages = [];
              }
              this.mountImages = [
                ...this.mountImages,
                ...response["data"]["content"],
              ];
              this.totalMountImageCount = response["data"].totalElements;
              this.totalMountingImagesCount.emit(response["data"].totalElements);
              // this.displayLoaderButton(false);
              // this.mountImages.sort(this.customSort);
              this.filteredImages = _.cloneDeep(this.mountImages);
              this.filteredImages = _.uniqBy(
                this.filteredImages,
                "mountingItems.images.id"
              );
              this.imageObjs = [];
              this.mountImages.forEach((img) => {
                this.imageObjs.push({
                  source: img.mountingItems.images.url,
                  alt: fileUtils.createImageDescription(img),
                });
              });
              this.setScrollHeightAndImagesPerPage();
              this.setSelectedImages();
              // this.filterData(this.searchData);
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
              const errorObject = ErrorUtil.getErrorObject(error);
              if (errorObject.code === 417 || errorObject.code === 412) {
                this.notifcationService.info(
                  errorObject.message
                    ? errorObject.message
                    : NotificationsMessages.TRY_AGAIN,
                  NotificationsMessages.INFORMATION
                );
              } else {
                this.notifcationService.error(
                  errorObject.message
                    ? errorObject.message
                    : NotificationsMessages.TECHNICAL_ISSUE,
                  NotificationsMessages.ERROR
                );
              }
            }
          );
      }
    }
  }

  customSort(item1, item2) {
    return (
      new Date(item2.mountingItems.date).valueOf() -
      new Date(item1.mountingItems.date).valueOf()
    );
  }

  setMountFilterOptions() {
    this.mountFilterOptions = utils.createDropdown(ImageTypeEnum, false);
  }

  onFilterChange(event) {
    if (event) {
      // this.searchData.filters = { "mountingItems.images.imageType": { value: [...event], matchMode: 'in' } };
      // this.getData();
      this.searchData.filters = [...event];
      this.filterData(this.searchData);
    }
  }

  filterData(event: SearchFilter) {
    const filteredDataFromDropdownMultiSelect = this.filterDropdown(
      event.filters
    );
    const filteredDataFromGlobalFilter = this.filterGlobal(event.globalFilter);
    if (filteredDataFromDropdownMultiSelect && filteredDataFromGlobalFilter) {
      this.filteredImages = filteredDataFromDropdownMultiSelect.filter((item) =>
        filteredDataFromGlobalFilter.some(
          (itm) => item.mountingItems.images.id === itm.mountingItems.images.id
        )
      );
    } else {
      if (
        !filteredDataFromDropdownMultiSelect &&
        !filteredDataFromGlobalFilter
      ) {
        this.filteredImages = _.cloneDeep(this.mountImages);
      } else if (!filteredDataFromDropdownMultiSelect) {
        this.filteredImages = filteredDataFromGlobalFilter;
      } else {
        this.filteredImages = filteredDataFromDropdownMultiSelect;
      }
    }
    this.imageObjs = [];
    this.filteredImages.forEach((img) => {
      this.imageObjs.push({
        source: img.mountingItems.images.url,
        alt: fileUtils.createImageDescription(img),
      });
    });
    this.setSelectedImages();
  }

  filterDropdown(value) {
    if (value && value.length > 0) {
      return this.mountImages.filter((item) => {
        if (value.includes(item.mountingItems.images.imageType)) {
          return item;
        }
      });
    } else {
      return null;
    }
  }

  filterGlobal(value) {
    if (value && value.length > 0) {
      return this.mountImages.filter((item, index) => {
        let found = false;
        this.globalFilterFields.forEach((field) => {
          // let splitField: any = [];
          // if (field.includes('.')) {
          //     splitField = field.split('.');
          //     if (item[splitField[0]] !== null && item[splitField[0]][splitField[1]] !== null && item[splitField[0]][splitField[1]].includes(value)) {
          //         found = true;
          //     }
          // } else
          if (field === "campaign.displayName") {
            if (
              item.campaign &&
              item.campaign.displayName
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
            ) {
              found = true;
            }
          } else if (
            item[field].toLocaleLowerCase().includes(value.toLocaleLowerCase())
          ) {
            found = true;
          }
        });
        if (found) {
          return item;
        }
      });
    } else {
      return null;
    }
  }

  downloadImage(image, index) {
    const url = image.mountingItems.images.url;
    const name = this.imageObjs[index].alt;
    const extension = fileUtils.getImageExtension(
      image.mountingItems.images.name
    );
    fileUtils.downloadImageFile(url, name, extension);
  }

  /**
   *called from photo interface componnet
   *
   * @memberof MountedImageComponent
   */
  downloadSelectedImages() {
    const imgs: { name: string; url: string; description?: string }[] = [];
    const fileName = "mount";
    this.selectedMountImages.forEach((image) => {
      imgs.push({
        name: image.mountingItems.images.name,
        url: image.mountingItems.images.url,
        description: fileUtils.createImageDescription(
          image,
          image.mountingItems.images.name
        ),
      });
    });
    fileUtils.createFileBlobs(imgs, fileName);
  }

  updateSelectedImages(){
    this.confirmationService.confirm({
      message:"This Action will update the selected images in the inventory",
      accept:()=>{
        this.photoInterfaceService.updateInventoryImages(this.selectedMountImages)
        .subscribe((response)=>{
          this.checked=[];
          this.selectedMountImages=[];
          this.eEmitSelectedMountImages.emit(this.selectedMountImages);
         this.notifcationService.success(
          // "Inventory Photos Update",
          "Successfuly Updated photos in inventory",
          "Inventory Photos Update"
         )
        },
        (error)=>{
          this.notifcationService.error(error.error.message ? error.error.message : 'Some technical issue','Error!!')
        }
        )
      },
      reject:()=>{

      }
    })
  }
  /**
   *called from photo interface component
   *
   * @memberof MountedImageComponent
   */
  downloadAllImages() {
    this.confirmationService.confirm({
      message:"This Action will download all the images",
      accept:()=>{
        this.loaderSubjects.isAllImagesDownloading.next(true);
    const imgs: { name: string; url: string; description?: string }[] = [];
    const fileName = "mount";
    this.filteredImages.forEach((image) => {
      imgs.push({
        name: image.mountingItems.images.name,
        url: image.mountingItems.images.url,
        description: fileUtils.createImageDescription(
          image,
          image.mountingItems.images.name
        ),
      });
    });
    fileUtils.createFileBlobs(imgs, fileName,this.loaderSubjects);
  },
  reject:()=>{

  }
})
  }

  onCheckboxSelectionChange(event, imageObj, index) {
    if (event) {
      this.selectedMountImages.push(imageObj);
    } else {
      this.selectedMountImages = this.selectedMountImages.filter((item) => {
        if (item.mountingItems.images.id !== imageObj.mountingItems.images.id) {
          return item;
        }
      });
    }
    // this.setMoreMenu();
    this.eEmitSelectedMountImages.emit(this.selectedMountImages);
  }

  onImageClick(index) {
    const imageData: any[] = [];
    this.filteredImages.forEach((img) => {
      imageData.push(
        new ImageDataDisplay().getImageData(img, img.mountingItems.images)
      );
    });
    this.eEmitImagesAndIndex.emit({ images: imageData, index: index });
  }

  setSelectedImages() {
    // this.mountImages.forEach((image) => {
    this.checked = [];
    this.filteredImages.forEach((image) => {
      const index = this.selectedMountImages.findIndex(
        (item) => item.mountingItems.images.id === image.mountingItems.images.id
      );
      this.checked.push(index !== -1 ? true : false);
    });
  }

  lazyLoadHandler(event) {
    this.setScrollHeightAndImagesPerPage();
    this.setPaginationData(event);
    this.getData();
  }

  setPaginationData(event) {
    this.dscrollerPaginationEvent = {
      page: this.dscroller.page,
      size: this.imagesPerPage,
      filters: this.searchData.filters
        ? {
            "mountingItems.images.imageType": {
              value: this.searchData.filters,
              matchMode: "in",
            },
          }
        : {},
      globalFilter: this.searchData.globalFilter
        ? this.searchData.globalFilter
        : "",
      sortField: "mountingItems.images.uploadedDate",
      sortOrder: 1,
    };
  }

  setGlobalFilterInPaginationData() {
    if (this.searchData.globalFilter || this.searchData.globalFilter === "") {
      this.dscrollerPaginationEvent.globalFilter = this.searchData.globalFilter;
      this.dscrollerPaginationEvent.page = 0;
      this.getData();
    }
  }

  setImageTypeFilter() {
    if (this.searchData.filters.length > 0) {
      Object.assign(this.dscrollerPaginationEvent.filters, {
        "mountingItems.images.imageType": {
          value: this.searchData.filters,
          matchMode: "in",
        },
      });
      this.dscrollerPaginationEvent.page = 0;
      this.getData();
    } else if (this.searchData.filters.length === 0) {
      this.dscrollerPaginationEvent.filters = {};
      this.dscrollerPaginationEvent.page = 0;
      this.getData();
    }
  }

  ngOnDestroy() {
    $("body").removeClass("overflow-hidden");
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
  }
}
