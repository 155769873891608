import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { GridConfig } from '../../../components/sib-forms/grid/grid.config';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { Dropdown, SelectItem } from 'primeng/primeng';
import { HoardingLightEnum } from '../../constants/hoarding-light-enum';
import * as utils from '../../../helpers/utils';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { InvoiceStatus } from '../../constants/invoice-status-enum';
import { Router } from '@angular/router';
import { AppUrls } from '../../../services/urls';
import { MountItemImageStatus } from '../../constants/mount-item-image-status-enum';
import { UserService } from '../../../services/shared/user.service';
import { UnmountItemImageStatus } from '../../constants/unmount-item-image-status-enum';
import { MonitoringItemImageStatus } from '../../constants/monitoring-item-image-status-enum';
// import { KioskTypeEnum } from '../../constants/kiosk-type.enum';

@Component({
  selector: 'sib-view-inventory',
  templateUrl: './view-inventory.component.html',
  styleUrls: ['./view-inventory.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewInventoryComponent implements OnInit {

  @ViewChild(GridComponent) gridViewChildRef: GridComponent<any>;
  @ViewChild('lighttypedd') lightTypeDd: Dropdown;

  _columns: any[] = [];
  @Input() set columns(columns) {
    this._columns = columns;
  }
  get columns() {
    return this._columns;
  }

  _showEmployee: boolean;
  @Input() set showEmployee(showEmployee) {
    this._showEmployee = showEmployee;
    this.setColumns();
  }

  get showEmployee() {
    return this._showEmployee;
  }

  @Input() gridConfig: GridConfig<any> = new GridConfig<any>();
  @Input() totalRecords: number;
  @Input() title: string;
  @Input() gridNameEnum: string;
  @Input() showXlsExport: boolean = false;
  @Input() showFilter: boolean = false;

  @Output() eEmitRemoveHoarding: EventEmitter<any> = new EventEmitter();
  @Output() eEmitModifiedColumns: EventEmitter<any> = new EventEmitter();

  email: string;
  lightTypeDropdown: any[] = [];
  hmSelectedColumns: any[] = [];
  subscription: Subscription[] = [];
  modifiedColumns: any[] = [];
  invoiceStatusDropdown: any[] = [];

  authorized: boolean = false; // to check if the logged in user is authorized for all - currently admin and accountant
  mountingItemImageStatusDropdown: SelectItem[] = [];
  unmountingItemImageStatusDropdown: SelectItem[] = [];
  monitoringItemImageStatusDropdown: SelectItem[] = [];

  mounters: any = [];
  constructor(
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const roles = userInfo[0].app_metadata.authorization.roles;
    this.email = userInfo[0].email;

    roles.forEach((role) => {
      if (role === "admin" || role === "sales-representative") {
        this.authorized = true;
      }
    });

    if (this.gridNameEnum !== undefined) {
      this.userGridConfigService.create([this.gridNameEnum]).subscribe((hoardingGridConfig) => {
        this.columns = utils.getUserConfiguredColumns(hoardingGridConfig[this.gridNameEnum], this.columns);
        // this.hmColService.defaultColumns.next(this.columns);
        this.gridViewChildRef.ngOnInit();
      });
    }

    this.hmColService.selectedColumns.subscribe((hmSelectedColumns) => {
      this.hmSelectedColumns = JSON.parse(JSON.stringify(hmSelectedColumns));
      this.detectChanges();
      setTimeout(() => {
        this.gridViewChildRef.setColumns();
      }, 0)
    })

    this.setColumns();
    if (this.gridNameEnum === 'mountingReport' || this.gridNameEnum === 'unmountingReport' || this.gridNameEnum === 'monitoringReport') {
      this.getMounters();
    }
    this.setFilterDropdown();
  }

  ngAfterViewInit() {
    this.modifiedColumns = this.gridViewChildRef.getModifiedColumns();
    this.eEmitModifiedColumns.emit(this.modifiedColumns);
  }

  setFilterDropdown() {
    this.setLightFilterDropdown();
    this.setInvoiceStatusDropdown();
    this.setMountingImageStatusDropdown();
    this.setUnmountingImageStatusDropdown();
    this.setMonitoringImageStatusDropdown();
  }

  setLightFilterDropdown() {
    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
    });
  }

  setInvoiceStatusDropdown() {
    this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatus, true);
  }

  setMountingImageStatusDropdown() {
    this.mountingItemImageStatusDropdown = utils.createDropdown(MountItemImageStatus, false);
  }

  setUnmountingImageStatusDropdown() {
    this.unmountingItemImageStatusDropdown = utils.createDropdown(UnmountItemImageStatus, false);
  }

  setMonitoringImageStatusDropdown() {
    this.monitoringItemImageStatusDropdown = utils.createDropdown(MonitoringItemImageStatus, false);
  }

  getModifiedColumns() {
    // return (this.gridViewChildRef.getModifiedColumns());
  }

  updatingUserGridConfig(event) {
    let hoardingGridConfig: Map<string, string> = new Map();
    hoardingGridConfig = utils.getGridConfigMap(event, this.columns);
    this.userGridConfigService.patch(utils.buildObject(hoardingGridConfig), null, this.gridNameEnum)
      .subscribe((result) => {
      });
  }

  removeHoarding(rowIndex) {
    this.eEmitRemoveHoarding.emit(rowIndex);
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.hmColService.defaultColumns.next([]);
  }

  setColumns() {
    this.columns.filter((col) => {
      switch (col.field) {
        case 'campaign.employee.fullName': {
          col.permanent = !this.showEmployee;
          col.hidden = !this.showEmployee;
          break;
        }
        case 'displayName' : {
          col.hidden = utils.checkPermission('view:display-name') ? false : true;
          col.permanent = col.hidden;
          break;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }

  getLinkTarget(rowData) {
    window.open(AppUrls.CAMPAIGNS + AppUrls.VIEW + '/' + rowData.campaign.id, "_blank");
  }

  getInvoice(rowData) {
    window.open(AppUrls.BILLINGS + AppUrls.BILLS + AppUrls.VIEW + '/' + rowData._id, "_blank");
  }

  getSuperbill(rowData) {
    window.open(AppUrls.BILLINGS + AppUrls.VIEW + '/' + rowData.campaign.id, "_blank");
  }

  refresh() {
    this.gridViewChildRef.refresh();
  }

  resetDataTable() {
    this.gridViewChildRef.resetDataTable();
  }

  getMounters() {
    this.userService.getMounters().subscribe((response: any) => {
      this.mounters = utils.createDropdownFromStringArray(response.map(val => val.fullName), false) || [];
      // console.log(this.mounters)
    })
  }
  onFilter(event) {
    // console.log(event)
  }
}
