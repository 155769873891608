import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as $ from "jquery";
import { MenuItem } from "primeng/primeng";
import { ActionDispatcher } from "../app state/action-dispatcher/action-dispatcher";
import { AuthService } from "../login-service/auth.service";
import { NotificatoinsService } from "../services/notifications/notifications.service";
import { SystemService } from "../services/shared/system.service";
import { SIBConstants } from "../shared/constants/SIBConstant";
import { PackagesService } from "../subscription-plans/services/packages.service";
import { MainMenuComponent } from "./main-menu/main-menu.component";
enum MenuOrientation {
  STATIC,
  OVERLAY,
  SLIM,
  HORIZONTAL,
}

declare var jQuery: any;
//  let jQuery: any;

@Component({
  selector: "sib-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  layoutCompact: boolean = true;

  layoutMode: MenuOrientation = MenuOrientation.STATIC;

  darkMenu: boolean = false;

  profileMode: string = "top";

  rotateMenuButton: boolean;

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  rightPanelActive: boolean;

  rightPanelClick: boolean;

  layoutContainer: HTMLDivElement;

  layoutMenuScroller: HTMLDivElement;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  resetMenu: boolean;

  menuHoverActive: boolean;

  @ViewChild("layoutContainer") layourContainerViewChild: ElementRef;

  @ViewChild("layoutMenuScroller") layoutMenuScrollerViewChild: ElementRef;

  model: MenuItem[] = [];

  mainMenuComponent: MainMenuComponent = new MainMenuComponent();

  template: string = `<img src="http://pa1.narvii.com/5722/2c617cd9674417d272084884b61e4bb7dd5f0b15_hq.gif">`;
  isLoading: boolean = true;

  constructor(
    private auth: AuthService,
    private systemService: SystemService,
    private notificationServcie: NotificatoinsService,
    private actionDispatcher: ActionDispatcher,
    private packageService: PackagesService
  ) {}

  ngOnInit() {
    if (
      localStorage.getItem("profileSetOnLogin") &&
      JSON.parse(localStorage.getItem("profileSetOnLogin"))
    ) {
      // if there is any api call to be made on ngoninit then add it in this if clause and in auth.emitproifle subscription
      // and this if condition is in 3 components 1) apptopbar 2)main.component 3) top-section.component
      // This if clause is added to avoid API calls before getting token from auth0
      this.getPassbookEnableProperty();
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
    this.layoutMode = MenuOrientation.SLIM;
    this.setMenuItems();
    this.auth.emitProifle.subscribe((profile) => {
      this.getPassbookEnableProperty();
      this.isLoading = false;
    });
    this.packageService.$packageSubscribed.subscribe((value) => {
      if (value) {
        this.setMenuItems();
      }
    });
  }

  setMenuItems() {
    this.model = [];
    const permission = this.auth.getPermissions();
    // var subscriptionActive = !JSON.parse(localStorage.getItem('subscriptionActive')) ? false : true;
    // var subscribedPackage = JSON.parse(localStorage.getItem('subscribedPackage'))
    // Setup
    let myMenuItem = new Object();

    if (permission.includes("menu:tasks") || permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.TASKS_MENU,
        icon: "icon-task",
        routerLink: ["/tasks"],
      });
    }
    // , disabled: !subscriptionActive
    // && subscribedPackage && subscribedPackage.supportedModules.includes(PackageModulesEnum['CUSTOMER'])
    if (permission.includes("menu:customers") || permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.CUSTOMER_MENU;
      myMenuItem["icon"] = "icon-customer";
      myMenuItem["items"] = [];
      if (permission.includes("read:customers")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CUSTOMER_MENU,
          icon: "icon-customer",
          routerLink: ["/customers"],
        });
      }
      if (permission.includes("menu:customer-groups") || permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CUSTOMER_GROUPS_MENU,
          icon: "icon-customer-groups",
          routerLink: ["/customers/customergroups"],
        });
      }
      if (permission.includes("menu:pocs") || permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CUSTOMER_POC_MENU,
          icon: "icon-poc",
          routerLink: ["/customers/pointofcontacts"],
        });
      }
      myMenuItem["items"].push({
        label: SIBConstants.CUSTOMER_SETTINGS,
        icon: "icon-settings",
        routerLink: ["/customers/configuredcustomer"],
      });
      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:contracts")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.CONTRACT_MENU;
      myMenuItem["icon"] = "icon-contract-type";
      myMenuItem["items"] = [];
      if (permission.includes("menu:contracts")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CONTRACT_MENU,
          icon: "icon-contract-submenu",
          routerLink: ["/contracts"],
        });
      }
      if (permission.includes("read:inventory-expenses")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.INVENTORY_EXPENSES,
          icon: "icon-inventory-expenses-submenu",
          routerLink: ["/contracts/inventoryExpenses"],
        });
      }

      if (permission.includes("menu:supplier")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.SUPPLIER,
          icon: "icon-users",
          routerLink: ["/contracts/supplier"],
        });
      }
      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:hoardings")|| permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.INVENTORY_MENU,
        icon: "icon-hoarding",
        routerLink: ["/hoardings"],
      });

      // myMenuItem = new Object();
      // myMenuItem['label'] = SIBConstants.INVENTORY_MENU;
      // myMenuItem['icon'] = 'icon-hoarding';
      // myMenuItem['items'] = [];
      // if (permission.includes('menu:hoardings')) {
      //     myMenuItem['items'].push({ label: SIBConstants.INVENTORY_MENU, icon: 'icon-inventory-submenu', routerLink: ['/hoardings'] });
      // }

      // myMenuItem['items'].push({ label: SIBConstants.PARTNER_VENDOR_MASTER, icon: 'icon-partner-vendor-master', routerLink: ['/hoardings/partner-vendor-master'] }),
      //     myMenuItem['items'].push({ label: SIBConstants.PARTNER_INVENTORY_MASTER, icon: 'icon-partner-inventory-master', routerLink: ['/hoardings/partner-inventory-master'] })

      // this.model.push(myMenuItem);
    }

    if (permission.includes("menu:plans")|| permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.PLAN_MENU,
        icon: "icon-plans",
        routerLink: ["/plans"],
      });
    }

    if (permission.includes("menu:campaigns")|| permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.CAMPAIGN_MENU,
        icon: "icon-campaign-4",
        routerLink: ["/campaigns"],
      });
    }
    if (permission.includes("menu:operations")|| permission.includes("sim:admin")) {
      // myMenuItem = new Object();
      // myMenuItem['label'] = SIBConstants.OPERATIONS_MENU;
      // myMenuItem['icon'] = 'icon-operations';
      // myMenuItem['items'] = [];
      // if (permission.includes('menu:operations-hoardings')) {
      //     myMenuItem['items'].push({ label: SIBConstants.OP_KANBAN_MENU, icon: 'icon-hoarding-grid', routerLink: ['/operations/hoardings-operation'] })
      // }
      // if (permission.includes('menu:operations-campaigns')) {
      // }

      // myMenuItem['items'].push({ label: SIBConstants.OP_GRID_MENU, icon: 'icon-hoarding-operations', routerLink: ['/operations/hoardings'] })
      this.model.push({
        label: SIBConstants.OPERATIONS_MENU,
        icon: "icon-operations",
        routerLink: ["/operations/hoardings"],
      });

      // this.model.push(myMenuItem);
    }

    if (permission.includes("menu:billings")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.BILLING_MENU;
      myMenuItem["icon"] = "icon-billings-2";
      myMenuItem["items"] = [];
            if (permission.includes("menu:superbills")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.SUPERBILL_SUBMENU,
          icon: "icon-billings-1",
          routerLink: ["/billings"],
        });
      }
      if (permission.includes("menu:reserve-invoices")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.RESERVE_INVOICES_MENU,
          icon: "icon-tally",
          routerLink: ["/billings/reserve-invoices"],
        });
      }
      if (permission.includes("menu:reserve-credit-notes")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.RESERVE_CN_MENU,
          icon: "icon-tally-1",
          routerLink: ["/billings/reserve-credit-notes"],
        });
      }
      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:users")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.USER_MENU;
      myMenuItem["icon"] = "icon-users";
      myMenuItem["items"] = [];
      if (permission.includes("menu:territory")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: "Territory",
          icon: SIBConstants.USER_USERS_MENU,
          items: [
            {
              label: SIBConstants.USER_TERRITORY_WAREHOUSE_MENU,
              icon: "icon-warehouse-keeper",
              routerLink: ["/users/WarehouseKeeperTerritory"],
            },
            {
              label: SIBConstants.USER_TERRITORY_MOUNTER_MENU,
              icon: "icon-mounter",
              routerLink: ["/users/MounterTerritory"],
            },
          ],
        });

        myMenuItem["items"].push({
          label: SIBConstants.USER_USERS_MENU,
          icon: "contacts",
          items: [
            {
              label: SIBConstants.USER_USERS_MENU,
              icon: "icon-users",
              routerLink: ["/users/employees"],
            },
            {
              label: SIBConstants.USER_USERS_PERMISSIONS_MENU,
              icon: "icon-permissions",
              routerLink: ["/users/permissions"],
            },
          ],
        });
      }

      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:masters")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.MASTERS_MENU;
      myMenuItem["icon"] = "icon-masters";
      myMenuItem["items"] = [];
      if (permission.includes("menu:contractTypes")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CONTRACT_MENU,
          icon: "icon-contract-type",
          command: () => (this.layoutCompact = true),
          routerLink: ["/masters/contract-type"],
        });
      }
      if (permission.includes("menu:custom-area")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.MASTERS_CUSTOM_DISTRICTS_MENU,
          icon: "icon-custom-district",
          command: () => (this.layoutCompact = true),
          routerLink: ["/masters/district"],
        });
      }
      if (permission.includes("menu:custom-area")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.MASTERS_CUSTOM_CITY_MENU,
          icon: "icon-custom-city",
          command: () => (this.layoutCompact = true),
          routerLink: ["/masters/city"],
        });
      }
      if (permission.includes("menu:custom-area")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.MASTERS_CUSTOM_AREAS_MENU,
          icon: "icon-custom-area",
          command: () => (this.layoutCompact = true),
          routerLink: ["/masters/area"],
        });
      }
      if (permission.includes("menu:industry-segments")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.MASTERS_INDUSTRY_MENU,
          icon: "icon-industry",
          command: () => (this.layoutCompact = true),
          routerLink: ["/masters/industry"],
        });
      }

      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:photo-interface")|| permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.PHOTO_INTERFACE_MENU,
        icon: "icon-photo-interface",
        routerLink: ["/photo-interface"],
      });
    }

    if (permission.includes("menu:analytics")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.ANALYTICS_MENU;
      myMenuItem["icon"] = "icon-analytics";
      myMenuItem["items"] = [];
      if (permission.includes("view:capacity-chart")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.CAPACITY_CHART_SUBMENU,
          icon: "icon-capacity-1",
          routerLink: ["/analytics/capacity-chart"],
        });
      }
      if (permission.includes("view:sales-chart")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.SALES_CHART_SUBMENU,
          icon: "icon-sales",
          routerLink: ["/analytics/sales-chart"],
        });
      }
      if (permission.includes("view:roi")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.ROI_SUBMENU,
          icon: "icon-roi",
          routerLink: ["/analytics/roi"],
        });
      }
      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:others")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.OTHERS;
      myMenuItem["icon"] = "icon-other";
      myMenuItem["items"] = [];

      if (permission.includes("menu:activity")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.ACTIVITY_MENU,
          icon: "icon-activity-log",
          command: () => (this.layoutCompact = true),
          routerLink: ["/activity"],
        });
      }
      if (permission.includes("menu:documents")|| permission.includes("sim:admin")) {
        myMenuItem["items"].push({
          label: SIBConstants.DOCUMENT_MENU,
          icon: "icon-documents",
          command: () => (this.layoutCompact = true),
          routerLink: ["/documents"],
        });
      }
      myMenuItem["items"].push({
        label: SIBConstants.SUGGESTION,
        icon: "fa  fa-thumbs-o-up",
        command: () => (this.layoutCompact = true),
        routerLink: ["/suggestion"],
      });
      // if (permission.includes("menu:articles")) {
      //   myMenuItem["items"].push({
      //     label: SIBConstants.MASTERS_ARTICLES_MENU,
      //     icon: "icon-menu-articles",
      //     command: () => (this.layoutCompact = true),
      //     routerLink: ["/masters/articles"],
      //   });
      // }
      this.model.push(myMenuItem);
    }

    if (permission.includes("menu:settings")|| permission.includes("sim:admin")) {
      this.model.push({
        label: SIBConstants.MASTERS_SETTINGS_MENU,
        icon: "icon-settings",
        routerLink: ["/masters/settings"],
      });
    }

    if (permission.includes("menu:reports")|| permission.includes("sim:admin")) {
      myMenuItem = new Object();
      myMenuItem["label"] = SIBConstants.REPORTS;
      myMenuItem["icon"] = "icon-report";
      myMenuItem["items"] = [];
      myMenuItem["items"].push({
        label: SIBConstants.EXPENSE_REPORT,
        icon: "icon-expense-report",
        routerLink: ["/reports/expense-report"],
      });
      myMenuItem["items"].push({
        label: SIBConstants.MOUNTER_REPORT,
        icon: "icon-expense-report",
        routerLink: ["/reports/mounter-report"],
      });
      this.model.push(myMenuItem);
    }
  }

  ngAfterViewInit() {
    this.layoutContainer = <HTMLDivElement>(
      this.layourContainerViewChild.nativeElement
    );
    this.layoutMenuScroller = <HTMLDivElement>(
      this.layoutMenuScrollerViewChild.nativeElement
    );

    setTimeout(() => {
      jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
    }, 10);
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
  }
  onMenuButtonClick(event) {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop())
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      else this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;

    if (!this.isHorizontal()) {
      setTimeout(() => {
        jQuery(this.layoutMenuScroller).nanoScroller();
      }, 500);
    }
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) this.activeTopbarItem = null;
    else this.activeTopbarItem = item;

    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }

  onRightPanelClick() {
    this.rightPanelClick = true;
  }

  hideOverlayMenu() {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  isHorizontal() {
    return this.layoutMode === MenuOrientation.HORIZONTAL;
  }

  isSlim() {
    return this.layoutMode === MenuOrientation.SLIM;
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  changeToHorizontalMenu() {
    this.layoutMode = MenuOrientation.HORIZONTAL;
  }

  changeToSlimMenu() {
    this.layoutMode = MenuOrientation.SLIM;
  }

  getPassbookEnableProperty() {
    this.systemService
      .get({ module: "PASSBOOK", key: "BLOCK_CREDIT" }, "/byModule/key")
      .subscribe(
        (response) => {
          if (response) {
            this.actionDispatcher.setEnablePassbook(response["value"]);
          }
        },
        (error) => {
          this.notificationServcie.error(
            error.error
              ? error.error.message
                ? error.error.message
                : "Some technical issue"
              : error.message,
            "Error!!"
          );
        }
      );
  }

  ngOnDestroy() {
    jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
  }
  @HostListener("click", ["$event.target"])
  onClick(target) {
    if ($("#chat-box").is(":visible")) {
      $("#chat-box").slideToggle(function () {
        $("#chat-button").slideToggle();
      });
    }
  }
}
