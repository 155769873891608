import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./chart.base.service";
import { LineChartConfig } from "../../modals/charts/lineChart.config";

declare var google: any;

@Injectable()
export class GoogleLineChartService extends GoogleChartsBaseService {
  constructor() {
    super();
  }

  public buildLineChart(
    elementId: String,
    data: any[],
    config: LineChartConfig
  ): void {
    const chartFunc = () =>
      new google.visualization.LineChart(
        document.getElementById(elementId + "")
      );
    const options = {
      title: config.title,
      hAxis: config.hAxis,
      vAxis: config.vAxis,
      aggregationTarget: config.aggregationTarget,
      pointsVisible: config.pointsVisible,
      height: config.height,
      width: config.width,
      legend: config.legend,
      chartArea: config.chartArea,
      curveType: config.curveType,
      series: config.series,
    };
    this.buildChart(data, chartFunc, options);
  }
}
