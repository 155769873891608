<div class="superBillView">
  <!-- <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div> -->

  <div class="ui-g padding-right-5">
    <!-- <sib-back></sib-back> -->
    <ul class="c-list-inline">
      <li>
        <sib-back></sib-back>
      </li>
      <ng-container *permission="'adjust:superbills'">
        <li
          *ngIf="!disableAdjust && !isCampaignBalanced"
          class="float-right padding-top-3"
        >
          <label class="font-12 font-bold label-vCenter padding-right-2">
            Adjust
          </label>
          <button
            class="mtab-secondary c-edit-btn border-rad-2"
            (click)="adjustSuperBill()"
            pButton
            icon="fa fa-adjust"
          ></button>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="card padding-0 detail-container">
    <div class="detail-container-data pie-chart-container">
      <div class="heading-container">
        <h3 class="sb-heading">{{ campaign?.customer?.billingName }}</h3>

        <h3 class="sb-heading">
          <span>Display: </span>{{ campaign?.displayName }}
        </h3>
      </div>
      <div class="superbill-piechart-container">
        <!-- <div *ngIf="pieChartLoader" class="ui-g-12">
                    <div class="chart-loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                </div> -->
        <div *ngIf="pieChartLoader" class="loading-specific-area">
          <!-- class="loading" -->
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div style="margin: 5px">
          <sib-chart
            #sibPieChart
            *ngIf="pieChartData.length > 0"
            [data]="pieChartData"
            [pieConfig]="pieConfig"
            [elementId]="pieElementId"
            [chartType]="'pieChart'"
            (eEmitPieChartLoaded)="pieChartLoader = false"
          ></sib-chart>
        </div>
      </div>
    </div>
    <div class="detail-container-data summary-container">
      <h3 class="sb-heading margin-left-10">Summary</h3>
      <div class="summary-container">
        <div class="summary-container-data">
          <div class="keyfont">SUPERBILL</div>
          <div class="valuefont">
            {{
              getIntegerRoundedOffValue(
                getParsedValueToDecimalTwoPlaces(superBillAmount)
              ) | CurrencyConverter
            }}
          </div>
        </div>
        <div class="summary-container-data">
          <div class="keyfont">BILLED</div>
          <div class="valuefont">
            {{
              getIntegerRoundedOffValue(
                getParsedValueToDecimalTwoPlaces(billedAmountWithoutTax)
              ) | CurrencyConverter
            }}
            <span
              class="neg-diff"
              style="padding: 0.5em; padding-left: 0em"
              *ngIf="superbillDifference() < 0"
            >
              ({{
                getAbsoluteValue(
                  getIntegerRoundedOffValue(superbillDifference())
                ) | CurrencyConverter
              }})
            </span>
            <span
              class="pos-diff"
              style="padding: 0.5em; padding-left: 0em"
              *ngIf="superbillDifference() >= 0"
            >
              ({{
                getIntegerRoundedOffValue(superbillDifference())
                  | CurrencyConverter
              }})
            </span>
          </div>
        </div>
        <hr />
        <div class="summary-container-data">
          <div *ngIf="isCampaignBalanced" class="keyfont">ADJUSTED</div>
          <div *ngIf="!isCampaignBalanced" class="keyfont">UNBILLED</div>

          <div class="valuefont">
            {{
              getIntegerRoundedOffValue(
                getParsedValueToDecimalTwoPlaces(unbilledAmount)
              ) | CurrencyConverter
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="detail-container-data">
      <h3 class="sb-heading margin-left-10">UNBILLED TRANSACTIONS</h3>
      <div class="superbill-piechart-container">
        <!-- <div class="ui-g-12" *ngIf="stackedBarChartLoader">
                    <div class="chart-loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                </div> -->
        <div *ngIf="pieChartLoader" class="loading-specific-area">
          <!-- class="loading" -->
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div style="margin: 5px">
          <sib-chart
            *ngIf="mountingStackedBarChartData.length > 0"
            [chartType]="'stackedBarChart'"
            #sibMountingStackedBarChart
            [data]="mountingStackedBarChartData"
            [stackedBarConfig]="stackedBarConfig"
            [elementId]="mountingStackedBarElementId"
            (eEmitStackedBarChartLoaded)="stackedBarChartLoader = false"
          ></sib-chart>
        </div>
      </div>
    </div>
    <!-- <div class="ui-g-6 border-right">
            <div class="ui-g padding-0 margin-10">


                <div class="ui-g-7 valuefont padding-0 margin-top-3">{{campaign?.customer?.company}}</div>
                <div class="ui-g-5 padding-0 margin-top-3">Display :
                    <span class="valuefont">{{campaign?.displayName}}</span></div>
            </div>
            <hr>
            <div class="ui-g-6">
                <div *ngIf="pieChartLoader" class="ui-g-12">
                    <div class="chart-loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                </div>
                <sib-chart #sibPieChart *ngIf="pieChartData.length > 0" [data]="pieChartData" [pieConfig]="pieConfig" [elementId]="pieElementId"
                    [chartType]="'pieChart'" (eEmitPieChartLoaded)="pieChartLoader = false"></sib-chart>
            </div>
            <div class="ui-g-5 float-right margin-top-10">
                <div class="ui-g summary-hr">
                    <div class="ui-g-5 keyfont  title-width">SUPERBILL</div>

                    <div class="ui-g-7 valuefont ctext-right word-break-wrap data-width">{{getIntegerRoundedOffValue(getParsedValueToDecimalTwoPlaces(superBillAmount)) | CurrencyConverter}}
                    </div>
                </div>
                <div class="ui-g summary-hr">
                    <div class="ui-g-5 keyfont title-width">BILLED</div>

                    <div class="ui-g-7 valuefont ctext-right word-break-wrap data-width">
                        <div>{{getIntegerRoundedOffValue(getParsedValueToDecimalTwoPlaces(billedAmountWithoutTax))|CurrencyConverter
                            }}
                        </div>
                    </div>


                    <span class="neg-diff" style="padding:0.5em; padding-left:0em;" *ngIf="superbillDifference() < 0">
                        ({{getAbsoluteValue(getIntegerRoundedOffValue( superbillDifference())) |
                        CurrencyConverter}})
                    </span>
                    <span class="pos-diff" style="padding:0.5em; padding-left:0em;" *ngIf="superbillDifference() >= 0">
                        ({{getIntegerRoundedOffValue( superbillDifference()) |
                        CurrencyConverter}})
                    </span>
                </div>
                <hr>
                <div class="ui-g summary-hr">
                    <div class="ui-g-5 keyfont title-width">
                        
                        <span *ngIf="isCampaignBalanced">
                            ADJUSTED
                        </span>
                        <span *ngIf="!isCampaignBalanced">
                            UNBILLED
                        </span>
                    </div>

                    <div class="ui-g-7 valuefont ctext-right word-break-wrap data-width">{{getIntegerRoundedOffValue(getParsedValueToDecimalTwoPlaces(unbilledAmount)) | CurrencyConverter}}
                    </div>
                </div>
                

            </div>
        </div> -->
    <!-- <div class="ui-g-6">
            <div class="ui-g padding-0 valuefont margin-10">
                <div class="valuefont padding-0 margin-top-3">UNBILLED TRANSACTIONS</div>
            </div>
            <hr>
            <div class="ui-g">
                <div class="ui-g-12" *ngIf="stackedBarChartLoader">
                    <div class="chart-loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                </div>
                <sib-chart *ngIf="mountingStackedBarChartData.length > 0" [chartType]="'stackedBarChart'" #sibMountingStackedBarChart [data]="mountingStackedBarChartData"
                    [stackedBarConfig]="stackedBarConfig" [elementId]="mountingStackedBarElementId" (eEmitStackedBarChartLoaded)="stackedBarChartLoader = false"></sib-chart>
            </div>
        </div> -->
  </div>
  <!-- <div class="ui-g-12 padding-0 margin-bottom--56">
        <div class="ui-g-6 padding-left-5 margin-top-5 valuefont">
            INVOICES
        </div>
    </div> -->
  <!-- <ul class="cinline-block padding-right-140">
        <li>
            <button class="mtab-primary" (click)="AdjustSuperBill()" pButton label="ADJUST SUPERBILL" [disabled]="false"></button>
        </li>
    </ul> -->
  <div class="ui-g-12 padding-0">
    <!-- (eEmitAdjustSuperBill)="adjustSuperBill()" [disableAdjust]="disableAdjust"  -->
    <sib-superbill-invoices
      #superbillInvoiceGrid
      [noState]="noState"
      [completelyBilled]="completelyBilled"
      [enableGenerate]="enableGenerate"
      [campaignExpired]="campaignExpired"
      [campaignId]="campaignId"
      [invoices]="invoices"
      [showGenerate]="showGenerate"
      (eEmitIsInvoiceItemDeleted)="onInvoiceDeletion($event)"
      [itemRateChanged]="campaign.itemRateChanged"
    ></sib-superbill-invoices>
  </div>

  <div class="padding-0">
    <p-dialog
      header="Adjust SuperBill"
      [(visible)]="showAdjustDialog"
      modal="modal"
      showEffect="fade"
      width="600"
      height="375"
      (onHide)="showAdjustDialog = false"
    >
      <sib-adjust-superbill *ngIf="showAdjustDialog"></sib-adjust-superbill>
    </p-dialog>
  </div>

  <p-confirmDialog
    header="Confirmation"
     
    width="425"
    #cd
  >
    <p-footer>
      <button
        type="button"
        pButton
        class="mtab-save margin-hori-5"
        label="YES"
        (click)="cd.accept()"
      ></button>
      <button
        type="button"
        pButton
        class="mtab-secondary"
        label="NO"
        (click)="cd.reject()"
      ></button>
    </p-footer>
  </p-confirmDialog>
</div>
