/**
 * Programmer:  Sanchit Mirg
 * Date:        09-15-2017
 * Description: All the shared imports, components are declared in this shared module
 * and removed from app.module.ts and this module is imported in app.module and app component is 
 * bootsraped in app.module.ts
 *
 * Import section is divided into three sections 1) Import from angular 2) import from primeng 3) Import for custom components
 */

/**
 *  List of Modules from angular
 */

// -----------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';

// -------------------------------------------------------------------------------


/**
 * List of Modules importing from primrng
 */
// --------------------------------------------------------------------------------
import { AccordionModule } from 'primeng/primeng';
import { AutoCompleteModule } from 'primeng/primeng';
import { BreadcrumbModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { CarouselModule } from 'primeng/primeng';
import { ColorPickerModule } from 'primeng/primeng';
import { ChartModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { ChipsModule } from 'primeng/primeng';
import { CodeHighlighterModule } from 'primeng/primeng';
import { ConfirmDialogModule } from 'primeng/primeng';
import { SharedModule } from 'primeng/primeng';
import { ContextMenuModule } from 'primeng/primeng';
import { DataGridModule } from 'primeng/primeng';
import { DataListModule } from 'primeng/primeng';
// import { DataScrollerModule } from 'primeng/primeng';
import { DataTableModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { DragDropModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { EditorModule } from 'primeng/primeng';
import { FieldsetModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/primeng';
import { GalleriaModule } from 'primeng/primeng';
import { GMapModule } from 'primeng/primeng';
import { GrowlModule } from 'primeng/primeng';
import { InputMaskModule } from 'primeng/primeng';
import { InputSwitchModule } from 'primeng/primeng';
import { InputTextModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/primeng';
import { LightboxModule } from 'primeng/primeng';
import { ListboxModule } from 'primeng/primeng';
import { MegaMenuModule } from 'primeng/primeng';
import { MenuModule } from 'primeng/primeng';
import { MenubarModule } from 'primeng/primeng';
import { MessagesModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/primeng';
import { OrderListModule } from 'primeng/primeng';
import { OrganizationChartModule } from 'primeng/primeng';
import { OverlayPanelModule } from 'primeng/primeng';
import { PaginatorModule } from 'primeng/primeng';
import { PanelModule } from 'primeng/primeng';
import { PanelMenuModule } from 'primeng/primeng';
import { PasswordModule } from 'primeng/primeng';
import { PickListModule } from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/primeng';
import { RatingModule } from 'primeng/primeng';
import { ScheduleModule } from 'primeng/primeng';
import { SelectButtonModule } from 'primeng/primeng';
import { SlideMenuModule } from 'primeng/primeng';
import { SliderModule } from 'primeng/primeng';
import { SpinnerModule } from 'primeng/primeng';
import { SplitButtonModule } from 'primeng/primeng';
import { StepsModule } from 'primeng/primeng';
import { TabMenuModule } from 'primeng/primeng';
import { TabViewModule } from 'primeng/primeng';
import { TerminalModule } from 'primeng/primeng';
import { TieredMenuModule } from 'primeng/primeng';
import { ToggleButtonModule } from 'primeng/primeng';
import { ToolbarModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/primeng';
import { TreeModule } from 'primeng/primeng';
import { TreeTableModule, SidebarModule, ProgressSpinnerModule } from 'primeng/primeng';
// -------------------------------------------------------------------------------------------


/**
 * Custom components import
 */
// -------------------------------------------------------------------------------------------
import { AppComponent } from '../app.component';
import { AppSubMenu } from '../app.menu.component';
import { AppTopBar } from '../app.topbar.component';
import { AppFooter } from '../app.footer.component';
import { AppRightPanel } from '../app.rightpanel.component';
import { InlineProfileComponent } from '../app.profile.component';
import { DashboardDemo } from '../demo/view/dashboarddemo';
import { SampleDemo } from '../demo/view/sampledemo';
import { FormsDemo } from '../demo/view/formsdemo';
import { DataDemo } from '../demo/view/datademo';
import { PanelsDemo } from '../demo/view/panelsdemo';
import { OverlaysDemo } from '../demo/view/overlaysdemo';
import { MenusDemo } from '../demo/view/menusdemo';
import { MessagesDemo } from '../demo/view/messagesdemo';
import { MiscDemo } from '../demo/view/miscdemo';
import { EmptyDemo } from '../demo/view/emptydemo';
import { ChartsDemo } from '../demo/view/chartsdemo';
import { FileDemo } from '../demo/view/filedemo';
import { UtilsDemo } from '../demo/view/utilsdemo';
import { Documentation } from '../demo/view/documentation';

import { CarService } from '../demo/service/carservice';
import { CountryService } from '../demo/service/countryservice';
import { EventService } from '../demo/service/eventservice';
import { NodeService } from '../demo/service/nodeservice';
// import { AuthService } from '../login-service/auth.service';
// import { AuthGuardService } from '../login-service/auth-guard.service';
import { MainComponent } from '../main/main.component';
import { ProfileComponent } from '../profile/profile.component';
import { MainMenuComponent } from '../main/main-menu/main-menu.component';
import { MainSubmenuComponent } from '../main/main-menu/main-submenu/main-submenu.component';
import { ListPaginationSearchComponent } from '../list-pagination-search/list-pagination-search.component';
import { DemoCarService } from '../services/demo-car.service';
import { TestListCompComponent } from '../test-list-comp/test-list-comp.component';
import { SibGridModule } from '../components/sib-forms/grid/grid.module';
import { IndustryDialogComponent } from '../shared/components/industry-dialog/industry-dialog.component';
import { HoardingsComponent } from '../hoardings/hoardings.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MapDialogModule } from '../shared/components/map-dialog/map-dialog.module';
import { UserDetailsComponent } from '../login-service/user-details/user-details.component';
import { PhotoInterfaceModule } from '../shared/components/photo-interface/photo-interface.module';
import { PublicPageModule } from '../public-page/public-page.module';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { DashboardModule } from '../dashboard/dashboard.module';

import { SibChatComponent } from '../sib-chat/sib-chat.component';
import { DirectiveModules } from './directive.modules';
import { HelpSupportComponent } from '../help-support/help-support.component'
import { SuggestionDialogModule } from '../suggestion-dialog/suggestion-dialog.module';
import { OutboundCallComponent } from '../outbound-call/outbound-call.component';
import { WebNotificationsModule } from '../shared/components/web-notifications/web-notifications.module';

@NgModule({
    imports: [
        // BrowserModule,
        // CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ColorPickerModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        SharedModule,
        ContextMenuModule,
        DataGridModule,
        DataListModule,
        // DataScrollerModule,
        DataTableModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GMapModule,
        GrowlModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        SibGridModule,
        ReactiveFormsModule,
        NotificationsModule,
        GMapModule,
        Ng4LoadingSpinnerModule.forRoot(),
        MapDialogModule,
        // HotkeyModule.forRoot(),
        SidebarModule,
        PhotoInterfaceModule,
        PublicPageModule,
        ProgressSpinnerModule,
        UserProfileModule,
        DashboardModule,
        DirectiveModules,
        SuggestionDialogModule,
        WebNotificationsModule
        // UploadDocumentModule
        // HelpSupportModule
    ],
    declarations: [
        AppComponent,
        // AppMenuComponent,
        AppSubMenu,
        AppTopBar,
        AppFooter,
        AppRightPanel,
        InlineProfileComponent,
        DashboardDemo,
        SampleDemo,
        FormsDemo,
        DataDemo,
        PanelsDemo,
        OverlaysDemo,
        MenusDemo,
        MessagesDemo,
        MessagesDemo,
        MiscDemo,
        ChartsDemo,
        EmptyDemo,
        FileDemo,
        UtilsDemo,
        Documentation,
        MainComponent,
        ProfileComponent,
        MainMenuComponent,
        MainSubmenuComponent,
        TestListCompComponent,
        ListPaginationSearchComponent,
        // SecuredDirective,
        // InputTrimDirective
        // IndustryDialogComponent
        // SecuredDirective
        UserDetailsComponent,
        SibChatComponent,
        HelpSupportComponent,
        OutboundCallComponent
    ],

    providers: [
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
        CarService,
        CountryService,
        EventService,
        NodeService,
        DemoCarService,
        // HotkeysService
        // SibApiService
    ],

})

export class SharedModules { }
