import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class PhotoInterfaceService extends ApiService<any, any, any> {
    urlPath: string = 'campaignItems/search/images';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.injector.get(BASE_URL);
    }

    updateInventoryImages(imagesObject) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        }

        if (navigator.onLine) {
            const body = JSON.stringify(imagesObject);
            return this.Http.post(this.baseUrl + 'campaignItems/images/updateInvImage', body, options)
                .pipe(map((response) => {
                    return response;
                }))
        }
    }

}


@Injectable()
export class PhotoInterfaceAllImagesService extends ApiService<any, any, any> {
    urlPath: string = 'images';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }
}
