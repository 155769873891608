<div class="planVersusCampaignChart">
  <div *ngIf="isLoading">
    <p-progressSpinner class="loading-specific-area"></p-progressSpinner>
  </div>
  <div class="planVersusCampaignActionContainer">
    <ul
      class="cinline-block float-right"
      [ngClass]="{ 'ul-width': lineChartData.length === 1 }"
    >
      <li class="input-switch" *ngIf="graphCustomization">
        <p-inputSwitch
          name="chartInputSwitch"
          [(ngModel)]="chartVisibility"
          (onChange)="onChartVisibilityChange($event)"
        >
        </p-inputSwitch>
      </li>
      <li class="margin-top-5 text-align-left">
        <p-multiSelect
          name="employee"
          [options]="employees"
          [style]="{ width: '12em' }"
          placeholder="Choose"
          (onChange)="onEmployeeChange($event)"
          [(ngModel)]="selectedEmployee"
          appendTo="body"
          maxSelectedLabels="1"
          [panelStyleClass]="'plan-campaign-employee-dropdown'"
        >
        </p-multiSelect>
      </li>
      <li class="sites-from-text">
        <div>Employee</div>
      </li>
      <li class="margin-top-5">
        <p-calendar
          [styleClass]="'site-from-button-hm'"
          dateFormat="ddMy"
          appendTo="body"
          [disabled]="!startDate || graphCustomization"
          placeholder=" Ex-01Jan18"
          (onInput)="onDateSelection($event)"
          (onSelect)="onDateSelection($event)"
          [(ngModel)]="endDate"
          [utc]="true"
          [showIcon]="true"
          [minDate]="stDate.value"
          #eDate="ngModel"
          name="eDate"
        ></p-calendar>
      </li>
      <li class="sites-from-text">
        <div>End Date</div>
      </li>
      <li class="margin-top-5">
        <p-calendar
          [styleClass]="'site-from-button-hm'"
          dateFormat="ddMy"
          appendTo="body"
          placeholder=" Ex-01Jan18"
          (onInput)="onDateSelection($event)"
          (onSelect)="onDateSelection($event)"
          [(ngModel)]="startDate"
          [utc]="true"
          [showIcon]="true"
          [disabled]="graphCustomization"
          [maxDate]="eDate.value"
          #stDate="ngModel"
          name="stDate"
        ></p-calendar>
      </li>
      <li class="sites-from-text">
        <div>Start Date</div>
      </li>
    </ul>
  </div>
  <div class="planVersusCampaignChartContainer">
    <sib-chart
      class="plan-campaign-line-chart"
      *ngIf="lineChartData.length > 1 && lineChartConfig"
      [data]="lineChartData"
      [lineConfig]="lineChartConfig"
      [chartType]="'linechart'"
      [elementId]="lineElementId"
    ></sib-chart>
    <div *ngIf="!lineChartData.length" class="no-message">
      <h3>No Plans/Campaigns data available for the selected period/user.</h3>
    </div>
  </div>
</div>
