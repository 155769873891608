<div *ngIf="isLoading" class="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div #invoiceCardRef class="invoice-chart-container">
  <!-- (eEmitChartClickedData)="chart($event)"   *ngIf="billingUIService.getBillingTabIndex() === '2'"-->
  <sib-invoice-chart [height]="invoiceChartHeight" [width]="invoiceChartWidth"
    (eEmitRefreshEvent)="refreshChart($event)" (eEmitChartClickedData)="chart($event)"
    (eEmitFinancialYearChanged)="financialYearChanged($event)" (eEmitSelectedFiscalYear)="setFinancialYear($event)">
  </sib-invoice-chart>
</div>
<div>
  <p-tabView (onChange)="onTabChange($event)" [activeIndex]="activeIndex">
    <p-tabPanel header="Invoice" *permission="'read:invoices'" [selected]="activeIndex == 0">
      <sib-invoice-list *ngIf="checkPermission('read:invoices') && activeIndex === 0" #invoiceList
        [isInvoiceChartFilter]="isInvoiceChartFilter" [invoiceChartEvent]="invoiceChartEvent"
        [showEmployee]="showEmployee" [isFinancialYearChanged]="isFinancialYearChanged" [financialYear]="financialYear">
      </sib-invoice-list>
    </p-tabPanel>
    <p-tabPanel header="Reserved Invoice" *permission="'read:invoices'">
      <sib-reserve-invoices
        *ngIf="(checkPermission('read:invoices') && activeIndex === 1) || (!checkPermission('read:invoices') && activeIndex === 0)"
        #reserveInvoiceList [isReserveInvoiceChartFilter]="isReserveInvoiceChartFilter"
        [reserveInvoiceChartEvent]="reserveInvoiceChartEvent" [isFinancialYearChanged]="isFinancialYearChanged"
        [financialYear]="financialYear" [showTitle]="showTitle" (eEmitIsLoading)="getIsLoading($event)"
        [showAction]="false" [showEmployee]="showEmployee"></sib-reserve-invoices>
    </p-tabPanel>
  </p-tabView>
</div>