import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { GridLoader } from "../../modals/loader-subjects/grid-loader";
import { PhotoLoader } from "../../modals/loader-subjects/photo-loader";
import { PPTLoader } from "../../modals/loader-subjects/pptloader";

@Injectable({
  providedIn: "root",
})
export class LoaderSubjects {
  pptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  xlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  gridLoader: Subject<GridLoader> = new Subject<GridLoader>();
  campaignXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  invoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  invoiceTallyXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  pendingUnbilledXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  photoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
  mountingPhotoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
  monitoringPhotoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
  flexItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  mountingItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  monitoringItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  unmountingItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  superbillListXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  reservedInvoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  migratedInvoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  creditNoteXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  reservedCreditNoteXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  campaignPptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  pocXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  hoardingXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  hoardingGroupXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  kioskXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  contractXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  salesChartXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  contractListXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  campaignTrackerXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  campaignTrackerPptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  roiContractDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  roiContractInventoryDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  untappedRoiDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  templateHoardingXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  templateHoardingGroupXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  templateKioskXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  campaignRollbackLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  loadingSubject: Subject<boolean> = new Subject<boolean>();

  pillarXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  bqsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  gantryXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  pickupStandXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  unipoleXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  signboardXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  utilityXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  ledXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  expenseLevel1Loader: Subject<PPTLoader> = new Subject<PPTLoader>();
  expenseLevel2Loader: Subject<PPTLoader> = new Subject<PPTLoader>();

  mountingReportXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  monitoringReportXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  unmountingReportXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  advanceSearchHoardingXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchKioskXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchPillarXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchBqsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchGantryXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchPickupStandXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchUnipoleXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchSignboardXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchUtilityXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  advanceSearchLedXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  confirmationQuoteLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
  inventoryPhotoUpdateLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

  planPptLoader: Subject<boolean> = new Subject<boolean>();
  isAllImagesDownloading: Subject<boolean> = new Subject<boolean>();
  capacityChartXlsLoader : Subject<boolean> = new Subject<boolean>();
}
