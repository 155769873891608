import { Injectable } from "@angular/core";
import { FileItem } from "../../modals/files/file-item";
import { MultiMedia } from "../../modals/hoardings/multiMedia";
import * as utils from "../../helpers/utils";
import { MultiMediaTypeEnum } from "../../shared/constants/multi-media-type-enum";
import { Picture } from "../../modals/hoardings/picture";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { PurchaseOrder } from "../../modals/campaigns/purchaseOrder";
import * as fileUtils from "../../helpers/file.util";
import { SibDocument } from "../../modals/sib-document";
import Compressor from "compressorjs";

@Injectable()
export class ImageService {
  tenantId: string = localStorage.getItem("id");
  environment: string = environment.name;

  databaseImageObject: Picture = new Picture();
  firebaseImageObjects: FileItem[] = [];
  databaseImageObjects: Picture[] = [];
  databaseImageObjects$: Subject<Picture[]> = new Subject();

  databasePoObject: PurchaseOrder = new PurchaseOrder();
  firebasePoObjects: FileItem[] = [];
  databasePoObjects: PurchaseOrder[] = [];
  databasePoObjects$: Subject<PurchaseOrder[]> = new Subject();
  files: any[] = [];
  firebaseDocObjects: FileItem[] = [];
  databaseDocObject: SibDocument = new SibDocument();
  databaseDocObjects: SibDocument[] = [];
  databaseDocObjects$: Subject<SibDocument[]> = new Subject();

  constructor(private sanitizer: DomSanitizer) {}

  getFirebaseImageObjects(files: any[] = []) {
    this.firebaseImageObjects = [];
    for (let i = 0; i < files.length; i++) {
      this.firebaseImageObjects.push(new FileItem(files[i], ""));
    }
    return this.firebaseImageObjects;
  }

  compressFile(file) {
    return Observable.create((observer) => {
      new Compressor(file, {
        // quality: 0.4,
        convertSize:1000000,
        success: (result) => {
          if (result) {
            observer.next(result);
            observer.complete();
          }
        },
      });
    });
  }

  getDatabaseImageObjects(cfiles: any[], multiMediaType: string) {
    this.databaseImageObjects = [];
    const originalLen = cfiles.length;
    this.files = cfiles;

    for (let i = 0; i < this.files.length; i++) {
      this.databaseImageObject = new Picture();
      (function (
        k,
        file,
        databaseImageObject,
        databaseImageObjects,
        databaseImageObjects$
      ) {
        const reader = new FileReader();
        databaseImageObject = new Picture();
        databaseImageObject.name = file.name;
        databaseImageObject.size = file.size;
        databaseImageObject.multiMediaType = utils.getKey(
          MultiMediaTypeEnum,
          multiMediaType
        );
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          databaseImageObject.url = reader.result;
          databaseImageObjects.push(
            JSON.parse(JSON.stringify(databaseImageObject))
          );
          if (databaseImageObjects.length === originalLen) {
            databaseImageObjects$.next(databaseImageObjects);
            databaseImageObjects = [];
            databaseImageObjects$.observers = [];
          }
        };
      })(
        i,
        this.files[i],
        this.databaseImageObject,
        this.databaseImageObjects,
        this.databaseImageObjects$
      );
    }
  }

  /**
   * @description return firebase po objects
   * @param {any[]} [files=[]]
   * @returns
   * @memberof ImageService
   */
  getFirebasePoObjects(files: any[] = []) {
    this.firebasePoObjects = [];
    for (let i = 0; i < files.length; i++) {
      this.firebasePoObjects.push(new FileItem(files[i], ""));
    }
    return this.firebasePoObjects;
  }

  getFirebaseDocObjects(files: any[] = []) {
    this.firebaseDocObjects = [];
    for (let i = 0; i < files.length; i++) {
      this.firebaseDocObjects.push(new FileItem(files[i], ""));
    }
    return this.firebaseDocObjects;
  }

  getDatabasePoObjects(files: any[] = [], multiMediaType: string) {
    this.databasePoObjects = [];
    const originalLen = files.length;
    this.files = files;
    for (let i = 0; i < this.files.length; i++) {
      this.databasePoObject = new PurchaseOrder();
      (function (
        k,
        file,
        databasePoObject,
        databasePoObjects,
        databasePoObjects$
      ) {
        const reader = new FileReader();
        databasePoObject = new PurchaseOrder();
        databasePoObject.name = file.name;
        const splitName = file.name.split(".");
        databasePoObject.extension = splitName[splitName.length - 1];
        databasePoObject.multiMediaType = utils.getKey(
          MultiMediaTypeEnum,
          multiMediaType
        );
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          databasePoObject.url = reader.result;
          databasePoObjects.push(JSON.parse(JSON.stringify(databasePoObject)));
          if (databasePoObjects.length === originalLen) {
            databasePoObjects$.next(databasePoObjects);
            databasePoObjects = [];
            databasePoObjects$.observers = [];
          }
        };
      })(
        i,
        this.files[i],
        this.databasePoObject,
        this.databasePoObjects,
        this.databasePoObjects$
      );
    }
  }

  getDatabaseDocObjects(files: any[] = [], multiMediaType: string) {
    this.databaseDocObjects = [];
    const originalLen = files.length;
    this.files = files;
    for (let i = 0; i < this.files.length; i++) {
      this.databaseDocObject = new SibDocument();
      (function (
        k,
        file,
        databaseDocObject,
        databaseDocObjects,
        databaseDocObjects$
      ) {
        const reader = new FileReader();
        databaseDocObject = new SibDocument();
        databaseDocObject.name = file.name;
        const splitName = file.name.split(".");
        databaseDocObject.extension = splitName[splitName.length - 1];
        databaseDocObject.multiMediaType = utils.getKey(
          MultiMediaTypeEnum,
          multiMediaType
        );
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          databaseDocObject.url = reader.result;
          databaseDocObjects.push(
            JSON.parse(JSON.stringify(databaseDocObject))
          );
          if (databaseDocObjects.length === originalLen) {
            databaseDocObjects$.next(databaseDocObjects);
            databaseDocObjects = [];
            databaseDocObjects$.observers = [];
          }
        };
      })(
        i,
        this.files[i],
        this.databaseDocObject,
        this.databaseDocObjects,
        this.databaseDocObjects$
      );
    }
  }
  // imageNameData?: any
  setFirebaseImageFolder(
    files: FileItem[],
    imageCategory: string,
    id?: string,
    subcategory?: string
  ) {
    const imageFolder = this.getImageFolder(imageCategory, id, subcategory);
    files.forEach((file) => {
      file.imagesFolder = imageFolder;
    });

    // this.setFirebaseImageName(files, imageNameData)

    return files;
  }

  // , firebaseFiles?: any
  setDatabaseImageFolder(
    files: Picture[],
    imageCategory: string,
    id?: string,
    subcategory?: string
  ) {
    const imageFolder = this.getImageFolder(imageCategory, id, subcategory);
    files.forEach((file, index) => {
      file.folder = imageFolder;
      /* if (firebaseFiles.length > 0) {
                file.name = firebaseFiles[index].file.name
            } */
    });

    return files;
  }

  getImageFolder(imageCategory: string, id?: string, subcategory?: string) {
    const imageFolder =
      this.environment +
      "/" +
      this.tenantId +
      "/" +
      imageCategory +
      "/" +
      (id ? id + "/" : "") +
      (subcategory ? subcategory + "/" : "");
    return imageFolder;
  }

  resetData() {
    // this.firebaseImageObjects = [];
    // this.databaseImageObjects = [];
    // this.databaseImageObjects$.next([]);
  }

  /* setFirebaseImageName(files, imageNameData) {

        var imageName = this.getImageName(imageNameData)
        this.setImageNameInFiles(files, imageName)
        var fileList = this.getFileList(files);
        fileUtils.renameDuplicateNames(fileList, 'name')
        for (let i = 0; i < files.length; i++) {
            files[i].file = fileList[i];
        }
    } */

  setFirebaseImageNames(files, databaseImages) {
    if (files.length === databaseImages.length) {
      this.setImageNameInFirebaseFile(files, databaseImages);
    } else if (files.length !== databaseImages.length) {
      var reverseFiles = files.reverse();
      var reverseDatabaseImages = databaseImages.reverse();
      this.setImageNameInFirebaseFile(reverseFiles, reverseDatabaseImages);
    }
  }

  setImageNameInFirebaseFile(files, databaseImages) {
    for (let i = 0; i < files.length; i++) {
      files[i]["name"] = databaseImages[i].name;
      Object.defineProperty(files[i].file, "name", {
        writable: true,
        value: databaseImages[i].name,
      });
    }
  }

  /**
   * Sets logo image names
   *
   * @param {*} files
   * @param {*} fileType
   * @memberof ImageService
   */
  setLogoImageNamesInFirebaseFile(files, fileType, imageObjects?) {
    let incrementedImageNameNum = 0;
    if (imageObjects) {
      imageObjects.forEach((imageObject) => {
        if (imageObject.id) {
          const index = imageObject.name ? imageObject.name.indexOf("[") : 0;
          imageObject.name &&
          Number(imageObject.name[index + 1]) > incrementedImageNameNum
            ? (incrementedImageNameNum = Number(imageObject.name[index + 1]))
            : null;
        }
      });
    }
    const index = incrementedImageNameNum + 1;

    for (let i = 0; i < files.length; i++) {
      var extension = fileUtils.getImageExtension(files[i].file.name);

      Object.defineProperty(files[i].file, "name", {
        writable: true,
        value:
          imageObjects && imageObjects.length > 0
            ? fileType + "[" + index + "]" + extension
            : fileType + extension,
      });
    }
  }

  setDatatbaseImageName(files, imageNameData) {
    var imageName = this.getImageName(imageNameData);
    this.setDatabaseImageNameInFiles(files, imageName);
    this.renameDuplicateNames(files);
  }

  renameDuplicateNames(files) {
    fileUtils.renameDuplicateNames(files, "name");
  }

  setDatabaseImageNameInFiles(files, imageName) {
    files.forEach((file) => {
      var extension = fileUtils.getImageExtension(file.name);
      file.name = imageName + extension;
    });
  }

  /* setImageNameInFiles(files, imageName) {

        files.forEach((file) => {
            var extension = fileUtils.getImageExtension(file.file.name)
            Object.defineProperty(file.file, 'name', {
                writable: true,
                value: imageName + extension
            })
            // file.file.name = imageName;
        })
    } */

  /* getFileList(files) {
        var fileList = [];
        files.forEach((file) => {
            fileList.push(file.file)
        })
        return fileList;
    } */

  getImageName(data) {
    if (data) {
      //  + (data.imageType ? " - " + data.imageType.toLowerCase() : "")
      return (
        data.hid +
        " - " +
        data.city +
        ", " +
        data.location +
        (data.size ? " - " + data.size : "")
      );
    }
  }
}
