import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ReservedCreditNoteMetadata } from '../../modals/billings/reserved-credit-note-metadata';
import { SelectItem, SelectItemGroup, Dropdown } from 'primeng/primeng';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { UserService } from '../../services/shared/user.service';
import { AppUrls } from '../../services/urls';
import { User } from '../../modals/campaigns/user';
import { InvoiceService } from '../../services/shared/invoice.service';
import { CompanyService } from '../../services/shared/company.service';
import { Customer } from '../../modals/KYC/customer';
import { InvoiceGstDetail } from '../../modals/billings/invoice-gst-detail';
import * as _ from "lodash";
import { SIBConstants } from '../../shared/constants/SIBConstant';
import * as utils from '../../helpers/utils';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';

@Component({
    selector: 'sib-reserve-credit-note-detail-dialog',
    templateUrl: './reserve-credit-note-detail-dialog.component.html',
    styleUrls: ['./reserve-credit-note-detail-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReserveCNDetailDialogComponent implements OnInit, OnDestroy {

    @ViewChild("groupedDropdown") groupedDropdown: Dropdown;
    @ViewChild("companyDropdown") companyDropdown: Dropdown;

    @Input() displayReserveCNDetailDialog = false;

    _rowData: ReservedCreditNoteMetadata = new ReservedCreditNoteMetadata(); // to store the row marked/unmarked as utilized
    @Input() set rowData(rowData) {
        if (rowData && rowData.tallyGeneratedDate) {
            rowData.tallyGeneratedDate = new Date(rowData.tallyGeneratedDate);
        }
        this._rowData = rowData;
    }

    get rowData() {
        return this._rowData;
    }

    @Output() eEmitDisplayReset: EventEmitter<boolean> = new EventEmitter();

    @Output() eEmitsaveCNDetails: EventEmitter<any> = new EventEmitter();

    @Output() eEmitUnutilizeCN: EventEmitter<any> = new EventEmitter();

    isLoading = false;
    salesReps: SelectItem[] = [];
    selectedSR: any = null;
    selectedCompany: any = null;
    selectedBranch: any = null;
    branchDropdown = false;
    branches: SelectItemGroup[] = [];
    companies: SelectItem[] = [];
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    address: string;
    customer: any;

    constructor(
        private notificationServcie: NotificatoinsService,
        private userService: UserService,
        private invoiceService: InvoiceChartService,
        private companyService: CompanyService,
    ) { }

    ngOnInit() { }

    /**
     * action to be performed when the dialog opens
     * 
     * @memberof ReserveCNDetailDialogComponent
     */
    showCNDetailsDialog() {
        this.isLoading = true;
        this.getAllSalesReps();
    }


    /**
     * Get all the sales representatives
     * 
     * @memberof ReserveCNDetailDialogComponent
     */
    getAllSalesReps() {
        this.salesReps = [];
        this.selectedSR = null;
        this.userService.get({ role: SIBConstants.SALES_REPRESENTATIVE }, AppUrls.SEARCH + AppUrls.BY_ROLE).subscribe(
            (response) => {
                if (response) {
                    const resp = response["_embedded"]["users"];
                    this.setSalesRepsDropdown(resp);
                    if (this.rowData.utilized) { // if the reserve invoice is utilized set the selected SR.
                        this.setSelectedSR();
                    } else {
                        this.isLoading = false;
                    }
                }
            },
            (error) => { // Error
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    /**
     * create SR dropdown
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setSalesRepsDropdown(resp) {
        this.salesReps = utils.createDropdownFromArray(resp, "fullName", false);
    }

    /**
     * create Company dropdown
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setCompanyDropdown(resp) {
        this.companies = utils.createDropdownFromArray(resp, 'billingName', false);
    }

    /**
     * set Selected SR 
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setSelectedSR() {
        this.isLoading = true;
        this.salesReps.filter((sr) => {
            if (sr.value.emailId === this.rowData.employee.emailId) { //Filter and set SR using emailId
                this.selectedSR = sr.value;
            } else {
                // Do nothing
            }
        });
        if (this.selectedSR) { // set Company if SR is selected
            this.setCompanyForSR();
        } else {
            // Do Nothing
        }
    }

    /**
     * action to be performed on SR selection.
     * Set company for selected SR.
     * @param {*} event
     * @memberof ReserveCNDetailDialogComponent
     */
    onSRChange(event) {
        this.selectedSR = event.value;
        this.setCompanyForSR();
    }

    /**
     * set the CN Details and closes the dialog
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    saveCNDetails() {
        this.isLoading = true;
        this.rowData.utilized = true;
        this.rowData.customer = (this.selectedCompany) ? this.selectedCompany : new Customer();
        this.rowData.customer.address1 = this.address ? this.address : this.selectedCompany.address1;
        this.rowData.invoiceGstDetail = (this.selectedBranch) ? new InvoiceGstDetail().setInvoiceGstDetail(this.createBranchItemObject(this.selectedBranch)) : new InvoiceGstDetail();
        this.rowData.employee = (this.selectedSR) ? this.selectedSR : new User();
        this.eEmitsaveCNDetails.emit(this.rowData);
        this.isLoading = false;
        this.displayReserveCNDetailDialog = false;
    }

    /**
     * cancel the CN details and closes the dialog
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    cancelCNDetails() {
        this.isLoading = true;
        this.hideCNDetailsDialog();
    }

    /**
     * action to be performed when the CN is unutilized.
     * resets the CN details.
     * @memberof ReserveCNDetailDialogComponent
     */
    unutilizedCN() {
        this.isLoading = true;
        this.rowData.utilized = false;
        this.rowData.utilizedDate = null;
        this.rowData.tallyGeneratedDate = null;
        this.rowData.employee = new User();
        this.rowData.displayName = null;
        this.eEmitUnutilizeCN.emit(this.rowData);
        this.isLoading = false;
        this.displayReserveCNDetailDialog = false;
    }

    /**
     * closes the CN dialog
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    hideCNDetailsDialog() {
        this.isLoading = false;
        this.displayReserveCNDetailDialog = false;
        this.eEmitDisplayReset.emit(false);
    }

    /**
     * show Error Notification
     *
     * @param {*} error
     * @memberof ReserveCNDetailDialogComponent
     */
    showErrorNotification(error) { // error notification
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
    }

    /**
     * show Success Notification
     *
     * @param {*} message
     * @param {*} header
     * @memberof ReserveCNDetailDialogComponent
     */
    showSuccessNotification(message, header) { // Success notification
        this.notificationServcie.success(message, header);
    }

    /**
     * Set the invoice Gst Details
     *
     * @param {*} invoiceId
     * @memberof ReserveCNDetailDialogComponent
     */
    setGstDetail(invoiceId) {
        this.isLoading = true;
        this.invoiceService.get({ invoiceId: invoiceId }, '/' + AppUrls.RESERVE + AppUrls.GET_GST_DETAIL).subscribe((response) => {
            if (response && response['data'].length > 0) { // set invoice Gst detail and customer
                this.invoiceGstDetail = response['data'][0].invoiceGstDetail;
                this.customer = response['data'][0].customer;
                this.rowData.displayName = response['data'][0].displayName; // set display name of invoice
                if (this.rowData.invoiceReferenceId) { // set selected company 
                    // this.isLoading = true;
                    this.setSelectedCompany();
                } else {
                    this.isLoading = false;
                }
            } else if (this.rowData.utilized) {
                this.customer = this.rowData.customer;
                this.setSelectedCompany();
                this.isLoading = false;
            } else {
                this.isLoading = false;
            }
        },
            (error) => { // Error
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    /**
     * set Company for SR
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setCompanyForSR() {
        this.companies = [];
        this.companyService.getCompaniesWithSrEmail(this.selectedSR.emailId).subscribe((response: any) => {
            if (response && response.length > 0) {
                this.setCompanyDropdown(response);
                if (this.rowData.utilized) { // set Invoice gst detail
                    // this.isLoading = true;
                    // this.setGstDetail(this.rowData.invoiceReferenceId);
                    this.customer = this.rowData.customer;
                    this.setSelectedCompany();
                } else {
                    this.isLoading = false;
                }
            } else {
                this.isLoading = false;
            }
        });

    }

    /**
     * Set Selected Company
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setSelectedCompany() {
        this.address = null;
        this.selectedBranch = null;
        this.companies.forEach((company) => {
            if (company.value.id === this.customer.id && this.rowData.utilized) { // filters and sets the customer if reserved invoice is utilized
                company.value = this.customer;
                this.selectedCompany = company.value;
                this.address = this.selectedCompany.address1;
            } else {
                // Do Nothing
            }
            if (company.value.id === this.customer.id && !this.rowData.utilized) { // filters and sets the customer if not utilized
                this.selectedCompany = company.value;
                this.address = this.selectedCompany.address1;
            } else {
                // Do Nothing
            }

        });
        if (this.selectedCompany) { // set the branch if the customer is selected
            this.setBranches();
        } else {
            this.isLoading = false;
        }
    }

    /**
     * Set Branches of the Selected Company
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setBranches() {
        this.branches = [];
        this.selectedBranch = null;
        this.groupedDropdown.value = '';
        if (this.selectedCompany !== undefined && this.selectedCompany !== null &&
            this.selectedCompany.gstDetails !== undefined && this.selectedCompany.gstDetails !== null) { // if selectedCompany exists then set branch
            this.setBranchDropdown();
            if (this.rowData.invoiceReferenceId && this.branches.length > 0) { // set Selected Branch
                this.setSelectedBranch();
            } else { // otherwise
                this.isLoading = false;
            }
        } else {
            this.isLoading = false;
        }
    }

    /**
     * Set Selected Branch
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setSelectedBranch() {
        let branchNotMatchedCount: number = 0;
        this.branches.forEach((branch) => {
            branchNotMatchedCount = 0;
            branch.items.forEach((item) => {
                if (this.checkEqualityForBranch(item.value, this.rowData.invoiceGstDetail)) {
                    this.selectedBranch = item.value;
                    this.groupedDropdown.value = item.value;
                } else {
                    branchNotMatchedCount += 1;
                }
            });
            if (branchNotMatchedCount === this.branches[0].items.length) {
                this.selectedBranch = this.branches[0].items[0].value;
            }
        });

        this.isLoading = false;
    }

    checkEqualityForBranch(item, gstDetail) {
        let result = false;
        if (item.gst.gstNo === gstDetail.gstNo && item.gst.panNo === gstDetail.panNo &&
            item.address.address1 === gstDetail.branchAddress.address1 &&
            item.address.branchName === gstDetail.branchAddress.branchName &&
            item.address.postalCode === gstDetail.branchAddress.postalCode) {
            result = true;
        }
        return result;
    }

    /**
     * action to be performed on Branch Selection.
     * set selected Branch
     * @param {*} event
     * @memberof ReserveCNDetailDialogComponent
     */
    onBranchChange(event) {
        this.selectedBranch = event.value;
    }

    /**
     * action to be performed on company selection
     * set selected Company and branch
     * @param {*} event
     * @memberof ReserveCNDetailDialogComponent
     */
    onCompanyChange(event) {
        this.selectedCompany = event.value;
        // console.log(event);
        this.address = this.selectedCompany.address1;
        this.setBranches();

    }

    /**
     * set Branch Dropdown
     *
     * @memberof ReserveCNDetailDialogComponent
     */
    setBranchDropdown() {
        this.selectedCompany.gstDetails.forEach((gst) => {
            const items: SelectItem[] = [];
            gst.branchAddress.forEach((add) => {
                const value = { address: add, gst: gst };
                items.push({ label: add.branchName + '-' + add.city.name, value: value });
            });
            this.branches.push({ label: gst.gstNo, value: gst, items: items });
        });
    }

    createBranchItemObject(branch) {
        const branchClone = _.cloneDeep(branch); // to prevent the actual branch in dropdown from getting changed
        let index;
        branchClone.gst.branchAddress.filter((add, ind) => {
            if (JSON.stringify(branchClone.address) === JSON.stringify(branchClone.gst.branchAddress[ind])) {
                index = ind;
            }
        });
        if (index !== undefined) {
            branchClone.gst.branchAddress = branchClone.gst.branchAddress.filter((add, ind) => {
                if (ind === index) {
                    return add;
                }
            });
            return branchClone.gst;
        }
    }

    ngOnDestroy() { }

}
