import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class FilterService extends ApiService<any, any, any> {
  urlPath: string = "userConfigurations";

  constructor(Http: HttpClient, Injector: Injector, private http: Http) {
    super(Http, Injector);
  }
}
