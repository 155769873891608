<div *ngIf="isDashboardVisible">
  <!-- *ngIf="showDashboardModule" -->
  <div class="float-right customize-graph margin-8">
    <div *ngIf="!graphCustomization">
      <i
        class="fa fa-pencil-square-o icon margin-right-5 customize-graph-icon"
        title="Customize Graphs"
        (click)="customizeGraph()"
      >
      </i>
      <label for="chart">Customize Graphs</label>
    </div>
    <div *ngIf="graphCustomization">
      <i
        class="fa fa-check-circle icon margin-right-5 customize-graph-icon"
        title="Save Graphs Configuration"
        (click)="saveChartConfiguration()"
      >
      </i>
      <label for="chart">Done</label>
    </div>
    <!-- <button type="button" class="mtab-primary-button" pButton *ngIf="graphCustomization"
        (click)="saveChartConfiguration()">DONE
    </button> -->
  </div>
  <!-- [ngClass]="{'margin-top-36' : showDashboardModule}" -->
  <div class="top-db-sec-cont margin-top-36">
    <div class="card count-container">
      <sib-inventory-count-card></sib-inventory-count-card>
    </div>
    <div class="card count-container">
      <sib-customer-count-card></sib-customer-count-card>
    </div>
    <div class="card count-container">
      <sib-plan-count-card></sib-plan-count-card>
    </div>
    <div class="card count-container">
      <sib-campaign-count-card></sib-campaign-count-card>
    </div>
    <div class="card count-container">
      <sib-user-count-card></sib-user-count-card>
    </div>
  </div>

  <!-- Provided permission for capacity chart -->
  <div
    class="capacity-chart margin-top-16"
    [ngClass]="{
      'box-shadow-tab': !graphCustomization,
      'container-border': graphCustomization
    }"
    *permission="'view:capacity-chart'"
  >
    <!-- showDashboardModule && -->
    <sib-capacity-chart
      *ngIf="
        (user &&
          user.dashBoardCardToIshidden &&
          user.dashBoardCardToIshidden['CAPACITY_CHART']) ||
        graphCustomization
      "
      [showTitle]="false"
      [showAction]="false"
      [graphCustomization]="graphCustomization"
      [capacityChartVisibility]="capacityChartVisibility"
      (eEmitChartVisibility)="capacityChartVisibility = $event"
    >
    </sib-capacity-chart>
  </div>

  <div
    class="margin-top-16"
    [ngClass]="{
      'box-shadow-tab': !graphCustomization,
      'container-border': graphCustomization
    }"
  >
    <sib-plan-campaign-comparison-chart
      *ngIf="
        (user &&
          user.dashBoardCardToIshidden &&
          user.dashBoardCardToIshidden['PLAN_VS_CAMPAIGN_CHART']) ||
        graphCustomization
      "
      [graphCustomization]="graphCustomization"
      [chartVisibility]="chartVisibility"
      (eEmitChartVisibility)="chartVisibility = $event"
    >
    </sib-plan-campaign-comparison-chart>
  </div>

  <div
    class="margin-top-16"
    [ngClass]="{
      'box-shadow-tab': !graphCustomization,
      'container-border': graphCustomization
    }"
  >
    <sib-industrial-chart
      *ngIf="
        (user &&
          user.dashBoardCardToIshidden &&
          user.dashBoardCardToIshidden['INDUSTRY_CHART']) ||
        graphCustomization
      "
      [graphCustomization]="graphCustomization"
      [industrialChartVisibility]="industrialChartVisibility"
      (eEmitChartVisibility)="industrialChartVisibility = $event"
    >
    </sib-industrial-chart>
  </div>

  <div
    class="margin-top-16"
    [ngClass]="{
      'box-shadow-tab': !graphCustomization,
      'container-border': graphCustomization
    }"
    *ngIf="userRole === 'Admin'"
  >
    <sib-employee-table-chart
      *ngIf="
        (user &&
          user.dashBoardCardToIshidden &&
          user.dashBoardCardToIshidden['EMP_SALES_CHART']) ||
        graphCustomization
      "
      [graphCustomization]="graphCustomization"
      [employeeChartVisibility]="employeeChartVisibility"
      (eEmitChartVisibility)="employeeChartVisibility = $event"
    >
    </sib-employee-table-chart>
  </div>
</div>
