import { Address } from "../contracts/address";
import { GSTDetail } from "../KYC/GSTDetails";
import { SupplierVendorType } from "./supplier-vendor-type";

export class SupplierVendor {
  id: string;
  name: string;
  discountPercentage: number = 0;
  address = new Address();
  facebookUrl: string = "";
  linkedinUrl: string = "";
  emailIds: any[] = [''];
  contactNumber1: string = "";
  contactNumber2: string = "";
  type: SupplierVendorType;
  gstDetails: GSTDetail[] = [];
  pan: string;
  website: string = "";
  updatedBy: string;
  note: string = "";
}

export class SupplierVendorForCreation {
  id: string;
  name: string;
  discountPercentage: number = 0;
  address = new Address();
  facebookUrl: string = "";
  linkedinUrl: string = "";
  emailIds: any[] = [{email:''}];
  contactNumber1: string = "";
  contactNumber2: string = "";
  type: SupplierVendorType;
  gstDetails: GSTDetail[] = [];
  pan: string;
  website: string = "";
  updatedBy: string;
  note: string = "";
}
