<div class="assignToPlan padding-0">
    <div *ngIf="isloading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>


    <form #newPlanForm="ngForm" autocomplete="off">
        <div class="ui-g">
            <div class="ui-g-12" *ngIf="!planEdit">
                <div class="ui-float-label dropdown-icon">
                    <p-autoComplete #selectedPlanText="ngModel" [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedPlan"
                        (ngModelChange)="selectedPlan =$event" [suggestions]="filteredPlans"
                        (completeMethod)="filterPlan($event)" field="displayName" (onSelect)="getSelectedPlan()"
                        (onClear)="clearSelectedPlan()" name="selectedPlan" [minLength]="0" [multiple]="false"
                        [dropdown]="true" scrollHeight="160px" styleClass="wid20" forceSelection="true"
                        [style]="{'width':'100%'}">
                    </p-autoComplete>
                    <label>Select from Existing Plan</label>
                </div>
            </div>
            <div class="ui-g-12 padding-top-20" *permission="'readAll'">
                <div class="ui-float-label" style="width:100%">
                    <p-autoComplete [inputStyle]="{'width':'100%'}" [ngModel]="selectedEmployee"
                        (ngModelChange)="selectedEmployee=$event" [suggestions]="filteredEmployees" field="fullName"
                        (completeMethod)="filterEmployees($event)" name="selectedEmployee" [minLength]="0"
                        [multiple]="false" [dropdown]="true" scrollHeight="160px" styleClass="wid20"
                        (onSelect)="setCompanyForEmployee()" forceSelection="true" [style]="{'width':'100%'}"
                        (onClear)="clearSelectedEmployee()" changes [changedValue]="selectedEmployee?.fullName"
                        [changedType]="getChangedType()" [changedField]="'employee.fullName'"
                        [changedFieldDisplayName]="'Sales Representative'">
                    </p-autoComplete>
                    <label>Select a Sales Rep</label>
                </div>
            </div>
            <div class="ui-g-12 padding-top-20">
                <div class="ui-float-label" style="width:100%">
                    <p-autoComplete [inputStyle]="{'width':'100%'}" [ngModel]="selectedBillingName"
                        (ngModelChange)="selectedBillingName=$event" [suggestions]="filteredBillingName"
                        (completeMethod)="filterBillingName($event)" field="billingName" name="billingName"
                        [minLength]="0" [multiple]="false" [dropdown]="true" scrollHeight="160px" styleClass="wid20"
                        forceSelection="true" [style]="{'width':'100%'}" changes
                        [changedValue]="selectedBillingName?.billingName" [changedType]="getChangedType()"
                        [changedField]="'customer.billingName'" [changedFieldDisplayName]="'Billing Name'">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.billingName}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <label>Billing Name</label>
                </div>
            </div>
            <div class="ui-g-12 width100 padding-top-20">
                <div class="ui-float-label">
                    <input sibTitlecase id="displayName" trim="blur" maxlength="100" type="text" pInputText
                        name="displayName" [(ngModel)]="newPlan.displayName" (ngModelChange)="newPlan.displayName=$event"
                        autocomplete="off" #displayName="ngModel" [disabled]="selectedPlan?.id !== null"
                        pTooltip="{{displayName.hasError('required')? 'Required field' : displayName.hasError('maxlength')? '' : 'Max Length is 100 characters'}}"
                        required changes [changedValue]="newPlan?.displayName" [changedType]="getChangedType()"
                        [changedField]="'displayName'" [changedFieldDisplayName]="'Display Name'" />
                    <label for="displayName">Display Name
                        <span class="required">*</span>
                    </label>
                </div>
            </div>
            <div class="ui-g-12 width100 padding-top-20">
                <div class="ui-g-5 custom-padding">
                    <span class="ui-float-label">
                        <p-calendar [inputStyle]="{'width':'100%'}" #planStartDate="ngModel" id="stDate"
                            name="planStartDate" [utc]="true" [ngModel]="newPlan.campaignStartDate"
                            (ngModelChange)="newPlan.campaignStartDate=$event" showIcon="true" dateFormat="ddMy"
                            shortYearCutoff="100" monthNavigator="true" [minDate]="currentDate" yearNavigator="true"
                            [disabled]="selectedPlan?.id !== null" [yearRange]="'2017:2050'" [utc]="true" required
                            (onSelect)="validateDates()" appendTo="body" (keydown)="checkStartDateValue()"
                            (keyup)="checkStartDateValue()" (blur)="checkStartDateValue()"
                            pTooltip="{{planStartDate.hasError('required')? 'Ex - 01Jan18' :''}}" trim="blur" changes
                            [changedValue]="newPlan?.campaignStartDate" [changedType]="getChangedType()"
                            [changedField]="'campaignStartDate'" [changedFieldDisplayName]="'Plan Start Date'"
                            [formatterType]="'date'">
                        </p-calendar>
                        <label for="stDate">Start Date
                            <span class="required">*</span>
                        </label>
                    </span>
                </div>

                <div class="ui-g-5 custom-padding">
                    <span class="ui-float-label">
                        <p-calendar [inputStyle]="{'width':'100%'}" #planEndDate="ngModel" id="endDate"
                            name="planEndDate" shortYearCutoff="100" showIcon="true" [utc]="true"
                            [ngModel]="newPlan.campaignEndDate" (ngModelChange)="newPlan.campaignEndDate=$event"
                            [disabled]="!planStartDate.value || selectedPlan?.id !== null" dateFormat="ddMy"
                            [minDate]="planStartDate.value < currentDate ? currentDate : planStartDate.value"
                            pTooltip="{{planEndDate.hasError('required')? 'Ex - 01Jan18' :''}}" trim="blur"
                            [defaultDate]="planStartDate.value" (onSelect)="validateDates()" monthNavigator="true"
                            yearNavigator="true" [yearRange]="'2017:2050'" required [utc]="true" appendTo="body" changes
                            [changedValue]="newPlan?.campaignEndDate" [changedType]="getChangedType()"
                            [changedField]="'campaignEndDate'" [changedFieldDisplayName]="'Plan End Date'"
                            [formatterType]="'date'">
                        </p-calendar>
                        <label for="endDate">End Date
                            <span class="required">*</span>
                        </label>
                    </span>
                </div>
                <div class="ui-g-2 padding-0">
                    <span class="ui-float-label">
                        <input pInputText type="number" #noOfDays id="noOfDays" name="noOfDays"
                            (focus)="checkStartDateValue()" [min]="1" [max]="1000" pKeyFilter="pint"
                            [disabled]="selectedPlan?.id !== null"
                            (input)="((noOfDays.value && noOfDays.value !== '0') ? noOfDays.value : noOfDays.value ='');setDates(noOfDays.value)"
                            (keyup)="((noOfDays.value && noOfDays.value !== '0') ? noOfDays.value : noOfDays.value ='');setDates(noOfDays.value)">
                        <label for="noOfDays">Days
                        </label>
                    </span>
                </div>
            </div>
            <div class="ui-g-12 width100 padding-top-20">
                <div class="md-inputfield">
                    <input sibTitlecase type="text" [ngModel]="newPlan.note" (ngModelChange)="newPlan.note=$event"
                        name="planNote" (ngModelOptions)="{standAlone: true}" #planNote="ngModel"
                        [disabled]="selectedPlan?.id !== null" pInputText changes [changedValue]="newPlan?.note"
                        [changedType]="getChangedType()" [changedField]="'note'" [changedFieldDisplayName]="'Note'">
                    <label>Note</label>
                </div>
            </div>
        </div>
    </form>

    <div class="ctext-center">
        <button type="button" pButton class="mtab-save margin-hori-5"
            label="{{ copyConvertedPlan ? 'COPY' : planEdit || selectedPlan?.id !== null ? 'UPDATE' : 'CREATE'}}" [disabled]="!newPlanForm.valid"
            (click)="createPlan()"></button>

        <button type="button" pButton class="mtab-secondary" label="CANCEL" (click)="hidePlanDialog()"></button>
    </div>
</div>