import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { PackageModulesEnum } from '../../shared/constants/package-modules-enum';
import { SubscriptionDetail } from '../subscriptions/subscription-detail';

@Component({
  selector: 'sib-subscription-package',
  templateUrl: './subscription-package.component.html',
  styleUrls: ['./subscription-package.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionPackageComponent implements OnInit {

  @Input() package: any;
  @Output() eEmitSelectedPackage: EventEmitter<any> = new EventEmitter();
  packageModules: any;
  subscriptionDetail: SubscriptionDetail;
  selectedPackage: any;
  showConfirmationDialog: boolean = false;

  constructor() { }

  ngOnInit() {
    this.packageModules = Object.keys(PackageModulesEnum);
  }

  kFormatter(num) {
    return (Math.abs(num) > 999 ? ((Math.abs(num) / 1000)) + 'k' : Math.sign(num) * Math.abs(num));
  }


  /**
   * @description when user selects susbcription package
   * @author Raveena Nathani
   * @date 2020-06-05
   * @param {*} plan
   * @memberof SubscriptionPackageComponent
   */
  onSubscriptionBuy(plan) {
    this.eEmitSelectedPackage.emit(plan)
  }


}
