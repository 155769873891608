<div class="invoice-chart-container">
  <div
    class="loading-specific-area billing-chart-loader"
    *ngIf="!columnChartData || !columnChartConfig || !columnChartData.length"
  >
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="ui-g fyear-dropdown">
    <span class="ui-float-label">
      <p-dropdown
        [options]="fiscalYears"
        [dropdownIcon]="'fa fa-calendar'"
        [styleClass]="'fiscal-dropdown'"
        [(ngModel)]="selectedFiscalYear"
        [style]="{ width: '100px' }"
        placeholder="Financial Year"
        (onChange)="onFiscalYearSelection()"
      ></p-dropdown>
      <label>Financial Year</label>
    </span>
  </div>
  <div>
    <div
      class="flex-container float-right margin-top--40"
      *ngIf="columnChartData.length > 0 && columnChartConfig"
    >
      <!-- <div class="flex-item margin-10 icon" pTooltip="Invoice Total With Tax = {{totalInvoiceAmount}}
                                                      Invoice Total Without Tax = {{totalInvoiceAmountWithoutTax}}"> -->
      <div
        class="flex-item margin-10 icon"
        (mouseenter)="showOverlay($event)"
        (mouseleave)="overlay.hide($event)"
      >
        <strong> Total = {{ totalInvoiceAmount }}</strong>
      </div>
    </div>
    <sib-chart
      *ngIf="columnChartData.length > 0 && columnChartConfig"
      [data]="columnChartData"
      [columnConfig]="columnChartConfig"
      [chartType]="chartType"
      [elementId]="lineElementId"
    ></sib-chart>
  </div>

  <p-overlayPanel #overlay [style]="{ width: '240px' }">
    <ol class="ordered-list">
      <li>Invoice Total With Tax = {{ totalInvoiceAmount }}</li>
      <li>Invoice Total Without Tax = {{ totalInvoiceAmountWithoutTax }}</li>
    </ol>
  </p-overlayPanel>
</div>
