
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/primeng';
import { MenuConstants } from '../../../constants/menu-constants';
import { MountImage } from '../../../../modals/photo-interface/mount-image';
import { UnmountImage } from '../../../../modals/photo-interface/unmount-image';
import { MonitoringImage } from '../../../../modals/photo-interface/monitoring-image';
import { PaginationData } from '../../../../modals/paginationdata';
import { Subject } from 'rxjs';
import { PhotoInterfaceGlobalSrevice } from './services/photo-interface-global.service';
import { ImageTypeEnum } from '../../../constants/image-type-enum';
import { MediaSubCategoryEnum } from '../../../constants/media-sub-category-enum';
import { PhotoDialogComponent } from '../../photo-dialog/photo-dialog.component';
import { ImageDataDisplay } from '../../../../modals/image/image-data';
import { ImageBO } from '../../../../modals/photo-interface/image-bo';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../../../shared/constants/SIBConstant';
import * as utils from '../../../../helpers/utils';
import { MountedImageComponent } from './mounted-image/mounted-image.component';
import { MonitoringImageComponent } from './monitoring-image/monitoring-image.component';
import { UnmountImageComponent } from './unmount-image/unmount-image.component';
export class FilterObject {
  selectedFilter: {
    filter: any;
    tabIndex: number
  };
}

export interface SearchFilter {
  filters?: any;
  globalFilter?: any;
}

@Component({
  selector: 'sib-photo-interface',
  templateUrl: './photo-interface.component.html',
  styleUrls: ['./photo-interface.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class PhotoInterfaceComponent implements OnInit, AfterViewInit {

  @ViewChild('mountingImage') mountingImageComponent;
  @ViewChild('monitoringImage') monitoringImageComponent;
  @ViewChild('unmountingImage') unmountingImageComponent;
  @ViewChild(MountedImageComponent) MountedImageComponent: MountedImageComponent;
  @ViewChild(MonitoringImageComponent) MonitoringImageComponent: MonitoringImageComponent;
  @ViewChild(UnmountImageComponent) UnmountImageComponent: UnmountImageComponent;
  @ViewChild('inventoryImage') inventoryImageComponent;
  @ViewChild('allImages') allImagesComponent;
  @ViewChild("photoDialog") photoDialog: PhotoDialogComponent;

  menu: MenuItem[]; // more menu
  tabIndex = 0;
  activeIndex: number;
  disableMenuDownload = true; // to disable the download option in menu, now outside of menu
  selectedMountImages: MountImage[] = [];
  selectedUnmountImages: UnmountImage[] = [];
  selectedMonitoringImages: MonitoringImage[] = [];
  selecteInventoryImages: any[] = [];
  selectedAllImages: ImageBO[] = [];
  globalSearchData: Subject<any> = new Subject();
  searchData: PaginationData = {
    filters: {},
    globalFilter: ''
  };
  filterOptions: SelectItem[] = []; // multiselect dropdown options
  selectedFilters: any[] = [];
  filterObjectData: Subject<FilterObject> = new Subject();

  globalFilterFields = ['HID', 'City', 'Location', 'Display Name'];
  
  totalMountedImagesCount: number;
  totalMonitoringImagesCount: number;
  totalUnmountedImagesCount: number;

  constructor(
    private photoInterfaceGlobalSrevice: PhotoInterfaceGlobalSrevice,
    private pageTitle: Title
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.PHOTO_INTERFACE_MENU) : this.pageTitle.setTitle(SIBConstants.PHOTO_INTERFACE_MENU + ' - ' + appTitle);
    this.getLastActiveTabIndex();
    this.setMenus();
    this.globalSearchData.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe((searchValue) => {
        this.photoInterfaceGlobalSrevice.globalFilter.next(searchValue);
      });
      this.MountedImageComponent.getData();
      this.MonitoringImageComponent.getData();
      this.UnmountImageComponent.getData();
  }

  ngAfterViewInit() {
    // // to set the default value of filter options
    // this.onTabChange(0);
    this.getLastActiveTabIndex();
  }

  getHeaders(operationType: number){
    switch(operationType){
      case 0: {
        return `Mounted(${this.totalMountedImagesCount ? this.totalMountedImagesCount : 0})`;
      }
      case 1: {
        return `Monitoring(${this.totalMonitoringImagesCount ? this.totalMonitoringImagesCount : 0})`;
      }
      case 2: {
        return `Unmounted(${this.totalUnmountedImagesCount ? this.totalUnmountedImagesCount : 0})`;
      }
    }
  }

  getLastActiveTabIndex() {
    this.onTabChange(Number(this.photoInterfaceGlobalSrevice.getPhotoInterfaceTabIndex()));
  }

  setMenus() {
    this.setMoreMenu();
  }

  setMoreMenu() {
    this.menu = [
      {
        label: String(MenuConstants.DOWNLOAD_ALL), icon: 'fa-download',
        command: (event) => {
          // this.downloadSelectedImages();
          this.downloadAllImages();
        },
        // disabled: this.disableMenuDownload,
      },
    ];
  }

  onTabChange(index) {
    this.tabIndex = index;
    this.activeIndex = index;
    this.setSelectedFilters();
    this.setMenuDownload();
    this.setFilterOptions();
    this.photoInterfaceGlobalSrevice.setPhotoInterfaceTabIndex(this.tabIndex);
  }

  setSelectedFilters() {
    const currentTabObject = this.getCurrentTabObject();
    const filterObject: FilterObject = new FilterObject();
    filterObject.selectedFilter = {
      // filter: (currentTabObject.component.searchData.filters && currentTabObject.component.searchData.filters[currentTabObject.component.filterField] && currentTabObject.component.searchData.filters[currentTabObject.component.filterField].value) ? currentTabObject.component.searchData.filters[currentTabObject.component.filterField].value : undefined,
      filter: (currentTabObject && currentTabObject.component && currentTabObject.component.searchData.filters) ? currentTabObject.component.searchData.filters : undefined,
      tabIndex: this.tabIndex
    };
    this.selectedFilters = filterObject.selectedFilter.filter;
    this.photoInterfaceGlobalSrevice.filterObject.next(filterObject);
  }

  setSelectedMountImages(event) {
    this.selectedMountImages = event;
    this.setMenuDownload();
  }

  setSelectedMonitoringImages(event) {
    this.selectedMonitoringImages = event;
    this.setMenuDownload();
  }

  setSelectedUnmountingImages(event) {
    this.selectedUnmountImages = event;
    this.setMenuDownload();
  }

  setSelectedInventoryImages(event) {
    this.selecteInventoryImages = event;
    this.setMenuDownload();
  }

  setSelectedAllImages(event) {
    this.selectedAllImages = event;
    this.setMenuDownload();
  }

  setMenuDownload() {
    const currentTabObject = this.getCurrentTabObject();
    this.disableMenuDownload = (currentTabObject && currentTabObject.disableMenuDownload !== undefined) ? currentTabObject.disableMenuDownload : this.disableMenuDownload;
    this.setMoreMenu();
  }

  downloadAllImages() {
    const currentTabObject = this.getCurrentTabObject();
    if (currentTabObject && currentTabObject.component) {
      currentTabObject.component.downloadAllImages();
    } else {
      // do notihng
    }
  }

  downloadSelectedImages() {
    const currentTabObject = this.getCurrentTabObject();
    if (currentTabObject && currentTabObject.component) {
      currentTabObject.component.downloadSelectedImages();
    } else {
      // do notihng
    }
  }

  
  updateSelectedPhotos(){
    const currentTabObject = this.getCurrentTabObject();
    if (currentTabObject && currentTabObject.component) {
      currentTabObject.component.updateSelectedImages();
    } else {
      // do notihng
    }
  }


  setFilterOptions() {
    const currentTabObject = this.getCurrentTabObject();
    this.filterOptions = (currentTabObject && currentTabObject.filterOptions) ? currentTabObject.filterOptions : this.filterOptions;
  }
  getTotalMountingImagesCount(numberOfMountedImages: number){
    this.totalMountedImagesCount = numberOfMountedImages;
  }

  getTotalMonitoringImagesCount(numberOfMonitoringImages: number){
    this.totalMonitoringImagesCount = numberOfMonitoringImages;
  }

  getTotalUnmountedImagesCount(numberOfUnmountedImages: number){
    this.totalUnmountedImagesCount = numberOfUnmountedImages;
  }

  getCurrentTabObject() {
    let currentTabObject: {
      filterOptions: SelectItem[],
      component: any,
      disableMenuDownload: boolean,
      selectedItemCount: number
    };
    switch (this.tabIndex) {
      // case 0: {
      //   if (this.allImagesComponent) {
      //     currentTabObject = { filterOptions: this.allImagesComponent.allImagesFilterOptions, component: this.allImagesComponent, disableMenuDownload: (this.selectedAllImages.length > 0) ? false : true, selectedItemCount: this.selectedAllImages.length };
      //   }
      //   break;
      // }
      case 0: {
        if (this.mountingImageComponent) {
          currentTabObject = { filterOptions: this.mountingImageComponent.mountFilterOptions, component: this.mountingImageComponent, disableMenuDownload: (this.selectedMountImages.length > 0) ? false : true, selectedItemCount: this.selectedMountImages.length };
        }
        break;
      }
      case 1: {
        if (this.monitoringImageComponent) {
          currentTabObject = { filterOptions: this.monitoringImageComponent.monitoringFilterOptions, component: this.monitoringImageComponent, disableMenuDownload: (this.selectedMonitoringImages.length > 0) ? false : true, selectedItemCount: this.selectedMonitoringImages.length };
        }
        break;
      }
      case 2: {
        if (this.unmountingImageComponent) {
          currentTabObject = { filterOptions: this.unmountingImageComponent.unmountFilterOptions, component: this.unmountingImageComponent, disableMenuDownload: (this.selectedUnmountImages.length > 0) ? false : true, selectedItemCount: this.selectedUnmountImages.length };
        }
        break;
      }
      case 3: {
        if (this.inventoryImageComponent) {
          currentTabObject = { filterOptions: this.inventoryImageComponent.inventoryFilterOptions, component: this.inventoryImageComponent, disableMenuDownload: (this.selecteInventoryImages.length > 0) ? false : true, selectedItemCount: this.selecteInventoryImages.length };
        }
        break;
      }
    }
    return currentTabObject;
  }

  globalFiltering(data) {
    this.globalSearchData.next(data);
  }

  onFilterChange() {
    const filterObject: FilterObject = new FilterObject();
    filterObject.selectedFilter = {
      filter: this.selectedFilters,
      tabIndex: this.tabIndex
    };
    this.photoInterfaceGlobalSrevice.filterObject.next(filterObject);
  }

  getFilterLable(filter) {
    if (this.tabIndex === 4) {
      return MediaSubCategoryEnum[filter];
    } else {
      return ImageTypeEnum[filter];
    }
  }

  removeFilter(index) {
    this.selectedFilters.splice(index, 1);
    this.onFilterChange();
  }

  onImageClick(event) {
    this.photoDialog.photoIndex = event.index;
    this.photoDialog.imageData = event.images;
    this.photoDialog.showImages();
  }

  resetPhotoDialogDisplay() {
    this.photoDialog.photoIndex = null;
    this.photoDialog.imageData = [];
  }

  getCommaSeparatedFields() {
    return utils.getCommaSeparatedFields(this.globalFilterFields);
  }
}
