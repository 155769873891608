<div class="topbar clearfix">
  <div class="topbar-left">
    <div (click)="moveToHomepage()">
      <div *ngIf="isLoading">
        <p-progressSpinner class="logo-loader"></p-progressSpinner>
      </div>
      <img
        src="{{ logoUrl }}"
        width="130px"
        height="33px"
        style="display: inline-block; vertical-align: middle; cursor: pointer"
      />
    </div>
  </div>

  <div class="topbar-right">
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)"> </a>

    <a
      id="topbar-menu-button"
      href="#"
      (click)="app.onTopbarMenuButtonClick($event)"
    >
      <i class="material-icons">menu</i>
    </a>

    <ul
      class="topbar-items animated fadeInDown"
      [ngClass]="{ 'topbar-items-visible': app.topbarMenuActive }"
    >
      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="pptloader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </div>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          (app.isHorizontal() && pptloader?.text?.length)
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span>{{ pptloader?.text }}</span>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="xlsLoader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </div>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          (app.isHorizontal() && xlsLoader?.text?.length)
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span>{{ xlsLoader?.text }}</span>
      </li>

      <li
      #profile
      class="profile-item"
      *ngIf="app.profileMode === 'top' || app.isHorizontal()"
      [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
    >
      <div *ngIf="isAllImageDownloading">
        <p-progressSpinner
          [style]="{ width: '20px', height: '20px' }"
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="2s"
        ></p-progressSpinner>
      </div>
    </li>

    <li
    #profile
    class="profile-item"
    *ngIf="app.profileMode === 'top' || app.isHorizontal()"
    [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
  >
    <div *ngIf="isCapacityExcelDownloading">
      <p-progressSpinner
        [style]="{ width: '20px', height: '20px' }"
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration="2s"
      ></p-progressSpinner>
    </div>
  </li>
  <li
  #profile
  class="profile-item"
  *ngIf="
  isCapacityExcelDownloading &&
    (app.profileMode === 'top' ||
    (app.isHorizontal()))
  "
  [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
>
  <span>Downloading Excel</span>
</li>

    <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          app.isHorizontal()
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="isAllImageDownloading">
          Downloading Images...
        </div>
      </li>

      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="quoteLoader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </div>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          (app.isHorizontal() && quoteLoader?.text?.length)
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span>{{ quoteLoader?.text }}</span>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="inventoryPhotoUpdateLoader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </div>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          (app.isHorizontal() && inventoryPhotoUpdateLoader?.text?.length)
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span>{{ inventoryPhotoUpdateLoader?.text }}</span>
      </li>
      <li
        #profile
        class="profile-item"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <ng-container *ngIf="gridLoader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </ng-container>
      </li>
      <li
        #profile
        class="profile-item"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span *ngIf="gridLoader?.isLoading">{{ gridLoader?.text }}</span>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <div *ngIf="photoLoader?.isLoading">
          <p-progressSpinner
            [style]="{ width: '20px', height: '20px' }"
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration="2s"
          ></p-progressSpinner>
        </div>
      </li>
      <li
        #profile
        class="profile-item"
        *ngIf="
          app.profileMode === 'top' ||
          (app.isHorizontal() && photoLoader?.isLoading)
        "
        [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }"
      >
        <span *ngIf="photoLoader?.isLoading">{{ photoLoader?.text }}</span>
      </li>

      <li
        #profile
        class="profile-item padding-right-5 cursor-pointer"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{
          'active-top-menu': app.activeTopbarItem === profile,
          'disable-icon': pptloader?.isLoading
        }"
      >
        <i
          class="fa fa-bell-o notification-bell cursor-pointer"
          title="Notifications"
          (click)="onNotificationBellClick($event)"
        >
        </i>
        <span class="badge" *ngIf="unreadCount > 0">
          <span class="badge-count">{{ unreadCount }}</span>
        </span>
      </li>

      <ng-container *permission="'read:passbook'">
        <li
          #profile
          class="profile-item padding-right-5"
          *ngIf="
            (app.profileMode === 'top' || app.isHorizontal()) && showPassBook
          "
          [ngClass]="{
            'active-top-menu': app.activeTopbarItem === profile,
            'disable-icon': pptloader?.isLoading
          }"
        >
          <i
            class="custom-wallet-icon icon-wallet"
            title="Passbook"
            [routerLink]="['/passbook']"
          ></i>
        </li>
      </ng-container>

      <li
        #profile
        class="profile-item padding-right-5"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{
          'active-top-menu': app.activeTopbarItem === profile,
          'disable-icon': pptloader?.isLoading
        }"
      >
      <a href={{DOCUMENTATION_URL}} target="_blank">
        <i
          class="help-box-icon icon-help-and-support"
          title="Help"
        ></i>
      </a>
      </li>

      <li
        #profile
        class="profile-item"
        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [ngClass]="{
          'active-top-menu': app.activeTopbarItem === profile,
          'disable-icon': pptloader?.isLoading
        }"
      >
        <a href="#" (click)="app.onTopbarItemClick($event, profile)">
          <div class="c-topbar-item-name">{{ profileInitials }}</div>
        </a>

        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem" class="profile-menu-li">
            <div class="ui-g">
              <div class="ui-g-3">
                <div class="c-topbar-item-name">{{ profileInitials }}</div>
              </div>
              <div class="ui-g-9">
                <div class="topbar-item-name profile-name">
                  {{ userProfile?.user_metadata?.firstname }}
                  {{ userProfile?.user_metadata?.lastname }}
                </div>
                <div class="topbar-item-name profile-email">
                  {{ userProfile?.email }}
                </div>
                <div class="topbar-item-name profile-email">
                  <span style="font-weight: bold">Roles: </span>{{ getRoles() }}
                </div>
              </div>
              <div class="ui-g-12 signout-button">
                <div class="logout ui-g-6">
                  <a (click)="logout()">Sign out</a>
                </div>
                <div class="view-profile ui-g-6">
                  <a (click)="viewProfile()">View Profile</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <p-menu
      #helpmenu
      popup="helpPopup"
      [model]="helpItems"
      class="help-menu"
    ></p-menu>
  </div>
</div>

<div *ngIf="display">
  <sib-help-support
    [display]="display"
    (emitCloseHelpDialog)="display = false"
  ></sib-help-support>
</div>

<div *ngIf="displaySuggestionDialog">
  <sib-suggestion-dialog
    [showSuggestionDialog]="displaySuggestionDialog"
    (emitCloseSuggestionDialog)="closeSuggestionDialog()"
  ></sib-suggestion-dialog>
</div>

<p-overlayPanel #notificationOverlay class="notification-container">
  <div class="notifications-title">Notifications</div>
  <div class="web-notifications-container">
    <sib-web-notifications *ngIf="showNotifications"> </sib-web-notifications>
  </div>
</p-overlayPanel>
<!-- <p-overlayPanel #phone>
      <sib-outbound-call></sib-outbound-call>
  </p-overlayPanel> -->

<!-- <div class="chat-box-container">
      <div class="chat-box-header">
          <div class="header-user-details">
          </div>
          <div class="chat-window-functions">
          </div>
      </div>
      <div class="communication-header">
      </div>
  
      <div class="chat-section">
          
      </div>
  
  </div> -->
