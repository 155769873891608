import { Component, ViewEncapsulation, AfterViewInit, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from "primeng/primeng";
import { Campaign } from "../../modals/campaigns/campaign";
import { Subscription } from "rxjs";
import { Invoice } from "../../modals/billings/invoice";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Router } from "@angular/router";
import { AdditionalItem } from "../../modals/billings/additional-item";
import { CampaignService } from "../../services/shared/campaign.service";
import { CampaignQueryParams } from "../../modals/queryparams/campaign-queryparams";
import { CampaignItem } from "../../modals/campaigns/campaignItem";
import { CalculationService } from "../../services/shared/calculationService";
import { TaxConfigurationService } from "../../services/shared/tax-configuration.service";
import * as utils from '../../helpers/utils';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { ChargeTypeEnum } from '../../shared/constants/charge-type-enum';
import { InventoryTypeEnum } from '../../shared/constants/inventory-type.enum';

@Component({
    selector: 'sib-invoice-summary',
    templateUrl: './invoice-summary.component.html',
    styleUrls: ['./invoice-summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class InvoiceSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

    // @Input() invoice: Invoice = new Invoice();
    _invoice: Invoice = new Invoice();
    @Input() set invoice(invoice) {
        this._invoice = invoice;
        this._invoice = JSON.parse(JSON.stringify(this._invoice));
        if (this.campaignItems && this.campaignItems.length) {
            this.calculateInvoiceSummary();
        }
    }

    get invoice() {
        return this._invoice;
    }

    _tempInvoice: Invoice = new Invoice();
    @Input() set tempInvoice(_tempInvoice) {
        this._tempInvoice = _tempInvoice;
        this._tempInvoice = JSON.parse(JSON.stringify(this._tempInvoice));
        this.calculateTempInvoiceLSPPctg();
    }

    get tempInvoice() {
        return this._tempInvoice;
    }

    _showChanges: boolean = false;
    @Input() set showChanges(showChanges) {
        this._showChanges = showChanges;
        this._showChanges = JSON.parse(JSON.stringify(this._showChanges));
    }

    get showChanges() {
        return this._showChanges;
    }

    _invoiceFormat: InvoiceFormatEnum;
    @Input() set invoiceFormat(invoiceFormat) {
        if (invoiceFormat) {
            this._invoiceFormat = invoiceFormat;
            this._invoiceFormat = JSON.parse(JSON.stringify(this._invoiceFormat));
        }
    }

    get invoiceFormat() {
        return this._invoiceFormat;
    }

    @Output() eEmitCampAndLspDiff: EventEmitter<any> = new EventEmitter();

    isLoading = false;
    subscription: Subscription[] = [];
    campaign: Campaign = new Campaign();
    campaignItems: CampaignItem[] = [];
    campaignDifference = 0;
    campaignDifferencePctg = 0;
    lspDifference = 0;
    lspDifferencePctg = 0;
    rentAmount = 0;
    printAmount = 0;
    mountAmount = 0;
    minAmount = 0;
    totalWithoutTax = 0;
    igstTax = 0;
    cgstTax = 0;
    sgstTax = 0;
    totalWithTax = 0;

    igstParameter: number;
    gstParameter: number;
    cgstParameter: number;
    sgstParameter: number;

    tempInvoiceLSPDifferencePctg = 0;
    isIgst = false;

    selectedPrintChargeType: Map<string, string> = new Map();

    constructor(
        private campaignService: CampaignService,
        private campaignQueryParams: CampaignQueryParams,
        private calculationService: CalculationService,
        private taxConfigurationService: TaxConfigurationService,
        private currencyPipe: CurrencyHelperPipe,
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.getTaxConfiguration();
        // this.getCampaign();
        // this.getCampaignItems();
    }

    getCampaign() {
        this.campaignQueryParams = new CampaignQueryParams();
        this.campaignQueryParams.projection = 'campaignProjection';
        this.subscription.push(this.campaignService.getCampaignWithEmployee(this.invoice.campaign.id, this.campaignQueryParams).subscribe((response: any) => {
            this.campaign = response;
            this.checksChargesTypes()
        }));
    }

    checksChargesTypes() {
        for (let inventory in this.campaign.mediaTypeToPrintingPrice) {
            if (this.campaign.mediaTypeToPrintingPrice[inventory].perUnit) {
                this.selectedPrintChargeType.set(inventory, ChargeTypeEnum['PER_UNIT'])
            } else {
                this.selectedPrintChargeType.set(inventory, ChargeTypeEnum['PER_SQFT']);
            }
        }
    }

    getCampaignItems() {
        this.subscription.push(this.campaignService.getHoardings(this.invoice.campaign.id).subscribe((response: any) => {
            this.campaignItems = response;
            this.calculateInvoiceSummary();
            this.calculateTempInvoiceLSPPctg();
            this.isLoading = false;
        }));
    }

    ngAfterViewInit() { }

    getTaxConfiguration() {
        this.taxConfigurationService.getTaxConfiguration();
        this.subscription.push(
            this.taxConfigurationService.gst
                .subscribe((gst) => {
                    this.gstParameter = gst;
                }));

        this.subscription.push(
            this.taxConfigurationService.cgst
                .subscribe((cgst) => {
                    this.cgstParameter = cgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.sgst
                .subscribe((sgst) => {
                    this.sgstParameter = sgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.igst
                .subscribe((igst) => {
                    this.igstParameter = igst;
                    this.getCampaignItems();
                }));

    }

    calculateInvoiceSummary() {
        let rentDifference = 0;
        let printDifference = 0;
        let mountDifference = 0;
        // setting to 0 because tax is subscribed 2 times - need to resolve
        this.rentAmount = 0;
        this.printAmount = 0;
        this.mountAmount = 0;
        this.minAmount = 0;
        const compareObject = this.createMountingItemObjectToCompare();
        const rentCompareObject = this.createRentItemObjectToCompare();
        this.campaignItems.forEach((ci) => {
            this.invoice.rentItems.forEach((item) => {
                if (item.customId === ci.customId && item.campaignItemId === ci.id) {

                    var difference;
                    const days = this.calculationService.calculateDays(item.itemStartDate, item.itemEndDate);
                    const cost = Number(this.calculationService.calculateCostOfHoarding(item.rate, days));

                    const minCost = this.calculationService.calculateCostOfHoarding(ci.minimumPrice, days);

                    if (ci.rateApprovalPending) {                             //when item is in rate pending state then take oldRate in consideration
                        difference = item.rate - ci.oldRate;
                    } else {
                        difference = item.rate - ci.rate;
                    }
                    rentDifference += this.calculationService.calculateCostOfHoarding(difference, days);
                    this.rentAmount += cost;
                    this.minAmount += minCost;
                }
            });

            // rentCompareObject.forEach((obj) => {
            //     this.invoice.rentItems.forEach((item) => {
            //         if (item.customId === ci.customId&& item.campaignItemId===ci.id) {
            //             const days = this.calculationService.calculateDays(item.itemStartDate, item.itemEndDate);
            //             const cost = Number(this.calculationService.calculateCostOfHoarding(item.rate, days));

            //             const minCost = this.calculationService.calculateCostOfHoarding(obj.minimumPrice, days);
            //             const difference = item.rate - obj.rate;
            //             rentDifference += this.calculationService.calculateCostOfHoarding(difference, days);
            //             this.rentAmount += cost;
            //             this.minAmount += minCost;
            //         }
            //     });
            // this.invoice.printingItems.forEach((item) => {
            //     if (item.customId === ci.customId) {
            //         const difference = Number(item.amount - ci.printCharge);
            //         printDifference += Number(difference);
            //         this.printAmount += this.getDecimalValue(Number(item.amount), 2);
            //     }
            // });
            // this.invoice.mountingItems.forEach((item) => {
            //     if (item.customId === ci.customId) {
            //         const difference = Number(item.amount - ci.mountCharge);
            //         mountDifference += Number(difference);
            //         this.mountAmount += this.getDecimalValue(Number(item.amount), 2);
            //     }
            // });
        });
        compareObject.forEach((obj) => {
            this.invoice.printingItems.forEach((item) => {
                // && new Date(this.calculationService.setToBeginning(item.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(obj.serviceDate)).valueOf()
                if (item.customId === obj.customId && item.creative === obj.creative) {
                    const difference = Number(item.amount - obj.printCharge);
                    printDifference += Number(difference);
                    this.printAmount += Number(this.getDecimalValue(Number(item.amount), 2));
                }
            });
            this.invoice.mountingItems.forEach((item) => {
                if (item.customId === obj.customId && item.creative === obj.creative) {
                    const difference = Number(item.amount - obj.mountCharge);
                    mountDifference += Number(difference);
                    this.mountAmount += Number(this.getDecimalValue(Number(item.amount), 2));
                }
            });
        });
        this.lspDifference = this.calculationService.calculateLSPDifference(this.rentAmount, this.minAmount);
        if (this.minAmount !== 0) {
            this.lspDifferencePctg = this.calculationService.calculateLSPDifferencePercentage(this.rentAmount, this.minAmount);
        } else {
            this.lspDifferencePctg = 0;
        }
        this.totalWithoutTax = Number(this.getDecimalNum(Number(this.rentAmount + this.printAmount + this.mountAmount)));
        this.campaignDifference = Number(this.getIntegerRoundedOffValue(this.getDecimalValue(Number(rentDifference + printDifference + mountDifference), 2)));
        if (this.totalWithoutTax !== 0) {
            this.campaignDifferencePctg = this.calculationService.calculatePctg(this.campaignDifference, this.totalWithoutTax);
        } else {
            this.campaignDifferencePctg = 0;
        }
        this.igstTax = Number(this.calculateTax(this.totalWithoutTax, this.igstParameter));
        // this.totalWithTax = this.totalWithoutTax + this.igstTax;
        // if (this.invoice.campaign.customer !== undefined && this.invoice.campaign.customer !== null && this.invoice.campaign.customer.state !== undefined && this.invoice.campaign.customer.state !== null && this.invoice.campaign.customer.state.name === "Gujarat") {
        // if (this.invoice.invoiceGstDetail !== undefined && this.invoice.invoiceGstDetail !== null && this.invoice.invoiceGstDetail.branchAddress.state !== undefined && this.invoice.invoiceGstDetail.branchAddress.state !== null && this.invoice.invoiceGstDetail.branchAddress.state.name === "Gujarat") {
        this.cgstTax = Number(this.calculateTax(this.totalWithoutTax, this.cgstParameter));
        this.sgstTax = Number(this.calculateTax(this.totalWithoutTax, this.sgstParameter));
        this.totalWithTax = Number(this.getIntegerRoundedOffValue(this.totalWithoutTax + this.cgstTax + this.sgstTax));
        this.isIgst = false;
        // }
        // if (!this.invoice.invoiceGstDetail.branchAddress.state.name ? (this.invoice.campaign.customer.state.name && this.invoice.campaign.customer.state.name !== 'Gujarat') : this.invoice.invoiceGstDetail.branchAddress.state.name && (this.invoice.invoiceGstDetail.branchAddress.state.name !== 'Gujarat' && this.invoice.invoiceGstDetail.branchAddress.state.name !== '')) {
        //     // if (this.invoice.invoiceGstDetail.branchAddress.state.name && (this.invoice.invoiceGstDetail.branchAddress.state.name !== 'Gujarat' && this.invoice.invoiceGstDetail.branchAddress.state.name !== '')) {
        //     this.totalWithTax = this.totalWithoutTax + this.igstTax;
        // }
        if (this.invoice) {
            if (this.invoice.invoiceGstDetail && this.invoice.invoiceGstDetail.gstNo) {
                if (this.invoice.supplier && this.invoice.supplier.supplierGstDetail && this.invoice.supplier.supplierGstDetail.gstNo) {
                    if (this.invoice.invoiceGstDetail.branchAddress && this.invoice.invoiceGstDetail.branchAddress.state && this.invoice.invoiceGstDetail.branchAddress.state.name && this.invoice.supplier.supplierGstDetail.branchAddress && this.invoice.supplier.supplierGstDetail.branchAddress.state && this.invoice.supplier.supplierGstDetail.branchAddress.state.name && this.invoice.invoiceGstDetail.branchAddress.state.name !== this.invoice.supplier.supplierGstDetail.branchAddress.state.name) {
                        this.totalWithTax = this.totalWithoutTax + this.igstTax;
                        this.isIgst = true;
                    }
                } else {
                    if (this.invoice.supplier && this.invoice.supplier.address && this.invoice.supplier.address.state && this.invoice.supplier.address.state.name) {
                        if (this.invoice.invoiceGstDetail.branchAddress && this.invoice.invoiceGstDetail.branchAddress.state && this.invoice.invoiceGstDetail.branchAddress.state.name && this.invoice.invoiceGstDetail.branchAddress.state.name !== this.invoice.supplier.address.state.name) {
                            this.totalWithTax = this.totalWithoutTax + this.igstTax;
                            this.isIgst = true;
                        }
                    }
                }
            } else {
                if (this.invoice && this.invoice.campaign && this.invoice.campaign.customer && this.invoice.campaign.customer.state && this.invoice.campaign.customer.state.name) {
                    if (this.invoice.supplier && this.invoice.supplier.supplierGstDetail && this.invoice.supplier.supplierGstDetail.gstNo) {
                        if (this.invoice.supplier.supplierGstDetail.branchAddress && this.invoice.supplier.supplierGstDetail.branchAddress.state && this.invoice.supplier.supplierGstDetail.branchAddress.state.name && this.invoice.campaign.customer.state.name !== this.invoice.supplier.supplierGstDetail.branchAddress.state.name) {
                            this.totalWithTax = this.totalWithoutTax + this.igstTax;
                            this.isIgst = true;
                        }
                    } else {
                        if (this.invoice.supplier && this.invoice.supplier.address && this.invoice.supplier.address.state && this.invoice.supplier.address.state.name) {
                            if (this.invoice.campaign.customer.state.name !== this.invoice.supplier.address.state.name) {
                                this.totalWithTax = this.totalWithoutTax + this.igstTax;
                                this.isIgst = true;
                            }
                        }
                    }
                }
            }
        }
        this.eEmitCampAndLspDiff.emit({ campDiff: this.campaignDifference, campDiffPctg: this.campaignDifferencePctg, lspDiff: this.lspDifference, lspDiffPctg: this.lspDifferencePctg });
    }

    calculateTempInvoiceLSPPctg() {
        let tempInvoiceMinAmount = 0;
        this.campaignItems.forEach((ci) => {
            this.tempInvoice.rentItems.forEach((item) => {
                if (item.customId === ci.customId) {
                    const days = this.calculationService.calculateDays(item.itemStartDate, item.itemEndDate);
                    const minCost = this.calculationService.calculateCostOfHoarding(ci.minimumPrice, days);
                    tempInvoiceMinAmount += minCost;
                }
            });
        });
        if (tempInvoiceMinAmount !== 0) {
            this.tempInvoiceLSPDifferencePctg = this.calculationService.calculateLSPDifferencePercentage(this.tempInvoice.rentAmount, tempInvoiceMinAmount);
        } else {
            this.tempInvoiceLSPDifferencePctg = 0;
        }
    }

    createMountingItemObjectToCompare() {
        const obj: {
            customId: string,
            creative: string,
            serviceDate: Date,
            printCharge: number,
            mountCharge: number
        }[] = [];
        this.campaignItems.forEach((ci) => {
            ci.mountingItems.forEach((mi) => {
                // obj.push({ customId: ci.customId, creative: mi.creativeName, serviceDate: new Date(mi.date), printCharge: (HoardingLightEnum[ci.lightType] === HoardingLightEnum.F_L) ? ci.printingPrice.flNlPrice * ci.squareFeet : (HoardingLightEnum[ci.lightType] === HoardingLightEnum.B_L) ? ci.printingPrice.blPrice * ci.squareFeet : 0, mountCharge: ci.mountingPrice.pricePerSqFt * ci.squareFeet });
                obj.push({
                    customId: ci.customId,
                    creative: mi.creativeName,
                    serviceDate: new Date(mi.date),
                    printCharge: this.getPrintCharge(ci, mi),
                    mountCharge: this.getMountCharge(ci, mi)

                });
            });
        });
        return obj;
    }

    createRentItemObjectToCompare() {
        const obj: {
            customId: string,
            validationStartDate: Date,
            validationEndDate: Date,
            minimumPrice: number,
            rate: number
        }[] = [];
        this.campaignItems.forEach((ci) => {
            obj.push({ customId: ci.customId, validationStartDate: new Date(ci.itemStartDate), validationEndDate: new Date(ci.itemEndDate), minimumPrice: ci.minimumPrice, rate: ci.rate });
        });
        return obj;
    }

    getPrintCharge(ci, mi) {
        if (mi.creativeName === 'Default') {
            return ci.printCharge;
        } else if (mi.creativeName !== 'Default' && this.selectedPrintChargeType.get(ci.lightType) === ChargeTypeEnum['PER_SQFT']) {
            if (HoardingLightEnum[ci.lightType] !== HoardingLightEnum.VARIOUS) {
                return ((HoardingLightEnum[ci.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[ci.lightType] === HoardingLightEnum.N_L) ? mi.printingPrice.flNlPrice * ci.squareFeet : mi.printingPrice.blPrice * ci.squareFeet)
            } else if (HoardingLightEnum[ci.lightType] === HoardingLightEnum.VARIOUS) {
                var itemPrintingPrice = 0;
                var printCharge = 0
                ci.childInventories.forEach((inventory) => {
                    itemPrintingPrice = HoardingLightEnum[inventory.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[inventory.lightType] === HoardingLightEnum.N_L ? mi.printingPrice.flNlPrice : (HoardingLightEnum[inventory.lightType] === HoardingLightEnum.B_L) ? mi.printingPrice.blPrice : 0;
                    printCharge += this.getDecimalNum(itemPrintingPrice * inventory.squareFeet) * ci.quantity;
                })

                return printCharge;
            }
        } else if (mi.creativeName !== 'Default' && this.selectedPrintChargeType.get(ci.lightType) === ChargeTypeEnum['PER_UNIT']) {
            if (ci.childInventories.length > 0) {
                var printCharge = 0
                ci.childInventories.forEach((child) => {
                    if (InventoryTypeEnum[child.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE']) {
                        printCharge += HoardingLightEnum[child.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[child.lightType] === HoardingLightEnum.N_L ? Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitFl) * 2)) : Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitBl) * 2))
                    } else {
                        printCharge += HoardingLightEnum[child.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[child.lightType] === HoardingLightEnum.N_L ? Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitFl))) : Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitBl)));
                    }
                })
            } else {
                if (InventoryTypeEnum[ci.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE']) {
                    printCharge = HoardingLightEnum[ci.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[ci.lightType] === HoardingLightEnum.N_L ? Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitFl) * 2)) : Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitBl) * 2));
                } else {
                    printCharge = HoardingLightEnum[ci.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[ci.lightType] === HoardingLightEnum.N_L ? Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitFl))) : Number(this.getDecimalNum(ci.quantity * Number(mi.printingPrice.pricePerUnitBl)));
                }
                // printCharge = InventoryTypeEnum[ci.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE'] ? Number(this.getDecimalNum(ci.quantity * Number(mi.printPrice) * 2)) : Number(this.getDecimalNum(mi.quantity * Number(mi.printPrice)));
            }

            return printCharge;
        }
    }

    getMountCharge(ci, mi) {
        if (mi.creativeName === 'Default') {
            return ci.mountCharge;
        } else if (mi.creativeName !== 'Default' && this.selectedPrintChargeType.get(mi.lightType) === ChargeTypeEnum['PER_SQFT']) {
            if (HoardingLightEnum[mi.lightType] !== HoardingLightEnum.VARIOUS) {
                return this.getDecimalNum(mi.mountingPrice.pricePerSqFt * mi.squareFeet);
            } else {
                var mountCharge = 0;
                mi.childInventories.forEach((child) => {
                    mountCharge += this.getDecimalNum(mi.mountingPrice.pricePerSqFt * child.squareFeet) * mi.quantity;
                })

                return mountCharge;
            }
        } else if (mi.creativeName !== 'Default' && this.selectedPrintChargeType.get(mi.lightType) === ChargeTypeEnum['PER_UNIT']) {
            if (HoardingLightEnum[ci.lightType] !== HoardingLightEnum['VARIOUS']) {
                if (mi.childInventories.length > 0) {
                    var mountCharge = 0;
                    mi.childInventories.forEach((child) => {
                        mountCharge += InventoryTypeEnum[child.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE'] ? Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt) * 2)) : Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt)));
                    })
                } else {
                    mountCharge = InventoryTypeEnum[mi.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE'] ? Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt) * 2)) : Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt)));
                }
                return mountCharge;
            } else {
                var mountCharge = 0;
                mi.childInventories.forEach((inventory) => {
                    mountCharge += InventoryTypeEnum[inventory.inventoryType] === InventoryTypeEnum['DOUBLE_SIDE'] ? (Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt) * 2))) : (Number(this.getDecimalNum(mi.quantity * Number(mi.mountingPrice.pricePerSqFt))));
                })

                return mountCharge;
            }
        }
        // return (mi.creativeName === "Default") ? ci.mountCharge : mi.mountingPrice.pricePerSqFt * ci.squareFeet
    }

    calculateTax(value, taxValue) {
        return Number(Math.fround(value * taxValue / 100).toFixed(2));
    }

    getDecimalValue(value, fractionPlaces) {
        return Number(Math.fround(value).toFixed(fractionPlaces));
    }

    parseValueToDecimalTwoPlaces(value) {
        return JSON.parse(JSON.stringify(utils.parseValueToDecimalTwoPlaces(value)));
    }

    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    getDecimalNum(value) {
        return utils.getDecimalNum(value);
    }

    getCurrencyFormat(value) {
        return this.currencyPipe.transform(value);
    }

    getAbsoluteValue(value) {
        return Number(Math.abs(Number(value)));
    }

    getTaxValue(tax, type) {
        let value: number = 0;
        switch (type) {
            case 'CGST': {
                value = ((this.cgstParameter * tax) / this.gstParameter);
                break;
            }
            case 'SGST': {
                value = ((this.sgstParameter * tax) / this.gstParameter);
                break;
            }
            case 'IGST': {
                value = ((this.igstParameter * tax) / this.gstParameter);
                break;
            }
        }
        return value;
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }
}
